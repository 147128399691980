import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import allApplicationDropdown from '../../dependencies/utils/json/allApplicationDropdown.json'
import { connect } from 'react-redux';
import columns, { rowStyle2 } from './common/dependencies/startupTableColumns';
import Table from '../common/Table';
import './scss/index.scss';
import ActionModal from './modal';
import popArchiveIcon from '../../assests/images/pop-archive.png';
import popApprovedIcon from '../../assests/images/pop-check.png';
import Download from '../common/Download';
import SectionTitle from '../common/sectionTitle/sectionTitle';
import SearchBox from '../common/Search';
import { SearchByStartupApplicationOptions } from './common/constant';
import InputField from '../common/InputField';
import { getNSAReportList, saveNsaResultData, doDownloadNSAResultList } from '../../dependencies/services/beneficiary';
import Button from '../common/Button';
import downArrowBtn from '../../assests/images/down-arrow-btn.png';
import downArrowBtnblack from '../../assests/images/down-arrow-btn-black.png';
import { toast } from "react-toastify";
import Refresh from '../common/Refresh';
import { ROLES } from '../../constants/Roles';
import { storage } from '../../dependencies/store/storage';

const NSAResult = (props) => {

  const { getNSAReportList, saveNsaResultData, nsaReportDataState: { data, recordsTotal,status,message }, doDownloadNSAResultList } = props;
  const [sortField, setSortField] = useState('applicationDate');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [sizePerPage, setSizePerPage] = useState(50);

  const [dropDownStatus, setDropDownStatus] = useState('text');
  const [edited, setEdited] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [query, setQuery] = useState({
    nsaTag: true, archived: false, edited: null
  });
  const [searchParams, setSearchParams] = useState({});
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [checkedValue, setCheckedValue] = useState([]);
  const [modalValue, setModalValue] = useState(false);
  const [buttonValue, setButtonValue] = useState('');
  const [bulkValue, setBulkValue] = useState({});
  const [natureOption, SetNatureOption] = useState([]);
  const [actionType, setActionType] = useState('');
  const [newApplication, setNewApplication] = useState(false);
  const [sectorOptions, setSectorOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showIncompleteHistory, setShowIncompleteHistory] = useState(false);
  const [exemptionId, setExemptionId] = useState("");
  const [diiptNumber, setDiiptNumber] = useState('');
  const [error, setError] = useState('');
  const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] = useState(false);
  const [nsaResultView, setNsaResultView] = useState(false);
  const [openSelectDropdown, setOpenSelectDropdown] = useState(false);
  const [openNSAYearDropdown, setOpenNSAYearDropdown] = useState(false);
  const [openSelectFilterDropdown, setOpenSelectFilterDropdown] = useState(false);
  const [openNSAYearFilterDropdown, setOpenNSAYearFilterDropdown] = useState(false);
  const [selectFilter, setSelectFilter] = useState('Select By');
  const [nsaYearFilter, setNsaYearFilter] = useState('NSA Year');
  const [select, setSelect] = useState('Select By');
  const [nsaYear, setNsaYear] = useState('NSA Year');
  const [searchDisable, setSearchDisable] = useState(true);
  const [saveDisable, setSaveDisable] = useState(true);
  const [nameOfEntity, setNameOfEntity] = useState('');
  const [incorporationNumber, setIncorporationNumber] = useState('');
  const [incorporationDate, setIncorporationDate] = useState('');
  const [certificateNumber, setCertificateNumber] = useState('');

  const userRole = storage.getUserRole();


  useEffect(() => {
    getTableData();
  }, [sortField, sortOrder, sizePerPage, pageNo, searchParams, query, searchText])

  const getTableData = () => {
    getNSAReportList({
      sortColumn: sortField,
      sortOrder: sortOrder.toUpperCase(),
      pageSize: sizePerPage,
      pageIndex: pageNo,
      query
    });
  }


  const onSelectRows = (selectedRows) => {
    setCheckedValue(selectedRows)
  }

  const onSortChange = async (params) => {
    const { order, dataField } = params;
    let sortName = '';
    if (dataField === 'mobileNumber') {
      sortName = 'representative.mobile';
    } else if (dataField === 'referenceNumber') {
      sortName = 'referenceNumber';
    } else if (dataField === 'certificateNumber') {
      sortName = 'startupEntity.recognitionNumber';
    } else if (dataField === 'incorporationNumber') {
      sortName = 'incorporationNumber';
    } else if (dataField === 'nameOfEntity') {
      sortName = 'startupEntity.name';
    } else if (dataField === 'applicationDate') {
      sortName = 'applicationDate';
    } else if (dataField === 'registrationDate') {
      sortName = 'startupEntity.corCreatedDate';
    } else if (dataField === 'emailId') {
      sortName = 'representative.email';
    } else {
      sortName = 'createdDate';
    }
    if (dataField !== '') {
      setSortField(sortName);
      setSortOrder(order.toUpperCase());
    }

  }
  const handleOnPageChange = (params) => {
    const { page } = params;
    setPageNo(page - 1);
  }
  const handleOnSizePerPageChange = (params) => {
    const { sizePerPage } = params;
    setSizePerPage(sizePerPage)
    setPageNo(0);
  }

  useEffect(() => {
    if (data && status) {
      setNameOfEntity(data[0]?.nameOfEntity);
      setIncorporationDate(data[0]?.incorporationDate);
      setIncorporationNumber(data[0]?.incorporationNumber);
      setCertificateNumber(data[0]?.certificateNumber);
    }
  else{
    toast.error(message)
  // setNsaResultView(false)

  }

  }, [data])
  // useEffect(() => {
  //   props.getStarutupselection();
  // }, []);

  const toggleActionModal = () => {
    setModalValue(!modalValue);
    if (modalValue) {
      setActionType('');
    }
  }

  const actionHandler = (actionType) => {
    setActionType(actionType)
  }
  useEffect(() => {
    if (checkedValue.length === 0 && actionType) {
      setButtonValue('invalid')
      toggleActionModal();
    } else {
      bulkApprove();
    }
  }, [actionType])

  const bulkApprove = () => {
    if (actionType === 'approved') {
      toggleActionModal();
      setButtonValue('approved')
      setBulkValue({
        ids: checkedValue,
        status: 'APPROVE'
      })
    } else if (actionType === 'archived') {
      toggleActionModal();
      setButtonValue('archived')
      setBulkValue({
        ids: checkedValue,
        status: 'ARCHIVE'
      })
    }

  }
  function downloadNSAResultList() {
    if (doDownloadNSAResultList) {
      return doDownloadNSAResultList({
        fetchAllRecords: true,
        sortColumn: sortField,
        sortOrder: sortOrder.toUpperCase(),
        pageSize: sizePerPage,
        pageIndex: pageNo,
        query
      }).then((data) => {
        return data;
      })
    }
  }



  useEffect(() => {
    if (props.getSectorOptions) {
      props.getSectorOptions().then((response) => {
        const { data = [] } = response;
        let options = [];
        if (data && data instanceof Array) {
          options = data.reduce((arr, option) => {
            if (option['industryName'] !== null) {
              var text = `${option['sectionName']} (${option['industryName']})` || "";
            } else {
              var text = `${option['sectionName']}` || "";
            }
            const value = option['id'] || "";
            arr.push({ text, value });
            return arr;
          }, []);
        }
        setSectorOptions(options);
      });
    }

    // getNatureOfList().then((response) => {
    //   const { data, status } = response || {};
    //   if (status) {
    //     let options = [];
    //     if (data && data instanceof Array) {
    //       options = data.reduce((arr, option) => {
    //         const text = option['name'] || "";
    //         const value = option['id'] || "";
    //         arr.push({ text, value });
    //         return arr;
    //       }, []);
    //     }
    //     SetNatureOption(options);
    //   }
    // });

  }, [])

  useEffect(() => {
    if (exemptionId) {
      setShowIncompleteHistory(true)
    }
  }, [exemptionId]);
  const clickOnAction = (id) => {
    if (id) {
      setExemptionId(id)
    }
  }
  useEffect(() => {
    if (selectFilter != 'Select By' && nsaYearFilter != 'NSA Year') {
      setSearchDisable(false)
    }
  }, [nsaYearFilter, selectFilter])

  useEffect(() => {
    if (select != 'Select By' && nsaYear != 'NSA Year') {
      setSaveDisable(false)
    }
  }, [nsaYear, select])

  const handleSearch = () => {
    setSelect('Select By');
    setNsaYear('NSA Year');
    setSaveDisable(true);
    setCertificateNumber('');
    setIncorporationDate('');
    setIncorporationNumber('');
    setNameOfEntity('');
    if (diiptNumber) {
      setError('');
      setQuery(() => {
        query.randomReg = diiptNumber
        delete query['status'];
        delete query['nsaTag'];
        return { ...query, 'searchType': 'CREATED_DATE', }
      });
      setSearchText(searchText);
      setPageNo(0);
      setNsaResultView(true)
    } else {
      setHasInvalidSubmitOccurred(true)
      setError("Please enter Dipp number")
    }
  }
  const handleInput = (e, name) => {
    let value = e.target.value;

    if (name === 'diiptNumber') {
      setDiiptNumber(value);
    }
  }
  const handleOpenSelectDropdown = () => {
    setOpenSelectDropdown(!openSelectDropdown);
  }
  const handleOpenSelectFilterDropdown = () => {
    setOpenSelectFilterDropdown(!openSelectFilterDropdown);
  }

  const handleOpenNSAYearDropdown = () => {
    setOpenNSAYearDropdown(!openNSAYearDropdown);
  }
  const handleOpenNSAYearFilterDropdown = () => {
    setOpenNSAYearFilterDropdown(!openNSAYearFilterDropdown);
  }

  const handleSelectByFilter = (event, value) => {
    setSelectFilter(value);
    setOpenSelectFilterDropdown(false);
  }

  const handleNSAYearFilter = (event, value) => {
    setNsaYearFilter(value); // Set the selected value
    setOpenNSAYearFilterDropdown(false);
  };

  const handleSubmit = async () => {
    const data = await saveNsaResultData({ dippNumber: diiptNumber, result: select.charAt(0) + '_' + nsaYear })
    if (data.status) {
      window.location.reload();
    } else {
      toast.error(data.message);

    }
  }


  const handleSearchBySelect = () => {
    setQuery(() => {
      query.startupAdminTag = selectFilter.charAt(0) + '_' + nsaYearFilter;
      delete query['edited'];
      delete query['status'];
      return { ...query }
    });
  }


  const handleNSAYear = (event, value) => {
    setNsaYear(value);
    setOpenNSAYearDropdown(false);
  }
  const handleSelectBy = (event, value) => {
    setSelect(value);
    setOpenSelectDropdown(false);
  }

  function refreshStatupList() {
    window.location.reload();
  }

  let nsaYearArray = ['2019', '2020', '2021', '2022', '2023', '2024']
  return (

    <div>
      <SectionTitle>NSA Results</SectionTitle>
      <div className="startup-app-filter-form-results">
        <div className='row ju results-row'>
          <div className='col-md-5 w-100'>
            {userRole === ROLES.SUPER_ADMIN && <>
              <div className="inner-wrap clearfix">
                <div className="left-filter-wrap">
                  <div className='nsa-results'>
                    <div className='nsa-results-grup'>
                      <div className='nsa-results-no'>
                        DIPP Number
                      </div>
                      <InputField
                        required
                        type="text"
                        name="diiptNumber"
                        //  label="DIPP Number"
                        value={diiptNumber}
                        onChange={handleInput}
                        containerClass={"form-group"}
                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                        error={error}
                      />
                    </div>
                  </div>
                  <div className={`button-wrap`}>
                    <Button
                      className={`bttn`}
                      type="submit"
                      text="Submit"
                      onClick={handleSearch}

                    />
                  </div>

                </div>
              </div>
            </>}
          </div>
          <div className='col-md-7 w-100'>
            {!nsaResultView && <div className='row mt-0 mb-2 mt-0 justify-content-end ml-0 mr-0'>
              <div className='col-md-4'>
              <div onClick={handleOpenSelectFilterDropdown} className={`download-container bttn dropdown-check dropdown-check-list ${openSelectFilterDropdown ? 'show' : ""}`}>
  {selectFilter}  <img width="15px" src={downArrowBtnblack} className='showicon-black' />
  <img src={downArrowBtn} width="15px" className='showicon-white' />
  <ul>
    <li>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          value={'Finalist'}
          id={`radio-${'Finalist'}`}
          name="selectByFilter"
          onChange={(event) => handleSelectByFilter(event, 'Finalist')}
        />
        <label
          className="form-check-label"
          htmlFor={`radio-${'Finalist'}`}
        >
          Finalist
        </label>
      </div>
    </li>
    <li>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          value={'Winner'}
          id={`radio-${'Winner'}`}
          name="selectByFilter"
          onChange={(event) => handleSelectByFilter(event, 'Winner')}
        />
        <label
          className="form-check-label"
          htmlFor={`radio-${'Winner'}`}
        >
          Winner
        </label>
      </div>
    </li>
  </ul>
</div>

              </div>
              <div className='col-md-4'>
                <div onClick={handleOpenNSAYearFilterDropdown}
                  className={`download-container bttn dropdown-check dropdown-check-list ${openNSAYearFilterDropdown ? 'show' : ""}`}
                >
                  {nsaYearFilter}    <img width="15px" src={downArrowBtnblack} className='showicon-black' />
                  <img src={downArrowBtn} width="15px" className='showicon-white' />
                  <ul>
                    {nsaYearArray?.map((el) => (
                      <li key={el}  > {/* Added key attribute */}
                        <div className="form-check" >
                          <input
                            className="form-check-input"
                            type="radio"
                            value={el}
                            id={`checkbox-${el}`}
                            name="nsaYearFilter"
                            onChange={(event) => handleNSAYearFilter(event, el)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`checkbox-${el}`}
                            // onClick={(event) => handleNSAYearFilter(event, el)}
                          >
                            {el}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className='col-md-3'>
                <div className="button-wrap">
                  <Button
                    className="bttn submit-bnt"
                    type="submit"
                    text="Search"
                    onClick={handleSearchBySelect}
                    disabled={searchDisable}
                  />
                </div>
              </div>
            </div>}
          </div>
        </div>



        {nsaResultView ? <>
          <Refresh
            onClickRefresh={refreshStatupList}
            className=""
          />
          <div className='entity-details-box'>
            <div className='entity-heading'>Entity Details</div>
            <div className='row p18'>
              <div className='col-xl-3 col-12 col-md-6 col-sm-12'>
                <InputField
                  required
                  type="text"
                  name="diiptNumber"
                  label="DIPP No"
                  value={certificateNumber}
                  containerClass={"form-group"}
                  disabled
                />
              </div>
              <div className='col-xl-3 col-12 col-md-6 col-sm-12'>
                <InputField
                  required
                  type="text"
                  name="diiptNumber"
                  label="Incorporation Date"
                  value={incorporationDate}
                  containerClass={"form-group"}
                  disabled
                />
              </div>
              <div className='col-xl-3 col-12 col-md-6 col-sm-12'>
                <InputField
                  required
                  type="text"
                  name="diiptNumber"
                  label="Incorporation No."
                  value={incorporationNumber}
                  containerClass={"form-group"}
                  disabled
                />
              </div>
              <div className='col-xl-3 col-12 col-md-6 col-sm-12'>
                <InputField
                  required
                  type="text"
                  name="diiptNumber"
                  label="Name of Entity"
                  value={nameOfEntity}
                  containerClass={"form-group"}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className='row mt-2 mb-2 mt-4 justify-content-end ml-0 mr-0 entity-row'>
            <div className='col-md-3'>
              <div onClick={handleOpenSelectDropdown}
                className={`download-container bttn dropdown-check dropdown-check-list ${openSelectDropdown ? 'show' : ""}`}
              >
                {select}    <img width="15px" src={downArrowBtnblack} className='showicon-black' />
                <img src={downArrowBtn} width="15px" className='showicon-white' />
                <ul>
                  <li >
                    <div className="form-check">
                      <input className="form-check-input" type="radio" value={'Winner'} id={`checkbox-${'Winner'}`} name="selectBy" onChange={(event) => handleSelectBy(event, 'Winner')}  />
                      <label className="form-check-label" htmlFor={`checkbox-${'Winner'}`} >
                        Winner
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" value={'Finalist'} id={`checkbox-${'Finalist'}`} name="selectBy" onChange={(event) => handleSelectBy(event, 'Finalist')} />
                      <label className="form-check-label" htmlFor={`checkbox-${'Finalist'}`} >
                        Finalist
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-md-3'>
              <div onClick={handleOpenNSAYearDropdown}
                className={`download-container bttn dropdown-check dropdown-check-list ${openNSAYearDropdown ? 'show' : ""}`}
              >
                {nsaYear}    <img width="15px" src={downArrowBtnblack} className='showicon-black' />
                <img src={downArrowBtn} width="15px" className='showicon-white' />
                <ul>
                  {nsaYearArray?.map((el) => (<li key={el} >
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        value={el}
                        id={`checkbox-${el}`}
                        name="nsaYear"
                        onChange={(event) => handleNSAYear(event, el)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`checkbox-${el}`}
                      //  onClick={(event) => handleNSAYear(event, el)}
                      >
                        {el}
                      </label>
                    </div>
                  </li>))}


                </ul>


              </div>
            </div>
            <div className='col-md-2 p-0 pding15'>
              <div className="button-wrap">
                <Button
                  className="bttn submit-bnt"
                  type="submit"
                  text="Save"
                  onClick={handleSubmit}
                  disabled={saveDisable}
                />
              </div>
            </div>
          </div>
        </> : <>
          <div className='row' style={{ marginTop: '15px' }}>

            <div className='col-sm-12 mt-3 w-100' style={{ paddingRight: '24px' }}>
              <Download
                fileName={'NSA Result Details'}
                onClickDownload={downloadNSAResultList}
                className="bttn-link"
              />
            </div>
          </div>
          <Table
            dataTable={data}
            columns={columns({
              clickOnAction,
              pageNo: pageNo,
              size: sizePerPage
            })}
            keyField='applicationId'
            onSortChange={onSortChange}
            totalSize={recordsTotal}
            rowStyle={rowStyle2}
            sizePerPage={sizePerPage}
            onPageChange={handleOnPageChange}
            onSizePerPageChange={handleOnSizePerPageChange}
            actionHandler={actionHandler}
          /></>}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    // natureOfTheEntity: state.startupApplicationForm.natureOfTheEntity,
    // startupSectionList: state.startupSectionList.startupSelectionData,
    nsaReportDataState: state.nsaReportList.nasReportData,

  }
}

export default connect(mapStateToProps, { getNSAReportList, saveNsaResultData, doDownloadNSAResultList })(withRouter(NSAResult));