import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { login } from '../common/dependencies/action'
import Button from '../../common/Button';
import { Link } from "react-router-dom";
import LoadingAnimation from '../../common/loader/loadingAnimation'
import AlertMessage from '../../common/AlertMessage';
import InputField from '../../common/InputField';
import Captcha from '../Captcha';
import refreshIcon from '../../../assests/images/refresh-icon-blue.png';
import './scss/index.scss';
import { AlertType } from '../../../constants';
import FormValidator from '../common/dependencies/FormValidator';
import ReCAPTCHA from "react-google-recaptcha";


const Login = (props) => {
  const defaultFormData = {
    password: '',
    username: '',
    captcha: '',
  }

  const [formData, setFormData] = useState(defaultFormData);
  const [error, setError] = useState({});
  const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] = useState(false);
  const [captchaText, setCaptchaText] = useState("");
  const [isRefresh, setIsRefresh] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  useEffect(() => {
    if (!isRefresh) {
      setIsRefresh(true);
      setFormData(formData => {
        return { ...formData, captcha: '' }
      });
    }
  }, [isRefresh]);

  const validateInput = () => {
    const {
      username,
      password,
      // captcha
    } = formData;
    const formStatus = FormValidator.validateLoginForm({
      username,
      password,
      // captcha
    });
    const errors = {
      username: formStatus.username,
      password: formStatus.password,
      captcha: formStatus.captcha,
    };
    setError(errors);
    return !formStatus.hasErrors;
  };

  const handleInputChange = (e, name) => {
    const { value, } = e.target;
    setFormData((user) => {
      return { ...user, [name]: value }
    });
  }

  const getCaptcha = (text) => {
    setCaptchaText(text);
  }

  const doReset = () => {
    setFormData(defaultFormData);
    setError({})
    setCaptchaText('');
  }

  const onBlur = (e, name) => {
    validateInput();
  }

  const doRefresh = () => {
    setIsRefresh(false);
  };

  // To refresh the alert
  const onClose = () => {
    setAlertMessage('')
  }
  // const Gcaptch = (e) => {
  //   console.log(e)
  // }

  const onSubmit = (e) => {
    e.preventDefault();
    onClose();
    const { login } = props;
    const { captcha } = formData;
    if (!validateInput()) {
      setHasInvalidSubmitOccurred(true)
    } else if(!captchaText) {
      setError((error) => {
        return { ...error, captcha: 'Please fill captcha' }
      });
    }
    // else if (captchaText !== captcha) {
    //   setError((error) => {
    //     return { ...error, captcha: 'Please provide valid captcha' }
    //   });
    //   doRefresh();
    // } 
    else {
      const { username, password } = formData;
      login(username, password)
        .then((data) => {
          const { status = false, message } = data || {};
          if (status) {
            props.history.push('/dashboard');
          } else {
            setAlertMessage(message);
            setAlertType(AlertType.DANGER);
            // doRefresh();
          }
        });
    }
  }



  return (
    <div className="login-form-wrap basic-dialog">
      <LoadingAnimation />
      <AlertMessage
        message={alertMessage}
        type={alertType}
        allowClose={true}
        onClose={onClose}
      />

      <form onSubmit={e => onSubmit(e)}>
        <InputField
          required
          type="text"
          name="username"
          label="username"
          value={formData.username}
          onChange={handleInputChange}
          error={error.username}
          containerClass={"form-group"}
          onBlur={onBlur}
          hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
        />
        <InputField
          required
          autoComplete="off"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleInputChange}
          error={error.password}
          label={"password"}
          containerClass={"form-group"}
          onBlur={onBlur}
          hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
        />
        <ReCAPTCHA
          sitekey="6Lco5SAkAAAAAJ8KerOQXsdtwEsFPuS0kkltFdod"
          onChange={(e) => getCaptcha(e)}
        />
        {
          error.captcha && 
          <div className="input-field-container clearfix form-group error">
            <div className="inline-error">{error.captcha}</div>
          </div>
        }
        {/* <div className="captcha-outer">
        {
          isRefresh &&
          <Captcha
            getCaptcha={getCaptcha}
          />
        }
        
        <Button
          text=""
          icon={(<img src={refreshIcon} />)}
          onClick={doRefresh}
          disabled={!isRefresh}
          className="bttn-icon"

        />
        </div> */}
        {/* <InputField
          required
          type="text"
          name="captcha"
          value={formData.captcha}
          onChange={handleInputChange}
          error={error.captcha}
          label={"Enter Above Characters (Case Sensitive)"}
          containerClass={"form-group"}
          onBlur={onBlur}
          autoComplete="off"
          hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
        /> */}

        <div className="dialog-footer">
          <Button
            type="submit"
            text="Submit"
            className='bttn'
          />
          {/* <Button
            type='button'
            onClick={doReset}
            text="Reset"
            className='bttn blue'
          /> */}
          <Link className="forget-pass-link" to={'/auth/forget-password'}>Forgot Password?</Link>
        </div>
      </form>
    </div>
  );
}

export default connect(null, { login })(withRouter(Login));

