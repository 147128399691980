import api from '../../../dependencies/utils/Api';
import {
    FETCH_OTHER_DOCUMENTS_LIST_URL,
    ADD_OTHER_DOCUMENT_URL,
    EDIT_OTHER_DOCUMENT_URL,
    DELETE_OTHER_DOCUMENT_URL,
    FETCH_TAB_SECTION_LIST_URL
} from "../../../constants/ApplicationUrl";



export const OtherDucumentsServices = {


    fetchList(page, pageSize) {
        return api.GET(`${FETCH_OTHER_DOCUMENTS_LIST_URL}?page=${page}&pageSize=${pageSize}`).
            then((response) => {
                const { data = {}, status } = response;
                return { data, status };
            }).catch(e => {
                return Promise.reject(e);
            });;
    },

    fetchListByID(params) {
        return api.GET(`${FETCH_OTHER_DOCUMENTS_LIST_URL}/${params}`).
            then((response) => {
                const { data = {}, status } = response;
                return { data, status };
            }).catch(e => {
                return Promise.reject(e);
            });;
    },

    addOtherDocumentService(params) {
        return api.POST(ADD_OTHER_DOCUMENT_URL, params).
            then((response) => {
                const { data: { status, message } } = response;
                return { status, message };
            });;
    },


    editOtherDocumentService(id, params) {
        return api.POST(`${EDIT_OTHER_DOCUMENT_URL}/${id}`, params).
            then((response) => {
                const { data: { status, message } } = response;
                return { status, message };
            });;
    },

    deleteOtherDocumentService(id) {
        return api.POST(`${DELETE_OTHER_DOCUMENT_URL}/${id}`).
            then((response) => {
                const { data: { data, status, message } } = response;
                return { data, status, message };
            });;
    },

    fetchTabSectionListService(params) {
        return api.GET(`${FETCH_TAB_SECTION_LIST_URL}`).
            then((response) => {
                const { data = {} } = response;
                return { data };
            });
    },
}