import React, { useState, useEffect, Fragment } from 'react';

const ReadMore = (props) => {
    const { text, maxLength } = props;

    const [showComplete, setShowComplete] = useState(false);

    const exceedMax = () => {
        if (showComplete === false && text.length > maxLength) {
            return <Fragment>
                {text.substr(0,maxLength)}...
                <a href="javascript:void(0);" onClick={() => setShowComplete(true)}>
                    Read More
                </a>
            </Fragment>
        } else {
            return text;
        }
    }

    return (
        <span >
            {exceedMax()}
        </span>
    );
}

export default ReadMore;

ReadMore.defaultProps = {
    maxLength: 50
}