import api from '../../../../dependencies/utils/Api';
import { EDIT_PROFILE } from '../../../../constants/ApplicationUrl';

export const profileServices = {
    doEditProfile(params) {
        return api.POST(EDIT_PROFILE, params).
            then((response) => {
                const { data: { status, data: user, message } = {} } = response || {};
                if (status) {
                    return { user, message, status };
                }
                return Promise.reject({ message, status })
            })
    }
}