import api from '../../../dependencies/utils/Api';
import {
    FETCH_DASHBOARD_COUNT_DETAILS,
    FETCH_DASHBOARD_DAILY_COUNT_DETAILS,
    FETCH_AUDIT_TRAIL,
    FETCH_DASHBOARD_DAILY_COUNT_NEW_DETAILS,
    FETCH_DASHBOARD_COUNT_NEW_DETAILS

} from "../../../constants/ApplicationUrl";
import startupTransformer from './transformer/transformer'
import { returnStatement } from '@babel/types';



export const DashboardServices = {
    getDashboardCount() {
        return api.GET(FETCH_DASHBOARD_COUNT_DETAILS).
            then((response) => {
                const { data: { status, data } } = response;
                return { data, status };
            })
    },
    getDashboardDailyCount() {
        return api.GET(FETCH_DASHBOARD_DAILY_COUNT_DETAILS).
            then((response) => {
                const { data: { status, data } } = response;
                return { status, data }
            });
    },
    getDashboardDailyCountnew(params) {
        return api.GET(FETCH_DASHBOARD_DAILY_COUNT_NEW_DETAILS+'/'+params.id).
            then((response) => {
                const { data: { status, data } } = response;
                return { status, data }
            });
    },
    getDashboardCountNew(params) {
        return api.GET(FETCH_DASHBOARD_COUNT_NEW_DETAILS+'/'+params.id).
            then((response) => {
                const { data: { status, data } } = response;
                return { status, data }
            });
    },
    getAuditTrail(params) {
        return api.POST(FETCH_AUDIT_TRAIL, params)
            .then((resp) => {
                const { data: { status, message, data, recordsFiltered } } = resp;
                return { status, message, data, recordsFiltered };
            });
    }

}