import React from 'react';
import { httpClient } from './dependencies/utils/Api';
import get from "lodash/get";
import { storage } from "./dependencies/store/storage";
import { toast } from 'react-toastify';

export default {
  setupInterceptors: () => {
    httpClient.interceptors.request.use(function (config) {
      const token = storage.getJwtToken();
      if (token) {
        config.headers.Authorization = 'Bearer ' + token;
      }
      return config;
    }, function (err) {
      return Promise.reject(err);
    });

    httpClient.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/auth/login';

      }
      if (error.response && error.response.status === 403) {

        toast.error('')
      }
      /*if (error.response && error.response.status >= 500) {
        if(error && error.message !== 'Request aborted' && error.message !== 'Operation canceled') {
          history.push(PATHS.SERVER_ERROR);
        }
      }*/
      return Promise.reject(error);
    });
  },
};
