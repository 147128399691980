import React from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from '../../../../constants/ApplicationUrl';
import { SUPPORTING_DOCUMENTS, headerSortingStyle } from '../../../../constants';
import { ROLES } from '../../../../constants/Roles';
import { storage } from '../../../../dependencies/store/storage';
import moment from 'moment';



export const rowStyle2 = (row, rowIndex) => {
    const style = {};
    if (row.nonProfitCompany) {
        style.backgroundColor = '#a3ca57';
    } else if (row.isEdited) {
        style.backgroundColor = '#bffedf';
    } else if (row.natureOfEntity === "Registered Partnership") {
        style.backgroundColor = '#ffdb99';
    }

    return style;
};

const columns = ({
    clickOnAction,
    pageNo,
    size
}) => {
    const userRole = storage.getUserRole();
    const resultNsaFormatter = (cell, row) => {
        if (Array.isArray(cell)) {
          return cell.map((item, index) => {
            if (item.startsWith("W")) {
              return (
                <span key={index}>
                  <span style={{ color: "#4284ca" }}>{item}</span>
                  {index !== cell.length - 1 && ", "} {/* Add comma unless it's the last item */}
                </span>
              );
            } else {
              return (
                <span key={index}>
                  {item}
                  {index !== cell.length - 1 && ", "} {/* Add comma unless it's the last item */}
                </span>
              );
            }
          });
        }
        
        return cell;
      };
      

      const recognitionDateFormatter= (cell)=>{
        return (moment(cell).format('YYYY-MM-DD'))
      }
      
    return [
        {
            dataField: '',
            text: 'S.No',
            formatter: (cell, row, rowIndex) => {
                return (pageNo * size) + (rowIndex + 1);
            }
        },
        {
            dataField: 'certificateNumber',
            text: 'DIPP No',
            sort: false,
        },
        {
            dataField: 'incorporationNumber',
            text: 'Incorporation No.',
            sort: false,
        },
        {
            dataField: 'incorporationDate',
            text: 'Incorporation Date',
            sort: false,
        },
        {
            dataField: 'nameOfEntity',
            text: 'Name Of Entity',
            sort: false,
        }, 
        {
            dataField: 'recognitionDate',
            text: 'Startup Recognized Date',
            formatter: recognitionDateFormatter

        }, 
        {
            dataField: 'natureOfEntity',
            text: 'Nature of Entity'
        }, 
        {
            dataField: 'emailId',
            text: 'Email Id'
        },
        {
            dataField: 'mobileNumber',
            text: 'Phone No.'
        },
        {
            dataField: 'resultNsa',
            text: 'Status',
            formatter: resultNsaFormatter
        },

    ];
}


{/* <TableHeaderColumn width='150px' dataField='supportingDocument' dataFormat={this.supportingDocumentFormatter}>Supporting Document</TableHeaderColumn> */ }

export default columns;