import api from '../../../../dependencies/utils/Api';
import {
    FORGET_PASSWORD_URL,
    RESET_PASSWORD_URL,
    CHECK_VALID_TOKEN,
    LOGIN_URL,
    LOGOUT_URL
} from '../../../../constants/ApplicationUrl';

export const authServices = {
    doLogin(params) {
        return api.POST(LOGIN_URL, params).
            then((response) => {
                const { data: { status, data: user = {}, message } = {} } = response;
                return { user, message, status };
            });
    },
    checkLogin() {
        return api.GET(CHECK_VALID_TOKEN).
            then((response) => {
                const { data: { status = false } = {} } = response;
                if (status) {
                    return { status };
                }
                return Promise.reject(false);
            });
    },
    doForgetRequest(params) {
        return api.POST(FORGET_PASSWORD_URL, params)
            .then((response) => {
                const { data: { status, message } } = response;
                return { status, message };
            });
    },
        doResetPassword(params) {
            const { token, newPassword, confirmPassword } = params;
            return api.POST(`${RESET_PASSWORD_URL}?token=${token}`, { newPassword, confirmPassword })
                .then((data) => {
                    const { data: { status, message } } = data;
                    return { status, message };
                });
        },
    doLogout() {
        return api.POST(LOGOUT_URL)
            .then((data) => {
                const { data: { status, message } } = data;
                if (status) {
                    return status;
                }
                return Promise.reject(false);
            });
    }
}