import React, { Component, useEffect, useState } from 'react';
import InputField from '../common/InputField';
import GlobalDropDown from '../common/GlobalDropDown/index'
import { CorStatus, Stage, Gender, CoeStatus, DownloadField } from './dependencies/constant/searchConstant'
import { getStateList, getSectorList, dowloadReport,generateReport, downloadExcelFile } from './dependencies/action';
import { getIndustryList, getStartupSector } from '../../components/startupApplication/dependencies/action'
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import Button from '../common/Button'
import Download from '../common/Download';
import _ from 'lodash';
import SectionTitle from '../common/sectionTitle/sectionTitle';
import { Multiselect } from 'multiselect-react-dropdown'
import './style.scss'
import { toast } from "react-toastify";
import moment from 'moment/moment';

const StartupDetailReport = (props) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [corStatus, SetCorStatus] = useState('');
    const [coeStatus, SetCoeStatus] = useState('');
    const [stage, SetStage] = useState('');
    const [gender, SetGender] = useState('');
    const [state, SetState] = useState('');
    const [sector, SetSector] = useState('');
    const [industry, SetIndustry] = useState('');
    const { getStartupSector, getIndustryList, getSectorList, getStateList, IndustryList, StateList, SectorList, dowloadReport,downloadExcelFile,generateReport } = props;
    const [selectedFields, setSelectedFields] = useState(DownloadField);
    const [csvFile, setCsvFile] = useState('');
    const [reportDetail, SetReportDetails] = useState({})

    var m = new Date();
    var dateString =
        m.getUTCFullYear() + "-" +
        ("0" + (m.getUTCMonth() + 1)).slice(-2) + "-" +
        ("0" + m.getUTCDate()).slice(-2)
    if (StateList.data) {
        var stateListFinalValue = StateList.data.data.map((data) => {
            return {
                value: data.id,
                text: data.stateName
            };
        })
    }

    if (SectorList) {
        var sectorListFinalValue = SectorList.map((data) => {
            return {
                value: data.id,
                text: data.sectionName
            };
        })
    }

    if (IndustryList) {
        var industryListFinalValue = IndustryList.map((data) => {
            return {
                value: data.id,
                text: data.industryName
            };
        })
    }

    useEffect(() => {
        getSectorList();
        getStateList();
        getIndustryList();
        getReportUrl();
    }, [])


    useEffect(() => {
        getStartupSector({ industryId: industry }).then((data) => {
        })
    }, [industry])

    const handleInput = (e, type) => {
        let value = e.target.value;
        if (type === 'startDate') {
            setStartDate(value);
        } else if (type === 'endDate') {
            setEndDate(value);
        } else if (type === 'corStatus') {
            SetCorStatus(value);
        } else if (type === 'coeStatus') {
            SetCoeStatus(value);
        } else if (type === 'state') {
            SetState(value);
        } else if (type === 'sector') {
            SetSector(value);
        } else if (type === 'stage') {
            SetStage(value);
        } else if (type === 'gender') {
            SetGender(value);
        } else if (type === 'industry') {
            SetIndustry(value);
        }
    }

    function downloadStartupDetail() {

        if (dowloadReport) {

            // "endDate": "2020-06-06T17:01:25.190Z",
            // "gender": "MALE",
            // "startDate": "2020-01-01T17:01:25.190Z",
            const headers = selectedFields.reduce((arr, option) => {
                if (option['dataField']) {
                    arr.push(option['dataField']);
                }
                return arr;
            }, []);

            let query = {                
                "endDate": endDate,
                "gender": gender,
                "startDate": startDate,
                "stage": stage,
                "recognitionCertificateStatus": corStatus,
                "eligibilityCertificateStatus": coeStatus,
                "stateId": state,
                "startupSectionId": sector,
                "startupIndustryId": industry,
                headers,
            }

            for (var propName in query) {
                if (query[propName] === null || query[propName] === undefined || query[propName] === '') {
                    delete query[propName];
                }
            }

            return dowloadReport({ query }).then((response) => {

                const { data } = response || [];
                return data;
            })
        }
    }
    function GenerateStartupDetail() {

        if(generateReport ) {

            // "endDate": "2020-06-06T17:01:25.190Z",
            // "gender": "MALE",
            // "startDate": "2020-01-01T17:01:25.190Z",
            const headers = selectedFields.reduce((arr, option) => {
                if (option['dataField']) {
                    arr.push(option['dataField']);
                }
                return arr;
            }, []);

            let query = {                
                "endDate": endDate,
                "gender": gender,
                "startDate": startDate,
                "stage": stage,
                "recognitionCertificateStatus": corStatus,
                "eligibilityCertificateStatus": coeStatus,
                "stateId": state,
                "startupSectionId": sector,
                "startupIndustryId": industry,
                headers,
            }

            for (var propName in query) {
                if (query[propName] === null || query[propName] === undefined || query[propName] === '') {
                    delete query[propName];
                }
            }

            return generateReport({query}).then(response => {

                const { data } = response || [];
                if(data == 'Success') {
                    toast.success('Report Generation Started - Please Wait For Some Time To Download File.')
                }
            })
        }
    }
    const submitFormHandler = async (e) => {
        e.preventDefault();
    }

    const getReportUrl = async (e) => {
        await downloadExcelFile().then(res => {
            SetReportDetails(res.preSignedUrl)
        })
    }

    const downloadFIle = async (e) => {
        var a = document.createElement("a");
        const url = reportDetail.preSignedUrl;
        a.href = url;
        a.download = '_fileName';
        a.click();
        window.URL.revokeObjectURL(url);
        
    }
    const onSelect = (selectedList, selectedItem) => {
        setSelectedFields(selectedList);
    }
    const onRemove = (selectedList, removedItem) => {
        setSelectedFields(selectedList);
    }

    const refreshGenerateAPI = () => {
        getReportUrl()
    }

    return (
        <div className="startup-report">
            <SectionTitle>Startup Detail Report</SectionTitle>
            <div className="filter-form custom-form" >
                <div className="row">
                    <div className="col-md-12">
                        <h3>Startup Details Report</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="startdate col-md-3">
                        <InputField

                            type="date"
                            name="startDate"
                            label='From Date'
                            value={startDate}
                            min={endDate}
                            onChange={handleInput}
                            containerClass={"form-group"}
                            placeholder="Search"
                            max = {dateString}

                        />
                    </div>
                    <div className="endDate col-md-3">
                        <InputField

                            type="date"
                            name="endDate"
                            min={startDate}
                            label="To Date"
                            value={endDate}
                            onChange={handleInput}
                            containerClass={"form-group"}
                            placeholder="Search"
                            max = {dateString}

                        />
                    </div>
                    <div className="col-md-3">
                        <GlobalDropDown
                            label='COR Generated Status'
                            containerStyle="form-group "
                            selectTagStyle="form-control dropdown-media"
                            onChange={handleInput}
                            options={CorStatus}
                            value={corStatus}
                            name={'corStatus'}
                            error=""
                        />

                    </div>
                    <div className="col-md-3">
                        <GlobalDropDown
                            label='COE Generated Status'
                            containerStyle="form-group "
                            selectTagStyle="form-control dropdown-media"
                            onChange={handleInput}
                            options={CoeStatus}
                            value={coeStatus}
                            name={'coeStatus'}
                            error=""
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">
                        <GlobalDropDown
                            label='State	'
                            containerStyle="form-group "
                            selectTagStyle="form-control dropdown-media"
                            onChange={handleInput}
                            options={stateListFinalValue}
                            value={state}
                            name={'state'}
                            error=""
                        />
                    </div>
                    <div className="col-md-3">
                        <GlobalDropDown
                            label='Industry'
                            containerStyle="form-group "
                            selectTagStyle="form-control dropdown-media"
                            onChange={handleInput}
                            options={industryListFinalValue}
                            value={industry}
                            name={'industry'}
                            error=""
                        />
                    </div>
                    <div className="col-md-3">
                        <GlobalDropDown
                            label='Sector'
                            containerStyle="form-group "
                            selectTagStyle="form-control dropdown-media"
                            onChange={handleInput}
                            options={sectorListFinalValue}
                            value={sector}
                            name={'sector'}
                            error=""
                        />
                    </div>
                    <div className="col-md-3">
                        <GlobalDropDown
                            label='Stage'
                            containerStyle="form-group "
                            selectTagStyle="form-control dropdown-media"
                            onChange={handleInput}
                            options={Stage}
                            value={stage}
                            name={'stage'}
                            error=""
                        />
                    </div>
                    <div className="col-md-3">
                        <GlobalDropDown
                            label='Gender'
                            containerStyle="form-group "
                            selectTagStyle="form-control dropdown-media"
                            onChange={handleInput}
                            options={Gender}
                            value={gender}
                            name={'gender'}
                            error=""
                        />
                    </div>
                    <div className="col-md-9">
                        <label>Fields</label>
                        <Multiselect
                            options={DownloadField}
                            showCheckbox={true}
                            onSelect={onSelect} // Function will trigger on select event
                            onRemove={onRemove}
                            displayValue="text"
                            selectedValues={selectedFields}
                            closeOnSelect={false}
                        />
                    </div>
                </div>
                
                <div className="button-wrap d-flex align-items-start pt-20 pl-0 clearfix" style={{marginBottom: '20px'}}>
                    <Download
                        fileName={'Saved Applications Details'}
                        onClickDownload={downloadStartupDetail}
                        className="bttn"
                        alignLeft
                    /> 
                    {/* <button onClick={() => downloadFIle()} className='btn btn-info mr-4 p-2'>Download Report</button>      */}
                    {/* <button onClick={() => GenerateStartupDetail()} className='download-container btn btn-info ml-4'>Generate Report</button> */}
                                  
                </div>
                <span className='text-success' style={{ fontSize:'23px' }}><a href='javascript:void(0)' onClick={() => GenerateStartupDetail()}>Generate Report </a><code>(Click <a onClick={refreshGenerateAPI} style={{ cursor:'pointer'}}>Refresh</a> After Generating Report)</code><br /> Last Generated Report: { reportDetail.lastLinkGeneratedDate? moment(reportDetail.lastLinkGeneratedDate).format('YYYY-MM-DD, h:mm:ss a'):'Loading...'} <a href='javascript:void(0)' onClick={() => downloadFIle()} style={!reportDetail.lastLinkGeneratedDate? {pointerEvents: 'none',cursor: 'default',opacity: '0.6'}: {}}> Download Generated Report</a></span>
                <p>&nbsp;</p>
            </div >

            {/* </form> */}
        </div>
    )

}


const mapStateToProps = (state) => {

    return {
        StateList: state.StartupDetailReport.reportStateList,

        IndustryList: state.startupApplicationForm.startupIndustry,
        SectorList: state.startupApplicationForm.startupEntitySector,
    }
}

export default connect(mapStateToProps, { getStartupSector, getSectorList, getStateList, dowloadReport,generateReport,downloadExcelFile, getIndustryList })(withRouter(StartupDetailReport));