import {
    validateEmail,
    validateUsernameEmail,
    validateCaptcha,
    validateUserName,
    validateLoginPassword,
    validatePassword,
    validateConfirmPassword
} from '../../../../dependencies/utils/validation/Validation';

export default {
    validateForgetPasswordForm: (params) => {
        const { emailOrUsername, captcha } = params;
        let formStatus = {};
        const emailOrUsernameStatus = validateUsernameEmail(emailOrUsername);
        const captchaStatus = validateCaptcha(captcha);

        formStatus = {
            emailOrUsername: emailOrUsernameStatus.error,
            captcha: captchaStatus.error,
            hasErrors: (
                emailOrUsernameStatus.hasError
                // || captchaStatus.hasError
            )
        };
        return formStatus;
    },
    validateLoginForm: (params) => {
        const { username, password, captcha } = params;
        let formStatus = {};
        const usernameStatus = validateUserName(username);
        const passwordStatus = validateLoginPassword(password);
        // const captchaStatus = validateCaptcha(captcha);

        formStatus = {
            username: usernameStatus.error,
            password: passwordStatus.error,
            // captcha: captchaStatus.error,
            hasErrors: (
                usernameStatus.hasError
                || passwordStatus.hasError
                // || captchaStatus.hasError
            )
        };
        return formStatus;
    },
    validateResetPasswordForm: (params) => {
        const { newPassword, confirmPassword } = params;
        let formStatus = {};
        const newPasswordStatus = validatePassword(newPassword);
        const confirmPasswordStatus = validateConfirmPassword(confirmPassword, newPassword);

        formStatus = {
            confirmPassword: confirmPasswordStatus.error,
            newPassword: newPasswordStatus.error,
            hasErrors: (
                confirmPasswordStatus.hasError
                || newPasswordStatus.hasError
            )
        };
        return formStatus;
    }
};
