import { startupRecognitionReportService } from './service';

import { isLoading } from '../../../constants/actionConstants';
import { toast } from "react-toastify";


export function downloadIncompleteReport(params) {   

    return async function (dispatch) {
        dispatch(isLoading(true));
        let DownloadReport = await startupRecognitionReportService.downloadReport(params);

        dispatch(isLoading(false));
        if(DownloadReport === undefined){
            toast.error('Error')
        }else{
            return DownloadReport;
        }
        
    }

}