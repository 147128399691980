import React, { useState } from 'react';
import { FaArrowCircleUp, FaArrowCircleDown } from 'react-icons/fa';
import './scss/index.css';


const ScrollArrow = () => {

    const [showScroll, setShowScroll] = useState(false)
    const [showScrollTop, setShowScrollTop] = useState(true)
    const  userShowArrow = localStorage.getItem("invest_india_admin_user");
    const checkScrollTop = () => {
        if (showScrollTop && (window.innerHeight + window.scrollY + 15) >= document.body.offsetHeight) {
            
            setShowScrollTop(false);
        } else if (!showScrollTop && (window.innerHeight + window.scrollY + 15) <= document.body.offsetHeight) {
            setShowScrollTop(true);
        }

        if (!showScroll && window.pageYOffset > 100) {
            setShowScroll(true);
        } 
        else if (showScroll && window.pageYOffset <= 100) {
            setShowScroll(false);
        }
    };

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const scrollBottom = () => {
        window.scrollTo({ top: 10000, behavior: 'smooth' });
    };

    window.addEventListener('scroll', checkScrollTop)
    
    return (
        <div>
            {userShowArrow ? <FaArrowCircleUp className="scrollTop" onClick={scrollTop} style={{ height: 40, display: showScroll ? 'flex' : 'none' }} /> : null }

            {userShowArrow ? <FaArrowCircleDown className="scrollBottom" onClick={scrollBottom} style={{ height: 40, display: showScrollTop ? 'flex' : 'none' }} /> : null }
        </div>
    );
}

export default ScrollArrow;
