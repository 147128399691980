import api from '../utils/Api';
import { FETCH_STARTUP_APPLICATION_VIEW } from "../../constants/ApplicationUrl";
import { fetchStartupApplicationView } from "../../constants/actionConstants";
import resData from "../transformers/startupApplicationView.transformer"
import {  isLoading } from '../../constants/actionConstants';

export const getStartupApplicationView = ({sAppId}) => (dispatch) => {
    dispatch(isLoading(true));
    return api.GET(`${FETCH_STARTUP_APPLICATION_VIEW}/${sAppId}`)
        .then((data) => {
            if (data && data.data && data.data.data) {
             let getTransformerData =    resData(data.data.data);
                dispatch(fetchStartupApplicationView(getTransformerData));
            }
            dispatch(isLoading(false));

        })

};
