import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFetchTaxBenefitReport, downloadTaxBenefitReport } from '../dependencies/action';
import taxBenefitColumns from '../dependencies/columns/taxBenefitColumns';
import Table from '../../common/customTable';
import moment from 'moment';
import Download from '../../common/Download';
import SectionTitle from '../../common/sectionTitle/sectionTitle';
import '../dependencies/style/index.scss'


class TaxBenefitReport extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getFetchTaxBenefitReport();
    }

    downloadReport = () => {
        const { downloadTaxBenefitReport } = this.props;
        if (downloadTaxBenefitReport) {
            return downloadTaxBenefitReport().then((data) => {
                return data;
            })
        }
    }

    render() {
        const currentDate = moment(new Date()).format('YYYYMMDD')
        const { downloadReport } = this;
        const data = this.props.taxBenefitReportData && this.props.taxBenefitReportData;
        const info = data && data.data
        return (
            <div className="row summary-report-container">
                <div className="col-sm-12">
                    <SectionTitle breadCrumb title="Summary Report > Number of applications for Tax benefits">Number of applications for Tax benefits</SectionTitle>

                    <Download
                        fileName={`Report_for_Number_of_applications_where_IPR_and_Tax_benefits_${currentDate}`}
                        onClickDownload={downloadReport}
                        className="bttn-link"
                    />
                    <Table
                        dataTable={info}
                        columns={taxBenefitColumns()}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        taxBenefitReportData: state.ReportReducer.taxBenefitReport,
    }
}

export default connect(mapStateToProps, { getFetchTaxBenefitReport, downloadTaxBenefitReport })(withRouter(TaxBenefitReport));