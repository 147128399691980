import React, { useEffect, useState } from 'react';
import SectionTitle from '../../common/sectionTitle/sectionTitle';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { getIncompleteHistory } from '../dependencies/action';
import Modal from '../../common/Modal';
import columns from '../dependencies/columns/historyColumns';
import Table from '../../common/Table'

const IncompleteHistory = (props) => {

    const {
        exemptionId,
        getIncompleteHistory,
    } = props;

    const [list, setList] = useState([]);
    
    useEffect(() => {
        if (exemptionId) {
            getIncompleteHistory(exemptionId).then((response) => {
                const { data, status } = response;
                if (status && data) {
                    setList(data);
                }
            })
        }
    }, []);


    return (
        <div>
            <SectionTitle>Startup India : Incomplete Reasons History</SectionTitle>
            <Table
                dataTable={list}
                columns={columns}
                keyField='incompleteDate'
                cssClass='startupLogs'
            />
        </div>


    )
}
export default connect(null, { getIncompleteHistory })(withRouter(Modal(IncompleteHistory)))
