import api from '../utils/Api';
import { GET_USER_DETAILS,GET80IACUSERDETAILS } from "../../constants/ApplicationUrl";
import { getUserDetails, isLoading } from "../../constants/actionConstants";

export const getUserDetailsValue = ({ userId }) => (dispatch) => {
    dispatch(isLoading(true));
    return api.GET(`${GET_USER_DETAILS}/${userId}`)
        .then((data) => {
            if (data && data.data && data.data.data) {
                dispatch(getUserDetails(data.data.data));
            }
            dispatch(isLoading(false));

        })

};
export const get80IACUserList = (params) => (dispatch) => {
    // dispatch(isLoading(true));
   // return api.GET(`${GET80IACUSERDETAILS}/${params.role}/${params.userId}/${params.section}`)
    return api.GET(`${GET80IACUSERDETAILS}/${params.role}/${params.userId}`)
}
