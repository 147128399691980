import React from 'react';
import './scss/index.scss';

const entityDataRow = (props) => {
    return (
        <div className={`entity-row clearfix ${props.className}`} >
            <div className="entity-title">
                <h6>{props.title}</h6>
            </div>
            <div className="entity-content">
                <p className="clearfix"><span>:&nbsp;&nbsp;</span><span>{props.description}</span></p>
            </div>
        </div>
    )
}
export default entityDataRow;