import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { logout } from '../../Auth/common/dependencies/action'

const Logout = (props) => {
    const { history, logout } = props;

    useEffect(() => {
        if (logout) {
            logout().then(() => {
                history.push('/auth/login')
            })
        }
    }, [])
    return (
        <div></div>
    )

}


export default connect(null, { logout })(withRouter(Logout));

