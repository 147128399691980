import moment from 'moment';
import React from 'react';
import { API_URL } from '../../../constants/ApplicationUrl';
// import moment from 'moment'

const columns = [
    {
        dataField: 'id',
        text: 'Sr.No ',
        formatter: (cell, row, rowIndex) => {
            return rowIndex + 1
        }
    },{
        // const datte = 'clarificationDate',
        // dataField: moment('clarificationDate').format('DD-MM-YYYY'),
        dataField:'clarificationDate',
        text: 'Clarification Date',
       
    }, {
        dataField: 'clarification',
        text: 'Clarification Document',
        formatter: (cell, row) => {
            if (cell && cell.publicUrl) {
                return (
                    <a href={`${API_URL()}${cell.publicUrl}`} target="_blank">View Document </a>
                   
                )
            }

        }
    }
];


export default columns;