import get from 'lodash/get'
import api from '../utils/Api';
import {
  UPLOAD_FILE,UPLOAD_FILE_CSV
} from "../../constants/ApplicationUrl";

export const uploadFileService = {
  uploadFile(params) {
    return api.POSTDATA(UPLOAD_FILE, params).
      then((response) => {

        const { data, status } = get(response, 'data') || {};
        if (status) {
          return (data);
        } else {
          return Promise.reject();
        }
      }).catch(e => {
        return Promise.reject(e);
      });
  },
  uploadFileBtn(params) {
    return api.POSTDATA(UPLOAD_FILE_CSV, params).then((response) => {
      const {data, status} = get(response, 'data') || {};
      if(status) {
        return (data);
      }else {
        return Promise.reject();
      }
    }).catch(e => {
      return Promise.reject(e);
    });
  },
}
