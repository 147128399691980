import React, { Component, useEffect, useState } from "react";
import { toast } from "react-toastify";
import './viewProfile.scss';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import SectionTitle from '../../common/sectionTitle/sectionTitle';
import moment from 'moment';
import editIcon from '../../../assests/images/edit-icon-blue.png';

const ViewProfile = (props) => {
    const { userDetails: { emailId, username, name, phoneNumber, dob, designation } = {}, } = props;
    const [formattedDob, setDOB] = useState("")
    useEffect(() => {
        const newDob = moment(dob).format('YYYY-MM-DD');
        setDOB(newDob)
    }, [dob])

    return (
        <div className="view-profile-wrap">
            <SectionTitle>View Profile</SectionTitle>
            <div className="view-profile-inner">
                <div className="row">
                    <div className="col">
                        <label>User ID</label>
                        <span>
                            {username}
                        </span>
                    </div>
                    <div className="col">
                        <label>Name</label>
                        <span>
                            {name}
                        </span>
                    </div>
                    <div className="col">
                        <label>Phone Number</label>
                        <span>
                            {phoneNumber}
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Email ID</label>
                        <span>
                            {emailId}
                        </span>
                    </div>
                    <div className="col">
                        <label>Designation</label>
                        <span>
                            {designation}
                        </span>
                    </div>
                    <div className="col">
                        <label>Date of Birth</label>
                        <span>
                            {formattedDob}
                        </span>
                    </div>
                </div>
                <Link className="edit-profile" to='/edit-profile' ><img width='16' src={editIcon} />&nbsp;Edit Profile</Link>
            </div>
        </div>
    )

}
const mapStateToProps = (state) => {
    return {
        userDetails: state.loginReducer.user,
    }
}

export default connect(mapStateToProps, null)(withRouter(ViewProfile));