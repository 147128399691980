import api from '../utils/Api';
import {
  FETCH_NSA_REPORT_LIST,SAVE_NSA_RESULT_DATA,FETCH_STARTUP_APPLICATION_LIST
} from "../../constants/ApplicationUrl";
import { fetchNSAReport,saveNsaResult } from "../../constants/actionConstants";
import { isLoading } from '../../constants/actionConstants';


export const getNSAReportList = ({ sortColumn, sortOrder, pageSize, pageIndex, query }) => (dispatch) => {
    dispatch(isLoading(true));
    return api.POST(FETCH_NSA_REPORT_LIST, { sortColumn, sortOrder, pageSize, pageIndex, query })
      .then((data) => {
        if (data && data.data) {
          dispatch(fetchNSAReport(data.data));
        }
        dispatch(isLoading(false));
      })
  }

  export const saveNsaResultData = ({ dippNumber,result }) => (dispatch) => {
    dispatch(isLoading(true));
    return api.GET(`${SAVE_NSA_RESULT_DATA}/${dippNumber}/${result}`)
      .then((data) => {
        dispatch(isLoading(false));
        if (data && data.data && data.data.status) {
          dispatch(saveNsaResult(data.data));
          return data.data
        }
        else{
          dispatch(saveNsaResult(data.data));
          return data.data
        }
      
      })
  }

  export const doDownloadNSAResultList = (params) => (dispatch) => {
    dispatch(isLoading(true));
    return api.DOWNLOADDATA(FETCH_STARTUP_APPLICATION_LIST, params)
      .then((response) => {
        const { data = "" } = response;
        dispatch(isLoading(false));
        return data;
      }).catch(e => {
        dispatch(isLoading(false))
        return "";
      })
  }


