export const CorStatus = [
    {
        value: 'PENDING',
        text: "In Process"
    },
    {
        value: 'RECOGNISED',
        text: "Recognised by Dipp"
    },
    {
        value: 'REJECT',
        text: "Rejected"
    },
    {
        value: 'EXPIRED',
        text: "Expired"
    },
    {
        value: 'CANCELLED',
        text: "Canceled"
    },

]

export const Stage = [
    {
        value: 'IDEATION',
        text: "Ideation"
    },
    {
        value: 'VALIDATION',
        text: "Validation"
    },
    {
        value: 'EARLY_TRACTION',
        text: "Early Traction"
    },
    {
        value: 'SCALING',
        text: "Scaling"
    }
]

export const Gender = [
    {
        value: 'MALE',
        text: 'Male'
    },
    {
        value: 'FEMALE',
        text: 'Female'
    },
    {
        value: 'OTHER',
        text: 'Other'
    }
]


export const CoeStatus = [
    {
        value: 'PENDING',
        text: "In Process"
    },
    {
        value: 'APPROVED',
        text: "Approved"
    },
    {
        value: 'REJECT',
        text: "Rejected"
    },
    {
        value: 'EXPIRED',
        text: "Expired"
    },
    {
        value: 'CANCELLED',
        text: "Canceled"
    },

]

export const DownloadField = [
    {
        text: "Incorporation No",
        dataField: "Incorporation No",
    },
    {
        text: "Incorporation Date",
        dataField: "Incorporation Date",
    },
    {
        text: "Reference Number",
        dataField: "Reference Number",
    },
    {
        text: "Recognition Number",
        dataField: "Recognition Number",
    },
    {
        text: "Nature of Entity",
        dataField: "Nature of Entity",
    },
    {
        text: "Entity Name",
        dataField: "Entity Name",
    },
    {
        text: "Industry",
        dataField: "Industry",
    },
    {
        text: "Sector",
        dataField: "Sector",
    },
    {
        text: "Categories",
        dataField: "Categories",
    },
    {
        text: "PAN Number",
        dataField: "PAN Number",
    },
    {
        text: "Office Address",
        dataField: "Office Address",
    },
    {
        text: "Address 2",
        dataField: "Address 2",
    },
    {
        text: "Address 3",
        dataField: "Address 3",
    },
    {
        text: "City",
        dataField: "City",
    },
    {
        text: "State",
        dataField: "State",
    },
    {
        text: "District",
        dataField: "District",
    },
    {
        text: "AdminDistrict",
        dataField: "AdminDistrict"
    },
    {
        text: "Sub District",
        dataField: "Sub District",
    },
    {
        text: "Pin Code",
        dataField: "Pin Code",
    },
    {
        text: "Representative Name",
        dataField: "Representative Name",
    },
    {
        text: "Designation",
        dataField: "Designation",
    },
    {
        text: "Representative Mobile",
        dataField: "Representative Mobile",
    },
    {
        text: "Representative Email",
        dataField: "Representative Email",
    },
    {
        text: "No Of Directors",
        dataField: "No Of Directors",
    },
    {
        text: "Recommendation Type",
        dataField: "Recommendation Type",
    },
    {
        text: "Current no. of employees",
        dataField: "Current no. of employees",
    },
    {
        text: "Current stage",
        dataField: "Current stage",
    },
    {
        text: "Applied for IPR",
        dataField: "Applied for IPR",
    },
    {
        text: "Patent",
        dataField: "Patent",
    },
    {
        text: "Trademark",
        dataField: "Trademark",
    },
    {
        text: "Copyright",
        dataField: "Copyright",
    },
    {
        text: "Design",
        dataField: "Design",
    },
    {
        text: "Plant Variety",
        dataField: "Plant_variety",
    },
    {
        text: "Creating innovative product/service",
        dataField: "Creating innovative product/service",
    },
    {
        text: "Product",
        dataField: "Product",
    },
    {
        text: "Service",
        dataField: "Service",
    },
    {
        text: "Process",
        dataField: "Process",
    },
    {
        text: "Creating scalable business model",
        dataField: "Creating scalable business model",
    },
    {
        text: "Brief about the startup",
        dataField: "Brief about the startup",
    },
    {
        text: "Tax Benefits",
        dataField: "Tax Benefits",
    },
    {
        text: "Funding",
        dataField: "Funding",
    },
    {
        text: "Awards",
        dataField: "Awards",
    },
    {
        text: "Problem Solving",
        dataField: "Problem Solving",
    },
    {
        text: "Proposed Solution",
        dataField: "Proposed Solution",
    },
    {
        text: "Uniqueness of Solution",
        dataField: "Uniqueness of Solution",
    },
    {
        text: "Revenue",
        dataField: "Revenue",
    },
    {
        text: "Registration Date",
        dataField: "Registration Date",
    },
    {
        text: "Application Status",
        dataField: "Application Status",
    },
    {
        text: "Certificate Date",
        dataField: "Certificate Date",
    },
    {
        text: "Rejected Date",
        dataField: "Rejected Date",
    },
    {
        text: "Recognition Certificate Status",
        dataField: "Recognition Certificate Status",
    },
    {
        text: "Eligibility Certificate Status",
        dataField: "Eligibility Certificate Status",
    },
    {
        text: "Eligibility Certificate Date",
        dataField: "Eligibility Certificate Date",
    },
    {
        text: "No. of chances given to Startup",
        dataField: "No. of chances given to Startup",
    },
    {
        text: "Directors",
        dataField: "Directors"
    }
    

];