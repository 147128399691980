import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';


const columns =({onEdit}) =>{

return [
    {
        dataField: 'id',
        text: 'Sr.No',
        formatter: (cell, row, rowIndex) => {
            return rowIndex + 1
        }
    },
    {
        dataField: 'subDistrictName',
        text: 'Sub-District Name'
    },
    {
        dataField: '',
        text: 'Action',
        formatter: (cell, row, rowIndex) => {
            return <div className='edit-color'>
                <a onClick={()=> {onEdit(row.id)}}>Edit</a>
            </div>
        }
    }
];
}

export default columns;
