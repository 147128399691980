import React, { useState, useEffect } from 'react';
import get from 'lodash/get'
import './index.scss'

const Table = (props) => {
  const { columns = [], dataTable } = props;

  const [tableColumn, setTableColumn] = useState([]);
  let serialNo = 0;
  useEffect(() => {
    let totalColumn = [];
    columns.forEach(element => {
      if (element.nestedColumn) {
        totalColumn = [...totalColumn, ...element.nestedColumn];
      } else {
        totalColumn.push(element)
      }
    });
    setTableColumn(totalColumn);
  }, [])


  const getRows = (row, rowIndex) => {
    if (tableColumn && tableColumn instanceof Array) {
      return (
        tableColumn.map((col, index) => {
          if (col && col.rowIndex && row && !row[col.showIndexField]) {

          } else if (col && col.rowIndex) {
            serialNo += 1;
          }
          return (
            col.rowIndex ? <td className={col.className ? col.className : ''}>
              {row && !row[col.showIndexField] ? '' : serialNo}
            </td> :
              <td className={col.className ? col.className : ''}> {get(row, col.dataField)}</td>
          )
        })

      )
    }
  }

  return (
    <div className="react-bootstrap-table table-responsive custom-table-container">
      <table className="table table-striped table-bordered">
        <thead>
          <tr>

            {

              columns && columns instanceof Array &&
              columns.map((val, index) => {
                return (
                  <th colSpan={val.nestedColumn ? val.nestedColumn.length : 1} rowSpan={val.nestedColumn ? 1 : 2} key={index}>
                    {val.text}
                    {val.showTitle && <span className="title-icon" title={val.titleText}></span>}
                  </th>
                )
              })
            }
          </tr>
          <tr>

            {
              columns && columns instanceof Array &&
              columns.map((val) => {
                if (val && val.nestedColumn) {
                  return (
                    val.nestedColumn && val.nestedColumn instanceof Array && val.nestedColumn.map((col, index) => {
                      return (
                        <th scope='col' key={index}>{col.text}</th>
                      )
                    })
                  )
                }
              })
            }
          </tr>
        </thead>
        {tableColumn && (<tbody>
          {
            dataTable && dataTable instanceof Array &&
            dataTable.map((row, index) => {
              return (
                <tr key={index}>
                  {
                    getRows(row, index)
                  }
                </tr>
              )
            })
          }
        </tbody>)}
      </table>
    </div>
  )
}

export default Table;