import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getCountry } from '../dependencies/action';
import { connect } from 'react-redux';
import SectionTitle from '../../common/sectionTitle/sectionTitle';
import Table from '../../common/Table';
import column from '../dependencies/columns/country';
import '../style.scss';

const Country = (props) => {
    const { getCountry } = props;

    const [countryList, setCountryList] = useState([]);

    useEffect(() => {
        if (getCountry) {
            getCountry().then((repsonse) => {
                const { status, data } = repsonse;
                if (status) {
                    setCountryList(data);
                }
            });
        }
    }, [])

    return (
        <>
            <div className="country-container">
                <h2 className="mb-20">{"Country"}
                    <a href="/update/country/form" className="bttn blue normal add-btn">
                        {"Add Country"}
                    </a>
                </h2>

                <Table
                    dataTable={countryList}
                    columns={column}
                    keyField={'id'}
                    sizePerPage={1000}
                />
            </div>
        </>
    )
}

export default connect(null, { getCountry })(Country);