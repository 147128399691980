import React from 'react';
import Radio from '../../../common/InputRadio';
import { cloneDeep } from 'lodash';


const workCategoriesColumn = (params) => {

    const { onChange } = params;
    const handleInputChange = (e, flag, index, row) => {
        const editColumn = { ...row };
        const checked = e.target && e.target.checked;
        if (flag === "innovative") {
            editColumn['innovative'] = checked;
            editColumn['improvement'] = !checked
        } else {
            editColumn['innovative'] = !checked;
            editColumn['improvement'] = checked
        }

        if (onChange) {
            onChange(editColumn, index)
        }
    }
    return [
        {
            dataField: 'workNature',
            text: 'Title',
        },
        {
            dataField: 'innovative',
            text: 'Innovative',
            formatter: (cell, row, rowIndex) => {
                return (
                    <input
                        type="radio"
                        name={`innovative_${rowIndex}`}
                        onChange={e => handleInputChange(e, `innovative`, rowIndex, row)}
                        checked={cell}
                    />
                )
            }
        }, {
            dataField: 'improvement',
            text: 'Improvement',
            formatter: (cell, row, rowIndex) => {
                return (
                    <input
                        name={`innovative_${rowIndex}`}
                        type="radio"
                        onChange={e => handleInputChange(e, `improvement`, rowIndex, row)}
                        checked={cell}
                    />
                )
            }
        }
    ];
}

export default workCategoriesColumn;