import React, { Component, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import './editProfile.scss';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import AlertMessage from '../../common/AlertMessage';
import { AlertType } from '../../../constants';
import { editProfile } from '../common/dependencies/action';
import InputField from '../../common/InputField';
import FormValidator from '../common/dependencies/FormValidator';
import LoadingAnimation from '../../common/loader/loadingAnimation'
import Button from '../../common/Button';
import moment from 'moment';
import refreshIconwhite from '../../../assests/images/refresh-icon-white.png';
import SectionTitle from '../../common/sectionTitle/sectionTitle';
import { ROLES } from '../../../constants/Roles'
import { storage } from '../../../dependencies/store/storage';



const EditUser = (props) => {
  const { editProfile, userDetails = {}, history } = props;
  const defaultFormData = {
    emailId: '',
    username: '',
    name: '',
    phoneNumber: '',
    dob: ''
  }
  const userRole = storage.getUserRole();
  const [formData, setFormData] = useState(defaultFormData);
  const [error, setError] = useState({});
  const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  const validateInput = () => {
    const {
      emailId,
      username,
      name,
      phoneNumber,
      dob
    } = formData;
    const formStatus = FormValidator.validateEditProfile({
      emailId,
      username,
      name,
      phoneNumber,
      dob
    });
    const errors = {
      username: formStatus.username,
      emailId: formStatus.emailId,
      name: formStatus.name,
      phoneNumber: formStatus.phoneNumber,
      dob: formStatus.dob
    };
    setError(errors);
    return !formStatus.hasErrors;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!validateInput()) {
      setHasInvalidSubmitOccurred(true)
    } else {
      editProfile(
        { ...formData }
      ).then((data) => {
        const { status = false, message } = data || {};
        if (status) {
          history.push('/user-management/viewprofile', { message: 'User profile updated successfully' })
        } else if (!status) {
          if (message) {
            setAlertMessage(message);
          } else {
            setAlertMessage('Internal Server Error');
          }
          setAlertType(AlertType.DANGER);
        }
      })
    }
  }
  const setUserProfile = () => {
    const user = { ...userDetails };
    user.dob = moment(user.dob).format('YYYY-MM-DD');
    setFormData(u => {
      return { ...u, ...user }
    })
  }
  const handleInputChange = (e, name) => {
    const { value, } = e.target;
    setFormData((user) => {
      return { ...user, [name]: value }
    });
  }

  useEffect(() => {
    if (userDetails && !isEmpty(userDetails)) {
      setUserProfile();
    }
  }, [userDetails])

  const onBlur = (e, name) => {
    // validateInput();
  }

  const onClose = () => {
    setAlertMessage('')
  }

  const doReset = () => {
    if (userDetails) {
      setUserProfile();
    }
    setError({})
  }

  return (
    <div className="edit-user-wrap">
      <LoadingAnimation />
      <SectionTitle>Edit Profile</SectionTitle>
      <AlertMessage
        message={alertMessage}
        type={alertType}
        allowClose={true}
        onClose={onClose}
      />
      <form onSubmit={e => onSubmit(e)}>
        <div className="row">
          <div className='col-sm-4'>
            <InputField
              readonly='readonly'
              type="text"
              containerClass={"form-group"}
              name="username"
              label="User Id"
              value={formData.username}
              disabled={true}
            />
          </div>
          <div className='col-sm-4'>
            <InputField
              required
              type="text"
              readonly={ROLES.ADMIN === userRole ? 'readonly' : ''}
              name="emailId"
              label="Email Id"
              value={formData.emailId}
              onChange={handleInputChange}
              error={error.emailId}
              containerClass={"form-group"}
              onBlur={onBlur}
              hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
            />
          </div>
        </div>
        <div className="row">
          <div className='col-sm-4'>
            <InputField
              required
              type="text"
              name="name"
              label="Name"
              value={formData.name}
              onChange={handleInputChange}
              error={error.name}
              containerClass={"form-group"}
              onBlur={onBlur}
              hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
            />
          </div>
          <div className='col-sm-4'>
            <InputField
              required
              type="text"
              name="phoneNumber"
              label="Phone Number"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              error={error.phoneNumber}
              containerClass={"form-group"}
              onBlur={onBlur}
              hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
            />
          </div>
        </div>
        <div className="row">
          <div className='col-sm-4'>
            <InputField
              required
              type="date"
              name="dob"
              label="DOB"
              value={formData.dob}
              onChange={handleInputChange}
              error={error.dob}
              containerClass={"form-group"}
              onBlur={onBlur}
              hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
            />
          </div>
        </div>
        <div className="bttn-group">
          <Button
            type="submit"
            text="Submit"
            className='bttn'
          />
          <Button
            className="bttn blue"
            type='button'
            onClick={doReset}
            text="Reset"
            icon={(<img src={refreshIconwhite} />)}
          />
          <Link className='bttn-link' to='/user-management/viewprofile'>Cancel</Link>
        </div>
      </form>
    </div >
  )

}

EditUser.propTypes = {
  editProfile: PropTypes.func,
}
EditUser.defaultProps = {
}



const mapStateToProps = (state) => {
  return {
    userDetails: state.loginReducer.user,
  }
}

export default connect(mapStateToProps, { editProfile })(withRouter(EditUser));