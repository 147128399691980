import api from '../../../dependencies/utils/Api';
import {
    FETCH_NOTIFICATION_DOCUMENTS_LIST_URL,
    ADD_NOTIFICATION_DOCUMENT_URL,
    EDIT_NOTIFICATION_DOCUMENT_URL,
    DELETE_NOTIFICATION_DOCUMENT_URL
} from "../../../constants/ApplicationUrl";



export const NotificationDucumentsServices = {


    fetchList(page, pageSize) {
        return api.GET(`${FETCH_NOTIFICATION_DOCUMENTS_LIST_URL}?page=${page}&pageSize=${pageSize}`).
            then((response) => {
                const { data = {}, status } = response;
                return { data, status };
            }).catch(e => {
                return Promise.reject(e);
            });;
    },

    fetchListByID(params) {
        return api.GET(`${FETCH_NOTIFICATION_DOCUMENTS_LIST_URL}/${params}`).
            then((response) => {
                const { data = {}, status } = response;
                return { data, status };
            });;
    },

    addNotificationDocumentService(params) {
        return api.POST(ADD_NOTIFICATION_DOCUMENT_URL, params).
            then((response) => {
                const { data: { status, message } } = response;
                return { status, message };
            });;
    },


    editNotificationDocumentService(id, params) {
        return api.POST(`${EDIT_NOTIFICATION_DOCUMENT_URL}/${id}`, params).
            then((response) => {
                const { data: { status, message } } = response;
                return { status, message };
            });;
    },

    deleteNotificationDocumentService(id) {
        return api.POST(`${DELETE_NOTIFICATION_DOCUMENT_URL}/${id}`).
            then((response) => {
                const { data: { data, status, message } } = response;
                return { data, status, message };
            }).catch(e => {
                return Promise.reject(e);
            });;
    },

}