import React, { useEffect, useState } from 'react';
import { get, isEmpty, cloneDeep } from 'lodash';
import { API_URL } from '../../../../../../constants/ApplicationUrl'
import InputField from '../../../../../common/InputField';
import InlineError from '../../../../../common/inlineError'
import Radio from '../../../../../common/InputRadio';
import TextareaField from '../../../../../common/TextareaField';
import { STAGE } from '../../../../dependencies/constant';
import Table from '../../../../../common/Table';
import IPRSColumns from '../../../../dependencies/columns/iprsColumns';
import WorkCategoriesColumn from '../../../../dependencies/columns/workCategory';
import { uploadFileService } from '../../../../../../dependencies/services/uploadFileService';
import FileUpload from '../../../../../common/FileUpload';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { unEscapeRegHtml } from '../../../../../../dependencies/utils/helper';
import Button from '../../../../../common/Button'

const AdditionalInformation = (props) => {
  const {
    additionalInformation,
    onChangeHandler,
    error = [],
    isLoading,
    doReset,
    doSubmit
  } = props;

  const [formData, setFormData] = useState({});
  const [businessModel, setBusinessModel] = useState({});
  const [iprsArray, setIprsArray] = useState([]);

  useEffect(() => {
    if (!isEmpty(additionalInformation)) {
      setFormData(data => {
        return { ...data, ...additionalInformation }
      })

      if (additionalInformation && additionalInformation.businessModel) {
        setBusinessModel(data => {
          return { ...data, ...additionalInformation.businessModel }
        })
      }
    }
  }, [additionalInformation]);

  useEffect(() => {
    onChangeHandler({
      additionalInformation: { ...formData }
    })
    // debugger
    if (formData && formData.iprs) {
      const arr = [];
      formData.iprs.forEach((element, index) => {
        const item = { ...element, error: error && error.iprs && error.iprs[index] && error.iprs[index].applicationNumber || "" }
        arr.push(item);
      });
      setIprsArray(arr)
    }
  }, [formData]);

  const handleInputChange = (e, name) => {

    if (e && e.target) {
      const value = e.target.value;

      const value1 = value === 'true' ? true :
        value === 'false' ? false :
          value;

      setFormData(data => {
        return { ...data, [name]: value1 }
      })
    }
  }

  useEffect(() => {
    setFormData(data => {
      return { ...data, businessModel: businessModel }
    })
  }, [businessModel])

  const handleChangeBusinessModel = (e, name) => {
    if (e && e.target) {
      let value = "";
      if (e.target.hasOwnProperty('checked')) {
        value = e.target.checked;
      } else {
        value = e.target.value;
      }
      setBusinessModel(data => {
        return { ...data, [name]: value }
      })
    }
  }

  const onUploadFile = (file) => {
    isLoading(true)
    const data = new FormData();
    data.append('file', file)
    data.append('documentType', 'FUNDING_PROOF');
    uploadFileService.uploadFile(data).then((data) => {
      if (data) {
        setFormData(d => {
          return { ...d, fundingProof: data };
        });
      }
      isLoading(false)
    }, (error) => {
      isLoading(false);
    });
  }

  const onChangeWorkCategories = (row, index) => {
    if (formData && formData.workCategories && formData.workCategories.length > 0) {
      let editArray = [...formData.workCategories];
      editArray[index].innovative = row.innovative;
      editArray[index].improvement = row.improvement;

      setFormData(data => {
        return { ...data, workCategories: editArray }
      })
    }
  }
  const onChangeIPRS = (row, index) => {
    if (formData && formData.iprs && formData.iprs.length > 0) {
      const editArray = [...formData.iprs];
      editArray[index]['applied'] = row["applied"];
      editArray[index]['registered'] = row["registered"];
      editArray[index]['applicationNumber'] = row["applicationNumber"];
      setFormData(data => {
        return { ...data, iprs: editArray }
      })
    }
  }

  // blur event  on iprs application no. 
  const afterSaveCell = (oldValue, newValue, row, column) => {
    const iprsarray = cloneDeep(formData.iprs)
    iprsarray.forEach(val => {
      if (val.iprTitle === row.iprTitle) {
        val.applicationNumber = newValue;
      }
    })
    setFormData(d => {
      return { ...d, iprs: iprsarray }
    })
  };

  useEffect(() => {
    if (formData && formData.iprs) {
      const arr = [];
      formData.iprs.forEach((element, index) => {
        const err = error.iprs && error.iprs[index] && error.iprs[index].applicationNumber || "";
        const item = { ...element, error: err }
        arr.push(item);
      });
      setIprsArray(arr)
    }
  }, [error])

  return (
    <div>
      <div className="row mb-20">
        <div className="col-sm-6">
          <InputField
            label='Current Number of Employees (Including Founders)'
            required
            type='text'
            name="numberOfEmployees"
            value={formData.numberOfEmployees}
            onChange={handleInputChange}
            error={error.numberOfEmployees}
            containerClass={"form-group"}
            hasInvalidSubmitOccurred={true}
          />
        </div>
      </div>
      <div className="row mb-20">
        <div className="col-12">
          <label>What is the current stage of your products/services</label>

          <div className="row no-gutter">
            <div className="col-sm-3">
              <Radio
                type="radio"
                label='Ideation'
                value={STAGE.IDEATION}
                name='stage'
                checked={formData.stage == STAGE.IDEATION}
                onChange={handleInputChange}
                hasInvalidSubmitOccurred={true}
                error={error.stage}
              />
            </div>
            <div className="col-sm-3">
              <Radio
                type="radio"
                label='Validation'
                value={STAGE.VALIDATION}
                name='stage'
                checked={formData.stage == STAGE.VALIDATION}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-sm-3">
              <Radio
                type="radio"
                label='Early Traction'
                value={STAGE.EARLY_TRACTION}
                name='stage'
                checked={formData.stage == STAGE.EARLY_TRACTION}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-sm-3">
              <Radio
                type="radio"
                label='Scaling'
                name='stage'
                value={STAGE.SCALING}
                checked={formData.stage == STAGE.SCALING}
                onChange={handleInputChange}

              />
            </div>
            {
              error.stage && (
                <InlineError message={error.stage} />
              )
            }
          </div>
        </div>
      </div>
      <div className="row mb-20">
        <div className="col-12">
          <label>Has your startup applied for any IPR (Intellectual Property Right) ?</label>
          <div className="row no-gutter">
            <div className="col-sm-3">
              <Radio
                label='Yes'
                name='ipr'
                value={true}
                onChange={handleInputChange}
                checked={formData.ipr == true}
                hasInvalidSubmitOccurred={true}
                error={error.ipr}
              />
            </div>
            <div className="col-sm-3">
              <Radio
                label='No'
                name='ipr'
                value={false}
                onChange={handleInputChange}
                checked={formData.ipr == false}

              />
            </div>
            {
              error.ipr && (
                <InlineError message={error.ipr} />
              )
            }
          </div>
        </div>
        {
          formData.ipr
          && iprsArray
          && iprsArray.length > 0
          &&
          (<div className='col-12 table-container '>
            <div className='dependent-table'>
              <BootstrapTable
                // table-responsive
                wrapperClasses="table-responsive"
                data={iprsArray}
                columns={IPRSColumns({
                  onChange: onChangeIPRS,
                  error: error.iprs || []
                })}
                keyField={'iprTitle'}
                // cellEdit={cellEditFactory({
                //   mode: 'click',
                //   afterSaveCell: afterSaveCell,
                //   blurToSave: true
                // })}
                classes="table"
                striped
                table-bordered
                hover
              />
            </div>

          </div>)
        }
      </div>
      <div className="row mb-20">
        <div className="col-12">
          <label>Is the startup creating an innovative product / service / process or improving an existing product / service / process</label>
          <div className="row no-gutter">
            <div className="col-sm-3">
              <Radio
                label='Yes'
                name='workCategory'
                value={true}
                onChange={handleInputChange}
                checked={formData.workCategory === true}
                hasInvalidSubmitOccurred={true}
                error={error.workCategory}
              />
            </div>
            <div className="col-sm-3">
              <Radio
                label='No'
                name='workCategory'
                value={false}
                onChange={handleInputChange}
                checked={formData.workCategory === false}

              />
            </div>
            {
              error.workCategory && (
                <InlineError message={error.workCategory} />
              )
            }
          </div>

        </div>
        {
          formData.workCategory &&
          <div className='col-12 table-container '>

            <div className='dependent-table'>
              <Table
                dataTable={formData.workCategories}
                columns={WorkCategoriesColumn({
                  onChange: onChangeWorkCategories
                })}
                keyField={'workNature'}
              />
            </div>

          </div>
        }
      </div>
      <div className="row mb-20">
        <div className="col-12">
          <label>Is the startup creating a scalable business model with high potential of employment generation or wealth creation?</label>
          <div className="row no-gutter">
            <div className="col-sm-3">
              <Radio
                label='Yes'
                name='scalableBusinessModel'
                value={true}
                onChange={handleInputChange}
                checked={formData.scalableBusinessModel === true}
                hasInvalidSubmitOccurred={true}
                error={error.scalableBusinessModel}
              />
            </div>
            <div className="col-sm-3">
              <Radio
                label='No'
                name='scalableBusinessModel'
                value={false}
                onChange={handleInputChange}
                checked={formData.scalableBusinessModel === false}

              />
            </div>
            {
              error.scalableBusinessModel && (
                <InlineError message={error.scalableBusinessModel} />
              )
            }
          </div>
        </div>
      </div>
      {formData.scalableBusinessModel
        && (
          <div className="row mb-20">
            <div className="col-12">
              <label>Employment Generation</label>

              <div className="row no-gutter">
                <div className="col">
                  <input
                    type="checkbox"
                    name={'employmentGeneration'}
                    // ref="check_me"
                    onClick={e => handleChangeBusinessModel(e, 'employmentGeneration')}
                    checked={businessModel.employmentGeneration}

                  />
                  Employment Generation
            </div>
                <div className="col">
                  <input
                    type="checkbox"
                    name={'wealthCreation'}
                    onClick={e => handleChangeBusinessModel(e, 'wealthCreation')}
                    checked={businessModel.wealthCreation}

                  />
                  Wealth Creation
            </div>

              </div>
            </div>
          </div>)
      }

      <div className="row no-gutter">
        <div className="col-sm-12">
          <TextareaField
            label='Please submit a brief note supporting the options choosen above the innovation, imporvement and scability - 400 Characters maximum.'
            required
            name='note'
            value={unEscapeRegHtml(businessModel.note)}
            onChange={handleChangeBusinessModel}
            containerClass={"form-group"}
            rows={5}
            error={error.note}
            hasInvalidSubmitOccurred={true}
          />
        </div>
      </div>
      <div className="row mb-20">
        <div className="col-12">
          <label>Has your startup received any funding?</label>
          <div className="row no-gutter">
            <div className="col-sm-3">
              <Radio
                label='Yes'
                name='receiveFunding'
                value={true}
                onChange={handleInputChange}
                checked={formData.receiveFunding === true}
              />
            </div>
            <div className="col-sm-3">
              <Radio
                label='No'
                name='receiveFunding'
                value={false}
                onChange={handleInputChange}
                checked={formData.receiveFunding === false}

              />
            </div>

            {
              error.receiveFunding && (
                <InlineError message={error.receiveFunding} />
              )
            }
          </div>
          <br />
          {
            formData && formData.fundingProof && formData.fundingProof.publicUrl && formData.receiveFunding &&
            <label>Funding Proof:  <a href={API_URL() + formData.fundingProof.publicUrl} target="_blank">{formData.fundingProof.compressedName}</a></label>
          }
          {
            formData.receiveFunding
            && (<div col-sm-6 pt-20>
              <FileUpload
                onUploadFile={onUploadFile}
                allowedFiles={[".pdf", ".png", ".jpg"]}
                // error={error.awardDocument}
                label='Proof of Funding'
                maxFileSizeInMB={5}

              />
              <span className="text-note">
                (The document must be uploaded in pdf,png,jpg format with file size not exceeding 5 MB)
          </span>
            </div>)
          }

        </div>
      </div>
      {
        error.tnc && (
          <div className="row mb-20">
            <div className="col-sm-12">
              <InlineError message={`Go to "Self Certification" and ${error.tnc}`} />
            </div>
          </div>
        )
      }
      <div className="row mb-20">
        <div className="col-sm-12 text-center">
          <Button
            className="bttn mb-20"
            type="button"
            text="Save"
            onClick={() => doSubmit("INFORMATION")}
          />
         
        </div>
      </div>
    </div>
  )
}

export default AdditionalInformation;