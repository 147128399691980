import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import InputField from '../../../../common/InputField';
import GlobalDropDown from '../../../../common/GlobalDropDown';
import { dropdownOptionsTransform } from '../../../dependencies/utils';
import DirectorDetail from './DirectorDetail'
import Button from '../../../../common/Button'
import InlineError from "../../../../common/inlineError"

class DirectorModel {
    constructor() {
        this.address = "";
        this.email = "";
        this.gender = 'MALE';
        this.id = null;
        this.mobileNumber = "";
        this.name = "";
        this.nativeId = 0;
        this.directorCin = ""
        this.error = {};
    }
}

const DirectorsAndPartners = (props) => {

    const {
        directors = [],
        onChangeHandler,
        error = {},
        doSubmit,
        doReset,
        natureOfEntityName
    } = props;
    const [formData, setFormData] = useState([]);
    const [directorsCount, setDirectorsCount] = useState(0);
    const [dropdownOptions, setDropDownOptions] = useState([]);
    const [directorsList, setDirectorsList] = useState([]);

    useEffect(() => {
        const options = getDropDownOption(15);
        setDropDownOptions(options);
    }, []);

    useEffect(() => {
        const isArray = directors instanceof Array;
        if (isArray) {
            const length = directors.length;
            setDirectorsCount(length);
            setDirectorsList(cloneDeep(directors));
        }
        // console.log("directorsList", directorsList)
    }, [directors]);

    const handleInputChange = (e, flag, index) => {
        const value = e.target && e.target.value || "";
        const currentList = cloneDeep(directorsList);
        if (currentList && currentList.length > 0 && currentList.length > index) {
            currentList[index] = { ...currentList[index], [flag]: value };
        }
        setDirectorsList(currentList);
    }
    const getDropDownOption = (count) => {
        const options = [];
        if (!isNaN(count)) {
            for (let i = 1; i <= count; i++) {
                options.push({ text: i, value: i });
            }
        }
        return options;
    }
    const onChangeDropdown = (e, name) => {
        const value = e.target.value;
        setDirectorsCount(value);
    }


    useEffect(() => {
        if (natureOfEntityName === "Registered Partnership") {
            for (let i = 0; i < directorsList.length; i++) {
                directorsList[i].directorCin = "NA"
            }
        }
        if (onChangeHandler) {
            onChangeHandler({
                directors: directorsList
            })
        }
    }, [directorsList])
    useEffect(() => {
        const newList = []
        const currentList = cloneDeep(directorsList);

        for (let i = 0; i < directorsCount; i++) {
            if (i < currentList.length) {
                newList.push({ ...directorsList[i] });
            } else if (i < directors.length) {
                newList.push(cloneDeep(directors[i]));
            } else {
                const director = new DirectorModel();
                if (natureOfEntityName === "Registered Partnership")
                    director.directorCin = "NA"
                newList.push(director);
            }
        }
        setDirectorsList(newList);
    }, [directorsCount]);
    return (
        <div>
            <div className="row mb-20">
                <div className="col-sm-4">
                    <GlobalDropDown
                        required
                        label='Number of Director (s) / Partner (s)'
                        selectTagStyle="form-control dropdown-media"
                        onChange={onChangeDropdown}
                        options={dropdownOptions}
                        name={'noOfDirectors'}
                        value={directorsCount}
                        error={error.noOfDirectors}
                        hasInvalidSubmitOccurred={true}
                    />
                </div>
            </div>
            <div className="parter-detail-wrap pt-15">
                {
                    directorsList && directorsList.length > 0
                    && directorsList.map((director, index) => {
                        const err = error && error.directors && error.directors[index] || {};
                        return (
                            <DirectorDetail
                                key={index}
                                director={director}
                                index={index}
                                handleInputChange={handleInputChange}
                                error={err}
                                natureOfEntityName={natureOfEntityName}
                            />
                        )
                    })
                }
            </div>
            {
                error.tnc && (
                    <div className="row mb-20">
                        <div className="col-sm-12">
                            <InlineError message={`Go to "Self Certification" and ${error.tnc}`} />
                        </div>
                    </div>
                )
            }
            <div className="row mb-20 text-center">
                <div className="col-sm-12">
                    <Button
                        className="bttn mb-20"
                        type="button"
                        text="Save"
                        onClick={() => doSubmit("DIRECTOR")}
                    />

                </div>
            </div>

        </div>
    )
}

export default DirectorsAndPartners;