import api from '../utils/Api';
import { FETCH_STARTUP_SELECTION } from "../../constants/ApplicationUrl";
import { fetchStartupSection } from "../../constants/actionConstants";
    export const getStarutupselection = () => (dispatch) => {
        
        return api.GET(FETCH_STARTUP_SELECTION)
            .then((data) => {
                if (data && data.data && data.data.data) {
                    dispatch(fetchStartupSection(data.data.data));
                }
            })
    
    }