import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFetchTotalReport, downloadTotalReport } from '../dependencies/action';
import totalReportColumns from '../dependencies/columns/totalRepotsColumns';
import Table from '../../common/customTable';
import moment from 'moment';
import Download from '../../common/Download';
import '../dependencies/style/index.scss'
import SectionTitle from '../../common/sectionTitle/sectionTitle';


class TotalReport extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getFetchTotalReport();
    }

    downloadCSV = () => {
        const { downloadTotalReport } = this.props;
        if (downloadTotalReport) {
            return downloadTotalReport().then((data) => {
                return data;
            })
        }
    }


    render() {
        const { downloadCSV } = this;
        const currentDate = moment(new Date()).format('YYYYMMDD')
        const data = this.props.totalReportData && this.props.totalReportData;
        const info = data && data.data
        return (
            <div className="row summary-report-container">

                <div className="col-sm-12">
                    <SectionTitle breadCrumb title="Summary Report > Total number of applications submitted">Total number of applications submitted</SectionTitle>
                    <Download
                        fileName={`Report_for_Total_number_of_applications_submitted_${currentDate}`}
                        onClickDownload={downloadCSV}
                        className="bttn-link"
                    />
                    <Table
                        dataTable={info}
                        columns={totalReportColumns()}
                    />
                </div>
            </div>)
    }
}

const mapStateToProps = (state) => {
    return {
        totalReportData: state.ReportReducer.totalReport,
    }
}

export default connect(mapStateToProps, { getFetchTotalReport, downloadTotalReport })(withRouter(TotalReport));