import {GET_REJECTION_LIST} from '../../constants/actionConstants';


const initialState = {
    rejectionList: []
};

export default (state = initialState, action) => {
    let newState;
    switch (action.type) {

        case GET_REJECTION_LIST:
            newState = {
                ...state,
                rejectionList: action.payload
            };
            return newState;
        default:
            return state;
    }
}
