
import { get, isEmpty } from 'lodash';
import { AuditTrail } from './service';
import { isLoading, RECOGNITION_HISTORY,APPLICATION80IAC_HISTORY,SECTION_56_HISTORY } from '../../../constants/actionConstants';
import { toast } from "react-toastify";



export const getRecognitionHistoryList = (params) => async (dispatch) => {
  
    dispatch(isLoading(true))
    return await AuditTrail.getRecognitionHistory(params).then((response) => {
        const { data = [], status = false } = response;
        dispatch(isLoading(false));
      
            dispatch({
                type: RECOGNITION_HISTORY,
                payload: data
            })
            return { status, data };
     
      
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}

export const get80IacHistoryList = (params) => async (dispatch) => {
  
    dispatch(isLoading(true))
    return await AuditTrail.get80IacHistory(params).then((response) => {
        const { data = [], status = false } = response;
        dispatch(isLoading(false));
      
            dispatch({
                type: APPLICATION80IAC_HISTORY,
                payload: data
            })
            return { status, data };
       
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}


export const getSection56HistoryList = (params) => async (dispatch) => {
  
    dispatch(isLoading(true))
    return await AuditTrail.getSection56HistoryList(params).then((response) => {
        const { data = [], status = false , message = '',  recordsTotal = ''} = response;
        dispatch(isLoading(false));
        if (status) {
            dispatch({
                type: SECTION_56_HISTORY,
                payload: response
            })
            return { status, data, message, recordsTotal };
        }else{
            dispatch({
                type: SECTION_56_HISTORY,
                payload: response
            })
            return { status, data, message, recordsTotal };
        }
        
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}