import {
    validateDescription
} from '../../../../dependencies/utils/validation/Validation';

export default {
    validateCommentNRDC: (params) => {
        const {
            briefAboutEntity,
            patent,
            patentComment,
            pspComment,
            service,
            serviceComment
        } = params;
        let formStatus = {};

        const pspCommentStatus = validateDescription(pspComment);
        const patentCommentStatus = validateDescription(patentComment);
        const serviceCommentStatus = validateDescription(serviceComment);

        formStatus = {
            pspComment: pspCommentStatus.error || "",
            patentComment: patent && patentCommentStatus.error || "",
            serviceComment: service && serviceCommentStatus.error || "",
            hasErrors: (
                pspCommentStatus.hasError
                || (patent && patentCommentStatus.hasError)
                || (service && serviceCommentStatus.hasError)
            )
        };
        return formStatus;
    },
};
