import api from '../../../dependencies/utils/Api';
import {
    FETCH_STARTUP_LOGO_LIST,
    FETCH_STARTUP_LOGO_APP_COUNT,
    FETCH_STARTUP_LOGO_VIEW_DETAIL,
    UPDATE_STARTUP_LOGO_STATUS,
    DOWNLOAD_STARTUP_LOGO_LIST
} from "../../../constants/ApplicationUrl";
import startupTransformer from './transformer/transformer'
import { returnStatement } from '@babel/types';



export const StartupLogoServices = {
    getStartupLogos(params) {
        return api.POST(FETCH_STARTUP_LOGO_LIST, params)
            .then((resp) => {
                const { data = {} } = resp;
                return { data };
            });
    },
    getStartupLogoApp() {
        return api.GET(FETCH_STARTUP_LOGO_APP_COUNT).
            then((response) => {
                const { data: { status, data } } = response;
                if (status) {
                    return Promise.resolve(data);
                } else {
                    return Promise.reject(data);
                }
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    startupLogosView(id) {
        return api.GET(`${FETCH_STARTUP_LOGO_VIEW_DETAIL}/${id}`).
            then((response) => {
                const { data: { status, message, data } } = response;
                    // return data;
                    return { status, message, data };
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    updateStatus(params) {
        return api.POST(UPDATE_STARTUP_LOGO_STATUS, params).
            then((response) => {
                const { data = {}, status } = response;
                return { data, status };
            });
    },
    downloadStartupLogos(params) {
        return api.DOWNLOADDATA(DOWNLOAD_STARTUP_LOGO_LIST, params)
        .then((response) => {
            return response;
        });        
    }
}