import React from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from '../../../../constants/ApplicationUrl'
import { SUPPORTING_DOCUMENTS, headerSortingStyle } from '../../../../constants';


export const editRowStyle = (row, rowIndex) => {
    const style = {};
    if (row.isEdited) {
        style.backgroundColor = '#bffedf';
    }

    return style;
};

const columns = ({
    clickOnAction,
    pageNo,
    size
}) => {

    return [
        {
            dataField: '',
            text: 'Sr.No',
            formatter: (cell, row, rowIndex) => {
                return (pageNo * size) + (rowIndex + 1);
            }
        }, {
            dataField: 'referenceNumber',
            text: 'Refrence No',
            sort: false,
            headerSortingStyle
        }, {
            dataField: 'certificateNumber',
            text: 'Certificate No',
            sort: false,
            headerSortingStyle
        }, {
            dataField: 'incorporationNumber',
            text: 'Registration No',
            sort: false,
            headerSortingStyle
        }, {
            dataField: 'incorporationDate',
            text: 'Registration Date',

        },
        {
            dataField: 'Options',
            text: 'Options',
            formatter: (cell, row) => {
                const applicationId = row.applicationId
                return (
                    <div className="IAS-Actions-list">
                        <ul className="IAS-Actions-list">
                            <li>
                                <Link to={`/application/archived/${applicationId}`}>
                                    View
              </Link>
                            </li>

                            <li><a href="javascript:void(0)" onClick={() => clickOnAction(applicationId)}>View Audit Trail</a></li>
                        </ul>
                    </div>
                )
            }
        },
        {
            dataField: 'applicationDate',
            text: 'Application Date',
            sort: true,
            headerSortingStyle
        }, {
            dataField: 'panNumber',
            text: 'PAN Number'
        }, {
            dataField: 'nameOfEntity',
            text: 'Name Of Entity',
            sort: false,
            headerSortingStyle
        }, {
            dataField: 'mobileNumber',
            text: 'Mobile No',
            sort: false,
            headerSortingStyle
        }, {
            dataField: 'emailId',
            text: 'Email Id',
            sort: false,
            headerSortingStyle
        }, {
            dataField: 'statusLabel',
            text: 'Status',
        }, {
            dataField: 'additionalDocument',
            text: 'Supporting Document',
            formatter: (cell, row, rowIndex) => {
                if (cell !== undefined) {
                    return cell.map((element, index) => {
                        let link = "";
                        if (element && element.file) {
                            link = `${API_URL()}${element.file.publicUrl}`
                        } else if (element && element.url && element.name === 'WEBSITE_LINK') {
                            link = `${element.url.indexOf('http') == -1 ? 'http://' : ''}${element.url}`
                        } else if (element && element.url) {
                            link = element.url
                        }
                        return link ? <div key={index}><a href={link} target="_blank"> {SUPPORTING_DOCUMENTS[element.name] || ""} </a> </div> : null;
                    })
                }
            }
        }, {
            dataField: 'registrationCertificate',
            text: 'Incorporation Certificate',
            formatter: (cell, row) => {
                return (
                    cell ? <a href={`${API_URL()}${cell.publicUrl}`} target="_blank"> view Document </a> : 'No Document'

                )
            }
        }, {
            dataField: 'briefWriteup',
            text: 'Brief Writeup',
        },
    ];
}




{/* <TableHeaderColumn width='150px' dataField='supportingDocument' dataFormat={this.supportingDocumentFormatter}>Supporting Document</TableHeaderColumn> */ }

export default columns;