import {
    validateTitle,
    validateFileTyPe,
    validateURL,
    validateUploadFile
} from '../../../dependencies/utils/validation/Validation';

export default {

    validateNotificationDocument: (params) => {
        const {
            fileInfo,
            fileType,
            linkTitle,
            title,
            url,
        } = params;
        let formStatus = {};

        const fileInfoStatus = validateUploadFile(fileInfo);
        const fileTypeStatus = validateFileTyPe(fileType);
        const linkTitleStatus = validateTitle(linkTitle);
        const titleStatus = validateTitle(title);
        const urlStatus = validateURL(url);

        formStatus = {
            fileType: fileTypeStatus.error,
            linkTitle: linkTitleStatus.error,
            title: titleStatus.error,
        }
        if (fileType === 'LINK') {
            formStatus = {
                ...formStatus, url: urlStatus.error,
            }
        } else if (fileType === 'FILE_UPLOAD') {
            formStatus = {
                ...formStatus, fileInfo: fileInfoStatus.error,
            }
        }

        let hasFileError = false;
        if (fileType === 'LINK') {
            hasFileError = urlStatus.hasError;
        } else if (fileType === 'FILE_UPLOAD') {
            hasFileError = fileInfoStatus.hasError;
        }

        formStatus.hasErrors = (
            fileTypeStatus.hasError
            || linkTitleStatus.hasError
            || titleStatus.hasError
            || hasFileError
        )
        return formStatus;
    },




};
