import React, { Component } from 'react';
import EntityDataRow from '../../common/EntityDataRow';
import './Style.css'


const KeyValue = (props) => {
    function makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    }
    
    let allData = Object.entries(props.items).map((data, index) => {
        return <EntityDataRow key={makeid(20)} className="" title={data[0]} description={data[1]} />
    })

    return (
        <div className='start-details-wrapp' >
            <div className='start-details-content'>
            {allData}
            </div>
           
        </div>
    );
}

export default KeyValue;




