import React, { useEffect, useState } from 'react';
import KeyValue from '../keyValue';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { getImbMemberCommentList } from '../dependencies/action';
import { getClarificationRequest, getIMBMemberCommentList, saveImbMemberComment } from '../dependencies/action';
import Table from '../../common/Table';
import imbColumns from "../dependencies/columns/imbColumns";
import TextArea from '../../common/TextareaField';
import Button from '../../common/Button';
import './style.scss';
import SectionTitle from '../../common/sectionTitle/sectionTitle';

const IMBMembers = (props) => {
    const { getClarificationRequest,
        getIMBMemberCommentList,
        imbMembercommentList,
        saveImbMemberComment,
        match: { params: { id: exemptionId } } = {},
        clarificationData: {
            dippNumber,
            nameOfEntity,
            incorporationNumber,
            id: clarificationId
        }
    } = props;

    const [comment, setComment,] = useState("");
    const [disabledbtn, setdisabledbtn,] = useState(true);

    // const getList = () => {
    //     if (getIMBMemberCommentList) {
    //         getIMBMemberCommentList({
    //             clarificationId
    //         })
    //     }
    // }

    useEffect(() => {
        if (getClarificationRequest) {
            getClarificationRequest({ id: exemptionId }).then((response) => {
                const { status, data } = response;
                if (status) {
                }
            }).catch(e => {
            });
        }
    }, [])



    const handleOnChange = (e, name) => {
        if (e && e.target) {
            
            const val = e.target.value || "" ;
            var regexp = /^[\dA-Za-z]+[\dA-Za-z\s]{1,50}$/;
            if (val && val != "" && regexp.test(val)) {
                setdisabledbtn(false)
            } else {
                setdisabledbtn(true)
            }
            setComment(val)
        }


    }
    let communicationEntity =
    {
        'Certificate No.': dippNumber,
        'Registration No.': incorporationNumber,
        'Name of The Entity': nameOfEntity,
    }


    const onSubmit = (e) => {
        e.preventDefault();
        if (saveImbMemberComment) {
            saveImbMemberComment({
                clarificationId,
                comment,
            }).then((data) => {
                const { status } = data;
                if(status){
                    setComment('')
                }
            })
        }
    }
    return (
        <div>
            <SectionTitle breadCrumb title="Startup Applications for IMB > Communication Request">Communication Request</SectionTitle>
            <div className="entity-detail-wrap mb-30">
                <div className="entity-detail-inner p-20 ">
                    <h3>Communication</h3>
                    <KeyValue items={communicationEntity} />
                </div>
            </div>
            {clarificationId && <div className="imb-form-container">
                <form onSubmit={e => onSubmit(e)}>
                    <TextArea
                        name={'comment'}
                        value={comment}
                        className={`input-element`}
                        onChange={handleOnChange}
                        //onBlur={e => handleInputBlur(e, name)}
                        placeholder={'Comment'}
                        rows={10}
                    />

                    <Button
                        text="Submit"
                        className="bttn"
                        type="submit"
                        disabled={disabledbtn}

                    />
                    <Button
                        text="Reset"
                        className="bttn blue"
                        disabled={disabledbtn}
                        type="button"
                        onClick={() => { setComment(""); setdisabledbtn(true) }}
                    />
                </form>
            </div>
            }
            <div className="observation-communication-table">
                <Table
                    dataTable={imbMembercommentList}
                    columns={imbColumns}
                    keyField='observation'
                />

            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        clarificationData: state.Applicatio80IACReducer.clarificationObservation,
        imbMembercommentList: state.Applicatio80IACReducer.imbMemberCommentList,
    }
}

export default connect(mapStateToProps, { getClarificationRequest, getIMBMemberCommentList, saveImbMemberComment })(withRouter(IMBMembers));
