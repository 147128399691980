import React, { useEffect, useState } from 'react';
import '../view/scss/index.scss';
import SectionTitle from '../../../components/common/sectionTitle/sectionTitle';
import { withRouter, Link } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { get80IACApplicationDetails } from '../dependencies/action';
import KeyValue from '../keyValue';
import Table from '../../common/Table';
import annualAccountColumns from '../dependencies/annualAccountColumns';
import itrColumns from '../dependencies/itrColumns';
import clarificationColumns from '../dependencies/clarificationColumns'
import DisplayButton from '../view/components/displayButtons';
import ActionModal from '../dependencies/modal/index';
import { ROLES } from '../../../constants/Roles';
import { storage } from '../../../dependencies/store/storage';
import moment from 'moment'

const Application80IACExpertPanelView = (props) => {
    const userRole = storage.getUserRole();    
    const sAppId = props.match.params.id;
    useEffect(() => {
        props.get80IACApplicationDetails(sAppId);
    }, []);

    const callApi = () => {
        props.get80IACApplicationDetails(sAppId);
    }

    const validRoles = userRole === ROLES.SUPER_ADMIN || userRole === ROLES.ADMIN
    const superAdminRoles = userRole === ROLES.SUPER_ADMIN

    const clarificationsList = [];
    props.iacApplicationViewData.clarifications && props.iacApplicationViewData.clarifications.forEach(element => {
        var obj = {};
        obj["clarificationDate"] = moment(element.clarificationDate).format('DD-MM-YYYY');
        obj["clarification"] = element.clarification;
        clarificationsList.push(obj);
    });

    return (
        <div className="p30 app-80-iac-wrapper">
            <SectionTitle>Startup India : Expert Panel (Non Financial)</SectionTitle>
            <div className="entity-detail-wrap">
                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Startup Details</h3>
                    <KeyValue items={props.iacApplicationViewData.natureOfEntity ? props.iacApplicationViewData.natureOfEntity : ''} />
                </div>
                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Contact details of Startup</h3>
                    <KeyValue items={props.iacApplicationViewData.contactDetailsStartup ? props.iacApplicationViewData.contactDetailsStartup : ''} />
                </div>
                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Existing/proposed activities</h3>
                    <KeyValue items={props.iacApplicationViewData.existingProposesActivity ? props.iacApplicationViewData.existingProposesActivity : ''} />
                </div>
                <div className="entity-detail-inner p-20 mb-15">
                    <h3> Annual Accounts of the startup</h3>
                    <Table
                        dataTable={props.iacApplicationViewData.annualAccountStatement}
                        columns={annualAccountColumns}
                        keyField='financialYear'
                    />

                </div>
                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Income Tax returns</h3>
                    <Table
                        dataTable={props.iacApplicationViewData.itrStatement}
                        columns={itrColumns}
                        keyField='financialYear'
                    />
                    <KeyValue items={props.iacApplicationViewData.detailsOfCert ? props.iacApplicationViewData.detailsOfCert : ''} />
                </div>

                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Clarifications</h3>
                    <Table
                        dataTable={clarificationsList}
                        columns={clarificationColumns}
                        sizePerPage="20"
                        keyField='clarificationDate'
                    />
                </div>

                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Supporting Documents</h3>
                    <KeyValue items={props.iacApplicationViewData.supportingDocuments ? props.iacApplicationViewData.supportingDocuments : ''} />
                </div>
                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Information about the Startup</h3>
                    <KeyValue items={props.iacApplicationViewData.informationAboutStartup ? props.iacApplicationViewData.informationAboutStartup : ''} />
                </div>
                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Startup Activites</h3>
                    <KeyValue items={props.iacApplicationViewData.startupActivites ? props.iacApplicationViewData.startupActivites : ''} />
                </div>
                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Declaration</h3>
                    <KeyValue items={props.iacApplicationViewData.declaration ? props.iacApplicationViewData.declaration : ''} />
                </div>


            </div>
            {
                validRoles &&
                <DisplayButton showBtn={superAdminRoles} callApi={callApi} appId={sAppId ? sAppId : ''} status={props.iacApplicationViewData.status ? props.iacApplicationViewData.status : ''} />
            }
            {/* <a href="#" onClick={handleclick}>click me</a> */}



        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        iacApplicationViewData: state.Applicatio80IACReducer.applicatio80IAC
    }
}
export default connect(mapStateToProps, { get80IACApplicationDetails })(withRouter(Application80IACExpertPanelView))