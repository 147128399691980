import { section56ReportService } from './service';

import { isLoading } from '../../../constants/actionConstants';
import { toast } from "react-toastify";


export function downloadSection56Report(params) {   

    return async function (dispatch) {
        dispatch(isLoading(true));
        let DownloadReport = await section56ReportService.downloadReport(params);

        dispatch(isLoading(false));
        if(DownloadReport === undefined){
            toast.error('Error')
        }else{
            return DownloadReport;
        }
        
    }

}