import React, { Component, useEffect, useState, useRef } from 'react';
import InputField from '../common/InputField';
import GlobalDropDown from '../common/GlobalDropDown/index'
import { CorStatus, Stage, Gender, CoeStatus, DownloadField } from './dependencies/constant/searchConstant'
import { getStateList, getSectorList, dowloadReport, generateReport, downloadExcelFile, GenerateSuiReport, RefreshSuiReport, downloadSuiPreviusReport, lastGeneratedReportHistory } from './dependencies/action';
import { getIndustryList, getStartupSector } from '../../components/startupApplication/dependencies/action'
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import Button from '../common/Button'
import Download from '../common/Download';
import _ from 'lodash';
import SectionTitle from '../common/sectionTitle/sectionTitle';
import { Multiselect } from 'multiselect-react-dropdown'
import './style.scss'
import { toast } from "react-toastify";
import moment from 'moment/moment';
import downloadIcon from '../../assests/images/download-icon.png';
import refreshIcon from '../../assests/images/refresh-icon-green.png';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import downArrowBtn from '../../assests/images/down-arrow-btn.png';

// import { refreshIcon } from 'react-icons/fa';

const SuiMonthlyReport = (props) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [corStatus, SetCorStatus] = useState('');
    const [coeStatus, SetCoeStatus] = useState('');
    const [stage, SetStage] = useState('');
    const [gender, SetGender] = useState('');
    const [state, SetState] = useState('');
    const [sector, SetSector] = useState('');
    const [industry, SetIndustry] = useState('');
    const { getStartupSector, getIndustryList, getSectorList, getStateList, IndustryList, StateList, SectorList, dowloadReport, downloadExcelFile, generateReport, GenerateSuiReport, RefreshSuiReport, downloadSuiPreviusReport, lastGeneratedReportHistory } = props;
    const [selectedFields, setSelectedFields] = useState(DownloadField);
    const [csvFile, setCsvFile] = useState('');
    const [reportDetail, SetReportDetails] = useState({});
    const [openDateSelectionModal, setOpenDateSelectionModal] = useState(false);
    const [openHistory, setOpenHistory] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [lastThreeMonths, setLastThreeMonths] = useState([]);
    const [historyData, setHistoryData] = useState({});



    var m = new Date();
    var dateString =
        m.getUTCFullYear() + "-" +
        ("0" + (m.getUTCMonth() + 1)).slice(-2) + "-" +
        ("0" + m.getUTCDate()).slice(-2)
    if (StateList.data) {
        var stateListFinalValue = StateList.data.data.map((data) => {
            return {
                value: data.id,
                text: data.stateName
            };
        })
    }

    if (SectorList) {
        var sectorListFinalValue = SectorList.map((data) => {
            return {
                value: data.id,
                text: data.sectionName
            };
        })
    }

    if (IndustryList) {
        var industryListFinalValue = IndustryList.map((data) => {
            return {
                value: data.id,
                text: data.industryName
            };
        })
    }

    useEffect(() => {
        getSectorList();
        getStateList();
        getIndustryList();
        refreshGenerateAPI();
    }, [])

    useEffect(() => {
        getStartupSector({ industryId: industry }).then((data) => {
        })
    }, [industry]);

    useEffect(() => {
        const months = [];
        const today = new Date();
        let targetMonth = today.getMonth() - 1;
        let targetYear = today.getFullYear();
        for (let i = 0; i < 2; i++) {
            if (targetMonth === -1) {
                targetMonth = 11;
                targetYear -= 1;
            }
            const targetDate = new Date(targetYear, targetMonth, 1);
            const monthName = targetDate.toLocaleString('default', { month: 'long' });
            const monthNumber = `${String(targetMonth + 1).padStart(2, '0')}-${targetYear}`;
            months.push({
                name: `${monthName} ${targetYear}`,
                number: monthNumber
            });
            targetMonth -= 1;
        }
        setLastThreeMonths(months);
    }, []);

    const handleInput = (e, type) => {
        let value = e.target.value;
        if (type === 'startDate') {
            setStartDate(value);
        } else if (type === 'endDate') {
            setEndDate(value);
        } else if (type === 'corStatus') {
            SetCorStatus(value);
        } else if (type === 'coeStatus') {
            SetCoeStatus(value);
        } else if (type === 'state') {
            SetState(value);
        } else if (type === 'sector') {
            SetSector(value);
        } else if (type === 'stage') {
            SetStage(value);
        } else if (type === 'gender') {
            SetGender(value);
        } else if (type === 'industry') {
            SetIndustry(value);
        }
    }

    function downloadStartupDetail(e) {
        if (dowloadReport) {

            // "endDate": "2020-06-06T17:01:25.190Z",
            // "gender": "MALE",
            // "startDate": "2020-01-01T17:01:25.190Z",
            const headers = selectedFields.reduce((arr, option) => {
                if (option['dataField']) {
                    arr.push(option['dataField']);
                }
                return arr;
            }, []);

            let query = {
                "endDate": endDate,
                "startDate": startDate,
                headers,
            }

            for (var propName in query) {
                if (query[propName] === null || query[propName] === undefined || query[propName] === '') {
                    delete query[propName];
                }
            }

            return dowloadReport({ query }).then((response) => {

                const { data } = response || [];
                const fileName = "report"

                e.preventDefault();
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";

                if (data) {
                    if (navigator.appName === "Microsoft Internet Explorer") {
                        if (navigator.msSaveBlob) { // IE 10+
                            let _fileName = `${fileName}.csv`;
                            let blob = new Blob([data], { "type": "text/csv;charset=utf8;" });
                            navigator.msSaveBlob(blob, _fileName);
                        }
                    } else {
                        let _fileName = `${fileName}.csv`;
                        var blob = new Blob([data], { type: "text/csv;charset=utf-8" });
                        // FileSaver.saveAs(blob, _fileName);
                        const url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = _fileName;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                }
                // return data;
            })
        }
    }

    function handledownloadPreviusSuiReport(e) {

        if (downloadSuiPreviusReport && e.target.value) {

            // "endDate": "2020-06-06T17:01:25.190Z",
            // "gender": "MALE",
            // "startDate": "2020-01-01T17:01:25.190Z",
            const headers = selectedFields.reduce((arr, option) => {
                if (option['dataField']) {
                    arr.push(option['dataField']);
                }
                return arr;
            }, []);

            let query = {
                "endDate": endDate,
                "startDate": startDate,
                headers,
            }

            for (var propName in query) {
                if (query[propName] === null || query[propName] === undefined || query[propName] === '') {
                    delete query[propName];
                }
            }

            return downloadSuiPreviusReport({ query, month: e.target.value }).then((response) => {

                const { data } = response || [];
                const fileName = "report"

                e.preventDefault();
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";

                if (data) {
                    if (navigator.appName === "Microsoft Internet Explorer") {
                        if (navigator.msSaveBlob) { // IE 10+
                            let _fileName = `${fileName}.csv`;
                            let blob = new Blob([data], { "type": "text/csv;charset=utf8;" });
                            navigator.msSaveBlob(blob, _fileName);
                        }
                    } else {
                        let _fileName = `${fileName}.csv`;
                        var blob = new Blob([data], { type: "text/csv;charset=utf-8" });
                        // FileSaver.saveAs(blob, _fileName);
                        const url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = _fileName;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                }
                // return data;
            })
        }
    }


    function openDateModal() {
        setOpenDateSelectionModal(true);
     
    }

    function GenerateStartupDetail() {
        if (generateReport) {
            const headers = selectedFields.reduce((arr, option) => {
                if (option['dataField']) {
                    arr.push(option['dataField']);
                }
                return arr;
            }, []);

            let query = {
                "endDate": endDate,
                "startDate": startDate,
                headers,
            }

            for (var propName in query) {
                if (query[propName] === null || query[propName] === undefined || query[propName] === '') {
                    delete query[propName];
                }
            }

            for (var propName in query) {
                if (query[propName] === null || query[propName] === undefined || query[propName] === '') {
                    delete query[propName];
                }
            }

            return GenerateSuiReport({ query }).then(response => {
                const { data } = response || [];
                if (data.status == true) {
                    toast.success('Report Generation Started - Please Wait For Some Time To Download File.')
                }
                else{
                    toast.error(data.message);
                }
            })
        }
    }
    function GenerateReport(e) {
        let startDateObj = new Date(startDate);
        let endDateObj = new Date(endDate);

        // Calculate the difference in months
        let diffInMonths = (endDateObj.getFullYear() - startDateObj.getFullYear()) * 12 +
            (endDateObj.getMonth() - startDateObj.getMonth());

        // Calculate the difference in days
        let diffInDays = endDateObj.getDate() - startDateObj.getDate();

        // Adjust for negative differences
        if (diffInDays < 0) {
            // Subtract 1 month from the difference in months
            diffInMonths--;

            // Add days in the last month of the start date
            let lastMonthEndDate = new Date(startDateObj.getFullYear(), startDateObj.getMonth() + 1, 0);
            diffInDays += lastMonthEndDate.getDate();
        }

        if (diffInMonths > 6 || (diffInMonths === 6 && diffInDays > 0)) {
            // Call function if difference is more than 6 months or exactly 6 months with more than 0 days
            GenerateStartupDetail();
        } else {
            // Call another function if difference is less than or equal to 6 months and 0 days
            downloadStartupDetail(e);
        }
        setOpenDateSelectionModal(false);
        setStartDate('');
        setEndDate('')


    }
    const submitFormHandler = async (e) => {
        e.preventDefault();
    }

    const getReportUrl = async (e) => {
        await downloadExcelFile().then(res => {
            // SetReportDetails(res.preSignedUrl)
        })
    }

    const downloadFIle = async (e) => {
        var a = document.createElement("a");
        const url = reportDetail.currentPreSignedUrl;
        a.href = url;
        a.download = '_fileName';
        a.click();
        window.URL.revokeObjectURL(url);

    }
    const onSelect = (selectedList, selectedItem) => {
        setSelectedFields(selectedList);
    }
    const onRemove = (selectedList, removedItem) => {
        setSelectedFields(selectedList);
    }

    const refreshGenerateAPI = async () => {
        if (RefreshSuiReport) {
            await RefreshSuiReport().then(res => {
                // const {data: {currentPreSignedUrl, previousPreSignedUrl, suiDate}} = res.data
                SetReportDetails(res.data.data)
            })
        }
    }
    const toggleDateSelectionModal = () => {
        setOpenDateSelectionModal(false);
        setStartDate('');
        setEndDate('')
    }

    const openHistoryModal = async () => {
        let data = await lastGeneratedReportHistory();
        if (data.status) {
            setHistoryData(data.data)
        }
        setOpenHistory(true);
    }
    const closeHistoryModal = () => {
        setOpenHistory(false);
    }
    const handleOpenDropdown = () => {
        setOpenDropdown(!openDropdown);
    }

    return (
        <>
            <div className="startup-report">
                <SectionTitle>SUI Monthly Report</SectionTitle>
                <div className="filter-form custom-form" >
                    {/* <div className="row">
                        <div className="startdate col-md-3">
                            <InputField
                                type="date"
                                name="startDate"
                                label='From Date'
                                value={startDate}
                                min={endDate}
                                onChange={handleInput}
                                containerClass={"form-group"}
                                placeholder="Search"
                                max={dateString}

                            />
                        </div>
                        <div className="endDate col-md-3">
                            <InputField
                                type="date"
                                name="endDate"
                                min={startDate}
                                label="To Date"
                                value={endDate}
                                onChange={handleInput}
                                containerClass={"form-group"}
                                placeholder="Search"
                                max={dateString}
                            />
                        </div>
                    </div> */}

                    <div className="row">
                        <div className="col-md-12">
                            <div className='d-flex justify-content-between Refresh-abt'>
                                <label className='fields-line'>Fields</label>
                                <div className='Refresh-btn'>
                                    <a className='text-success' onClick={(e) => refreshGenerateAPI(e)}>
                                        <img width="15" style={{ marginRight: '5px' }} src={refreshIcon} />
                                        Refresh &nbsp;</a>
                                </div>
                            </div>
                            <div className='fields-list'>
                                <ul>
                                    {DownloadField?.map((el => <>
                                        <li>{el?.text}</li>
                                    </>))}

                                </ul>
                            </div>
                            {/* <Multiselect
                                options={DownloadField}
                                showCheckbox={true}
                                onSelect={onSelect} 
                                onRemove={onRemove}
                                displayValue="text"
                                selectedValues={selectedFields}
                                closeOnSelect={false}
                            /> */}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 d-flex justify-content-between pt-20 mb-3'>
                            {/* <div className="button-wrap  d-flex align-items-start pt-20 pl-0 clearfix mb-3"> */}
                            <button
                                className={`download-container downloadReport`}
                                onClick={(event) => openDateModal(event)}
                            >
                                {'Generate SUI Report'}
                            </button>

                            <div onClick={handleOpenDropdown}
                                className={`download-container bttn dropdown-check ${openDropdown ? 'show' : ""}`}
                            >
                                {'Last Month SUI Report'}    <img width="15" src={downArrowBtn} />

                                <ul>
                                    {lastThreeMonths?.map((el, index) => (
                                        <li key={index} onClick={(event) => handledownloadPreviusSuiReport(event)}>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" value={el?.number} id={`checkbox-${index}`} name="month" />
                                                <label className="form-check-label" htmlFor={`checkbox-${index}`}>
                                                    {el?.name}
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                {/* </div> */}
                            </div>
                        </div>

                        {/* <button
                        className={`download-container bttn`}
                        onClick={(event) => downloadStartupDetail(event)}
                    >
                        {'Download'}
                    </button> */}
                    </div>
                    <a href='javascript:void(0)' onClick={() => downloadFIle()} style={!reportDetail.suiDate ? { pointerEvents: 'none', cursor: 'default', opacity: '0.6' } : {}}> Download Generated Report</a><br />
                    <span className='text-success' style={{ fontSize: '14px' }}>Last Generated Report: {reportDetail.suiDate ? moment(reportDetail.suiDate).format('YYYY-MM-DD, h:mm:ss a') : 'Loading...'} </span><button className='history-btn' onClick={openHistoryModal}>History</button>
                    {/* <span className='text-success' style={{ fontSize: '18px' }}>
                    
                    Last Generated Report: {reportDetail.lastLinkGeneratedDate ? moment(reportDetail.lastLinkGeneratedDate).format('YYYY-MM-DD, h:mm:ss a') : 'Loading...'} 
                </span> */}

                    {/* <div className='col-me-12 mt-3'>

                        <button
                            className={`download-container bttn`}
                            onClick={(event) => handledownloadPreviusSuiReport(event)}
                        >
                            {'Last Month SUI Report'}
                        </button>
                    </div> */}
                </div >

                {/* </form> */}
            </div>
            <Modal isOpen={openDateSelectionModal} toggle={toggleDateSelectionModal} className="sui-monthly-report">
                <ModalHeader toggle={toggleDateSelectionModal}>
                   Generate SUI Report
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="startdate col-md-6">
                            <InputField
                                type="date"
                                name="startDate"
                                label='From Date'
                                value={startDate}
                                min={endDate}
                                onChange={handleInput}
                                containerClass={"form-group"}
                                placeholder="Search"
                                max={dateString}
                                required
                            />
                        </div>
                        <div className="endDate col-md-6">
                            <InputField
                                type="date"
                                name="endDate"
                                min={startDate}
                                label="To Date"
                                value={endDate}
                                onChange={handleInput}
                                containerClass={"form-group"}
                                placeholder="Search"
                                max={dateString}
                                required
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="button-container">
                        <button className="download-container downloadReport-sui" onClick={(event) => GenerateReport(event)} disabled={!startDate || !endDate}>
                            Generate
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
            <Modal isOpen={openHistory} toggle={closeHistoryModal} className="last-generated-report">
                <ModalHeader toggle={closeHistoryModal}>
                    Last Generated Report
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className='last-generated'>
                            <ul>
                                <li><strong>Name : </strong> <p>{historyData['Name']}</p></li>
                                <li><strong>Date : </strong> <p>{moment(historyData['Date']).format('DD/MM/YYYY')}</p></li>
                                <li><strong>Query : </strong> <p>{`Since ${moment(historyData["StartDate"]).format('DD/MM/YYYY')} - ${moment(historyData['EndDate']).format('DD/MM/YYYY')}`}</p></li>
                            </ul>
                        </div>
                        {/* <p>Name : Admin user </p> <br />
                        <p>Date : 06/03/2024 </p> <br />
                        <p>Query : Since 04/04/2016 - 06/03/2024 </p> */}
                    </div>

                </ModalBody>
                <ModalFooter>
                    <div className="button-container">
                        <button className="download-container downloadReport-Generated" onClick={(event) => closeHistoryModal(event)}>
                            Ok
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        StateList: state.StartupDetailReport.reportStateList,
        IndustryList: state.startupApplicationForm.startupIndustry,
        SectorList: state.startupApplicationForm.startupEntitySector,
    }
}

export default connect(mapStateToProps, { lastGeneratedReportHistory, getStartupSector, getSectorList, getStateList, dowloadReport, generateReport, downloadExcelFile, getIndustryList, GenerateSuiReport, RefreshSuiReport, downloadSuiPreviusReport })(withRouter(SuiMonthlyReport));