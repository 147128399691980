import axios from 'axios';
import { toast } from 'react-toastify';
import { storage } from "../store/storage";

export const httpClient = axios.create();
httpClient.defaults.timeout = 5 * 60 * 1000;


const axiosConfig = {
    headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*"
    }
};

const configData = {
    headers: {
        'Content-Type': 'multipart/form-data',
        "Access-Control-Allow-Origin": "*"
    }
}
const downloadHeader = {
    headers: {
        'Accept': 'text/csv',
        "Access-Control-Allow-Origin": "*"
    }
}
const downloadHeaderSui = {
    headers: {
       // 'Accept': 'text/csv',
        "Access-Control-Allow-Origin": "*"
    }
}
class Api {
    POST(url, payload = {}, config = axiosConfig) {
        return httpClient.post(url, payload, config).catch(function (error) {
        });
    }

    GET(url, config = axiosConfig) {
        return httpClient.get(url, config).catch(function (error) {
        });
    }

    POSTDATA(url, payload = {}, config = configData) {
        return httpClient.post(url, payload, config).catch(function (error) {
        });
    }

    DELETE(url, config = axiosConfig) {
        return httpClient.delete(url, config).catch(function (error) {  
          
        });
    }
    DOWNLOADDATA(url, payload = {}, config = downloadHeader) {
        return httpClient.post(url, payload, config).catch(function (error) {
        })
    }

    DOWNLOADGET(url, config = downloadHeader) {
        return httpClient.get(url, config).catch(function (error) {
        })
    }

    DOWNLOADGETSUI(url, config = downloadHeaderSui) {
            return httpClient.get(url, config).catch(function (error) {
            })
    }
}

export default new Api();