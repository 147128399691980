import api from '../../../dependencies/utils/Api';
import {
    FETCH_GLOSSARY_LIST_URL,
    FETCH_GLOSSARY_ID_URL,
    SAVE_GLOSSARY_URL,
    DELETE_GLOSSARY_URL
} from "../../../constants/ApplicationUrl";



export const GlossaryServices = {


    fetchListServices(params) {
        return api.POST(FETCH_GLOSSARY_LIST_URL, params).
            then((response) => {
                const { data = {} } = response;
                return { data };
            });
    },

    fetchListByID(params) {
        return api.GET(`${FETCH_GLOSSARY_ID_URL}/${params}`).
            then((response) => {
                const { data = {}, status } = response;
                return { data, status };
            });
    },

    saveGlossaryService(params) {
        return api.POST(SAVE_GLOSSARY_URL, params).
            then((response) => {
                const { data: { status, message } } = response;
                return { status, message };
            });
    },


    deleteGlossaryService(id) {
        return api.POST(`${DELETE_GLOSSARY_URL}/${id}`).
            then((response) => {
                const { data: { data, status, message } } = response;
                return { data, status, message };
            });
    },

}