import { storage } from '../store/storage';
import { API_URL } from '../../constants/ApplicationUrl';
import ReactHtmlParser from 'react-html-parser';



export const isUserloggedIn = () => {
    if (storage.isLoggedIn()) {
        return true;
    }
    return false;
}


export const getDocumentLink = (link) => {
    if (link) {
        const path = `${API_URL()}${link}`
        return path
    }
    return ""
}

export const dropdownOptionsTransform = (array, text, val) => {
    const isArray = array instanceof Array;
    let options = [];
    if (isArray) {
        options = array.reduce((arr, option) => {
            const label = option[text] || "";
            const value = option[val] || "";
            arr.push({ text: label, value });
            return arr;
        }, []);
    }
    return options;
}

export const unEscapeRegHtml = (unsafe) => {
    if(!unsafe){
        return "";
    }
    return ReactHtmlParser(unsafe.replace(/amp;/g, ""))
        
}