import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Accordion from '../../common/Accordian';
import './scss/index.scss';
import { getInfoForStartupAppliactionForm, getDistrictData, getSubDistrictList, getStartupSector, updateStartupForm } from '../dependencies/action';
import { isLoading } from '../../../constants/actionConstants';
import EntityDetail from './components/EntityDetails';
import Address from './components/Address';
import Authorized from './components/AuthorizedDetail';
import DirectorsAndPartners from './components/DirectorsAndPartners'
import Information from './components/Information'
import Activities from './components/Information/Activities'
import AdditionalInformation from './components/Information/AdditionalInformation'
import Button from '../../common/Button';
import SelfCertification from './components/SelfCertification';
import {Link} from "react-router-dom";
import {
  validateStartupForm,
  validateStartupEntityForm,
  validateStartupInfoForm,
  validateStartupAddressForm,
  validateStartupRepresentativeForm,
  validateStartupDirectorForm,
  validateStartupActivityForm,
  validateStartupCertificateForm
} from '../dependencies/utils';
import InlineError from '../../common/inlineError';
import { toast } from "react-toastify";


const StartupApplicationForm = (props) => {
  const {
    getInfoForStartupAppliactionForm,
    getDistrictData,
    getSubDistrictList,
    match,
    history,
    natureOfTheEntity,
    newNatureOfTheEntity,
    startupIndustry,
    startupEntitySector,
    startupServices,
    statesAndUnionTerritory,
    startupDistrict,
    startupSubDistrict,
    activeStartupApplication = {},
    getStartupSector,
    updateStartupForm,
    isLoading
  } = props;

  const [applicationData, setApplicationData] = useState({});

  const startupEntity = get(activeStartupApplication, 'startupEntity');
  const address = get(activeStartupApplication, 'address');
  const incorporationNumber = get(activeStartupApplication, 'incorporationNumber');
  const representative = get(activeStartupApplication, 'representative');
  const directors = get(activeStartupApplication, 'directors') || [];
  const activities = get(activeStartupApplication, 'activities') || {};
  const additionalInformation = get(activeStartupApplication, 'additionalInformation');
  const selfCertificate = get(activeStartupApplication, 'selfCertificate');
  const isPreCertified = get(activeStartupApplication, 'isPreCertified');
  const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] = useState(false);
  const [error, setError] = useState({})
  const [checked, setChecked] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);

  const id = get(match, 'params.id');

  useEffect(() => {
    if (!isEmpty(activeStartupApplication)) {
      setApplicationData(data => {
        return { ...data, ...activeStartupApplication }
      })
      const { activities, additionalInformation, } = applicationData
    }
  }, [activeStartupApplication])

  useEffect(() => {
    getInfoForStartupAppliactionForm({ id });
  }, [])

  const onChangeHandler = (params) => {
    setApplicationData(data => {
      return { ...data, ...params };
    })
  }
  const handleInputChange = (e, name) => {
    if (e && e.target) {
      const value = e.target.checked;
      setApplicationData(data => {
        return { ...data, ['tnc']: value }
      })
    }
  }
  const validateEntityForm = () => {
    const formStatus = validateStartupEntityForm(applicationData);
    const errors = {
      startupIndustry: formStatus.startupIndustry,
      startupNatureOfEntity: formStatus.startupNatureOfEntity,
      startupServices: formStatus.startupServices,
      startupSection: formStatus.startupSection,
      // tnc: formStatus.tnc,
    };
    setError(errors);
    return !formStatus.hasErrors;
  };
  const validateInfoForm = () => {
    const formStatus = validateStartupInfoForm(applicationData);
    const errors = {
      numberOfEmployees: formStatus.numberOfEmployees,
      scalableBusinessModel: formStatus.scalableBusinessModel,
      stage: formStatus.stage,
      workCategory: formStatus.workCategory,
      iprs: formStatus.iprs,
      note: formStatus.note,
      receiveFunding: formStatus.receiveFunding,
      // tnc: formStatus.tnc,

    };
    setError(errors);
    return !formStatus.hasErrors;
  };
  const validateAddressForm = () => {
    const formStatus = validateStartupAddressForm(applicationData);
    const errors = {
      address1: formStatus.address1,
      address2: formStatus.address2,
      city: formStatus.city,
      stateId: formStatus.stateId,
      districtId: formStatus.districtId,
      subDistrictId: formStatus.subDistrictId,
      pinCode: formStatus.pinCode,
      // tnc: formStatus.tnc,
    };
    setError(errors);
    return !formStatus.hasErrors;
  };
  const validateRepresentativeForm = () => {
    const formStatus = validateStartupRepresentativeForm(applicationData);
    const errors = {
      name: formStatus.name,
      designation: formStatus.designation,
      mobile: formStatus.mobile,
      email: formStatus.email,
      // tnc: formStatus.tnc,
    };
    setError(errors);
    return !formStatus.hasErrors;
  };
  const validateDirectorForm = () => {
    const formStatus = validateStartupDirectorForm(applicationData);
    const errors = {
      noOfDirectors: formStatus.noOfDirectors,
      directors: formStatus.directors,
      gender: formStatus.gender,
      // tnc: formStatus.tnc,
      // din:formStatus.din
    };
    setError(errors);
    return !formStatus.hasErrors;
  };
  const validateActivityForm = () => {
    const formStatus = validateStartupActivityForm(applicationData);
    const errors = {
      generateRevenue: formStatus.generateRevenue,
      problemSolution: formStatus.problemSolution,
      problemStatement: formStatus.problemStatement,
      solutionUniqueness: formStatus.solutionUniqueness,
      awardDocument: formStatus.awardDocument,
      awardsReceived: formStatus.awardsReceived,
      // tnc: formStatus.tnc,
    };
    setError(errors);
    return !formStatus.hasErrors;
  };
  const validateCertificateForm = () => {
    const formStatus = validateStartupCertificateForm(applicationData);
    const errors = {
      registrationCertificate: formStatus.registrationCertificate,
      // tnc: formStatus.tnc,
    };
    setError(errors);
    return !formStatus.hasErrors;
  };
  const validateForm = () => {
   
    const formStatus = validateStartupForm(applicationData);
   
    const errors = {
      startupIndustry: formStatus.startupIndustry,
      startupNatureOfEntity: formStatus.startupNatureOfEntity,
      startupServices: formStatus.startupServices,
      startupSection: formStatus.startupSection,
      address1: formStatus.address1,
      address2: formStatus.address2,
      city: formStatus.city,
      stateId: formStatus.stateId,
      districtId: formStatus.districtId,
      // subDistrictId: formStatus.subDistrictId,
      pinCode: formStatus.pinCode,
      name: formStatus.name,
      designation: formStatus.designation,
      mobile: formStatus.mobile,
      email: formStatus.email,
      gender: formStatus.gender,
      noOfDirectors: formStatus.noOfDirectors,
      receiveFunding: formStatus.receiveFunding,
      numberOfEmployees: formStatus.numberOfEmployees,
      scalableBusinessModel: formStatus.scalableBusinessModel,
      stage: formStatus.stage,
      workCategory: formStatus.workCategory,
      iprs: formStatus.iprs,
      generateRevenue: formStatus.generateRevenue,
      problemSolution: formStatus.problemSolution,
      problemStatement: formStatus.problemStatement,
      solutionUniqueness: formStatus.solutionUniqueness,
      registrationCertificate: formStatus.registrationCertificate,
      directors: formStatus.directors,
      // tnc: formStatus.tnc,
      awardDocument: formStatus.awardDocument,
      awardsReceived: formStatus.awardsReceived,
      note: formStatus.note
    };
  
    setError(errors);
    return !formStatus.hasErrors;
  };


  const doReset = (detailType) => {
    // setApplicationData(activeStartupApplication);
    if (getInfoForStartupAppliactionForm) {
      getInfoForStartupAppliactionForm({ id })


    }
  }
  const doSubmit = (detailType) => {
    if (detailType === "ENTITY") {
      if (validateEntityForm()) {
        if (updateStartupForm) {
          const { incorporationDate } = startupEntity;
          if (incorporationDate) {
            applicationData.startupEntity.incorporationDate = moment(incorporationDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
          } else {
            return 
          }
          const entityData = {};
          entityData.startupEntity = applicationData.startupEntity;
          entityData.id = applicationData.id;
          entityData.incorporationNumber = applicationData.incorporationNumber;
          entityData.recognitionDetailType = detailType;
           if(checked)
           {
            entityData['newIncorporationNumber'] = applicationData.startupEntity.newIncorporationNumber;
            entityData['isCOREnabled'] = applicationData.startupEntity.isCOREnabled;
           }
          else{
            delete applicationData.startupEntity.newIncorporationNumber;
            delete applicationData.startupEntity.isCOREnabled;
            delete applicationData.startupEntity.newNameOfEntity;
            delete applicationData.startupEntity.newIncorporationDate;
          }
     
          // delete applicationData.startupEntity.newIncorporationNumber;
          // delete applicationData.startupEntity.isCOREnabled;
          updateStartupForm({
            ...entityData
          }).then((data) => {
            
            const { status = false } = data;
            if (status) {
             
              setChecked(false);
              setFormSubmit(true);
              // history.push('/application/startup');
              if (getInfoForStartupAppliactionForm) {
                getInfoForStartupAppliactionForm({ id })
              }
            }
          });
        }
      }
    }
    if (detailType === "INFORMATION") {
      if (validateInfoForm()) {
        if (updateStartupForm) {
          const { incorporationDate } = startupEntity;
          if (incorporationDate) {
            applicationData.startupEntity.incorporationDate = moment(incorporationDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
          } else {
            return
          }
          const infoData = {};
          infoData.additionalInformation = applicationData.additionalInformation;
          infoData.id = applicationData.id;
          infoData.incorporationNumber = applicationData.incorporationNumber;
          infoData.recognitionDetailType = detailType;
          
          updateStartupForm({
            ...infoData
          }).then((data) => {
            const { status = false } = data;
            if (status) {
              if (getInfoForStartupAppliactionForm) {
                getInfoForStartupAppliactionForm({ id })
              }
              // history.push('/application/startup');
            }
          });
        }
      }
    }
    if (detailType === "ADDRESS") {
      
      if (validateAddressForm()) {
        
        if (updateStartupForm) {
          const { incorporationDate } = startupEntity;
          if (incorporationDate) {
            applicationData.startupEntity.incorporationDate = moment(incorporationDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
          } else {
            return
          }
          const addressData = {};
          addressData.address = applicationData.address;
          addressData.id = applicationData.id;
          addressData.incorporationNumber = applicationData.incorporationNumber;
          addressData.recognitionDetailType = detailType;
          // console.log("addressData", addressData);
          // console.log("applicationData", applicationData)
          updateStartupForm({
            ...addressData
          }).then((data) => {
            const { status = false } = data;
            if (status) {
              if (getInfoForStartupAppliactionForm) {
                getInfoForStartupAppliactionForm({ id })
              }
              // history.push('/application/startup');
            }
          });
        }
      }
    }
    if (detailType === "REPRESENTATIVE") {
      if (validateRepresentativeForm()) {
        if (updateStartupForm) {
          const { incorporationDate } = startupEntity;
          if (incorporationDate) {
            applicationData.startupEntity.incorporationDate = moment(incorporationDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
          } else {
            return
          }
          const representativeData = {};
          representativeData.representative = applicationData.representative;
          representativeData.id = applicationData.id;
          representativeData.incorporationNumber = applicationData.incorporationNumber;
          representativeData.recognitionDetailType = detailType;
          // console.log("representativeData", representativeData);
          // console.log("applicationData", applicationData)
          updateStartupForm({
            ...representativeData
          }).then((data) => {
            const { status = false } = data;
            if (status) {
              if (getInfoForStartupAppliactionForm) {
                getInfoForStartupAppliactionForm({ id })
              }
              // history.push('/application/startup');
            }
          });
        }
      }
    }
    if (detailType === "DIRECTOR") {
      if (validateDirectorForm()) {
        if (updateStartupForm) {
          const { incorporationDate } = startupEntity;
          if (incorporationDate) {
            applicationData.startupEntity.incorporationDate = moment(incorporationDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
          } else {
            return
          }
          const directorsData = {};
          directorsData.directors = applicationData.directors;
          directorsData.id = applicationData.id;
          directorsData.incorporationNumber = applicationData.incorporationNumber;
          directorsData.recognitionDetailType = detailType;
          // console.log("directorsData", directorsData);
          // console.log("applicationData", applicationData)
          updateStartupForm({
            ...directorsData
          }).then((data) => {
            const { status = false } = data;
            if (status) {
              if (getInfoForStartupAppliactionForm) {
                getInfoForStartupAppliactionForm({ id })
              }
              // history.push('/application/startup');
            }
          });
        }
      }
    }
    if (detailType === "ACTIVITIES") {
      if (validateActivityForm()) {
        if (updateStartupForm) {
          const { incorporationDate } = startupEntity;
          if (incorporationDate) {
            applicationData.startupEntity.incorporationDate = moment(incorporationDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
          } else {
            return
          }
          const activitiesData = {};
          activitiesData.activities = applicationData.activities;
          activitiesData.id = applicationData.id;
          activitiesData.incorporationNumber = applicationData.incorporationNumber;
          activitiesData.recognitionDetailType = detailType;
          updateStartupForm({
            ...activitiesData
          }).then((data) => {
            const { status = false } = data;
            if (status) {
              if (getInfoForStartupAppliactionForm) {
                getInfoForStartupAppliactionForm({ id })
              }
              // history.push('/application/startup');
            }
          });
        }
      }
    }
    if (detailType === "CERTIFICATION") {
      if (validateCertificateForm()) {
        if (updateStartupForm) {
          const { incorporationDate } = startupEntity;
          if (incorporationDate) {
            applicationData.startupEntity.incorporationDate = moment(incorporationDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
          } else {
            return
          }
          const selfCertificateData = {};
          selfCertificateData.selfCertificate = applicationData.selfCertificate;
          selfCertificateData.id = applicationData.id;
          selfCertificateData.incorporationNumber = applicationData.incorporationNumber;
          selfCertificateData.recognitionDetailType = detailType;
          updateStartupForm({
            ...selfCertificateData
          }).then((data) => {
            const { status = false } = data;
            if (status) {
             
              if (getInfoForStartupAppliactionForm) {

                getInfoForStartupAppliactionForm({ id })
              }
              // history.push('/application/startup');
            }
          });
        }
      }
    }

    if (detailType === "ALL") {
      if (validateForm()) {
        if (updateStartupForm) {
          const { incorporationDate } = startupEntity;
          if (incorporationDate) {
            applicationData.startupEntity.incorporationDate = moment(incorporationDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
          } else {
            return;
          }
          updateStartupForm({
            ...applicationData
          }).then((data) => {
            const { status = false } = data;
            if (status) {
              history.push('/application/startup');
            }
          });
        }
      }
      else{
        toast.error("Please complete the form for the submission.");
      }
    }
  }
  return (
    <form>
      <div className="startup-application-form">
        <Accordion allowMultipleOpen>
          <div label='Entity Details' isOpen >
            <div className="accordion-content">
              <EntityDetail
                natureOfTheEntity={natureOfTheEntity}
                newNatureOfTheEntity={newNatureOfTheEntity}
                startupIndustry={startupIndustry}
                startupEntitySector={startupEntitySector}
                startupServices={startupServices}
                startupEntity={startupEntity}
                incorporationNumber={incorporationNumber}
                getStartupSector={getStartupSector}
                onChangeHandler={onChangeHandler}
                error={error}
                doSubmit={(detailType) => doSubmit(detailType)}
                doReset={(detailType) => doReset(detailType)}
                setChecked={setChecked}
                checked ={checked}
                formSubmit={formSubmit}
              />
            </div>
          </div>
          <div label='Full Address (Office)'>
            <div className="accordion-content">
              <Address
                statesAndUnionTerritory={statesAndUnionTerritory}
                startupDistrict={startupDistrict}
                startupSubDistrict={startupSubDistrict}
                address={address}
                getDistrictData={getDistrictData}
                getSubDistrictList={getSubDistrictList}
                onChangeHandler={onChangeHandler}
                error={error}
                doSubmit={(detailType) => doSubmit(detailType)}
                doReset={(detailType) => doReset(detailType)}
              />
            </div>
          </div>
          <div label='Authorized Representative Details'>
            <div className="accordion-content">
              <Authorized
                representative={representative}
                onChangeHandler={onChangeHandler}
                error={error}
                doSubmit={(detailType) => doSubmit(detailType)}
                doReset={(detailType) => doReset(detailType)}
              />
            </div>

          </div>
          <div label='Director (s) / Partner (s) Details'>
            <div className="accordion-content">
              <DirectorsAndPartners
                directors={directors}
                onChangeHandler={onChangeHandler}
                error={error}
                doSubmit={(detailType) => doSubmit(detailType)}
                doReset={(detailType) => doReset(detailType)}
                natureOfEntityName={activeStartupApplication?.startupEntity?.startupNatureOfEntity?.name}
              />
            </div>

          </div>
          {/*<div label='Information Required'>
            <div className="accordion-content">
              <Information
                activities={activities}
                additionalInformation={additionalInformation}
                onChangeHandler={onChangeHandler}
                isLoading={isLoading}
                error={error}
              />
            </div>

  </div>*/}
          <div label='Information Required'>
            <div className="accordion-content">
              <AdditionalInformation
                activities={activities}
                additionalInformation={additionalInformation}
                onChangeHandler={onChangeHandler}
                isLoading={isLoading}
                error={error}
                doSubmit={(detailType) => doSubmit(detailType)}
                doReset={(detailType) => doReset(detailType)}
              />
            </div>
          </div>
          <div label='Activities'>
            <div className="accordion-content">
              <Activities
                activities={activities}
                additionalInformation={additionalInformation}
                onChangeHandler={onChangeHandler}
                isLoading={isLoading}
                error={error}
                doSubmit={(detailType) => doSubmit(detailType)}
                doReset={(detailType) => doReset(detailType)}
              />
            </div>
          </div>

          <div label='Self Certification'>
            <div className="accordion-content">
              <SelfCertification
                selfCertificate={selfCertificate}
                onChangeHandler={onChangeHandler}
                isLoading={isLoading}
                error={error}
                doSubmit={(detailType) => doSubmit(detailType)}
                isPreCertified={activeStartupApplication.isPreCertified}
                natureOfEntityName={activeStartupApplication?.startupEntity?.startupNatureOfEntity?.name}
              />
            </div>

          </div>
        </Accordion>
        <div className="i-certify-wrap">
          <div className=" mb-20">

            <h5> <input type="checkbox" /> I certify that </h5>
            <ul>
            <li>has not been incorporated for more than 7 years, (10 years for Startups in the biotechnology sector); and</li>
              <li>is working towards innovation, development or improvement of products or processes or services, or if it is a scalable business model with a high potential of employment generation or wealth creation; and</li>
              <li>has not formed the entity by splitting up or reconstruction of a business already in existence;</li>
              {/* <li>The entity has not been incorporated for more than 10 years from the date of incorporation/ registration.</li>
              <li>Turnover of the entity for any of the financial years since incorporation/ registration has not exceeded one hundred crore rupees.</li>
              <li>Entity is working towards innovation, development or improvement of products or processes or services, or if it is a scalable business model with a high potential of employment generation or wealth creation.</li>
              <li>Entity is not formed by splitting up or reconstruction of an existing business.</li>
              <li>The entity is not formed due to compromise/ arrangement as provided under the Companies Act, 2013.</li>
              <li>The entity is neither incorporated/registered as a subsidiary of any Indian or foreign entity nor it is incorporated/registered as a holding company of any Indian or foreign entity.(Please note that any startup becoming holding/subsidiary of any company after obtaining recognition will be derecognized) </li>
              <li>The entity is not formed by Joint Venture. (Please note that any startup entering into any Joint Venture after obtaining recognition will be derecognized) </li>
              <li>The entity is not incorporated outside the Indian territory. </li>
              <li>The shareholding by Indian promoters in the startup is atleast 51%, as per Companies Act, 2013 and SEBI (ICDR) Regulations, 2018.</li>
              <li>The entity has not incorporated additional entities having similar address with same production line/services and at least one common director/ designated partner/partner.</li>
              <li>The entity does not operate in domains specifically prohibited by law. </li>
              <li>The entity is not a sole proprietorship.</li>
              <li>The entity having common director/designated partner/ partner with any other entity fulfils the provisions of the Companies Act, 2013. I further certify that the entity shall not undertake any related party transaction with such common entities except transactions on arm’s length basis. </li> */}
            </ul>
            <h5>Please Note:</h5>
            <p className="mb-20">The Certificate of Recognition for startups will now be issued after examination of the application and document submission  </p>
            <div>
              <input type="checkbox" name='tnc' onChange={e => handleInputChange(e)} /><span className="tnc">I accept the <Link to='#'>Terms &amp; Conditions</Link></span>
            </div>
            {
              error.tnc && (
                <InlineError message={error.tnc} />
              )
            }
          </div>
        </div>
        <div>
          <Button
            className="bttn mb-20"
            type="button"
            text="Submit"
            onClick={() => doSubmit("ALL")}
          />
          <Button
            className="bttn blue mb-20"
            type="button"
            text="Reset"
            onClick={doReset}
          />
        </div>
      </div>
    </form>
  );
}
StartupApplicationForm.propTypes = {
  activeStartupApplication: PropTypes.object,
};

StartupApplicationForm.defaultProps = {
  activeStartupApplication: {}
};
const mapStateToProps = (state) => {
  return {
    natureOfTheEntity: state.startupApplicationForm.natureOfTheEntity,
    newNatureOfTheEntity: state.startupApplicationForm.natureOfTheEntity,
    startupIndustry: state.startupApplicationForm.startupIndustry,
    startupEntitySector: state.startupApplicationForm.startupEntitySector,
    startupServices: state.startupApplicationForm.startupServices,
    activeStartupApplication: state.startupApplicationForm.activeStartupApplication,
    statesAndUnionTerritory: state.startupApplicationForm.statesAndUnionTerritory,
    startupDistrict: state.startupApplicationForm.startupDistrict,
    startupSubDistrict: state.startupApplicationForm.startupSubDistrict,
  }
}
export default connect(mapStateToProps, { getInfoForStartupAppliactionForm, getDistrictData, getSubDistrictList, getStartupSector, updateStartupForm, isLoading })(withRouter(StartupApplicationForm));
