import { ADD_USER, ADD_USER_SUCCESS, ADD_USER_FAILED } from '../../constants/actionConstants';


const initialState = {
    status: true,
    message: '',
    error: false
};

export default (state = initialState, action) => {
    let newState;
    switch (action.type) {

        case ADD_USER:
            newState = {
                ...state,
                message: '',
                status: true,
                error: false
            };
            return newState;
        case ADD_USER_SUCCESS:
            newState = {
                ...state,
                message: action.payload,
                status: true,
                error: false
            };
            return newState;
        case ADD_USER_FAILED:
            newState = {
                ...state,
                message: action.payload,
                status: false,
                error: true
            };
            return newState;

        default:
            return state;
    }
}
