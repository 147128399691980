import React, { useState, useEffect } from 'react';
import { API_URL } from '../../../../../../constants/ApplicationUrl'
import { get, isEmpty } from 'lodash';
import TextareaField from '../../../../../common/TextareaField';
import InputField from '../../../../../common/InputField';
import Radio from '../../../../../common/InputRadio';
import FileUpload from '../../../../../common/FileUpload';
import { uploadFileService } from '../../../../../../dependencies/services/uploadFileService';
import { unEscapeRegHtml } from '../../../../../../dependencies/utils/helper';
import Button from '../../../../../common/Button'
import InlineError from '../../../../../common/inlineError'

const Activities = (props) => {
  const { activities, onChangeHandler, error, isLoading, doSubmit, doReset } = props;
  const defaultFormData = {
    "awardDocument": null,
    "awardsReceived": "",
    "generateRevenue": "",
    "problemSolution": "",
    "problemStatement": "",
    "solutionUniqueness": ""
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [showAwardDocuments, setShowAwardDocument] = useState("no");

  useEffect(() => {
    if (showAwardDocuments === "no") {
      setFormData(d => {
        return { ...d, awardDocument: null, awardsReceived: "" }
      })
    }
  }, [showAwardDocuments])

  useEffect(() => {
    if (!isEmpty(activities)) {
      setFormData(data => {
        return { ...data, ...activities }
      });
      if (activities && activities.awardsReceived && (activities.awardsReceived.length > 0 || activities.awardDocument)) {
        setShowAwardDocument("yes");
      }
    }
  }, [activities]);


  const handleInputChange = (e, name) => {
    if (e && e.target) {
      const value = e.target.value;
      setFormData((data) => {
        return { ...data, [name]: value }
      });
    }
  }

  const onUploadFile = (file) => {
    isLoading(true)
    const data = new FormData();
    data.append('file', file)
    data.append('documentType', 'STARTUP_AWARD_DOCUMENT');
    uploadFileService.uploadFile(data).then((data) => {
      if (data) {
        setFormData(d => {
          return { ...d, awardDocument: data };
        });
      }
      isLoading(false);
    }, (error) => {
      isLoading(false);
    });
  }

  useEffect(() => {
    if (onChangeHandler) {
      onChangeHandler({
        activities: { ...formData, showAwards: showAwardDocuments }
      })
    }
  }, [formData]);

  const awardFileViewPath = formData.awardDocument && formData.awardDocument.publicUrl || "";

  return (
    <div className="activities-container">
      <div className="row mb-20">
        <div className="col-12">
          <label>Has Your Startup Received Any Awards/Recognition?</label>

          <div className="row no-gutter">
            <div className="col-sm-3">
              <Radio
                type="radio"
                label='Yes'
                value={"yes"}
                name='awardDetail'
                checked={showAwardDocuments === "yes"}
                onChange={(e) => {
                  setShowAwardDocument(e.target.value);
                }}
              />
            </div>
            <div className="col-sm-3">
              <Radio
                type="radio"
                label='No'
                value={"no"}
                name='awardDetail'
                checked={showAwardDocuments === "no"}
                onChange={(e) => {
                  setShowAwardDocument(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {
        showAwardDocuments === "yes" && (
          <React.Fragment>
            <div className="row mb-20">
              <div className="col-sm-12">
                <TextareaField
                  required
                  label='Please mention any awards/recognition received by the entity (If applicable - 400 Characters maximum)'
                  rows={4}
                  name='awardsReceived'
                  value={unEscapeRegHtml(formData.awardsReceived)}
                  onChange={handleInputChange}
                  error={error.awardsReceived}
                />
              </div>
            </div>
            <div className="row mb-20">
              <div className="col-sm-12">
                {awardFileViewPath &&
                  <label>Award Document <a href={API_URL() + awardFileViewPath} target="_blank">{formData.awardDocument && formData.awardDocument.compressedName}</a></label>
                }
                <FileUpload
                  onUploadFile={onUploadFile}
                  allowedFiles={[".pdf", ".png", ".jpg"]}
                  error={error.awardDocument}
                  label='Upload Award Document'
                  hasInvalidSubmitOccurred={true}
                  maxFileSizeInMB={5}

                />
                <span className="text-note">
                  (The document must be uploaded in pdf,png,jpg format with file size not exceeding 5 MB)
          </span>
              </div>
            </div>
          </React.Fragment>
        )
      }
      <div className="row mb-20">
        <div className="col-sm-12">
          <TextareaField
            label='What is the problem the startup is solving? (400 Characters maximum)'
            required
            rows={4}
            name='problemStatement'
            value={unEscapeRegHtml(formData.problemStatement)}
            onChange={handleInputChange}
            error={error.problemStatement}
            hasInvalidSubmitOccurred={true}
          />
        </div>
      </div>
      <div className="row mb-20">
        <div className="col-sm-12">
          <TextareaField
            label='How does your startup propose to solve this problem? (400 Characters maximum)'
            required
            rows={4}
            name='problemSolution'
            value={unEscapeRegHtml(formData.problemSolution)}
            onChange={handleInputChange}
            error={error.problemSolution}
            hasInvalidSubmitOccurred={true}
          />
        </div>
      </div>
      <div className="row mb-20">
        <div className="col-sm-12">
          <TextareaField
            label='What is the uniqueness of your solution? (400 Characters maximum)'
            required
            rows={4}
            name='solutionUniqueness'
            value={unEscapeRegHtml(formData.solutionUniqueness)}
            onChange={handleInputChange}
            error={error.solutionUniqueness}
            hasInvalidSubmitOccurred={true}
          />
        </div>
      </div>
      <div className="row mb-20">
        <div className="col-sm-12">
          <TextareaField
            label='How does your startup generate revenue? (400 Characters maximum)'
            required
            rows={4}
            name='generateRevenue'
            value={unEscapeRegHtml(formData.generateRevenue)}
            onChange={handleInputChange}
            error={error.generateRevenue}
            hasInvalidSubmitOccurred={true}
          />
        </div>
      </div>
      {
        error.tnc && (
          <div className="row mb-20">
            <div className="col-sm-12">
              <InlineError message={`Go to "Self Certification" and ${error.tnc}`} />
            </div>
          </div>
        )
      }
      <div className="row mb-20 text-center">
        <div className="col-sm-12">
          <Button
            className="bttn mb-20"
            type="button"
            text="Save"
            onClick={() => doSubmit("ACTIVITIES")}
          />
          
        </div>
      </div>
    </div>)

}


export default Activities;