import api from '../utils/Api';
import { FETCH_USER_LIST_URL,FETCH_USER_LIST_URL_COUNT,FETCH_USER_LIST_FORM_56,FETCH_USER_LIST_FROM_RECOGNATION } from "../../constants/ApplicationUrl";
import { fetchUserList,fetchUserListFiftySix,fetchUserListStartupUsers, clearUserList, isLoading } from "../../constants/actionConstants";


// export const getUserList = (params) => (dispatch) => {
//     dispatch(isLoading(true));
//     return api.POST(FETCH_USER_LIST_URL, params)

//         .then((data) => {
//             if (data && data.data && data.data.data) {
//                 dispatch(fetchUserList(data.data));
//             }
//             dispatch(isLoading(false));
//         })

// };
export const getUserList = (params) => (dispatch) => {
    dispatch(isLoading(true));
    return api.GET(`${FETCH_USER_LIST_URL_COUNT}?role=${params.role}`)

        .then((data) => {
            
            if (data && data.data) {
                dispatch(fetchUserList(data));
            }
            dispatch(isLoading(false));
        })

};

export const getUserList56 = (params) => (dispatch) => {
    dispatch(isLoading(true));
    return api.GET(`${FETCH_USER_LIST_FORM_56}/${params.role}`)

        .then((data) => {
            
            if (data && data.data) {
                dispatch(fetchUserListFiftySix(data));
            }
            dispatch(isLoading(false));
        })
};

export const getUserRecognatio = (params) => (dispatch) => {
    dispatch(isLoading(true));
    return api.GET(`${FETCH_USER_LIST_FROM_RECOGNATION}/${params.role}`)
        .then((data) => {            
            if (data && data.data) {
                dispatch(fetchUserListStartupUsers(data));
            }
            dispatch(isLoading(false));
        })
}


export const clearUserListData = () => (dispatch) => {
    dispatch(clearUserList(''));
}
