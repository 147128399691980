import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { SUPPORTING_DOCUMENTS, headerSortingStyle } from '../../../../constants';
import { ROLES } from '../../../../constants/Roles';
import { storage } from '../../../../dependencies/store/storage';
import { getDocumentLink } from '../../../../dependencies/utils/helper';


const iasListColumns = ({
    clickOnActionIncomplete,
    clickOnAction,
    ApproveRejectAction,
    pageNo = 0,
    size = 10
}) => {
    const userRole = storage.getUserRole();
    const getUser = storage.getUser();
    if(userRole === ROLES.SUPER_ADMIN) {
        return [
            {
                dataField: '',
                text: 'Sr.No',
                formatter: (cell, row, rowIndex) => {
                    return (pageNo * size) + (rowIndex + 1);
                }
            }, {
                dataField: 'recognitionNumber',
                text: 'COR Number',
                formatter: (cell, row, rowIndex) => {
                    return cell
                }
            }, {
                dataField: 'id',
                text: '',
                hidden: true
            }, 
            
            {
                dataField: 'applicationDate',
                text: 'Application Date',
                sort: true,
                formatter: (cell) => {
                    if (cell) {
                        return moment(cell).format('DD/MM/YYYY');
                    }
                },
            },
            {
                dataField: '',
                text: 'Action',
                hidden: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.NRDC_ADMIN, ROLES.TIFAC_ADMIN].indexOf(userRole) === -1,
                formatter: (cell, row) => {
                    const { id } = row;
                    let exceptNrdcUser = userRole === ROLES.NRDC_ADMIN
    
                    return (
                        <div className="IAS-Actions-list">
                            <ul>
                                <li><Link to={`/application/80iac/${id}`} target="_blank">View</Link></li>
                                {/* <li><Link to={`/imb-members-comment/${id}`}>Comments by IMB Members</Link></li> */}
                                {/* {
                                    (userRole === ROLES.SUPER_ADMIN || userRole === ROLES.ADMIN) &&
    
                                    <li><a href="javascript:void(0)" onClick={() => clickOnAction(id)}>Observation Communicated</a></li>
                                } */}
                                <li><Link to={`/application/80iac/thread/${id}`} target="_blank">Thread</Link></li>
                                {/* <li><Link to={`/NRDClist/${id}`}>Technical Team Comments</Link></li> */}
                                {/* <li><Link to={`/application/80iac/comment/${id}`} target="_blank">Technical Team Comments</Link></li> */}
                                
                                <li><a href="javascript:void(0)" onClick={() => clickOnActionIncomplete(id)}>View Incomplete History</a></li>
                                
                                {/* <li><a href="javascript:void(0)" onClick={() => ApproveRejectAction(id)}>Approve/Reject</a></li> */}
    
                            </ul>
                        </div>
                    )
    
                }
            },
            
            {
                dataField: 'statusLabel',
                text: 'Status',
            },
            
             {
                dataField: 'incorporationNumber',
                text: 'Registration No',
                sort: false,
                headerSortingStyle
            }, {
                dataField: 'entityName',
                text: 'Name of the Entity',
                sort: false,
                headerSortingStyle
            }, 
            {
                dataField: 'recommendStatusIMBChair',
                text: 'Last Updated Date',
                sort: false,
                formatter: (cell, row) => {       
                    if(row.status === 'PENDING' && row.recommendStatusAdmin && row.recommendStatusAdmin.assignDate) {
                        return moment(row.recommendStatusAdmin.assignDate).format('DD/MM/YYYY')
                    }else if(row.status === 'PENDING' && !row.recommendStatusAdmin && row.lastUpdatedDate) {
                        return moment(row.lastUpdatedDate).format('DD/MM/YYYY')
                    }else if(row.status === 'DOCUMENT_VERIFIED' && row.recommendStatusAdmin && row.recommendStatusAdmin.actionTakenDate){
                        return moment(row.recommendStatusAdmin.actionTakenDate).format('DD/MM/YYYY')
                    }else if(cell && cell.actionTakenDate){
                        return moment(cell.actionTakenDate).format('DD/MM/YYYY')
                    } else {
                        
                    }                    
                }
            },
            {
                dataField: 'eligibilityCertificate',
                text: 'Certificate Of Eligibility',
                hidden: userRole === ROLES.NRDC_ADMIN,
                formatter: (cell) => {
                    if (cell && cell.publicUrl) {
                        return (<a href={`${getDocumentLink(cell.publicUrl)}`} target="_blank" >view document</a>)
                    }
                }
            }            
        ];
    }else if(userRole === ROLES.EP_1 || userRole === ROLES.EP_2){
        return [
            {
                dataField: '',
                text: 'S.No',
                formatter: (cell, row, rowIndex) => {
                    let {id} = row
                    return (
                        <Link to={`/application/80iac/${id}`} target="_blank">{(pageNo * size) + (rowIndex + 1)}</Link>
                    )
                    return (pageNo * size) + (rowIndex + 1);
                }
            }, 
            {
                dataField: 'id',
                text: '',
                hidden: true
            },
            {
                dataField: 'recognitionNumber',
                text: 'COR Number',
                formatter: (cell, row, rowIndex) => {
                    let {id} = row
                    return (
                        <Link to={`/application/80iac/${id}`} target="_blank">{cell}</Link>
                    )
                    return cell
                }
            },  
            {
                dataField: 'recommendStatusEP',
                text: 'Receiving date',
                formatter: (cell, row) => {
                    if (cell) {
                        let {id} = row
                        let newdate = cell.filter((el) => {
                            return el.adminName == getUser.user.name
                        }) 
                        return (
                            <Link to={`/application/80iac/${id}`} target="_blank">{moment(newdate[0].assignDate).format('DD/MM/YYYY')}</Link>
                        )
                        return moment(cell).format('DD/MM/YYYY')
    
    
                    }
                    
                },
                sort: false,
                headerSortingStyle
            },
            {
                dataField: 'recommendStatusEP',
                text: 'Status',
                sort: false,
                formatter: (cell, row) => {
                    let {id} = row
                    let status = ''
                    if(cell) {
                        status = cell.filter((el) => {
                            return el.adminName == getUser.user.name
                        }) 
                    } 
                    if(!status[0]) {
                        window.location.reload();
                    }
                    if (status[0].statusEP == 'NOT_RECOMMENDED')   {
                        status[0].statusEP = 'NOT RECOMMENDED'
                    }         
                    return (
                        <Link to={`/application/80iac/${id}`} target="_blank">{status[0].statusEP}</Link>
                    )
                    return cell
                },
            },
            
            {
                dataField: 'incorporationNumber',
                text: 'Registration No',
                sort: false,
                headerSortingStyle,
                formatter: (cell, row) => {
                    let {id} = row
                    return (
                        <Link to={`/application/80iac/${id}`} target="_blank">{cell}</Link>
                    )
                }
            }, 
            {
                dataField: 'entityName',
                text: 'Name of the Entity',
                sort: false,
                headerSortingStyle,
                formatter: (cell, row) => {
                    let {id} = row
                    return (
                        <Link to={`/application/80iac/${id}`} target="_blank">{cell}</Link>
                    )
                }
            }, 
            
        ];
    }else if(userRole === ROLES.IMB_MEMBER) {
        return [
            {
                dataField: '',
                text: 'S.No',
                formatter: (cell, row, rowIndex) => {
                    let {id} = row
                    return (
                        <Link to={`/application/80iac/${id}`} target="_blank">{(pageNo * size) + (rowIndex + 1)}</Link>
                    )
                    return (pageNo * size) + (rowIndex + 1);
                }
            }, 
            {
                dataField: 'id',
                text: '',
                hidden: true
            },
            {
                dataField: 'recognitionNumber',
                text: 'COR Number',
                formatter: (cell, row, rowIndex) => {
                    let {id} = row
                    return (
                        <Link to={`/application/80iac/${id}`} target="_blank">{cell}</Link>
                    )
                    return cell
                }
            },  
            {
                dataField: 'recommendStatusIMBMember',
                text: 'Receiving date',
                formatter: (cell, row) => {
                    if (cell) {
                        let {id} = row
                        let newdate = cell.filter((el) => {
                            return el.adminName == getUser.user.name
                        }) 
                        return (
                            <Link to={`/application/80iac/${id}`} target="_blank">{moment(newdate[0].assignDate).format('DD/MM/YYYY')}</Link>
                        )
                        return moment(cell).format('DD/MM/YYYY')
    
    
                    }
                    
                },
                sort: false,
                headerSortingStyle
            },
            {
                dataField: 'recommendStatusIMBMember',
                text: 'Status',
                sort: false,
                formatter: (cell, row) => {
                    let {id} = row
                    let status = ''
                    if(cell) {
                        status = cell.filter((el) => {
                            return el.adminName == getUser.user.name
                        }) 
                    }
                    if (status && status[0].statusImbMember == 'NOT_RECOMMENDED')   {
                        status[0].statusImbMember = 'NOT RECOMMENDED'
                        
                    } 
                    return (
                        <Link to={`/application/80iac/${id}`} target="_blank">{status[0].statusImbMember}</Link>
                    )
                    
                    return cell
                },
            },
            
            {
                dataField: 'incorporationNumber',
                text: 'Registration No',
                sort: false,
                headerSortingStyle,
                formatter: (cell, row) => {
                    let {id} = row
                    return (
                        <Link to={`/application/80iac/${id}`} target="_blank">{cell}</Link>
                    )
                }
            }, 
            {
                dataField: 'entityName',
                text: 'Name of the Entity',
                sort: false,
                headerSortingStyle,
                formatter: (cell, row) => {
                    let {id} = row
                    return (
                        <Link to={`/application/80iac/${id}`} target="_blank">{cell}</Link>
                    )
                }
            }, 
            
        ];
    }else if(userRole === ROLES.IMB_CHAIR) {
        return [
            {
                dataField: '',
                text: 'S.No',
                formatter: (cell, row, rowIndex) => {
                    let {id} = row
                    return (
                        <Link to={`/application/80iac/${id}`} target="_blank">{(pageNo * size) + (rowIndex + 1)}</Link>
                    )
                    return (pageNo * size) + (rowIndex + 1);
                }
            }, 
            {
                dataField: 'id',
                text: '',
                hidden: true
            },
            {
                dataField: 'recognitionNumber',
                text: 'COR Number',
                formatter: (cell, row, rowIndex) => {
                    let {id} = row
                    return (
                        <Link to={`/application/80iac/${id}`} target="_blank">{cell}</Link>
                    )
                    return cell
                }
            },  
            {
                dataField: 'applicationDate',
                text: 'Application Date',
                sort: false,
                formatter: (cell, row) => {
                    if (cell) {
                        let {id} = row
                        return (
                            <Link to={`/application/80iac/${id}`} target="_blank">{moment(cell).format('DD/MM/YYYY')}</Link>
                        )
                        return moment(cell).format('DD/MM/YYYY');
                    }
                },
            },
            {
                dataField: 'recommendStatusIMBChair',
                text: 'Receiving date',
                formatter: (cell, row) => {
                    if (cell) {
                        let {id} = row
                        return (
                            <Link to={`/application/80iac/${id}`} target="_blank">{moment(cell.assignDate).format('DD/MM/YYYY')}</Link>
                        )
                        return moment(cell).format('DD/MM/YYYY')
    
    
                    }
                    
                },
                sort: false,
                headerSortingStyle
            },
            {
                dataField: '',
                text: 'Action',
                formatter: (cell, row) => {
                    const { id } = row;
    
                    return (
                        <div className="IAS-Actions-list">
                            <ul>
                                <li><Link to={`/application/80iac/${id}`} target="_blank">View</Link></li>
                                
                                <li><Link to={`/application/80iac/thread/${id}`} target="_blank">Thread</Link></li>
    
                            </ul>
                        </div>
                    )
    
                }
            },
            {
                dataField: 'recommendStatusIMBChair',
                text: 'Status',
                sort: false,
                formatter: (cell, row) => {
                    let {id} = row
                    if(cell && cell.statusImbChair == 'NOT_RECOMMENDED') {
                        cell.statusImbChair = 'NOT RECOMMENDED'
                    }
                    return (
                        <Link to={`/application/80iac/${id}`} target="_blank">{cell?cell.statusImbChair:row.statusLabel}</Link>
                    )
                    return cell
                },
            },
            {
                dataField: 'incorporationNumber',
                text: 'Registration No',
                sort: false,
                headerSortingStyle,
                formatter: (cell, row) => {
                    let {id} = row
                    return (
                        <Link to={`/application/80iac/${id}`} target="_blank">{cell}</Link>
                    )
                }
            }, 
            {
                dataField: 'entityName',
                text: 'Name of the Entity',
                sort: false,
                headerSortingStyle,
                formatter: (cell, row) => {
                    let {id} = row
                    return (
                        <Link to={`/application/80iac/${id}`} target="_blank">{cell}</Link>
                    )
                }
            }, 
            
        ];
    } else {
        return [
            {
                dataField: '',
                text: 'Sr.No',
                formatter: (cell, row, rowIndex) => {
                    return (pageNo * size) + (rowIndex + 1);
                }
            }, {
                dataField: 'recognitionNumber',
                text: 'COR Number',
                formatter: (cell, row, rowIndex) => {
                    return cell
                }
            }, {
                dataField: 'id',
                text: '',
                hidden: true
            }, {
                dataField: 'recognitionDate',
                text: 'COR issue date',
                formatter: (cell) => {
                    if (cell) {
                        return moment(cell).format('DD/MM/YYYY')
    
                    }
                },
                sort: false,
                headerSortingStyle
            },
            {
                dataField: 'applicationDate',
                text: 'Application Date',
                sort: true,
                formatter: (cell) => {
                    if (cell) {
                        return moment(cell).format('DD/MM/YYYY');
                    }
                },
            },
            {
                dataField: '',
                text: 'Action',
                hidden: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.NRDC_ADMIN, ROLES.TIFAC_ADMIN].indexOf(userRole) === -1,
                formatter: (cell, row) => {
                    const { id } = row;
                    let exceptNrdcUser = userRole === ROLES.NRDC_ADMIN
    
                    return (
                        <div className="IAS-Actions-list">
                            <ul>
                                <li><Link to={`/application/80iac/${id}`} target="_blank">View</Link></li>
                                {/* <li><Link to={`/imb-members-comment/${id}`}>Comments by IMB Members</Link></li> */}
                                {/* {
                                    (userRole === ROLES.SUPER_ADMIN || userRole === ROLES.ADMIN) &&
    
                                    <li><a href="javascript:void(0)" onClick={() => clickOnAction(id)}>Observation Communicated</a></li>
                                } */}
                                <li><Link to={`/application/80iac/thread/${id}`} target="_blank">Thread</Link></li>
                                {/* <li><Link to={`/NRDClist/${id}`}>Technical Team Comments</Link></li> */}
                                {/* <li><Link to={`/application/80iac/comment/${id}`} target="_blank">Technical Team Comments</Link></li> */}
                                
                                <li><a href="javascript:void(0)" onClick={() => clickOnActionIncomplete(id)}>View Incomplete History</a></li>
                                
                                {/* <li><a href="javascript:void(0)" onClick={() => ApproveRejectAction(id)}>Approve/Reject</a></li> */}
    
                            </ul>
                        </div>
                    )
    
                }
            },
            {
                dataField: '',
                text: 'Action',
                hidden: userRole !== ROLES.USER,
                align: 'center',
                formatter: (cell, row) => {
                    const { id } = row;
                    return (
                        <div className="IAS-Actions-list">
                            <ul className="text-center">
                                <li><Link to={`/application/80iac/${id}`} target="_blank">View</Link></li>
                            </ul>
                        </div>
                    )
    
                }
            },
            {
                dataField: 'statusLabel',
                text: 'Status',
            }, {
                dataField: 'incorporationCertificate',
                text: 'Incorporation Certificate',
                formatter: (cell) => {
                    if (cell && cell.publicUrl) {
                        return (<a href={`${getDocumentLink(cell.publicUrl)}`} target="_blank">view document</a>)
                    }
                }
            }, {
                dataField: 'incorporationNumber',
                text: 'Registration No',
                sort: false,
                headerSortingStyle
            }, {
                dataField: 'entityName',
                text: 'Name of the Entity',
                sort: false,
                headerSortingStyle
            }, {
                dataField: 'eligibilityCertificate',
                text: 'Certificate Of Eligibility',
                hidden: userRole === ROLES.NRDC_ADMIN,
                formatter: (cell) => {
                    if (cell && cell.publicUrl) {
                        return (<a href={`${getDocumentLink(cell.publicUrl)}`} target="_blank" >view document</a>)
                    }
                }
            },
            {
                dataField: 'incorporationDate',
                text: 'Registration Date',
                formatter: (cell) => {
                    if (cell) {
                        return moment(cell).format('DD/MM/YYYY')
    
                    }
                }
            },
            {
                dataField: 'supportingDocument',
                text: 'Supporting Document',
                formatter: (cell, row, rowIndex) => {
                    if (cell !== undefined) {
                        var allData = cell.map((element, index) => {
                            let link = "";
                            if (element && element.hasOwnProperty('file') && element.file) {
                                link = `${getDocumentLink(element.file.publicUrl)}`
                            } else if (element && element.hasOwnProperty('url') && element.name === 'WEBSITE_LINK') {
                                link = `${element.url.indexOf('http') == -1 ? 'http://' : ''}${element.url}`
                            } else if (element && element.hasOwnProperty('url') && element.url) {
                                link = element.url
                            }
                            return <div key={index}>
                                {link ?
                                    <a href={link} target="_blank"> {SUPPORTING_DOCUMENTS[element.name] || ""} </a> : null} </div>
                        })
    
                    }
    
                    if (row.associationMemorandum !== null && row.associationMemorandum) {
                        let link = `${getDocumentLink(row.associationMemorandum.publicUrl)}`
                        var moaData = <div>
                            <a href={link} target="_blank"> MOA </a>
                        </div>
                    }
                    return allData.concat(moaData);
                }
            }, {
                dataField: 'startupReplyDate',
                text: 'Startup Reply Received Date',
                formatter: (cell) => {
                    if (cell) {
                        return moment(cell).format('DD/MM/YYYY')
    
                    }
                },
                sort: false,
                headerSortingStyle
            }, {
                dataField: 'mobileNumber',
                text: 'Mobile No',
                sort: false,
                headerSortingStyle
            }, {
                dataField: 'emailId',
                text: 'Email Id',
                sort: false,
                headerSortingStyle
            }
        ];
    }

    
}

export default iasListColumns;