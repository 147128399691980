import { startupDetailReportService } from './service';
import {
    REPORT_STATE_LIST,
    REPORT_SECTOR_LIST,
    SET_STARTUP_INDUSTRY
} from '../../../constants/actionConstants';
// import { isLoading } from '../constants/actionConstants';
import { isLoading } from '../../../constants/actionConstants';
import { toast } from "react-toastify";
export function getStateList() {
    return async function (dispatch) {

        let StartupDetailsStateList = await startupDetailReportService.fetchStateList();
        dispatch({
            type: REPORT_STATE_LIST,
            payload: StartupDetailsStateList
        });

    }
}


export function getIndustryList() {

    return async function (dispatch) {
        let StartupDetailIndustryList = await startupDetailReportService.fetchStartupIndustry();
        dispatch({
            type: SET_STARTUP_INDUSTRY,
            payload: StartupDetailIndustryList
        })
    }
}



export function getSectorList() {
    return async function (dispatch) {
        return await startupDetailReportService.getStartupSector().then((response) => {
            const { startupSector = [], status } = response;
            dispatch({
                type: REPORT_SECTOR_LIST,
                payload: startupSector
            })
        }).catch(() => {
            return { startupSector: [], status: false }
        })
    }
}

export function dowloadReport(params) {

    return async function (dispatch) {
        dispatch(isLoading(true));
        let DownloadReport = await startupDetailReportService.downloadReport(params);

        dispatch(isLoading(false));
        if (DownloadReport === undefined) {
            toast.error('Error')
        } else {
            return DownloadReport;
        }

    }

}

export function generateReport(params) {
    return async function (dispatch) {
        dispatch(isLoading(true));
        let DownloadReport = await startupDetailReportService.generateReport(params);
        dispatch(isLoading(false));
        if (DownloadReport === undefined) {
            toast.error('Error')
        } else {
            return DownloadReport;
        }
    }
}

export function downloadExcelFile(params) {
    return async function (dispatch) {
        dispatch(isLoading(true));
        return await startupDetailReportService.getcsvFileLink().then((response) => {
            dispatch(isLoading(false));        
            return response
        });

        

    }
}