
export const StartupApplicationStatus = [
    {
        text: 'Saved',
        value: 'DRAFT',
    },
    {
        text: 'In Progress',
        value: 'SUBMIT',
    },
    {
        text: 'Approve',
        value: 'APPROVE',
    },
    {
        text: 'Incomplete',
        value: 'INCOMPLETE',
    },
    {
        text: 'Recognition Cancelled',
        value: 'CANCELLED',
    },
    {
        text: 'Expired',
        value: 'EXPIRED',
    }
];



export const SearchByStartupApplicationOptions = (sectorOptions,natureOption) => {

    return [
        {
            text: "Certificate Number",
            value: "randomReg",
            searchFields: [{
                field: 'text',
                name: 'randomReg'
            }],
        },
        {
            text: "Registration No",
            value: "incorporationNumber",
            searchFields: [{
                field: 'text',
                name: 'incorporationNumber'
            }],
        }, 
        {
            text: "Reference No",
            value: "referenceNumber",
            searchFields: [{
                field: 'text',
                name: 'referenceNumber'
            }],
        },
        {
            text: "Registration Date",
            value: "incorporationDate",
            searchFields: [{
                field: 'date',
                name: 'incorporationStartDate',
                label: 'Start Date'
            }, {
                field: 'date',
                name: 'incorporationEndDate',
                label: 'End Date'
            }],
        },
        {
            text: "Name of the Entity",
            value: "nameOfEntity",
            searchFields: [{
                field: 'text',
                name: 'nameOfEntity'
            }]
        },
        {
            text: "Type Of Entity",
            value: "natureOfEntityId",
            searchFields: [{
                field: 'select',
                name: 'natureOfEntityId',
                options: natureOption,
            }]
        },
        {
            text: "Mobile",
            value: "mobileNumber",
            searchFields: [{
                field: 'text',
                name: 'mobileNumber'
            }]
        }, {
            text: "Email",
            value: "email",
            searchFields: [{
                field: 'text',
                name: 'email'
            }]
        },
        {
            text: "Application Date",
            value: "createdDate",
            searchFields: [{
                field: 'date',
                name: 'startDate'
            }, {
                field: 'date',
                name: 'endDate'
            }]
        },
        {
            text: "Sector",
            value: "sectionId",
            searchFields: [{
                field: 'select',
                name: 'sectionId',
                options: sectorOptions,
            }]
        }
    ]
}


