import {
    validateTitle,
    validateDescription,
} from '../../../dependencies/utils/validation/Validation';

export default {

    validateGlossary: (params) => {
        const { name, description } = params;
        let formStatus = {};
        const nameStatus = validateTitle(name);
        const descriptionStatus = validateDescription(description);

        formStatus = {
            name: nameStatus.error,
            description: descriptionStatus.error,

            hasErrors: (
                nameStatus.hasError
                || descriptionStatus.hasError
            )
        };
        return formStatus;
    },




};
