import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFetchEntityTypeApplicationReport, downloadEntityTypeApplicationReport } from '../dependencies/action';
import entityColumns from '../dependencies/columns/entityColumns';
import CustomTable from '../../common/customTable';
import moment from 'moment';
import Download from '../../common/Download';
import '../dependencies/style/index.scss'
import SectionTitle from '../../common/sectionTitle/sectionTitle';


const EntityTypeReport = (props) => {
    const { getFetchEntityTypeApplicationReport, entityTypeReportData: { data: list = [] }, downloadEntityTypeApplicationReport } = props;

    useEffect(() => {
        if (getFetchEntityTypeApplicationReport) {
            getFetchEntityTypeApplicationReport().then((data) => {
            });
        }
    }, [])

    const downloadReport = () => {
        if (downloadEntityTypeApplicationReport) {
            return downloadEntityTypeApplicationReport().then((data) => {
                return data;
            })
        }
    }
    const currentDate = moment(new Date()).format('YYYYMMDD')
    return (
        <div className="row summary-report-container">

            <div className="col-sm-12">
                <SectionTitle breadCrumb title="Summary Report >  Number of applications based on Entity Type">Number of applications based on Entity Type</SectionTitle>
                <Download
                    fileName={`Report_for_Number_of_applications_based_on_Entity_Type_${currentDate}`}
                    onClickDownload={downloadReport}
                    className="bttn-link"
                />
                <CustomTable
                    dataTable={list}
                    columns={entityColumns()}
                />
            </div>
        </div>)
}


const mapStateToProps = (state) => {
    return {
        entityTypeReportData: state.ReportReducer.entityTypeApplicationReport,
    }
}

export default connect(mapStateToProps, { getFetchEntityTypeApplicationReport, downloadEntityTypeApplicationReport })(withRouter(EntityTypeReport));