import React, { useState } from 'react';
import Button from '../../../common/Button';
import ActionModal from '../../dependencies/modal/index';
import '../scss/index.scss';

const DisplayButton = (props) => {
    const [modalValue, setModalValue] = useState(false);
    const [buttonValue, setButtonValue] = useState('');

    const toggleActionModal = () => {
        setModalValue(!modalValue);

    }
    const submitAction = (name) => {
        toggleActionModal();
        setButtonValue(name)
    }
    const {
        status,
        appId,
        callApi,
        showBtn,
        showAdmin
    } = props
    return (
        <div className="allButton 80iac-btn-group mb-30">
            {(status === 'PENDING' || status === 'OBSERVATION_COMMUNICATED' || status === 'UNDER_TECHNICAL_EVALUATION' || status === 'UNDER_IMB_CONSIDERATION') && showAdmin
                ?  <Button
                        id='offline'
                        className="bttn green normal"
                        type='button'
                        onClick={() => submitAction('DOCUMENT_VERIFIED')}
                        text="Complete"
                        value={'DOCUMENT VERIFIED'}
                    />
                
                : ''
            }
            {(status === 'PENDING' || status === 'CANCELLED' || status === 'REJECT' || status === 'EXPIRED') && showAdmin
                ? <Button
                    className="bttn blue normal"
                    type='button'
                    onClick={() => submitAction('ARCHIVE')}
                    text="Archive"
                />

                : ''
            }
            {/* {(status === 'PENDING' || status === 'OBSERVATION_COMMUNICATED' || status === 'DOCUMENT_VERIFIED' || status === 'UNDER_IMB_CONSIDERATION') && showBtn
                ? <Button
                        className="bttn yellow normal"
                        type='button'
                        onClick={() => submitAction('UNDER_TECHNICAL_EVALUATION')}
                        text="Under Technical Evaluation"
                        value={'UNDER TECHNICAL EVALUATION'}
                    />
                
                : ''
            } */}
            {/* {(status === 'PENDING' || status === 'OBSERVATION_COMMUNICATED' || status === 'DOCUMENT_VERIFIED' || status === 'UNDER_TECHNICAL_EVALUATION') && showBtn
                ? <Button
                    className="bttn blue normal"
                    type='button'
                    onClick={() => submitAction('UNDER_IMB_CONSIDERATION')}
                    text="Under IMB Consideration"
                />

                : ''
            } */}
            {(status === 'PENDING' || status === 'OBSERVATION_COMMUNICATED' || status === 'DOCUMENT_VERIFIED' || status === 'UNDER_TECHNICAL_EVALUATION' || status === 'UNDER_IMB_CONSIDERATION') && showAdmin
                ? <Button
                        className="bttn yellow normal"
                        type='button'
                        onClick={() => submitAction('INCOMPLETE')}
                        text="Incomplete"
                    />
               
                : ''
            }
            {(status === 'PENDING' || status === 'OBSERVATION_COMMUNICATED' || status === 'DOCUMENT_VERIFIED' || status === 'UNDER_TECHNICAL_EVALUATION' || status === 'UNDER_IMB_CONSIDERATION') && showAdmin
                ? <Button
                    className="bttn normal green"
                    type='button'
                    onClick={() => submitAction('APPROVED')}
                    text="Approve"
                />

                : ''
            }
            {(status === 'PENDING' || status === 'OBSERVATION_COMMUNICATED' || status === 'DOCUMENT_VERIFIED' || status === 'UNDER_TECHNICAL_EVALUATION' || status === 'UNDER_IMB_CONSIDERATION') && showBtn
                ? <Button
                    className="bttn normal"
                    type='button'
                    onClick={() => submitAction('approvedReject')}
                    text="Reject"
                />

                : ''
            }
            {(status === 'PENDING' || status === 'OBSERVATION_COMMUNICATED' || status === 'UNDER_TECHNICAL_EVALUATION' || status === 'UNDER_IMB_CONSIDERATION') && showBtn
                ? <Button
                    className="bttn normal blue"
                    type='button'
                    onClick={() => submitAction('notRecommend')}
                    text="Not Recommend"
                />

                : ''
            }


            {modalValue ?
                <ActionModal
                    modalValue={modalValue}
                    toggleActionModal={toggleActionModal}
                    actionValue={buttonValue}
                    callApi={callApi}
                    id={appId
                        ?
                        appId : ''}
                /> : null
            }

        </div>

    );

}

export default DisplayButton;
