import React, { Component, useEffect, useState } from 'react';
import InputField from '../common/InputField';
import Button from '../common/Button';
import { updateCin } from '../../dependencies/services/startupApplicationService';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import FormValidator from './dependencies/FormValidator';
import { getNatureOfList } from '../startupApplication/dependencies/action';
import GlobalDropDown from '../common/GlobalDropDown/index'
import "./scss/index.scss";

const GenerateCor = (props) => {

    const [natureOfEntityId, setNatureOfEntityId] = useState('');
    const [oldIncorporationNumber, setOldIncorporationNumber] = useState('');
    const [newIncorporationNumber, setNewIncorporationNumber] = useState('');
    const [natureOption, SetNatureOption] = useState([]);
    const [error, setError] = useState('');
    const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] = useState(false);


    useEffect(() => {
        props.getNatureOfList().then((response) => {
            const { data, status } = response;
            if (status) {
                let options = [];
                if (data && data instanceof Array) {
                    options = data.reduce((arr, option) => {
                        const text = option['name'] || "";
                        const value = option['id'] || "";
                        arr.push({ text, value });
                        return arr;
                    }, []);
                }
                SetNatureOption(options);
            }
        });
    }, [])
    const handleInput = (e, type) => {
        let value = e.target.value;
        if (type === 'natureOfEntityId') {
            setNatureOfEntityId(value);
        } else if (type === 'oldIncorporationNumber') {
            setOldIncorporationNumber(value);
        } else if (type === 'newIncorporationNumber') {

            setNewIncorporationNumber(value);
        }
    }


    const validateIncorporationNumber = () => {
        const formStatus = FormValidator.validateIncorporationNumbers({
            natureOfEntityId,
            oldIncorporationNumber,
            newIncorporationNumber

        });
        const errors = {
            natureOfEntityId: formStatus.natureOfEntityId,
            oldIncorporationNumber: formStatus.oldIncorporationNumber,
            newIncorporationNumber: formStatus.newIncorporationNumber
        };
        setError(errors);
        return !formStatus.hasErrors;
    }
    const onBlur = (e, name) => {

        validateIncorporationNumber();
    }
    const incorporationNumber = (e) => {
        e.preventDefault();
        if (!validateIncorporationNumber()) {
            setHasInvalidSubmitOccurred(true)
        } else {
            props.updateCin(
                { oldIncorporationNumber, newIncorporationNumber, natureOfEntityId }).then(response => {
                    if (response.status) {
                        setNatureOfEntityId('');
                        setNewIncorporationNumber('');
                        setOldIncorporationNumber('');
                    }

                })
        }
    }
    return (
        <div className="regenerate-cor">

            <div>
                <form className="filter-form custom-form" onSubmit={incorporationNumber}>
                    <div className="row">
                        <div className="col-md-12">
                            <h3>Update the Incorporation / Registration No.</h3>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-4">
                            <InputField
                                required
                                type="text"
                                name="oldIncorporationNumber"
                                label="Old Incorporation Number"
                                value={oldIncorporationNumber}
                                onChange={handleInput}
                                containerClass={"form-group"}
                                onBlur={onBlur}
                                hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                error={error.oldIncorporationNumber}

                            />
                        </div>
                        <div className="col-md-4">
                            <InputField
                                required
                                type="text"
                                name="newIncorporationNumber"
                                label="New Incorporation Number"
                                value={newIncorporationNumber}
                                onChange={handleInput}
                                containerClass={"form-group"}
                                onBlur={onBlur}
                                hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                error={error.newIncorporationNumber}

                            />
                        </div>
                        <div className="col-md-4">
                            <GlobalDropDown
                                label='Nature of Entity'
                                containerStyle="form-group "
                                selectTagStyle="form-control dropdown-media"
                                onChange={handleInput}
                                options={natureOption}
                                value={natureOfEntityId}
                                name={'natureOfEntityId'}
                                error={error.natureOfEntityId}
                                hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                onBlur={onBlur}
                            />
                        </div>
                    </div>
                    <div className="button-wrap">
                        <Button
                            className="bttn"
                            type="submit"
                            text="Submit"
                        />
                    </div>
                </form>
            </div>
        </div>

    )
}

const mapStateToProps = (state) => {


}

export default connect(mapStateToProps, { updateCin, getNatureOfList })(withRouter(GenerateCor));