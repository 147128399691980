import {FETCH_ROLES} from '../../constants/actionConstants';


const initialState = {
    roles: []
};

export default (state = initialState, action) => {
    let newState;
    switch (action.type) {

        case FETCH_ROLES:
            newState = {
                ...state,
                roles: action.payload
            };
            return newState;
        default:
            return state;
    }
}
