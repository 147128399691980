import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { getStartupApplicationList } from '../../dependencies/services/startupApplicationService';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import columns from './dependencies/columns';
import Table from '../common/Table';
import SectionTitle from '../common/sectionTitle/sectionTitle';

const ExpiredList = (props) => {

    const { getStartupApplicationList, startupApplicationDataState: { data, recordsTotal } } = props;
    const [sizePerPage, setSizePerPage] = useState(50);
    const [pageNo, setPageNo] = useState(0);
    const [sortColumn, setSortColumn] = useState('applicationDate');
    const [sortOrder, setSortOrder] = useState('DESC');

    useEffect(() => {
        getTableData();

    }, [sizePerPage, pageNo, sortOrder, sortColumn])

    const getTableData = () => {
        getStartupApplicationList({
            pageSize: sizePerPage,
            pageIndex: pageNo,
            sortColumn,
            sortOrder,
            query: {
                status: "EXPIRED",
                archived: false,
            }
        });
    }

    const onSortChange = async (params) => {
        const { order, dataField } = params;
        var sortName = '';
        if (dataField === 'mobileNumber') {
            sortName = 'representative.mobile';
        } else if (dataField === 'referenceNumber') {
            sortName = 'referenceNumber';
        } else if (dataField === 'certificateNumber') {
            sortName = 'startupEntity.recognitionNumber';
        } else if (dataField === 'incorporationNumber') {
            sortName = 'incorporationNumber';
        } else if (dataField === 'nameOfEntity') {
            sortName = 'startupEntity.name';
        } else if (dataField === 'applicationDate') {
            sortName = 'applicationDate';
        } else if (dataField === 'registrationDate') {
            sortName = 'startupEntity.corCreatedDate';
        } else if (dataField === 'emailId') {
            sortName = 'representative.email';
        } else if (dataField === 'incorporationDate') {
            sortName = 'startupEntity.incorporationDate'
        } else {
            sortName = 'createdDate';
        }
        if (dataField !== '') {
            setSortColumn(await (sortName));
            setSortOrder(await (order.toUpperCase()));
        }

    }

    const handleOnPageChange = (params) => {
        const { page } = params;
        setPageNo(page - 1);
    }

    return (

        <div>
            <SectionTitle breadCrumb title="Expired Applications">Expired Applications</SectionTitle>
            <Table
                dataTable={data}
                columns={columns({
                    pageNo: pageNo,
                    size: sizePerPage
                })}
                onSortChange={onSortChange}
                keyField='applicationId'
                totalSize={recordsTotal}
                sizePerPage={sizePerPage}
                onPageChange={handleOnPageChange}
            />
        </div>
    )
}

const mapStateToProps = (state) => {

    return {
        startupApplicationDataState: state.startupApplicationList.startupData,
        startupSectionList: state.startupSectionList.startupSelectionData
    }
}

export default connect(mapStateToProps, { getStartupApplicationList })(withRouter(ExpiredList));