import { FETCH_NSA_REPORT } from '../../constants/actionConstants';

const initialState = {
    nasReportData: []
};

export default (state = initialState, action) => {

    let newState;
    switch (action.type) {

        case FETCH_NSA_REPORT:
            newState = {
                ...state,
                nasReportData: action.payload
            };
            return newState;
        default:
            return state;
    }
}
