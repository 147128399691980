import React, { useEffect, useState } from 'react';
import { getDistrict, getState, updateDistrict } from '../dependencies/action';
import { connect } from 'react-redux';
import Table from '../../common/Table';
import Dropdown from '../../common/GlobalDropDown';
import column from '../dependencies/columns/district';
import '../style.scss';
import { dropdownOptionsTransform } from '../../../dependencies/utils/helper';
import InputField from '../../common/InputField';

const District = (props) => {
    const { getDistrict, getState, updateDistrict, history } = props;

    const [districts, setDistricts] = useState([]);
    const [options, setOptions] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [selectedState, setSelectedState] = useState("");
    const [error, setError] = useState(false);
    const [form, setForm] = useState({
        districtName: "",
        id: null,
        stateDTO: {
            id: "",
            stateName: "",
        },
    })
    useEffect(() => {
        if (getState) {
            getState().then((response) => {
                const { status, data } = response;
                if (status) {
                    const options = dropdownOptionsTransform(data, 'stateName', 'id');
                    setOptions(options);
                }
            });
        }
    }, [])

    const onCancel = () => {
        setShowForm(false);
        setForm(d => {
            return { ...d, id: "", districtName: "" }
        })
    }

    useEffect(() => {
        if (selectedState) {
            getDistrict(selectedState).then((response) => {
                const { status, data } = response;
                if (status) {
                    setDistricts(data);
                    onCancel();
                }
            })
        } else {
            setDistricts([]);
            onCancel()
        }
    }, [selectedState]);

    const handleInputChange = (e, name) => {
        const { value, } = e.target;
        let stateDTO = form && form.stateDTO || {};
        let districtName = "";
        if (name === 'states') {
            const selectedState = options.filter(x => x.value === value)[0] || {};
            stateDTO = { stateName: selectedState.text, id: selectedState.value };
            setSelectedState(value);
        }
        if (name === 'districtName') {
            districtName = value;
        }
        setForm(d => {
            return { ...d, stateDTO, districtName }
        })
    }

    const validateName = (value) => {
        // var nameRegex = /^(?![\s.]+$)[a-zA-Z\s.]*$/;
        var nameRegex =  /^[ A-Za-z_@./#&+-]*$/
        var name = value.trim();
        let error = "";
        if (name == "" || name == undefined || name == null) {
            error = "This field is required.";
        }
        else if (!nameRegex.test(name)) {
            error = "Please provide valid country name.";
        }
        else if (name.length < 2) {
            error = "Please provide valid country name.";
        }
        else {
            error = "";
        }
        setError(error);
        return error;
    }


    const onSubmit = (e) => {
        e.preventDefault();
        const name = form && form.districtName;
        const stateId = selectedState;
        if (!validateName(name)) {
            const districtName = (form && form.districtName && form.districtName.trim()) || "";
            updateDistrict({ ...form, districtName }).then((response) => {
                const { status } = response;
                if (status) {
                    onCancel();
                    setSelectedState("");
                    setSelectedState(stateId);
                }
            });
        }
    }

    const onEdit = (id) => {
        let selectedDistrict = districts.filter(x => x.id === id)[0] || {};
        selectedDistrict = selectedDistrict && { id: selectedDistrict.id, districtName: selectedDistrict.districtName } || {};
        setForm(d => {
            return { ...d, ...selectedDistrict }
        })
        setShowForm(true)
    }

    return (
        <>
            <div className="country-container">
                <h2 className="mb-20">{"Districts"}
                    {form.stateDTO && form.stateDTO.id &&
                        <button onClick={e => { onEdit() }} className="bttn blue normal add-btn">
                            {"Add District"}
                        </button>}
                </h2>

                <Dropdown
                    containerStyle="form-group "
                    selectTagStyle="form-control dropdown-media"
                    onChange={handleInputChange}
                    options={options}
                    name={'states'}
                    value={form.stateDTO && form.stateDTO.id}
                />

                {
                    !showForm ?
                        <Table
                            dataTable={districts}
                            columns={column({
                                onEdit,
                            })}
                            keyField={'id'}
                            sizePerPage={1000}
                        />
                        : <form>
                            <InputField
                                required
                                autoComplete="off"
                                type="text"
                                name="districtName"
                                value={form.districtName}
                                onChange={handleInputChange}
                                error={error}
                                label={"District Name"}
                                containerClass={"form-group"}
                            />
                            <button onClick={e => { onSubmit(e) }} className="bttn blue normal add-btn">
                                {"Submit"}
                            </button>
                            <button onClick={e => { onCancel() }} className="bttn normal add-btn">
                                {"Cancel"}
                            </button>
                        </form>
                }
            </div>
        </>
    )
}

export default connect(null, { getDistrict, getState, updateDistrict })(District);