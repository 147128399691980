import { combineReducers } from 'redux';
import {
    SECTION_56_FETCH_ID,
    SECTION_56_LIST,
    FETCH_SECTION56_CLARIFICATION
} from '../../../constants/actionConstants';


const sectionIdDetails = (state = [], action) => {
    switch (action.type) {
        case SECTION_56_FETCH_ID:
            return { ...action.payload }
        default:
            return state;
    }
}

const section56List = (state = {}, action) => {
    switch (action.type) {
        case SECTION_56_LIST:
            return { ...action.payload }
        default:
            return state;
    }
}

const clarificationRequest = (state = {}, action) => {
    switch (action.type) {
        case FETCH_SECTION56_CLARIFICATION:
            return { ...action.payload };
        default:
            return state;
    }
}


const Section56Reducer = combineReducers({
    sectionIdDetails,
    section56List,
    clarificationRequest
})

export default Section56Reducer;