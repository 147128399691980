import React, { useEffect, useState } from 'react';
import { updateState, getState } from '../../dependencies/action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../style.scss';
import InputField from '../../../common/InputField';

const State = (props) => {
    const { match: { params: { id: stateId = null } }, stateList = [], getState, updateState, history } = props;

    const [form, setForm] = useState({
        stateName: "",
        isUnionTerritory: false
    });
    const [error, setError] = useState("")

    useEffect(() => {
        if (stateId) {
            if (stateList && stateList.length > 0) {
                const state = stateList.filter(x => x.id === stateId)[0] || {};
                const stateName = (state && state.stateName) || "";
                const isUnionTerritory = state.isUnionTerritory;
                setForm(x => {
                    return { ...x, stateName, id: stateId, isUnionTerritory }
                });
            } else {
                getState().then((repsonse) => {
                });
            }
        }
    }, [stateList]);

    const handleInputChange = (e, name) => {
        let value = "";
        if (e && e.target) {
            if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked;
            } else {
                value = e.target.value;
            }
            setForm((c) => {
                return { ...c, [name]: value }
            });
        }
    }

    const validateName = (value) => {
        // var nameRegex = /^(?![\s.]+$)[a-zA-Z\s.]*$/;
        var nameRegex =  /^[ A-Za-z_@./#&+-]*$/
        var name = value.trim();
        let error = "";
        if (name == "" || name == undefined || name == null) {
            error = "This field is required.";
        }
        else if (!nameRegex.test(name)) {
            error = "Please provide valid country name.";
        }
        else if (name.length < 2) {
            error = "Please provide valid country name.";
        }
        else {
            error = "";
        }
        setError(error);
        return error;
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const name = form && form.stateName;
        if (!validateName(name)) {
            const stateName = (form && form.stateName && form.stateName.trim()) || "";
            updateState({ ...form, stateName }).then((response) => {
                const { status } = response;
                if (status) {
                    history.push('/update/state');
                }
            });
        }
    }

    return (
        <div className="country-container">
            <h2 className="mb-20">
                {stateId ? "Update State" : "Add New State"}
            </h2>
            <form>
                <InputField
                    required
                    autoComplete="off"
                    type="text"
                    name="stateName"
                    value={form.stateName}
                    onChange={handleInputChange}
                    error={error}
                    label={"State Name"}
                    containerClass={"form-group"}
                />
                <div className="form-check mb-20">
                    <label className="form-check-label">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name={'isUnionTerritory'}
                            onClick={e => handleInputChange(e, 'isUnionTerritory')}
                            checked={form.isUnionTerritory}
                            style={{
                                marginTop:0,
                                width:'auto'
                            }}
                        />
                        {'Enable union territory '}
                    </label>
                </div>
                <button onClick={e => { onSubmit(e) }} className="bttn blue normal add-btn">
                    {"Submit"}
                </button>
                <button onClick={e => { history.push('/update/state') }} className="bttn normal add-btn">
                    {"Cancel"}
                </button>
            </form>


        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        stateList: state.UpdateReducer.stateList
    }
}

export default connect(mapStateToProps, { updateState, getState })(withRouter(State));