import React, { useState } from 'react';
import InputField from '../../../../../common/InputField';
import GlobalDropDown from '../../../../../common/GlobalDropDown';
import TextareaField from '../../../../../common/TextareaField';
import MobileNumberField from '../../../../../common/MobileNumberField';
import { unEscapeRegHtml } from '../../../../../../dependencies/utils/helper';



const gender = [{
  text: 'Male',
  value: 'MALE'
},
{
  text: 'Female',
  value: 'FEMALE'
},
{
  text: 'Other',
  value: 'OTHER'
}];

const DirectorDetail = (props) => {
  const {
    director = {},
    handleInputChange,
    handleOnBlur,
    index,
    error = {},
    natureOfEntityName
  } = props;

  const onChange = (e, name) => {

    if (handleInputChange) {
      handleInputChange(e, name, index);
    }
  }
  return (
    <React.Fragment>
      <div className='row mb-20'>
        <div className='col-12'>
          <h4>
            <i className="partner-count mr-10">{index + 1}</i>
            Director (s) / Partner (s) Details <span className='required'>*</span>
          </h4>
        </div>
      </div>
      <div className="row mb-10">
        <div className="col-sm-4">
          <InputField
            label='Name of Director/Partner'
            required
            type='text'
            name="name"
            value={unEscapeRegHtml(director.name)}
            onChange={onChange}
            defaultOptionDisable={true}
            containerClass={"form-group"}
            hasInvalidSubmitOccurred={true}
            error={error.name}
          />
        </div>
        <div className="col-sm-4">
          <GlobalDropDown
            required
            label='Gender'
            selectTagStyle="form-control dropdown-media filter-dropdown"
            onChange={onChange}
            options={gender}
            name={'gender'}
            value={director.gender}
            defaultOptionDisable={true}
            hasInvalidSubmitOccurred={true}
            error={error.gender}
          />
        </div>
        <div className="col-sm-4">
          <InputField
            required
            label='Mobile Number'
            type='text'
            name="mobileNumber"
            value={director.mobileNumber}
            onChange={onChange}
            hasInvalidSubmitOccurred={true}
            error={error.mobileNumber}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <InputField
            label='Email ID'
            required
            type='email'
            name="email"
            value={director.email}
            onChange={onChange}
            containerClass={"form-group"}
            hasInvalidSubmitOccurred={true}
            error={error.email}
          />
        </div>
        <div className="col-sm-4">
          <TextareaField
            label='Postal Address'
            required
            name='address'
            value={unEscapeRegHtml(director.address)}
            onChange={onChange}
            containerClass={"form-group"}
            hasInvalidSubmitOccurred={true}
            error={error.address}
          />
        </div>
        {(natureOfEntityName !== "Registered Partnership") ? 
        <div className="col-sm-4">
            <InputField
              required
              label='DIN/DPIN'
              type='text'
              name="directorCin"
              value={director.directorCin}
              onChange={onChange}
              hasInvalidSubmitOccurred={true}
              error={error.din}
            />
          
        </div> : ""}
      </div>
    </React.Fragment>
  )
}

export default DirectorDetail;