import {
    validateTextField,
    validateEmptyObject,
    validateMobile,
    validateQuantity,
    validateInputNumber,
    validateNullOrEmpty,
    validateNullOrEmptyDin,
    validateTermAndCondition,
    validateUploadFile,
    validateTextarea
} from './FormValidation';
import {
    validateEmail,
} from '../../../../dependencies/utils/validation/Validation';


export default {
    startupFormValidation: (params) => {
        const {
            startupIndustry,
            startupNatureOfEntity,
            startupServices,
            startupSection,
            address1,
            address2,
            city,
            stateId,
            districtId,
            subDistrictId,
            pinCode,
            name,
            designation,
            mobile,
            email,
            noOfDirectors,
            receiveFunding,
            numberOfEmployees,
            scalableBusinessModel,
            stage,
            workCategory,
            ipr,
            iprs,
            awardDocument,
            awardsReceived,
            showAwards,
            generateRevenue,
            problemSolution,
            problemStatement,
            solutionUniqueness,
            registrationCertificate,
            directors,
            // tnc,
            note,
            additionalDocument
        } = params;
        let formStatus = {};
        const startupIndustryStatus = validateEmptyObject(startupIndustry);
        const startupNatureOfEntityStatus = validateEmptyObject(startupNatureOfEntity);
        const startupServicesStatus = validateEmptyObject(startupServices);
        const startupSectionStatus = validateEmptyObject(startupSection);
        const address1Status = validateTextField(address1);
        const address2Status = validateTextField(address2);
        const cityStatus = validateTextField(city);
        const stateIdStatus = validateNullOrEmpty(stateId);
        const districtIdStatus = validateNullOrEmpty(districtId);
        const subDistrictIdStatus = validateNullOrEmpty(subDistrictId);
        const pinCodeStatus = validateTextField(pinCode);
        const nameStatus = validateTextField(name);
        const designationStatus = validateTextField(designation);
        const mobileStatus = validateMobile(mobile);
        const emailStatus = validateEmail(email);
        const noOfDirectorsStatus = validateQuantity(noOfDirectors);
        const receiveFundingStatus = validateNullOrEmpty(receiveFunding);
        const numberOfEmployeesStatus = validateInputNumber(numberOfEmployees);
        const scalableBusinessModelStatus = validateNullOrEmpty(scalableBusinessModel);
        const stageStatus = validateNullOrEmpty(stage);
        const workCategoryStatus = validateNullOrEmpty(workCategory);
        const iprStatus = validateNullOrEmpty(ipr);
        const iprsStatus = validateIprs(iprs);
        const generateRevenueStatus = validateTextarea(generateRevenue);
        const problemSolutionStatus = validateTextarea(problemSolution);
        const problemStatementStatus = validateTextarea(problemStatement);
        const solutionUniquenessStatus = validateTextarea(solutionUniqueness);
        const registrationCertificateStatus = validateUploadFile(registrationCertificate);
        const directorsStatus = validatePartners(directors);
        // const tncStatus = validateTermAndCondition(tnc)
        const awardDocumentStatus = validateAwardDocument(showAwards, awardDocument);
        const awardsReceivedStatus = validateAwardReceived(showAwards, awardsReceived);
        const noteStatus = validateTextarea(note);

        formStatus = {
            startupIndustry: startupIndustryStatus.error,
            startupNatureOfEntity: startupNatureOfEntityStatus.error,
            startupServices: startupServicesStatus.error,
            startupSection: startupSectionStatus.error,
            address1: address1Status.error,
            address2: address2Status.error,
            city: cityStatus.error,
            stateId: stateIdStatus.error,
            districtId: districtIdStatus.error,
            subDistrictId: subDistrictIdStatus.error,
            pinCode: pinCodeStatus.error,
            noOfDirectors: noOfDirectorsStatus.error,
            receiveFunding: receiveFundingStatus.error,
            numberOfEmployees: numberOfEmployeesStatus.error,
            scalableBusinessModel: scalableBusinessModelStatus.error,
            stage: stageStatus.error,
            workCategory: workCategoryStatus.error,
            ipr: iprStatus.error,
            iprs: iprsStatus.error,
            generateRevenue: generateRevenueStatus.error,
            problemSolution: problemSolutionStatus.error,
            problemStatement: problemStatementStatus.error,
            solutionUniqueness: solutionUniquenessStatus.error,
            registrationCertificate: registrationCertificateStatus.error,
            name: nameStatus.error,
            designation: designationStatus.error,
            mobile: mobileStatus.error,
            email: emailStatus.error,
            directors: directorsStatus.error,
            // tnc: tncStatus.error,
            awardDocument: awardDocumentStatus.error,
            awardsReceived: awardsReceivedStatus.error,
            note: noteStatus.error,
            hasErrors: (
                startupIndustryStatus.hasError
                || startupNatureOfEntityStatus.hasError
                || startupServicesStatus.hasError
                || startupSectionStatus.hasError
                || address1Status.hasError
                || address2Status.hasError
                || cityStatus.hasError
                || stateIdStatus.hasError
                || districtIdStatus.hasError
                || subDistrictIdStatus.hasError
                || pinCodeStatus.hasError
                || nameStatus.hasError
                || designationStatus.hasError
                || mobileStatus.hasError
                || emailStatus.hasError
                || noOfDirectorsStatus.hasError
                || receiveFundingStatus.hasError
                || numberOfEmployeesStatus.hasError
                || scalableBusinessModelStatus.hasError
                || stageStatus.hasError
                || workCategoryStatus.hasError
                || iprStatus.hasError
                || iprsStatus.hasError
                || generateRevenueStatus.hasError
                || problemSolutionStatus.hasError
                || problemStatementStatus.hasError
                || solutionUniquenessStatus.hasError
                || registrationCertificateStatus.hasError
                || directorsStatus.hasError
                // || tncStatus.hasError
                || awardDocumentStatus.hasError
                || awardsReceivedStatus.hasError
                || noteStatus.hasError
            )
        };
        return formStatus;
    },
    startupFormEntityValidation: (params) => {
        const {
            startupIndustry,
            startupNatureOfEntity,
            startupServices,
            startupSection,
            // tnc,
        } = params;
        let formStatus = {};
        const startupIndustryStatus = validateEmptyObject(startupIndustry);
        const startupNatureOfEntityStatus = validateEmptyObject(startupNatureOfEntity);
        const startupServicesStatus = validateEmptyObject(startupServices);
        const startupSectionStatus = validateEmptyObject(startupSection);
        // const tncStatus = validateTermAndCondition(tnc)
        formStatus = {
            startupIndustry: startupIndustryStatus.error,
            startupNatureOfEntity: startupNatureOfEntityStatus.error,
            startupServices: startupServicesStatus.error,
            startupSection: startupSectionStatus.error,
            // tnc: tncStatus.error,
            hasErrors: (
                startupIndustryStatus.hasError
                || startupNatureOfEntityStatus.hasError
                || startupServicesStatus.hasError
                || startupSectionStatus.hasError
                // || tncStatus.hasError
            )
        };
        return formStatus;
    },
    startupFormInfoValidation: (params) => {
        const {
            receiveFunding,
            numberOfEmployees,
            scalableBusinessModel,
            stage,
            workCategory,
            ipr,
            iprs,
            // tnc,
            note,
        } = params;
        let formStatus = {};
        const receiveFundingStatus = validateNullOrEmpty(receiveFunding);
        const numberOfEmployeesStatus = validateInputNumber(numberOfEmployees);
        const scalableBusinessModelStatus = validateNullOrEmpty(scalableBusinessModel);
        const stageStatus = validateNullOrEmpty(stage);
        const workCategoryStatus = validateNullOrEmpty(workCategory);
        const iprStatus = validateNullOrEmpty(ipr);
        const iprsStatus = validateIprs(iprs);
        // const tncStatus = validateTermAndCondition(tnc)
        const noteStatus = validateTextarea(note);

        formStatus = {
            receiveFunding: receiveFundingStatus.error,
            numberOfEmployees: numberOfEmployeesStatus.error,
            scalableBusinessModel: scalableBusinessModelStatus.error,
            stage: stageStatus.error,
            workCategory: workCategoryStatus.error,
            ipr: iprStatus.error,
            iprs: iprsStatus.error,
            // tnc: tncStatus.error,
            note: noteStatus.error,
            hasErrors: (
                receiveFundingStatus.hasError
                || numberOfEmployeesStatus.hasError
                || scalableBusinessModelStatus.hasError
                || stageStatus.hasError
                || workCategoryStatus.hasError
                || iprStatus.hasError
                || iprsStatus.hasError
                // || tncStatus.hasError
                || noteStatus.hasError
            )
        };
        return formStatus;
    },
    startupFormAddressValidation: (params) => {
        const {
            address1,
            address2,
            city,
            stateId,
            districtId,
            subDistrictId,
            pinCode,
            // tnc,
        } = params;
        let formStatus = {};
        const address1Status = validateTextField(address1);
        const address2Status = validateTextField(address2);
        const cityStatus = validateTextField(city);
        const stateIdStatus = validateNullOrEmpty(stateId);
        const districtIdStatus = validateNullOrEmpty(districtId);
        // const subDistrictIdStatus = validateNullOrEmpty(subDistrictId);
        const pinCodeStatus = validateTextField(pinCode);
        // const tncStatus = validateTermAndCondition(tnc)

        formStatus = {
            address1: address1Status.error,
            address2: address2Status.error,
            city: cityStatus.error,
            stateId: stateIdStatus.error,
            districtId: districtIdStatus.error,
            // subDistrictId: subDistrictIdStatus.error,
            pinCode: pinCodeStatus.error,
            // tnc: tncStatus.error,
            hasErrors: (
                address1Status.hasError
                || address2Status.hasError
                || cityStatus.hasError
                || stateIdStatus.hasError
                || districtIdStatus.hasError
                // || subDistrictIdStatus.hasError
                || pinCodeStatus.hasError
                // || tncStatus.hasError
            )
        };
        return formStatus;
    },
    startupFormRepresentativeValidation: (params) => {
        const {
            name,
            designation,
            mobile,
            email,
            // tnc,
        } = params;
        let formStatus = {};
        const nameStatus = validateTextField(name);
        const designationStatus = validateTextField(designation);
        const mobileStatus = validateMobile(mobile);
        const emailStatus = validateEmail(email);
        // const tncStatus = validateTermAndCondition(tnc)

        formStatus = {
            name: nameStatus.error,
            designation: designationStatus.error,
            mobile: mobileStatus.error,
            email: emailStatus.error,
            // tnc: tncStatus.error,
            hasErrors: (
                nameStatus.hasError
                || designationStatus.hasError
                || mobileStatus.hasError
                || emailStatus.hasError
                // || tncStatus.hasError
            )
        };
        return formStatus;
    },
    startupFormDirectorValidation: (params) => {
        const {
            noOfDirectors,
            directors,
            // tnc,
        } = params;
        let formStatus = {};
        const noOfDirectorsStatus = validateQuantity(noOfDirectors);
        const directorsStatus = validatePartners(directors);
        // const tncStatus = validateTermAndCondition(tnc)

        formStatus = {
            noOfDirectors: noOfDirectorsStatus.error,
            directors: directorsStatus.error,
            // tnc: tncStatus.error,
            hasErrors: (
                noOfDirectorsStatus.hasError
                || directorsStatus.hasError
                // || tncStatus.hasError
            )
        };
        return formStatus;
    },
    startupFormAcitivityValidation: (params) => {
        const {
            awardDocument,
            awardsReceived,
            showAwards,
            generateRevenue,
            problemSolution,
            problemStatement,
            solutionUniqueness,
            // tnc,
        } = params;
        let formStatus = {};
        const generateRevenueStatus = validateTextarea(generateRevenue);
        const problemSolutionStatus = validateTextarea(problemSolution);
        const problemStatementStatus = validateTextarea(problemStatement);
        const solutionUniquenessStatus = validateTextarea(solutionUniqueness);
        // const tncStatus = validateTermAndCondition(tnc)
        const awardDocumentStatus = validateAwardDocument(showAwards, awardDocument);
        const awardsReceivedStatus = validateAwardReceived(showAwards, awardsReceived);

        formStatus = {
            generateRevenue: generateRevenueStatus.error,
            problemSolution: problemSolutionStatus.error,
            problemStatement: problemStatementStatus.error,
            solutionUniqueness: solutionUniquenessStatus.error,
            // tnc: tncStatus.error,
            awardDocument: awardDocumentStatus.error,
            awardsReceived: awardsReceivedStatus.error,
            hasErrors: (
                generateRevenueStatus.hasError
                || problemSolutionStatus.hasError
                || problemStatementStatus.hasError
                || solutionUniquenessStatus.hasError
                // || tncStatus.hasError
                || awardDocumentStatus.hasError
                || awardsReceivedStatus.hasError
            )
        };
        return formStatus;
    },
    startupFormCertificateValidation: (params) => {
        const {
            registrationCertificate,
            // tnc,
            additionalDocument
        } = params;
        let formStatus = {};
        const registrationCertificateStatus = validateUploadFile(registrationCertificate);
        // const tncStatus = validateTermAndCondition(tnc)

        formStatus = {
            registrationCertificate: registrationCertificateStatus.error,
            // tnc: tncStatus.error,
            hasErrors: (
                registrationCertificateStatus.hasError
                // || tncStatus.hasError
            )
        };
        return formStatus;
    },
};

export function validatePartners(partners) {
    const isArray = partners instanceof Array;
    const error = [];
    let hasError = false;
    var isDirectorDinStatus = false;
    if (isArray) {
        partners.forEach(partner => {
            const {
                address,
                email,
                gender,
                mobileNumber,
                name,
                directorCin
            } = partner;
            let formStatus = {};
            const addressStatus = validateTextField(address);
            const emailStatus = validateEmail(email);
            const genderStatus = validateTextField(gender);
            const mobileNumberStatus = validateMobile(mobileNumber);
            const nameStatus = validateTextField(name);
            const directorCinStatus = validateNullOrEmptyDin(directorCin);
            formStatus = {
                address: addressStatus.error,
                email: emailStatus.error,
                gender: genderStatus.error,
                mobileNumber: mobileNumberStatus.error,
                name: nameStatus.error,
                din: directorCinStatus.error
            }

            hasError =
                addressStatus.hasError
                || emailStatus.hasError
                || genderStatus.hasError
                || mobileNumberStatus.hasError
                || nameStatus.hasError
                || directorCinStatus.hasError
                || false;

            error.push(formStatus);
        });
    }


    return { error, hasError };
}


export function validateAwardDocument(showAwards, awardDocument) {
    if (showAwards && showAwards === "yes") {
        return validateUploadFile(awardDocument);
    }
    return { status: true, hasError: false, error: "" }
}

export function validateAwardReceived(showAwards, awardsReceived) {
    if (showAwards && showAwards === "yes") {
        return validateTextarea(awardsReceived);
    }
    return { status: true, hasError: false, error: "" }
}

export function validateIprs(iprs) {
    let hasError = false;
    let error = [];
    iprs && iprs instanceof Array && iprs.forEach(element => {
        let applicationNumberStatus = { hasError: false, error: "" }
        let formStatus = {};
        if (element.applied || element.registered) {
            applicationNumberStatus = validateTextField(element.applicationNumber);
        }

        formStatus = {
            applicationNumber: applicationNumberStatus.error,
        }
        if (!hasError) {
            hasError = applicationNumberStatus.hasError;
        }
        error.push({ ...formStatus });
    });
    return { hasError, error }
}