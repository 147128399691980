import React, { useEffect, useState } from 'react';
import './scss/index.scss';
import SectionTitle from '../common/sectionTitle/sectionTitle';
import DataCard from "./dataCard";
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { getStartupLogoAppCount, getStartupLogoList, downloadStartupLogoList } from './dependencies/action';
import Table from '../common/Table';
import columns from './dependencies/columns';
import SearchBox from '../common/Search';
// import { SearchByStartupLogo } from '../../dependencies/constant/searchConstant';
import { SearchByStartupLogo } from './dependencies/constant/searchConstant';
import Download from '../common/Download';


const StartupLogos = (props) => {
  // const {  startupLogoList: { startupLogoList[0], recordsTotal } } = props;
  const { getStartupLogoAppCount, getStartupLogoList, startupLogoList, downloadStartupLogoList } = props;
  const fetchAllRecords = false;
  const [pageSize, setpageSize] = useState(50);
  const [pageIndex, setPageIndex] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [query, setQuery] = useState({
  });
  useEffect(() => {
    getStartupLogoAppCount();
  }, []);
  let data, recordsTotal, appCosortOrderuntData, totalApp, approvedApp, rejectedApp, pendingApp, appCountData;

  if (props.applicationCount[0]) {
    appCountData = props.applicationCount[0];
    totalApp = appCountData.totalApplication;
    approvedApp = appCountData.approvedApplication;
    rejectedApp = appCountData.rejectedApplication;
    pendingApp = appCountData.pendingApplication;
  }



  const getTableData = () => {

    getStartupLogoList({
      fetchAllRecords,
      pageIndex,
      pageSize,
      searchText,
      query,
    }).then((data) => { });
  }
  useEffect(() => {
    getTableData();
  }, [pageSize, pageIndex, query, searchText])

  if (startupLogoList[0]) {
    data = startupLogoList[0].data.data;
    recordsTotal = startupLogoList[0].data.recordsTotal;
  }

  const handleOnPageChange = (params) => {
    const { page } = params;
    setPageIndex(page - 1);
  }
  const doSearch = (params) => {

    const { query = {}, searchText = "", searchBy = "" } = params;
    setQuery(query);
  }

  const downloadCSV = () => {
    if (downloadStartupLogoList) {
        return downloadStartupLogoList({
            fetchAllRecords: true,
            pageIndex,
            pageSize,
            query,
        }).then((data) => {
            return data;
        })
    }
  }


  return (
    <div className="startup-logo">
      <SectionTitle>Startup Logo</SectionTitle>
      <div className="data-wrap mb-30">
        <div className="row">
          <div className="col-3">
            <DataCard title="Total Applications" data={totalApp} />
          </div>
          <div className="col-3">
            <DataCard title="Approved Applications" data={approvedApp} />
          </div>
          <div className="col-3">
            <DataCard title="Rejected Applications" data={rejectedApp} />
          </div>
          <div className="col-3">
            <DataCard title="Pending Applications" data={pendingApp} />
          </div>
        </div>
      </div>
      <div className="filter-wrap">
        <SearchBox
          searchByOptions={SearchByStartupLogo}
          doSearch={doSearch}
        />
      </div>
      <div className="table-wrap">

      </div>
      <div>
        <Download
          fileName={'startup_Logos'}
          onClickDownload={downloadCSV}
          className="bttn-link"
        />
        <Table
          dataTable={data}
          columns={columns({
            pageNo: pageIndex,
            size: pageSize
          })}

          keyField='id'
          totalSize={recordsTotal}
          sizePerPage={pageSize}
          onPageChange={handleOnPageChange}
        />
      </div>
    </div>

  )
}

const mapStateToProps = (state) => {
  return {
    applicationCount: state.StartupLogoReducer.startupLogoAppCount,
    startupLogoList: state.StartupLogoReducer.startupLogoList,

  }
}

export default connect(mapStateToProps, { getStartupLogoAppCount, getStartupLogoList, downloadStartupLogoList })(withRouter(StartupLogos))
