import React, { useState, useEffect } from 'react';
import './index.scss'
import downloadIcon from '../../../assests/images/download-icon.png';

const Download = (props) => {

  const { onClickDownload, fileName = "report", className, alignLeft } = props;


  function downloadFile(fileName, urlData) {

    var aLink = document.createElement('a');
    var evt = document.createEvent("HTMLEvents");
    evt.initEvent("click");
    aLink.download = fileName;
    aLink.href = urlData;
    aLink.dispatchEvent(evt);
  }

  const onDownload = (e, done) => {
 
    e.preventDefault();
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
  
    if (onClickDownload) {
    
        onClickDownload().then((data) => {

          if (data) {
            if (navigator.appName === "Microsoft Internet Explorer") {
              if (navigator.msSaveBlob) { // IE 10+
                let _fileName = `${fileName}.csv`;
                let blob = new Blob([data], { "type": "text/csv;charset=utf8;" });
                navigator.msSaveBlob(blob, _fileName);
              }
            } else {
              let _fileName = `${fileName}.csv`;
              var blob = new Blob([data], { type: "text/csv;charset=utf-8" });
              // FileSaver.saveAs(blob, _fileName);
              const url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = _fileName;
              a.click();
              window.URL.revokeObjectURL(url);
            }
          }
        });      
    }


  }

  return (
    <div className={`download-outer ${alignLeft && ('float-left')}`}>
      <button
        className={`download-container ${className}`}
        onClick={(event) => onDownload(event)}
      >
        <img src={downloadIcon} width="21" />
        {'Download List'}
      </button>
    </div>
  );

}


export default Download;