import {
    validateStartDate,
    validateEndDate
} from '../../../dependencies/utils/validation/Validation';

export default {
    validateDate: (params) => {
        const { startDate, endDate } = params;
        let formStatus = {};
        const startDateStatus = validateStartDate(startDate);
        const endDateStatus = validateEndDate(endDate);

        formStatus = {
            startDate: startDateStatus.error,
            endDate: endDateStatus.error,
            hasErrors: (
                startDateStatus.hasError
                || endDateStatus.hasError
            )
        };
        return formStatus;
    }
};
