import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getIndustry, getSections, updateSection } from '../dependencies/action';
import { connect } from 'react-redux';
import SectionTitle from '../../common/sectionTitle/sectionTitle';
import Table from '../../common/Table';
import Dropdown from '../../common/GlobalDropDown';
import column from '../dependencies/columns/section';
import '../style.scss';
import { dropdownOptionsTransform } from '../../../dependencies/utils/helper';
import InputField from '../../common/InputField';

const Section = (props) => {
    const { getSections, getIndustry, updateSection } = props;

    const [sections, setSections] = useState([]);
    const [options, setOptions] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [selectedIndustry, setSelectedIndustry] = useState("");
    const [error, setError] = useState(false);
    const [form, setForm] = useState({
        sectionName: "",
        id: null,
        industryName: "",
        industryId: ""

    })
    useEffect(() => {
        if (getIndustry) {
            getIndustry().then((response) => {
                const { status, data } = response;
                if (status) {
                    const options = dropdownOptionsTransform(data, 'industryName', 'id');
                    setOptions(options);
                }
            });
        }
    }, [])


    useEffect(() => {
        if (selectedIndustry) {
            getSections(selectedIndustry).then((response) => {
                const { status, message, data } = response;
                if (status) {
                    setSections(data)
                }
            })
        } else {
            setSections([]);
        }
    }, [selectedIndustry, showForm]);

    const handleInputChange = (e, name) => {
        const { value, } = e.target;
        let industryName = form && form.industryName || '';
        let industryId = form && form.industryId || '';
        let sectionName = "";
        if (name === 'industry') {
            const selectedIndustry = options.filter(x => x.value === value)[0] || {};
            industryName = selectedIndustry.text;
            industryId = selectedIndustry.value;
            setSelectedIndustry(value);
        }
        if (name === 'sectionName') {
            sectionName = value;
        }
        setForm(d => {
            return { ...d, industryName, industryId, sectionName }
        })
    }

    const validateName = (value) => {
        // var nameRegex = /^(?![\s.]+$)[a-zA-Z\s.]*$/;
        var nameRegex =  /^[ A-Za-z_@./#&+-]*$/;
        var name = value.trim();
        let error = "";
        if (name == "" || name == undefined || name == null) {
            error = "This field is required.";
        }
        else if (!nameRegex.test(name)) {
            error = "Please provide valid section name.";
        }
        else if (name.length < 2) {
            error = "Please provide valid section name.";
        }
        else {
            error = "";
        }
        setError(error);
        return error;
    }


    const onSubmit = (e) => {
        e.preventDefault();
        const name = form && form.sectionName;
        if (!validateName(name)) {
            const sectionName = (form && form.sectionName && form.sectionName.trim()) || "";
            updateSection({ ...form, sectionName }).then((response) => {
                if (response.status) {
                    setShowForm(false);
                }
            });
        }
    }

    const onEdit = (id) => {
        let selectedSection = sections.filter(x => x.id === id)[0] || {};
        selectedSection = selectedSection && { id: selectedSection.id, sectionName: selectedSection.sectionName } || {};
        setForm(d => {
            return { ...d, ...selectedSection }
        })
        setShowForm(true)
    }

    const onAdd = () => {
        setShowForm(true)
    }

    const handleCancel = () => {
        setShowForm(false)
        form.sectionName = "";
        form.id = "";
    }

    return (
        <>
            <div className="country-container">
                <h2 className="mb-20">{"Sections"}
                    {form && form.industryId &&
                        <a href="javascript:void(0)" onClick={onAdd} className="bttn blue normal add-btn">
                            {"Add Section"}
                        </a>
                    }
                </h2>

                <Dropdown
                    containerStyle="form-group "
                    selectTagStyle="form-control dropdown-media"
                    onChange={handleInputChange}
                    options={options}
                    name={'industry'}
                    value={form && form.industryId}
                />

                {
                    !showForm ?
                        <Table
                            dataTable={sections}
                            columns={column({
                                onEdit,
                            })}
                            keyField={'id'}
                            sizePerPage={1000}
                        />
                        : <form onSubmit={e => { onSubmit(e) }}>
                            <InputField
                                required
                                autoComplete="off"
                                type="text"
                                name="sectionName"
                                value={form.sectionName}
                                onChange={handleInputChange}
                                error={error}
                                label={"Section Name"}
                                containerClass={"form-group"}
                            />
                            <button className="bttn normal add-btn" onClick={handleCancel}>
                                {"Cancel"}
                            </button>
                            <button className="bttn blue normal add-btn">
                                {"Submit"}
                            </button>

                        </form>
                }
            </div>
        </>
    )
}

export default connect(null, { getIndustry, getSections, updateSection })(Section);