import api from '../../../dependencies/utils/Api';
import {
    FETCH_COUNTRY_LIST,
    FETCH_STATE_LIST,
    UPDATE_COUNTRY,
    UPDATE_STATE,
    FETCH_DISTRICT_LIST,
    UPDATE_DISTRICT,
    FETCH_SUB_DISTRICT_LIST,
    UPDATE_SUBDISTRICT,
    FETCH_INDUSTRY_LIST,
    UPDATE_INDUSTRY,
    FETCH_SERVICES_LIST,
    UPDATE_SERVICES,
    FETCH_SECTIONS_LIST,
    UPDATE_SECTION
} from "../../../constants/ApplicationUrl";

export const UpdateServices = {
    getCountry() {
        return api.GET(FETCH_COUNTRY_LIST).
            then((response) => {
                const { data: { status, data, message } } = response;

                return { data, status, message };
            });
    },
    getState() {
        return api.GET(FETCH_STATE_LIST).
            then((response) => {
                const { data: { status, data, message } = {} } = response;
                return { data, status, message };
            });
    },
    updateCountry(params) {
        return api.POST(UPDATE_COUNTRY, params).
            then((response) => {
                const { data: { status, data, message } = {} } = response;
                return { data, status, message };
            });
    },
    updateState(params) {
        return api.POST(UPDATE_STATE, params).
            then((response) => {
                const { data: { status, data, message } } = response;
                return { data, status, message };
            });
    },
    getDistrict(params) {
        return api.GET(`${FETCH_DISTRICT_LIST}/${params}/district/list`).
            then((response) => {
                const { data: { status, data, message } } = response;
                return { data, status, message };
            });
    },
    updateDistrict(params) {
        return api.POST(UPDATE_DISTRICT, params).
            then((response) => {
                const { data: { status, data, message } } = response;
                return { data, status, message };
            });
    },
    getSubDistrict(params) {
        return api.GET(`${FETCH_SUB_DISTRICT_LIST}/${params}/subDistrict/list`).
            then((response) => {
                const { data: { status, data, message } } = response;
                return { data, status, message };
            });
    },
    updateSubDistrict(params) {
        return api.POST(UPDATE_SUBDISTRICT, params).
            then((response) => {
                const { data: { status, data, message } } = response;
                return { data, status, message };
            });
    },
    getIndustry() {
        return api.GET(FETCH_INDUSTRY_LIST).
            then((response) => {
                const { data: { status, data, message } } = response;
                return { data, status, message };
            });
    },
    updateIndustry(params) {
        return api.POST(UPDATE_INDUSTRY, params).
            then((response) => {
                const { data: { status, data, message } } = response;
                return { data, status, message };
            });
    },
    getServices() {
        return api.GET(FETCH_SERVICES_LIST).
            then((response) => {
                const { data: { status, data, message } } = response;
                return { data, status, message };
            });
    },
    UpdateServices(params) {
        return api.POST(UPDATE_SERVICES, params).
            then((response) => {
                const { data: { status, data, message } } = response;
                return { data, status, message };
            });
    },
    getSections(params) {
        return api.GET(`${FETCH_SECTIONS_LIST}/${params}/startupSection/list`).
            then((response) => {
                const { data: { status, data, message } } = response;
                return { data, status, message };
            });
    },

    updateSection(params) {
        return api.POST(UPDATE_SECTION, params).
            then((response) => {
                const { data: { status, data, message } } = response;
                return { data, status, message };
            });
    },
}