import { FETCH_STARTUP_APPLICATION_VIEW } from '../../constants/actionConstants';

const initialState = {
    startupAppData: ''
};

export default (state = initialState, action) => {

    let newState;
    switch (action.type) {

        case FETCH_STARTUP_APPLICATION_VIEW:
            newState = {
                ...state,
                startupAppData: action.payload
            };
            return newState;
        default:
            return state;
    }
}
