import React, { useState, useEffect } from 'react';
import RCG from 'react-captcha-generator';

const Captcha = (props) => {

  const { getCaptcha } = props;
  const [captcha, setCaptcha] = useState('');

  useEffect(() => {
    if (getCaptcha) {
      getCaptcha(captcha);
    }
  }, [captcha])
  const result = (text) => {
    setCaptcha(text);
  }

  return (
    <div className="captcha-container">
      <RCG result={result} />
    </div>
  );

}

export default Captcha;