import {
    validateIncorporationNumber,
    validateNatureOfEntity
} from '../../../dependencies/utils/validation/Validation';

export default {

    validateIncorporationNumbers: (params) => {

        const { natureOfEntityId,oldIncorporationNumber, newIncorporationNumber } = params;
        let formStatus = {};
        const natureOfEntityIdStatus = validateNatureOfEntity(natureOfEntityId);
        const oldIncorporationNumberStatus = validateIncorporationNumber(oldIncorporationNumber);
        const newIncorporationNumberStatus = validateIncorporationNumber(newIncorporationNumber);

        formStatus = {
            natureOfEntityId:natureOfEntityIdStatus.error,
            oldIncorporationNumber:oldIncorporationNumberStatus.error,
            newIncorporationNumber:newIncorporationNumberStatus.error,
            hasErrors: (
                natureOfEntityIdStatus.hasError,
                oldIncorporationNumberStatus.hasError,
                newIncorporationNumberStatus.hasError
            )
        };
        return formStatus;
    },
   
};
