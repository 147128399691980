import React, { Component } from 'react';
import EntityDataRow from '../../common/EntityDataRow';


const KeyValue = (props) => {
    let allData = Object.entries(props.items).map((data, index) => {
        return <EntityDataRow className="" title={data[0]} description={data[1]} />
    })

    return (
        <div >
            {allData}
        </div>
    );
}

export default KeyValue;




