import api from '../../../dependencies/utils/Api';
import {
    FETCH_RECOGNITION_HISTORY,
    FETCH_80IAC_HISTORY,
    FETCH_SECTION56_HISTORY
} from "../../../constants/ApplicationUrl";
import { isLoading } from '../../../constants/actionConstants';

export const AuditTrail = {
    getRecognitionHistory(params) {
  
        return api.POST(FETCH_RECOGNITION_HISTORY, params).
            then((response) => {
                const { data: { status, data, message } } = response;

                return { data, status, message };
            });
    },
    get80IacHistory(params) {
   
      return api.POST(FETCH_80IAC_HISTORY, params).
          then((response) => {
              const { data: { status, data, message } } = response;

              return { data, status, message };
          });
  },
  getSection56HistoryList(params) {
    return api.POST(FETCH_SECTION56_HISTORY, params).
        then((response) => {
            const { data: { status, data, message, recordsTotal } } = response;

            return { data, status, message, recordsTotal };
        });
},
}

export const doDownloadStartupList = (params) => (dispatch) => {
    dispatch(isLoading(true));
  
    return api.DOWNLOADDATA(FETCH_RECOGNITION_HISTORY, params)
      .then((response) => {
        const { data = "" } = response;
        dispatch(isLoading(false));
        return data;
      }).catch(e => {
        dispatch(isLoading(false))
        return "";
      })
  }


export const doDownload80IacList = (params) => (dispatch) => {
  dispatch(isLoading(true));

  return api.DOWNLOADDATA(FETCH_80IAC_HISTORY, params)
    .then((response) => {
      const { data = "" } = response;
      dispatch(isLoading(false));
      return data;
    }).catch(e => {
      dispatch(isLoading(false))
      return "";
    })
}


  export const doDownloadSection56 = (params) => (dispatch) => {
    dispatch(isLoading(true));
  
    return api.DOWNLOADDATA(FETCH_SECTION56_HISTORY, params)
      .then((response) => {
        const { data = "" } = response;
        dispatch(isLoading(false));
        return data;
      }).catch(e => {
        dispatch(isLoading(false))
        return "";
      })
  }