import React, { Component, useState } from "react";
import './changePassword.scss';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import AlertMessage from '../../common/AlertMessage';
import { AlertType } from '../../../constants';
import { changePasswordService, clearMessage } from '../../../dependencies/services/changePassword';
import FormValidator from '../common/dependencies/FormValidator';
import InputField from '../../common/InputField'
import Button from '../../common/Button';
import refreshIconwhite from '../../../assests/images/refresh-icon-white.png';
import LoadingAnimation from '../../common/loader/loadingAnimation'
import SectionTitle from '../../common/sectionTitle/sectionTitle';
import img from './images/red-bulb.png';


const ChangePassword = (props) => {
    const defaultFormData = {
        oldPassword: '',
        password: '',
        confirmPassword: ''
    }

    const [formData, setFormData] = useState(defaultFormData);
    const [error, setError] = useState({});
    const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState(AlertType.SUCCESS);

    const validateInput = () => {
        const {
            oldPassword,
            password,
            confirmPassword
        } = formData;
        const formStatus = FormValidator.validateUpdatePasswordForm({
            oldPassword,
            password,
            confirmPassword
        });
        const errors = {
            oldPassword: formStatus.oldPassword,
            password: formStatus.password,
            confirmPassword: formStatus.confirmPassword,
        };
        setError(errors);
        return !formStatus.hasErrors;
    };

    const handleInputChange = (e, name) => {
        const { value, } = e.target;
        setFormData((formData) => {
            return { ...formData, [name]: value }
        });
    }

    const doReset = () => {
        setError({});
        setFormData(defaultFormData);
    }

    const onBlur = (e, name) => {
        validateInput();
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (!validateInput()) {
            setHasInvalidSubmitOccurred(true);
        } else {
            props.changePasswordService({
                newPassword: formData.password,
                confirmPassword: formData.confirmPassword,
                oldPassword: formData.oldPassword
            }).then((data) => {
               
                const { status, message = "Internal Server Error" } = data;
                if (status) {
                    setAlertType(AlertType.SUCCESS);
                    setTimeout(()=>{
                        window.location.href = '/logout'
                    },3000)
                   
                } else {
                    setAlertType(AlertType.DANGER);
                }
                setAlertMessage(message);
                doReset();
            });
        }
    }

    const onClose = () => {
        setAlertMessage('');
    }

    return (
        <div className="change-password-wrap">
            <SectionTitle title={'Change Password'}>Change password</SectionTitle>
            <LoadingAnimation />
            <AlertMessage
                type={alertType}
                allowClose={true}
                onClose={onClose}
                message={alertMessage}
            />

            <form onSubmit={e => onSubmit(e)}>
                <div className="row">
                    <div className="col-sm-4">
                        <InputField
                            required={true}
                            autoComplete="off"
                            type="password"
                            name="oldPassword"
                            value={formData.oldPassword}
                            onChange={handleInputChange}
                            error={error.oldPassword}
                            label={"Old Password"}
                            containerClass={"form-group"}
                            onBlur={onBlur}
                            hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-4">
                        <InputField
                            required={true}
                            autoComplete="off"
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            error={error.password}
                            label={"Password"}
                            containerClass={"form-group"}
                            onBlur={onBlur}
                            hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                        />
                    </div>
                    <div className="col-sm-4">
                        <InputField
                            required={true}
                            type="password"
                            name="confirmPassword"
                            label="Confirm Password"
                            value={formData.confirmPassword}
                            onChange={handleInputChange}
                            error={error.confirmPassword}
                            containerClass={"form-group"}
                            onBlur={onBlur}
                            autoComplete="off"
                            hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                        />
                    </div>
                </div>
                <div className="tips-wrap clearfix">
                    <img src={img} alt="" />
                    <div className="tip-content">
                        <h6>Tips</h6>
                        <p>Password must be 8 characters long &amp; combination of 1 uppercase letter, 1 smallcase letter
&amp; 1 number. </p>
                    </div>
                </div>

                <div className="">
                    <Button
                        className="bttn"
                        type="submit"
                        text="Submit"
                    />
                    <Button
                        className="bttn blue"
                        type='button'
                        onClick={doReset}
                        text="Reset"
                        icon={(<img src={refreshIconwhite} />)}
                    />
                    <Link className="bttn-link" to='/dashboard'>
                        Cancel
          </Link>
                </div>
            </form>
        </div>
    );
}




const mapStateToProps = (state) => {
    return { changePasswordState: state.changePassword }
}

export default connect(mapStateToProps, { changePasswordService, clearMessage })(withRouter(ChangePassword));
