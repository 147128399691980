import React, { useEffect, useState } from 'react';
import './scss/index.scss';
import SectionTitle from '../common/sectionTitle/sectionTitle';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { getAuditTrailList } from '../dashboard/dependencies/action';
import Table from '../common/Table';
import columns from './dependencies/columns';
import moment from 'moment';
import InputField from '../common/InputField';
import Button from '../common/Button';


const AuditTrail = (props) => {
  const { getAuditTrailList, auditTrailList } = props;
  const defaultFormData = {
    userName: '',
    dateFrom: '',
    dateTo: ''
  }
  const [formData, setFormData] = useState(defaultFormData);
  const fetchAllRecords = false;
  const [pageSize, setpageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [query, setQuery] = useState({
  });

  useEffect(() => {
    if (getAuditTrailList) {
      getAuditTrailList({
        fetchAllRecords,
        pageIndex,
        pageSize,
        query
      })
    }
  }, [pageIndex, pageSize, query]);

 const handleInputChange = (e, name) => {
    const { value, } = e.target;
    setFormData((formData) => {
      return { ...formData, [name]: value }
    });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    let searchData = {
      fromDate: formData.dateFrom ? moment(formData.dateFrom).format("YYYY-MM-DD") : '',
      searchQuery: formData.userName ? formData.userName : '',
      toDate: formData.dateTo ? moment(formData.dateTo).format("YYYY-MM-DD") : ''
    }
    
    props.getAuditTrailList({
      fetchAllRecords,
        pageIndex,
        pageSize,
      query: {
        fromDate: formData.dateFrom ? moment(formData.dateFrom).format("YYYY-MM-DD") : '',
        searchQuery: formData.userName ? formData.userName : '',
        toDate: formData.dateTo ? moment(formData.dateTo).format("YYYY-MM-DD") : ''
      }
    });
    setQuery(searchData)
  }
  const handleOnPageChange = (params) => {
    const { page } = params;
    setPageIndex(page - 1);
    
  }
 
  return (
    <div className="audit-trail-outer">
      <SectionTitle title={'Dashboard'}>Audit trail</SectionTitle>
      <div className="audit-filter-wrap">
        <form onSubmit={e => onSubmit(e)}>
          <div className="row">
            <div className="col-sm-3">
              <InputField
                autoComplete="off"
                type="text"
                name="userName"
                value={formData.userName}
                onChange={handleInputChange}
                label={"Username"}
                containerClass={"form-group"}
              />
            </div>
            <div className="col-sm-3">
              <InputField
                autoComplete="off"
                type="date"
                name="dateFrom"
                max={moment().format("YYYY-MM-DD")}
                value={formData.dateFrom}
                onChange={handleInputChange}
                label={"From Date"}
                containerClass={"form-group"}
              />

            </div>
            <div className="col-sm-3">
              <InputField
                autoComplete="off"
                type="date"
                max={moment().format("YYYY-MM-DD")}
                min={moment(formData.dateFrom).format("YYYY-MM-DD")}
                name="dateTo"
                value={formData.dateTo}
                onChange={handleInputChange}
                label={"To Date"}
                containerClass={"form-group"}
              />
            </div>
          </div>
          <div className="btn-wrap  mb-30">
            <Button
              className="bttn normal"
              type="submit"
              text="Submit"
            />
          </div>
        </form>
      </div>
      <div className="audit-trail-wrap">
        <div className="audit-trail-inner">
          
          <Table
            dataTable={auditTrailList && auditTrailList[0] && auditTrailList[0].data}
            columns={columns()}
            keyField='auditId'
            onPageChange={handleOnPageChange}
            totalSize={ auditTrailList && auditTrailList[0] && auditTrailList[0].recordsFiltered}
            sizePerPage={pageSize}

         />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {

  return {
    auditTrailList: state.DashboardReducer.dashboardAuditTrail
  }
}

export default connect(mapStateToProps, { getAuditTrailList })(withRouter(AuditTrail))
