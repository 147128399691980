import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InlineError from '../inlineError';
import './scss/index.scss';

const InputField = (props) => {
  const {
    containerClass, value,
    error, onChange, type, label, required, name,
    hasInvalidSubmitOccurred, placeholder, readonly,
    disabled, onBlur, getRef = "", className, labelClass, ...rest
  } = props;

  const [touched, setTouched] = useState(false);

  const handleInputBlur = (e, name) => {
    setTouched(true);
    if (onBlur) {
      onBlur(e, name);
    }
    return true;
  };


  const displayError = touched || hasInvalidSubmitOccurred;

  return (
    <div
      className={`input-field-container clearfix ${containerClass || ''} ${displayError && error ? 'error' : ''}`}
    >
      <div className="inner-wrap">
        { label && !rest.hidden &&
        <label
          htmlFor=""
          className={labelClass}
        >
          {label}
          {
            required
            && (
              <span className="required">&nbsp;*</span>
            )
          }
        </label>}
        {
          <input
            readOnly={readonly}
            ref={getRef}
            name={name}
            disabled={disabled}
            type={type}
            value={value}
            className={`input-element ${value ? 'filled' : ''} ${className}`}
            onChange={e => onChange(e, name)}
            onBlur={e => handleInputBlur(e, name)}
            placeholder={placeholder}
            {...rest}
          />
        }
      </div>
      {
        displayError && error && (
          <InlineError message={error} />
        )
      }
    </div>
  );

}


InputField.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  hasInvalidSubmitOccurred: PropTypes.bool,
  getRef: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  className: PropTypes.string,
  labelClass: PropTypes.string,
  containerClass: PropTypes.string
};

InputField.defaultProps = {
  disabled: false,
  value: '',
  error: '',
  onChange: () => { },
  type: '',
  label: '',
  required: false,
  name: '',
  mask: '',
  placeholder: '',
  containerClass: '',
  onBlur: () => { },
  hasInvalidSubmitOccurred: false,
  getRef: () => { },
  className: '',
  labelClass: '',
};

export default InputField;