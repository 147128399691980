import {
    validateRejectDropDown,
    validateOther
} from '../../../dependencies/utils/validation/Validation';

export default {
    validateCancellationAction: (params) => {
        const { status, reason, other, isOtherOption } = params;
        let formStatus = {};
        const statusFieldStatus = validateRejectDropDown(status);
        const reasonStatus = validateRejectDropDown(reason);
        const otherStatus = validateOther(other);
        const isOtherExist = isOtherOption || false;
        formStatus = {
            status: statusFieldStatus.error,
            reason: reasonStatus.error,
            other: isOtherExist && otherStatus.error || "",

            hasErrors: (
                statusFieldStatus.hasError
                || reason.hasError
                || (isOtherExist && otherStatus.hasError)
            )
        };
        return formStatus;
    },
    // validateDropDownValue: (params) => {
    //     const { dropDownValidate, other, approveRejectValidate } = params;
    //     console.log('approveRejectValidate', approveRejectValidate)
    //     if ((params.other && params.other.length !== 0) || (params.other == '')) {
    //         let formStatus = {};
    //         const dropDownValidateStatus = validateRejectDropDown(dropDownValidate);
    //         const otherStatus = validateOther(other);

    //         formStatus = {
    //             dropDownValidate: dropDownValidateStatus.error,
    //             other: otherStatus.error,

    //             hasErrors: (
    //                 dropDownValidateStatus.hasError,
    //                 otherStatus.hasError
    //             )
    //         };
    //         return formStatus;
    //     }
    //     else if (approveRejectValidate !== undefined) {

    //         let formStatus = {};
    //         const dropDownValidateStatus = validateRejectDropDown(dropDownValidate);
    //         const approveRejectValidateStatus = validateRejectDropDown(approveRejectValidate);

    //         formStatus = {
    //             dropDownValidate: dropDownValidateStatus.error,
    //             approveRejectValidate: approveRejectValidateStatus.error,
    //             hasErrors: (
    //                 dropDownValidateStatus.hasError,
    //                 approveRejectValidateStatus.hasError
    //             )
    //         };
    //         return formStatus;
    //     } else {

    //         let formStatus = {};
    //         const dropDownValidateStatus = validateRejectDropDown(dropDownValidate);

    //         formStatus = {
    //             dropDownValidate: dropDownValidateStatus.error,
    //             hasErrors: (
    //                 dropDownValidateStatus.hasError
    //             )
    //         };
    //         return formStatus;
    //     }


    // },



};
