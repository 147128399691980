import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ActionPopup from '../actionPopup';

const TableHOC = (TableWrapperComponent) => {
  const TableWrapper = (props) => {
    const {
      dataTable,
      keyField,
      selectRowEnabled,
      selectMode,
      columns,
      onSelectRows,
      onSortChange,
      totalSize,
      sizePerPage,
      onPageChange,
      onSizePerPageChange,
      rowStyle,
      actionHandler,
      tableActions = [],
      selected=[],
      cssClass,
      wrapperClasses
    } = props;

    const handleOnSelect = (row, isSelect, rowIndex, e) => {
      if (isSelect && row && row[keyField]) {

        setSelectedRow(rows => {
          return [...rows, row[keyField]];
        });
      } else {
        setSelectedRow(rows => {
          const selectedRows = rows.filter(x => x !== row[keyField])
          return [...selectedRows]
        });
      }
    }

    const handleOnSelectAll = (isSelect, rows, e) => {
      const selectedRowKey = rows.map(r => r[keyField]);
      if (isSelect) {
        setSelectedRow(selectedRowKey);
      } else {
        setSelectedRow([]);
      }
    }

    const handlePerPageChange = (sizePerPage, page) => {
      if (onSizePerPageChange) {
        onSizePerPageChange({
          sizePerPage, page
        });
      }

    }
    const handleOnPageChange = (page, sizePerPage) => {
      if (onPageChange) {
        onPageChange({
          sizePerPage, page
        })
      }
      setPaginationOption(option => {
        return { ...option, page }
      })
    }

    const rowSelectOption = {
      mode: 'checkbox',
      clickToSelect: false,
      hideSelectColumn: true,
      selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
        <React.Fragment>
        <div className="header-selection">
          <input
            type="checkbox"
            ref={(input) => {
              if (input) input.indeterminate = indeterminate;
            }}
            {...rest}
          />
          {
            tableActions && tableActions instanceof Array && tableActions.length > 0 &&
            <ActionPopup
              actionHandler={actionHandler}
              actions={tableActions}
            />
          }
          </div>
        </React.Fragment>
      ),
      onSelect: handleOnSelect,
      onSelectAll: handleOnSelectAll,
      selected
    }

    const defaultPaginationOption = {
      page: 1,
      paginationSize: 4,
      pageStartIndex: 1,
      sizePerPage: 100,
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: 'All', value: totalSize
      }], // A numeric array is also available. the purpose of above example is custom the text
      onSizePerPageChange: (sizePerPage, page) => {
        handlePerPageChange(sizePerPage, page);
      },
      onPageChange: (page, sizePerPage) => {
        handleOnPageChange(page, sizePerPage);
      },
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true
    };
    const [selectRowOptions, setSelectRowOptions] = useState(rowSelectOption);
    const [selectedRow, setSelectedRow] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortField, setSortField] = useState('');
    const [paginationOptions, setPaginationOption] = useState(defaultPaginationOption);

    useEffect(() => {
      if (selectRowEnabled) {
        const mode = selectMode || 'checkbox';
        setSelectRowOptions((options) => {
          return { ...options, mode, hideSelectColumn: !selectRowEnabled, selected }
        });
      }
    }, [selected]);

    useEffect(() => {
      if (onSelectRows) {
        onSelectRows(selectedRow)
      }
    }, [selectedRow])

    useEffect(() => {
      if (onSortChange) {
        onSortChange({
          order: sortOrder, dataField: sortField
        })
      }
    }, [sortOrder, sortField])

    useEffect(() => {
      if (totalSize && !isNaN(totalSize)) {
        setPaginationOption(options => {
          return { ...options, totalSize: totalSize }
        });
      }

    }, [totalSize]);

    useEffect(() => {
      let size = dataTable && dataTable.length || 0;
      let pageSize = dataTable && dataTable.length || 0;
      if (totalSize) {
        size = totalSize;
      }
      if (sizePerPage) {
        pageSize = sizePerPage
      }
      setPaginationOption(option => {
        return { ...option, sizePerPage: pageSize, totalSize: size }
      })
    }, [sizePerPage, totalSize, dataTable.length])


    const onTableChange = (type, newState) => {
      const { sortField, sortOrder } = newState;
      switch (type) {
        case "sort":
          setSortOrder(sortOrder);
          setSortField(sortField);
          break;

        default:
          break;
      }

    }
    return (
      <div className="table-container">
        <TableWrapperComponent
          dataTable={dataTable}
          columns={columns}
          keyField={keyField}
          selectRowOptions={selectRowOptions}
          // sortOption={sortOption}
          paginationOptions={paginationOptions}
          onTableChange={onTableChange}
          rowStyle={rowStyle}
          cssClass={cssClass}
          wrapperClasses={wrapperClasses}
        />
      </div>
    );
  }
  TableWrapper.propTypes = {
    dataTable: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
      dataField: PropTypes.string,
      text: PropTypes.string
    })).isRequired,
    keyField: PropTypes.string.isRequired,
    totalSize: PropTypes.number,
    sizePerPage: PropTypes.number,
    onPageChange: PropTypes.func,
    onSizePerPageChange: PropTypes.func,
    rowStyle: PropTypes.func,
    actionHandler: PropTypes.func,
    tableActions: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      text: PropTypes.string,
      icon: PropTypes.string
    }))
  };
  TableWrapper.defaultProps = {
    dataTable: [],
    totalSize: 0,
    sizePerPage: 10,
    onPageChange: () => { },
    onSizePerPageChange: () => { },
    rowStyle: () => { },
    actionHandler: () => { },
    tableActions: []
  };
  return TableWrapper;
};

export default TableHOC;