import { FETCH_USER_LIST_FIFTY_SIX } from '../../constants/actionConstants';


const initialState = {
    userData: [],
};
export default (state = initialState, action) => {
    let newState;
    switch(action.type) {
        case FETCH_USER_LIST_FIFTY_SIX:
            newState = {
                ...state,
                userData: action.payload
            };
            return newState;
        default:
            return state;
    }
}