import React from 'react';

import { SUPPORTING_DOCUMENTS, headerSortingStyle } from '../../../../constants';
import moment from 'moment';
import { unEscapeRegHtml } from '../../../../dependencies/utils/helper';


const columns = [
  

    {
        dataField: 'recognition',
        text: 'Registration No',
        formatter: (cell, row) => {
            if (cell && cell.incorporationNumber) {
                return cell.incorporationNumber;
            }

        },
    },
    {
        dataField: 'recognition',
        text: 'Entity Name',
        formatter: (cell, row) => {
            if (cell && cell.startupEntity && cell.startupEntity.name) {
                return cell.startupEntity.name;
            }

        },
    },
    {
        dataField: 'incompleteAction',
        text: 'Incomplete Reason',

        formatter: (cell, row) => {
            if (cell && cell.incompleteReasons) {
                return cell.incompleteReasons.map((element, index) => {
                    if (element.reason == 'Others- If any other, please specify.') {
                        return <div>  {index + 1}. {unEscapeRegHtml(cell.incompleteDescription)}</div>

                    } else {
                        return <div>  {index + 1}. {unEscapeRegHtml(element.reason)}</div>

                    }
                })
            }

        }
    },
    {
        dataField: 'incompleteAction',
        text: 'Incomplete Date',
        formatter: (cell, row) => {
            if (cell && cell.incompleteDate) {
                return (moment(cell.incompleteDate).format('YYYY-MM-DD'))

            }

        },
    },

    {
        dataField: 'rejectAction',
        text: 'Reject Reason',
  
        formatter: (cell, row) => {
          if (cell && cell.rejectDescription) {
          
              return unEscapeRegHtml(cell.rejectDescription);
           
          }
  
        }
      },
      {
        dataField: 'rejectAction',
        text: 'Reject Date',
        formatter: (cell, row) => {
          if (cell && cell.rejectDate) {
            return (moment(cell.rejectDate).format('YYYY-MM-DD'))
  
          }
  
        },
      },
    {
        dataField: 'status',
        text: 'Status',

        formatter: (cell, row) => {

            if (row.migratedData === true) {
                return 'Migrated Data';

            } else {
                if (row && row.status) {
                    if(row.status == "REJECT"){
                        return "REJECTED";
                    }
                    return row.status;

                }

            }

        },

    },
];




{/* <TableHeaderColumn width='150px' dataField='supportingDocument' dataFormat={this.supportingDocumentFormatter}>Supporting Document</TableHeaderColumn> */ }

export default columns;