import { FETCH_STARTUP_SECTION } from '../../constants/actionConstants';

const initialState = {
    startupSelectionData: ''
};

export default (state = initialState, action) => {

    let newState;
    switch (action.type) {

        case FETCH_STARTUP_SECTION:
            newState = {
                ...state,
                startupSelectionData: action.payload
            };
            return newState;
        default:
            return state;
    }
}
