import React, { Component, useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchListAction, deleteGlossaryAction } from './dependencies/action';
import columns from './dependencies/columns/columns';
import Table from '../common/Table';
import { ROLES } from '../../constants/Roles';
import { storage } from '../../dependencies/store/storage';


const GlossaryTable = (props) => {
    const userRole = storage.getUserRole();

    const { fetchListAction, deleteGlossaryAction, glossaryList: { data } = {} } = props;
    const fetchAllRecords = false;
    const [pageSize, setpageSize] = useState(1000);
    const [pageIndex, setPageIndex] = useState(0);
    const [query, setQuery] = useState({
    });

    const getList = () => {
        if (fetchListAction) {
            fetchListAction(
                {
                    fetchAllRecords,
                    pageIndex,
                    pageSize,
                    query,
                }
            ).then((data) => {
            });
        }
    }

    useEffect(() => {
        getList();
    }, [pageSize, pageIndex, query]);


    const handleOnPageChange = (params) => {
        const { page } = params;
        setPageIndex(page - 1);
    }

    const clickOnAction = async (id) => {
        var r = window.confirm("Do you want to Delete this row?");
        if (r == true) {
            deleteGlossaryAction(id).then((data) => {
                const { status } = data;
                if (status) {
                    getList();
                }
            })
        } else {
        }
    }
    const validRoles = userRole === ROLES.SUPER_ADMIN || userRole === ROLES.ADMIN;
    return (
        <div>

            <div className="report-table">
                {
                    validRoles &&
                    <h3>Manage Glossary <Link to={'/glossary/form'} className="bttn blue normal add-btn" style={{ float: 'right', minWidth: 'auto', marginRight: '0' }}>Add</Link></h3>
                }
                <Table
                    dataTable={data}
                    columns={columns({
                        clickOnAction,
                        pageNo: pageIndex,
                        size: pageSize
                    })}
                    keyField='id'
                    // totalSize={recordsTotal}
                    sizePerPage={pageSize}
                    onPageChange={handleOnPageChange}
                />
            </div>
        </div>)
}


const mapStateToProps = (state) => {
    return {
        glossaryList: state.GlossaryReducer.glossaryList,
    }
}

export default connect(mapStateToProps, { fetchListAction, deleteGlossaryAction })(withRouter(GlossaryTable));