import { combineReducers } from 'redux';
import {
    STARTUP_LOGO_LIST,
    STARTUP_LOGO_APP_COUNT,
    STARTUP_LOGO_VIEW_DETAIL

} from '../../../constants/actionConstants';

const startupLogoList = (state = [], action) => {
    switch (action.type) {
        case STARTUP_LOGO_LIST:
            return [action.payload]
        default:
            return state;
    }
}
const startupLogoAppCount = (state = [], action) => {
    switch (action.type) {
        case STARTUP_LOGO_APP_COUNT:
            return [action.payload]
        default:
            return state;
    }
}
const startupLogoDetails = (state = [], action) => {
    switch (action.type) {
        case STARTUP_LOGO_VIEW_DETAIL:
            return [action.payload]
        default:
            return state;
    }
}

const StartupLogoReducer = combineReducers({
    startupLogoList,
    startupLogoAppCount,
    startupLogoDetails
    
})

export default StartupLogoReducer;