import {UPDATE_BULK_ARVCHIVE} from '../../constants/actionConstants';


const initialState = {
    bulkArchive: ''
};

export default (state = initialState, action) => {
    let newState;
    switch (action.type) {

        case UPDATE_BULK_ARVCHIVE:
            newState = {
                ...state,
                bulkArchive: action.payload
            };
            return newState;
        default:
            return state;
    }
}
