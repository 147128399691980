import React, { useEffect, useState } from 'react';
import { updateIndustry, getIndustry } from '../../dependencies/action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../style.scss';
import InputField from '../../../common/InputField';


const Industry = (props) => {
    const { match: { params: { id = null } }, industryList = [], getIndustry, updateIndustry, history } = props;

    const [form, setForm] = useState({
        industryName: ""
    });
    const [error, setError] = useState("")

    useEffect(() => {
        if (id) {
            if (industryList && industryList.length > 0) {
                const industry = industryList.filter(x => x.id === id)[0] || {};
                const industryName = (industry && industry.industryName) || "";
                setForm(x => {
                    return { ...x, industryName, id }
                });
            } else {
                getIndustry().then((repsonse) => {
                });
            }
        }
    }, [industryList])

    const handleInputChange = (e, name) => {
        const { value, } = e.target;
        setForm((c) => {
            return { ...c, [name]: value }
        });
    }
    const handleCancel = () => {
        props.history.push(`/update/industry`);
    }

    const validateName = (value) => {
        // var nameRegex = /^(?![\s.]+$)[a-zA-Z\s.]*$/;
        var nameRegex =  /^[ A-Za-z_@./#&+-]*$/
        var name = value.trim();
        let error = "";
        if (name == "" || name == undefined || name == null) {
            error = "This field is required.";
        }
        else if (!nameRegex.test(name)) {
            error = "Please provide valid Industry name.";
        }
        else if (name.length < 2) {
            error = "Please provide valid Industry name.";
        }
        else {
            error = "";
        }
        setError(error);
        return error;
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const name = form && form.industryName;
        if (!validateName(name)) {
            const industryName = (form && form.industryName && form.industryName.trim()) || "";
            updateIndustry({ ...form, industryName }).then((response) => {
                const { status } = response;
                if (status) {
                    history.push(`/update/industry`);
                }
            });
        }
    }


    return (
        <div className="country-container">
            <h2 className="mb-20">{id ? "Update Industry" : "Add Industry"}
            </h2>
            <form onSubmit={e => { onSubmit(e) }}>
                <InputField
                    required
                    autoComplete="off"
                    type="text"
                    name="industryName"
                    value={form.industryName}
                    onChange={handleInputChange}
                    error={error}
                    label={"Industry Name"}
                    containerClass={"form-group"}
                />
                <button className="bttn normal add-btn" onClick={handleCancel}>
                    {"Cancel"}
                </button>
                <button className="bttn blue normal add-btn">
                    {"Submit"}
                </button>
            </form>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        industryList: state.UpdateReducer.industryList
    }
}

export default connect(mapStateToProps, { updateIndustry, getIndustry })(withRouter(Industry));