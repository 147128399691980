
export const SearchBySavedApplication = () => {

    return [

        {
            text: "Registration No",
            value: "incorporationNumber",
            searchFields: [{
                field: 'text',
                name: 'incorporationNumber'
            }],
        },
        {
            text: "Reference No",
            value: "referenceNumber",
            searchFields: [{
                field: 'text',
                name: 'referenceNumber'
            }],
        },
        {
            text: "Name of the Entity",
            value: "nameOfEntity",
            searchFields: [{
                field: 'text',
                name: 'nameOfEntity'
            }]
        },
        {
            text: "Application Date",
            value: "incorporationDate",
            searchFields: [{
                field: 'date',
                name: 'startDate',
                label: 'Start Date'
            }, {
                field: 'date',
                name: 'endDate',
                label: 'End Date'
            }],
        },

    ]
}


