import { combineReducers } from 'redux';
import {
    FETCH_TOTAL_REPORT,
    FETCH_ENTITY_TYPE_APPLICATION_REPORT,
    FETCH_RECOGNISED_REPORT,
    FETCH_TAX_BENEFIT_REPORT
} from '../../../constants/actionConstants';


const entityTypeApplicationReport = (state = [], action) => {
    switch (action.type) {
        case FETCH_ENTITY_TYPE_APPLICATION_REPORT:
            return { ...action.payload }
        default:
            return state;
    }
}

const recognisedReport = (state = {}, action) => {
    switch (action.type) {
        case FETCH_RECOGNISED_REPORT:
            return { ...action.payload }
        default:
            return state;
    }
}

const taxBenefitReport = (state = {}, action) => {
    switch (action.type) {
        case FETCH_TAX_BENEFIT_REPORT:
            return { ...action.payload };
        default:
            return state;
    }
}

const totalReport = (state = {}, action) => {
    switch (action.type) {
        case FETCH_TOTAL_REPORT:
            return { ...action.payload };
        default:
            return state;
    }
}


const ReportReducer = combineReducers({
    totalReport,
    taxBenefitReport,
    recognisedReport,
    entityTypeApplicationReport
})

export default ReportReducer;