export const ROLES = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    ADMIN: 'ADMIN',
    USER: 'USER',
    NRDC_ADMIN: 'NRDC_ADMIN',
    TIFAC_ADMIN: 'TIFAC_ADMIN',
    LOGO_ADMIN: 'LOGO_ADMIN',
    SEC_56_ADMIN: 'SEC_56_ADMIN',
    EP_1: 'EP_1',
    EP_2: 'EP_2',
    IMB_CHAIR:'IMB_CHAIR',
    IMB_MEMBER:'IMB_MEMBER'
}

export const routeWithRoles = {
    SUPER_ADMIN: [
        'logout',
        // user detail components's name
        'changePassword',
        'profile',
        'editProfile',
        'viewProfile',
        // dashboard
        'dashboard',
        // user management component's name
        'userManagement',
        'startupUsers',
        'eightyIacUsers',
        'formFiftySix',
        'editUser',
        'startupEdituser',
        'form56Edituser',
        // audit trails
        'auditTrail',
        //
        'application',
        // startup application list and their sub component's name
        'startupApplicationList',
        'startupEditForm',
        'startupApplicationView',
        // saved application 
        'savedApplicationList',
        'savedApplicationView',
        'savedEditForm',
        //archived
        'archivedApplicationList',
        'archivedApplicationView',
        // 80 IAC list and their sub components
        'IAC80List',
        'application80IACView',
        'NRDCList',
        'NRDCCommentUpdate',
        // 'addNewComment',
        // 'EditNewComment',
        'thread',
        'observationCommunicated80Iac',
        'IMBMembers',
        // section 56
        'section56View',
        'section56List',
        //startup logos
        'startupLogosList',
        'startupLogoView',
        //
        'startupDetailReport',
        'suiMonthlyReport',
        //
        'cancellationList',
        'cancellationView',
        //
        'expiredList',
        //
        'notificationDocumentsList',
        'notificationDocumentForm',
        'notificationDocumentForm',
        //
        'otherDocumentsList',
        'otherDocumentForm',
        'otherDocumentForm',
        //
        'glossaryList',
        'glossaryForm',
        'glossaryForm',
        //
        'summaryReport',
        'totalReport',
        'entityTypeReport',
        'recognisedReport',
        'taxBenefitReport',
        //
        'generateCor',
        'updateIncorporationNumber',
        //
        'update',
        'updateCountryList',
        'updateStateList',
        'countryForm',
        'stateForm',
        'districtList',
        'subDistrictList',
        'updateIndustryList',
        'industryForm',
        'updateserviceList',
        'serviceForm',
        'sectionList',
        //
        'incompleteRecognitionReport',
        'incomplete80iacReport',
        //
        'download',
        'startupApplication',
        'startupApplication80Iac',
        'section56list',
        'section56Report',
        'dpiitRecognized',
        'beneficiary',
        'nsaResult',

    ],
    ADMIN: [
        'logout',
        // user detail components's name
        'changePassword',
        'profile',
        'editProfile',
        'viewProfile',
        // dashboard
        'dashboard',
        // // user management component's name
        'userList',
        'userManagement',
        'startupUsers',
        'eightyIacUsers',
        'formFiftySix',
        // 'editUser',

        // audit trails
        'auditTrail',
        //
        'application',
        // startup application list and their sub component's name
        'startupApplicationList',
        'startupEditForm',
        'startupApplicationView',
        // saved application 
        'savedApplicationList',
        'savedApplicationView',
        'savedEditForm',
        //archived
        'archivedApplicationList',
        'archivedApplicationView',
        // 80 IAC list and their sub components
        'IAC80List',
        'application80IACView',
        'NRDCList',
        'NRDCCommentUpdate',
        // 'addNewComment',
        // 'EditNewComment',
        'thread',
        'observationCommunicated80Iac',
        'IMBMembers',
        // section 56
        'section56View',
        'section56List',
        //startup logos
        'startupLogosList',
        'startupLogoView',
        //
        //'startupDetailReport',
        //
        'cancellationList',
        'cancellationView',
        //
        'expiredList',
        //
        'notificationDocumentsList',
        'notificationDocumentForm',
        'notificationDocumentForm',
        //
        'otherDocumentsList',
        'otherDocumentForm',
        'otherDocumentForm',
        //
        'glossaryList',
        'glossaryForm',
        'glossaryForm',
        //
        'summaryReport',
        'totalReport',
        'entityTypeReport',
        'recognisedReport',
        'taxBenefitReport',
        //
        'generateCor',
        'incompleteRecognitionReport',
        'incomplete80iacReport',
        'download',
        'startupApplication',
        'startupApplication80Iac',
        'section56list',
        'section56Report',
        'suiMonthlyReport',
        'beneficiary',
        'nsaResult',
        //'dpiitRecognized'

    ],
    USER: [
        'logout',
        // user detail components's name
        'changePassword',
        'profile',
        'editProfile',
        'viewProfile',
        // dashboard
        'dashboard',
        // // user management component's name
        // 'userList',
        // 'editUser',

        // audit trails
        'auditTrail',
        //
        'application',
        // startup application list and their sub component's name
        'startupApplicationList',
        'startupApplicationView',
        // saved application 
        'savedApplicationList',
        'savedApplicationView',
        //archived
        'archivedApplicationList',
        'archivedApplicationView',
        // 80 IAC list and their sub components
        'IAC80List',
        'application80IACView',
        'NRDCList',
        'NRDCCommentUpdate',
        // 'addNewComment',
        // 'EditNewComment',
        'thread',
        'observationCommunicated80Iac',
        'IMBMembers',
        // section 56
        'section56View',
        'section56List',
        //startup logos
        'startupLogosList',
        'startupLogoView',
        //
        'startupDetailReport',
        //
        'cancellationList',
        'cancellationView',
        //
        'expiredList',
        //
        'notificationDocumentsList',
        'notificationDocumentForm',
        'notificationDocumentForm',
        //
        'otherDocumentsList',
        'otherDocumentForm',
        'otherDocumentForm',
        //
        'glossaryList',
        'glossaryForm',
        'glossaryForm',
        //
        'summaryReport',
        'totalReport',
        'entityTypeReport',
        'recognisedReport',
        'taxBenefitReport',
        'incompleteRecognitionReport',
        'incomplete80iacReport'
    ],
    NRDC_ADMIN: [
        'logout',
        // user detail components's name
        'changePassword',
        'profile',
        'editProfile',
        'viewProfile',
        // dashboard
        // 'dashboard',
        //
        'application',
        // 80 IAC list and their sub components
        'IAC80List',
        'application80IACView',
        'NRDCList',
        'NRDCCommentUpdate',
        'addNewComment',
        'EditNewComment',
        'thread',
        'observationCommunicated80Iac',
        'IMBMembers',
    ],
    TIFAC_ADMIN: [
        'logout',
        // user detail components's name
        'changePassword',
        'profile',
        'editProfile',
        'viewProfile',
        // dashboard
        // 'dashboard',
        //
        'application',
        // 80 IAC list and their sub components
        'IAC80List',
        'application80IACView',
        'NRDCList',
        'NRDCCommentUpdate',
        'addNewComment',
        'EditNewComment',
        'thread',
        'observationCommunicated80Iac',
        'IMBMembers',
    ],
    LOGO_ADMIN: [
        'logout',
        // user detail components's name
        'changePassword',
        'profile',
        'editProfile',
        'viewProfile',
        // dashboard
        // 'dashboard',
        // // user management component's name
        // 'userList',
        // 'editUser',

        //startup logos
        'startupLogosList',
        'startupLogoView',
    ],

    SEC_56_ADMIN: [
        'logout',
        'changePassword',
        'profile',
        'editProfile',
        // 'dashboard',
        //
        'application',
        // section 56
        'section56List',
        'section56View',

        'download',
        // 'section56list',
        // 'startupDetailReport',
        'section56Report',
        'dpiitRecognized'
    ],

    EP_1: [
        'logout',
        // user detail components's name
        'changePassword',
        'profile',
        'editProfile',
        'viewProfile',
        // dashboard
        'dashboard',
        // // user management component's name
        // 'userList',
        // 'editUser',

        // audit trails
        // 'auditTrail',
        //
        'application',
        // startup application list and their sub component's name
        // 'startupApplicationList',
        // 'startupEditForm',
        // 'startupApplicationView',
        // saved application 
        // 'savedApplicationList',
        // 'savedApplicationView',
        // 'savedEditForm',
        //archived
        // 'archivedApplicationList',
        // 'archivedApplicationView',
        // 80 IAC list and their sub components
        'IAC80List',
        'application80IACView',
        // 'NRDCList',
        // 'NRDCCommentUpdate',
        // 'addNewComment',
        // 'EditNewComment',
        // 'thread',
        // 'observationCommunicated80Iac',
        // 'IMBMembers',
        // section 56
        // 'section56View',
        // 'section56List',
        //startup logos
        // 'startupLogosList',
        // 'startupLogoView',
        //
        //'startupDetailReport',
        //
        // 'cancellationList',
        // 'cancellationView',
        //
        // 'expiredList',
        //
        // 'notificationDocumentsList',
        // 'notificationDocumentForm',
        // 'notificationDocumentForm',
        //
        // 'otherDocumentsList',
        // 'otherDocumentForm',
        // 'otherDocumentForm',
        //
        // 'glossaryList',
        // 'glossaryForm',
        // 'glossaryForm',
        //
        // 'summaryReport',
        // 'totalReport',
        // 'entityTypeReport',
        // 'recognisedReport',
        // 'taxBenefitReport',
        //
        // 'generateCor',
        // 'incompleteRecognitionReport',
        // 'incomplete80iacReport',
        // 'download',
        // 'startupApplication',
        // 'startupApplication80Iac',
        // 'section56list',
        // 'section56Report',
        //'dpiitRecognized'
    ],
    EP_2: [
        'logout',
        // user detail components's name
        'changePassword',
        'profile',
        'editProfile',
        'viewProfile',
        // dashboard
        'dashboard',
        // // user management component's name
        // 'userList',
        // 'editUser',

        // audit trails
        // 'auditTrail',
        //
        'application',
        // startup application list and their sub component's name
        // 'startupApplicationList',
        // 'startupEditForm',
        // 'startupApplicationView',
        // saved application 
        // 'savedApplicationList',
        // 'savedApplicationView',
        // 'savedEditForm',
        //archived
        // 'archivedApplicationList',
        // 'archivedApplicationView',
        // 80 IAC list and their sub components
        'IAC80List',
        'application80IACView',
    ],
    IMB_CHAIR: [
        'logout',
        // user detail components's name
        'changePassword',
        'profile',
        'editProfile',
        'viewProfile',
        // dashboard
        'dashboard',
        // // user management component's name
        // 'userList',
        // 'editUser',

        // audit trails
        // 'auditTrail',
        //
        'application',
        // startup application list and their sub component's name
        // 'startupApplicationList',
        // 'startupEditForm',
        // 'startupApplicationView',
        // saved application 
        // 'savedApplicationList',
        // 'savedApplicationView',
        // 'savedEditForm',
        //archived
        // 'archivedApplicationList',
        // 'archivedApplicationView',
        // 80 IAC list and their sub components
        'IAC80List',
        'application80IACView',
        'thread'
    ],
    IMB_MEMBER: [
        'logout',
        // user detail components's name
        'changePassword',
        'profile',
        'editProfile',
        'viewProfile',
        // dashboard
        'dashboard',
        // // user management component's name
        // 'userList',
        // 'editUser',

        // audit trails
        // 'auditTrail',
        //
        'application',
        // startup application list and their sub component's name
        // 'startupApplicationList',
        // 'startupEditForm',
        // 'startupApplicationView',
        // saved application 
        // 'savedApplicationList',
        // 'savedApplicationView',
        // 'savedEditForm',
        //archived
        // 'archivedApplicationList',
        // 'archivedApplicationView',
        // 80 IAC list and their sub components
        'IAC80List',
        'application80IACView',
    ]
}