import React, { Component } from 'react';
import HomePage from '../../components/homepage';
import Login from '../../components/Auth/Login';
import ForgetPassword from '../../components/Auth/ForgotPassword';
import ResetPassword from '../../components/Auth/resetPassword';
import ChangePassword from '../../components/AdminDetails/changePassword';
import Profile from '../../components/AdminDetails/viewProfile/index'
import Dashboard from '../../components/dashboard';
import AuditTrail from '../../components/auditTrail'
import StartupApplicationForm from '../../components/startupApplication/startupApplicationForm/';
import EditUser from '../../components/userManagement/editUser';
import Form56Edituser from '../../components/userManagement/form56Edituser';
import StartupEdituser from '../../components/userManagement/startupEdituser';
import EditProfile from '../../components/AdminDetails/editProfile';
import AddUser from '../../components/addUser';
import ViewProfile from '../../components/AdminDetails/viewProfile/index'
import UserListEightyIAC from '../../components/userManagement/userList';
import UserListStartupUsers from '../../components/userManagement/UserListStartupUsers';
import UserListFromFiftySix from '../../components/userManagement/UserListFromFiftySix';
import Auth from '../../components/Auth';
import CommunicationRequest from '../../components/applicationFor80Iac/commnication';
import IAC80List from '../../components/applicationFor80Iac/IAC80List';
import NRDCCommentUpdate from '../../components/applicationFor80Iac/nrdcCommentUpdate';
import AddNewComment from '../../components/applicationFor80Iac/addNewComment';
import Section56Info from '../../components/Section56/section56IdDetails';
import Section56List from '../../components/Section56/List';
import TotalReport from '../../components/Report/totalReport';
import EntityTypeReport from '../../components/Report/entityTypereport';
import RecognisedReport from '../../components/Report/recognisedReport';
import TaxBenefitReport from '../../components/Report/taxBenefitReport';
import ExpiredList from '../../components/Expired';
import StartupApplicationView from '../../components/startupApplication/startupApplicationView/index'
import StartupApplication from '../../components/startupApplicationList';
import SavedApplication from '../../components/savedApplicationList';
import ArchivedApplication from '../../components/archivedApplicationList';
import CancellationList from '../../components/cancellation/List';
import Application80IACView from '../../components/applicationFor80Iac/view'
import Application80IACExpertPanelView from '../../components/applicationFor80Iac/expertPanel/index'
import Logout from '../../components/AdminDetails/logout/logout';
import ObservationCommunicated80Iac from "../../components/applicationFor80Iac/observationCommunicated/";
import IMBMembers from "../../components/applicationFor80Iac/IMBMembers";
import StartupLogos from '../../components/startupLogos';
import StartupLogoView from '../../components/startupLogos/view';
import NotificationDocuments from '../../components/NotificationDocument';
import AddEditNotificationDocuments from '../../components/NotificationDocument/AddEditNotificationDocuments';
import StartupDetailReport from '../../components/startupDetailReport';
import GenerateCor from '../../components/generateCor';
import GlossaryTable from '../../components/GlossaryController';
import AddEditGlossary from '../../components/GlossaryController/AddEditGlossary';
import OtherDocuments from '../../components/OtherDocuments';
import AddEditOtherDocuments from '../../components/OtherDocuments/AddEditOtherDocuments';
import UpdateIncorporationNumber from '../../components/updateincorporationNumber';
import Country from '../../components/Update/Country';
import State from '../../components/Update/State';
import District from '../../components/Update/District';
import SubDistrict from '../../components/Update/SubDistrict';
import AddOrEditCountry from '../../components/Update/Country/AddOrEdit';
import AddOrEditState from '../../components/Update/State/AddOrEdit';
import Industry from '../../components/Update/Industry';
import AddOrEditIndustry from '../../components/Update/Industry/AddOrEdit';
import Services from '../../components/Update/Services';
import AddOrEditService from '../../components/Update/Services/AddOrEdit';
import Section from '../../components/Update/Section';
import IncompleteRecognitionReport from '../../components/incompleteRecognitionReport';
import Incomplete80iacReport from '../../components/incomplete80iacReport';
import StartupApplicationDownload from '../../components/DownloadSection/startupApplication';
import StartupApplication80IacDownload from '../../components/DownloadSection/applicationFor80Iac'
import Section56Download from '../../components/DownloadSection/section56'
import Section56Report from '../../components/section56Report'
import DpiitRecognitionReport from '../../components/dpiitRecognitionReport'
import suiMonthlyReport from '../../components/suiMonthlyReport';
import NSAResult from '../../components/beneficiary';

export const desktopRoutes = [
    {
        path: "/auth",
        component: Auth,
        name: 'auth',
    },
    {
        path: "/logout",
        component: Logout,
        name: 'logout',
        exact: true,

    },
    {
        path: "/application/startup/edit/:id",
        component: StartupApplicationForm,
        name: 'startupEditForm',
        exact: true,

    },
    {
        path: "/dashboard",
        component: Dashboard,
        name: 'dashboard',
        exact: true,

    },
    {
        path: "/audit-trail",
        component: AuditTrail,
        name: 'auditTrail',
        exact: true,

    },
    {
        path: "/changePassword",
        component: ChangePassword,
        name: 'changePassword',
        exact: true,

    },
    {
        path: "/profile",
        component: Profile,
        name: 'profile',
        exact: true,

    },
    {
        path: "/user-management/adduser",
        component: AddUser,
        name: 'addUser',
        exact: true,

    },
    {
        path: "/user-management/edituser/:id",
        component: EditUser,
        name: 'editUser',
        exact: true,

    },
    {
        path: "/user-management/startupEdituser/:id",
        component: StartupEdituser,
        name: 'startupEdituser',
        exact: true,

    },
    {
        path: "/user-management/form56Edituser/:id",
        component: Form56Edituser,
        name: 'form56Edituser',
        exact: true,

    },
    {
        path: "/edit-profile",
        component: EditProfile,
        name: 'editProfile',
        exact: true,

    },
    {
        path: "/user-management/viewprofile",
        component: ViewProfile,
        name: 'viewProfile',
        exact: true,

    },
    {
        path: "/user-management/eighty-iac-users",
        component: UserListEightyIAC,
        name: 'eightyIacUsers',
        exact: true,

    },
    {
        path: "/user-management/startup-users",
        component: UserListStartupUsers,
        name: 'startupUsers',
        exact: true,

    },
    {
        path: "/user-management/form-fifty-six",
        component: UserListFromFiftySix,
        name: 'formFiftySix',
        exact: true,

    },
    {
        path: "/application/startup/:id",
        component: StartupApplicationView,
        name: 'startupApplicationView',
        exact: true,

    },
    {
        path: "/application/startup",
        component: StartupApplication,
        name: 'startupApplicationList',
        exact: true,

    },
    {
        path: "/application/saved",
        component: SavedApplication,
        name: 'savedApplicationList',
        exact: true,

    },
    {
        path: "/application/saved/:id",
        component: StartupApplicationView,
        name: 'savedApplicationView',
        exact: true,
    },
    {
        path: "/application/saved/edit/:id",
        component: StartupApplicationForm,
        name: 'savedEditForm',
        exact: true,

    },
    {
        path: "/application/archived",
        component: ArchivedApplication,
        name: 'archivedApplicationList',
        exact: true,

    },
    {
        path: "/application/archived/:id",
        component: StartupApplicationView,
        name: 'archivedApplicationView',
        exact: true,

    },
    {
        path: "/application/80iac/:id",
        component: Application80IACView,
        name: 'application80IACView',
        exact: true,

    },
    {
        path: "/application/80iacExpertPanel/:id",
        component: Application80IACExpertPanelView,
        name: 'Application80IACExpertPanelView',
        exact: true,

    },
    // {
    //     path: "/application/80iac/NRDClist/:id",
    //     component: NRDCList,
    //     name: 'NRDCList',
    //     exact: true,

    // },
    {
        path: "/application/80iac/comment/:id",
        component: NRDCCommentUpdate,
        name: 'NRDCCommentUpdate',
        exact: true,

    },
    {
        path: "/application/80iac/comment/form/:id",
        component: AddNewComment,
        name: 'addNewComment',
        exact: true,

    },
    {
        path: "/application/80iac/comment/form/:id/:rowId",
        component: AddNewComment,
        name: 'EditNewComment',
        exact: true,

    },
    {
        path: "/summaryreport/totalapplicationsreport",
        component: TotalReport,
        name: 'totalReport',
        exact: true,

    },
    {
        path: "/summaryreport/entityreport",
        component: EntityTypeReport,
        name: 'entityTypeReport',
        exact: true,

    },
    {
        path: "/summaryreport/recognisedreport",
        component: RecognisedReport,
        name: 'recognisedReport',
        exact: true,

    },
    {
        path: "/summaryreport/taxbenefitreport",
        component: TaxBenefitReport,
        name: 'taxBenefitReport',
        exact: true,

    },
    {
        path :"/section56Report",
        component : Section56Report,
        name : 'section56Report',
        exact : true,

    },
    {
        path : "/dpiitRecognized",
        component : DpiitRecognitionReport,
        name : 'dpiitRecognized',
        exact : true
    },
    {
        path: "/cancellation",
        component: CancellationList,
        name: 'cancellationList',
        exact: true,

    },
    {
        path: "/cancellation/:id",
        component: StartupApplicationView,
        name: 'cancellationView',
        exact: true,

    },
    {
        path: "/application/section56",
        component: Section56List,
        name: 'section56List',
        exact: true,

    },
    {
        path: "/application/section56/:id",
        component: Section56Info,
        name: 'section56View',
        exact: true,

    },
    {
        path: "/application/80iac/thread/:id",
        component: CommunicationRequest,
        name: 'thread',
        exact: true,

    },
    {
        path: "/application/80iac/observation/:id",
        component: ObservationCommunicated80Iac,
        name: 'observationCommunicated80Iac',
        exact: true,

    },
    {
        path: "/application/80iac/imb-members-comment/:id",
        component: IMBMembers,
        name: 'IMBMembers',
        exact: true,

    },
    {
        path: "/application/80iac",
        component: IAC80List,
        name: 'IAC80List',
        exact: true,

    },
    {
        path: "/startup-logo",
        component: StartupLogos,
        name: 'startupLogosList',
        exact: true,

    },
    {
        path: "/startup-logo/detail/:id",
        component: StartupLogoView,
        name: 'startupLogoView',
        exact: true,

    },
    {
        path: "/notification/list",
        component: NotificationDocuments,
        name: 'notificationDocumentsList',
        exact: true,

    },
    {
        path: "/notification/form",
        component: AddEditNotificationDocuments,
        name: 'notificationDocumentForm',
        exact: true,

    },
    {
        path: "/notification/form/:id",
        component: AddEditNotificationDocuments,
        name: 'notificationDocumentForm',
        exact: true,

    },
    {
        path: "/glossary",
        component: GlossaryTable,
        name: 'glossaryList',
        exact: true,

    },
    {
        path: "/glossary/form",
        component: AddEditGlossary,
        name: 'glossaryForm',
        exact: true,

    },
    {
        path: "/glossary/form/:id",
        component: AddEditGlossary,
        name: 'glossaryForm',
        exact: true,

    },
    {
        path: "/other/list",
        component: OtherDocuments,
        name: 'otherDocumentsList',
        exact: true,

    },
    {
        path: "/other/form",
        component: AddEditOtherDocuments,
        name: 'otherDocumentForm',
        exact: true,

    },
    {
        path: "/other/form/:id",
        component: AddEditOtherDocuments,
        name: 'otherDocumentForm',
        exact: true,

    },
    {
        path: "/startup-detail-report",
        component: StartupDetailReport,
        name: 'startupDetailReport',
        exact: true,

    },
    {
        path: "/sui-monthly-report",
        component: suiMonthlyReport,
        name: 'suiMonthlyReport',
        exact: true,

    },
    {
        path: "/incomplete-recognition-report",
        component: IncompleteRecognitionReport,
        name: 'incompleteRecognitionReport', 
        exact: true,

    },
    {
        path: "/incomplete-80iac-report",
        component: Incomplete80iacReport,
        name: 'incomplete80iacReport',
        exact: true,

    },
    
    {
        path: "/generate-cor",
        component: GenerateCor,
        name: 'generateCor',
        exact: true,

    },
    {
        path: "/expired/list",
        component: ExpiredList,
        name: 'expiredList',
        exact: true,

    },
    {
        path: "/update-incorporation-number",
        component: UpdateIncorporationNumber,
        name: 'updateIncorporationNumber',
        exact: true
    },
    {
        path: "/update/country",
        component: Country,
        name: 'updateCountryList',
        exact: true
    },
    {
        path: "/update/country/form",
        component: AddOrEditCountry,
        name: 'countryForm',
        exact: true
    },
    {
        path: "/update/country/form/:id",
        component: AddOrEditCountry,
        name: 'countryForm',
        exact: true
    },
    {
        path: "/update/state",
        component: State,
        name: 'updateStateList',
        exact: true
    },
    {
        path: "/update/state/form",
        component: AddOrEditState,
        name: 'stateForm',
        exact: true
    },
    {
        path: "/update/state/form/:id",
        component: AddOrEditState,
        name: 'stateForm',
        exact: true
    },
    {
        path: "/update/district",
        component: District,
        name: 'districtList',
        exact: true
    },
    {
        path: "/update/subdistrict",
        component: SubDistrict,
        name: 'subDistrictList',
        exact: true
    },
    {
        path: "/update/industry",
        component: Industry,
        name: 'updateIndustryList',
        exact: true
    },
    {
        path: "/update/industry/form",
        component: AddOrEditIndustry,
        name: 'industryForm',
        exact: true
    },
    {
        path: "/update/industry/form/:id",
        component: AddOrEditIndustry,
        name: 'industryForm',
        exact: true
    },
    {
        path: "/update/service",
        component: Services,
        name: 'updateserviceList',
        exact: true
    },
    {
        path: "/update/service/form",
        component: AddOrEditService,
        name: 'serviceForm',
        exact: true
    },
    {
        path: "/update/service/form/:id",
        component: AddOrEditService,
        name: 'serviceForm',
        exact: true
    },
    {
        path: "/update/sections",
        component: Section,
        name: 'sectionList',
        exact: true
    },
    {
        path: "/download/startupApplication",
        component: StartupApplicationDownload,
        name: 'startupApplication',
        exact: true
    },
    {
        path: "/download/80iac",
        component: StartupApplication80IacDownload,
        name: 'startupApplication80Iac',
        exact: true
    },
    {
        path: "/download/section56",
        component: Section56Download,
        name: 'section56list',
        exact: true
    },
    {
        path: "/nsa-result",
        component: NSAResult,
        name: 'nsaResult',
        exact: true,
    },
    
];



export const AuthRoute = [
    {
        path: "/auth/login",
        component: Login,
        name: 'login',
        exact: true,

    },
    {
        path: "/auth/forget-password",
        component: ForgetPassword,
        name: 'forgetPassword',
        exact: true,

    },
    {
        path: "/auth/reset-password",
        component: ResetPassword,
        name: 'resetPassword',
        exact: true,

    }
]