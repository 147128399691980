import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import DropDown from '../GlobalDropDown';
import InputField from '../InputField';
import Button from '../Button';
import './scss/index.scss';
import moment from 'moment';
import { toast } from 'react-toastify';

const SearchBox = (props) => {
  const { doSearch, searchByOptions = [], } = props;
  const [searchBy, setSearchBy] = useState({});
  const [queryFields, setQueryFields] = useState({});
  const [searchText, setSearchText] = useState('')

  const onChangeHandler = (e, name) => {
    const val = e && e.target && e.target.value || '';
    if (name === 'searchText') {
      setSearchText(val);
    } else {
      if (val == '') {
        setSearchText('')
      }
      const searchByDetail = searchByOptions && searchByOptions.filter(x => x.value === val)[0] || {};
      setSearchBy(searchByDetail);
      setQueryFields({})
    }
  }

  const handleInputChange = (e, name) => {
    if (e && e.target) {
      const val = e.target.value && e.target.value;
      setQueryFields(d => {
        return { ...d, [name]: val }
      })
    }
  }

  const getQueryFields = () => {
    const obj = {};
    // if (isEmpty(searchBy)) {
    //   obj['searchText'] = searchText;
    //   obj['query'] = { ...queryFields }
    //   return obj;
    // } else {
    const query = {};
    const { searchFields = [], value } = searchBy;
    obj['searchBy'] = value;
    if (searchBy && searchFields instanceof Array) {
      searchFields.forEach((x) => {
        if (queryFields && queryFields[x.name]) {
          const val = queryFields[x.name] && queryFields[x.name].trim();
          query[x.name] = val;
        }
      });
    }
    obj['query'] = { ...query };
    return obj;
  }

  const onSearch = () => {
    const searchData = getQueryFields();
    switch (searchData.searchBy) {
      case 'incorporationDate': {
        if (moment(searchData.query.incorporationStartDate) <= moment(searchData.query.incorporationEndDate)) {
          if (doSearch) {
            doSearch({
              ...searchData
            });
          }
        }
        else {
          toast.error("Start Date is must be less or equal to End Date.")
        }
        break;
      }
      case 'eventDate':
      case 'certificateDate':
      case 'createdDate': {
        if (moment(searchData.query.startDate) <= moment(searchData.query.endDate)) {
          if (doSearch) {
            doSearch({
              ...searchData
            });
          }
        }
        else {
          toast.error("Start Date must be less or equal to End Date.")
        }
        break;
      }
      case 'assignDate': {
        if (moment(searchData.query.startReceivedDate) <= moment(searchData.query.endReceivedDate)) {
          if (doSearch) {
            doSearch({
              ...searchData
            });
          }
        }
        else {
          toast.error("Start Date must be less or equal to End Date.")
        }
        break;
      }
      default: {
        if (searchData && searchData.query && !isEmpty(searchData.query)) {
          if (doSearch) {
            doSearch({
              ...searchData
            });
          }
        }
      }
    }
  }

  const getSearchFields = (val) => {
    const { field, name = "", label, options = [], subField } = val;
    if (subField === 'eventDate') {
      return <div className="">
        <InputField
          type={field}
          name={name}
          value={queryFields[name]}
          onChange={handleInputChange}
          containerClass={"form-group"}
          placeholder={label}
          className=""

        />
      </div>
    }

    else if (field === 'date') {
      return <div className="">
        <InputField
          type={field}
          name={name}
          value={queryFields[name]}
          onChange={handleInputChange}
          containerClass={"form-group"}
          placeholder={label}
          className=""
          max={moment().format("YYYY-MM-DD")}
        />
      </div>
    } else if (field === "select") {
      return (
        <div className="">
          <DropDown
            containerStyle="form-group "
            selectTagStyle="form-control dropdown-media"
            onChange={handleInputChange}
            options={options}
            name={name}
            value={queryFields[name]}
          />
        </div>
      )
    } else if (field === 'text') {
      return <div className="">
        <InputField
          type={'text'}
          name={name}
          className="search-input"
          value={queryFields[name]}
          onChange={handleInputChange}
          containerClass={"form-group"}
          placeholder="Search"

        />
      </div>
    }
  }

  return (
    <div className="global-search-filter">
      <div className="clearfix">
        <div className="main-search-wrap">
          <div className="filter-dropdown clearfix">
            <label className="text-center ph-15">search by</label>
            <DropDown
              containerStyle="form-group "
              selectTagStyle="form-control dropdown-media filter-dropdown"
              onChange={onChangeHandler}
              // value={formData.accountEnabled}
              options={searchByOptions}
              name={'searchBy'}
              value={searchBy.value}
              defaultOptionText={'Select search by'}
            />
          </div>
        </div>
        {
          (searchBy && searchBy.searchFields && searchBy.searchFields.length > 0) ?
            (searchBy.searchFields.map((val, index) => {
              return <div className="sub-search-wrap" key={index}>
                {getSearchFields(val)}
              </div>
            })) : null
        }
      </div>
      <div className="row">
        <div className='col-12'>
          <Button
            text={'Search'}
            onClick={onSearch}
            type={'button'}
            className={'bttn'}
          />
        </div>
      </div>
    </div>
  )
}

SearchBox.propTypes = {
  query: PropTypes.object,
  doSearch: PropTypes.func,
};

SearchBox.defaultProps = {
  doSearch: () => { }
};

export default SearchBox;