import { get, isEmpty } from 'lodash';
import { IACServices } from './service';
import {
    IAC_APPLICATION_DETAILS,
    NRDC_List,
    NRDC_Recognition,
    COMMUNICATION_REQUEST,
    EP_ASSIGNED_DETAIL,
    IMBMEMBER_ASSIGNED_DETAIL,
    IMBCHAIR_ASSIGNED_DETAIL,
    FETCH_80IAC_CLARIFICATION,
    IAC_80_List_DETAIL,
    IMB_MEMBER_COMMENT_LIST,
    IAC_80_SECTOR_LIST,
    INCOMPLETE_LIST_IAC,
    ADMIN_SUPERADMIN_DETAIL
} from '../../../constants/actionConstants';
import { isLoading } from '../../../constants/actionConstants';
import { toast } from "react-toastify";


export function get80IACApplicationDetails(params) {
    return async function (dispatch) {
        dispatch(isLoading(true));
        let IACApplicationDetails = await IACServices.get80IACApplication(params);
        dispatch({
            type: IAC_APPLICATION_DETAILS,
            payload: IACApplicationDetails
        });
        dispatch(isLoading(false));
    }
}

export function updateRecommendation(params) {
    return async function (dispatch) {
        dispatch(isLoading(true));
        IACServices.updateRecommendation(params).then(responce => {
            const { message, status } = responce.data;
            if (status) {
                toast.success(message)
            } else {
                toast.error(message)
            }      
            dispatch(isLoading(false))      
        }).catch(e => {
            toast.error(e)
            dispatch(isLoading(false))
        })
        
    }
}
export function updateImbRecommendation(params) {
    return async function (dispatch) {
        dispatch(isLoading(true));
        IACServices.updateImbRecommendation(params).then(responce => {
            const { message, status } = responce.data;
            if (status) {
                toast.success(message)
            } else {
                toast.error(message)
            }      
            dispatch(isLoading(false))      
        }).catch(e => {
            toast.error(e)
            dispatch(isLoading(false))
        })
        
    }
}
export function updateImbChairRecommendation(params) {
    return async function (dispatch) {
        dispatch(isLoading(true));
        IACServices.updateImbChairRecommendation(params).then(responce => {
            const { message, status } = responce.data;
            if (status) {
                toast.success(message)
            } else {
                toast.error(message)
            }            
            dispatch(isLoading(false))
        }).catch(e => {
            toast.error(e)
            dispatch(isLoading(false))
        })
        
    }
}

export function getIncompleteHistory(params){
    
    return async function(dispatch){
        dispatch(isLoading(true));
        let IncompleteList =  await IACServices.fecthIncompleteList(params);
     
        dispatch({
            type: INCOMPLETE_LIST_IAC,
            payload : IncompleteList
        })
        dispatch(isLoading(false));
    }
}
export const getClarificationRequest = (params) => async (dispatch, store) => {
    dispatch(isLoading(true));
    const resposne = await IACServices.getClarificationRequest(params).
        catch(e => {
            return { data: {}, status: false }
        });
    if (resposne) {
        const { status, data } = resposne;
        if (status) {
            dispatch({
                type: FETCH_80IAC_CLARIFICATION,
                payload: data
            });
        }
        dispatch(isLoading(false));
        return { status, data };
    }
}


export const getIAC80List = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    const response = await IACServices.getIAC80List(params).
        catch(e => {
            return { data: {} };
        });
    if (response) {
        const { data } = response;
        dispatch({
            type: IAC_80_List_DETAIL,
            payload: data,
        })
        dispatch(isLoading(false))
        return data;
    }

}
export const updateIacAction = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    await IACServices.updateAction(params).
        then((data) => {
            const { message, status } = data;
            if (status) {
                toast.success(message)
            } else {
                toast.error(message)
            }
            dispatch(isLoading(false));            
        }).catch(e => {
            toast.error(e)
            dispatch(isLoading(false));
        })        
}

export const updateimbStatus = (id) => async (dispatch) => {
    dispatch(isLoading(true));
    await IACServices.updateIMBStatus(id).then((data) => {
        const { message, status } = data;
        if (status) {
            toast.success(message)
        } else {
            toast.error(message)
        }
        dispatch(isLoading(false))
    }).catch(e => {
        toast.error(e)
        dispatch(isLoading(false))
    })
    
}



export function getNRDCListDetails(params) {
    return async function (dispatch) {
        dispatch(isLoading(true));
        let NRDCDetails = await IACServices.getNRDCList(params);
        dispatch({
            type: NRDC_List,
            payload: NRDCDetails
        });
        dispatch(isLoading(false));
    }

}

export function getCommunicationRequestDetails(params) {
    return async function (dispatch) {
        dispatch(isLoading(true));
        let CommunicationRequestDetails = await IACServices.getCommunicationRequest(params);
        dispatch({
            type: COMMUNICATION_REQUEST,
            payload: CommunicationRequestDetails
        });
        dispatch(isLoading(false));
    }

}
export const getAssignedEpUserDetails = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let AssignedEpDetails = await IACServices.getAssignedEpDetailsRequest(params);
    dispatch({
        type: EP_ASSIGNED_DETAIL,
        payload: AssignedEpDetails
    });
    dispatch(isLoading(false));
}

export const getAssignedIMBMemberUserDetails = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let AssignedIMBMemberDetails = await IACServices.getAssignedIMBMemberUserDetails(params);
    dispatch({
        type: IMBMEMBER_ASSIGNED_DETAIL,
        payload: AssignedIMBMemberDetails
    });
    dispatch(isLoading(false));
}

export const getAssignedImbChairDetails = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let AssignedIMBChairDetails = await IACServices.getAssignedImbChairDetails(params);
    dispatch({
        type: IMBCHAIR_ASSIGNED_DETAIL,
        payload: AssignedIMBChairDetails
    });
    dispatch(isLoading(false));
}
export const getAssignedAdminSuperadminDetails = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let AssignedAdminSuperAdminDetails = await IACServices.getAssignedAdminSuperadminDetails(params);
    dispatch({
        type: ADMIN_SUPERADMIN_DETAIL,
        payload: AssignedAdminSuperAdminDetails
    });
    dispatch(isLoading(false));
}

export const doDownloadIAC80List = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    return await IACServices.doDownloadIAC80List(params).then((response) => {
        const { data = "" } = response;
        dispatch(isLoading(false));
        return data;
    }).catch(e => {
        dispatch(isLoading(false))
        return "";
    })
}
export const doDownloadBeneficiaryList = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await IACServices.doDownloadBeneficiaryList(params).then((response) => {
        const { data = "" } = response;
        dispatch(isLoading(false));
        return data;
    }).catch(e => {
        dispatch(isLoading(false))
        return "";
    })
}

export const submitClarificationObservation = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let response = await IACServices.postClarificationObservation(params).catch(e => {
        return { status: false, message: "" };
    });
    if (response) {
        dispatch(isLoading(false));
        return response;
    }
}

export const getIMBMemberCommentList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let response = await IACServices.getIMBMemberCommentList(params).catch(e => {
        return { status: false, data: [] }
    });

    if (response) {
        const { data, status } = response;
        if (status) {
            dispatch({
                type: IMB_MEMBER_COMMENT_LIST,
                payload: data
            })
        }
        dispatch(isLoading(false));
        return { status }
    }
}

export const saveImbMemberComment = (params) => async (dispatch) => {
    const { clarificationId } = params;
    dispatch(isLoading(true));
    let response = await IACServices.saveImbMemberComment(params).catch(e => {
        return { status: false, message: "" }
    });
    if (response) {
        const { status, message } = response;
        if (status) {
            toast.success(message);
            if (clarificationId) {
                dispatch(getIMBMemberCommentList({ id: clarificationId }));
            }
        } else {
            toast.error(message);
            dispatch(isLoading(false))
        }
        return { status }
    }
}

export const doBulkAction = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let response = await IACServices.doBulkAction(params).catch(e => {
        return { status: false, message: "System Error" }
    })
    if (response) {
        const { status, message } = response;
        if (message) {
            if (status) {
                toast.success(message);
            } else {
                toast.error(message)
            }
        }
        dispatch(isLoading(false))
        return { status }
    }
}

export function getNRDCRecognitionDetails(params) {
    return async function (dispatch) {
        dispatch(isLoading(true));
        const response = await IACServices.getNRDCRecognition(params).catch(e => {
            return { data: {}, status: false };
        });
        if (response) {
            const { data, status } = response;
            dispatch(isLoading(false));
            return { data, status };
        }
    }

}


export const updateNRDCComment = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    return await IACServices.updateNRDCCommentAction(params).
        then((data) => {
            const { message, status } = data;
            if (status) {
                toast.success(message)
            } else {
                toast.error(message)
            }
            dispatch(isLoading(false));
            return { status }
        }).catch(e => {
            toast.error(e);
            dispatch(isLoading(false));
            return { status: false }
        })
}

export const getSectorOptions = (params) => async (dispatch) => {
    const response = await IACServices.getSector(params).
        catch(e => {
            return { data: [], status: false };
        });
    if (response) {
       
        const { data = [] } = response;
        return { data };
    }
}
export const getEpUsers = (params) => async (dispatch) => {
    const response = await IACServices.getEPUsers(params).
        catch(e => {
            return { data: [], status: false };
        });
    if (response) {
       
        const { data = [] } = response;
        return { data };
    }
}