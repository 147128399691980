import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { getStartupApplicationList } from '../../../dependencies/services/startupApplicationService';
import { getStarutupselection } from '../../../dependencies/services/startupSelection';
import { connect } from 'react-redux';
import cancellationColumns from '../dependencies/columns/cancellationColumns';
import Table from '../../common/Table';
import './scss/index.scss';
import ActionModal from '../dependencies/modal/index';
import SectionTitle from '../../common/sectionTitle/sectionTitle';
import { getNatureOfList } from '../../startupApplication/dependencies/action';
import { SearchByStartupApplicationOptions } from '../dependencies/constant'
import SearchBox from '../../common/Search';
import Refresh from '../../common/Refresh';
import { getSectorOptions } from '../../applicationFor80Iac/dependencies/action';

const CancellationList = (props) => {

  const { getStartupApplicationList, startupApplicationDataState: { data, recordsTotal } } = props;
  const [sortField, setSortField] = useState('applicationDate');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [sizePerPage, setSizePerPage] = useState(50);
  const [edited, setEdited] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [query, setQuery] = useState({
    status: "APPROVE", archived: false
  });
  const [sectorOptions, setSectorOptions] = useState([]);
  const [natureOption, SetNatureOption] = useState([]);
  const [checkedValue, setCheckedValue] = useState([]);
  const [modalValue, setModalValue] = useState(false);
  const [buttonValue, setButtonValue] = useState('');
  const [actionType, setActionType] = useState('');
  const [exemptionId, setExemptionId] = useState("");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    getTableData();

  }, [sortField, sortOrder, sizePerPage, pageNo, query, searchText])

  const getTableData = () => {
    getStartupApplicationList({
      sortColumn: sortField,
      sortOrder: sortOrder.toUpperCase(),
      pageSize: sizePerPage,
      pageIndex: pageNo,
      query
    });
  }

  const onSelectRows = (selectedRows) => {
    setCheckedValue(selectedRows)

  }

  const onSortChange = async (params) => {
    const { order, dataField } = params;
   
    let sortName = '';
    if (dataField === 'mobileNumber') {
      sortName = 'representative.mobile';
    } else if (dataField === 'refrenceNumber') {

      sortName = 'refrenceNumber';
    } else if (dataField === 'certificateNumber') {
      sortName = 'startupEntity.recognitionNumber';
    } else if (dataField === 'incorporationNumber') {
      sortName = 'incorporationNumber';
    } else if (dataField === 'nameOfEntity') {
      sortName = 'startupEntity.name';
    } else if (dataField === 'applicationDate') {
      sortName = 'applicationDate';
    } else if (dataField === 'registrationDate') {
      sortName = 'startupEntity.corCreatedDate';
    } else if (dataField === 'incorporationDate') {
      sortName = 'startupEntity.incorporationDate';
    } else {
      sortName = 'createdDate';
    }
    if (dataField !== '') {
      setSortField(sortName);
      setSortOrder(order.toUpperCase());


    }

  }
  const handleOnPageChange = (params) => {
    const { page } = params;
    setPageNo(page - 1);
  }
  const handleOnSizePerPageChange = (params) => {
    const { sizePerPage } = params;
    setSizePerPage(sizePerPage)
    setPageNo(0);
  }

  useEffect(() => {
    props.getNatureOfList();
    props.getStarutupselection();
  }, [])

  const doSearch = (params) => {
    const { searchText = "", searchBy = "" } = params;
    var query = { ...{ status: "APPROVE", archived: false, edited: edited }, ...params.query }

    if (searchBy && searchBy === 'certificateDate') {
      setQuery(() => {
        return { ...query, 'searchType': 'CERTIFICATE_DATE' }
      });
    } else if (searchBy && searchBy === 'createdDate') {
      setQuery(() => {
        return { ...query, 'searchType': 'CREATED_DATE' }
      });
    } else {
      setQuery(query);
    }
    setSearchText(searchText);
    setPageNo(0)
  }



  const toggleActionModal = () => {
    setModalValue(!modalValue);
  }

  const actionHandler = (actionType) => {
    setActionType(actionType)
  }

  const clickOnAction = (id) => {

    if (id) {
      setExemptionId(id);
      toggleActionModal();
      setButtonValue('approvedReject')
    }
  }

  useEffect(() => {
    if (props.getSectorOptions) {
      props.getSectorOptions().then((response) => {

        const { data = [] } = response;
        let options = [];
        if (data && data instanceof Array) {
          options = data.reduce((arr, option) => {
            if (option['industryName'] !== null) {
              var text = `${option['sectionName']} (${option['industryName']})` || "";
            } else {
              var text = `${option['sectionName']}` || "";
            }
            const value = option['id'] || "";
            arr.push({ text, value });
            return arr;
          }, []);
        }
        setSectorOptions(options);
      });
    }

    props.getNatureOfList().then((response) => {
      const { data, status } = response;
      if (status) {

        let options = [];
        if (data && data instanceof Array) {
          options = data.reduce((arr, option) => {
            const text = option['name'] || "";
            const value = option['id'] || "";
            arr.push({ text, value });
            return arr;
          }, []);
        }
        SetNatureOption(options);
      }
    });

  }, []);

  const callApi = () => {
    getTableData();
  }
  function refreshStatupList() {
    setSortField('applicationDate');
    setSortOrder('DESC');

    getStartupApplicationList({
      sortColumn: 'applicationDate',
      sortOrder: 'DESC',
      pageSize: sizePerPage,
      pageIndex: pageNo,
      query
    });
  }
  return (


    <div>
      <div className="cancellation-outer">
        <SectionTitle>Cancellation</SectionTitle>
        {modalValue ?
          <ActionModal
            modalValue={modalValue}
            toggleActionModal={toggleActionModal}
            actionValue={buttonValue}
            callApi={callApi}
            cancellationList={data}
            id={exemptionId
              ?
              exemptionId : ''}
          /> : null
        }
      </div>
      <div>
        <SearchBox
          searchByOptions={SearchByStartupApplicationOptions(sectorOptions, natureOption)}
          doSearch={doSearch}
        />
      </div >
      <div>
      </div>
      <Refresh
        onClickRefresh={refreshStatupList}
        className=""
      />

      <Table
        dataTable={data}
        columns={cancellationColumns({
          clickOnAction,
          pageNo: pageNo,
          size: sizePerPage
        })}
        keyField='applicationId'
        onSortChange={onSortChange}
        totalSize={recordsTotal}
        sizePerPage={sizePerPage}
        onPageChange={handleOnPageChange}
        onSizePerPageChange={handleOnSizePerPageChange}
      />
    </div >
  )
}

const mapStateToProps = (state) => {

  return {
    natureOfTheEntity: state.startupApplicationForm.natureOfTheEntity,

    startupApplicationDataState: state.startupApplicationList.startupData,
    startupSectionList: state.startupSectionList.startupSelectionData
  }
}

export default connect(mapStateToProps, { getStartupApplicationList, getStarutupselection, getNatureOfList, getSectorOptions })(withRouter(CancellationList));