import React, { Component, useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchOtherDocumentsList, deleteOtherDocumentAction } from './dependencies/action';
import columns from './dependencies/columns/columns';
import Table from '../common/Table';
import { ROLES } from '../../constants/Roles';
import { storage } from '../../dependencies/store/storage';


const OtherDocuments = (props) => {
    const userRole = storage.getUserRole();
    const [pageSize, setpageSize] = useState(1000);
    const [pageIndex, setPageIndex] = useState(0);


    const { fetchOtherDocumentsList, deleteOtherDocumentAction } = props;
    const list = props.otherList && props.otherList.data && props.otherList.data.content;
    const totalElements = props.otherList && props.otherList.data && props.otherList.data.totalElements;


    const getList = () => {
        if (fetchOtherDocumentsList) {
            fetchOtherDocumentsList(pageIndex, pageSize).then((data) => {
                return false;
            });

        }
    }
    useEffect(() => {
        getList();
    }, [pageSize, pageSize])

    const handleOnPageChange = (params) => {
        const { page } = params;
        setPageIndex(page - 1);
        const pagevalue = (page - 1);
        if (fetchOtherDocumentsList) {
            fetchOtherDocumentsList(pagevalue, pageSize).then((data) => {
                return false;
            });
        }
    }

    const clickOnAction = async (id) => {
        var r = window.confirm("Do you want to Delete this row?");
        if (r == true && deleteOtherDocumentAction) {
            deleteOtherDocumentAction(id).then((data) => {
                const { status } = data;
                if (status) {
                    getList();
                }
            })
        } else {
        }
    }
    const validRoles = userRole === ROLES.SUPER_ADMIN || userRole === ROLES.ADMIN
    return (
        <div>

            <div className="report-table">
                {
                    validRoles &&
                    <h3>Other Documents <Link to={'/other/form'} className="bttn blue normal add-btn" style={{ float: 'right', minWidth: 'auto', marginRight: '0' }}>Add</Link></h3>
                }
                <Table
                    dataTable={list}
                    columns={columns({
                        clickOnAction,
                        pageNo: pageIndex,
                        size: pageSize
                    })}
                    keyField='id'
                    totalSize={totalElements}
                    sizePerPage={pageSize}
                    onPageChange={handleOnPageChange}
                />

            </div>
        </div>)
}


const mapStateToProps = (state) => {
    return {
        otherList: state.OtherDocumentsReducer.otherDocumentsList,
    }

}

export default connect(mapStateToProps, { fetchOtherDocumentsList, deleteOtherDocumentAction })(withRouter(OtherDocuments));