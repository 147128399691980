import React, { useState, useEffect } from 'react';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import Table from '../../common/Table';
import columns from '../dependencies/columns/section56Columns';
import SearchBox from '../../common/Search';
import { getSection56HistoryList} from '../dependencies/action';
import { doDownloadSection56, Section56DefaultField} from '../dependencies/service';
import { SearchBySection50Options } from '../common/constant';
import Download from '../../common/Download';
import SectionTitle from '../../common/sectionTitle/sectionTitle';

const Section56List = (props) => {
    const fetchAllRecords = false;
    const { getSection56HistoryList, section56History: { data : list } = {}, doDownloadSection56 } = props;
    const [sortColumn, setSortColumn] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');
    const [pageSize, setpageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [query, setQuery] = useState({});

    useEffect(() => {
     
            getTableData()
     
    }, [query])

    const getTableData = () => {
        if (getSection56HistoryList) {
            getSection56HistoryList({
                query,
            }).then((data) => { });
        }
    }


    const doSearch = (params) => {
        const { query = {}, searchText = "", searchBy = "" } = params;
        setQuery(query);
        setSearchText(searchText);
        setPageIndex(0);
        
    }


    const downloadCSV = () => {
        if (doDownloadSection56) {
            return doDownloadSection56({
                query,
            }).then((data) => {
                return data;
            })
        }
    }

    return (
        <div>
            <SectionTitle>Section 56 Exemption Declaration</SectionTitle>
            <SearchBox
                searchByOptions={SearchBySection50Options}
                // defaultFields={Section56DefaultField}
                doSearch={doSearch}
            />
            <Download
                fileName={'Section_56_list'}
                onClickDownload={downloadCSV}
                className="bttn-link"

            />
             <Table
                dataTable={list}
                columns={columns()}
                keyField='id'
                sizePerPage={1000}
                cssClass = 'startupLogs'

            />
            
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        section56History: state.RecognitionHistoryReducer.section56History,
    }
}

export default connect(mapStateToProps, { getSection56HistoryList, doDownloadSection56 })(withRouter(Section56List));