import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ROLES } from '../../../constants/Roles';

const columns = ({userRole,page}) => {
    return [
        // {
        //     dataField: 'username',
        //     text: 'User ID'
        // },  
        {
            dataField: 'name',
            text: 'Name',
            // sort: true,
        }, 
        
        // {
        //     dataField: 'phoneNumber',
        //     text: 'Phone'
        // },
        // {
        //     dataField: 'designation',
        //     text: 'Designation'
        // }, 
        // {
        //     dataField: 'emailId',
        //     text: 'Email'
        // }, 
        {
            dataField: 'userStatus',
            text: 'User Status',
            // formatter: (cell, row) => {
            //     return cell ? 'Active' : 'Inactive'
            // },
        }, 
        {
            dataField: 'role',
            text: 'User Role',
            // sort:true
            // formatter: (cell, row) => {
            //     return cell ? 'EP' : 'EP'
            // },
        }, 
        {
            dataField: 'pendingCount',
            text: 'Pending Application',
            // formatter: (cell, row) => {
            //     return 10
            // },
        }, 
        {
            dataField: 'completeCount',
            text: 'Completed Application',
            // formatter: (cell, row) => {
            //     return 10
            // },
        },
        {
            dataField: 'totalAssignedCount',
            text: 'Total Assigned Application',
            // formatter: (cell, row) => {
            //     return 10
            // },
        },
        // {
        //     dataField: 'totalAllocatedCount',
        //     text: 'Total Allocated Application',
        // },
        {
            dataField: 'totalAllocatedCount',
            text: 'Total Allocated Application',
        },
        {
            dataField: 'lastPendingApplicationDate',
            text: 'Last Pending Application Date',
            formatter :(cell,row) =>{
                if(cell) {
                    return (moment(cell).format('YYYY-MM-DD'))
                }else{
                    return ''
                }
                
            },
            // sort:true
        },
        {
            dataField: '',
            text: 'Action',
            hidden: (ROLES.SUPER_ADMIN == userRole)?false:true,
            formatter: (cell, row) => {
                const userId = row.id
                return (
                    <>
                        {page==='80IAC'?(<Link to={`/user-management/edituser/${userId}`}> Edit </Link>):''}
                        {page==='STARTUPUSERS'?(<Link to={`/user-management/startupEdituser/${userId}`}> Edit </Link>):''}
                        {page==='FORM56'?(<Link to={`/user-management/form56Edituser/${userId}`}> Edit </Link>):''}
                    </>
                )
            }
        }
    ];
};


export default columns;