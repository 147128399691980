import React from 'react';
import { SUPPORTING_DOCUMENTS } from '../../../../constants';
import moment from 'moment'
import { getDocumentLink } from '../../../../dependencies/utils/helper';
import ReactHtmlParser from 'react-html-parser';

export default (response) => {

  if (response.recognition) {
    if (response.recognition.address) {
      var userAddress = `${response.recognition.address.address1},${response.recognition.address.address2},${response.recognition.address.address3},${response.recognition.address.city},${response.recognition.address.subDistrictName ? response.recognition.address.subDistrictName : ''},${response.recognition.address.districtName},${response.recognition.address.stateName}`
    }
  }
  let natureOfEntity =
  {
    'Name of the Startup': response.recognition ? response.recognition.startupEntity.name : '',
    'Date of Incorporation/Registration': response.recognition.startupEntity ? response.recognition.startupEntity.incorporationDate : '',
    'Registration / Incorporation No.': response.recognition ? response.recognition.incorporationNumber : '',
    'Address and Business Location': userAddress,
    'Nature of Entity': response.recognition.startupEntity.startupNatureOfEntity ? response.recognition.startupEntity.startupNatureOfEntity.name : '',
    'Industry': response.recognition.startupEntity.startupIndustry ? response.recognition.startupEntity.startupIndustry.industryName : '',
    'Sector': response.recognition.startupEntity.startupSection ? response.recognition.startupEntity.startupSection.sectionName : '',
    'Categories': response.recognition.startupEntity ? Array.prototype.map.call(response.recognition.startupEntity.startupServices, s => s.serviceName).join(", ").toString() : '',
  }


  if (response.recognition && response.recognition.selfCertificate && response.recognition.selfCertificate.additionalDocument) {
    var linkDiv = response.recognition.selfCertificate.additionalDocument.map((element, index) => {
      let link = "";
      if (element && element.hasOwnProperty('file') && element.file) {
        link = `${getDocumentLink(element.file.publicUrl)}`
      } else if (element && element.hasOwnProperty('url') && element.name === 'WEBSITE_LINK') {
        link = `${element.url.indexOf('http') == -1 ? 'http://' : ''}${element.url}`
      } else if (element && element.hasOwnProperty('url') && element.url) {
        link = element.url
      }
      return <div key={index}>
        {link ?
          <a href={link} target="_blank"> {SUPPORTING_DOCUMENTS[element.name] || ""} </a> : null} </div>
    })
  }


  let incomppleteReason = {}
  if (response && response.incompleteAction && response.incompleteAction.incompleteReasons && response.incompleteAction.incompleteReasons.length > 0) {
    var incompleteDiv = response.incompleteAction.incompleteReasons.map((element, index) => {
      // if (element.reason == 'Others- If any other, please specify.') {
      //   return <div>  {index + 1}. {response.incompleteAction.incompleteDescription}</div>

      // } else {
      //   return <div>  {index + 1}. {element.reason}</div>

      // }
    })
    incomppleteReason = { 'Incomplete Reason': incompleteDiv, 'Incomplete Date': response.incompleteAction.incompleteDate ? moment(response.incompleteAction.incompleteDate).format('DD-MM-YYYY') : '' }

  }

  let rejectReason = {}

  if (response && response.rejectAction) {
    rejectReason = { 'Reject Reason': response.rejectAction.rejectDescription ? response.rejectAction.rejectDescription : '', 'Reject Date': response.rejectAction.rejectDate ? moment(response.rejectAction.rejectDate).format('DD-MM-YYYY') : '' }
  }



  let contactDetailsStartup =
  {
    'Phone No': response.recognition.representative ? response.recognition.representative.mobile : '',
    'Email Address': response.recognition.representative ? response.recognition.representative.email : '',
    'Additional document to support your application': linkDiv,
    'Permanent Account Number': response.recognition ? response.recognition.startupEntity.panNumber : '',
    'Startup Recognition number allotted by DIPP': response.recognition.startupEntity.recognitionNumber ? response.recognition.startupEntity.recognitionNumber : '',
  }

  let existingProposesActivity =
  {
    'Memorandum of association': response.associationMemorandum ? <a href={`${getDocumentLink(response.associationMemorandum.publicUrl)}`} target="_blank">View Document </a> : '',
    'Board Resolution': response.boardResolution && response.boardResolution ? <a href={`${getDocumentLink(response.boardResolution.publicUrl)}`} target="_blank">View Document </a> : '',
  }
  var finalCategory = {};
  var finalIprs = {};
  if (response.recognition.additionalInformation && response.recognition.additionalInformation.workCategories) {
    var finalCategory = response.recognition.additionalInformation.workCategories.reduce(
      (obj, item) => Object.assign(obj, {
        [item.workNature]: item.innovative ? 'Innovative'
          : (item.improvement ? 'Improvement' : '')
      }), {});
  }

  if (response.recognition.additionalInformation && response.recognition.additionalInformation.iprs) {
    var finalIprs = response.recognition.additionalInformation.iprs.reduce(
      (obj, item) => {
        let data = {};
        if (item && item.hasOwnProperty('iprTitle')) {
          data[item.iprTitle] = item.applied ? item.applicationNumber ? `Applied (Application No:- ${item.applicationNumber})` : 'Applied' : (item.registered ? item.applicationNumber ? `Registered (Application No:- ${item.applicationNumber})` : 'Registered' : '');
        }
        return { ...obj, ...data }
      }, {})
  }


  let informationAboutStartup =
  {
    'Current number of employees (including founders)': response.recognition.additionalInformation ? response.recognition.additionalInformation.numberOfEmployees : '',
    'What is the current stage of development of your product / service?': response.recognition.additionalInformation ? response.recognition.additionalInformation.stage : '',
    ...finalIprs,
    'Has your startup applied for any IPR (Intellectual Property Right)?': response.recognition.additionalInformation.ipr ? 'Yes' : 'No',
    'Has your startup received any funding?': response.recognition.additionalInformation.receiveFunding ? 'Yes' : 'No',
    'Please submit a brief note supporting the options chosen above for innovation, improvement and scalability - 1000 words maximum	': (response.recognition.additionalInformation !== undefined && response.recognition.additionalInformation.businessModel) ? ReactHtmlParser(response.recognition.additionalInformation.businessModel.note) : '',

  }

  let startupActivites =
  {
    'Please mention any awards/recognition received by the entity': response.recognition.activities ? ReactHtmlParser(response.recognition.activities.awardsReceived) : '',
    'Awards/Recognition Document': response.recognition.activities && response.recognition.activities.awardDocument ? <a href={`${getDocumentLink(response.recognition.activities.awardDocument.publicUrl)}`} target="_blank">View Document </a> : '',
    'What is the problem the startup is solving?': response.recognition.activities ? ReactHtmlParser(response.recognition.activities.problemStatement) : '',
    'How does your startup propose to solve this problem?': response.recognition.activities ? ReactHtmlParser(response.recognition.activities.problemSolution) : '',
    'What is the uniqueness of your solution?': response.recognition.activities ? ReactHtmlParser(response.recognition.activities.solutionUniqueness) : '',
    'How does your startup generate revenue?': response.recognition.activities ? ReactHtmlParser(response.recognition.activities.generateRevenue) : '',
  }
  // let incompleteAction = {
  //   'IncompleteDate': response.incompleteAction ? moment(response.incompleteAction.incompleteDate).format('DD-MM-YYYY') : '',
  //   'IncompleteReasons': response.incompleteAction && response.incompleteAction.incompleteReasons && response.incompleteAction.incompleteReasons[0] !== null ? ReactHtmlParser(response.incompleteAction.incompleteReasons.map( (d) => '<li>'+d.reason+'</li>')) :''
  // }
  let incompleteAction = {}
  if(response.incompleteAction && response.incompleteAction.incompleteDescription) {
    incompleteAction = {'Incomplete Date': moment(response.incompleteAction.incompleteDate).format('DD-MM-YYYY'), 'Incomplete Reasons': response.incompleteAction.incompleteDescription}
  }

  if(response.incompleteAction && !response.incompleteAction.incompleteDescription &&  response.incompleteAction.incompleteReasons && response.incompleteAction.incompleteReasons[0] !== null) {
    incompleteAction = {'Incomplete Date': moment(response.incompleteAction.incompleteDate).format('DD-MM-YYYY'), 'Incomplete Reasons': ReactHtmlParser(response.incompleteAction.incompleteReasons.map( (d) => '<li>'+d.reason+'</li>'))}
  }

  if (
    response.incompleteAction &&
    response.incompleteAction.incompleteDescription &&
    response.incompleteAction.incompleteReasons
  ) {
    incompleteAction = {
      'Incomplete Date': moment(response.incompleteAction.incompleteDate).format('DD-MM-YYYY'),
      'Incomplete Reasons': ReactHtmlParser(
        response.incompleteAction.incompleteReasons.map((d) =>
          d.reason === 'Others- If any other, please specify.'
            ? `<li>${response.incompleteAction.incompleteDescription}</li>`
            : `<li>${d.reason}</li>`
        ).join('')
      )
    };
  }

  let declaration = {

    'No of Chances given to startup?': response.incompleteCount ? response.incompleteCount : '0',
    'Name of the authorised signatory	': response.authorisedSignatory ? response.authorisedSignatory : '',
    'Designation': response.designation ? response.designation : '',
    'Place': response.place ? response.place : '',
    'Date': response.applicationDate ? moment(response.applicationDate).format('DD-MM-YYYY') : '',
    // ...incomppleteReason,
    ...rejectReason
  }
  let detailsOfCert =
  {

    'Details of certificate granted under Section 56 of Income-tax Act, 1961': response.certificateUnderSection56 ? response.certificateUnderSection56 : '',

  }

  let supportingDocuments = {
    'Startup Video': response.startupVideoLink && response.startupVideoLink.url ? <a href={`${response.startupVideoLink.url}`} target="_blank">Video </a> : '',
    'Pitch Deck 80 IAC': response.pitchDeck && response.pitchDeck.file ? <a href={`${getDocumentLink(response.pitchDeck.file.publicUrl)}`} target="_blank">Pitch Deck </a> : '',
    // 'Clarification': response && response.clarification ? <a href={`${getDocumentLink(response.clarification.publicUrl)}`} target="_blank">Clarification </a> : '',
  }
  return {
    natureOfEntity: natureOfEntity || [],
    contactDetailsStartup: contactDetailsStartup,
    existingProposesActivity: existingProposesActivity,
    informationAboutStartup: informationAboutStartup,
    startupActivites: startupActivites,
    declaration: declaration,
    beneficiaryReason: response.beneficiaryReason? response.beneficiaryReason:'',
    incompleteAction: response.incompleteAction && response.incompleteAction.incompleteReasons && response.incompleteAction.incompleteReasons[0] !== null ?incompleteAction:null,
    annualAccountStatement: response.annualAccountStatement ? response.annualAccountStatement : [],
    itrStatement: response.itrStatement ? response.itrStatement : [],
    clarifications: response.clarifications ? response.clarifications : [],
    detailsOfCert: detailsOfCert,
    isBeneficiary : response.isBeneficiary,
    status: response.recognition.startupEntity.eightyIACFormStatus ? response.recognition.startupEntity.eightyIACFormStatus : '',
    nrdcListInfo: response.nrdcListInfo ? response.nrdcListInfo : [],
    supportingDocuments : supportingDocuments,
    recommendStatusEP : response.recommendStatusEP ? response.recommendStatusEP : [],
    recommendStatusIMBMember : response.recommendStatusIMBMember ? response.recommendStatusIMBMember : [],
    recommendStatusIMBChair : response.recommendStatusIMBChair ? response.recommendStatusIMBChair : {}
  };
};