import React from "react";
import PropTypes from 'prop-types';
import logo from '../../../assests/images/logo.png';
import './header.scss';
import { Link, withRouter } from 'react-router-dom';
import img from '../../../assests/images/linkicon-2.png';
import { logout } from '../../Auth/common/dependencies/action';
import { connect } from "react-redux";
import moment from 'moment';
import editIcon from './images/edit-profile-icon.png';
import viewIcon from './images/view-profile-icon.png';
import logoutIcon from './images/logout-icon.png';
import changePasswIcon from './images/change-pass-icon.png';

const Header = (props) => {  
  const { user: { lastLoginTimeStamp: lastLogin, name = "" } = {},setCssClass } = props;
  const setCssClassfn = (e) => {
    if(props.classData) {
      setCssClass('')
    }else(
      setCssClass('active')
    )
  }
  return (
    <header className="header clearfix">
      <div className="logo-wrap">
        <Link to="#">
          <img src={logo} alt="Startup India" />
        </Link>
        <div className={"hamburger-lines "+props.classData} onClick={() => setCssClassfn()}>
                <span className="line line1"></span>
                <span className="line line2"></span>
                <span className="line line3"></span>
            </div>
      </div>
      <div className="right-header-wrap clearfix">
        <div className="right-header-content">
          <h2>Administration Portal</h2>
          <span>Last Login: {moment(lastLogin).format('D MMMM YYYY, h:mm:ss a')}</span>
        </div>
        <div className="left-header-content">
          <div className="inner-profile-wrap clearfix">
            <div className="profile-img-wrap">
              <img src={img} alt="" />
            </div>
            <div className="profile-name">
              <h4>{name}</h4>
            </div>
            <div className='dropdown-wrapper'>
              <div className="dropdown-inner ph-20">
                <ul>
                  <li><Link to="/user-management/viewprofile"><i><img width="18" src={viewIcon} /></i>View Profile</Link></li>
                  <li><Link to="/edit-profile"><i><img width="16" src={editIcon} /></i>Edit Profile</Link></li>
                  <li><Link to="/changePassword"><i><img width="18" src={changePasswIcon} /></i>Change Password</Link></li>
                  <li><Link to="/logout"><i><img width="18" src={logoutIcon} /></i>Signout</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  lastLogin: PropTypes.number,
}
Header.defaultProps = {
  lastLogin: new Date().getTime(),

}

const mapStateToProps = (state) => {
  return {
    user: state.loginReducer.user,
  }
}
export default connect(mapStateToProps, { logout })(withRouter(Header));