import React, { useEffect, useState } from 'react';
import './scss/index.scss';
import SectionTitle from '../../../components/common/sectionTitle/sectionTitle';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { getClarificationRequest, submitClarificationObservation } from '../dependencies/action';
import moment from 'moment';
import TextareaField from '../../common/TextareaField';
import Button from '../../common/Button';
import Modal from '../../common/Modal';
import { toast } from 'react-toastify';
import { ROLES } from '../../../constants/Roles';
import { storage } from '../../../dependencies/store/storage';
import { getDocumentLink,unEscapeRegHtml } from '../../../dependencies/utils/helper';

const ObservationCommunicated80Iac = (props) => {
    const userRole = storage.getUserRole();
    const { getClarificationRequest,
        data: { observation,
            startupResponse,
            requestDate,
            responseDate,
            uploadedFiles,
            requestDescription } = {},
        exemptionId,
        submitClarificationObservation,
        onClose,
    } = props;

    const [description, setDescription] = useState("");
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (exemptionId) {
            getClarificationRequest({ id: exemptionId }).then((response) => {
                const { status, data } = response;
                if (status) {
                    if (data.requestDescription) {
                        let html = ''
                            + (data.requestDescription.briefAboutEntity ? 'Brief about the entity \n' + data.requestDescription.briefAboutEntity + '\n\n' : '')
                            + (data.requestDescription.assessment ? 'Assessment \n' + data.requestDescription.assessment + '\n\n' : '')
                            + (data.requestDescription.patents ? 'Similar Patents \n' + data.requestDescription.patents + '\n\n' : '')
                            + (data.requestDescription.services ? 'Similar Services \n' + data.requestDescription.services + '\n\n' : '')
                            + (data.requestDescription.decision ? 'Decision \n' + data.requestDescription.decision : '')

                        setDescription(html);

                    }
                }

            });
        }


    }, []);



    if (uploadedFiles) {

        var filesData = uploadedFiles.map((element, index) => {
  
              return <div><a href={`${getDocumentLink(element['publicUrl'])}`} target="_blank"> {element.name} </a></div>
           
          })
    }

    useEffect(() => {
        if (description && description.trim()) {
            setDisabled(false);
        } else {
            setDisabled(true)
        }
    }, [description])


    const handleInputChange = (e, name) => {
        if (e && e.target) {
            const val = e.target.value || "";
            setDescription(val)
        }
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        if (submitClarificationObservation && exemptionId) {
            submitClarificationObservation({
                "exemption": {
                    "id": exemptionId
                },
                "observation": description,
            }).then((data) => {
                const { status = false, message = "" } = data || {};
                if (status) {
                    toast.success(message)
                } else {
                    toast.error(message)
                }
                onClose();
            }).catch(e => {
            });
        }
    }
    const validRoles = userRole === ROLES.SUPER_ADMIN || userRole === ROLES.ADMIN
    return (
        <div>
            <SectionTitle>Startup India : Observation Communicated</SectionTitle>

            <div className="entity-detail-wrap observation">
                <div class="entity-detail-inner p-20 mb-15">
                    <div className="entity-row clearfix ">
                        <div class="entity-title">
                            <h6>{'Observation'}</h6>
                        </div>
                        <div class="entity-content">
                            <p class="clearfix"><span>:&nbsp;&nbsp;</span>
                                <span>{unEscapeRegHtml(observation)}</span>
                            </p>
                        </div>
                    </div>
                    <div className="entity-row clearfix ">
                        <div class="entity-title">
                            <h6>{"Startup's Response"}</h6>
                        </div>
                        <div class="entity-content">
                            <p class="clearfix"><span>:&nbsp;&nbsp;</span>
                                <span>{unEscapeRegHtml(startupResponse)}</span>
                            </p>
                        </div>
                    </div>
                    <div className="entity-row clearfix ">
                        <div class="entity-title">
                            <h6>{'Uploaded Files'}</h6>
                        </div>
                        <div class="entity-content">
                            <p class="clearfix"><span>:&nbsp;&nbsp;</span>
                                <span>{filesData}</span>
                            </p>
                        </div>
                    </div>
                    <div className="entity-row clearfix ">
                        <div class="entity-title">
                            <h6>{'Requested Date'}</h6>
                        </div>
                        <div class="entity-content">
                            <p class="clearfix"><span>:&nbsp;&nbsp;</span>
                                <span>{requestDate && moment(requestDate).format('LLLL')}</span>
                            </p>
                        </div>
                    </div>
                    <div className="entity-row clearfix ">
                        <div class="entity-title">
                            <h6>{'Response Date'}</h6>
                        </div>
                        <div class="entity-content">
                            <p class="clearfix"><span>:&nbsp;&nbsp;</span>
                                <span>{responseDate && moment(responseDate).format('LLLL')}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="entity-detail-wrap">
                <div class="entity-detail-inner p-20 mb-15">
                    <form onSubmit={e => handleOnSubmit(e)}>
                        <TextareaField
                            required
                            rows='10'
                            name="description"
                            value={unEscapeRegHtml(description)}
                            label={'Request Description'}
                            onChange={handleInputChange}
                            disabled={!validRoles}
                        />
                        <Button
                            text="Send Observation"
                            className="bttn blue"
                            type="submit"
                            disabled={disabled}
                        />
                    </form>
                </div>
            </div>
        </div>


    )
}
const mapStateToProps = (state) => {
    return {
        data: state.Applicatio80IACReducer.clarificationObservation,
    }
}
export default connect(mapStateToProps, { getClarificationRequest, submitClarificationObservation })(withRouter(Modal(ObservationCommunicated80Iac)))
