export const AlertType = {
    DANGER: 'danger',
    SUCCESS: 'success'
}

export const SUPPORTING_DOCUMENTS = {
    VIDEOS: 'Videos',
    PITCH_DECK: 'PitchDeck',
    WEBSITE_LINK: 'Websitelink',
    PATENTS: 'Patents',
    OTHERS: 'Others',
}

export const headerSortingStyle = {
    backgroundColor: '#8997af'
}