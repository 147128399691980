const navList = [
  {
    name: "User Management",
    link: '/user-management/eighty-iac-users',
    routeName: 'userManagement',
    className: 'user-management',
    // subNav: [
    //   {
    //     name: "Startup Application",
    //     link: '/user-management/startup-users',
    //     routeName: 'startupUsers',
    //     className: 'startup-application',
    //     toolTip : 'This tab permits access to startup recognition applications submitted by the users'
    //   },
    //   {
    //     name: "Application for 80IAC examption",
    //     link: '/user-management/eighty-iac-users',
    //     routeName: 'eightyIacUsers',
    //     className: 'startup-application',
    //     toolTip : 'This tab permits access to startup recognition applications submitted by the users'
    //   },
    //   {
    //     name: "Form 2: Section 56 examption Decleration",
    //     link: '/user-management/form-fifty-six',
    //     routeName: 'formFiftySix',
    //     className: 'startup-application',
    //     toolTip : 'This tab permits access to startup recognition applications submitted by the users'
    //   }
    // ]
     toolTip : 'This tab has the list of all user profiles created to access the administrative dashboard along with all related details'
  },
  {
    name: "Dashboard",
    link: '/dashboard',
    routeName: 'dashboard',
    className: 'dashboard',    
    toolTip : 'The landing page on login. This tab has relevant statistics and information about startup recognition and user management'
  },
  {
    name: "Application",
    link: '/application',
    routeName: 'application',
    className: 'application',
    subNav: [
      {
        name: "Startup Application",
        link: '/application/startup',
        routeName: 'startupApplicationList',
        className: 'startup-application',
        toolTip : 'This tab permits access to startup recognition applications submitted by the users'
      },
      {
        name: "Saved Application",
        link: '/application/saved',
        routeName: 'savedApplicationList',
        className: 'saved-application',
        toolTip : 'This tab permits access to startup recognition applications started but not yet submitted by the users'
      },
      {
        name: "Archived Application",
        link: '/application/archived',
        routeName: 'archivedApplicationList',
        className: 'archived-application',
        toolTip : 'This tab acts as the record-keeper for all startup recognition applications that no longer fit the criteria of the two tabs provided above'
      },
      {
        name: "Applications for 80IAC Exemption",
        link: '/application/80iac',
        routeName: 'IAC80List',
        className: 'archived-application',
        toolTip : 'This tab permits access to the applications submitted by recognized startups for issuance of the Certificate of Eligibility to claim Income Tax Exemption'
      },
      {
        name: "Form 2: Section 56 Exemption Declaration",
        link: '/application/section56',
        routeName: 'section56List',
        className: 'section-56-declaration',
        toolTip : 'This tab permits access to the applications submitted by recognized startups for issuance of approval to claim Angel Tax Exemption'
      },
    ]
  },
  
  {
    name: "Cancellation",
    link: '/cancellation',
    routeName: 'cancellationList',
    className: 'cancellation',
    toolTip : 'This tab permits the user to cancel any currently active Certificate of Recognition or Certificate of Eligibility after providing a valid reason for the cancellation'
  },
  {
    name: "Expired",
    link: '/expired/list',
    routeName: 'expiredList',
    className: 'Expired',
    toolTip : 'This tab permits the user to view all recognized startups that have crossed the ten years since incorporation criteria and subsequently their Certificate of Recognition were automatically invalidated'
  },

  {
    name: "Download Report",
    link: '/download',
    className: 'reports',
    routeName: 'download',
    subNav: [
      {
        name: "Startup Detail Report",
        link: '/startup-detail-report',
        routeName: 'startupDetailReport',
        className: 'reports',
        toolTip : 'This tab permits the user to download the data provided by recognized startups in a excel file. The user can also use it to customize the amount of detail required in the downloaded report'
      },
      {
        name: "SUI Monthly Report",
        link: '/sui-monthly-report',
        routeName: 'suiMonthlyReport',
        className: 'reports',
        toolTip : 'This tab permits the user to download the data provided by recognized startups in a excel file. The user can also use it to customize the amount of detail required in the downloaded report'
      },
      {
        name: "Incomplete 80IAC Report",
        link: '/incomplete-80iac-report',
        routeName: 'incomplete80iacReport',
        className: 'reports',
      },
      {
        name: "Incomplete Startup Recognition Report",
        link: '/incomplete-recognition-report',
        routeName: 'incompleteRecognitionReport',
        className: 'reports',
      },
      {
        name: "Section 56 Applications Report",
        link: '/section56Report',
        routeName: 'section56Report',
        className: 'reports',
      },
    
      {
        name: "DPIIT Recognized Startup Report",
        link: '/dpiitRecognized',
        routeName: 'dpiitRecognized',
        className: 'reports',
      },
      {
        name: "Startup Application Logs",
        link: '/download/startupApplication',
        routeName: 'startupApplication',
        className: 'startup-application',
        toolTip : 'This tab allows access to the complete history of actions taken by a user on any recognition application'
      },
      {
        name: "Applications for 80IAC Exemption Logs",
        link: '/download/80iac',
        routeName: 'startupApplication80Iac',
        className: 'startup-application-80Iac',
        toolTip :'This tab allows access to the complete history of actions taken by a user on any application for 80IAC exemption'
      },
      {
        name: "Section 56 Logs",
        link: '/download/section56',
        routeName: 'section56list',
        className: 'section56',
        toolTip : 'This tab allows access to the complete history of actions taken by a user on any application for Section 56 exemption'
      },

      // {
      //   name: "SubDistrict",
      //   link: '/update/subdistrict',
      //   routeName: 'subDistrictList',
      //   className: 'total-application'
      // }

    ]
  },
  {
    name: "Beneficiary",
    link: '/beneficiary',
    className: 'beneficiary',
    routeName: 'beneficiary',
    subNav: [
      {
        name: "NSA Result",
        link: '/nsa-result',
        routeName: 'nsaResult',
        className: 'nsaresult',
        toolTip : 'This tab permits the user to download the data provided by recognized startups in a excel file. The user can also use it to customize the amount of detail required in the downloaded report'
      }
    ]
  },
  {
    name: "Startup Logos",
    link: '/startup-logo',
    routeName: 'startupLogosList',
    className: 'startup-logos',
    toolTip : 'Startup Logos – This tab provides information on all requests submitted to utilize the Startup India Logo via the logo request form available on Startup India Hub'
  },
  {
    name: 'Regenerate COR',
    link: '/generate-cor',
    routeName: 'generateCor',
    className: 'regenerate-cor',
    toolTip : 'This tab permits re-generation of existing Certificate of Recognition with updated details, without changing the DIPP Number'
  },
  {
    name: 'Update Incorporation Number',
    link: '/update-incorporation-number',
    routeName: 'updateIncorporationNumber',
    className: 'update-incorporation-number',
    toolTip : 'This tab permits generation of existing Certificate of Recognition with updated Incorporation Number and Type of Entity, without changing the DIPP Number'
  },
  {
    name: "Audit Trail Management",
    link: '/audit-trail',
    routeName: 'auditTrail',
    className: 'audit-trail-mamagement',
    toolTip : 'This tab provides information on all actions taken by a user on the administrative portal'
  },
  {
    name: "Notification Documents",
    link: '/notification/list',
    routeName: 'notificationDocumentsList',
    className: 'documents',
    toolTip : 'This tab provides links to all important government notifications issues that are related to startups'
  },
  {
    name: "Other Documents",
    link: '/other/list',
    routeName: 'otherDocumentsList',
    className: 'documents',
    toolTip : 'This tab provides links to other information documents that were generated by the Startup India team'
  },
  {
    name: "Manage Glossary",
    link: '/glossary',
    routeName: 'glossaryList',
    className: 'manage-glossary',
    toolTip : 'This tab provides a comprehensive list of the terms used in the startup ecosystem, along with their meanings'
  },
  {
    name: "Summary Report",
    link: '/summaryreport',
    className: 'reports',
    routeName: 'summaryReport',
    subNav: [
      {
        name: "Total number of applications submitted ",
        link: '/summaryreport/totalapplicationsreport',
        routeName: 'totalReport',
        className: 'total-application'
      },
      {
        name: "Number of applications for DIPP Recognition Certificate",
        link: '/summaryreport/recognisedreport',
        routeName: 'recognisedReport',
        className: 'recognised-application'
      },
      {
        name: "Number of applications for Tax benefits",
        link: '/summaryreport/taxbenefitreport',
        routeName: 'taxBenefitReport',
        className: 'taxbenefit-application'
      },
      {
        name: "Number of applications based on Entity Type",
        link: '/summaryreport/entityreport',
        routeName: 'entityTypeReport',
        className: 'entity-application'
      }
    ]
  },

  {
    name: "Update",
    link: '/update',
    className: 'reports',
    routeName: 'update',
    subNav: [
      {
        name: "Country ",
        link: '/update/country',
        routeName: 'updateCountryList',
        className: 'total-application'
      },
      {
        name: "State",
        link: '/update/state',
        routeName: 'updateStateList',
        className: 'total-application'
      },
      {
        name: "District",
        link: '/update/district',
        routeName: 'districtList',
        className: 'total-application'
      }, {
        name: "SubDistrict",
        link: '/update/subdistrict',
        routeName: 'subDistrictList',
        className: 'total-application'
      },
      {
        name: 'Industry',
        link: '/update/industry',
        routeName: 'updateIndustryList',
        className: 'total-application'
      },
      {
        name: 'Sections',
        link: '/update/sections',
        routeName: 'sectionList',
        className: 'total-application'
      },
      {
        name: 'Services (Categories)',
        link: '/update/service',
        routeName: 'updateserviceList',
        className: 'total-application'
      }

    ]
  },

];

export const parentLinks = ['/summaryreport', '/application', '/update', '/download']

export default navList

