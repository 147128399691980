import React from 'react';
import './scss/index.scss';
import { Link } from 'react-router-dom';
import linkIcon from '../../../assests/images/linkArrow.png';

const LinkCard = (props) => {
    const { link} = props;
    return (
        <div className='link-card-outer'>
            <Link to={link} className="inner-wrap">
                <div className='img-wrap'>
                    <img width={props.iconWidth} src={props.img} alt={props.title} />
                </div>
                <div className="info-wrap">
                    <h5>{props.title}</h5>
                    <div className="sub-ttl-wrap">
                        <span>{props.subTitle}</span>
                        <img className="link-arrow" width="25" src={linkIcon} alt="" />
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default LinkCard;