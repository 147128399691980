import React, { Component, useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getNRDCListDetails } from '../dependencies/action';
import nrdcCommentUpdateCol from '../dependencies/columns/nrdcCommentUpdateCol';
import Table from '../../common/Table';
import './style.scss'
import { ROLES } from '../../../constants/Roles';
import { storage } from '../../../dependencies/store/storage';



class NRDCCommentUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sAppId: this.props.match.params.id
        }
        
    }
    
    componentDidMount() {
        this.props.getNRDCListDetails(this.state.sAppId);
    }
    
    
    render() {
        const { NRDClistData: { listNRDC: { data: list = [] } = {} } = {} } = this.props;
        const userRole = storage.getUserRole();
        const validRoles = userRole === ROLES.NRDC_ADMIN || userRole === ROLES.TIFAC_ADMIN;

        return (
            <div>
                <h1 className="heading">{"NRDC Comments List"}
                    {
                        validRoles &&
                        <Link to={`/application/80iac/comment/form/${this.state.sAppId}`}>Add New Comment</Link>
                    }
                </h1>
                <Table
                    dataTable={list}
                    columns={nrdcCommentUpdateCol()}
                    keyField='recognitionNumber'
                    sizePerPage={100}
                    cssClass='startupLogs'
                />

            </div>)
    }
}

const mapStateToProps = (state) => {
    return {
        NRDClistData: state.Applicatio80IACReducer.listNRDC,
    }
}

export default connect(mapStateToProps, { getNRDCListDetails })(withRouter(NRDCCommentUpdate));
