import { get, isEmpty } from 'lodash';
import { StartupLogoServices } from './service';
import {
    STARTUP_LOGO_LIST,
    STARTUP_LOGO_APP_COUNT,
    STARTUP_LOGO_VIEW_DETAIL

} from '../../../constants/actionConstants';
import { isLoading } from '../../../constants/actionConstants';
import { toast } from "react-toastify";


export function getStartupLogoAppCount(params) {
    return async function (dispatch) {
        dispatch(isLoading(true));
        let startupLogoAppCount = await StartupLogoServices.getStartupLogoApp(params).catch(e => {
            return { data: [], status: false };
        });
        if (startupLogoAppCount) {
            dispatch({
                type: STARTUP_LOGO_APP_COUNT,
                payload: startupLogoAppCount
            });

            dispatch(isLoading(false));
        }


    }
}


export function getStartupLogoList(params) {
    return async function (dispatch) {
        dispatch(isLoading(true));
        let startupLogoList = await StartupLogoServices.getStartupLogos(params).catch(e => {
            return { data: [], status: false };
        });
        if (startupLogoList) {
            dispatch({
                type: STARTUP_LOGO_LIST,
                payload: startupLogoList
            })
            dispatch(isLoading(false));
        }

    }
}

export const downloadStartupLogoList = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await StartupLogoServices.downloadStartupLogos(params).then((response) => {
        const { data = "" } = response;
        dispatch(isLoading(false));
        return data;
    }).catch(e => {
        dispatch(isLoading(false))
        return "";
    })
}

export function startupLogoViewDetail(params) {

    return async function (dispatch) {
        dispatch(isLoading(true));
        let startupLogoView = await StartupLogoServices.startupLogosView(params).catch(e => {
            return { data: [], status: false };
        });
        if (startupLogoView) {
            dispatch({
                type: STARTUP_LOGO_VIEW_DETAIL,
                payload: startupLogoView
            });
            dispatch(isLoading(false));
        }

    }
}
export const UpdateStartupLogoStatus = (params) => async (dispatch) => {

    await StartupLogoServices.updateStatus(params).
        then((data) => {
            const { message, status } = data;
            if (status) {
                toast.success(message)
            } else {
                toast.error(message)
            }
        }).catch(e => {
            toast.error(e)
        })
}





