import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment'

const columns = ({
    pageNo,
    size
}) => {

    return [
        {
            dataField: '',
            text: 'Sr.No',
            formatter: (cell, row, rowIndex) => {
                return (pageNo * size) + (rowIndex + 1);
            }
        }, {
            dataField: 'nameOfApplicant',
            text: 'Name of Applicant',
        }, {
            dataField: 'nameOfEvent',
            text: 'Name of Event',
        }, {
            dataField: 'eventDate',
            text: 'Event Date',
            formatter: (cell) => {
                if (cell) {
                    return moment(cell).format('DD-MM-YYYY')

                }
            },
        },
        {
            dataField: 'incorporationDate',
            text: 'Action',
            formatter: (cell, row) => {
                const applicationId = row.id
                return (
                    <Link to={`/startup-logo/detail/${applicationId}`} target="_blank">
                        View
                  </Link>
                )
            }
        },
        {
            dataField: 'nameOfOrganisingEntity',
            text: 'Name of Organising Entity',

        },
        {
            dataField: 'natureOfEntity',
            text: 'Nature of Entity',

        },
         {
            dataField: 'natureOfEvent',
            text: 'Nature of Event',

        }, {
            dataField: 'statusLabel',
            text: 'Status',
        },
        ];
}

export default columns;