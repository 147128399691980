import { get, isEmpty } from 'lodash';
import { GlossaryServices } from './service';
import {
    GLOSSARY_LIST,
    NOTIFICATION_GLOSSARY_BY_ID
} from '../../../constants/actionConstants';
import { isLoading } from '../../../constants/actionConstants';
import { toast } from "react-toastify";



export const fetchListAction = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await GlossaryServices.fetchListServices(params).then((response) => {
        const { data } = response;
        if (data) {
            dispatch({
                type: GLOSSARY_LIST,
                payload: data,
            });
        }
        dispatch(isLoading(false));
        return data;
    }).catch(e => {
        dispatch(isLoading(false))
        return { data: {} }
    })
}

export const fetchGlossaryByID = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await GlossaryServices.fetchListByID(params).then((response) => {
        const { data: { data = {}, status = false } } = response;
        dispatch(isLoading(false));
        return { status, data };
    }).catch(e => {
        dispatch(isLoading(false))
        return { data: {}, status: false };
    })
}

export const saveGlossaryAction = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let response = await GlossaryServices.saveGlossaryService(params).catch(e => {
        return { status: false, message: "" }
    });
    if (response) {
        const { status, message } = response;
        if (status) {
            toast.success(message);
            dispatch(isLoading(false))
        } else {
            toast.error(message);
            dispatch(isLoading(false))
        }
        return { status }
    }
}



export const deleteGlossaryAction = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let response = await GlossaryServices.deleteGlossaryService(params).catch(e => {
        return { status: false, message: "" }
    });
    if (response) {
        const { status, message } = response;
        if (status) {
            toast.success(message);
        } else {
            toast.error(message);
        }
        dispatch(isLoading(false))
        return { status }
    }
}
