import { combineReducers } from 'redux';
import {
    NOTIFICATION_DOCUMENTS_LIST,
    NOTIFICATION_DOCUMENTS_LIST_BY_ID
} from '../../../constants/actionConstants';


const notificationDocumentsList = (state = [], action) => {
    switch (action.type) {
        case NOTIFICATION_DOCUMENTS_LIST:
            return { ...action.payload }
        default:
            return state;
    }
}

const notificationDocumentsID = (state = {}, action) => {
    switch (action.type) {
        case NOTIFICATION_DOCUMENTS_LIST_BY_ID:
            return { ...action.payload }
        default:
            return state;
    }
}


const NotificationDocumentsReducer = combineReducers({
    notificationDocumentsList,
    notificationDocumentsID
})

export default NotificationDocumentsReducer;