import api from '../../../dependencies/utils/Api';
import {
    FETCH_CANCEL_CERTIFICATE_URL
} from "../../../constants/ApplicationUrl";



export const CancellationServices = {

    updateCancelCertificateServices(params) {
    
        return api.POST(FETCH_CANCEL_CERTIFICATE_URL, params).
            then((response) => {
                const { data = {} } = response;
                return data;
            });
    },
}