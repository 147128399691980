export const section56Status = [
    {
        text: 'In Progress',
        value: 'PENDING',
    },
    // {
    //     text: 'Reject',
    //     value: 'REJECT',
    // },
    {
        text: 'Incomplete for 56',
        value: 'INCOMPLETE',
    },
     {
        text: 'Archive',
        value: 'ARCHIVE',
    },
    // {
    //     text: 'N/A',
    //     value: 'NA',
    // },
    {
        text: 'Approved',
        value: 'APPROVE',
    },
]
export const SearchBySection50Options = [

    {
        text: "Registration No",
        value: "incorporationNumber",
        searchFields: [{
            field: 'text',
            name: 'incorporationNumber'
        }],
    }, {
        text: "Reference No.",
        value: "referenceNumber",
        searchFields: [{
            field: 'text',
            name: 'referenceNumber'
        }],
    },
    {
        text: "Certificate Number",
        value: "recognitionNumber",
        searchFields: [{
            field: 'text',
            name: 'recognitionNumber'
        }],
    },
    {
        text: "Name of the Entity",
        value: "entityName",
        searchFields: [{
            field: 'text',
            name: 'entityName'
        }]
    },
    {
        text: "Created Date",
        value: "createdDate",
        searchFields: [{
            field: 'date',
            name: 'startDate'
        }, {
            field: 'date',
            name: 'endDate'
        }]
    },
    {
        text:'Status',
        value: 'status',
        searchFields: [{
            field: 'select',
            name: 'status',
            options: section56Status,
        }]
    }
]

