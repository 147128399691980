import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { getStartupApplicationList, doDownloadStartupList } from '../../dependencies/services/startupApplicationService';
import { bulkArchive } from '../../dependencies/services/bulkArchive';
import { connect } from 'react-redux';
import columns from './common/dependencies/startupTableColumns';
import Table from '../common/Table';
import './scss/index.scss';
import Download from '../common/Download';
import SectionTitle from '../common/sectionTitle/sectionTitle';
import SearchBox from '../common/Search';
import { SearchBySavedApplication } from './common/constant';
import Refresh from '../common/Refresh';
import IncompleteHistory from '../startupApplicationList/IncompleteHitory';

const StartupApplication = (props) => {

  const { getStartupApplicationList, startupApplicationDataState: { data, recordsTotal }, doDownloadStartupList } = props;
  const [sortField, setSortField] = useState('applicationDate');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [sizePerPage, setSizePerPage] = useState(50);
  const [pageNo, setPageNo] = useState(0);
  const [query, setQuery] = useState({
    status: "DRAFT", archived: false, draft : true
  });
  const [searchParams, setSearchParams] = useState({});
  const [searchText, setSearchText] = useState("");
  const [showIncompleteHistory, setShowIncompleteHistory] = useState(false);
  const [exemptionId, setExemptionId] = useState("")


  useEffect(() => {
    getTableData();

  }, [sortField, sortOrder, sizePerPage, pageNo, searchParams, query, searchText])

  const getTableData = () => {
    getStartupApplicationList({
      sortColumn: sortField,
      sortOrder: sortOrder.toUpperCase(),
      pageSize: sizePerPage,
      pageIndex: pageNo,
      query
    });
  }

  const onSortChange = async (params) => {
    const { order, dataField } = params;
    var sortName = '';
    if (dataField === 'mobileNumber') {
      sortName = 'representative.mobile';
    } else if (dataField === 'referenceNumber') {
      sortName = 'referenceNumber';
    } else if (dataField === 'certificateNumber') {
      sortName = 'startupEntity.recognitionNumber';
    } else if (dataField === 'incorporationNumber') {
      sortName = 'incorporationNumber';
    } else if (dataField === 'nameOfEntity') {
      sortName = 'startupEntity.name';
    } else if (dataField === 'applicationDate') {
      sortName = 'applicationDate';
    } else if (dataField === 'registrationDate') {
      sortName = 'startupEntity.corCreatedDate';
    } else if (dataField === 'emailId') {
      sortName = 'representative.email';
    } else {
      sortName = 'createdDate';
    }
    if (dataField !== '') {
      setSortField(await (sortName));
      setSortOrder(await (order.toUpperCase()));
    }

  }
  const handleOnPageChange = (params) => {
    const { page } = params;
    setPageNo(page - 1);
  }
  const handleOnSizePerPageChange = (params) => {
    const { sizePerPage } = params;
    setSizePerPage(sizePerPage)
    setPageNo(0);
  }


  function downloadStartupList() {
    if (doDownloadStartupList) {
      return doDownloadStartupList({
        fetchAllRecords: true,
        sortColumn: sortField,
        sortOrder: sortOrder.toUpperCase(),
        pageSize: sizePerPage,
        pageIndex: pageNo,
        query
      }).then((data) => {
        return data;
      })
    }
  }
  function refreshStatupList() {
    getStartupApplicationList({
      sortColumn: 'applicationDate',
      sortOrder: 'DESC',
      pageSize: sizePerPage,
      pageIndex: pageNo,
      query
    });
  }

  const doSearch = (params) => {
    const { searchText = "", searchBy = "" } = params;
    var query = { ...{ status: "DRAFT", archived: false, draft: true }, ...params.query }

    if (searchBy && searchBy === 'certificateDate') {
      setQuery(() => {
        return { ...query, 'searchType': 'CERTIFICATE_DATE' }
      });
    } else if (searchBy && searchBy === 'createdDate') {
      setQuery(() => {
        return { ...query, 'searchType': 'CREATED_DATE' }
      });
    } else {
      setQuery(query);
    }
    setSearchText(searchText);
    setPageNo(0)
  }
  const onCloseIncompeletePopup = () => {
    setExemptionId('')
    setShowIncompleteHistory(x => !x);

  }
  useEffect(() => {
    if (exemptionId) {
      setShowIncompleteHistory(true)
    }
  }, [exemptionId]);
  const clickOnAction = (id) => {

    if (id) {
      setExemptionId(id)
    }
  }
  return (
    <div className="saved-application-wrapper">
      <SectionTitle>Saved Application</SectionTitle>
      <SearchBox
        searchByOptions={SearchBySavedApplication()}
        doSearch={doSearch}
      />
      <div>
      </div>
      <Download
        fileName={'Saved Applications Details'}
        onClickDownload={downloadStartupList}
        className="bttn-link"
      />
      <Refresh
        onClickRefresh={refreshStatupList}
        className=""
      />

      <Table
        dataTable={data}
        columns={columns({
          clickOnAction,
          pageNo: pageNo,
          size: sizePerPage
        })}
        keyField='applicationId'
        onSortChange={onSortChange}
        totalSize={recordsTotal}
        sizePerPage={sizePerPage}
        onPageChange={handleOnPageChange}
        onSizePerPageChange={handleOnSizePerPageChange}
      />

      {
        <IncompleteHistory
          isOpen={showIncompleteHistory}
          onClose={onCloseIncompeletePopup}
          exemptionId={exemptionId}
        />
      }

    </div>
  )
}

const mapStateToProps = (state) => {

  return {
    startupApplicationDataState: state.startupApplicationList.startupData,
    startupSectionList: state.startupSectionList.startupSelectionData
  }
}

export default connect(mapStateToProps, { getStartupApplicationList, bulkArchive, doDownloadStartupList })(withRouter(StartupApplication));