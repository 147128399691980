import api from '../../../dependencies/utils/Api';

import {
    FETCH_STATE_LIST,
    FETCH_STARTUP_SECTOR,
    DOWNLOAD_STARTUP_REPORT,
    FETCH_STARTUP_INDUSTRY_LIST,
    FETCH_STARTUP_REPORT_URL,
    DOWNLOAD_SUI_MONTHLY_REPORT,
    GENERATE_SUI_REPORT,
    REFRESH_SUI_REPORT,
    DOWNLOAD_PREVIUS_SUI_REPORT,
    LAST_GENERATED_REPORT_HISTORY
} from '../../../constants/ApplicationUrl';

export const startupDetailReportService = {

    fetchStateList() {
        return api.GET(`${FETCH_STATE_LIST}`).
            then((data) => {
                return data;
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    generateReport(params) {
        return api.POST(`${DOWNLOAD_STARTUP_REPORT}/false`,params).
        then((response) => {
            const { data } = response || {};
            return { data };
        });
    },
    fetchStartupIndustry() {
     
        return api.GET(`${FETCH_STARTUP_INDUSTRY_LIST}`).
            then((response) => {
                const { data: { data: startupSector = [], status } = {} } = response || {};
                return { startupSector, status };
            });
    },
    getStartupSector() {
        return api.GET(`${FETCH_STARTUP_SECTOR}/all/startupSection/list`).
            then((response) => {
                const { data: { data: startupSector = [], status } = {} } = response || {};
                return { startupSector, status };
            });
    },
    getcsvFileLink() {
        return api.GET(`${FETCH_STARTUP_REPORT_URL}`).
            then((response) => {
                const { data: { data: preSignedUrl = '', status } = {} } = response || {};
                return { preSignedUrl, status };
            });
    },
    downloadReport(params) {
        return api.DOWNLOADDATA(`${DOWNLOAD_SUI_MONTHLY_REPORT}/true`,params).
            then((data) => {
                return data;
            }).catch(e => {
                return Promise.reject(e);
            })
    },
    downloadPreviusSuiReport(params) {
        return api.DOWNLOADGETSUI(`${DOWNLOAD_PREVIUS_SUI_REPORT}/${params?.month}`).
            then((data) => {
                return data;
            }).catch(e => {
                return Promise.reject(e);
            })
    },
    
    generateSuiReport(params) {
        
        return api.POST(GENERATE_SUI_REPORT,params).
            then((data) => {
                return data;
            }).catch(e => {
                return Promise.reject(e);
            })
    },

    refreshSuiReport(params) {
        
        return api.GET(REFRESH_SUI_REPORT).
            then((data) => {
                return data;
            }).catch(e => {
                return Promise.reject(e);
            })
    },
    lastGeneratedReportHistory(params){
        return api.GET(LAST_GENERATED_REPORT_HISTORY).then((data)=>{
            if(data && data.data && data.data.status)
            {
                return data.data;
            }
        }).catch(e =>{
            return Promise.reject(e);
        })
    }
}