import React from 'react';
import './scss/index.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import { storage } from '../../../dependencies/store/storage';
import { ROLES } from '../../../constants/Roles';

const StatisticsBlock = (props) => {
    const userRole = storage.getUserRole();
    const renderRow = () => {
        let res = []
        for (var key in props.data) {
            if (props.data.hasOwnProperty(key)) {
                var title;
                if (key == 'approvedYTD') {
                    title = 'Approved startups till date';
                } else if(key == 'totalApplication'){
                    title = 'Total Application Submitted + Saved'
                }else if(key === 'pendingApplication'){
                    if(userRole === ROLES.EP_1 || userRole === ROLES.EP_2 || userRole === ROLES.IMB_MEMBER || userRole === ROLES.IMB_CHAIR) {
                        title = 'Pending Applications'
                    }else {
                        title = 'Application In Progress**'
                    }                    
                } else if(key === 'incompleteApplication'){
                    title = ' Applications Marked Incomplete'
                } else if(key === 'notrecommendedIMB_CHAIRApplication') {
                    title = 'Not Recommended'
                }else if(key === 'notrecommendedEPApplication') {
                    title = 'Not Recommended'
                }else if(key === 'notrecommendedIMB_MEMBERApplication') {
                    title = 'Not Recommended'
                }else if(key === 'pendingEPApplication') {
                    title = 'Pending Application'
                }else if(key ==='pendingIMB_CHAIRApplication'){
                    title = 'Pending Application'
                }else if(key === 'pendingIMB_MEMBERApplication') {
                    title = 'Pending Application'
                }else if(key === 'total80iacExemption'){
                    title = 'Total 80iac Exemption'
                }else {
                    title = key;
                }
                res.push(<div key={key} className="inner-stat-row clearfix">
                    <div className="count-wrap">
                        <span>
                            {props.data[key]}
                        </span>
                    </div>
                    <div className="title-wrap">
                        <h6>{title.replace(/([A-Z])/g, ' $1').trim()}</h6>
                    </div>
                </div>)

            }
        }
        return res;
    }

    return (
        <div className="stat-block-wrap">
            <div className="stat-header">
                <h5>{props.title}</h5>
            </div>
            <div className="stat-body clearfix">
                {/*<Scrollbars style={{ height: 328 }}>*/}
                {renderRow()}
                {/*</Scrollbars>*/}

            </div>
        </div>
    )
}

export default StatisticsBlock;