import React, { useEffect, useState } from 'react';
import { updateCountry, getCountry } from '../../dependencies/action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../style.scss';
import InputField from '../../../common/InputField';

const Country = (props) => {
    const { match: { params: { id: countryId = null } }, countryList = [], getCountry, updateCountry, history = {} } = props;

    const [form, setForm] = useState({
        countryName: ""
    });
    const [error, setError] = useState("")

    useEffect(() => {
        if (countryId) {
            if (countryList && countryList.length > 0) {
                const country = countryList.filter(x => x.id === countryId)[0] || {};
                const countryName = (country && country.countryName) || "";
                setForm(x => {
                    return { ...x, countryName, id: countryId }
                });
            } else {
                getCountry().then((response) => {
                });
            }
        }
    }, [countryList])

    const handleInputChange = (e, name) => {
        const { value, } = e.target;
        setForm((c) => {
            return { ...c, [name]: value }
        });
    }

    const validateName = (value) => {
        // var nameRegex = /^(?![\s.]+$)[a-zA-Z\s.]*$/;
        var nameRegex =  /^[ A-Za-z_@./#&+-]*$/
        var name = value.trim();
        let error = "";
        if (name == "" || name == undefined || name == null) {
            error = "This field is required.";
        }
        else if (!nameRegex.test(name)) {
            error = "Please provide valid country name.";
        }
        else if (name.length < 2) {
            error = "Please provide valid country name.";
        }
        else {
            error = "";
        }
        setError(error);
        return error;
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const name = form && form.countryName;
        if (!validateName(name)) {
            const countryName = (form && form.countryName && form.countryName.trim()) || "";

            updateCountry({ ...form, countryName }).then((response) => {
                const { status } = response;
                if (status) {
                    history.push('/update/country');
                }

            });
        }
    }


    return (
        <div className="country-container">
            <h2 className="mb-20">
                {countryId ? "Update Country" : "New Country"}
            </h2>
            <form>
                <InputField
                    required
                    autoComplete="off"
                    type="text"
                    name="countryName"
                    value={form.countryName}
                    onChange={handleInputChange}
                    error={error}
                    label={"Country Name"}
                    containerClass={"form-group"}
                />
                <button onClick={e => { onSubmit(e) }} className="bttn blue normal add-btn">
                    {"Submit"}
                </button>
                <button onClick={e => { history.push('/update/country') }} className="bttn normal add-btn">
                    {"Cancel"}
                </button>
            </form>


        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        countryList: state.UpdateReducer.countryList
    }
}

export default connect(mapStateToProps, { updateCountry, getCountry })(withRouter(Country));