import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import style from './model.css'
import { storage } from '../../../../dependencies/store/storage';
import { ROLES } from '../../../../constants/Roles';
const RecommendationModel = (props) =>{
    const { buttonLabel, className,actionValue } = props;
    const toggle = () => props.toggleActionModal();
    const [comment, setComment] = useState('');
    const [textBox, setTexbox] = useState(false)
    const [otherValue, setOtherValue]  = useState('')
    const [error, setError] = useState('');
    const [otherError, setOtherError] = useState('');
    const userRole = storage.getUserRole();  
    
    useEffect(() =>{
        props.callApi(comment)
        if(comment == 'Others') {
            setTexbox(true)
        }else {
            setTexbox(false)
        }

    })
    const actionPerform = async () => {
        if(!comment) {
            setError('Please select any reason to submit!')
            return false
        }    
        if(comment == 'Others') {
            if(!otherValue) {
                setOtherError('Please add reason to submit!')
                return false
            }
            setComment(otherValue)
            props.callApi(otherValue)
        }        
        await toggle();       

    }
    const ActionIMBRecommend = async () => {
        if(!otherValue) {
            setOtherError('Please add reason to submit!')
            return false
        }
        // if(comment == 'Others') {
            
            setComment(otherValue)
            props.imbMemberCall(false,otherValue)
            // return false
        // }
        await toggle(); 
        // props.imbMemberCall(false,comment)
    }
    const ActionIMBChairRecommend = async () => {
        
        // if(comment == 'Others') {
            if(!otherValue) {
                setOtherError('Please add reason to submit!')
                return false
            }
            setComment(otherValue)
            props.imbChairCall(false,otherValue)
            // return false
        // }
        await toggle(); 
        // props.imbChairCall(false,comment)
    }

    const ActionIMBChairRecommendYES = async () => {
        if(!otherValue) {
            setOtherError('Please add reason to submit!')
            return false
        }
        setComment(otherValue)
        props.imbChairCallYes(false,otherValue)
        await toggle();
    }
    
    const setTextvalue = (e) => {
        setOtherValue(e.target.value)
        setOtherError('')
        
    }
    const setRadioValue = (e) => {
        setComment(e.target.value)
        setError('')
        
    }
    return(
        <div>
            <Modal isOpen={props.modalValue} toggle={toggle} className={className}>
            <div className="modal-header" style={{padding: "16px"}}>
                <h5 className="modal-title model-title-line" style={{position: 'relative', width: "100%", color: '#393A93'}}>Reason For Not Recommending</h5>
                <button onClick={toggle} type="button" className="close" aria-label="Close" style={{
                    position: 'absolute', 
                    right: '3px', 
                    top: '7px', 
                    background: '#fffe', 
                    height: '21px', 
                    width: '21px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #393a93',
                    borderRadius: '100%',
                    zIndex: '9999',
                    opacity: '0.99',
                    padding: '1px',
                    lineHeight: '8px'
                }}><span aria-hidden="true" style={{
                    fontSize: '17px',
                lineHeight: '13px',
                marginTop: '-3px',
                }}>×</span></button>
            </div>
                <ModalBody>
                    {   
                        (actionValue == 'RECOMENDATION' && error)?(<label className='text-danger' style={{padding:'0 0 6px 0', marginLeft:'27px'}}>{error}</label>):''                        
                    }
                
                {
                    ((actionValue == 'RECOMENDATION' && userRole== ROLES.EP_1)?(
                        <>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Lack of Innovation - Insufficient information ascertaining innovation, scope of scalability and wealth generation aspect of the product/ services offered by the startup"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >Lack of Innovation - Insufficient information ascertaining innovation, scope of scalability and wealth generation aspect of the product/ services offered by the startup </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment1" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Insufficient Information about the product/service offering of the startup"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment1" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >Insufficient Information about the product/service offering of the startup </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment2" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Insufficient brief about how the startup is innovative and/or scalable (Uniqueness/USP of the startup that differentiates from its competitors in the market)"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment2" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >Insufficient brief about how the startup is innovative and/or scalable (Uniqueness/USP of the startup that differentiates from its competitors in the market) </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment4" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Insufficient details about adherence to the specific government approvals required by the startups such as FSSAI registration, guidelines set by Bureau of Indian Standards"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment4" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >Insufficient details about adherence to the specific government approvals required by the startups such as FSSAI registration, guidelines set by Bureau of Indian Standards </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment50" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Insufficient details about adherence to the specific government approvals required by the startups such as FSSAI registration, guidelines set by Bureau of Indian Standards"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment50" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >Insufficient details about adherence to the specific government approvals required by the startups such as FSSAI registration, guidelines set by Bureau of Indian Standards </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment5" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Insufficient details about the number of people directly employed with your startup"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment5" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >Insufficient details about the number of people directly employed with your startup </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment6" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Insufficient details about the number of customers/clients"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment6" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >Insufficient details about the number of customers/clients </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment7" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Insufficient proof of customers/users with a copy of at least one Contract/Work-Order/MoU or the number PlayStore/AppleStore downloads in case of mobile applications along with a screenshot of the app listing on these platforms"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment7" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >Insufficient proof of customers/users with a copy of at least one Contract/Work-Order/MoU or the number PlayStore/AppleStore downloads in case of mobile applications along with a screenshot of the app listing on these platforms </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment8" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Link to the startup’s website not working/ not updated/ does not provide sufficient information"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment8" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >Link to the startup’s website not working/ not updated/ does not provide sufficient information </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment9" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Screenshots/images of your product/website not providing sufficient information"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment9" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >Screenshots/images of your product/website not providing sufficient information </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment3" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Others"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment3" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}}>Others </label>
                            </div>
                            <div>
                                {(textBox)?(
                                    <>
                                        <textarea className="form-control" name="textValue" onChange={(e) => setTextvalue(e)} value={otherValue} placeholder="Input your message" />
                                        {
                                            otherError?(<label className='text-danger'>{otherError}</label>):''                        
                                        }
                                        <br />
                                    </>
                                ):''}
                                
                            </div>
                            <Button style={{
                                background: '#18AD00',
                                border: 'none',
                                padding: '5px  15px',
                                margin: '0 41.5%',
                            }} onClick={actionPerform}>Submit</Button>
                        </>
                        
                    ):'')
                }
                {
                    ((actionValue == 'RECOMENDATION' && userRole== ROLES.EP_2)?(
                        <>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Insufficient details about the shareholding pattern as on the date of filling this application"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >Insufficient details about the shareholding pattern as on the date of filling this application </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment1" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Insufficient Directors and Shareholders’ citizenship details"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment1" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >Insufficient Directors and Shareholders’ citizenship details </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment2" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Insufficient details on the Revenue model i.e. details of how the startup generates revenue"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment2" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >Insufficient details on the Revenue model i.e. details of how the startup generates revenue </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment4" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Insufficient details of the funding received from private investors or Angel/VC funds"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment4" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >Insufficient details of the funding received from private investors or Angel/VC funds </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment5" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Insufficient details of the support (monetary/non-monetary) received from Central or State Government Ministries/Departments/PSUs/Incubators"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment5" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >Insufficient details of the support (monetary/non-monetary) received from Central or State Government Ministries/Departments/PSUs/Incubators </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment6" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Insufficient details about the number of people directly employed with your startup"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment6" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >Insufficient details about the number of people directly employed with your startup </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment7" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Insufficient details about the number of customers/clients"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment7" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >Insufficient details about the number of customers/clients </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment8" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Insufficient proof of customers/users with a copy of at least one Contract/Work-Order/MoU or the number PlayStore/AppleStore downloads in case of mobile applications along with a screenshot of the app listing on these platforms"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment8" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >Insufficient proof of customers/users with a copy of at least one Contract/Work-Order/MoU or the number PlayStore/AppleStore downloads in case of mobile applications along with a screenshot of the app listing on these platforms </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment9" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Insufficient details on the accounts (P&L Statement and Balance Sheet with Notes of Accounts)"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment9" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >Insufficient details on the accounts (P&L Statement and Balance Sheet with Notes of Accounts) </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment10" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Incomplete MoA"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment10" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >Incomplete MoA </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment11" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="High probability/ sufficient proof of the entity being reconstructed"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment11" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}} >High probability/ sufficient proof of the entity being reconstructed </label>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',width: '100%'}}>
                                <input id="recomcomment13" name="recomentComment" type="radio" className="input-element filled form-check-input" onChange={(e) =>setRadioValue(e)} value="Others"  style={{width: '25px',left: '33px'}} />
                                <label htmlFor="recomcomment13" className="" style={{width: '94%', paddingTop: '0px', background: '#fff', float: 'right',lineHeight: '20px', textTransform: 'none'}}>Others </label>
                            </div>
                            <div>
                                {(textBox)?(
                                    <>
                                        <textarea className="form-control" name="textValue" onChange={(e) => setTextvalue(e)} value={otherValue} placeholder="Input your message" />
                                        {
                                            otherError?(<label className='text-danger'>{otherError}</label>):''                        
                                        }
                                        <br />
                                    </>
                                ):''}
                                
                            </div>
                            <Button style={{
                                background: '#18AD00',
                                border: 'none',
                                padding: '5px  15px',
                                margin: '0 41.5%',
                            }} onClick={actionPerform}>Submit</Button>
                        </>
                    ):'')
                }
                {
                    ((actionValue == 'IMB_RECOMENDATION')?(
                        <>
                            <div>
                                <textarea className="form-control" name="textValue" onChange={(e) => setTextvalue(e)} value={otherValue} placeholder="Input your message" />
                                {
                                    otherError?(<label className='text-danger'>{otherError}</label>):''                        
                                }
                                <br />
                                
                            </div>
                            <Button style={{
                                background: '#18AD00',
                                border: 'none',
                                padding: '5px  15px',
                                margin: '0 41.5%',
                            }} onClick={ActionIMBRecommend}>Submit</Button>
                        </>
                        
                    ):'')
                }
                {
                    ((actionValue == 'IMB_CHAIR_RECOMENDATION')?(
                        <>
                            
                            <div>
                                <textarea className="form-control" name="textValue" onChange={(e) => setTextvalue(e)} value={otherValue} placeholder="Input your message" />
                                {
                                    otherError?(<label className='text-danger'>{otherError}</label>):''                        
                                }
                                <br />
                                
                            </div>
                            <Button style={{
                                background: '#18AD00',
                                border: 'none',
                                padding: '5px  15px',
                                margin: '0 41.5%',
                            }} onClick={ActionIMBChairRecommend}>Submit</Button>
                        </>
                        
                    ):'' )
                }
                {
                    actionValue == 'IMB_CHAIR_RECOMENDATION_YES'? (
                        <>
                            
                            <div>
                                <textarea className="form-control" name="textValue" onChange={(e) => setTextvalue(e)} value={otherValue} placeholder="Input your message" />
                                {
                                    otherError?(<label className='text-danger'>{otherError}</label>):''                        
                                }
                                <br />
                                
                            </div>
                            <Button style={{
                                background: '#18AD00',
                                border: 'none',
                                padding: '5px  15px',
                                margin: '0 41.5%',
                            }} onClick={ActionIMBChairRecommendYES}>Submit</Button>
                        </>
                    ):''
                }
                
                </ModalBody>
                
            </Modal>
        </div>
    )
}

export default RecommendationModel;