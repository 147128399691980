import React, { useEffect, useState } from 'react';
import { getIndustry } from '../dependencies/action';
import { connect } from 'react-redux';
import Table from '../../common/Table';
import column from '../dependencies/columns/industry';
import '../style.scss';

const Industry = (props) => {
    const { getIndustry } = props;

    const [industryList, setIndustryList] = useState([]);

    useEffect(() => {
        if (getIndustry) {
            getIndustry().then((repsonse) => {
                const { status , data } = repsonse;
                if (status) {
                    setIndustryList(data);
                }
            });
        }
    }, [])

    return (
        <>
            <div className="country-container">
                <h2 className="mb-20">{"Industry"}
                    <a href="/update/industry/form" className="bttn blue normal add-btn">
                        {"Add Industry"}
                    </a>
                </h2>

                <Table
                    dataTable={industryList}
                    columns={column}
                    keyField={'id'}
                    sizePerPage={1000}
                />
            </div>
        </>
    )
}

export default connect(null, { getIndustry })(Industry);