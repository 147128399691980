import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

const AlertMessage = (props) => {
  const { allowClose, type, message, onClose } = props

  const [visible, setVisible] = useState(allowClose);
  const onDismiss = () => {
    setVisible(false);
    if (onClose) {
      onClose();
    }
  }
  useEffect(() => {
    if (message) {
      setVisible(true)
    }
  }, [message])

  return (
    message &&
    <Alert
      color={type}
      isOpen={visible}
      toggle={onDismiss}
    >
      {message}
    </Alert>
  );

}

AlertMessage.propTypes = {
  allowClose: PropTypes.bool,
  type: PropTypes.string,
  onClose: PropTypes.func,
}
AlertMessage.defaultProps = {
  allowClose: false,
  type: 'success',
  onClose: () => { }
}
export default AlertMessage;