import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import View from './view';

function mapStateToProps(state) {
  return {
    // userLoggedIn: state.loginReducer.isLoggedIn,
  };
}
export default connect(
  mapStateToProps,
  null)(View);

