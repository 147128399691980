import { get, isEmpty, cloneDeep } from 'lodash'
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { API_URL } from '../../../../../constants/ApplicationUrl'
import Button from '../../../../common/Button';
import { Link } from 'react-router-dom';
import FileUpload from '../../../../common/FileUpload';
import { uploadFileService } from '../../../../../dependencies/services/uploadFileService';
import OtherDocument from './otherDocuments';
import InlineError from '../../../../common/inlineError';

const otherDocumentType = {
  name: null,
  url: null,
  file: null,
}

const SelfCertification = (props) => {
  const { selfCertificate,
    onChangeHandler,
    error = {},
    isLoading,
    doSubmit,
    isPreCertified
  } = props;
  let defaultData = {};
  if (isPreCertified) {
    defaultData = {
      additionalDocument: [],
      registrationCertificate: {},
    }
  }
  else {
    defaultData = {
      additionalDocument: [],
      registrationCertificate: {},
      authorizationLetter: {},
      nameChangeDocs: [],
      conversionDocs: [],
      mergerDocs: [],
      cinChangeDocs: [],
    }
  }
  const [formData, setFormData] = useState(defaultData);

  useEffect(() => {
    if (!isEmpty(selfCertificate)) {
      setFormData(data => {
        return { ...data, ...selfCertificate };
      })
    }
  }, [selfCertificate]);

  const handleInputChange = (e, name) => {
    if (e && e.target) {
      const value = e.target.checked;
      setFormData(data => {
        return { ...data, ['tnc']: value }
      })
    }
  }

  const onInputChange = (params) => {
    const { index, document } = params;
    const { additionalDocument: documents = [] } = formData;

    if (index > -1 && documents && documents[index]) {
      documents[index] = { ...document }
    }

    setFormData(d => {
      return { ...d, additionalDocument: [...documents] };
    })
  }
  const onNameChangeUpload = (params) => {
    const { index, document } = params;
    const { additionalDocument: documents = [] } = formData;

    if (index > -1 && documents && documents[index]) {
      documents[index] = { ...document }
    }

    setFormData(d => {
      return { ...d, additionalDocument: [...documents] };
    })
  }
  const onUploadFile = (file) => {
    isLoading(true)
    const data = new FormData();
    data.append('file', file)
    data.append('documentType', 'SELF_CERTIFICATE');
    uploadFileService.uploadFile(data).then((data) => {
      if (data) {
        setFormData(d => {
          return { ...d, registrationCertificate: data };
        });
      }
      isLoading(false);
    }, (error) => {
      isLoading(false);

    });
  }
  const onUploadFileAuthLetter = (file) => {
    isLoading(true)
    const data = new FormData();
    data.append('file', file)
    data.append('documentType', 'SELF_CERTIFICATE_AUTHORIZATION_LETTER');
    uploadFileService.uploadFile(data).then((data) => {
      if (data) {
        setFormData(d => {
          return { ...d, authorizationLetter: data };
        });
      }
      isLoading(false);
    }, (error) => {
      isLoading(false);

    });
  }
  const onUploadSelfCertificationMerger = (file, index, docType) => {
    isLoading(true)
    const oldData = new FormData();
    oldData.append('file', file)
    oldData.append('documentType', docType);
    uploadFileService.uploadFile(oldData).then((data) => {
      if (data) {
        const document = { ...data, [index]: data, url: null }
        const { mergerDocs: documents = [] } = formData;
        if (index > -1 && documents && documents[index]) {
          documents[index] = { ...document }
        }
        setFormData(d => {
          return { ...d, mergerDocs: [...documents] };
        })
      }
      isLoading(false)
    }, (error) => {
      isLoading(false)

    });

  }
  const onUploadSelfCertificationConversion = (file, index, docType) => {
    isLoading(true)
    const oldData = new FormData();
    oldData.append('file', file)
    oldData.append('documentType', docType);
    uploadFileService.uploadFile(oldData).then((data) => {
      if (data) {
        const document = { ...data, [index]: data, url: null }
        const { conversionDocs: documents = [] } = formData;
        if (index > -1 && documents && documents[index]) {
          documents[index] = { ...document }
        }
        setFormData(d => {
          return { ...d, conversionDocs: [...documents] };
        })
      }
      isLoading(false)
    }, (error) => {
      isLoading(false)

    });

  }
  const onUploadSelfCertificationNameChange = (file, index, docType) => {
    isLoading(true)
    const oldData = new FormData();
    oldData.append('file', file)
    oldData.append('documentType', docType);
    uploadFileService.uploadFile(oldData).then((data) => {
      if (data) {
        const document = { ...data, [index]: data, url: null }
        const { nameChangeDocs: documents = [] } = formData;
        if (index > -1 && documents && documents[index]) {
          documents[index] = { ...document }
        }
        setFormData(d => {
          return { ...d, nameChangeDocs: [...documents] };
        })
      }
      isLoading(false)
    }, (error) => {
      isLoading(false)

    });

  }
  const onUploadSelfCertificationCinChange = (file, index, docType) => {
    isLoading(true)
    const oldData = new FormData();
    oldData.append('file', file)
    oldData.append('documentType', docType);
    uploadFileService.uploadFile(oldData).then((data) => {
      if (data) {
        const document = { ...data, [index]: data, url: null }
        const { cinChangeDocs: documents = [] } = formData;
        if (index > -1 && documents && documents[index]) {
          documents[index] = { ...document }
        }
        setFormData(d => {
          return { ...d, cinChangeDocs: [...documents] };
        })
      }
      isLoading(false)
    }, (error) => {
      isLoading(false)

    });

  }
  const doAddDocument = () => {
    const otherDocuments = formData.additionalDocument && cloneDeep(formData.additionalDocument) || [];
    otherDocuments.push({ ...otherDocumentType });
    setFormData(d => {
      return { ...d, additionalDocument: otherDocuments };
    })
  }

  useEffect(() => {
    if (onChangeHandler) {
      onChangeHandler({
        selfCertificate: { ...formData }
      })
    }
  }, [formData]);

  const handleOnDelete = (index) => {
    const { additionalDocument = [] } = formData;
    const otherData = cloneDeep(additionalDocument);

    if (otherData && otherData.length && otherData.length > 0 && otherData.length > index) {
      otherData.splice(index, 1)
      setFormData(d => {
        return { ...d, additionalDocument: otherData };
      });
    }
  }
  const registrationfileName = get(formData, 'registrationCertificate.originalFilename') || "registration certificate";
  const ViewRegistrationCertificate = formData.registrationCertificate && formData.registrationCertificate.publicUrl || "";
  const ViewRegistrationFileName = formData.registrationCertificate && formData.registrationCertificate.compressedName || "";

  const authorizationFileName = get(formData, 'authorizationLetter.originalFilename') || "authorization letter";
  const ViewAuthorizationLetter = formData.authorizationLetter && formData.authorizationLetter.publicUrl || "";
  const ViewAuthorizationFileName = formData.authorizationLetter && formData.authorizationLetter.compressedName || "";

  const MergerRecogDoc = formData?.mergerDocs && formData.mergerDocs[0] && formData.mergerDocs[0].publicUrl || "";
  const MergerRecogName = formData?.mergerDocs && formData.mergerDocs[0]?.compressedName || "";
  const MergerNCLTDoc = formData?.mergerDocs && formData.mergerDocs[1] && formData.mergerDocs[1].publicUrl || "";
  const MergerNCLTName = formData?.mergerDocs && formData.mergerDocs[1] && formData.mergerDocs[1].compressedName || "";
  const MergerINC28Doc = formData?.mergerDocs && formData.mergerDocs[2] && formData.mergerDocs[2].publicUrl || "";
  const MergerINC28Name = formData?.mergerDocs && formData.mergerDocs[2] && formData.mergerDocs[2].compressedName || "";
  const MergerFinancialDoc = formData?.mergerDocs && formData.mergerDocs[3] && formData.mergerDocs[3].publicUrl || "";
  const MergerFinancialName = formData?.mergerDocs && formData.mergerDocs[3] && formData.mergerDocs[3].compressedName || "";

  const conversionCorpDoc = formData?.conversionDocs && formData.conversionDocs[0] && formData.conversionDocs[0].publicUrl || "";
  const conversionCorpName = formData?.conversionDocs &&  formData.conversionDocs[0] && formData.conversionDocs[0].compressedName || "";
  const conversionConCorpDoc = formData?.conversionDocs && formData.conversionDocs[1] && formData.conversionDocs[1].publicUrl || "";
  const conversionConCorpName = formData?.conversionDocs && formData.conversionDocs[1] && formData.conversionDocs[1].compressedName || "";

  const NameChangeCorpDoc = formData?.nameChangeDocs && formData.nameChangeDocs[0] && formData.nameChangeDocs[0].publicUrl || "";
  const NameChangeCorpName = formData?.nameChangeDocs && formData.nameChangeDocs[0] && formData.nameChangeDocs[0].compressedName || "";
  const NameChangeConCorpDoc = formData?.nameChangeDocs && formData.nameChangeDocs[1] && formData.nameChangeDocs[1].publicUrl || "";
  const NameChangeConCorpName = formData?.nameChangeDocs && formData.nameChangeDocs[1] && formData.nameChangeDocs[1].compressedName || "";
  const NameChangePanDoc = formData?.nameChangeDocs && formData.nameChangeDocs[2] && formData.nameChangeDocs[2].publicUrl || "";
  const NameChangePanName = formData?.nameChangeDocs && formData.nameChangeDocs[2] && formData.nameChangeDocs[2].compressedName || "";

  const CinChangeCorpDoc = formData?.cinChangeDocs && formData.cinChangeDocs[0] && formData.cinChangeDocs[0].publicUrl || "";
  const CinChangeCorpName = formData?.cinChangeDocs && formData.cinChangeDocs[0] && formData.cinChangeDocs[0].compressedName || "";
  const CinChangeConCorpDoc = formData?.cinChangeDocs && formData.cinChangeDocs[1] && formData.cinChangeDocs[1].publicUrl || "";
  const CinChangeConCorpName = formData?.cinChangeDocs && formData.cinChangeDocs[1] && formData.cinChangeDocs[1].compressedName || "";

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-sm-6">
          {ViewRegistrationCertificate &&
            <label>Incorporation/ Registration Certificate <a href={API_URL() + ViewRegistrationCertificate} target="_blank">{ViewRegistrationFileName}</a></label>
          }
          <FileUpload
            onUploadFile={onUploadFile}
            label={registrationfileName}
            error={error.registrationCertificate}
            maxFileSizeInMB={5}
          />
        </div>
      </div>


      <div className="row">
        <div className="col">
          <p className="pt-20">(Please upload Company / LLP / Partnership Incorporation / Registration certificate. The Certificate must be uploaded in pdf,png,jpg with file size not exceeding 5 MB)</p>
        </div>
      </div>
      {
        formData && formData.additionalDocument && formData.additionalDocument.map((val, index) => {
          return (
            <div key={index} className="row other-documents">
              <OtherDocument
                index={index}
                otherDocument={val}
                onChange={onInputChange}
                isLoading={isLoading}
                handleOnDelete={handleOnDelete}
              />
            </div>
          )
        })
      }


      <Button
        className="bttn blue mb-20"
        type="button"
        text="Add More"
        onClick={doAddDocument}
      />
      {!isPreCertified &&
        <div>
          {formData?.isMerger ? <div className="merger">
            <div className="row">
              <div className="col">
                <ul>
                  <li>
                    <p>I certify that the entity is the result of merger or amalgamation under section 233 of the Companies Act, 2013 of two or more start-up companies; or one or more start-up company with one or more small company.</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <FileUpload
                  onUploadFile={(e) => onUploadSelfCertificationMerger(e, 0, "MERGER_DOC_RECOGNITION_CERTIFICATE")}
                  error={error.authorizationLetter}
                  maxFileSizeInMB={5}
                />
                <label className="mb-20">Recognition Certificate <a href={API_URL() + MergerRecogDoc} target="_blank">{MergerRecogName}</a></label>
              </div>
              <div className="col-sm-6">
                <FileUpload
                  onUploadFile={(e) => onUploadSelfCertificationMerger(e, 1, "MERGER_DOC_NCLT_ORDER")}
                  error={error.authorizationLetter}
                  maxFileSizeInMB={5}
                />
                <label className="mb-20">NCLT Order <a href={API_URL() + MergerNCLTDoc} target="_blank">{MergerNCLTName}</a></label>
              </div>
              <div className="col-sm-6">
                <FileUpload
                  onUploadFile={(e) => onUploadSelfCertificationMerger(e, 2, "MERGER_DOC_FORM_INC_28")}
                  error={error.authorizationLetter}
                  maxFileSizeInMB={5}
                />
                <label className="mb-20">Form INC-28 <a href={API_URL() + MergerINC28Doc} target="_blank">{MergerINC28Name}</a></label>
              </div>
              <div className="col-sm-6">
                <FileUpload
                  onUploadFile={(e) => onUploadSelfCertificationMerger(e, 3, "MERGER_DOC_FINANCIAL_STATEMENT")}
                  error={error.authorizationLetter}
                  maxFileSizeInMB={5}
                />
                <label className="mb-20">Financial statement <a href={API_URL() + MergerFinancialDoc} target="_blank">{MergerFinancialName}</a></label>
              </div>
            </div>
          </div> :
            <div className="row">
              <div className="col">
                <ul>
                  <li>
                    I certify that the entity is not the resultant entity or formed due to merger or demerger or acquisition or amalgamation or absorption.
                  </li>
                </ul>
              </div>
            </div>}
          {formData?.isConversion ? <div className="conversion">
            <div className="row">
              <div className="col">
                <ul>
                  <li>
                    <p >
                      The entity is formed as a result of conversion from one form to another subject to the fulfilment of condition provided in sub - section(3) of section 80-IAC of the Income- tax Act, 1961.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <FileUpload
                  onUploadFile={(e) => onUploadSelfCertificationConversion(e, 0, "CONVERSION_DOC_ORIGINAL_INCORPORATION_CERTIFICATE")}
                  error={error.authorizationLetter}
                  maxFileSizeInMB={5}
                />
                <label className="mb-20">Original Incorporation Certificate <a href={API_URL() + conversionCorpDoc} target="_blank">{conversionCorpName}</a></label>
              </div>
              <div className="col-sm-6">
                <FileUpload
                  onUploadFile={(e) => onUploadSelfCertificationConversion(e, 1, "CONVERSION_DOC_CONVERTED_INCORPORATION_CERTIFICATE")}
                  error={error.authorizationLetter}
                  maxFileSizeInMB={5}
                />
                <label className="mb-20">Incorporation Certificate upon conversion <a href={API_URL() + conversionConCorpDoc} target="_blank">{conversionConCorpName}</a></label>
              </div>
            </div>
          </div> : <div className="row">
            <div className="col">
              <ul>
                <li>
                  The entity is formed as a result of conversion from one form to another subject to the fulfilment of condition provided in sub - section(3) of section 80-IAC of the Income- tax Act, 1961.   :   <b>NA</b>
                </li>
              </ul>
            </div>
          </div>}
          {formData?.isNameChange ? <div className="nameChange">
            <div className="row">
              <div className="col">
                <ul>
                  <li>
                    <p>
                      The entity has changed its name as necessitated under the relevant provisions of the applicable Act and the entity is applying with the new name.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <FileUpload
                  onUploadFile={(e) => onUploadSelfCertificationNameChange(e, 0, "NAME_CHANGE_DOC_ORIGINAL_INCORPORATION_CERTIFICATE")}
                  error={error.authorizationLetter}
                  maxFileSizeInMB={5}
                />
                <label className="mb-20">Original Incorporation Certificate <a href={API_URL() + NameChangeCorpDoc} target="_blank">{NameChangeCorpName}</a></label>
              </div>
              <div className="col-sm-6">
                <FileUpload
                  onUploadFile={(e) => onUploadSelfCertificationNameChange(e, 1, "NAME_CHANGE_DOC_CONVERTED_INCORPORATION_CERTIFICATE")}
                  error={error.authorizationLetter}
                  maxFileSizeInMB={5}
                />
                <label className="mb-20">Incorporation Certificate upon conversion <a href={API_URL() + NameChangeConCorpDoc} target="_blank">{NameChangeConCorpName}</a></label>
              </div>

              {(props.natureOfEntityName === "Registered Partnership") ? <div className="col-sm-6">
                <FileUpload
                  onUploadFile={(e) => onUploadSelfCertificationNameChange(e, 2, "NAME_CHANGE_DOC_PAN_CARD")}
                  error={error.authorizationLetter}
                  maxFileSizeInMB={5}
                />
                <label className="mb-20">Pan Card <a href={API_URL() + NameChangePanDoc} target="_blank">{NameChangePanName}</a></label>
              </div>
                :
                ""}
            </div>
          </div> : <div className="row">
            <div className="col">
              <ul>
                <li>
                  The entity has changed its name as necessitated under the relevant provisions of the applicable Act and the entity is applying with the new name.     :   <b>NA</b>
                </li>
              </ul>
            </div>
          </div>}
          {formData?.isCinChange ? <div className="cinChange">
            <div className="row">
              <div className="col">
                <ul>
                  <li>
                    <p>
                      The entity/s CIN/LLPIN changed
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <FileUpload
                  onUploadFile={(e) => onUploadSelfCertificationCinChange(e, 0, "CIN_CHANGE_DOC_ORIGINAL_INCORPORATION_CERTIFICATE")}
                  error={error.authorizationLetter}
                  maxFileSizeInMB={5}
                />
                <label className="mb-20">Original Incorporation Certificate <a href={API_URL() + CinChangeCorpDoc} target="_blank">{CinChangeCorpName}</a></label>
              </div>
              <div className="col-sm-6">
                <FileUpload
                  onUploadFile={(e) => onUploadSelfCertificationCinChange(e, 0, "CIN_CHANGE_DOC_CONVERTED_INCORPORATION_CERTIFICATE")}
                  error={error.authorizationLetter}
                  maxFileSizeInMB={5}
                />
                <label className="mb-20">Incorporation Certificate upon conversion <a href={API_URL() + CinChangeConCorpDoc} target="_blank">{CinChangeConCorpName}</a></label>
              </div>
            </div>
          </div> : <div className="row">
            <div className="col">
              <ul>
                <li>
                  The entity/s CIN/LLPIN changed.   :   <b>NA</b>
                </li>
              </ul>
            </div>
          </div>}
          <div className="row autheraization mb-20">

            <div className="col-sm-12">
              <ul>
                <li>
                  <p>
                    Authorization Letter
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-sm-6">
              {ViewAuthorizationLetter &&
                <div>
                  <FileUpload
                    onUploadFile={onUploadFileAuthLetter}
                    error={error.authorizationLetter}
                    maxFileSizeInMB={5}
                  />
                  <label className="mb-20">{authorizationFileName} <a href={API_URL() + ViewAuthorizationLetter} target="_blank">{ViewAuthorizationFileName}</a></label>
                </div>
              }
            </div>
          </div>
        </div>}
      {/* <div className=" mb-20">

        <h5> <input type="checkbox" /> I certify that our entity</h5>
        <ul>
          <li>has not been incorporated for more than 7 years, (10 years for Startups in the biotechnology sector); and</li>
          <li>is working towards innovation, development or improvement of products or processes or services, or if it is a scalable business model with a high potential of employment generation or wealth creation; and</li>
          <li>has not formed the entity by splitting up or reconstruction of a business already in existence;</li>
        </ul>
        <h5>Please Note:</h5>
        <p className="mb-20">The Certificate of Recognition for startups will now be issued after examination of the application and document submission  </p>
        <div>
          <input type="checkbox" name='tnc' onChange={e => handleInputChange(e)} /><span className="tnc">I accept the <Link to='#'>Terms &amp; Conditions</Link></span>
        </div>
        {
          error.tnc && (
            <InlineError message={error.tnc} />
          )
        }
      </div> */}
      <div className="row mb-20 text-center">
        <div className="col-sm-12">
          <Button
            className="bttn mb-20"
            type="button"
            text="Save"
            onClick={() => doSubmit("CERTIFICATION")}
          />

        </div>
      </div>
    </React.Fragment>
  )
}

export default SelfCertification;