export const API_URL = () => process.env.REACT_APP_BASE_URL;
export const LOGIN_URL = `${API_URL()}/user/login`
export const LOGOUT_URL = `${API_URL()}/user/logout`
export const EDIT_PROFILE = `${API_URL()}/user/update`
export const FORGET_PASSWORD_URL = `${API_URL()}/user/forgot-password`;
export const UPDATE_PASSWORD_URL = `${API_URL()}/user/change-password`;
export const RESET_PASSWORD_URL = `${API_URL()}/user/reset-password`;
export const FETCH_ROLES_URL = `${API_URL()}/user/roles`;
export const CHECK_VALID_TOKEN = `${API_URL()}/user/isValidToken`;
export const ADD_NEW_USER_URL = `${API_URL()}/user/create`;
export const FETCH_USER_LIST_URL = `${API_URL()}/user/list`;
export const FETCH_USER_LIST_URL_COUNT = `${API_URL()}/user/getUserManagementCount`;
export const FETCH_USER_LIST_FORM_56 = `${API_URL()}/section56/getUserManagementForm56Count`;
export const FETCH_USER_LIST_FROM_RECOGNATION = `${API_URL()}/application/getAssignRecognitionCount`;
export const GET_USER_DETAILS = `${API_URL()}/user/details`;
export const GET80IACUSERDETAILS = `${API_URL()}/80iac/getAdminUserByRole`;

// Starup Application 
export const FETCH_STARTUP_APPLICATION_LIST = `${API_URL()}/application/list`;
export const FETCH_STARTUP_APPLICATION_VIEW = `${API_URL()}/application`;
export const FETCH_STARTUP_APPLICATION_UPDATE = `${API_URL()}/application/update/action`;
export const FETCH_STARTUP_SELECTION = `${API_URL()}/resource/startupIndustry/all/startupSection/list`;
export const FETCH_EP_USERS = `${API_URL()}/80iac/listOfEp`;
export const GET_NATURE_OF_ENTITY = `${API_URL()}/resource/natureOfEntity/list`;
export const FETCH_STARTUP_INDUSTRY_LIST = `${API_URL()}/resource/startupIndustry/list`;
// export const  FETCH_STARTUP_REPORT_URL = `${API_URL()}/application/details-url`;
export const FETCH_STARTUP_SERVICE_LIST = `${API_URL()}/resource/startupServices/list`;
export const FETCH_STATE_LIST = `${API_URL()}/resource/state/list`;
export const FETCH_DISTRICT_LIST = `${API_URL()}/resource/state`;
export const FETCH_SUB_DISTRICT_LIST = `${API_URL()}/resource/district`;
export const UPLOAD_FILE = `${API_URL()}/s3/upload/document`;
export const UPLOAD_FILE_CSV = `${API_URL()}/80iac/upload/records`;
export const FETCH_STARTUP_SECTOR = `${API_URL()}/resource/startupIndustry`;
export const  FETCH_STARTUP_REPORT_URL = `${API_URL()}/application/details-url`;
export const UPDATE_STARTUP_FORM = `${API_URL()}/application/update`;
export const FETCH_REJECTION_LIST = `${API_URL()}/resource/rejectReason/list`
export const UPDATE_BULK_ARVCHIVE_URL = `${API_URL()}/application/bulk/action`
export const FETCH_INCOMPLETE_HISTORY = `${API_URL()}/application/incomplete/history`
export const UPDATE_CIN_NUMBER = `${API_URL()}/application/update/incorporationNumber`
export const FETCH_AUDIT_TRAIL_list = `${API_URL()}/application/getAuditByCin`

// 80 IAC Application
export const FETCH_80IAC_APPLICATION_DETAILS = `${API_URL()}/80iac`;
export const FETCH_NRDC_LIST = `${API_URL()}/80iac/nrdcComment/list`;
export const FETCH_NRDC_RECOGNITION = `${API_URL()}/80iac/nrdcComment/recognition-data`;
export const FETCH_COMMUNICATION_REQUEST = `${API_URL()}/80iac/thread`;

export const FETCH_80IAC_LIST = `${API_URL()}/80iac/list`;
export const FETCH_80IAC_APPLICATION_OBSERVATION = `${API_URL()}/80iac/fetch/clarification`;
export const UPDATE_80IAC_ACTION = `${API_URL()}/80iac/update/action`;
export const FETCH_IMB_MEMBER_LIST = `${API_URL()}/80iac/imbMemberComment/list`;
export const SAVE_IMB_MEMBER_COMMENT = `${API_URL()}/80iac/imbMemberComment/save`;
export const SAVE_OBSERVATION_COMMUNICATED = `${API_URL()}/80iac/clarification-request`;
export const UPDATE_BULK_ACTION = `${API_URL()}/80iac/bulk/action`;
export const SAVE_NRDC_COMMENT = `${API_URL()}/80iac/nrdcComment/save`;
export const NATURE_OF_ENTITY_LIST = `${API_URL()}/natureOfEntity/list`;
export const FETCH_INCOMPLETE_HISTORY_IAC = `${API_URL()}/80iac/incomplete/history`;
export const FETCH_BENEFICIARY_LIST = `${API_URL()}/80iac/getEightyIacBeneficiary`;
export const UPDATE_RECOMENDATION_STATUS = `${API_URL()}/80iac/update/action/status`;
export const UPDATE_IMB_STATUS = `${API_URL()}/80iac/setBenificiary`;
export const UPDATE_IMB_RECOMENDATION_STATUS = `${API_URL()}/80iac/imbMember/update/action/status`;
export const UPDATE_IMB_CHAIR_RECOMENDATION_STATUS = `${API_URL()}/80iac/imbChair/update/action/status`;
export const FETCH_ASSIGNED_EP_REQUEST = `${API_URL()}/80iac/getAssignedEpUser`;
export const FETCH_ASSIGNED_IMBMEMBER_REQUEST = `${API_URL()}/80iac/getAssignedIMBMemberUser`;
export const FETCH_ASSIGNED_IMBCHAIR_REQUEST = `${API_URL()}/80iac/getAssignedIMBChairUser`;
export const FETCH_ASSIGNED_ADMIN_SUPERADMIN_REQUEST = `${API_URL()}/80iac/getAssignedAdminSuperAdminUser`;


// Section 56 Application

export const FETCH_SECTION_ID = `${API_URL()}/section56`;
export const FETCH_56_CLARIFICATION_REQUEST = `${API_URL()}/section56/fetch/clarification`;
export const SAVE_CLARIFICATION_REQUEST = `${API_URL()}/section56/clarification-request`;
export const UPDATE_SECTION56_URL = `${API_URL()}/section56/update/action`;
export const FETCH_SECTION_56 = `${API_URL()}/section56/list`;
export const DOWNLOAD_SECTION_56_LIST = `${API_URL()}/section56/report`;
export const FETCH_SECTION56_INCOMPLETE_HISTORY = `${API_URL()}/section56/incomplete/history`;
export const UPLOAD_ACTION_IN_BULK = `${API_URL()}/section56/update/action-in-bulk`;




// DashBoard

export const FETCH_DASHBOARD_COUNT_DETAILS = `${API_URL()}/dashboard/count`;
export const FETCH_DASHBOARD_DAILY_COUNT_DETAILS = `${API_URL()}/dashboard/count/daily`;
export const FETCH_AUDIT_TRAIL = `${API_URL()}/audit/fetch`;
export const FETCH_DASHBOARD_DAILY_COUNT_NEW_DETAILS = `${API_URL()}/dashboard/count/daily/80iac`;
export const FETCH_DASHBOARD_COUNT_NEW_DETAILS = `${API_URL()}/dashboard/count/80iac`;

export const DOWNLOAD_SUI_MONTHLY_REPORT = `${API_URL()}/application/imb-details-report`
export const GENERATE_SUI_REPORT = `${API_URL()}/application/sui-report`
export const REFRESH_SUI_REPORT = `${API_URL()}/application/sui-last-urls`
export const DOWNLOAD_PREVIUS_SUI_REPORT = `${API_URL()}/application/imb-details-previous-report`

// export const FETCH_DASHBOARD_COUNT_NEW_DETAILS = `${API_URL()}/user/getCurrentLoggedInUser`;



// startup Logos

export const FETCH_STARTUP_LOGO_LIST = `${API_URL()}/startUpLogo/list`;
export const FETCH_STARTUP_LOGO_APP_COUNT = `${API_URL()}/startUpLogo/applicationCount`;
export const FETCH_STARTUP_LOGO_VIEW_DETAIL = `${API_URL()}/startUpLogo/details`;
export const UPDATE_STARTUP_LOGO_STATUS = `${API_URL()}/startUpLogo/action/status`;
export const DOWNLOAD_STARTUP_REPORT = `${API_URL()}/application/details-report`
export const DOWNLOAD_STARTUP_LOGO_LIST = `${API_URL()}/startUpLogo/csvReport`;
export const LAST_GENERATED_REPORT_HISTORY = `${API_URL()}/application/detailReportHistory`



// Report

export const FETCH_ENTITY_TYPE_APPLICATION_REPORT_URL = `${API_URL()}/report/entityType`;
export const FETCH_RECOGNITION_REPORT_URL = `${API_URL()}/report/recognised`;
export const FETCH_TAX_BENEFIT_REPORT_URL = `${API_URL()}/report/taxBenefit`;
export const FETCH_TOTAL_REPORT_URL = `${API_URL()}/report/totalApplication`;
export const DOWNLOAD_INCOMPLETE_REPORT = `${API_URL()}/application/fetch/incomplete-report`;
// export const DOWNLOAD_INCOMPLETE_REPORT = `${API_URL()}/application/incomplete-form/report`;
export const DOWNLOAD_INCOMPLETE_80IAC_REPORT = `${API_URL()}/80iac/incomplete-form/report`;
export const DOWNLOAD_INCOMPLETE_SECTION56_REPORT = `${API_URL()}/section56/application-report`;
export const DOWNLOAD_DPIIT_REPORT = `${API_URL()}/application/cbdt/recognition-report`;

// Cancellation

export const FETCH_CANCEL_CERTIFICATE_URL = `${API_URL()}/application/cancel/certificate`;


// Startup Logo

//export const FETCH_STARTUP_LOGO_VIEW_DETAIL = `${API_URL()}/startUpLogo/details`;
//export const UPDATE_STARTUP_LOGO_STATUS = `${API_URL()}/startUpLogo/action/status`

// Notification Ducuments

export const FETCH_NOTIFICATION_DOCUMENTS_LIST_URL = `${API_URL()}/notificationDocuments/fetch`;
export const ADD_NOTIFICATION_DOCUMENT_URL = `${API_URL()}/notificationDocuments/add`;
export const EDIT_NOTIFICATION_DOCUMENT_URL = `${API_URL()}/notificationDocuments/update`;
export const DELETE_NOTIFICATION_DOCUMENT_URL = `${API_URL()}/notificationDocuments/remove`;


// Other

export const FETCH_OTHER_DOCUMENTS_LIST_URL = `${API_URL()}/otherDocuments/fetch`;
export const ADD_OTHER_DOCUMENT_URL = `${API_URL()}/otherDocuments/add`;
export const EDIT_OTHER_DOCUMENT_URL = `${API_URL()}/otherDocuments/update`;
export const DELETE_OTHER_DOCUMENT_URL = `${API_URL()}/otherDocuments/remove`;
export const FETCH_TAB_SECTION_LIST_URL = `${API_URL()}/otherDocuments/tabSectionNames`




// Regenerate COR

export const REGENERATE_COR = `${API_URL()}/regenerate/certificate`;


// Glossary

export const FETCH_GLOSSARY_LIST_URL = `${API_URL()}/glossary/list`;
export const FETCH_GLOSSARY_ID_URL = `${API_URL()}/glossary/fetch`;
export const SAVE_GLOSSARY_URL = `${API_URL()}/glossary/save`;
export const DELETE_GLOSSARY_URL = `${API_URL()}/glossary`;

// upadte
export const FETCH_COUNTRY_LIST = `${API_URL()}/resource/country/list`;
export const UPDATE_COUNTRY = `${API_URL()}/resource_modify/country`;
export const UPDATE_STATE = `${API_URL()}/resource_modify/state`;
export const UPDATE_DISTRICT = `${API_URL()}/resource_modify/district`;
export const UPDATE_SUBDISTRICT = `${API_URL()}/resource_modify/subDistrict`;
export const FETCH_INDUSTRY_LIST = `${API_URL()}/resource/startupIndustry/list`;
export const UPDATE_INDUSTRY = `${API_URL()}/resource_modify/startupIndustry`;
export const FETCH_SERVICES_LIST = `${API_URL()}/resource/startupServices/list`;
export const UPDATE_SERVICES = `${API_URL()}/resource_modify/startupService`;
export const FETCH_SECTIONS_LIST = `${API_URL()}/resource/startupIndustry`;
export const UPDATE_SECTION = `${API_URL()}/resource_modify/startupSection`;


// Audit Trail

export const FETCH_RECOGNITION_HISTORY = `${API_URL()}/audit/fetch/recognition-history`;
export const FETCH_80IAC_HISTORY = `${API_URL()}/audit/fetch/80iac-history`;
export const FETCH_SECTION56_HISTORY = `${API_URL()}/audit/fetch/section-56-history`;


//beneficiary

export const FETCH_NSA_REPORT_LIST = `${API_URL()}/application/list`;
export const SAVE_NSA_RESULT_DATA = `${API_URL()}/application/saveNsaResult`


