import React, { useState, useEffect } from 'react';
import { fetchSection56List, doDownloadSection56, doUploadCSV } from '../dependencies/action';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Table from '../../common/Table';
import columns from '../dependencies/columns/Section56Columns';
import ClarificationRequest from '../observationCommunicated'
import SearchBox from '../../common/Search';
import { SearchBySection50Options, Section56DefaultField } from '../dependencies/constant/searchConstant';
import Download from '../../common/Download';
import SectionTitle from '../../common/sectionTitle/sectionTitle';
import History from '../IncompleteHistory';
import UploadCSV from '../UploadCSV';
import { storage } from '../../../dependencies/store/storage';
import { ROLES } from '../../../constants/Roles';
import AssignedApplication from '../../common/AssignedApplication';



const Section56List = (props) => {
    const fetchAllRecords = false;
    const { fetchSection56List, section56List: { data: list, recordsTotal } = {}, doDownloadSection56, doUploadCSV } = props;
    const [sortColumn, setSortColumn] = useState('id');
    const [showClarificationModal, setShowClarificationModal] = useState(false);
    const [exemptionId, setExemptionId] = useState("")
    const [sortOrder, setSortOrder] = useState('asc');
    const [pageSize, setpageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(0);
    const [searchText, setSearchText] = useState("");
    // const [checkedValue, setCheckedValue] = useState([]);
    const [query, setQuery] = useState({});
    const [showUploadCSV, setShowUploadCSV] = useState(false);

    const userRole = storage.getUserRole();
    const getUser = storage.getUser();

    useEffect(() => {
        if (exemptionId) {
            setShowClarificationModal(true)
        }
        getTableData();
    }, [sortColumn, sortOrder, pageSize, pageIndex, query, searchText, exemptionId])

    const getTableData = () => {
        if (fetchSection56List) {
            fetchSection56List({
                fetchAllRecords,
                sortColumn,
                pageIndex,
                pageSize,
                sortOrder,
                searchText,
                query,
            }).then((data) => { });
        }
    }

    const onSortChange = async (params) => {
        const { order, dataField } = params;

        var sortName = '';
        if (dataField === 'mobileNumber') {
            sortName = 'recognitionData.representative.mobile';
        } else if (dataField === 'referenceNumber') {
            sortName = 'referenceNumber';
        } else if (dataField === 'recognitionNumber') {
            sortName = 'recognitionData.startupEntity.recognitionNumber';
        } else if (dataField === 'incorporationNumber') {
            sortName = 'recognitionData.incorporationNumber';
        } else if (dataField === 'entityName') {
            sortName = 'recognitionData.startupEntity.name';
        } else if (dataField === 'applicationDate') {
            sortName = 'applicationDate';
        } else if (dataField === 'registrationDate') {
            sortName = 'startupEntity.corCreatedDate';
        } else if (dataField === 'emailId') {
            sortName = 'recognitionData.representative.email';
        } else if (dataField === 'incorporationDate') {
            sortName = 'startupEntity.incorporationDate'
        } else {
            sortName = 'createdDate';
        }
        if (dataField !== '') {
            setSortColumn(await (sortName));
            setSortOrder(await (order.toUpperCase()));
        }

    }


    const handleOnPageChange = (params) => {
        const { page } = params;
        setPageIndex(page - 1);
    }

    const doSearch = (params) => {
        const { query = {}, searchText = "", searchBy = "" } = params;
        setQuery(query);
        setPageIndex(0);
    }

    const onCloseObservationPopup = () => {
        setExemptionId('')
        setShowClarificationModal(x => !x);

    }

    const clickOnAction = (id) => {
        if (id) {
            setExemptionId(id)
        }
    }
    const downloadCSV = () => {
        if (doDownloadSection56) {
            return doDownloadSection56({
                fetchAllRecords: true,
                sortColumn,
                pageIndex,
                pageSize,
                sortOrder,
                searchText,
                query,
            }).then((data) => {
                return data;
            })
        }
    }

    const onUploadFile = (file) => {
        const data = new FormData();
        data.append('file', file)
        data.append('documentType', 'CSV_File');
        return doUploadCSV(data).then((response) => {
            return (response)
        }, (error) => {
        });
    }

    const AssignedApplicationList = () => {
        setQuery(() => {
            return { ...query, role : userRole, adminUserName : getUser.user.name }
        });
    }
    
    
    const exceptSection56Role = userRole !== ROLES.SEC_56_ADMIN

    return (
        <div>
            <SectionTitle>{'Section 56 Exemption Declaration'}</SectionTitle>
            <SearchBox
                searchByOptions={SearchBySection50Options}
                // defaultFields={Section56DefaultField}
                doSearch={doSearch}
            />
            {
                exceptSection56Role &&
                <div className="download-outer">

                    <button
                        className={`download-container bttn-link`}
                        onClick={e => { setShowUploadCSV(true) }}
                    >
                        {'Bulk Action'}
                    </button>
                </div>
            }
            {/* {
                userRole === ROLES.ADMIN || userRole === ROLES.SUPER_ADMIN ? (
                    <AssignedApplication
                        onClickAssign={AssignedApplicationList}
                        className="bttn-link"
                    />
                ):''
            } */}
            <Download
                fileName={'Section_56_list'}
                onClickDownload={downloadCSV}
                className="bttn-link"

            />
            <UploadCSV
                onUpload={onUploadFile}
                isOpen={showUploadCSV}
                onClose={() => {
                    setShowUploadCSV(false)
                }}
            />
            <Table
                dataTable={list}
                columns={columns({
                    clickOnAction,
                    pageNo: pageIndex,
                    size: pageSize
                })}
                keyField='id'
                // selectRowEnabled={true}
                // selectMode="checkbox"
                // onSelectRows={onSelectRows}
                onSortChange={onSortChange}
                totalSize={recordsTotal}
                // // rowStyle={rowStyle2}
                sizePerPage={pageSize}
                onPageChange={handleOnPageChange}
            // actionHandler={actionHandler}
            // tableActions={actionsList}
            />
            {
                // <ClarificationRequest
                // />
                <History
                    isOpen={showClarificationModal}
                    onClose={onCloseObservationPopup}
                    exemptionId={exemptionId}
                />
            }


        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        section56List: state.Section56Reducer.section56List,
    }
}

export default connect(mapStateToProps, { fetchSection56List, doDownloadSection56, doUploadCSV })(withRouter(Section56List));