import React, { useEffect, useState } from 'react';
import './dashboard.scss';
import SectionTitle from '../common/sectionTitle/sectionTitle';
import LinkCard from "./linkCard";
import icon1 from '../../assests/images/linkicon-1.png';
import icon2 from '../../assests/images/linkicon-2.png';
import icon3 from '../../assests/images/linkicon-3.png';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { getDashboardDetails,getDashboardCountNewCom } from './dependencies/action';
import StatisticsBlock from './statisticsBlock';
import Table from '../common/Table';
import columns from './dependencies/columns';
import moment from 'moment';
import { storage } from '../../dependencies/store/storage';
import { ROLES } from '../../constants/Roles';


const Dashboard = (props) => {
  const { getDashboardDetails,getDashboardCountNewCom, countData, countDailyData, auditTrailList,countDataNew,countDataDailyNew } = props;
  
  const userRole = storage.getUserRole();
  const user = storage && storage.getUser()
  let newDaily = {};
  let newCount = {};
  if(userRole === ROLES.EP_1 || userRole === ROLES.EP_2) {
    if(countDataDailyNew && countDataDailyNew.data) {
      for(let [key, value] of Object.entries(countDataDailyNew.data)) {
        if(key == 'totalEPApplication') {
          newDaily.submittedApplication = value;
        }
        if(key == 'recommendedEPApplication') {
          newDaily.recommendedApplication = value
        }
        if(key == 'notrecommendedEPApplication') {
          newDaily.notrecommendedEPApplication = value
        }
        if(key == 'pendingEPApplication') {
          newDaily.pendingApplication = value
        }       
      }
    }
    
    if(countDataNew && countDataNew.data) {
      for(let [key, value] of Object.entries(countDataNew.data)) {
        if(key == 'totalEPApplication') {
          newCount.submittedApplication = value;
        }
        if(key == 'recommendedEPApplication') {
          newCount.recommendedApplication = value
        }
        if(key == 'notrecommendedEPApplication') {
          newCount.notrecommendedEPApplication = value
        }
        if(key == 'pendingEPApplication') {
          newCount.pendingEPApplication = value
        }       
      }
    }
    
  }
  if(userRole === ROLES.IMB_MEMBER) {
    if(countDataDailyNew && countDataDailyNew.data) {
      for(let [key, value] of Object.entries(countDataDailyNew.data)) {
        if(key == 'totalIMB_MEMBERApplication') {
          newDaily.submittedApplication = value;
        }
        if(key == 'recommendedIMB_MEMBERApplication') {
          newDaily.recommendedApplication = value
        }
        if(key == 'notrecommendedIMB_MEMBERApplication') {
          newDaily.notrecommendedIMB_MEMBERApplication = value
        }
        if(key == 'pendingIMB_MEMBERApplication') {
          newDaily.pendingApplication = value
        }       
      }
    }
    
    if(countDataNew && countDataNew.data) {
      for(let [key, value] of Object.entries(countDataNew.data)) {
        if(key == 'totalIMB_MEMBERApplication') {
          newCount.submittedApplication = value;
        }
        if(key == 'recommendedIMB_MEMBERApplication') {
          newCount.recommendedApplication = value
        }
        if(key == 'notrecommendedIMB_MEMBERApplication') {
          newCount.notrecommendedIMB_MEMBERApplication = value
        }
        if(key == 'pendingIMB_MEMBERApplication') {
          newCount.pendingApplication = value
        }       
      }
    }
    
  }
  if(userRole === ROLES.IMB_CHAIR) {
    if(countDataDailyNew && countDataDailyNew.data) {
      for(let [key, value] of Object.entries(countDataDailyNew.data)) {
        if(key == 'totalIMB_CHAIRApplication') {
          newDaily.submittedApplication = value;
        }
        if(key == 'recommendedIMB_CHAIRApplication') {
          newDaily.recommendedApplication = value
        }
        if(key == 'notrecommendedIMB_CHAIRApplication') {
          newDaily.notrecommendedIMB_CHAIRApplication = value
        }
        if(key == 'pendingIMB_CHAIRApplication') {
          newDaily.pendingApplication = value
        }       
      }
    }
    if(countDataNew && countDataNew.data) {
      for(let [key, value] of Object.entries(countDataNew.data)) {
        if(key == 'totalIMB_CHAIRApplication') {
          newCount.submittedApplication = value;
        }
        if(key == 'recommendedIMB_CHAIRApplication') {
          newCount.recommendedApplication = value
        }
        if(key == 'notrecommendedIMB_CHAIRApplication') {
          newCount.notrecommendedIMB_CHAIRApplication = value
        }
      if(key == 'pendingIMB_CHAIRApplication') {
          newCount.pendingApplication = value
        }       
      }
    }    
  }
  const sortObject = (obj) => {
    const keys = Object.keys(obj).sort().reverse();
    const res = {};
    keys.forEach(key => {
        res[key] = obj[key];
    });
    return res;
  };

  let countAll = {}
  let countAllDaily = {}
  if(userRole === ROLES.ADMIN || userRole === ROLES.SUPER_ADMIN) {
    for(let [key, value] of Object.entries(countData)) {
      if(key == 'totalApplication') {
        countAll.totalApplication = value;
      }
      if(key == 'approvedYTD') {
        countAll.approvedYTD = value
      }
      if(key == 'currentApproved') {
        countAll.currentApproved = value
      }
      if(key == 'expiredApplication') {
        countAll.expiredApplication = value
      }       
      if(key == 'rejectedApplication') {
        countAll.rejectedApplication = value
      } 
      if(key == 'pendingApplication') {
        countAll.pendingApplication = value
      } 
      if(key == 'incompleteApplication') {
        countAll.incompleteApplication = value
      } 
      if(key == 'section56Exemption') {
        countAll.section56Exemption = value
      } 
      if(key == 'total80iacExemption') {
        countAll.total80iacExemption = value
      } 
    }
    for(let [key, value] of Object.entries(countDailyData)) {
      if(key == 'submittedApplication') {
        countAllDaily.submittedApplication = value;
      }
      if(key == 'approvedApplication') {
        countAllDaily.approvedApplication = value
      }
      if(key == 'incompleteApplication') {
        countAllDaily.incompleteApplication = value
      }
      if(key == 'rejectedApplication') {
        countAllDaily.rejectedApplication = value
      }       
      if(key == 'newApplicationApproved') {
        countAllDaily.newApplicationApproved = value
      } 
      if(key == 'newApplicationIncomplete') {
        countAllDaily.newApplicationIncomplete = value
      } 
      if(key == 'incompleteApplicationApproved') {
        countAllDaily.incompleteApplicationApproved = value
      } 
      if(key == 'incompleteApplicationIncomplete') {
        countAllDaily.incompleteApplicationIncomplete = value
      } 
      if(key == 'pendingApplication') {
        countAllDaily.pendingApplication = value
      } 
    }
  }
 
  useEffect(() => {
    if (getDashboardDetails) {
      getDashbordDetailsApiCAll()
    }
  }, []);
  const getDashbordDetailsApiCAll = () => {
    if(userRole === ROLES.ADMIN || userRole === ROLES.SUPER_ADMIN) {
      getDashboardDetails({
        page: 0,
        pageSize: 10,
        query: {
          searchQuery: user.user.username,
          fromDate: getDate10DaysBefore(),
          toDate: getCurrentDateFormat()
        }
      })
    }
    if(userRole === ROLES.EP_1 || userRole === ROLES.EP_2 || userRole === ROLES.IMB_CHAIR || userRole === ROLES.IMB_MEMBER) {
      getDashboardCountNewCom({
        id: user.user.id,
        page: 0,
        pageSize: 10,
        query: {
          searchQuery: user.user.username,
          fromDate: getDate10DaysBefore(),
          toDate: getCurrentDateFormat()
        }
      })
    }
  }

  const getCurrentDateFormat = () => {
    let today = new Date();
    today = moment(today).format("YYYY-MM-DD")
    return today;

  }
  const getDate10DaysBefore = () => {
    let d = new Date();
    d.setDate(d.getDate() - 10);
    d.setMonth(d.getMonth() + 1 - 0);
    let curr_date = d.getDate();
    let curr_month = d.getMonth();
    let curr_year = d.getFullYear();
    if (curr_month < 10 && curr_date < 10) {
      let parsedDate = `${curr_year}-0${curr_month}-0${curr_date}`;
      return parsedDate;
    } else if (curr_month < 10 && curr_date > 9) {
      let parsedDate = `${curr_year}-0${curr_month}-${curr_date}`;
      return parsedDate;
    } else if (curr_month > 9 && curr_date < 10) {
      let parsedDate = `${curr_year}-${curr_month}-0${curr_date}`;
      return parsedDate;
    } else {
      let parsedDate = `${curr_year}-${curr_month}-${curr_date}`;
      return parsedDate;
    }
  }
  return (
    <div className="dashboard-outer">
      <SectionTitle title={'Dashboard'}>Dashboard</SectionTitle>
      <div className="">
        <div className="row mb-50 mb-bottom-30">
          {
            userRole === ROLES.SUPER_ADMIN &&
            <div className="col-12 col-lg-4">
              <LinkCard
                link='/user-management/userlist'
                iconWidth="78"
                img={icon1}
                title="Manage"
                subTitle="User"
              />
            </div>
          }
          <div className="col-12 col-lg-4">
            <LinkCard
              link='/edit-profile'
              iconWidth="66"
              img={icon2}
              title="Manage"
              subTitle="Profile"
            />
          </div>
          {
            (userRole === ROLES.ADMIN || userRole === ROLES.SUPER_ADMIN)  &&
            <div className="col-12 col-lg-4">
              <LinkCard
                link='/summaryreport/totalapplicationsreport'
                iconWidth="72"
                img={icon3}
                title="View"
                subTitle="Reports"
              />
            </div>
          }
        </div>
      </div>
      <div className="row mb-50 mb-bottom-30">
        <div className="col-12 col-lg-6 mb-bottom-30">
          <StatisticsBlock data={((userRole === ROLES.EP_1 || userRole === ROLES.EP_2 || userRole === ROLES.IMB_MEMBER || userRole === ROLES.IMB_CHAIR)?sortObject(newCount):countAll)} title="Startup Application" />
        </div>
        <div className="col-12 col-lg-6">
          <StatisticsBlock data={((userRole === ROLES.EP_1 || userRole === ROLES.EP_2 || userRole === ROLES.IMB_MEMBER || userRole === ROLES.IMB_CHAIR)?sortObject(newDaily):countAllDaily)} title="Startup Daily Statistics" />
        </div>
      </div>
      <div className="audit-trail-wrap">
        <div className="audit-trail-inner">
          <div className="audit-table-ttl">
            <h3>User Status</h3>
          </div>
          <Table
            dataTable={auditTrailList && auditTrailList[0] && auditTrailList[0].data}
            columns={columns()}
            keyField='createdDate'
            table-bordered={false}
          />
        </div>
      </div>
    </div >
  )
}

const mapStateToProps = (state) => {

  return {
    countData: state.DashboardReducer.dashboardCountDetail,
    countDailyData: state.DashboardReducer.dashboardDailyCountDetail,
    auditTrailList: state.DashboardReducer.dashboardAuditTrail,
    countDataNew: state.DashboardReducer.dashoardCountNew,
    countDataDailyNew: state.DashboardReducer.dashoardDailyCountNew
  }
}

export default connect(mapStateToProps, { getDashboardDetails,getDashboardCountNewCom })(withRouter(Dashboard))
