import React, { Component, useEffect, useState } from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import TextareaField from '../../../components/common/TextareaField';
import Radio from '../../../components/common/InputRadio';
import Checkbox from '../../../components/common/InputCheckbox';
import Button from '../../common/Button';
import { withRouter } from 'react-router-dom';
import { find, get } from 'lodash';
import { connect } from 'react-redux';
import { getNRDCListDetails, getNRDCRecognitionDetails, updateNRDCComment } from '../dependencies/action';
import './style.scss'
import formValidation from '../dependencies/Validation/commentValidation';


const NRDCForm = (props) => {
    const { NRDClistData, getNRDCListDetails, updateNRDCComment, getNRDCRecognitionDetails, match: { params: { rowId: commentId = "", id: exemptionId = "" } = {} }, history } = props;

    const defaultFormData = {
        briefAboutEntity: "",
        commentDate: "",
        dippNumber: "",
        edited: false,
        employment: false,
        entityName: "",
        exemptionId: null,
        id: null,
        improvement: false,
        innovation: false,
        patent: false,
        patentComment: "",
        pspComment: "",
        recommendType: "RECOMMEND",
        service: false,
        serviceComment: "",
        status: "",
        wealth: false
    }

    const [formData, setFormData] = useState(defaultFormData);
    const [imbData, setImbData] = useState({})
    const [error, setError] = useState({})
    const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] = useState(false);

    useEffect(() => {
        if (commentId) {
            const list = get(NRDClistData, 'listNRDC.data') || [];
            if (list && list.length && list.length > 0) {
                const editItem = list.filter(x => x.id === commentId)[0] || {};
                setFormData(d => {
                    return { ...d, ...editItem }
                })
            } else {
                getNRDCListDetails(exemptionId).then(() => {

                });
            }
        }
    }, [NRDClistData])

    useEffect(() => {
        if (getNRDCRecognitionDetails) {
            getNRDCRecognitionDetails(exemptionId).then(({ status = false, data = {} }) => {
                if (status) {
                    setImbData(data)
                }
            })
        }
    }, []);

    const validateInput = () => {
        const {
            briefAboutEntity,
            patent,
            patentComment,
            pspComment,
            service,
            serviceComment
        } = formData;

        const formStatus = formValidation.validateCommentNRDC({
            briefAboutEntity,
            patent,
            patentComment,
            pspComment,
            service,
            serviceComment
        });

        const errors = {
            patentComment: formStatus.patentComment,
            pspComment: formStatus.pspComment,
            serviceComment: formStatus.serviceComment,
        };
        setError(errors);
        return !formStatus.hasErrors;
    }

    const handleInputChange = (e, name) => {
        let { value: val, type, checked } = e.target;
        if (type === 'checkbox') {
            val = checked;
        }
        const value1 = val === 'true' ? true :
            val === 'false' ? false :
                val;
        setFormData((formData) => {
            return { ...formData, [name]: value1 }
        });
    }

    const onBlur = (e, name) => {
        validateInput();
    }

    const handleSubmit = (e, status) => {
        if (!validateInput()) {
            setHasInvalidSubmitOccurred(true)
        } else {
            updateNRDCComment(
                { ...formData, status, exemptionId }
            ).then((data) => {
                const { status } = data;
                if (status) {
                    history.push(`/application/80iac/comment/${exemptionId}`)

                }
            });
        }
    }

    return (
        <div className="comment-edit-container">
            <div className="comment-content">
                <div className="row">
                    <div className="col-md-12">
                        <h3>{'Add New Comment'}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="comment-content-list">
                            <label>{'Entity Name'}</label> <span>{imbData.entityName}</span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="comment-content-list">
                            <label>DIPP No.</label> <span>{imbData.dippNumber}</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="comment-content-list">
                            <label>Brief Writeup</label> <span>{imbData.briefWriteUp}</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="comment-content-list">
                            <label>Awards/recognition received by the entity</label> <span>{imbData.awardsReceivedByEntity && imbData.awardsReceivedByEntity.name}</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="comment-content-list">
                            <label>What is the problem the startup is solving?</label> <span>{imbData.problemStatement}</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="comment-content-list">
                            <label>How does your startup propose to solve this problem?</label> <span>{imbData.problemSolution}</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="comment-content-list">
                            <label>What is the uniqueness of your solutions?</label> <span>{imbData.solutionUniqueness}</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="comment-content-list">
                            <label>How does your startup generate revenue?</label> <span>{imbData.generateRevenue}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="comment-content-list">
                        <TextareaField
                            type="text"
                            name="briefAboutEntity"
                            label="Brief About Entity - 500 words maximum"
                            value={formData.briefAboutEntity}
                            onBlur={onBlur}
                            onChange={handleInputChange}
                            containerClass={"form-group"}
                            placeholder={'500 Characters limit(maximum)'}
                            rows={'5'}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="comment-content-list">
                        <label>Comments on Startup's product/service or process</label>
                        <div className="checkox-container">
                            <Checkbox
                                label='innovation'
                                name='innovation'
                                onChange={handleInputChange}
                                checked={formData.innovation}
                            // hasInvalidSubmitOccurred={true}
                            />
                            <Checkbox
                                label='Improvement and Scalability'
                                name='improvement'
                                onChange={handleInputChange}
                                checked={formData.improvement}
                            // hasInvalidSubmitOccurred={true}
                            />
                            <Checkbox
                                label='Employment generation'
                                name='employment'
                                onChange={handleInputChange}
                                checked={formData.employment}
                            // hasInvalidSubmitOccurred={true}
                            />
                            <Checkbox
                                label='Wealth creation'
                                name='wealth'
                                onChange={handleInputChange}
                                checked={formData.wealth}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="comment-content-list">
                        <TextareaField
                            required
                            type="text"
                            name="pspComment"
                            label="Please Submit your Comments - 500 words maximum"
                            value={formData.pspComment}
                            onChange={handleInputChange}
                            onBlur={onBlur}
                            error={error.pspComment}
                            hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                            containerClass={"form-group"}
                            placeholder={'500 Characters limit(maximum)'}
                            rows={'5'}
                        />
                    </div>
                </div>
            </div>
            <div className="comment-content-list">
                <label>Comments on Patent (if any)</label>
                <div className="row">
                    <div className="col-md-3">
                        <Radio
                            label='Yes'
                            name='patent'
                            value={true}
                            onChange={handleInputChange}
                            checked={formData.patent === true}
                        />
                    </div>
                    <div className="col-md-3">
                        <Radio
                            label='No'
                            name='patent'
                            value={false}
                            onChange={handleInputChange}
                            checked={formData.patent === false}
                        />
                    </div>
                </div>
            </div>
            {formData.patent &&
                <div className="row">
                    <div className="col-md-12">
                        <div className="comment-content-list">
                            <TextareaField
                                required
                                type="text"
                                name="patentComment"
                                label="Please Submit your Patent Comments - 500 words maximum"
                                value={formData.patentComment}
                                onChange={handleInputChange}
                                onBlur={onBlur}
                                error={error.patentComment}
                                containerClass={"form-group"}
                                placeholder={'500 Characters limit(maximum)'}
                                rows={'5'}
                                hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                            />
                        </div>
                    </div>
                </div>
            }
            <div className="comment-content-list">
                <label>Comments on Similar Services (if any)</label>
                <div className="row">
                    <div className="col-md-3">
                        <Radio
                            label='Yes'
                            name='service'
                            value={true}
                            onChange={handleInputChange}
                            checked={formData.service === true}
                        />
                    </div>
                    <div className="col-md-3">
                        <Radio
                            label='No'
                            name='service'
                            value={false}
                            onChange={handleInputChange}
                            checked={formData.service === false}
                        />
                    </div>
                </div>
            </div>
            {formData.service &&
                <div className="row">
                    <div className="col-md-12">
                        <div className="comment-content-list">
                            <TextareaField
                                required
                                type="text"
                                name="serviceComment"
                                label="Please Submit your Service Comments - 500 words maximum"
                                value={formData.serviceComment}
                                onChange={handleInputChange}
                                containerClass={"form-group"}
                                error={error.serviceComment}
                                hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                placeholder={'500 Characters limit(maximum)'}
                                rows={'5'}
                            /> </div> </div></div>
            }
            <div className="comment-content-list">
                <label>would you like to</label>
                <div className="row">
                    <div className="col-md-3">
                        <Radio
                            label='Recommend'
                            name='recommendType'
                            value='RECOMMEND'
                            onChange={handleInputChange}
                            checked={formData.recommendType == 'RECOMMEND'}
                        />
                    </div>
                    <div className="col-md-3">
                        <Radio
                            label='Not Recommend'
                            name='recommendType'
                            value='NOT_RECOMMEND'
                            onChange={handleInputChange}
                            checked={formData.recommendType == 'NOT_RECOMMEND'}
                        />
                    </div>
                    <div className="col-md-3">
                        <Radio
                            label='Not Found Suitable'
                            name='recommendType'
                            value='NOT_FOUND_SUITABLE'
                            onChange={handleInputChange}
                            checked={formData.recommendType == 'NOT_FOUND_SUITABLE'}
                        />
                    </div>
                </div>
            </div>

            {formData && formData.hasOwnProperty('status') && formData.status !== "SUBMIT" && (


                <div className="button-group m-20">
                    <Button
                        className="bttn blue"
                        type='button'
                        onClick={e => handleSubmit(e, 'SAVE_DRAFT')}
                        text="Save"
                    />
                    <Button
                        className="bttn blue"
                        type='button'
                        onClick={e => handleSubmit(e, 'SUBMIT')}
                        text="Submit"
                    />

                </div>
            )
            }

        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        recognitionData: state.Applicatio80IACReducer.NRDCRecognition,
        NRDClistData: state.Applicatio80IACReducer.listNRDC,
    }
}

export default connect(mapStateToProps, { getNRDCListDetails, getNRDCRecognitionDetails, updateNRDCComment })(withRouter(NRDCForm));
