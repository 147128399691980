import React, { useState, useEffect } from 'react';
import './index.scss';
import refreshIcon from '../../../assests/images/refresh-icon-blue.png';

const Refresh = (props) => {
    const { onClickRefresh, className, alignLeft } = props;
    const onRefresh = (e, value) => {
        e.preventDefault();
        onClickRefresh();
    }

    return (
        <div className={`refresh-outer ${alignLeft && ('float-left')}`}>
            <button
                className={`bttn-link refresh-btn ${className}`}
                onClick={(event) => onRefresh(event)}
            >
                <img width="15" src={refreshIcon} />
                &nbsp;
                {'Refresh'}
            </button>
        </div>
    );
}

export default Refresh;