import React from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from '../../../../constants/ApplicationUrl';
import { SUPPORTING_DOCUMENTS, headerSortingStyle } from '../../../../constants';
import { ROLES } from '../../../../constants/Roles';
import { storage } from '../../../../dependencies/store/storage';



export const rowStyle2 = (row, rowIndex) => {
    const style = {};
    if (row.nonProfitCompany) {
        style.backgroundColor = '#a3ca57';
    } else if (row.isEdited) {
        style.backgroundColor = '#bffedf';
    } else if (row.natureOfEntity === "Registered Partnership") {
        style.backgroundColor = '#ffdb99';
    }

    return style;
};

const columns = ({
    clickOnAction,
    pageNo,
    size
}) => {
    const userRole = storage.getUserRole();

    return [
        {
            dataField: '',
            text: 'Sr.No',
            formatter: (cell, row, rowIndex) => {
                return (pageNo * size) + (rowIndex + 1);
            }
        },
        {
            dataField: 'referenceNumber',
            text: 'Refrence No',
            sort: false,
            headerSortingStyle,
            formatter: (cell, row) => {
                const reference_no = row.referenceNumber;
                if(row.swsId){
                    return (
                        <span>{reference_no} <sup style={{color: 'rgb(197,90,17)'}}>SWS</sup></span>
                    )
                }else{
                    return reference_no;
                }
                
            }
        },
        {
            dataField:'swsId',
            text: 'Sws Id',
            sort: false
        }, 
        {
            dataField: 'certificateNumber',
            text: 'Certificate No',
            sort: false,
            headerSortingStyle
        },
        {
            dataField: 'applicationDate',
            text: 'Application Date',
            sort: true,
            headerSortingStyle
        },
        // {
        //     dataField: 'Options',
        //     text: 'Options',
        //     formatter: (cell, row) => {
        //         const applicationId = row.applicationId
        //         return (
        //             <Link to={`/application/startup/${applicationId}`}>
        //                 View
        //       </Link>
        //         )
        //     }
        // },
        {
            dataField: 'action',
            text: 'Action',
            hidden: userRole !== ROLES.SUPER_ADMIN && userRole !== ROLES.ADMIN && userRole !== ROLES.USER,
            formatter: (cell, row) => {
                const applicationId = row.applicationId
                return (
                    <div className="IAS-Actions-list">
                        <ul>

                            <li>
                                <Link to={`/application/startup/${applicationId}`}>
                                    View
                                </Link>
                            </li>
                            {
                                userRole !== ROLES.USER ?
                                    <div>
                                        <li><a href="javascript:void(0)" onClick={() => clickOnAction(applicationId)}>View Audit Trail</a></li>

                                        {(userRole === ROLES.ADMIN || userRole === ROLES.SUPER_ADMIN) ?
                                            <li className="edit-color"><Link to={`/application/startup/edit/${applicationId}`} target="_blank">
                                                Edit
                             </Link></li>
                                            :
                                            ''
                                        }
                                    </div>
                                    : ''
                            }


                        </ul>

                    </div>

                )

            }
        },
        {
            dataField: 'incorporationNumber',
            text: 'Registration No',
            sort: false,
            headerSortingStyle
        }, {
            dataField: 'nameOfEntity',
            text: 'Name Of Entity',
            sort: false,
            headerSortingStyle
        }, {
            dataField: 'statusLabel',
            text: 'Application Status'
        }, {
            dataField: 'recognitionCertificateStatusLabel',
            text: 'Recognition Status'
        }, {
            dataField: 'eligibilityCertificateStatusLabel',
            text: 'Form 80 IAC Status'
        }, {
            dataField: 'section56CommonStatusLabel',
            text: 'Form 56 Status',
        }, {
            dataField: 'incorporationDate',
            text: 'Registration Date',

        }, {
            dataField: 'briefWriteup',
            text: 'Brief Writeup',
        }, {
            dataField: 'additionalDocument',
            text: 'Supporting Document',
            formatter: (cell, row, rowIndex) => {
                if (cell !== undefined) {
                    return cell.map((element, index) => {
                        let link = "";
                        if (element && element.file) {
                            link = `${API_URL()}${element.file.publicUrl}`
                        } else if (element && element.url && element.name === 'WEBSITE_LINK') {
                            link = `${element.url.indexOf('http') == -1 ? 'http://' : ''}${element.url}`
                        } else if (element && element.url) {
                            link = element.url
                        }
                        return link ? <div key={index}><a href={link} target="_blank"> {SUPPORTING_DOCUMENTS[element.name] || ""} </a> </div> : null;
                    })
                }

            }
        }, {
            dataField: 'mobileNumber',
            text: 'Mobile No',
            sort: false,
            headerSortingStyle
        }, {
            dataField: 'emailId',
            text: 'Email Id',
            sort: false,
            headerSortingStyle
        },
        {
            dataField: 'panNumber',
            text: 'PAN Number',
            sort: false,
            headerSortingStyle
        }       

    ];
}


{/* <TableHeaderColumn width='150px' dataField='supportingDocument' dataFormat={this.supportingDocumentFormatter}>Supporting Document</TableHeaderColumn> */ }

export default columns;