import React from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from '../../../constants/ApplicationUrl';
import { SUPPORTING_DOCUMENTS } from '../../../constants';

const columns = ({
    pageNo,
    size
}) => {

    return [
        {
            text: 'S.No.',
            formatter: (cell, row, rowIndex) => {
                return (pageNo * size) + (rowIndex + 1);
            }
        }, {
            dataField: 'certificateNumber',
            text: 'Certificate No',
            sort : true
        }, {
            dataField: 'incorporationNumber',
            text: 'Registration No',
            sort : true

        }, {
            dataField: 'incorporationDate',
            text: 'Registration Date',
            sort : true

        }, {
            dataField: 'applicationDate',
            text: 'Application Date',
            sort : true
        }, {
            dataField: 'nameOfEntity',
            text: 'Name Of Entity',
            sort : true
        }, {
            dataField: 'mobileNumber',
            text: 'Mobile No',
            sort : true
        }, {
            dataField: 'recognitionCertificateStatusLabel',
            text: 'COR',
        },
        {
            dataField: 'eightyIACFormStatusLabel',
            text: 'COE',
        }
    ];
}

export default columns;