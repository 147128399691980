import React, { useState, useEffect } from 'react';
import './index.scss'
import AssignedIcon from '../../../assests/images/Assigned-icon.png';

const AssignedApplication = (props) => {

  const { onClickDownload,onClickAssign, fileName = "report", className, alignLeft } = props;

  const onDownload = (e) => {
    e.preventDefault();
    onClickAssign()


  }

  return (
    <div className={`assigned-outer ${alignLeft && ('float-left')}`}>
      <button
        className={`assigned-container ${className}`}
        onClick={(event) => onDownload(event)}
      >
        <img src={AssignedIcon} width="21" />
        {'Assigned Application'}
      </button>
    </div>
  );

}


export default AssignedApplication;