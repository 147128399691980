import { DashboardServices } from './service';
import {
    DASHBOARD_COUNT_DETAILS,
    DASHBOARD_DAILY_COUNT_DETAILS,
    DASHBOARD_AUDIT_TRAIL,
    DASHBORD_COUNT_NEW,
    DASHBORD_DAILY_COUNT_NEW
} from '../../../constants/actionConstants';
import { isLoading } from '../../../constants/actionConstants';

// export const getDashboardDetails = (params) => dispatch => {
//     dispatch(isLoading(true))
//     return bindDashboard(params).then((data) => {
        
//         const { dashboardCountDetails: { data: dashboardCount = [] }, dashboardDailyCountDetails: { data: dashboardDailyCount = [] }, auditTrailDetails={} } = data
//         dispatch({
//             type: DASHBOARD_COUNT_DETAILS,
//             payload: dashboardCount
//         });
//         dispatch({
//             type: DASHBOARD_DAILY_COUNT_DETAILS,
//             payload: dashboardDailyCount
//         });
//         dispatch({
//             type: DASHBOARD_AUDIT_TRAIL,
//             payload: auditTrailDetails
//         });
//         dispatch(isLoading(false));
//     });
// }

export const getDashboardDetails = (params) => dispatch => {
    dispatch(isLoading(true));

    Promise.all([
        DashboardServices.getDashboardCount(),
        DashboardServices.getDashboardDailyCount(),
        DashboardServices.getAuditTrail(params)
    ])
    .then(([dashboardCountDetails, dashboardDailyCountDetails, auditTrailDetails]) => {
        dispatch({
            type: DASHBOARD_COUNT_DETAILS,
            payload: dashboardCountDetails.data || []
        });
        dispatch({
            type: DASHBOARD_DAILY_COUNT_DETAILS,
            payload: dashboardDailyCountDetails.data || []
        });
        dispatch({
            type: DASHBOARD_AUDIT_TRAIL,
            payload: auditTrailDetails.data || {}
        });
    })
    .catch(error => {
        // Handle error here
    })
    .finally(() => {
        dispatch(isLoading(false));
    });
};



async function bindDashboard(params) {
    const dashboardCountDetails = await DashboardServices.getDashboardCount().catch(e => {
        return { data: [], status: false, message: e };
    });
    const dashboardDailyCountDetails = await DashboardServices.getDashboardDailyCount().catch(e => {
        return { status: false, message: e, data: [] }
    });
    const auditTrailDetails = await DashboardServices.getAuditTrail(params).catch(e => {
        return { status: false, message: e, data: [] }
    });

    return { dashboardCountDetails, dashboardDailyCountDetails, auditTrailDetails }
}

export function getDashboardCountNewCom(params) {
    return async function (dispatch) {
        dispatch(isLoading(true));
        let count = await DashboardServices.getDashboardCountNew(params).catch(e => {
            return { status: false, message: e, data: [] }
        });
        dispatch({
            type: DASHBORD_COUNT_NEW,
            payload: count
        });

        let countDaily = await DashboardServices.getDashboardDailyCountnew(params).catch(e => {
            return { status: false, message: e, data: [] }
        });
        dispatch({
            type: DASHBORD_DAILY_COUNT_NEW,
            payload: countDaily
        });
        const auditTrailDetails = await DashboardServices.getAuditTrail(params).catch(e => {
            return { status: false, message: e, data: [] }
        });
        dispatch({
            type: DASHBOARD_AUDIT_TRAIL,
            payload: auditTrailDetails
        });
        dispatch(isLoading(false));

    }
}
export function getAuditTrailList(params) {
    return async function (dispatch) {
        dispatch(isLoading(true));

        let dashboardDailyCountDetails = await DashboardServices.getAuditTrail(params).catch(e => {
            return { status: false, message: e, data: [] }
        });
        
        dispatch({
            type: DASHBOARD_AUDIT_TRAIL,
            payload: dashboardDailyCountDetails
        });
        dispatch(isLoading(false));
    }
}