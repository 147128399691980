import moment from 'moment';
import React, { useState, useEffect } from 'react';
import Button from '../../common/Button';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import AlertMessage from '../../common/AlertMessage';
import InputField from '../../common/InputField';
import Captcha from '../Captcha'
import { AlertType } from '../../../constants';
import { requestForgetPassword } from '../common/dependencies/action';
import FormValidator from '../common/dependencies/FormValidator';
import refreshIconBlue from '../../../assests/images/refresh-icon-blue.png';
import refreshIconwhite from '../../../assests/images/refresh-icon-white.png';
import backIcon from '../../../assests/images/back-icon.png';
import './scss/index.scss';

const ForgotPassword = (props) => {
  const defaultFormData = {
    emailOrUsername: '',
    captcha: '',
  }
  const [formData, setFormData] = useState(defaultFormData);
  const [error, setError] = useState({});
  const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] = useState(false);
  const [captchaText, setCaptchaText] = useState("");
  const [isRefresh, setIsRefresh] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState(AlertType.SUCCESS);

  useEffect(() => {
    if (!isRefresh) {
      setIsRefresh(true);
      setFormData(formData => {
        return { ...formData, captcha: '' }
      });
    }
  }, [isRefresh]);

  const validateInput = () => {
    const {
      emailOrUsername,
      captcha
    } = formData;
    const formStatus = FormValidator.validateForgetPasswordForm({
      emailOrUsername,
      captcha
    });
    const errors = {
      emailOrUsername: formStatus.emailOrUsername,
      captcha: formStatus.captcha,
    };
    setError(errors);
    return !formStatus.hasErrors;
  };

  const handleInputChange = (e, name) => {
    const { value, } = e.target;
    setFormData((formData) => {
      return { ...formData, [name]: value }
    });
  }

  const getCaptcha = (text) => {
    setCaptchaText(text);
  }


  const doRefresh = () => {
    setIsRefresh(false);
  };

  const doReset = () => {
    setFormData(defaultFormData);
    setError({})
    setCaptchaText('');
    doRefresh();
  }

  const onBlur = (e, name) => {
    validateInput();
  }

  const isEmail = (value) => {
    let emailRegex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (emailRegex.test(value)) {
      return true;
    }
    return false;
  }


  const onClose = () => {
    setAlertMessage('')
  }

  const onSubmit = (e) => {
    e.preventDefault();
    onClose();
    const { captcha } = formData;
    const { requestForgetPassword } = props;
    if (!validateInput()) {
      setHasInvalidSubmitOccurred(true);
    } else if (captchaText !== captcha) {
      setError((error) => {
        return { ...error, captcha: 'Please provide valid captcha' }
      });
      doRefresh();
    } else {
      const { emailOrUsername } = formData;
      const data = {};
      // const dob = moment(userIdOrDob, ['DD-MM-YYYY', 'DD/MM/YYYY']).format('YYYY-MM-DD');
      if (isEmail(emailOrUsername)) {
        data['userEmailId'] = emailOrUsername
      } else {
        data['username'] = emailOrUsername;
      }
      requestForgetPassword({ ...data })
        .then((data) => {
          const { status = false, message } = data || {};
          if (!status) {
            doRefresh();
            setAlertType(AlertType.DANGER);
            setAlertMessage(message);
          } else {
            doReset();
          }
          setHasInvalidSubmitOccurred(false);
        });
    }
  }

  return (
    <div className="forget-form-wrap basic-dialog">
      <div className="dialog-header">
        <Link className="back-to-login" to={'/login'}>
          <img src={backIcon} />
          Back
          </Link>
        <h2>Forgot Password</h2>
      </div>
      <div className="alert-box">
        <AlertMessage
          type={alertType}
          allowClose={true}
          message={alertMessage}
          onClose={onClose}
        />
      </div>
      <form onSubmit={e => onSubmit(e)}>
        {/* <InputField
          required
          type="text"
          name="emailId"
          label="Email/Username"
          value={formData.emailId}
          onChange={handleInputChange}
          error={error.emailId}
          containerClass={"form-group"}
          onBlur={onBlur}
          hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
        /> */}
        <InputField
          required
          autofocus={false}
          type="text"
          name="emailOrUsername"
          value={formData.emailOrUsername}
          onChange={handleInputChange}
          error={error.emailOrUsername}
          label={"Email/Username"}
          containerClass={"form-group"}
          onBlur={onBlur}
          placeholder=''
          autoComplete="off"
          hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
        />

        <div className="captcha-outer">
        {
          isRefresh &&
          <Captcha
            getCaptcha={getCaptcha}
          />
        }
        
        <Button
          text=""
          className='bttn-icon'
          onClick={doRefresh}
          disabled={!isRefresh}
          icon={(<img src={refreshIconBlue} />)}
        />
        </div>
        <InputField
          required
          type="text"
          name="captcha"
          value={formData.captcha}
          onChange={handleInputChange}
          error={error.captcha}
          label={"Enter Above Characters (Case Sensitive)"}
          containerClass={"form-group"}
          onBlur={onBlur}
          autoComplete="off"
          hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
        />

        <div className="dialog-footer">
          <Button
            type="submit"
            text="Submit"
            className="bttn"
          />
          <Button
            type='button'
            onClick={doReset}
            text="Reset"
            className="bttn blue"
            icon={(<img src={refreshIconwhite} />)}
          />
        </div>
      </form>
    </div>
  );
}


export default connect(null, { requestForgetPassword })(ForgotPassword);

