import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { saveGlossaryAction, fetchGlossaryByID } from '../dependencies/action';
import FormValidator from '../dependencies/FormValidator';
import GlobalDropDown from '../../common/GlobalDropDown/index';
import InputField from '../../common/InputField';

import { glossaryOptions } from '../dependencies/constant';

import Button from '../../common/Button';
import SectionTitle from '../../common/sectionTitle/sectionTitle';

const AddEditGlossary = (props) => {
    const { fetchGlossaryByID, saveGlossaryAction, match: { params: { id: exemptionId } = {} } = {}, history } = props;

    const [formData, setFormData] = useState({
        id: null,
        name: '',
        description: '',
        defaultGlossary: false,
    });
    const [error, setError] = useState({});
    const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] = useState(false);

    useEffect(() => {
        if (exemptionId) {
            fetchGlossaryByID(exemptionId).then((response) => {
                const { data, status } = response;
                if (status) {
                    setFormData(d => {
                        return { ...d, ...data }
                    })
                }
            })
        }
    }, [])

    const validateInput = () => {
        const {
            name,
            description,
        } = formData;

        const formStatus = FormValidator.validateGlossary({
            name,
            description,
        });
        const errors = {
            name: formStatus.name,
            description: formStatus.description,
        };

        setError(errors);
        return !formStatus.hasErrors;
    };

    const handleInputChange = (e, name) => {
        let { value, } = e.target;
        if (name === 'defaultGlossary') {
            value = value === 'true' ? true : false;
        }
        setFormData((formData) => {
            return { ...formData, [name]: value }
        });
    }

    const onBlur = (e, name) => {
        validateInput();
    }

    const handleSubmit = async () => {
        if (!validateInput()) {
            setHasInvalidSubmitOccurred(true)
        } else {
            saveGlossaryAction({
                ...formData,
                glossaryDefault: formData.defaultGlossary
            }).then(({ status = false }) => {
                if (status) {
                    history.push('/glossary')
                }
            })
        }
    }

    const handleReset = () => {
        setFormData({
            id: '',
            name: '',
            description: '',
            defaultGlossary: false,
        });
        setError({});
    }


    const defaultGlossaryValue = formData.defaultGlossary === true ? 'true' : 'false';
    return (
        <div className="row">

            <div className="report-table col-md-6">
                <SectionTitle>{exemptionId ? 'Edit Glossary' : 'Add New Glossary'}</SectionTitle>

                <InputField
                    required
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    error={error.name}
                    label={"Title"}
                    containerClass={"form-group"}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                />
                <InputField
                    required
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    error={error.description}
                    label={"Description"}
                    containerClass={"form-group"}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                />
                <GlobalDropDown
                    label='Default'
                    selectTagStyle="form-control"
                    onChange={handleInputChange}
                    options={glossaryOptions}
                    defaultOptionDisable={true}
                    name={'defaultGlossary'}
                    containerClass={"form-group"}
                    value={defaultGlossaryValue}
                />

                <div className="button-group mt-30">
                    <Button
                        className="bttn normal"
                        type='button'
                        onClick={handleSubmit}
                        text="Submit"
                    />
                    {!exemptionId &&

                        <Button
                            className="bttn blue normal"
                            type='button'
                            onClick={handleReset}
                            text="Reset"
                        />
                    }
                </div>

            </div>
        </div>)
}


const mapStateToProps = (state) => {
    return {
        glossaryListID: state.GlossaryReducer.glossaryListID,
    }
}

export default connect(mapStateToProps, { saveGlossaryAction, fetchGlossaryByID })(withRouter(AddEditGlossary));