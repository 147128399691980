import React from 'react';
import moment from 'moment';
// import { Link } from 'react-router-dom';
// import { API_URL } from '../../../../constants/ApplicationUrl';
// import { SUPPORTING_DOCUMENTS, headerSortingStyle } from '../../../../constants';
// import { ROLES } from '../../../../constants/Roles';
// import { storage } from '../../../../dependencies/store/storage';
import { unEscapeRegHtml } from '../../../../dependencies/utils/helper';


const columns = [
    {
        dataField: 'id',
        text: 'Sr.No',
        formatter: (cell, row, rowIndex) => {
            return rowIndex + 1
        }
    }, {
        dataField: 'incompleteDate',
        text: 'Incomplete Date',
        formatter : (cell,row,rowIndex) =>{
              return  moment(cell).format('DD-MM-YYYY');
            
        }
    },
    {
        dataField: 'incompleteReasons',
        text: 'Incomplete Reasons',
        formatter: (cell, row, rowIndex) => {
            if (cell && cell.length > 0 && cell[0] !== null) {
                console.log('okey',cell[0])
                return  cell.map((element, index) => {
                         
                    if (element.reason == 'Others- If any other, please specify.') {
                        return <div>  {index + 1}. {unEscapeRegHtml(row.incompleteDescription)}</div>        
                    } else {
                        return <div>  {index + 1}. {unEscapeRegHtml(element.reason)}</div>        
                    }
                })        
            }else {
                return row.incompleteDescription
            }
        }
    }
    
];


export default columns;
