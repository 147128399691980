import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, } from 'react-router-dom';
import Header from '../common/header/header';
import './HomePage.scss';
import SideBar from '../common/sideBar';
import { desktopRoutes as routes } from '../../dependencies/route';
import { routeWithRoles } from '../../constants/Roles';
import { storage } from '../../dependencies/store/storage';
import { ROLES } from '../../constants/Roles';
import { updateUserDetail } from '../AdminDetails/common/dependencies/action';

const HomePage = (props) => {
  const { updateUserDetail } = props;
  const userRole = storage.getUserRole();
  const [cssClass, setCssClass] = useState('')
  useEffect(() => {
    updateUserDetail().then((data) => {
    })
  }, []);
  const getCssClass = (data) => {
    setCssClass(data)
  }
  const getRoutes = () => {
    return (
      <Switch>
        {routes.map((route, index) => {
          return userRole && routeWithRoles[userRole] && route && (routeWithRoles[userRole].indexOf(route.name) > -1) &&
            (
              <Route key={index.toString()} {...route} />
            )

        })}
        {/* <Redirect from="/index" to="/dashboard" /> */}
        {
          [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.USER].indexOf(userRole) > -1 && (
            <Redirect from="/" to="/dashboard" />
          )
        }
        {
          [ROLES.NRDC_ADMIN, ROLES.TIFAC_ADMIN].indexOf(userRole) > -1 && (
            <Redirect from="/" to="/application/80iac" />
          )
        }
        {
          [ROLES.SEC_56_ADMIN].indexOf(userRole) > -1 && (
            <Redirect from="/" to="/application/section56" />
          )
        }
        {
          [ROLES.LOGO_ADMIN].indexOf(userRole) > -1 && (
            <Redirect from="/" to="/startup-logo" />
          )
        }
        {

          [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.USER, ROLES.TIFAC_ADMIN, ROLES.NRDC_ADMIN, ROLES.LOGO_ADMIN, ROLES.SEC_56_ADMIN].indexOf(userRole) === -1 && (
            <Redirect from="/" to="/auth/login" />
          )
        }
      </Switch>
    )
  }
  return (
    <div className='Dashboard'>
      <Header setCssClass={getCssClass} classData={cssClass} />
      <div className="dashboard-content clearfix">
        <div className={"sidebar-outer " + cssClass}>
          <SideBar />
        </div>
        <div className="dashboard-right-content">
          {getRoutes()}
        </div>
      </div>
    </div>
  );
}
export default connect(null, { updateUserDetail })(HomePage);

