import React, { useState } from 'react';
import InputField from '../../../common/InputField';
import { cloneDeep } from 'lodash';
import InlineError from '../../../common/inlineError';

const IPRSColumns = (params) => {
    const { onChange, error } = params;
    const handleInputChange = (e, flag, index, row) => {
        const value = e.target && e.target.value;
        const editColumn = { ...row };
        const checked = e.target && e.target.checked;
        if (flag === "applied") {
            editColumn['applied'] = checked;
            editColumn['registered'] = !checked
        } else if (flag === 'registered') {
            editColumn['applied'] = !checked;
            editColumn['registered'] = checked
        } else if (flag === 'applicationNumber') {
            editColumn['applicationNumber'] = value;
        }

        if (onChange) {
            onChange(editColumn, index)
        }
    }

    return [
        {
            dataField: 'iprTitle',
            text: 'S No',
        }, {
            dataField: 'applied',
            text: 'Applied For',
            formatter: (cell, row, rowIndex) => {
                return (
                    <input
                        type="radio"
                        name={'iprs_' + rowIndex}
                        value={cell}
                        onChange={e => handleInputChange(e, 'applied', rowIndex, row)}
                        checked={cell}
                    />
                )
            }
        }, {
            dataField: 'registered',
            text: 'Registered/Granted',
            formatter: (cell, row, rowIndex) => {
                return (
                    <input
                        type="radio"
                        name={'iprs_' + rowIndex}
                        value={cell}
                        onChange={e => handleInputChange(e, 'registered', rowIndex, row)}
                        checked={cell}
                    />
                )
            }
        }, {
            dataField: 'applicationNumber',
            text: 'Application No.',
            formatter: (cell, row, rowIndex) => {
                // debugger
                return (
                    <div>
                        <input
                            type="text"
                            name={'applicationNumber'}
                            value={cell}
                            onBlur={e => handleInputChange(e, 'applicationNumber', rowIndex, row)}
                        />
                        {
                           row.error && <InlineError message={row.error} />
                        }
                    </div>
                )
            }
        },
        {
            dataField: 'error',
            text: '',
            hidden: true
        }
    ];
}

export default IPRSColumns;