import api from '../../../dependencies/utils/Api';
import {
    FETCH_TOTAL_REPORT_URL,
    FETCH_ENTITY_TYPE_APPLICATION_REPORT_URL,
    FETCH_RECOGNITION_REPORT_URL,
    FETCH_TAX_BENEFIT_REPORT_URL
} from "../../../constants/ApplicationUrl";



export const ReportServices = {


    fetchEntityTypeApplicationReport(id) {
        return api.GET(`${FETCH_ENTITY_TYPE_APPLICATION_REPORT_URL}`).
            then((response) => {
                return Promise.resolve(response);
            }).catch(e => {
                return Promise.reject(e);
            });
    },

    fetchRecognisedReport(id) {
        return api.GET(`${FETCH_RECOGNITION_REPORT_URL}`).
            then((response) => {
                return Promise.resolve(response);
            }).catch(e => {
                return Promise.reject(e);
            });
    },

    fetchTaxBenefitReport(id) {
        return api.GET(`${FETCH_TAX_BENEFIT_REPORT_URL}`).
            then((response) => {
                return Promise.resolve(response);
            }).catch(e => {
                return Promise.reject(e);
            });
    },

    fetchTotalReport(id) {
        return api.GET(`${FETCH_TOTAL_REPORT_URL}`).
            then((response) => {
                return response;
            });
    },
    downloadTotalReport() {
        return api.DOWNLOADGET(`${FETCH_TOTAL_REPORT_URL}`).
            then((response) => {
                return response;
            });
    },
    downloadEntityTypeApplicationReport() {
        return api.DOWNLOADGET(`${FETCH_ENTITY_TYPE_APPLICATION_REPORT_URL}`).
            then((response) => {
                return response;
            });
    },
    downloadRecognisedReport() {
        return api.DOWNLOADGET(`${FETCH_RECOGNITION_REPORT_URL}`).
            then((response) => {
                return response;
            });
    },
    downloadTaxBenefitReport() {
        return api.DOWNLOADGET(`${FETCH_TAX_BENEFIT_REPORT_URL}`).
            then((response) => {
                return response;
            });
    }
    
}