import { get, isEmpty } from 'lodash';
import { CancellationServices } from './service';

import { isLoading } from '../../../constants/actionConstants';
import { toast } from "react-toastify";


export const updateCancelCertificateAction = (params) => async (dispatch) => {
    dispatch(isLoading(true))
        let response = await CancellationServices.updateCancelCertificateServices(params).catch(e => {
            return { status: false, message: "" }
        });
        if (response) {
            const { status, message } = response;
            if (status) {
                toast.success(message);
                dispatch(isLoading(false))
            } else {
                toast.error(message);
                dispatch(isLoading(false))
            }
            return { status }
        }
}

