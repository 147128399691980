import React, { Component } from 'react';
import HomePage from './components/homepage';
import Auth from './components/Auth';
import { BrowserRouter, Redirect, Route, Switch, } from 'react-router-dom';
import { desktopRoutes as routes } from './dependencies/route';
import httpService from './axios-interceptor';
httpService.setupInterceptors();

const AppRoutes = (props) => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/auth" component={Auth} />
                <Route path="/" component={HomePage} />
                <Redirect exact from="/" to="/login" />
                <Redirect from="/index" to="/login" />
            </Switch>
        </BrowserRouter>
    );
}


export default AppRoutes;
