import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFetchRecognisedReport, downloadRecognisedReport } from '../dependencies/action';
import recognisedColumns from '../dependencies/columns/recognisedColumns';
import CustomTable from '../../common/customTable';
import moment from 'moment';
import Download from '../../common/Download';
import '../dependencies/style/index.scss'
import SectionTitle from '../../common/sectionTitle/sectionTitle';

class RecognisedReport extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getFetchRecognisedReport();
    }

    downloadReport = () => {
        const { downloadRecognisedReport } = this.props;
        if (downloadRecognisedReport) {
            return downloadRecognisedReport().then((data) => {
                return data;
            })
        }
    }

    render() {
        const { downloadReport } = this;
        const currentDate = moment(new Date()).format('YYYYMMDD');
        const data = this.props.recognisedReportData && this.props.recognisedReportData;
        const info = data && data.data
        return (
            <div className="row summary-report-container">

                <div className="col-sm-12">
                    
                    <SectionTitle breadCrumb title="Summary Report > Number of applications where DIPP Recognition Certificate">Number of applications where DIPP Recognition Certificate</SectionTitle> 
                    
                    <Download
                        fileName={`Report_for_Number_of_applications_where_DIPP Recognition_Certificate_${currentDate}`}
                        onClickDownload={downloadReport}
                        className="bttn-link"
                    />
                    <CustomTable
                        dataTable={info}
                        columns={recognisedColumns()}
                    />
                </div>
            </div>)
    }
}

const mapStateToProps = (state) => {
    return {
        recognisedReportData: state.ReportReducer.recognisedReport,
    }
}

export default connect(mapStateToProps, { getFetchRecognisedReport, downloadRecognisedReport })(withRouter(RecognisedReport));