import { get, isEmpty } from 'lodash';
import { authServices } from './service';
import { toast } from "react-toastify";
import { storage } from '../../../../dependencies/store/storage'
import {
    SET_USER,
    CLEAR_USER,
    SET_LOGGED_IN,
    isLoading
} from '../../../../constants/actionConstants';
import { ROLES } from '../../../../constants/Roles';

export const login = (email, password) => async (dispatch) => {
    dispatch(isLoading(true));
    const response = await authServices.doLogin({ username: email, password: password })
        .catch((error) => {
            const { message = "Server Error" } = error;
            storage.clear();
            return { status: false, message: 'Server Error' };

        });
    if (response) {
        const { user, message, status } = response;
        if (status && !isEmpty(user)) {
            const { user: { role } } = user;
            if (role && ROLES[role]) {
                storage.setUser(user);
                dispatch({
                    type: SET_USER,
                    payload: user.user
                });
                dispatch({
                    type: SET_LOGGED_IN,
                    payload: true
                });
                toast.success(message);
            } else {}
        }
        dispatch(isLoading(false));
        return { status, message };
    }
    dispatch(isLoading(false));
};

export const checkLogin = () => async (dispatch, state) => {
    dispatch(isLoading(true));
    const storeData = storage.getUser() || {};
    const currentUser = storeData.user;
    if (!isEmpty(storeData)) {
        const response = await authServices.checkLogin()
            .catch((e) => {
                storage.clear();
                dispatch({
                    type: SET_LOGGED_IN,
                    payload: false,
                });
                dispatch({
                    type: CLEAR_USER,
                });
                return { status: false };
            });
        if (response) {
            const { status = false } = response;
            dispatch({
                type: SET_USER,
                payload: currentUser,
            });
            dispatch({
                type: SET_LOGGED_IN,
                payload: true,
            });
            dispatch(isLoading(false));
            return { status };
        }
    } else {
        dispatch(isLoading(false));

        return Promise.reject(false);
    }
};

export const requestForgetPassword = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    const response = await authServices.doForgetRequest(params)
        .catch(e => {
            return { status: false, message: 'Server Error' };
        });
    if (response) {
        const { message, status } = response;
        if (status) {
            toast.success(message);
        }
        dispatch(isLoading(false));
        return { status, message }
    }
}

export const resetPassword = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    const response = await authServices.doResetPassword(params).catch(e => {
        return { status: false, message: 'Server Error' };
    });
    if (response) {
        const { message, status } = response;
        if (status) {
            dispatch(isLoading(false));
            toast.success(message);
            return { status, message }
        }
        dispatch(isLoading(false));
        return { status, message }

    }
}

export const logout = (goToLogin) => async (dispatch, getState) => {
    dispatch(isLoading(true));
    dispatch({
        type: CLEAR_USER,
    });
    dispatch({
        type: SET_LOGGED_IN,
        payload: false
    });
    const response = await authServices.doLogout().catch(e => {
        //shoe error message
        return {}
    })
    if (response) {
        storage.clear();
        dispatch(isLoading(false));
        return response;
    }
};
