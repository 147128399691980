import { get, isEmpty } from 'lodash';
import { NotificationDucumentsServices } from './service';
import {
    NOTIFICATION_DOCUMENTS_LIST,
    NOTIFICATION_DOCUMENTS_LIST_BY_ID
} from '../../../constants/actionConstants';
import { isLoading } from '../../../constants/actionConstants';
import { toast } from "react-toastify";



export const fetchNotificationDocumentsList = (page, pageSize) => async (dispatch) => {
    dispatch(isLoading(true))
    return await NotificationDucumentsServices.fetchList(page, pageSize).then((response) => {
        const { data = {}, status = false } = response;
        if (status) {
            dispatch({
                type: NOTIFICATION_DOCUMENTS_LIST,
                payload: data,
            });
        }
        dispatch(isLoading(false));
        return { status };
    }).catch(e => {
        dispatch(isLoading(false))
    })
}

export const fetchNotificationDocumentsListByID = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await NotificationDucumentsServices.fetchListByID(params).then((response) => {
        const { data: { data = {}, status = false } } = response;
        dispatch(isLoading(false));
        return { status, data };
    }).catch(e => {
        dispatch(isLoading(false))
        return { data: {}, status: false };
    })
}

export const addNotificationDocumentAction = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    const { id } = params;
    let response;
    if (id) {
        response = await NotificationDucumentsServices.editNotificationDocumentService(id,params).catch(e => {
            return { status: false, message: "" }
        });
    } else {
        response = await NotificationDucumentsServices.addNotificationDocumentService(params).catch(e => {
            return { status: false, message: "" }
        });
    }
    if (response) {
     
        const { status, message } = response;
        if (status) {
            toast.success(message);
        } else {
            toast.error(message);
        }
        dispatch(isLoading(false))
        return { status }
    }



    // dispatch(isLoading(true));
    // let response = await NotificationDucumentsServices.addNotificationDocumentService(params).catch(e => {
    //     return { status: false, message: "" }
    // });
    // if (response) {
    //     const { status, message } = response;
    //     if (status) {
    //         toast.success(message);
    //         dispatch(isLoading(false))
    //     } else {
    //         toast.error(message);
    //         dispatch(isLoading(false))
    //     }
    //     return { status }
    // }
}

export const editNotificationDocumentAction = (id, params) => async (dispatch) => {
    dispatch(isLoading(true));
    let response = await NotificationDucumentsServices.editNotificationDocumentService(id, params).catch(e => {
        return { status: false, message: "" }
    });
    if (response) {
        const { status, message } = response;
        if (status) {
            toast.success(message);
            dispatch(isLoading(false))
        } else {
            toast.error(message);
            dispatch(isLoading(false))
        }
        return { status }
    }
}

export const deleteNotificationDocumentAction = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let response = await NotificationDucumentsServices.deleteNotificationDocumentService(params).catch(e => {
        return { status: false, message: "" }
    });
    if (response) {
        const { status, message } = response;
        if (status) {
            toast.success(message);
            dispatch(isLoading(false))
        } else {
            toast.error(message);
            dispatch(isLoading(false))
        }
        return { status }
    }
}
