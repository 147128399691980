import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment'

const columns = () => {
    return [
        {
            dataField: 'userName',
            text: 'User Name',

        },
        {
            dataField: 'module',
            text: 'Module Name',

        },
        {
            dataField: 'documentId',
            text: 'Document ID',

        },
        {
            dataField: 'action',
            text: 'Activity',
            classes: 'activity',
            formatter: (cell) => {
                if (cell) {
                    return cell.replace(/([A-Z])/g, ' $1').trim()
                }
            }
        },
        {
            dataField: 'ipAddress',
            text: 'IP Address',

        },
        {
            dataField: 'createdDate',
            text: 'Date',
            formatter: (cell) => {
                if (cell) {
                    return moment(cell).format("DD-MM-YYYY hh:mm:ss")
                }
            }

        },
        
        
    ];
}



export default columns;