import React, { useState, useEffect } from 'react';
import { isUserloggedIn } from "../../dependencies/utils/helper"
import { Redirect, Route, Switch } from "react-router-dom";
import logo from "../../assests/images/logo.png";
import './scss/style.scss'
import { AuthRoute as routes } from '../../dependencies/route';

const Auth = (props) => {
  const getRoutes = () => {
    return (
      <Switch>
        {routes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
        <Redirect from="/" to="/auth/login" /> 
        <Redirect from="/index" to="/auth/login" /> 
      </Switch>
    )
  }
  if (isUserloggedIn()) {
    return <Redirect to={'/dashboard'} />
  } else {
    return (
      <div className="auth-container">
        <div className="dialog-inner-wrap">
          <div className="dialog-header-wrap clearfix">
            <img src={logo} />
            <h2>Administration Portal</h2>
          </div>
          <div className="dialog-content-wrap">
            {getRoutes()}
          </div>
        </div>
      </div>
    );
  }
}
// }
export default Auth;