import React from 'react';
import { API_URL } from '../../../../constants/ApplicationUrl'
import { Link } from 'react-router-dom';
import moment from 'moment';
// import { SUPPORTING_DOCUMENTS } from '../../../../constants';


const totalReportColumns = () => {

  return [
    {
      rowIndex: true,
      text: 'SI.No.',
      className: "heading-text",
      showIndexField:'stateOrUt'
    }, {
      dataField: 'stateOrUtLabel',
      text: 'State/UT',
      className: "heading-text"
    }, {
      dataField: 'count',
      text: 'Total number of applications',
    }
  ];
}

export default totalReportColumns;