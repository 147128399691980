import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const Button = ({ text, onClick, className,  type, align, disabled, icon,value }) => {
  return (
    <button
      className={`btn-container ${icon ? 'icon' : ''} ${className}`}
      onClick={onClick}
      type={type}
      value={value}
      disabled={disabled}>
      {
        icon &&
        <i>
          {icon}
        </i>
      }
      <span className="ml5">
        {text}
      </span>
    </button>
  );
}


Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  align: PropTypes.string,
  value:PropTypes.string,
}

Button.defaultProps = {
  text: 'Load More',
  onClick: () => { },
  className: '',
  type: 'button',
  align: '',
  value:'',
}

export default Button;
