import React, { useState } from 'react';
import { Alert } from 'reactstrap';

const AlertMessage = (props) => {
  //let sucValue = {props.sucessValue};
  // setVisible(true);

  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);
  //const onDismiss = () => setVisible(true);

  return (
    <Alert
      color={props.alertColor}
      isOpen={props.visible}
      toggle={props.onDismiss}
      dismissible>
      {props.alertMessage}
    </Alert>
  );

}


export default AlertMessage;