import { get, isEmpty } from 'lodash';
import { OtherDucumentsServices } from './service';
import {
    OTHER_DOCUMENTS_LIST,
    OTHER_DOCUMENTS_LIST_BY_ID,
    TAB_SECTION_LIST
} from '../../../constants/actionConstants';
import { isLoading } from '../../../constants/actionConstants';
import { toast } from "react-toastify";



export const fetchOtherDocumentsList = (page, pageSize) => async (dispatch) => {
    dispatch(isLoading(true))
    return await OtherDucumentsServices.fetchList(page, pageSize).then((response) => {
        const { data = {}, status = false } = response;
        if (status) {
            dispatch({
                type: OTHER_DOCUMENTS_LIST,
                payload: data,
            });
        }
        dispatch(isLoading(false));
        return { status };
    }).catch(e => {
        dispatch(isLoading(false))
    })
}

export const fetchOtherDocumentsListByID = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await OtherDucumentsServices.fetchListByID(params).then((response) => {
        const { data: { data = {}, status = false } = {} } = response;
        dispatch(isLoading(false));
        return { status, data };
    }).catch(e => {
        dispatch(isLoading(false))
        return { data: {}, status: false };
    })
}

export const addOtherDocumentAction = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    const { id } = params;
    let response;
    if (id) {
        response = await OtherDucumentsServices.editOtherDocumentService(id, params).catch(e => {
            return { status: false, message: "" }
        });
    } else {
        response = await OtherDucumentsServices.addOtherDocumentService(params).catch(e => {
            return { status: false, message: "" }
        });
    }
    if (response) {
        const { status, message } = response;
        if (status) {
            toast.success(message);
        } else {
            toast.error(message);
        }
        dispatch(isLoading(false))
        return { status }
    }
}

export const deleteOtherDocumentAction = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let response = await OtherDucumentsServices.deleteOtherDocumentService(params).catch(e => {
        return { status: false, message: "" }
    });
    if (response) {
        const { status, message } = response;
        if (status) {
            toast.success(message);
            dispatch(isLoading(false))
        } else {
            toast.error(message);
            dispatch(isLoading(false))
        }
        return { status }
    }
}


export const fetchTabSectionListAction = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await OtherDucumentsServices.fetchTabSectionListService(params).then((response) => {
        const { data: { data = [], status } = {} } = response;
        dispatch(isLoading(false));
        return { data, status };
    }).catch(e => {
        dispatch(isLoading(false));
        return { data: {} }
    })
}
