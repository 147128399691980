import api from '../utils/Api';
import { FETCH_REJECTION_LIST } from "../../constants/ApplicationUrl";
import { getRejectionList, isLoading } from "../../constants/actionConstants";

export const fetchRejectionList = ({ enablerType, startupRejectType }) => (dispatch) => {
    return api.POST(FETCH_REJECTION_LIST, { enablerType, startupRejectType })
        .then((data) => {
            if (data && data.data && data.data.data) {
                let newDAta = data.data.data.filter( el => el.id !== "64144a0a874d05134e22a194" )
                dispatch(getRejectionList(newDAta));
            }
        })

};


export const fetchReasonsList = ({ enablerType, startupRejectType }) => async (dispatch) => {
    dispatch(isLoading(true))
    return api.POST(FETCH_REJECTION_LIST, { enablerType, startupRejectType })
        .then((response) => {
            const { data: { data = [], status = false } } = response;
            dispatch(isLoading(false))
            return { data, status }
        }).catch(e => {
            dispatch(isLoading(false))
            return { data: [], status: false }
        });
}
