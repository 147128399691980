import React, { useEffect, useState } from 'react';
import { updateServices, getServices } from '../../dependencies/action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../style.scss';
import InputField from '../../../common/InputField';

const Services = (props) => {
    const { match: { params: { id = null } }, servicesList = [], getServices, updateServices } = props;

    const [form, setForm] = useState({
        serviceName: ""
    });
    const [error, setError] = useState("")

    useEffect(() => {
        if (id) {
            if (servicesList && servicesList.length > 0) {
                const service = servicesList.filter(x => x.id === id)[0] || {};
                const serviceName = (servicesList && service.serviceName) || "";
                setForm(x => {
                    return { ...x, serviceName, id }
                });
            } else {
                getServices().then((repsonse) => {
                });
            }
        }
    }, [servicesList])

    const handleInputChange = (e, name) => {
        const { value, } = e.target;
        setForm((c) => {
            return { ...c, [name]: value }
        });
    }

    const validateName = (value) => {
        // var nameRegex = /^(?![\s.]+$)[a-zA-Z\s.]*$/;
        var nameRegex =  /^[ A-Za-z_@./#&+-]*$/
        var name = value.trim();
        let error = "";
        if (name == "" || name == undefined || name == null) {
            error = "This field is required.";
        }
        else if (!nameRegex.test(name)) {
            error = "Please provide valid Industry name.";
        }
        else if (name.length < 2) {
            error = "Please provide valid Industry name.";
        }
        else {
            error = "";
        }
        setError(error);
        return error;
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const name = form && form.serviceName;
        if (!validateName(name)) {
            const serviceName = (form && form.serviceName && form.serviceName.trim()) || "";
            updateServices({ ...form, serviceName }).then((response) => {
                if (response.status) {
                    props.history.push(`/update/service`);
                }
            });
        }
    }

    const handleCancel = () => {
        props.history.push(`/update/section`);
    }


    return (
        <div className="country-container">
            <h2 className="mb-20">{id ? "Update Service" : "Add Service"}
            </h2>
            <form onSubmit={e => { onSubmit(e) }}>
                <InputField
                    required
                    autoComplete="off"
                    type="text"
                    name="serviceName"
                    value={form.serviceName}
                    onChange={handleInputChange}
                    error={error}
                    label={"Service Name"}
                    containerClass={"form-group"}
                // onBlur={onBlur}
                // hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                />
                <button className="bttn normal add-btn" onClick={handleCancel}>
                    {"Cancel"}
                </button>
                <button className="bttn blue normal add-btn">
                    {"Submit"}
                </button>
            </form>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        servicesList: state.UpdateReducer.servicesList
    }
}

export default connect(mapStateToProps, { updateServices, getServices })(withRouter(Services));