import { get, isEmpty } from 'lodash'

/* To handle first name validation*/
export function validateName(name) {
    var nameRegex = /^(?![\s.]+$)[a-zA-Z\s.]*$/;
    var name = name.trim();

    if (name == "" || name == undefined || name == null) {
        return { status: false, error: "Please enter your name", hasError: true };
    }
    else if (!nameRegex.test(name)) {
        return { status: false, error: "Please provide valid name", hasError: true };
    }
    else if (name.length < 2) {
        return { status: false, error: "Please provide valid name", hasError: true }
    }
    else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateURL(url) {
    var urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    //url = url.trim();

    if (url == "" || url == undefined || url == null) {
        return { status: false, error: "Please enter your url", hasError: true };
    }
    else if (!urlRegex.test(url)) {
        return { status: false, error: "Please provide valid url", hasError: true };
    }
    else if (url.length < 2) {
        return { status: false, error: "Please provide valid url", hasError: true }
    }
    else {
        return { status: true, error: '', hasError: false };
    }
}


/* To handle first designation validation*/
export function validateDesignation(name) {
    var name = name.trim();

    if (name == "" || name == undefined || name == null) {
        return { status: false, error: "Please enter designation", hasError: true };
    }
    else if (name.length < 2) {
        return { status: false, error: "Please provide valid designation", hasError: true }
    }
    else {
        return { status: true, error: '', hasError: false };
    }
}


/* To handle Captcha validation*/
export function validateCaptcha(name) {
    var name = name.trim();

    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter captcha", hasError: true };
    }
    // else if (name.length < 2) {
    //     return { status: false, error: "Please provide valid captcha" }
    // }
    else {
        return { status: true, error: '', hasError: false };
    }
}


/* To handle Captcha validation*/
export function validateUsernameEmail(name) {
    var name = name.trim();

    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter email id or username", hasError: true };
    }
    else if (name.length < 2) {
        return { status: false, error: "Please provide valid email id or username ", hasError: true }
    }
    else {
        return { status: true, error: '', hasError: false };
    }
}
/* To handle first name validation*/
export function validateUserName(name) {
    var nameRegex = /^[a-zA-Z]+$/;
    var name = name.trim();

    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "Please enter username", hasError: true };
    }
    else if (!nameRegex.test(name)) {
        return { status: false, error: "Please provide valid username", hasError: true };
    }
    else if (name.length < 2) {
        return { status: false, error: "Please provide valid username", hasError: true }
    }
    else {
        return { status: true, error: '', hasError: false };
    }
}

/* To handle first name validation*/
export function validateRejectDropDown(name) {
    //var name = name.trim();

    if (name === "" || name === undefined || name === null || name.length === 0) {
        return { status: false, error: "Please Select value", hasError: true };
    }
    else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateFileTyPe(name) {
    //var name = name.trim();

    if (name === "" || name === undefined || name === null || name.length === 0) {
        return { status: false, error: "Please Select value", hasError: true };
    }
    else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateOther(value) {
    const text = value && value.trim();
    var numberRegex = /^[^\s]+(\s+[^\s]+)*$/;
    if (text === "" || text === undefined || text === null || text.length === 0) {
        return { status: false, error: "This field is required.", hasError: true };
    }
    else if (!numberRegex.test(text)) {
        return { status: false, error: "Please input valid text.", hasError: true }
    } else if (text.length < 2) {
        return { status: false, error: "Reason should not less than 2 character", hasError: true }
    } 
    else {
        return { status: true, error: '', hasError: false };
    }
}

/* To handle email validation */
export function validateEmail(email) {
    let emailRegex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    email = email.trim();
    if (email === "" || email === undefined || email === null) {
        return { status: false, error: "Please enter email address.", hasError: true };
    }
    else if (!emailRegex.test(email)) {
        return { status: false, error: "Please enter valid email address.", hasError: true };
    }
    else {
        return { status: true, error: '', hasError: false };
    }
}

/* To validate password */
export function validatePassword(password) {
    // var passwordRegex = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{16,}$/;
    //    var passwordRegex = /^(?=.{6,16})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=.*?[0-9]).*$/;
    // var passwordRegex = /^ (?=^.{8,16}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
    var passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/;
    password = password.trim();

    if (password === "" || password === undefined || password === null) {
        return { status: false, error: "Please enter valid password.", hasError: true }
    }
    // else if (!passwordRegex.test(password)) {
    //     return { status: false, error: "Please enter valid password." };
    // }
    // else if (password.length < 6) {
    //     return { status: false, error: "Password must have at least 6 characters with at least one Capital letter, at least one lower case letter and at least one number.", hasError: true };
    // }
    // else if(password.length > 8||password.length ==8){
    //     return { status: false, error: "Passwocters." };
    // }
    // else if (!passwordRegex.test(password)) {
    //     return { status: false, error: "Password must have at least 6 characters with at least one Capital letter, at least one lower case letter and at least one number.", hasError: true }
    // }
    else {
        return { status: true, error: '', hasError: false }
    }
}

/* To validate Mobile No. */

export function validateMobileNo(mobileNo) {
    var numberRegex = /^[1-9][0-9]{7,18}$/;
    mobileNo = mobileNo.trim()
    if (mobileNo === "") {
        return { status: true, error: "Please enter phone number.", hasError: true }
    }
    if (mobileNo === "" || mobileNo === undefined || mobileNo === null) {
        return { status: false, error: "Please enter phone number.", hasError: true }
    } else if (!numberRegex.test(mobileNo)) {
        return { status: false, error: "Please enter valid phone number (9XXXXXXXXX).", hasError: true }
    } else {
        return { status: true, error: '', hasError: false }
    }
}

export function validateMobileNo1(mobileNo) {
    var numberRegex = /^[1-9][0-9]{7,18}$/;
    mobileNo = mobileNo.trim()
    if (mobileNo === "") {
        return { status: true, error: "" }
    }
    if (mobileNo === undefined || mobileNo === null) {
        return { status: false, error: "Please enter phone number." }
    } else if (!numberRegex.test(mobileNo)) {
        return { status: false, error: "Please enter valid phone number." }
    } else {
        return { status: true, error: '' }
    }
}

export function validateConfirmPassword(confirmPassword, password) {
    if (confirmPassword === "" || confirmPassword === undefined || confirmPassword === null) {
        return { status: true, error: "Please enter valid confirm password", hasError: true }
    } else if (confirmPassword === password) {
        return { status: true, error: "", hasError: false }
    } else {
        return { status: false, error: "Password does not match", hasError: true }
    }
}

export function validateWeight(weight) {
    var numberRegex = /^((?!(0))[0-9]{2,3})$/g;
    weight = weight.trim();
    if (weight === "" || weight === undefined || weight === null) {
        return { status: false, error: "Please enter weight" }
    } else if (!numberRegex.test(weight)) {
        return { status: false, error: "Please enter valid weight" }
    } else {
        return { status: true, error: '' }
    }
}

export function validateGoals(goals) {
    var numberRegex = /^((?!(0))[0-9]{2,3})$/g;
    goals = goals.trim();
    if (goals === "" || goals === undefined || goals === null) {
        return { status: false, error: "Please enter your daily goal limit" }
    } else if (!numberRegex.test(goals)) {
        return { status: false, error: "Please enter valid goal limit" }
    } else {
        return { status: true, error: '' }
    }
}

export function validateUploadFile(file) {
    if (isEmpty(file) || !file.publicUrl) {
        return { status: false, error: "Please select file", hasError: true };
    }
    else {
        return { status: true, error: '', hasError: false };
    }
}



/* To validate login password */
export function validateLoginPassword(password) {
    if (password === "" || password === undefined || password === null) {
        return { status: false, error: "Please enter valid password.", hasError: true }
    } else {
        return { status: true, error: '', hasError: false }
    }
}

/* To validate login password */
export function validateDob(dob) {
    if (dob === "" || dob === undefined || dob === null) {
        return { status: false, error: "Please enter dob.", hasError: true }
    } else {
        return { status: true, error: '', hasError: false }
    }
}

export function validateTitle(value) {
    const title = value && value.trim();
    var nameRegex = /^(?!\s*$).+/;
    if (title == "" || title == undefined || title == null) {
        return { status: false, error: "This field is required", hasError: true };
    }
    else if (!nameRegex.test(title)) {
        return { status: false, error: "Please input valid text", hasError: true };
    }
    else if (title.length < 2) {
        return { status: false, error: "Please input valid text", hasError: true }
    }
    else {
        return { status: true, error: '', hasError: false };
    }
}
/* To validate login password */
export function validateDiip(value) {

    var numberRegex = /^[^\s]+(\s+[^\s]+)*$/;
    if (value === "" || value === undefined || value === null) {
        return { status: false, error: "Please enter DIPP number.", hasError: true }
    } else if (!numberRegex.test(value)) {
        return { status: false, error: "Please enter valid  DIPP number", hasError: true }
    } else {
        return { status: true, error: '', hasError: false }
    }
}

export function validateEntityName(value) {

    var numberRegex = /^[^\s]+(\s+[^\s]+)*$/;
    // var value = value.trim();
    if (value === "" || value === undefined || value === null) {
        return { status: false, error: "Please enter Entity Name.", hasError: true }
    } else if (!numberRegex.test(value)) {
        return { status: false, error: "Please enter valid  Entity Name", hasError: true }
    } else {
        return { status: true, error: '', hasError: false }
    }
}

export function validateIncorporationNumber(value) {

    var numberRegex = /^[^\s]+(\s+[^\s]+)*$/;
    // var value = value.trim();
    if (value === "" || value === undefined || value === null) {
        return { status: false, error: "Please enter Incorporation Number.", hasError: true }
    } else if (!numberRegex.test(value)) {
        return { status: false, error: "Please enter valid  Incorporation Number", hasError: true }
    } else {
        return { status: true, error: '', hasError: false }
    }
}

export function validateNatureOfEntity(value) {

    var numberRegex = /^[^\s]+(\s+[^\s]+)*$/;
    // var value = value.trim();
    if (value === "" || value === undefined || value === null) {
        return { status: false, error: "Please select nature of entity.", hasError: true }
    } else if (!numberRegex.test(value)) {
        return { status: false, error: "Please enter valid  Incorporation Number", hasError: true }
    } else {
        return { status: true, error: '', hasError: false }
    }
}
export function validateDescription(text) {
    var value = text && text.trim();
    var numberRegex = /^(?!\s*$).+/;

    if (value === "" || value === undefined || value === null) {
        return { status: false, error: "This field is required.", hasError: true }
    } else if (!numberRegex.test(value)) {
        return { status: false, error: "Please enter valid text", hasError: true }
    } else if (value.length < 2) {
        return { status: false, error: "Text length should be greater than 2.", hasError: true }
    } else {
        return { status: true, error: '', hasError: false }
    }
}

export function validateStartDate(value) {

   

    if (value === "" || value === undefined || value === null) {
        return { status: false, error: "Please enter From Date.", hasError: true }
    } 
    // else if (new Date(value) > new Date()) {
    //     return { status: false, error: "Should not be future date.", hasError: true }

    // } 
    else {
        return { status: true, error: '', hasError: false }
    }
}

export function validateEndDate(value) {

    if (value === "" || value === undefined || value === null) {
        return { status: false, error: "Please enter End Date.", hasError: true }
    } 
    // else if (new Date(value) > new Date()) {
    //     return { status: false, error: "Should not be future date.", hasError: true }

    // } 
    else {
        return { status: true, error: '', hasError: false }
    }
}