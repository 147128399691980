import api from '../utils/Api';
import { toast } from "react-toastify";
import {
  FETCH_STARTUP_APPLICATION_LIST,
  GET_NATURE_OF_ENTITY,
  FETCH_STARTUP_INDUSTRY_LIST,
  FETCH_STARTUP_SERVICE_LIST,
  FETCH_STATE_LIST,
  FETCH_STARTUP_APPLICATION_VIEW,
  FETCH_STARTUP_APPLICATION_UPDATE,
  FETCH_DISTRICT_LIST,
  FETCH_SUB_DISTRICT_LIST,
  FETCH_STARTUP_SECTOR,
  UPDATE_STARTUP_FORM,
  REGENERATE_COR,
  FETCH_80IAC_APPLICATION_OBSERVATION,
  NATURE_OF_ENTITY_LIST,
  FETCH_INCOMPLETE_HISTORY,
  UPDATE_CIN_NUMBER,
  FETCH_AUDIT_TRAIL_list
} from "../../constants/ApplicationUrl";
import { fetchStartupApplication } from "../../constants/actionConstants";
import startupTransformer from '../../components/startupApplication/dependencies/transformer/startuptransformer'
import { isLoading } from '../../constants/actionConstants';

export const startupServices = {
  getStartupApplicationInfo(id) {
    return api.GET(`${FETCH_STARTUP_APPLICATION_VIEW}/${id}`).
      then((response) => {
        const { data: { status, data } } = response;
        if (status) {
          return startupTransformer(data);
        } else {
          return Promise.reject(response.data);
        }
      }).catch(e => {
        return Promise.reject(e);
      });
  },
  fetchAuditTrailList(id) {
    return api.GET(`${FETCH_AUDIT_TRAIL_list}/${id}`).
      then((response) => {

        const { data: { status, data } } = response;
        if (status) {
          return { data, status };
        } else {
          return Promise.reject(response.data);
        }
      }).catch(e => {
        return Promise.reject(e);
      });
  },
  getStartupApplicationObservation(id) {
    return api.GET(`${FETCH_80IAC_APPLICATION_OBSERVATION}/${id}`).
      then((response) => {

        const { data: { status, data } } = response;
        if (status) {
          return startupTransformer(data);
        } else {
          return Promise.reject(response.data);
        }
      }).catch(e => {
        return Promise.reject(e);
      });
  },
  fetchAuditTrailList(id) {
    return api.GET(`${FETCH_AUDIT_TRAIL_list}/${id}`).
      then((response) => {

        const { data: { status, data } } = response;
        if (status) {
          return { data, status };
        } else {
          return Promise.reject(response.data);
        }
      }).catch(e => {
        return Promise.reject(e);
      });
  },
  getNatureOfTheEntity() {
    return api.GET(`${GET_NATURE_OF_ENTITY}`).
      then((response) => {
        const { data: { status, data } } = response;
        return { data, status };
      }).catch(e => {
        return { data: [], status: false };
      });
  },
  fetchStartupIndustryList() {
    return api.GET(`${FETCH_STARTUP_INDUSTRY_LIST}`).
      then((response) => {
        const { data: { status, data } } = response;
        return { data, status };
      }).catch(e => {
        return Promise.reject(e);
      });

  },
  fecthIncompleteList(id) {

    return api.GET(`${FETCH_INCOMPLETE_HISTORY}/${id}`).
      then((response) => {
        const { data = [] } = response.data;
        return data;
      })
  },
  fetchStartupServiceList() {
    return api.GET(`${FETCH_STARTUP_SERVICE_LIST}`).
      then((response) => {
        const { data: { status, data } } = response;
        return { data, status };
      }).catch(e => {
        return Promise.reject(e);
      });
  },
  fetchStateList() {
    return api.GET(`${FETCH_STATE_LIST}`).
      then((response) => {
        const { data: { status, data } } = response;
        return { data, status };
      }).catch(e => {
        return Promise.reject(e);
      });
  },
  getDistrict(params) {
    const { stateId } = params;
    return api.GET(`${FETCH_DISTRICT_LIST}/${stateId}/district/list`).
      then((response) => {
        const { data: { data, status } } = response;
        return { data, status };
      })
  },
  getSubDistrict(params) {
    const { districtId } = params;
    return api.GET(`${FETCH_SUB_DISTRICT_LIST}/${districtId}/subDistrict/list`).
      then((response) => {
        const { data: { status, message, data } = {} } = response;
        return { data, status, message };
      })
  },
  getStartupSector(params) {
    const { industryId } = params;
    return api.GET(`${FETCH_STARTUP_SECTOR}/${industryId}/startupSection/list`).
      then((response) => {
        const { data: { data: startupSector = [], status } = {} } = response || {};
        return { startupSector, status };
      });
  },

  updateStartupForm(params) {
    return api.POST(`${UPDATE_STARTUP_FORM}`, params).
      then((response) => {
        const { data: { status, message, errors } = {} } = response;
        return { status, message, errors };
      });
  }
}

export const getStartupApplicationList = ({ sortColumn, sortOrder, pageSize, pageIndex, query }) => (dispatch) => {
  dispatch(isLoading(true));
  return api.POST(FETCH_STARTUP_APPLICATION_LIST, { sortColumn, sortOrder, pageSize, pageIndex, query })
    .then((data) => {
      if (data && data.data && data.data.data) {
        dispatch(fetchStartupApplication(data.data));
      }
      dispatch(isLoading(false));
    })
}
export const actionStartupApplication = ({ id, status, rejectReason, rejectDescription, incompleteReasons, incompleteDescription, onHoldReasons, onHoldDescription }) => (dispatch) => {
  dispatch(isLoading(true));
  return api.POST(FETCH_STARTUP_APPLICATION_UPDATE, { id, status, rejectReason, rejectDescription, incompleteReasons, incompleteDescription, onHoldReasons, onHoldDescription})
    .then((data) => {
      if (data && data.data) {
        if (data.data.status) {
          toast.success(data.data.message);
        } else {
          toast.error(data.data.message);
        }

        dispatch(isLoading(false));
      }
    })

}
export const getStartupApplicationObservation = ({ sortColumn, sortOrder, pageSize, pageIndex, query }) => (dispatch) => {
  dispatch(isLoading(true));
  return api.POST(FETCH_80IAC_APPLICATION_OBSERVATION, { sortColumn, sortOrder, pageSize, pageIndex, query })
    .then((data) => {
      if (data && data.data && data.data.data) {
        
        dispatch(fetchStartupApplication(data.data));
      }
      dispatch(isLoading(false));
    })
}

export const doDownloadStartupList = (params) => (dispatch) => {
  dispatch(isLoading(true));

  return api.DOWNLOADDATA(FETCH_STARTUP_APPLICATION_LIST, params)
    .then((response) => {
      const { data = "" } = response;
      dispatch(isLoading(false));
      return data;
    }).catch(e => {
      dispatch(isLoading(false))
      return "";
    })
}

export const getNatureOfEntityList = () => (dispatch) => {
  return api.GET(`${NATURE_OF_ENTITY_LIST}`).
    then((data) => {
      return data;
    }).catch(e => {
      return Promise.reject(e);
    });
}

export const generateCor = (params) => (dispatch) => {
  dispatch(isLoading(true));

  return api.POST(REGENERATE_COR, params)
    .then((response) => {
      dispatch(isLoading(false));
      const { data: { data, status, message } = {} } = response;
      if (status === true) {
        toast.success(message);
      } else {
        toast.error(message);
      }
      return { status, data };
    }).catch(e => {
      dispatch(isLoading(false));
      return {}
    })
}


export const updateCin = (params) => (dispatch) => {
  dispatch(isLoading(true));

  return api.POST(UPDATE_CIN_NUMBER, params)
    .then((response) => {
      dispatch(isLoading(false));
      const { data: { data, status, message } = {} } = response;
      if (status === true) {
        toast.success(message);
      } else {
        toast.error(message);
      }
      return { status, data };
    }).catch(e => {
      dispatch(isLoading(false));
      return {}
    })
}