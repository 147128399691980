import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import Button from '../../common/Button';
import AlertMessage from '../../common/AlertMessage';
import { AlertType } from '../../../constants';
import InputField from '../../common/InputField';
import { resetPassword } from '../common/dependencies/action';
import FormValidator from '../common/dependencies/FormValidator';
import refreshIconwhite from '../../../assests/images/refresh-icon-white.png';
import './scss/index.scss';

const ResetPassword = (props) => {

  const { resetPassword, history = {} } = props;
  const defaultFormData = {
    newPassword: '',
    confirmPassword: ''
  }

  const [formData, setFormData] = useState(defaultFormData);
  const [error, setError] = useState({});
  const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState(AlertType.SUCCESS);
  const location = useLocation();
  const validateInput = () => {
    const {
      newPassword,
      confirmPassword
    } = formData;
    const formStatus = FormValidator.validateResetPasswordForm({
      newPassword,
      confirmPassword
    });
    const errors = {
      newPassword: formStatus.password,
      confirmPassword: formStatus.confirmPassword,
    };
    setError(errors);
    return !formStatus.hasErrors;
  };

  const handleInputChange = (e, name) => {
    const { value, } = e.target;
    setFormData((formData) => {
      return { ...formData, [name]: value }
    });
  }

  const doReset = () => {
    setError({});
    setFormData(defaultFormData);
  }

  const onBlur = (e, name) => {
    validateInput();
  }

  const onClose = () => {
    setAlertMessage('');
  }

  const onSubmit = (e) => {
    e.preventDefault();
    onClose();
    let str = location.search;
    let res = str.split('=');
    let token;
    if (res && res.length > 1) {
      token = res[1]
    }
    const { newPassword, confirmPassword } = formData;
    if (!validateInput()) {
      setHasInvalidSubmitOccurred(true);
    } else {
      resetPassword({ token, newPassword, confirmPassword })
        .then((data) => {
          const { status, message } = data;
          if (status) {
            history.push('/login')
          } else if (!status) {
            setAlertMessage(message);
            setAlertType(AlertType.DANGER);
          }
          doReset();
        });
    }
  }



  return (
    <div className="forget-form-wrap basic-dialog">
      <div className="dialog-header">

        <h2>Reset Password</h2>
      </div>
      <AlertMessage
        type={alertType}
        allowClose={true}
        onClose={onClose}
        message={alertMessage}
      />

      <form onSubmit={e => onSubmit(e)}>

        <InputField
          required={true}
          autoComplete="off"
          type="password"
          name="newPassword"
          value={formData.newPassword}
          onChange={handleInputChange}
          error={error.newPassword}
          label={"New Password"}
          containerClass={"form-group"}
          onBlur={onBlur}
          hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
        />

        <InputField
          required={true}
          type="password"
          name="confirmPassword"
          label="Confirm Password"
          value={formData.confirmPassword}
          onChange={handleInputChange}
          error={error.confirmPassword}
          containerClass={"form-group"}
          onBlur={onBlur}
          autoComplete="off"
          hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
        />
        <div className="dialog-footer">
          <Button
            className="bttn"
            type="submit"
            text="Submit"
          />
          <Button
            className="bttn blue"
            type='button'
            onClick={doReset}
            text="Reset"
            icon={(<img src={refreshIconwhite} />)}
          />

        </div>
      </form>
    </div>
  );
}

export default connect(null, { resetPassword })(withRouter(ResetPassword));
