import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addOtherDocumentAction, fetchOtherDocumentsListByID, fetchTabSectionListAction } from '../dependencies/action';
import { isLoading } from '../../../constants/actionConstants';
import FormValidator from '../dependencies/FormValidator';
import GlobalDropDown from '../../common/GlobalDropDown/index';
import InputField from '../../common/InputField';
import { uploadFileService } from '../../../dependencies/services/uploadFileService';
import FileUpload from '../../common/FileUpload';
import { SECTION_OPTIONS } from '../dependencies/constant';
import { getDocumentLink } from '../../../dependencies/utils/helper';

import Button from '../../common/Button';
import SectionTitle from '../../common/sectionTitle/sectionTitle';

const AddEditOtherDocuments = (props) => {

    const { fetchOtherDocumentsListByID, addOtherDocumentAction, fetchTabSectionListAction, isLoading, match: { params: { id: exemptionId } = {} } = {}, history } = props;
    const defaultFormData = {
        fileInfo: null,
        id: null,
        linkTitle: "",
        sectionName: "",
    }

    const [formData, setFormData] = useState(defaultFormData);
    const [error, setError] = useState({});
    const [fileName, setFileName] = useState(null);
    const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] = useState(false);
    const [tabSectionOptions, setTabSectionOptions] = useState([]);

    const getList = () => {
        if (exemptionId) {
            fetchOtherDocumentsListByID(exemptionId).then((response) => {
                const { data, status } = response;
                if (status) {
                    setFormData(d => {
                        return { ...d, ...data }
                    })
                }
            })
        }
    }

    useEffect(() => {
        fetchTabSectionListAction().then((response) => {
            const { data = [], status } = response || {};
            const options = [];
            if (status) {
                data && data.forEach(element => {
                    if (SECTION_OPTIONS && SECTION_OPTIONS[element]) {
                        options.push({
                            text: SECTION_OPTIONS[element],
                            value: element,
                        })
                    }
                });
            }
            setTabSectionOptions(options);
        });
    }, [])
    useEffect(() => {
        getList();
    }, [exemptionId]);

    const validateInput = () => {
        const {
            fileInfo,
            linkTitle,
            sectionName,
        } = formData;

        const formStatus = FormValidator.validateOtherDocument({
            fileInfo,
            linkTitle,
            sectionName,
        });
        const errors = {
            fileInfo: formStatus.fileInfo,
            linkTitle: formStatus.linkTitle,
            sectionName: formStatus.sectionName

        };

        setError(errors);
        return !formStatus.hasErrors;
    };

    const handleInputChange = (e, name) => {
        const { value, } = e.target;
        setFormData((formData) => {
            return { ...formData, [name]: value }
        });
    }

    const handleSubmit = async () => {
        if (!validateInput()) {
            setHasInvalidSubmitOccurred(true)
        } else {
            addOtherDocumentAction({
                ...formData,
            }).then(({ status = false }) => {
                if (status) {
                    history.push('/other/list')
                }
            })
        }
    }

    const handleReset = () => {
        setError({});
        setFileName('');
        setFormData(defaultFormData);
    }

    const onUploadFile = (file) => {
        isLoading(true)
        const data = new FormData();
        data.append('file', file)
        data.append('documentType', 'OTHER_DOC');
        uploadFileService.uploadFile(data).then((data) => {
            if (data) {
                setFormData(d => {
                    return { ...d, fileInfo: data };
                });
            }
            isLoading(false)
        }, (error) => {
            isLoading(false)
        });
    }

    const onBlur = () => {
        validateInput();
    }

    const fileLink = formData && formData.fileInfo && formData.fileInfo.publicUrl && getDocumentLink(formData.fileInfo.publicUrl) || ""
    return (
        <div className="row">

            <div className="report-table col-md-6">
                <SectionTitle>{exemptionId ? 'Edit Other Documents' : 'Add New Other Documents'}</SectionTitle>

                <GlobalDropDown
                    required
                    label='Tab-Section Name'
                    selectTagStyle="form-control dropdown-file"
                    onChange={handleInputChange}
                    options={tabSectionOptions}
                    name={'sectionName'}
                    value={formData.sectionName}
                    error={error.sectionName}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                />


                <InputField
                    required
                    type="text"
                    name="linkTitle"
                    value={formData.linkTitle}
                    onChange={handleInputChange}
                    error={error.linkTitle}
                    label={"Link Title"}
                    containerClass={"form-group"}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                />


                {
                    fileLink &&
                    <label>uploaded document:  <a href={fileLink}>{formData.fileInfo.compressedName}</a></label>
                }
                <FileUpload
                    required
                    onUploadFile={onUploadFile}
                    allowedFiles={[".pdf", ".png", ".jpg"]}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    error={error.fileInfo}
                    label='Upload Document'
                    value={fileName}
                    onBlur={onBlur}
                />


                <div className="button-group mt-30">
                    <Button
                        className="bttn normal"
                        type='button'
                        onClick={handleSubmit}
                        text="Submit"
                    />
                    {!exemptionId &&
                        <Button
                            className="bttn blue normal"
                            type='button'
                            onClick={handleReset}
                            text="Reset"
                        />
                    }
                </div>

            </div>
        </div>)
}


const mapStateToProps = (state) => {
    return {
        otherdata: state.OtherDocumentsReducer.otherDocumentsID,
        tabList: state.OtherDocumentsReducer.tabSectionList
    }
}

export default connect(mapStateToProps, { addOtherDocumentAction, fetchOtherDocumentsListByID, fetchTabSectionListAction, isLoading })(withRouter(AddEditOtherDocuments));