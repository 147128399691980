import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import InputField from '../../../../common/InputField';
import GlobalDropDown from '../../../../common/GlobalDropDown';
import { dropdownOptionsTransform } from '../../../dependencies/utils';
import FormValidator from '../../../dependencies/Validation';
import Button from '../../../../common/Button'
import InlineError from '../../../../common/inlineError';
import ReactSwitch from 'react-switch';
import "./style.scss";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const EntityDetail = (props) => {

  const {
    natureOfTheEntity,
    newNatureOfTheEntity,
    startupIndustry,
    startupEntitySector,
    startupServices,
    incorporationNumber,
    startupEntity,
    onChangeForm,
    getStartupSector,
    onChangeHandler,
    error = {},
    doReset,
    doSubmit,
    setChecked,
    checked,
    formSubmit


  } = props;

  const defaultFormData = {
    startupNatureOfEntity: {},
    startupNewNatureOfEntity: {},
    startupIndustry: {},
    startupSection: {},
    startupServices: [],
    name: "",
    incorporationDate: "",
    panNumber: "",
    recognitionCertificateStatus: "",
    eightyIACCertificateStatus: "",
    secFiftySixCertificateStatus: "",
    newNameOfEntity: null,
    newIncorporationNumber: null,
    isCOREnabled: false,
    newIncorporationDate:null
  }

  const [formData, setFormData] = useState(defaultFormData);
  const [natureOfEntityOptions, setNatureOfEntity] = useState([]);
  const [newNatureOfEntityOptions, setNewNatureOfEntity] = useState([]);
  const [startupIndustryOptions, setStartupIndustry] = useState([]);
  const [startupEntitySectorOptions, setStartupEntitySector] = useState([]);
  const [startupServicesOptions, setStartupServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loadSector, setLoadSector] = useState(false);
 

  useEffect(() => {
    const options = dropdownOptionsTransform(natureOfTheEntity, 'name', 'id');
    setNatureOfEntity(options);
  }, [natureOfTheEntity]);

  useEffect(() => {
    const options = dropdownOptionsTransform(newNatureOfTheEntity, 'name', 'id');
    setNewNatureOfEntity(options);
  }, [newNatureOfTheEntity]);
  useEffect(() => {
    const options = dropdownOptionsTransform(startupIndustry, 'industryName', 'id');
    setStartupIndustry(options);
  }, [startupIndustry]);
  useEffect(() => {
    const options = dropdownOptionsTransform(startupServices, 'serviceName', 'id');
    setStartupServices(options);
  }, [startupServices]);
  useEffect(() => {
    const options = dropdownOptionsTransform(startupEntitySector, 'sectionName', 'id');
    setStartupEntitySector(options);
  }, [startupEntitySector]);

  const fetchStartupSector = (id) => {
    if (getStartupSector) {
      getStartupSector({
        industryId: id
      }).then((data) => {
        setLoadSector(false)
      })
    }
  }

  const handleChange = val => {
    
    if(val == false || formSubmit)
    {
      formData.newIncorporationNumber =null;
      formData.newNameOfEntity =null;
      formData.newIncorporationDate = null;
    }

    setChecked(val);
    setFormData(options => {
      return { ...options, ...{ isCOREnabled: val } }
    })
  }

  useEffect(() => {
    if (!isEmpty(startupEntity)) {
      setFormData(options => {
        return { ...options, ...startupEntity }
      })
      if (startupEntity && startupEntity.startupIndustry && startupEntity.startupIndustry.id) {
        fetchStartupSector(startupEntity.startupIndustry.id);
        // if (getStartupSector) {
        //   getStartupSector({
        //     industryId: startupEntity.startupIndustry.id
        //   }).then((data) => {
        //     setLoadSector(false);
        //   })
        // }
      }
    }

  }, [startupEntity]);



  useEffect(() => {
    if (onChangeHandler) {
      onChangeHandler({
        startupEntity: { ...formData }
      });
    }
  }, [formData]);
  useEffect(() => {
    if (loadSector && formData && formData.startupIndustry && formData.startupIndustry.id) {
      fetchStartupSector(formData.startupIndustry.id);
    }

  }, [loadSector])


  const handleInputChange = (e, name) => {
    if (e && e.target) {
      let value = e.target.value;
      let field = {};
      if (name === 'startupNatureOfEntity') {
        value = natureOfTheEntity.filter(x => x.id === value)[0] || {};
      }
    else if (name === 'startupNewNatureOfEntity') {
        value = newNatureOfTheEntity.filter(x => x.id === value)[0] || {};
      }  
      else if (name === 'startupIndustry') {
        value = startupIndustry.filter(x => x.id === value)[0] || {};
        field = {
          startupSection: {}
        }
        setLoadSector(true);
      } else if (name === 'startupSection') {
        value = startupEntitySector.filter(x => x.id === value)[0] || {};
      } else if (name === 'startupServices') {
        const val = [];
        let options = e.target.options;
        options = Array.from(options)
        for (let i = 0, l = options.length; i < l; i++) {
          if (options[i].selected && options[i].value != '') {
            const item = startupServices.filter(x => x.id === options[i].value)[0];
            val.push(item);
          }
        }
        value = val || [];
      }

      setFormData((d) => {
        return { ...d, [name]: value, ...field }
      });

    }
  }





  const categoriesValue = formData.startupServices.map(val => val.id);
  return (
    <div>
      <div className={checked ? "reactSwitch-d-flex colour": "reactSwitch-d-flex"} >
        <ReactSwitch
          checked={checked} onChange={handleChange} />
      </div>

      <div className="row mb-20">
        <div className="col-sm-4">
          <GlobalDropDown
            required
            label='Nature of Entity'
            selectTagStyle="form-control dropdown-media"
            onChange={handleInputChange}
            options={natureOfEntityOptions}
            name={'startupNatureOfEntity'}
            value={formData.startupNatureOfEntity.id}
            error={error.startupNatureOfEntity}
            hasInvalidSubmitOccurred={true}
            disabled={true}
          />
        </div>
        
           {checked && <div className="col-sm-4">
          <GlobalDropDown
            required
            label='New Nature of Entity'
            selectTagStyle="form-control dropdown-media"
            onChange={handleInputChange}
            options={newNatureOfEntityOptions}
            name={'startupNewNatureOfEntity'}
            value={formData.startupNewNatureOfEntity.id}
            error={error.startupNewNatureOfEntity}
            hasInvalidSubmitOccurred={true}
          />
        </div>
        }
        
        <div className="col-sm-4">
          <GlobalDropDown
            required
            label='Industry'
            selectTagStyle="form-control dropdown-media"
            onChange={handleInputChange}
            options={startupIndustryOptions}
            name={'startupIndustry'}
            value={formData.startupIndustry.id}
            error={error.startupIndustry}
            hasInvalidSubmitOccurred={true}
          />
        </div>
        <div className="col-sm-4">
          <GlobalDropDown
            required
            label='Sector'
            selectTagStyle="form-control dropdown-media"
            onChange={handleInputChange}
            options={startupEntitySectorOptions}
            name={'startupSection'}
            value={formData.startupSection.id}
            error={error.startupSection}
            hasInvalidSubmitOccurred={true}

          />
        </div>
      </div>
      <div className="row mb-20">
        <div className="col-sm-4">
          <GlobalDropDown
            required
            label='Categories'
            selectTagStyle="form-control dropdown-media"
            multiple
            onChange={handleInputChange}
            options={startupServicesOptions}
            name={'startupServices'}
            value={categoriesValue}
            error={error.startupServices}
            hasInvalidSubmitOccurred={true}
          />
        </div>
        <div className="col-sm-8">
          <div className="row mb-20">
            <div className="col-sm-6">
              <InputField
                label='Incorporation / Registration No. '
                required
                type="text"
                name="name"
                value={incorporationNumber}
                containerClass={"form-group"}
                disabled={true}
              />
            </div>
            <div className="col-sm-6">
              <InputField
                label='Name of the Entity '
                type="text"
                name="name"
                value={formData.name}
                containerClass={"form-group"}
                disabled={true}
              />
            </div>
            {checked && <>
              <div className="col-sm-6">
                <InputField
                  label='New Incorporation / Registration No. '
                 // required
                  type="text"
                  name={"newIncorporationNumber"}
                  value={formData.newIncorporationNumber}
                  containerClass={"form-group"}
                  onChange={handleInputChange}

                />
              </div>
              <div className="col-sm-6">
                <InputField
                  label='new Name of the Entity '
                  type="text"
                  name={"newNameOfEntity"}
                  value={formData.newNameOfEntity}
                  containerClass={"form-group"}
                  onChange={handleInputChange}

                />
              </div></>}

          </div>
        </div>
      </div>
      <div className="row mb-20">
        <div className="col-sm-4">
          <InputField
            label='Incorporation / Registration Date'
            required
            type='text'
            name="incorporationDate"
            value={formData.incorporationDate}
            containerClass={"form-group"}
            disabled={true}
           // onChange={handleInputChange}
          />
          </div>
          {checked &&
          
          <div className="col-sm-4">
          <InputField
            label='New Incorporation / Registration Date'
            required
            type='date'
            name="newIncorporationDate"
            value={formData.newIncorporationDate}
            containerClass={"form-group"}
            onChange={handleInputChange}
          />
          
         </div>
}
        <div className="col-sm-4">
          <InputField
            label='PAN'
            type='text'
            name="panNumber"
            value={formData.panNumber}
            onChange={handleInputChange}
            containerClass={"form-group"}
          />
        </div>
      
      </div>
      {
        error.tnc && (
          <div className="row mb-20">
            <div className="col-sm-12">
              <InlineError message={`Go to "Self Certification" and ${error.tnc}`} />
            </div>
          </div>
        )
      }
      <div className="row mb-20 text-center">
        <div className="col-sm-12">
          <Button
            className="bttn mb-20"
            type="button"
            text="Save"
            onClick={() => doSubmit("ENTITY")}
          />

        </div>
      </div>

    </div>
  )
}

export default EntityDetail;  