import {
    validateRejectDropDown,
    validateOther
} from '../../../dependencies/utils/validation/Validation';

export default {

    validateDropDownValue: (params) => {

        const { dropDownValidate, other, approveRejectValidate } = params;
        if ((params.other && params.other.length !== 0) || (params.other == '')) {
            let formStatus = {};
            const dropDownValidateStatus = validateRejectDropDown(dropDownValidate);
            const otherStatus = validateOther(other);


            formStatus = {
                dropDownValidate: dropDownValidateStatus.error,
                other: otherStatus.error,

                hasErrors: (
                    dropDownValidateStatus.hasError,
                    otherStatus.hasError
                )
            };
            return formStatus;
        }
        else if (approveRejectValidate !== undefined) {

            let formStatus = {};
            const dropDownValidateStatus = validateRejectDropDown(dropDownValidate);
            const approveRejectValidateStatus = validateRejectDropDown(approveRejectValidate);

            formStatus = {
                dropDownValidate: dropDownValidateStatus.error,
                approveRejectValidate: approveRejectValidateStatus.error,
                hasErrors: (
                    dropDownValidateStatus.hasError,
                    approveRejectValidateStatus.hasError
                )
            };
            return formStatus;
        } else {

            let formStatus = {};
            const dropDownValidateStatus = validateRejectDropDown(dropDownValidate);

            formStatus = {
                dropDownValidate: dropDownValidateStatus.error,
                hasErrors: (
                    dropDownValidateStatus.hasError
                )
            };
            return formStatus;
        }


    },



};
