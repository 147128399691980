import { get, isEmpty } from 'lodash'

export function validateEmptyObject(obj) {
    if (isEmpty(obj)) {
        return { status: false, error: "Please select valid option", hasError: true };
    }
    else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateTextField(str) {
    var nameRegex = /^[a-zA-Z]+$/;
    var str = str && str.trim();

    if (str === "" || str === undefined || str === null) {
        return { status: false, error: "This field is required", hasError: true };
    }
    else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateTextarea(text) {
    var nameRegex = /^[a-zA-Z]+$/;
    var str = text && text.trim();
    if (str === "" || str === undefined || str === null) {
        return { status: false, error: "This field is required", hasError: true };
    } else if (str.length < 100) {
        return { status: false, error: "Characters length should be greater than 100.", hasError: true }
    } else if (str.length > 400) {
        return { status: false, error: "Characters length should be less than 400.", hasError: true }
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateMobile(mobileNo) {
    let nameRegex = /^([0|\+[0-9]{1,5}[.-\s]?)?([6-9][0-9]{9})$/;
    if (mobileNo === "" || mobileNo === undefined || mobileNo === null) {
        return { status: false, error: "This field is required", hasError: true };
    } else if (!nameRegex.test(mobileNo)) {
        return { status: false, error: "Please provide valid mobile value", hasError: true };
    } else {
        return { status: true, error: '', hasError: false };
    }
}
export function validateQuantity(quantity) {
    let nameRegex = /^\d{10}$/;
    if (quantity === "" || quantity === undefined || quantity === null | quantity === 0) {
        return { status: false, error: "This field is required", hasError: true };
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateInputNumber(number) {
    if (number === "" || number === undefined || number === null | number === 0) {
        return { status: false, error: "This field is required", hasError: true };
    } else if (isNaN(number) || number == 0) {
        
        return { status: false, error: "Please provide valid number", hasError: true };
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateNullOrEmpty(value) {
    if (value === "" || value === undefined || value === null) {
        return { status: false, error: "This field is required", hasError: true };
    } else {
        return { status: true, error: '', hasError: false };
    }
}
export function validateNullOrEmptyDin(value) {
    var numberRegex = /^\d{8}$/;
    if (value === "" || value === undefined || value === null) {
        return { status: false, error: "This field is required", hasError: true };
    } else if (value !== "NA") {
        if(!numberRegex.test(value)){
            return { status: false, error: "Please add the 8 digit DIN/DPIN number", hasError: true } 
        }
        else {
            return { status: true, error: '', hasError: false };
        }
    } else {
        return { status: true, error: '', hasError: false };
    }
}

export function validateTermAndCondition(value) {
    // if (value === "" || value === undefined || value === null) {
    //     return { status: false, error: "This field is required", hasError: true };
    // } else {
    //     return { status: true, error: '', hasError: false };
    // }
    if (value === true) {
        return { status: true, error: '', hasError: false };
    } else {
        return { status: true, error: 'please accept terms and condition', hasError: true };
    }
}

export function validateUploadFile(file) {
    if (isEmpty(file) || !file.publicUrl) {
        return { status: false, error: "Please select file", hasError: true };
    }
    else {
        return { status: true, error: '', hasError: false };
    }
}