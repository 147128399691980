import React from "react";
import './sectionTitle.scss';
import {Link} from 'react-router-dom';
const sectionTitle = (props) => (
    <div className="secion-title-wrap">
        <h1>{props.children}</h1>

        {props.breadCrumb && 
        <div className="bread-crumb-wrap">
            <Link to='/dashboard'>Dashboard</Link>
            &nbsp;<i>></i>&nbsp;
            <span>{props.title}</span>
        </div>}
    
    </div>
)

export default sectionTitle;
