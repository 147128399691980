import React, { useEffect, useState } from 'react';
import './scss/index.scss';
import KeyValue from '../keyValue';
import SectionTitle from '../../../components/common/sectionTitle/sectionTitle';
import { withRouter } from 'react-router-dom';
import { getStartupApplicationList } from '../../../dependencies/services/startupApplicationService';
import { getStartupApplicationView } from '../../../dependencies/services/getStartupApplicationView';
import connect from 'react-redux/es/connect/connect';
import EntityDataRow from '../../common/EntityDataRow';
import Button from '../../common/Button';
import ActionModal from '../modal';
import { ROLES } from '../../../constants/Roles';
import { storage } from '../../../dependencies/store/storage';


const EntityDetails = (props) => {

    const userRole = storage.getUserRole();
    const [modalValue, setModalValue] = useState(false);
    const [buttonValue, setButtonValue] = useState('');
    const sAppId = props.match.params.id;
    const swsCondition = props.startupApplicationViewData.swsId || props.startupApplicationViewData.applReqId || false

    useEffect(() => {
        props.getStartupApplicationView({
            sAppId: sAppId
        });
    }, []);

    if (props.startupApplicationViewData.directors !== undefined) {
        var directData = props.startupApplicationViewData.directors.map(item => {
            return (<div className="directors-list"><KeyValue items={item} /></div>)
        })
    }

    const toggleActionModal = () => {
        setModalValue(!modalValue);

    }

    const callApi = () => {
        props.getStartupApplicationView({
            sAppId: sAppId
        });
    }
    const submitAction = (name) => {
        toggleActionModal();
        setButtonValue(name)

    }

    const isValidUser = userRole === ROLES.SUPER_ADMIN || userRole === ROLES.ADMIN;
    const isValidUserForArchive = userRole === ROLES.SUPER_ADMIN;


    return (
        <div className="p30">
            <SectionTitle>Startup India : Registration Form{ ((props.startupApplicationViewData.swsId)?(' (NSWS)'):('')) }</SectionTitle>
            <div className="entity-detail-wrap">
                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Nature of the Entity</h3>
                    <KeyValue items={props.startupApplicationViewData.natureOfEntity ? props.startupApplicationViewData.natureOfEntity : ''} />
                </div>

                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Full Address (Office)</h3>
                    <KeyValue items={props.startupApplicationViewData.officeAddress ? props.startupApplicationViewData.officeAddress : ''} />
                </div>
                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Authorized Representative Details</h3>
                    <KeyValue items={props.startupApplicationViewData.autherizeRepresentativeDetails ? props.startupApplicationViewData.autherizeRepresentativeDetails : ''} />
                </div>
                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Director(s) / Partner(s) Details</h3>
                    <EntityDataRow className="" title={'Number of Directors / Partners	'} description={Object.values(props.startupApplicationViewData ? props.startupApplicationViewData.directors : '').length} />
                    {directData}
                </div>
                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Information Required</h3>
                    <KeyValue items={props.startupApplicationViewData.informationRequired ? props.startupApplicationViewData.informationRequired : ''} />
                </div>
                {(props.startupApplicationViewData.preCertified === false) ?
                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Self Certification</h3>
                    <KeyValue items={props.startupApplicationViewData.selfCertificate ? props.startupApplicationViewData.selfCertificate : ''} />
                </div> :''}
            </div>
            {isValidUser && (props.startupApplicationViewData && props.startupApplicationViewData.archived === false
                ? (props.startupApplicationViewData.buttonStatus === 'SUBMIT'
                    // ? (props.startupApplicationViewData.buttonStatus === 'REJECT'
                    ?
                    <div className="allButton mb-30">
                        {isValidUserForArchive && <Button
                            id='offline'
                            className="bttn blue normal"
                            type='button'
                            onClick={() => submitAction('ARCHIVE')}
                            text="Archive"
                            value={'ARCHIVE'}
                        />}

                        <Button
                            className="bttn green normal"
                            type='button'
                            onClick={() => submitAction('APPROVE')}
                            text="Approved"
                        />
                        <Button
                            className="bttn red normal"
                            type='button'
                            onClick={() => submitAction('REJECT')}
                            text="Reject"
                        />
                        <Button
                            className="bttn yellow normal"
                            type='button'
                            onClick={() => submitAction('INCOMPLETE')}
                            text="Incomplete"
                        />
                        {  !swsCondition &&
                            <Button
                            className="bttn voilet normal"
                            type='button'
                            onClick={() => submitAction('ON_HOLD')}
                            text="On Hold"
                        />}
                    </div>
                    : (props.startupApplicationViewData.buttonStatus === 'DRAFT'
                        || props.startupApplicationViewData.buttonStatus === 'APPROVE'
                        || props.startupApplicationViewData.buttonStatus === 'REJECT'
                        || props.startupApplicationViewData.buttonStatus === 'CANCELLED'
                        || props.startupApplicationViewData.buttonStatus === 'EXPIRED'
                        ? <>{isValidUserForArchive && <div className="allButton mb-30"> <Button
                        id='offline'
                        className="bttn blue normal"
                        type='button'
                        onClick={() => submitAction('ARCHIVE')}
                        text="Archive"
                        value={'ARCHIVE'}
                    /> </div>}</>
                        :
                        (props.startupApplicationViewData.buttonStatus === 'INCOMPLETE'
                            ? <div className="allButton mb-30">
                                {isValidUserForArchive && <Button
                                    id='offline'
                                    className="bttn blue normal"
                                    type='button'
                                    onClick={() => submitAction('ARCHIVE')}
                                    text="Archive"
                                    value={'ARCHIVE'}
                                />}
                                <Button
                                    className="bttn red normal"
                                    type='button'
                                    onClick={() => submitAction('REJECT')}
                                    text="Reject"
                                />
                                <Button
                                    className="bttn yellow normal"
                                    type='button'
                                    onClick={() => submitAction('INCOMPLETE')}
                                    text="Incomplete"
                                />
                            </div>
                            : (props.startupApplicationViewData.buttonStatus === 'ON_HOLD' ?
                                <div className="allButton mb-30">
                                    <Button
                                        className="bttn green normal"
                                        type='button'
                                        onClick={() => submitAction('SUBMIT')}
                                        text="Unhold"
                                    />
                                </div>
                                : ''
                            )
                        )
                    )
                )
                : ''
            )}

            {modalValue ?
                <ActionModal
                    modalValue={modalValue}
                    toggleActionModal={toggleActionModal}
                    callApi={callApi}
                    actionValue={buttonValue}
                    id={props.startupApplicationViewData
                        ?
                        props.startupApplicationViewData.applicationId : ''}
                /> : null
            }
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        startupApplicationDataState: state.startupApplicationList.startupData,
        startupApplicationViewData: state.startupSectionView.startupAppData,
    }
}
export default connect(mapStateToProps, { getStartupApplicationList, getStartupApplicationView })(withRouter(EntityDetails))