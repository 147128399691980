import { combineReducers } from 'redux';
import {
    IAC_APPLICATION_DETAILS,
    NRDC_List,
    NRDC_Recognition,
    COMMUNICATION_REQUEST,
    EP_ASSIGNED_DETAIL,
    IMBMEMBER_ASSIGNED_DETAIL,
    IMBCHAIR_ASSIGNED_DETAIL,
    ADMIN_SUPERADMIN_DETAIL,
    IAC_80_List_DETAIL,
    FETCH_80IAC_CLARIFICATION,
    IMB_MEMBER_COMMENT_LIST,
    IAC_80_SECTOR_LIST,
    INCOMPLETE_LIST_IAC,
    // ADMIN_SUPERADMIN_DETAIL
} from '../../../constants/actionConstants';



const applicatio80IAC = (state = {}, action) => {
    switch (action.type) {
        case IAC_APPLICATION_DETAILS:
            return { ...action.payload };
        default:
            return state;
    }
}

const IAC80ListDetail = (state = {}, action) => {
    switch (action.type) {
        case IAC_80_List_DETAIL:
            return { ...action.payload };
        default:
            return state;
    }
}

const listNRDC = (state = [], action) => {
    switch (action.type) {
        case NRDC_List:
            return { listNRDC: action.payload }
        default:
            return state;
    }
}

const NRDCRecognition = (state = [], action) => {
    switch (action.type) {
        case NRDC_Recognition:
            return { recognitionData: action.payload }
        default:
            return state;
    }
}


const communicationDetails = (state = [], action) => {
    switch (action.type) {
        case COMMUNICATION_REQUEST:
            return { communicationInfo: action.payload }
        default:
            return state;
    }
}
const epAssignedDetails = (state = [], action) => {
    switch (action.type) {
        case EP_ASSIGNED_DETAIL:
            return {epAssignedDetail: action.payload}
        default:
            return state;
    }
}

const imbMemberAssigned = (state = [], action) => {
    switch (action.type) {
        case IMBMEMBER_ASSIGNED_DETAIL:
            return {imbmemberAssignedDetail: action.payload}
        default:
            return state;
    }
}
const imbChairAssigned = (state = {}, action) => {
    switch (action.type) {
        case IMBCHAIR_ASSIGNED_DETAIL:
            return { ... action.payload };
        default:
            return state;
    }
}

const adminSuperadminAssigned = (state = {}, action) => {
    switch (action.type) {
        case ADMIN_SUPERADMIN_DETAIL:
            return { ... action.payload };
        default:
            return state;
    }
}

const clarificationObservation = (state = {}, action) => {
    switch (action.type) {
        case FETCH_80IAC_CLARIFICATION:
            return { ...action.payload };
        default:
            return state;
    }
}

const imbMemberCommentList = (state = [], action) => {
    switch (action.type) {
        case IMB_MEMBER_COMMENT_LIST:
            return [...action.payload]
        default:
            return state;
    }
}
const IAC80SectorList = (state = [], action) => {
    switch (action.type) {
        case IAC_80_SECTOR_LIST:
            return [...action.payload]
        default:
            return state;
    }
}

const incompleteHistoryList = (state = [], action) => {
    switch (action.type) {
        case INCOMPLETE_LIST_IAC:
            return [...action.payload]
        default:
            return state;
    }
}


const Applicatio80IACReducer = combineReducers({
    applicatio80IAC,
    listNRDC,
    NRDCRecognition,
    communicationDetails,
    IAC80ListDetail,
    clarificationObservation,
    imbMemberCommentList,
    IAC80SectorList,
    incompleteHistoryList,
    epAssignedDetails,
    adminSuperadminAssigned,
    imbMemberAssigned,
    imbChairAssigned
})

export default Applicatio80IACReducer;