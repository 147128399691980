import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import './style.scss';
import TableHOC from './common/HOC/TableHOC';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';

const Table = (props) => {
  const { dataTable, columns, keyField, classes, selectRowOptions, wrapperClasses, paginationOptions, onTableChange, rowStyle, cssClass } = props;
  return (
    <div className="">
      {
        columns && columns.length > 0
        //  && dataTable && dataTable.length > 0
        &&
        (
          <div>
            <BootstrapTable
              // table-responsive
              wrapperClasses={`table-responsive ${wrapperClasses}`}
              data={dataTable}
              columns={columns}
              keyField={keyField}
              classes={`table ${cssClass}`}
              selectRow={selectRowOptions}
              pagination={paginationFactory(paginationOptions)}
              noDataIndication={() => (<div>No Record found.</div>)}
              remote={{
                filter: false,
                pagination: true,
                sort: true,
                cellEdit: false
              }}
              rowStyle={rowStyle}
              onTableChange={onTableChange}
              striped
              // bordered={false}
              table-bordered
              hover={true}
            />
          </div>
        )
      }
    </div>
  )
}
Table.propTypes = {
  dataTable: PropTypes.arrayOf(PropTypes.object),
};

Table.defaultProps = {
  dataTable: [],
};

export default TableHOC(Table);