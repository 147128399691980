import React from 'react';
import { API_URL } from '../../../../constants/ApplicationUrl'
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getDocumentLink } from '../../../../dependencies/utils/helper'
import { ROLES } from '../../../../constants/Roles';
import { storage } from '../../../../dependencies/store/storage';


const columns = (
  {
    clickOnAction,
    pageNo,
    size
  }
) => {
  const userRole = storage.getUserRole();

  return [
    {
      dataField: '',
      text: 'Sr.No',
      formatter: (cell, row, rowIndex) => {
        return (pageNo * size) + (rowIndex + 1);
      }
    }, {
      dataField: 'sectionNameLabel',
      text: 'Tab-Section',

    }, {
      dataField: 'fileInfo',
      text: 'Document',
      formatter: (cell, row) => {
        if (cell) {
          const url = cell && cell.publicUrl || '';
          return (
            <div className="IAS-Actions-list">
              {url && <a href={getDocumentLink(url)} target="_blank">View Documents</a>}
            </div>
          )

        }
      }

    }, {
      dataField: 'linkTitle',
      text: 'Link Title	',

    }, {
      dataField: 'action',
      text: 'Action',
      hidden: userRole !== ROLES.SUPER_ADMIN && userRole !== ROLES.ADMIN,
      formatter: (cell, row) => {
        const { id } = row;
        return (
          <div className="IAS-Actions-list">
              <span><a href='javascript:void(0)' onClick={() => clickOnAction(id)}>Delete</a></span>
              <span className='edit-color'><Link to={`/other/form/${id}`} >Edit</Link></span>

          </div>
        )

      }
    }
  ];
}

export default columns;