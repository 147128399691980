import {
    validateTitle,
    validateFileTyPe,
    validateUploadFile
} from '../../../dependencies/utils/validation/Validation';

export default {

    validateOtherDocument: (params) => {
        const {
            fileInfo,
            linkTitle,
            sectionName } = params;
        let formStatus = {};

        const linkTitleStatus = validateTitle(linkTitle);
        const fileInfoStatus = validateUploadFile(fileInfo);
        const sectionNameStatus = validateFileTyPe(sectionName);

        formStatus = {
            linkTitle: linkTitleStatus.error,
            fileInfo: fileInfoStatus.error,
            sectionName: sectionNameStatus.error,

            hasErrors: (
                linkTitleStatus.hasError
                || fileInfoStatus.hasError
                || sectionNameStatus.hasError
            )
        };
        return formStatus;
    },
};
