import React from 'react';
import { API_URL } from '../../../../constants/ApplicationUrl'
import { Link } from 'react-router-dom';
import moment from 'moment';
// import { SUPPORTING_DOCUMENTS } from '../../../../constants';


const recognisedColumns = () => {

  return [
    {
      rowIndex: true,
      text: 'SI.No.',
      className: "heading-text",
      showIndexField:'stateOrUt'

    }, {
      dataField: 'stateOrUtLabel',
      text: 'State/UT',
      className: "heading-text"
    }, {
      text: 'Number of applications where DIPP Recognition Certificate',
      nestedColumn: [
        {
          dataField: 'generatedCount',
          text: 'Approved',
        }, {
          dataField: 'notGeneratedCount',
          text: 'Pending',
        },{
          dataField:"expiredCount",
          text:"Expired"
        }]
    },
  ];
}

export default recognisedColumns;