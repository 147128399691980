export const DownloadField = [
    { text: "Incorporation No", dataField: "Incorporation No" },
    { text: "Incorporation Date", dataField: "Incorporation Date" },
    { text: "Recognition Number", dataField: "Recognition Number" },
    { text: "Status", dataField: "Status" },
    { text: "Certificate Date", dataField: "Certificate Date" },
    { text: "Cancellation Date", dataField: "Cancellation Date" },
    { text: "Expiration Date", dataField: "Expiration Date" },
    { text: "Nature of Entity", dataField: "Nature of Entity" },
    { text: "Entity Name", dataField: "Entity Name" },
    { text: "Industry", dataField: "Industry" },
    { text: "Sector", dataField: "Sector" },
    { text: "Categories", dataField: "Categories" },
    { text: "PAN Number", dataField: "PAN Number" },
    { text: "Office Address", dataField: "Office Address" },
    { text: "City", dataField: "City" },
    { text: "State", dataField: "State" },
    { text: "District", dataField: "District" },
    { text: "Remapped District", dataField: "Remapped District" },
    { text: "Prayas District Name", dataField: "Prayas District Name" },
    { text: "LGD Code", dataField: "LGD Code" },
    { text: "Pin Code", dataField: "Pin Code" },
    { text: "Representative Name", dataField: "Representative Name" },
    { text: "Designation", dataField: "Designation" },
    { text: "Representative Mobile", dataField: "Representative Mobile" },
    { text: "Representative Email", dataField: "Representative Email" },
    { text: "No Of Directors", dataField: "No Of Directors" },
    { text: "Current no. of employees", dataField: "Current no. of employees" },
    { text: "Current stage", dataField: "Current stage" },
    { text: "Applied for IPR", dataField: "Applied for IPR" },
    { text: "Women Director Number", dataField: "Women Director Number" },
    { text: "Startups with Women Directors", dataField: "Startups with Women Directors" },
    { text: "Recognition Financial Year", dataField: "Recognition Financial Year" },
    { text: "Tier 2/3 City", dataField: "Tier 2/3 City" },
    { text: "Director Name 1", dataField: "Director Name 1" },
    { text: "Director Gender 1", dataField: "Director Gender 1" },
    { text: "Director mobileNumber 1", dataField: "Director mobileNumber 1" },
    { text: "Director Email 1", dataField: "Director Email 1" },
    // { text: "Director Gender 2", dataField: "Director Gender 2" },
    // { text: "Director Gender 3", dataField: "Director Gender 3" },
    // { text: "Director Gender 4", dataField: "Director Gender 4" },
    // { text: "Director Gender 5", dataField: "Director Gender 5" },
    // { text: "Director Gender 6", dataField: "Director Gender 6" },
    // { text: "Director Gender 7", dataField: "Director Gender 7" },
    // { text: "Director Gender 8", dataField: "Director Gender 8" },
    // { text: "Director Gender 9", dataField: "Director Gender 9" },
    // { text: "Director Gender 10", dataField: "Director Gender 10" },
    // { text: "Director Gender 11", dataField: "Director Gender 11" },
    // { text: "Director Gender 12", dataField: "Director Gender 12" },
    // { text: "Director Gender 13", dataField: "Director Gender 13" },
    // { text: "Director Gender 14", dataField: "Director Gender 14" },
    // { text: "Director Gender 15", dataField: "Director Gender 15" }
];