import { GET_USER_DETAILS } from '../../constants/actionConstants';

const initialState = {
    userDetails: ''
};

export default (state = initialState, action) => {

    let newState;
    switch (action.type) {

        case GET_USER_DETAILS:
    
            newState = {
                ...state,
                userDetails: action.payload
            };
            return newState;
        default:
            return state;
    }
}
