import React, { useEffect, useState } from 'react';
// import SectionTitle from '../../../../components/common/sectionTitle/sectionTitle';
// import SectionTitle from '../../../common/sectionTitle';
import SectionTitle from '../../../common/sectionTitle/sectionTitle';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import Modal from '../../../common/Modal';
import { getIncompleteHistory } from '../action';
import incompleteTable from './incompleteTable'
import Table from '../../../common/Table'
import { unEscapeRegHtml } from '../../../../dependencies/utils/helper';

const IncompleteHistory = (props) => {

    
    const {
        exemptionId,
        getIncompleteHistory,
        incompleteHistoryList,
        submitClarificationObservation,
        onClose,
    } = props;
    useEffect(() => {
        
        if (exemptionId) {
            getIncompleteHistory(exemptionId)
        }

    }, []);

 
    return (
        <div>
            <SectionTitle>Startup India : Incomplete Reasons History</SectionTitle>
            <Table
                dataTable={incompleteHistoryList ? incompleteHistoryList : ''}
                columns={incompleteTable}
                keyField='financialYear'
                cssClass='startupLogs'
            />
        </div>


    )
}
const mapStateToProps = (state) => {
  
    return {
        incompleteHistoryList: state.Applicatio80IACReducer.incompleteHistoryList
    }
}
export default connect(mapStateToProps, { getIncompleteHistory })(withRouter(Modal(IncompleteHistory)))
