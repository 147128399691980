import React from 'react';
import { API_URL } from '../../../../constants/ApplicationUrl'
import { Link } from 'react-router-dom';
import moment from 'moment';
import { headerSortingStyle } from '../../../../constants';
import { unEscapeRegHtml  } from '../../../../dependencies/utils/helper';


const Section56Columns = () => {

  return [
    {
      dataField: 'id',
      text: '',
      hidden: true
    }, {
      dataField: 'incorporationNumber',
      text: 'Registration No',
    },
    {
      dataField: 'referenceNumber',
      text: 'Reference No',
    },
    {
      dataField: 'entityName',
      text: 'Name of the Entity',
    }, {
      dataField: 'incompleteAction',
      text: 'Incomplete Reason',
      formatter: (cell, row) => {
        if (cell && cell.incompleteReasons) {
          return cell.incompleteReasons.map((element, index) => {

            if (element.reason == 'Others- If any other, please specify. A field to enter other reason with 1000 character limit.') {
              return <div>  {index + 1}. {unEscapeRegHtml(cell.incompleteDescription)}</div>

            } else {
              return <div>  {index + 1}. {unEscapeRegHtml(element.reason)}</div>

            }
          })
        }

      }
    }, , {
      dataField: 'incompleteAction',
      text: 'Incomplete Date',
      formatter: (cell, row) => {
        // console.log('U74900KA2015PTC078812',cell)
        if (cell && cell.incompleteDate) {
          return (moment(cell.incompleteDate).format('YYYY-MM-DD'))

        }

      },
    },
   
    {
      dataField: 'statusLabel',
      text: 'Status',
    }
  ];
}

export default Section56Columns;