import React, { Component, useEffect, useState } from 'react';
import InputField from '../common/InputField';
import { downloadIncompleteReport } from './dependencies/action';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import Download from '../common/Download';
import _ from 'lodash';
import SectionTitle from '../common/sectionTitle/sectionTitle';
import './style.scss';
import FormValidator from '../section56Report/dependencies/FormValidator';

const Incomplete80iacReport = (props) => {

    var m = new Date();
    var dateString =
        m.getUTCFullYear() + "-" +
        ("0" + (m.getUTCMonth() + 1)).slice(-2) + "-" +
        ("0" + m.getUTCDate()).slice(-2)

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [error, setError] = useState({});
    const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] = useState(false);


    const validateInput = () => {

        const formStatus = FormValidator.validateDate({
            startDate,
            endDate
        });
        const errors = {
            startDate: formStatus.startDate,
            endDate: formStatus.endDate
        };
        setError(errors);
        return !formStatus.hasErrors;
    };
    const onBlur = (e, name) => {
        validateInput();
    }

    const { downloadIncompleteReport } = props;

    const handleInput = (e, type) => {
        let value = e.target.value;
        if (type === 'startDate') {
            setStartDate(value);
        } else if (type === 'endDate') {
            setEndDate(value);
        }
    }

    function downloadStartupDetail() {
       
        if (!validateInput()) {
            setHasInvalidSubmitOccurred(true)
            const data = 'error';
            return data;
        } else {
            if (downloadIncompleteReport) {
                let query = {
                    "endDate": endDate,
                    "startDate": startDate,
                    "status": 'INCOMPLETE'
                }

                for (var propName in query) {
                    if (query[propName] === null || query[propName] === undefined || query[propName] === '') {
                        delete query[propName];
                    }
                }

                return downloadIncompleteReport({ query }).then((response) => {


                    const { data } = response || [];
                    return data;
                })
            }
        }
    }
    const submitFormHandler = async (e) => {
        e.preventDefault();
    }
    return (
        <div className="startup-report">
            <SectionTitle>Incomplete 80IAC Report</SectionTitle>
            <div className="filter-form custom-form" >

                <div className="row">
                    <div className="startdate col-md-3">
                        <InputField
                            type="date"
                            name="startDate"
                            label='From Date'
                            value={startDate}
                            max={endDate}
                            onChange={handleInput}
                            placeholder="Search"
                            max={dateString}
                            error={error.startDate}
                            containerClass={"form-group"}
                            onBlur={onBlur}
                            hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}


                        />
                    </div>
                    <div className="endDate col-md-3">
                        <InputField

                            type="date"
                            name="endDate"
                            min={startDate}
                            label="To Date"
                            value={endDate}
                            onChange={handleInput}
                            containerClass={"form-group"}
                            placeholder="Search"
                            max={dateString}
                            error={error.endDate}
                            containerClass={"form-group"}
                            onBlur={onBlur}
                            hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}


                        />
                    </div>
                    <div className="col-md-3">
                        <Download
                            fileName={'Saved Applications Details'}
                            onClickDownload={downloadStartupDetail}
                            className="bttn download-form-bttn"
                            alignLeft
                        />
                    </div>

                </div>


            </div>

            {/* </form> */}
        </div>
    )

}


const mapStateToProps = (state) => {


}

export default connect(mapStateToProps, { downloadIncompleteReport })(withRouter(Incomplete80iacReport));