import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { updateIacAction,updateimbStatus } from '../../dependencies/action';
import { fetchRejectionList } from '../../../../dependencies/services/getRejectList';
import GlobalDropDown from '../../../common/GlobalDropDown/index'
import TextareaField from '../../../common/TextareaField'
import FormValidator from '../../dependencies/FromValidator';
import ApprovedRejectDropDown from '../../../../dependencies/utils/json/approvedReject.json';

const ActionModal = (props) => {

    const {
        buttonLabel,
        className
    } = props;
    const refreshApi80Iac = () => props.callApi80Iac();
    const [incompleteStatus, setIncompleteStatus] = useState([]);
    const [approvedRejectStatus, setApprovedRejectStatus] = useState('')
    const [other, setOther] = useState('');
    const [showInputFiled, setShowInputFiled] = useState(false);
    const [incompleteStatusTitle, setIncompleteStatusTitle] = useState([])
    const [error, setError] = useState({});
    const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] = useState(false);
    const [otherValue, setOtherValue] = useState('');
    const [otherError, setOtherError] = useState('')
    useEffect(() => {
        props.fetchRejectionList({
            enablerType: "STARTUP",
            startupRejectType: 'INCOMPLETE_80_IAC',
        })
    }, [])

    if (props.getRejectList) {
        var outputSrtUpAppList = props.getRejectList.map(s => ({ text: s.reason, value: s.id }));
    }
    const validateInput = () => {

        let dropDownValidate

        if (incompleteStatus && approvedRejectStatus !== 'select') {
            dropDownValidate = incompleteStatus
        } if (approvedRejectStatus && approvedRejectStatus !== 'select') {
            dropDownValidate = approvedRejectStatus
        }

        const result = incompleteStatusTitle.indexOf('Others- If any other, please specify.')

        let otherData = {}
        if (result != -1 || approvedRejectStatus === 'REJECT') {
            otherData = { other }
        }
        const formStatus = FormValidator.validateDropDownValue({
            dropDownValidate,
            ...otherData

        });
        const errors = {
            dropDownValidate: formStatus.dropDownValidate,
            other: formStatus.other
        };
        setError(errors);
        return !formStatus.hasErrors;
    };

    const onBlur = (e, name) => {
        validateInput();
    }

    const submitFormHandler = async (e) => {        
        e.preventDefault();
        if (!validateInput()) {
            setHasInvalidSubmitOccurred(true)
        } else {
            let sendRes = {}
            let status = props.actionValue
            if (approvedRejectStatus) {
                status = approvedRejectStatus
                if (approvedRejectStatus === 'REJECT')
                    sendRes = {
                        rejectDescription: other
                    }
            } else if (incompleteStatus) {
                const arrayToObject = incompleteStatus.map((item) => { return { id: item } })
                sendRes = {
                    incompleteReasons: arrayToObject,
                    incompleteDescription: other
                }

            }
            await props.updateIacAction({
                id: props.id,
                status: status,
                ...sendRes
            })
            await toggle();
            if (approvedRejectStatus === 'REJECT') {
                await refreshApi();
            } else {
                await refreshApi();
            }

        }
    }

    const setTextValue = (e) => {
        // console.log(e.target.value)
        setOtherValue(e.target.value)
    }
    const ActionIMBRecommend = async () => {
        if(!otherValue) {
            setOtherError('Please enter reason!')
            return false
        }
        setOtherError('')
        await props.updateIacAction({
            id: props.id,
            status: "ON_HOLD",
            reason: otherValue
        })
        await toggle();
        await refreshApi();
        

    }

    const handleInput = (e, name) => {
        let value = e.target.value;
        if (name === 'approvedRejectValue') {
            if (e.target.value === 'REJECT') {
                setShowInputFiled(true)
            } else {
                setShowInputFiled(false)
            }
            setApprovedRejectStatus(e.target.value)
        } else if (name === 'incompleteDropDownValue') {
            const val = [];
            const titles = [];
            let options = e.target.options;
            options = Array.from(options);
            let otherSelected = false;
            for (let i = 0, l = options.length; i < l; i++) {
                if (options[i].selected) {
                    val.push(options[i].value);
                    titles.push(options[i].title);
                    if (options[i].title.indexOf('Others- If any other') > -1 && otherSelected === false) {
                        otherSelected = true;
                    }
                }
            }
            setIncompleteStatusTitle(titles)
            setIncompleteStatus(val)
            if (otherSelected) {
                setShowInputFiled(true)
            } else {
                setShowInputFiled(false)
            }
        } else if (name === 'other') {
            setOther(e.target.value)
        }
    }
    const toggle = () => props.toggleActionModal();
    const refreshApi = () => props.callApi();
    const actionPerform = async () => {
        await props.updateIacAction({
            id: props.id,
            status: props.actionValue
        })
        await toggle();
        await refreshApi();
        if (props.actionValue == 'ARCHIVE') {
            props.history.push('/application/80iac');
        }

    }
    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            <Modal isOpen={props.modalValue} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>{
                    ((props.actionValue === 'approvedReject') ? 'Approve/Reject' : (props.actionValue === 'notRecommend')? 'Reason For Not Recommending': props.actionValue)
                }</ModalHeader>
                <ModalBody>

                    {props.actionValue === 'INCOMPLETE'
                        ? (
                            <form className="filter-form" onSubmit={submitFormHandler}>

                                <GlobalDropDown
                                    required
                                    label='Reason'
                                    selectTagStyle="form-control dropdown-media"
                                    multiple
                                    onChange={handleInput}
                                    options={outputSrtUpAppList}
                                    name={'incompleteDropDownValue'}
                                    defaultOptionDisable={true}
                                    value={incompleteStatus}
                                    error={error.dropDownValidate}
                                    onBlur={onBlur}
                                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                />
                                {showInputFiled &&
                                    <TextareaField

                                        required
                                        type="text"
                                        name="other"
                                        label="Other"
                                        value={other}
                                        onChange={handleInput}
                                        error={error.other}
                                        containerClass={"form-group"}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />
                                }
                                <div className="button-wrap">
                                    <button type='submit' className='bttn'>Submit</button>
                                </div>
                            </form>
                        ) : (props.actionValue === 'approvedReject'
                            ? (<form className="filter-form" onSubmit={submitFormHandler}>

                                <GlobalDropDown
                                    required
                                    label='Status'
                                    containerStyle="form-group "
                                    selectTagStyle="form-control dropdown-media"
                                    onChange={handleInput}
                                    options={ApprovedRejectDropDown}
                                    value={approvedRejectStatus}
                                    name={'approvedRejectValue'}
                                    error={error.dropDownValidate}
                                    onBlur={onBlur}
                                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}

                                />


                                {showInputFiled &&
                                    <TextareaField

                                        required
                                        type="text"
                                        name="other"
                                        label="Other"
                                        value={other}
                                        onChange={handleInput}
                                        error={error.other}
                                        containerClass={"form-group"}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}

                                    />

                                }
                                <div className="button-wrap">
                                    <button type='submit' className='bttn'>Submit</button>
                                </div>
                            </form>) : (props.actionValue === 'notRecommend'
                                ? (
                                    <>
                                        <div>
                                            <textarea className="form-control" name="textValue" onChange={(e) => setTextValue(e)} value={otherValue} placeholder="Input your message" />
                                            {
                                                otherError?(<label className='text-danger'>{otherError}</label>):''                        
                                            }
                                            <br />
                                            
                                        </div>
                                        <Button style={{
                                            background: '#18AD00',
                                            border: 'none',
                                            padding: '5px  15px',
                                            margin: '0 41.5%',
                                        }} onClick={ActionIMBRecommend}>Submit</Button>
                                    </>
                                )
                            : 'Do you want to proceed?'))

                    }
                </ModalBody>

                {props.actionValue !== 'INCOMPLETE' && props.actionValue !== 'approvedReject' && props.actionValue !== 'notRecommend' ?
                    (
                        <ModalFooter>
                            {props.actionValue !== 'INCOMPLETE'
                                ? <Button color="primary" onClick={actionPerform}>Ok</Button>
                                : ''}

                        </ModalFooter>
                    )
                    :
                    ''
                }
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {

    return {
        getRejectList: state.getRejectListReducer.rejectionList
    }
}
export default connect(mapStateToProps, { updateIacAction, fetchRejectionList,updateimbStatus })(withRouter(ActionModal))
