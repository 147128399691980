import React, { useEffect, useState } from 'react';
import './scss/index.scss';
import style from './scss/style.css'
import SectionTitle from '../../../components/common/sectionTitle/sectionTitle';
import { withRouter, Link } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { get80IACApplicationDetails, updateRecommendation,updateImbRecommendation, updateImbChairRecommendation,updateIacAction } from '../dependencies/action';
import KeyValue from '../keyValue';
import Table from '../../common/Table';
import annualAccountColumns from '../dependencies/annualAccountColumns';
import itrColumns from '../dependencies/itrColumns';
import clarificationColumns from '../dependencies/clarificationColumns'
import DisplayButton from './components/displayButtons';
import RecommendationModel from './components/recommendationModel';
import { ROLES } from '../../../constants/Roles';
import { storage } from '../../../dependencies/store/storage';
import moment from 'moment'
import Form from 'react-bootstrap/Form';
import Button from '../../common/Button';
import Radio from '../../common/InputRadio';
import { FaBan } from 'react-icons/fa';
import { toast } from "react-toastify";

const Application80IACView = (props) => {
    const userRole = storage.getUserRole();   
    const getUser = storage.getUser();
    const sAppId = props.match.params.id;
    const [modalValue, setModalValue] = useState(false);
    const [buttonValue, setButtonValue] = useState('');
    const [recommendaded, setRecommendaded] = useState();
    const [recommendedComment, setRecommendedComment] = useState('');
    const [query, setQuery] = useState({})
    const [readMoreImbMember0, setReadMoreImbMember0] = useState(false);
    const [readMoreImbMember1, setReadMoreImbMember1] = useState(false);
    const [readMoreImbMember2, setReadMoreImbMember2] = useState(false);
    const [readMoreImbMember3, setReadMoreImbMember3] = useState(false);
    const [readMoreImbChair0, setReadMoreImbChair0] = useState(false);
    const [readMoreImbChair1, setReadMoreImbChair1] = useState(false);
    const [readMoreImbChair2, setReadMoreImbChair2] = useState(false);
    const [readMoreImbChair3, setReadMoreImbChair3] = useState(false);
    const [readMoreImbChair4, setReadMoreImbChair4] = useState(false);
    const [readMoreImbChair5, setReadMoreImbChair5] = useState(false);
    const [epRecommedstatusS, setEpRecommedstatus] = useState(false);
    // const [epRecommedValueS, setEpRecommedValue] = useState(false);
    const [imbMemStatusS, setImbMemStatus] = useState(false);
    const [imbChirStatusS, setImbChirStatus] = useState(false);
    useEffect(() => {
        props.get80IACApplicationDetails(sAppId);
    }, []);

    const callApi = () => {
        props.get80IACApplicationDetails(sAppId);
    }
    const validRoles = userRole === ROLES.SUPER_ADMIN || userRole === ROLES.ADMIN
    const superAdminRoles = userRole === ROLES.SUPER_ADMIN

    let epRecommedstatus = false
    let epRecommedValue = false
    if((userRole === ROLES.EP_1 || userRole === ROLES.EP_2) && props.iacApplicationViewData.recommendStatusEP) {        
        let status = props.iacApplicationViewData.recommendStatusEP.filter((el) => {
            return el.adminName == getUser.user.name
        })
        if(status && status[0].statusEP == 'PENDING') {
            epRecommedstatus = false
            // setEpRecommedstatus(false)
        }else {
            epRecommedstatus = true
            // setEpRecommedstatus(true)
            if(status) {
                // setEpRecommedValue(status[0].isRecommended)
            }
            
            epRecommedValue = status && status[0].isRecommended
        }        
    }

    let imbMemStatus = false
    if(userRole === ROLES.IMB_MEMBER && props.iacApplicationViewData.recommendStatusIMBMember) {        
        let status = props.iacApplicationViewData.recommendStatusIMBMember.filter((el) => {
            return el.adminName == getUser.user.name
        })
        if(status && status[0].statusImbMember == 'PENDING') {
            imbMemStatus = false
            // setImbMemStatus(false)
        }else {
            imbMemStatus = true
            // setImbMemStatus(true)
        }        
    }
    let imbChirStatus = false
    if(userRole === ROLES.IMB_CHAIR && props.iacApplicationViewData.recommendStatusIMBChair) {        
        let status = props.iacApplicationViewData.recommendStatusIMBChair
        if(status && status.statusImbChair == 'PENDING') {
            imbChirStatus = false
            // setImbChirStatus(false)
        }else {
            imbChirStatus = true
            // setImbChirStatus(true)
        }        
    }
    const MergedData = {...props.iacApplicationViewData.informationAboutStartup,...props.iacApplicationViewData.startupActivites}
    const contactDetailsForEP = props.iacApplicationViewData.contactDetailsStartup; 
    const DeclerationDetails = props.iacApplicationViewData.declaration;
    
    let startupActivites = props.iacApplicationViewData.startupActivites
    
    if((userRole === ROLES.EP_1 || userRole === ROLES.EP_2 || userRole === ROLES.IMB_MEMBER) && props.iacApplicationViewData.contactDetailsStartup) {
        delete contactDetailsForEP['Phone No'];
        delete contactDetailsForEP['Email Address']; 
        delete contactDetailsForEP['Permanent Account Number'];
    } 
    if((userRole === ROLES.EP_1 || userRole === ROLES.EP_2 || userRole === ROLES.IMB_MEMBER) && props.iacApplicationViewData.declaration) {
        delete DeclerationDetails['Name of the authorised signatory	'];
        delete DeclerationDetails['Designation']; 
    } 
    if((userRole === ROLES.SUPER_ADMIN || userRole === ROLES.ADMIN) && props.iacApplicationViewData.incompleteAction && startupActivites) {
        startupActivites = {...startupActivites,...props.iacApplicationViewData.incompleteAction}
    }
    const natureOfEntityNew = props.iacApplicationViewData.natureOfEntity;
    if((userRole === ROLES.EP_1 || userRole === ROLES.EP_2 || userRole === ROLES.IMB_MEMBER) && props.iacApplicationViewData.natureOfEntity) {
        delete natureOfEntityNew['Address and Business Location'];
    } 
    
    const clarificationsList = [];
    props.iacApplicationViewData.clarifications && props.iacApplicationViewData.clarifications.forEach(element => {
        var obj = {};
        obj["clarificationDate"] = moment(element.clarificationDate).format('DD-MM-YYYY');
        obj["clarification"] = element.clarification;
        clarificationsList.push(obj);
    });
    const toggleActionModal = () => {
        setModalValue(!modalValue);

    }
    const submitRecomendaton = () => {
        console.log(recommendedComment)
        if(!recommendaded && !recommendedComment && recommendedComment !== 'Recommended' || recommendedComment === 'Others') {
            toast.error('Please select any reason or option to not recommend!')
            return false;

        }
        if(recommendaded === true || recommendaded === false) {
            props.updateRecommendation({
                userId : sAppId,
                id : getUser.user.id, 
                isRecommended : recommendaded,
                recommendComment:recommendedComment
            })
        }      
        
        setEpRecommedstatus(true);
        callApi()
    }
    const getRecommendValue = (value) => {
        
        setRecommendedComment(value)
    }

    const setRecommendationData = (value) => {
        setRecommendaded(value)
        if(value) {
            setRecommendedComment('Recommended')
        }
        if(!value) {
            toggleActionModal();
            setButtonValue('RECOMENDATION') 
        }
    }

    const ImbRecommended = (data) => {
        if(!data) {
            toggleActionModal();
            setButtonValue('IMB_RECOMENDATION') 
            return false
        }
    }

    const hangleImbChairRecommend = (data) => {
        if(!data) {
            toggleActionModal();
            setButtonValue('IMB_CHAIR_RECOMENDATION') 
            return false
        }
                
    }
    const imbChairApiCall = (data,message='') => {
        if(!data && !message && message !== 'Recommended') {
            toast.error('Please select any reason or option to not recommend!')
            return false;

        }
        if(!data) {
            props.updateImbChairRecommendation({
                userId : sAppId,
                id : getUser.user.id, 
                isRecommended : data,
                recommendComment: message
            })
            setImbChirStatus(true)
        }else {
            props.updateImbChairRecommendation({
                userId : sAppId,
                id : getUser.user.id, 
                isRecommended : data,
                recommendComment: 'Recommended'
            })
            setImbChirStatus(true)
        }        
        callApi()
       
    }
    const imbMemberApiCAll = (data, message='') => {
        if(!data && !message && message !== 'Recommended') {
            toast.error('Please select any reason or option to not recommend!')
            return false;

        }
        if(!data) {
            props.updateImbRecommendation({
                userId : sAppId,
                id : getUser.user.id, 
                isRecommended : data,
                recommendComment: message
            })
            setImbMemStatus(true)
        }else {
            props.updateImbRecommendation({
                userId : sAppId,
                id : getUser.user.id, 
                isRecommended : data,
                recommendComment: 'Recommended'
            })
            setImbMemStatus(true)
        }
        
        callApi()
        
       
    }
    const benefeciceryYesRecommedHandle = (data) => {
        if(data) {
            props.updateIacAction({
                id: sAppId,
                status: "APPROVED"
            })
            setImbChirStatus(true)
        }else {
            toggleActionModal();
            setButtonValue('IMB_CHAIR_RECOMENDATION_YES') 
            return false
            
        }        
        callApi()       
        
    }
    const benefeciceryYesRecommedHandleYES = (data, message='') => {
            if(!data && !message && message !== 'Recommended') {
                toast.error('Please select any reason or option to not recommend!')
                return false;

            }
            props.updateIacAction({
                id: sAppId,
                status: "ON_HOLD",
                reason: message
            })
            setImbChirStatus(true)
            callApi() 
    }
    return (
        <div className="p30 app-80-iac-wrapper">
            <SectionTitle>
                {
                    ((userRole == ROLES.EP_1)?"Startup India : Expert Panel (Financial)":"")
                }
                {
                    ((userRole == ROLES.EP_2)?"Startup India : Expert Panel (Non Financial)":"")
                }
                {
                    ((userRole == ROLES.IMB_MEMBER)?"Startup India : IMB Members":"")
                }
                {
                    ((userRole == ROLES.IMB_CHAIR)?"Startup India : IMB Chair":"")
                }
                {
                    ((userRole === ROLES.ADMIN || userRole === ROLES.SUPER_ADMIN)?"Startup India : Applications for Section 80":'')
                }
                
            </SectionTitle>
            <div className="entity-detail-wrap">
                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Startup Details</h3>
                    {
                        ((userRole === ROLES.EP_1 || userRole === ROLES.EP_2 || userRole === ROLES.IMB_MEMBER)?(
                            
                            <KeyValue items={props.iacApplicationViewData.natureOfEntity ? natureOfEntityNew : ''} />
                        ):(
                            <KeyValue items={props.iacApplicationViewData.natureOfEntity ? props.iacApplicationViewData.natureOfEntity : ''} />
                        ))
                    }
                    
                </div>
                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Contact details of Startup</h3>
                    {
                        ((userRole === ROLES.EP_1 || userRole === ROLES.EP_2 || userRole === ROLES.IMB_MEMBER)?(
                            <KeyValue items={props.iacApplicationViewData.contactDetailsStartup ? props.iacApplicationViewData.contactDetailsStartup : ''} />
                        ):'')
                    }
                </div>
                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Existing/proposed activities</h3>
                    <KeyValue items={props.iacApplicationViewData.existingProposesActivity ? props.iacApplicationViewData.existingProposesActivity : ''} />
                </div>
                <div className="entity-detail-inner p-20 mb-15">
                    <h3> Annual Accounts of the startup</h3>
                    <Table
                        dataTable={props.iacApplicationViewData.annualAccountStatement}
                        columns={annualAccountColumns}
                        keyField='financialYear'
                    />

                </div>
                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Income Tax returns</h3>
                    <Table
                        dataTable={props.iacApplicationViewData.itrStatement}
                        columns={itrColumns}
                        keyField='financialYear'
                    />
                    <KeyValue items={props.iacApplicationViewData.detailsOfCert ? props.iacApplicationViewData.detailsOfCert : ''} />
                </div>

                <div className="entity-detail-inner p-20 mb-15">
                    {
                        ((userRole == ROLES.EP_1)?(
                            <h3>Additional Information</h3>
                        ):(
                            <h3>Clarifications</h3>
                        ))
                    }
                    <Table
                        dataTable={clarificationsList}
                        columns={clarificationColumns}
                        sizePerPage="20"
                        keyField='clarificationDate'
                    />
                </div>

                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Supporting Documents</h3>
                    <KeyValue items={props.iacApplicationViewData.supportingDocuments ? props.iacApplicationViewData.supportingDocuments : ''} />
                </div>
                <div className="entity-detail-inner p-20 mb-15">
                    
                    {
                        ((userRole == ROLES.EP_1 || userRole == ROLES.EP_2)?(
                            <>
                                <h3>Information as per Startup Recognation Application</h3>
                                <KeyValue items={props.iacApplicationViewData.informationAboutStartup ? MergedData : ''} />
                            </>
                        ):(                            
                            <>  
                                <h3>Information about the Startup</h3>
                                <KeyValue items={props.iacApplicationViewData.informationAboutStartup ? props.iacApplicationViewData.informationAboutStartup : ''} />
                            </>
                        ))
                    }
                    
                </div>
                {
                    ((userRole == ROLES.EP_1 || userRole == ROLES.EP_2)?(
                        ''   
                    ):(
                        <>
                            <div className="entity-detail-inner p-20 mb-15">
                                <h3>Startup Activites</h3>
                                <KeyValue items={props.iacApplicationViewData.startupActivites ? startupActivites : ''} />
                            </div>
                        </>
                    ))
                }
                <div className="entity-detail-inner p-20 mb-15">
                    <h3>Declaration</h3>
                    {
                        ((userRole === ROLES.IMB_MEMBER || userRole === ROLES.EP_1 || userRole === ROLES.EP_2)?(
                            <KeyValue items={props.iacApplicationViewData.declaration ? DeclerationDetails : ''} />
                        ):(                            
                            <KeyValue items={props.iacApplicationViewData.declaration ? props.iacApplicationViewData.declaration : ''} />
                        ))
                    }
                    
                </div>
                <div>
                    <h3 style={{background:'#1C4FAD', color:'white', borderRadius:'4px'}}>
                        <span style={{marginLeft:'30px'}}>Beneficiary</span>
                        <span style={{marginLeft:'40.7%'}}>: &nbsp;
                            {
                                ((props.iacApplicationViewData.isBeneficiary)?("Yes "+ props.iacApplicationViewData.beneficiaryReason):("No")) 
                            }
                        </span>
                    </h3> 
                </div>
                {/* Recomendation Start */}
                {
                    ((userRole == ROLES.EP_1 || userRole == ROLES.EP_2)?(
                        <>
                        
                        <div style={{marginLeft:'30px'}}>
                            <span>Recomendation: </span>

                            <div className="inner-wrap clearfix form-check-inline" style={{marginLeft:'20px'}}>
                                {
                                    ((epRecommedstatus || epRecommedstatusS) && epRecommedValue)?(
                                        <input checked={true}  disabled={(epRecommedstatus || epRecommedstatusS)?true:false} id="recom" onClick={() => setRecommendationData(true)} name="recoment" type="radio" className="input-element filled form-check-input" value="yes" />
                                    ):(
                                        <input  disabled={(epRecommedstatus || epRecommedstatusS)?true:false} id="recom" onClick={() => setRecommendationData(true)} name="recoment" type="radio" className="input-element filled form-check-input" value="yes" />
                                    )
                                }
                                
                                <i className="custom-radio"></i><label htmlFor="recom" className="">Yes</label>
                            </div>
                            <div className="inner-wrap clearfix form-check-inline">
                                {
                                    ((epRecommedstatus) && !epRecommedValue)?(
                                        <input checked={true} disabled={(epRecommedstatus || epRecommedstatusS)?true:false} id="recomno" name="recoment" onClick={() => setRecommendationData(false)} type="radio" className="input-element filled form-check-input" value="No" />
                                    ):(
                                        <input disabled={(epRecommedstatus || epRecommedstatusS)?true:false} id="recomno" name="recoment" onClick={() => setRecommendationData(false)} type="radio" className="input-element filled form-check-input" value="No" />
                                    )
                                }
                                
                                <i className="custom-radio"></i><label htmlFor="recomno" className="">No</label>
                            </div>
                        </div>
                        <div style={{marginTop:'20px' , marginLeft:'20px'}}>
                            <Button disabled={(epRecommedstatus || epRecommedstatusS)?true:false} onClick={submitRecomendaton} type="button" className={(epRecommedstatus || epRecommedstatusS)?'bttn not-allowed':'bttn'} text="Submit"  />
                        </div>
                        <br />
                        </>
                    ):(''))                    
                }  
                {
                    ((userRole === ROLES.IMB_MEMBER && (props.iacApplicationViewData.isBeneficiary == false))?(
                        <>
                           
                            <div className='beneficiary-table-wrap'>
                                <div className="beneficiary-tbl1">
                                    <div className='table-scroll table-height-150'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className='width-140-finc '>Expert Panel (Non Financial)</th>
                                                    <th>Date</th>
                                                    <th>Recommended</th>
                                                    <th>Comment</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    props.iacApplicationViewData.recommendStatusEP? (
                                                        props.iacApplicationViewData.recommendStatusEP[0].isRecommended ? (
                                                            <>
                                                                <tr>
                                                                    <td className="blue-td">{props.iacApplicationViewData.recommendStatusEP[0].adminName}</td>
                                                                    <td>{ moment(props.iacApplicationViewData.recommendStatusEP[0].actionTakenDate).format('DD/MM/YYYY') }</td>
                                                                    <td className="td-blue-color"><b>Yes</b></td>
                                                                    <td className="gray-td"></td>
                                                                </tr>
                                                            </>
                                                        ):(
                                                            <>                                                                
                                                                <tr>
                                                                    <td className="yellow-td">{props.iacApplicationViewData.recommendStatusEP[0].adminName}</td>
                                                                    <td>{ moment(props.iacApplicationViewData.recommendStatusEP[0].actionTakenDate).format('DD/MM/YYYY') }</td>
                                                                    <td className="td-yellow-color"><b>No</b></td>
                                                                    <td>
                                                                        {
                                                                            props.iacApplicationViewData.recommendStatusEP[0].recommendComment.length > 30 ?(
                                                                                <>
                                                                                    {readMoreImbMember0 ?  props.iacApplicationViewData.recommendStatusEP[0].recommendComment : `${props.iacApplicationViewData.recommendStatusEP[0].recommendComment.substring(0,30)} ...`}
                                                                                </>
                                                                            ):props.iacApplicationViewData.recommendStatusEP[0].recommendComment
                                                                        }
                                                                        {
                                                                            props.iacApplicationViewData.recommendStatusEP[0].recommendComment.length > 30 ?(
                                                                                <button className="btn btn-link " onClick={() => setReadMoreImbMember0(!readMoreImbMember0)}>
                                                                                    {readMoreImbMember0 ? "show less" : "read more"} 
                                                                                </button>
                                                                            ):('')
                                                                        } 
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    ):(<></>)
                                                }  
                                                {
                                                    props.iacApplicationViewData.recommendStatusEP? (
                                                        props.iacApplicationViewData.recommendStatusEP[1].isRecommended ? (
                                                            <>
                                                                <tr>
                                                                    <td className="blue-td">{props.iacApplicationViewData.recommendStatusEP[1].adminName}</td>
                                                                    <td>{ moment(props.iacApplicationViewData.recommendStatusEP[1].actionTakenDate).format('DD/MM/YYYY') }</td>
                                                                    <td className="td-blue-color"><b>Yes</b></td>
                                                                    <td className="gray-td"></td>
                                                                </tr>
                                                            </>
                                                        ):(
                                                            <>                                                                
                                                                <tr>
                                                                    <td className="yellow-td">{props.iacApplicationViewData.recommendStatusEP[1].adminName}</td>
                                                                    <td>{ moment(props.iacApplicationViewData.recommendStatusEP[1].actionTakenDate).format('DD/MM/YYYY') }</td>
                                                                    <td className="td-yellow-color"><b>No</b></td>
                                                                    <td>
                                                                        {
                                                                            props.iacApplicationViewData.recommendStatusEP[1].recommendComment.length > 30 ?(
                                                                                <>
                                                                                    {readMoreImbMember1 ? props.iacApplicationViewData.recommendStatusEP[1].recommendComment : `${props.iacApplicationViewData.recommendStatusEP[1].recommendComment.substring(0,30)} ...` }
                                                                                </>
                                                                            ):props.iacApplicationViewData.recommendStatusEP[1].recommendComment
                                                                        }
                                                                        {
                                                                            props.iacApplicationViewData.recommendStatusEP[1].recommendComment.length > 30 ?(
                                                                                <button className="btn btn-link " onClick={() => setReadMoreImbMember1(!readMoreImbMember1)}>
                                                                                    {readMoreImbMember1 ? "show less" : "  read more"} 
                                                                                </button>
                                                                            ):('')
                                                                        }                                                                        
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    ):(<></>)
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                 
                                </div>
                    
                    
                                <div className="beneficiary-tbl2 mt-top-1450">
                                    <div className='table-scroll'>
                                        <table>
                                            <thead>
                                                <tr>
                                                <th className='width-140-finc'>Expert Panel (Financial)</th>
                                                <th>Date</th>
                                                <th>Recommended</th>
                                                <th>Comment</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    props.iacApplicationViewData.recommendStatusEP? (
                                                        props.iacApplicationViewData.recommendStatusEP[2].isRecommended ? (
                                                            <>
                                                                <tr>
                                                                    <td className="blue-td">{props.iacApplicationViewData.recommendStatusEP[2].adminName}</td>
                                                                    <td>{ moment(props.iacApplicationViewData.recommendStatusEP[2].actionTakenDate).format('DD/MM/YYYY') }</td>
                                                                    <td className="td-blue-color"><b>Yes</b></td>
                                                                    <td className="gray-td"></td>
                                                                </tr>
                                                            </>
                                                        ):(
                                                            <>                                                                
                                                                <tr>
                                                                    <td className="yellow-td">{props.iacApplicationViewData.recommendStatusEP[2].adminName}</td>
                                                                    <td>{ moment(props.iacApplicationViewData.recommendStatusEP[2].actionTakenDate).format('DD/MM/YYYY') }</td>
                                                                    <td className="td-yellow-color"><b>No</b></td>
                                                                    <td>
                                                                        {
                                                                            props.iacApplicationViewData.recommendStatusEP[2].recommendComment.length > 30 ?(
                                                                                <>
                                                                                    {readMoreImbMember2 ? props.iacApplicationViewData.recommendStatusEP[2].recommendComment : `${props.iacApplicationViewData.recommendStatusEP[2].recommendComment.substring(0,30)} ...` }
                                                                                </>
                                                                            ):props.iacApplicationViewData.recommendStatusEP[2].recommendComment
                                                                        }
                                                                        {
                                                                            props.iacApplicationViewData.recommendStatusEP[2].recommendComment.length > 30 ?(
                                                                                <button className="btn btn-link " onClick={() => setReadMoreImbMember2(!readMoreImbMember2)}>
                                                                                    {readMoreImbMember2 ? "show less" : "  read more"} 
                                                                                </button>
                                                                            ):('')
                                                                        }     
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    ):(<></>)
                                                }  
                                                {
                                                    props.iacApplicationViewData.recommendStatusEP? (
                                                        props.iacApplicationViewData.recommendStatusEP[3].isRecommended ? (
                                                            <>
                                                                <tr>
                                                                    <td className="blue-td">{props.iacApplicationViewData.recommendStatusEP[3].adminName}</td>
                                                                    <td>{ moment(props.iacApplicationViewData.recommendStatusEP[3].actionTakenDate).format('DD/MM/YYYY') }</td>
                                                                    <td className="td-blue-color"><b>Yes</b></td>
                                                                    <td className="gray-td"></td>
                                                                </tr>
                                                            </>
                                                        ):(
                                                            <>                                                                
                                                                <tr>
                                                                    <td className="yellow-td">{props.iacApplicationViewData.recommendStatusEP[3].adminName}</td>
                                                                    <td>{ moment(props.iacApplicationViewData.recommendStatusEP[3].actionTakenDate).format('DD/MM/YYYY') }</td>
                                                                    <td className="td-yellow-color"><b>No</b></td>
                                                                    <td>
                                                                        {
                                                                            props.iacApplicationViewData.recommendStatusEP[3].recommendComment.length > 30 ?(
                                                                                <>
                                                                                    {readMoreImbMember3 ? props.iacApplicationViewData.recommendStatusEP[3].recommendComment : `${props.iacApplicationViewData.recommendStatusEP[3].recommendComment.substring(0,30)} ...` }
                                                                                </>
                                                                            ):props.iacApplicationViewData.recommendStatusEP[3].recommendComment
                                                                        }
                                                                        {
                                                                            props.iacApplicationViewData.recommendStatusEP[3].recommendComment.length > 30 ?(
                                                                                <button className="btn btn-link " onClick={() => setReadMoreImbMember3(!readMoreImbMember3)}>
                                                                                    {readMoreImbMember3 ? "show less" : "  read more"} 
                                                                                </button>
                                                                            ):('')
                                                                        } 
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    ):(<></>)
                                                } 
                                            </tbody>
                                        </table>
                                    </div>
                            
                                 
                                </div>  
                                <div className='btn-wrapp'>
                                    <div className='btn-left-49'> <button disabled={(imbMemStatus || imbMemStatusS)?true:false} onClick={() => imbMemberApiCAll(true)} className={(imbMemStatus || imbMemStatusS)?"btn btn-success  not-allowed":"btn btn-success"}>Recommended</button> </div>
                             
                                    <div className='btn-right-49'> <button disabled={(imbMemStatus || imbMemStatusS)?true:false} onClick={() => ImbRecommended(false)} className={(imbMemStatus || imbMemStatusS)? "btn btn-danger not-allowed":"btn btn-danger"}>Not Recommended</button>
                                </div>     </div>                             
                            </div>
                            <br />
                        </>
                    ):'')
                } 
                {
                    ((userRole === ROLES.IMB_CHAIR && (props.iacApplicationViewData.isBeneficiary == false))?(
                        <>
                            
                            <div className='beneficiary-table-wrap'>
                                <div class="ibm-member">
                                    <div class="beneficiary-tbl1">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>IMB Member</th>
                                                    <th>Date</th>
                                                    <th>Recommended</th>
                                                    <th>Comment</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    props.iacApplicationViewData.recommendStatusIMBMember? (
                                                        props.iacApplicationViewData.recommendStatusIMBMember[0].isRecommended ? (
                                                            <>
                                                                <tr>
                                                                    <td className="blue-td">{props.iacApplicationViewData.recommendStatusIMBMember[0].adminName}</td>
                                                                    <td>{ moment(props.iacApplicationViewData.recommendStatusIMBMember[0].actionTakenDate).format('DD/MM/YYYY') }</td>
                                                                    <td className="td-blue-color"><b>Yes</b></td>
                                                                    <td className="gray-td"></td>
                                                                </tr>
                                                            </>
                                                        ):(
                                                            <>                                                                
                                                                <tr>
                                                                    <td className="yellow-td">{props.iacApplicationViewData.recommendStatusIMBMember[0].adminName}</td>
                                                                    <td>{ moment(props.iacApplicationViewData.recommendStatusIMBMember[0].actionTakenDate).format('DD/MM/YYYY') }</td>
                                                                    <td className="td-yellow-color"><b>No</b></td>
                                                                    <td>
                                                                        {
                                                                            props.iacApplicationViewData.recommendStatusIMBMember[0].recommendComment.length > 30 ?(
                                                                                <>
                                                                                    {readMoreImbChair0 ? props.iacApplicationViewData.recommendStatusIMBMember[0].recommendComment : `${props.iacApplicationViewData.recommendStatusIMBMember[0].recommendComment.substring(0,30)} ...` }
                                                                                </>
                                                                            ):props.iacApplicationViewData.recommendStatusIMBMember[0].recommendComment
                                                                        }
                                                                        {
                                                                            props.iacApplicationViewData.recommendStatusIMBMember[0].recommendComment.length > 30 ?(
                                                                                <button className="btn btn-link " onClick={() => setReadMoreImbChair0(!readMoreImbChair0)}>
                                                                                    {readMoreImbChair0 ? "show less" : "  read more"} 
                                                                                </button>
                                                                            ):('')
                                                                        } 
                                                                    
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    ):(<></>)
                                                }  
                                                {
                                                    props.iacApplicationViewData.recommendStatusIMBMember? (
                                                        props.iacApplicationViewData.recommendStatusIMBMember[1].isRecommended ? (
                                                            <>
                                                                <tr>
                                                                    <td className="blue-td">{props.iacApplicationViewData.recommendStatusIMBMember[1].adminName}</td>
                                                                    <td>{ moment(props.iacApplicationViewData.recommendStatusIMBMember[1].actionTakenDate).format('DD/MM/YYYY') }</td>
                                                                    <td className="td-blue-color"><b>Yes</b></td>
                                                                    <td className="gray-td"></td>
                                                                </tr>
                                                            </>
                                                        ):(
                                                            <>                                                                
                                                                <tr>
                                                                    <td className="yellow-td">{props.iacApplicationViewData.recommendStatusIMBMember[1].adminName}</td>
                                                                    <td>{ moment(props.iacApplicationViewData.recommendStatusIMBMember[1].actionTakenDate).format('DD/MM/YYYY') }</td>
                                                                    <td className="td-yellow-color"><b>No</b></td>
                                                                    <td>
                                                                        {
                                                                            props.iacApplicationViewData.recommendStatusIMBMember[1].recommendComment.length > 30 ?(
                                                                                <>
                                                                                    {readMoreImbChair1 ? props.iacApplicationViewData.recommendStatusIMBMember[1].recommendComment : `${props.iacApplicationViewData.recommendStatusIMBMember[1].recommendComment.substring(0,30)} ...` }
                                                                                </>
                                                                            ):props.iacApplicationViewData.recommendStatusIMBMember[1].recommendComment
                                                                        }
                                                                        {
                                                                            props.iacApplicationViewData.recommendStatusIMBMember[1].recommendComment.length > 30 ?(
                                                                                <button className="btn btn-link " onClick={() => setReadMoreImbChair1(!readMoreImbChair1)}>
                                                                                    {readMoreImbChair1 ? "show less" : "  read more"} 
                                                                                </button>
                                                                            ):('')
                                                                        } 
                                                                    
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    ):(<></>)
                                                }                                     
                                            </tbody>
                                        </table>
                                    </div>
                                  
                                </div>
                                <div class="ep-2">
                                    <div className='scrolbar-imb-chair'>
                                        <div class="beneficiary-tbl2">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Expert Panel (Non Financial)</th>
                                                        <th>Date</th>
                                                        <th>Recommended</th>
                                                        <th>Comment</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        props.iacApplicationViewData.recommendStatusEP? (
                                                            props.iacApplicationViewData.recommendStatusEP[0].isRecommended ? (
                                                                <>
                                                                    <tr>
                                                                        <td className="blue-td">{props.iacApplicationViewData.recommendStatusEP[0].adminName}</td>
                                                                        <td>{ moment(props.iacApplicationViewData.recommendStatusEP[0].actionTakenDate).format('DD/MM/YYYY') }</td>
                                                                        <td className="td-blue-color"><b>Yes</b></td>
                                                                        <td className="gray-td"></td>
                                                                    </tr>
                                                                </>
                                                            ):(
                                                                <>                                                                
                                                                    <tr>
                                                                        <td className="yellow-td">{props.iacApplicationViewData.recommendStatusEP[0].adminName}</td>
                                                                        <td>{ moment(props.iacApplicationViewData.recommendStatusEP[0].actionTakenDate).format('DD/MM/YYYY') }</td>
                                                                        <td className="td-yellow-color"><b>No</b></td>
                                                                        <td>
                                                                            {
                                                                                props.iacApplicationViewData.recommendStatusEP[0].recommendComment.length > 30 ?(
                                                                                    <>
                                                                                        {readMoreImbChair2 ? props.iacApplicationViewData.recommendStatusEP[0].recommendComment : `${props.iacApplicationViewData.recommendStatusEP[0].recommendComment.substring(0,30)} ...` }
                                                                                    </>
                                                                                ):props.iacApplicationViewData.recommendStatusEP[0].recommendComment
                                                                            }
                                                                            {
                                                                                props.iacApplicationViewData.recommendStatusEP[0].recommendComment.length > 30 ?(
                                                                                    <button className="btn btn-link " onClick={() => setReadMoreImbChair2(!readMoreImbChair2)}>
                                                                                        {readMoreImbChair2 ? "show less" : "  read more"} 
                                                                                    </button>
                                                                                ):('')
                                                                            } 
                                                                        
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        ):(<></>)
                                                    }  
                                                    {
                                                        props.iacApplicationViewData.recommendStatusEP? (
                                                            props.iacApplicationViewData.recommendStatusEP[1].isRecommended ? (
                                                                <>
                                                                    <tr>
                                                                        <td className="blue-td">{props.iacApplicationViewData.recommendStatusEP[1].adminName}</td>
                                                                        <td>{ moment(props.iacApplicationViewData.recommendStatusEP[1].actionTakenDate).format('DD/MM/YYYY') }</td>
                                                                        <td className="td-blue-color"><b>Yes</b></td>
                                                                        <td className="gray-td"></td>
                                                                    </tr>
                                                                </>
                                                            ):(
                                                                <>                                                                
                                                                    <tr>
                                                                        <td className="yellow-td">{props.iacApplicationViewData.recommendStatusEP[1].adminName}</td>
                                                                        <td>{ moment(props.iacApplicationViewData.recommendStatusEP[1].actionTakenDate).format('DD/MM/YYYY') }</td>
                                                                        <td className="td-yellow-color"><b>No</b></td>
                                                                        <td>
                                                                            {
                                                                                props.iacApplicationViewData.recommendStatusEP[1].recommendComment.length > 30 ?(
                                                                                    <>
                                                                                        {readMoreImbChair3 ? props.iacApplicationViewData.recommendStatusEP[1].recommendComment : `${props.iacApplicationViewData.recommendStatusEP[1].recommendComment.substring(0,30)} ...` }
                                                                                    </>
                                                                                ):props.iacApplicationViewData.recommendStatusEP[1].recommendComment
                                                                            }
                                                                            {
                                                                                props.iacApplicationViewData.recommendStatusEP[1].recommendComment.length > 30 ?(
                                                                                    <button className="btn btn-link " onClick={() => setReadMoreImbChair3(!readMoreImbChair3)}>
                                                                                        {readMoreImbChair3 ? "show less" : "  read more"} 
                                                                                    </button>
                                                                                ):('')
                                                                            } 
                                                                        
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        ):(<></>)
                                                    }  
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="beneficiary-tbl3">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Expert Panel (Financial)</th>
                                                        <th>Date</th>
                                                        <th>Recommended</th>
                                                        <th>Comment</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        props.iacApplicationViewData.recommendStatusEP? (
                                                            props.iacApplicationViewData.recommendStatusEP[2].isRecommended ? (
                                                                <>
                                                                    <tr>
                                                                        <td className="blue-td">{props.iacApplicationViewData.recommendStatusEP[2].adminName}</td>
                                                                        <td>{ moment(props.iacApplicationViewData.recommendStatusEP[2].actionTakenDate).format('DD/MM/YYYY') }</td>
                                                                        <td className="td-blue-color"><b>Yes</b></td>
                                                                        <td className="gray-td"></td>
                                                                    </tr>
                                                                </>
                                                            ):(
                                                                <>                                                                
                                                                    <tr>
                                                                        <td className="yellow-td">{props.iacApplicationViewData.recommendStatusEP[2].adminName}</td>
                                                                        <td>{ moment(props.iacApplicationViewData.recommendStatusEP[2].actionTakenDate).format('DD/MM/YYYY') }</td>
                                                                        <td className="td-yellow-color"><b>No</b></td>
                                                                        <td>
                                                                            {
                                                                                props.iacApplicationViewData.recommendStatusEP[2].recommendComment.length > 30 ?(
                                                                                    <>
                                                                                        {readMoreImbChair4 ? props.iacApplicationViewData.recommendStatusEP[2].recommendComment : `${props.iacApplicationViewData.recommendStatusEP[2].recommendComment.substring(0,30)} ...` }
                                                                                    </>
                                                                                ):props.iacApplicationViewData.recommendStatusEP[2].recommendComment
                                                                            }
                                                                            {
                                                                                props.iacApplicationViewData.recommendStatusEP[2].recommendComment.length > 30 ?(
                                                                                    <button className="btn btn-link " onClick={() => setReadMoreImbChair4(!readMoreImbChair4)}>
                                                                                        {readMoreImbChair4 ? "show less" : "  read more"} 
                                                                                    </button>
                                                                                ):('')
                                                                            } 
                                                                        
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        ):(<></>)
                                                    }  
                                                    {
                                                        props.iacApplicationViewData.recommendStatusEP? (
                                                            props.iacApplicationViewData.recommendStatusEP[3].isRecommended ? (
                                                                <>
                                                                    <tr>
                                                                        <td className="blue-td">{props.iacApplicationViewData.recommendStatusEP[3].adminName}</td>
                                                                        <td>{ moment(props.iacApplicationViewData.recommendStatusEP[3].actionTakenDate).format('DD/MM/YYYY') }</td>
                                                                        <td className="td-blue-color"><b>Yes</b></td>
                                                                        <td className="gray-td"></td>
                                                                    </tr>
                                                                </>
                                                            ):(
                                                                <>                                                                
                                                                    <tr>
                                                                        <td className="yellow-td">{props.iacApplicationViewData.recommendStatusEP[3].adminName}</td>
                                                                        <td>{ moment(props.iacApplicationViewData.recommendStatusEP[3].actionTakenDate).format('DD/MM/YYYY') }</td>
                                                                        <td className="td-yellow-color"><b>No</b></td>
                                                                        <td>
                                                                            {
                                                                                props.iacApplicationViewData.recommendStatusEP[3].recommendComment.length > 30 ?(
                                                                                    <>
                                                                                        {readMoreImbChair5 ? props.iacApplicationViewData.recommendStatusEP[3].recommendComment : `${props.iacApplicationViewData.recommendStatusEP[1].recommendComment.substring(0,30)} ...` }
                                                                                    </>
                                                                                ):props.iacApplicationViewData.recommendStatusEP[3].recommendComment
                                                                            }
                                                                            {
                                                                                props.iacApplicationViewData.recommendStatusEP[3].recommendComment.length > 30 ?(
                                                                                    <button className="btn btn-link " onClick={() => setReadMoreImbChair5(!readMoreImbChair5)}>
                                                                                        {readMoreImbChair5 ? "show less" : "  read more"} 
                                                                                    </button>
                                                                                ):('')
                                                                            } 
                                                                        
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        ):(<></>)
                                                    } 
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                               
                                </div>
                                <div className='btn-wrapp'>
                                    <div className='btn-left'> <button disabled={(imbChirStatus || imbChirStatusS)?true:false} onClick={() => imbChairApiCall(true)} class={(imbChirStatus || imbChirStatusS)?"btn btn-success not-allowed":"btn btn-success"}>Recommended</button> </div>
                                    <div className='btn-right'><button disabled={(imbChirStatus || imbChirStatusS)?true:false} onClick={() => hangleImbChairRecommend(false) } class={(imbChirStatus || imbChirStatusS)?"btn btn-danger not-allowed":"btn btn-danger"}>Not Recommended</button></div>
                                </div>
                            </div>
                            <br></br>
                        </>
                    ):( <> </>))
                }    
                {
                    (props.iacApplicationViewData.isBeneficiary == true) && userRole === ROLES.IMB_CHAIR ? (
                        <>
                            
                            <div>
                                <button disabled={(imbChirStatus || imbChirStatusS)?true:false} onClick={() => benefeciceryYesRecommedHandle(true) } style={{margin:'15px',marginLeft:'39%'}} className={(imbChirStatus || imbChirStatusS)?"btn btn-success not-allowed":"btn btn-success"}>Recommended</button>
                                <button disabled={(imbChirStatus || imbChirStatusS)?true:false} onClick={() => benefeciceryYesRecommedHandle(false) } style={{margin:'15px'}} className={(imbChirStatus || imbChirStatusS)?"btn btn-danger not-allowed":"btn btn-danger"}>Not Recommended</button>
                            </div>
                        </>
                    ):''
                }
                {
                    (props.iacApplicationViewData.isBeneficiary == true) && userRole === ROLES.IMB_MEMBER ? (
                        <>
                            
                            <div>
                                <button disabled={(imbMemStatus || imbMemStatusS)?true:false} onClick={() => imbMemberApiCAll(true)} style={{margin:'15px',marginLeft:'39%'}} className={(imbChirStatus || imbChirStatusS)?"btn btn-success not-allowed":"btn btn-success"}>Recommended</button>
                                <button disabled={(imbMemStatus || imbMemStatusS)?true:false} onClick={() => ImbRecommended(false)} style={{margin:'15px'}} className={(imbChirStatus || imbChirStatusS)?"btn btn-danger not-allowed":"btn btn-danger"}>Not Recommended</button>
                            </div>
                        </>
                    ):''
                }
                {modalValue ?
                    <RecommendationModel
                        modalValue={modalValue}
                        toggleActionModal={toggleActionModal}
                        actionValue={buttonValue}
                        callApi={getRecommendValue}
                        imbChairCall={imbChairApiCall}
                        imbMemberCall={imbMemberApiCAll}
                        imbChairCallYes={benefeciceryYesRecommedHandleYES}
                        id={sAppId
                            ?
                            sAppId : ''}
                    /> : null
                }         
                {/* Recomendation end  */}
            </div>
            {
                validRoles &&
                <DisplayButton showBtn={superAdminRoles} showAdmin={validRoles} callApi={callApi} appId={sAppId ? sAppId : ''} status={props.iacApplicationViewData.status ? props.iacApplicationViewData.status : ''} />
            }
            {/* <a href="#" onClick={handleclick}>click me</a> */}




        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        iacApplicationViewData: state.Applicatio80IACReducer.applicatio80IAC
    }
}
export default connect(mapStateToProps, { get80IACApplicationDetails, updateRecommendation,updateImbRecommendation,updateImbChairRecommendation,updateIacAction })(withRouter(Application80IACView))