import api from "../utils/Api";
import { UPDATE_PASSWORD_URL } from "../../constants/ApplicationUrl";
import { Redirect, withRouter } from 'react-router-dom';
import {
    changePasswordAction,
    changePasswordFailedAction,
    changePasswordSuccessAction,
    isLoading,
    logoutAction,
} from "../../constants/actionConstants";
import { storage } from "../store/storage";

export const clearMessage = () => (dispatch) => {
    dispatch(changePasswordAction(''));
}

export const changePasswordService = ({ newPassword, confirmPassword, oldPassword }) => (dispatch) => {
    dispatch(isLoading(true));
    return api.POST(UPDATE_PASSWORD_URL, { confirmPassword, oldPassword, newPassword })
        .then((data) => {
            if (data) {
               
                if (data.data.status == true) {
                   
                    dispatch(changePasswordSuccessAction(data.data.message + ' Redirecting you to login page.'));
                    dispatch(logoutAction);
                    storage.clear();
                    dispatch(isLoading(false));
                    const { data: { status, message } } = data;
                    return { status, message };
                } else {
                    
                    dispatch(changePasswordFailedAction(data.data.message));
                    const { data: { status, message } } = data;
                    dispatch(isLoading(false));

                    return { status, message };
                }
            } else {
                dispatch(changePasswordFailedAction('Internal Server Error'));
            }
            dispatch(isLoading(false));
            return false;
        })
        .catch(error => {
            dispatch(changePasswordFailedAction(error.toString()));
            dispatch(isLoading(false));
            return false;
        })
};
