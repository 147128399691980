import { get, isEmpty } from 'lodash';
import { startupServices } from '../../../dependencies/services/startupApplicationService';
import {
    CURRENT_STARTUP_APPLICATION,
    SET_NATURE_OF_THE_ENTITY,
    SET_STARTUP_SERVICES,
    SET_STARTUP_SECTOR,
    SET_STATE,
    SET_STARTUP_DISTRICT,
    SET_STARTUP_SUB_DISTRICT,
    SET_STARTUP_INDUSTRY,
    INCOMPLETE_LIST,
    AUDIT_TRAIL
} from '../../../constants/actionConstants';
import { isLoading } from '../../../constants/actionConstants';
import { toast } from 'react-toastify';
import _ from 'lodash';
import React from 'react';

export const getInfoForStartupAppliactionForm = (params) => dispatch => {
    dispatch(isLoading(true));

    return getBindData(params).then((data) => {
        const { startupApplicationInfo, natureOfTheEntity, startupIndustryList, startupServiceList, stateList } = data;
        if (startupApplicationInfo) {
            dispatch({
                type: CURRENT_STARTUP_APPLICATION,
                payload: startupApplicationInfo
            });
        }
        if (natureOfTheEntity) {
            const { data } = natureOfTheEntity;
            dispatch({
                type: SET_NATURE_OF_THE_ENTITY,
                payload: data
            });
        }
        if (startupIndustryList) {
            const { data } = startupIndustryList;
            dispatch({
                type: SET_STARTUP_INDUSTRY,
                payload: data
            });
        }
        if (startupServiceList) {
            const { data } = startupServiceList;
            dispatch({
                type: SET_STARTUP_SERVICES,
                payload: data
            });
        }
        if (stateList) {
            const { data } = stateList;
            dispatch({
                type: SET_STATE,
                payload: data
            });

        }
        dispatch(isLoading(false));
    })
}

export function getDistrictData(params) {
    const { stateId } = params;
    return async function (dispatch) {
        dispatch(isLoading(true));
        if (stateId) {
            await startupServices.getDistrict(params).then((response) => {
                const { data = [], status = false } = response;
                if (status) {
                    dispatch({
                        type: SET_STARTUP_SUB_DISTRICT,
                        payload: []
                    });
                    dispatch({
                        type: SET_STARTUP_DISTRICT,
                        payload: data
                    });
                }
                dispatch(isLoading(false));
                return { status }
            }).catch(e => {
                dispatch(isLoading(false));
            })
        }
        dispatch(isLoading(false));
    }
}

async function getBindData(params) {
    const { id } = params;
    const natureOfTheEntity = await startupServices.getNatureOfTheEntity().catch(e => { return { data: [], status: false } });
    const startupIndustryList = await startupServices.fetchStartupIndustryList().catch(e => { return { data: [], status: false } });
    const startupServiceList = await startupServices.fetchStartupServiceList().catch(e => { return { data: [], status: false } });
    const stateList = await startupServices.fetchStateList().catch(e => { return { data: [], status: false } });
    const startupApplicationInfo = await startupServices.getStartupApplicationInfo(id).catch(e => { return {} });

    return { startupApplicationInfo, natureOfTheEntity, startupIndustryList, startupServiceList, stateList }
}

export function getAuditTrail(params) {
    return async function (dispatch) {
        return await startupServices.fetchAuditTrailList(params).then((response) => {
            const { data = [], status } = response;
            dispatch({
                type: AUDIT_TRAIL,
                payload: data
            });
            return { data, status };
        })
    }
}

export function getIncompleteHistory(params) {
    return async function (dispatch) {
        dispatch(isLoading(true));
        let IncompleteList = await startupServices.fecthIncompleteList(params);
        dispatch({
            type: INCOMPLETE_LIST,
            payload: IncompleteList
        })
        dispatch(isLoading(false));
    }
}

// export function getAuditTrail(params) {
//     return async function (dispatch) {
//         return await startupServices.fetchAuditTrailList(params).then((response) => {
//             const { data = [], status } = response;
//             dispatch({
//                 type: AUDIT_TRAIL,
//                 payload: data
//             });
//             return { data, status };
//         })
//     }
// }

export function getIndustryList() {
    return async function (dispatch) {
        return await startupServices.fetchStartupIndustryList().then((response) => {
            const { data = [], status } = response;
            dispatch({
                type: SET_STARTUP_INDUSTRY,
                payload: data
            });
            return { data, status };
        })
    }
}
export function getNatureOfList() {
    return async function (dispatch) {
        return await startupServices.getNatureOfTheEntity().then((response) => {
            const { data = [], status } = response;
            if (status) {
                dispatch({
                    type: SET_NATURE_OF_THE_ENTITY,
                    payload: data
                });
            }
            return { data, status };
        })
    }
}

export function getSubDistrictList(params) {

    const { districtId } = params;
    // let subDistrictList = []
    return async function (dispatch) {
        dispatch(isLoading(true));
        if (districtId) {
            await startupServices.getSubDistrict(params).then((response) => {
                const { data = [], status = false } = response;
                if (status) {
                    dispatch({
                        type: SET_STARTUP_SUB_DISTRICT,
                        payload: data
                    });
                }
                dispatch(isLoading(false));
            }).catch(e => {
                dispatch(isLoading(false));
            });

        }
    }
}

export function getStartupSector(params) {
    const { industryId } = params;
    return async function (dispatch) {
        if (industryId) {
            const response = await startupServices.getStartupSector(params).catch(e => {
                return { startupSector: [], status: false };
            });

            if (response) {
                const { status, startupSector } = response;
                if (status) {
                    dispatch({
                        type: SET_STARTUP_SECTOR,
                        payload: startupSector
                    });
                }

                return { status }
            }
        }
    }
}

export function updateStartupForm(params) {
    return async function (dispatch) {
        dispatch(isLoading(true));
        if (!isEmpty(params)) {
            const response = await startupServices.updateStartupForm(params).catch(e => {
                return { status: false, errors: [], message: "" };
            })

            if (response) {
                const { status = false, errors = [], message = "Unknown Error" } = response;
                if (status) {
                    toast.success(message)
                }
                else {
                    const NewMessage = errors.reduce((message, error) => message + '<br>- ' + error.errorMessage, "");
                    if (NewMessage) {
                      toast.error(
                        <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: NewMessage }} />,
                        {
                          position: 'top-center',
                          style: {
                            width: "800px"
                          },
                          className: "toast-error"
                        }
                      );
                    }
                    //     const NewMessage = errors.reduce((message, error) => message + '\n' + error.errorMessage, "");
                    //     console.log("testing4",NewMessage)
                    //    if(NewMessage)
                    //    {
                    //     toast.error(NewMessage, {
                    //         position: 'top-center',
                    //         style: {
                    //             width: "800px"
                    //         },
                    //         className: "toast-error"
                    //     });
                    // }
                    else {
                        toast.error(message, {
                            position: 'top-center',
                            style: {
                                width: "800px"
                            },
                            className: "toast-error"
                        });
                    }

                }
                dispatch(isLoading(false));
                return { status }
            }
        }

    }
}