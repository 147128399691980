import { API_URL } from '../../../../../../constants/ApplicationUrl'
import React from 'react';
import InputField from '../../../../../common/InputField';
import DropDown from '../../../../../common/GlobalDropDown';
import FileUpload from '../../../../../common/FileUpload';
import { uploadFileService } from '../../../../../../dependencies/services/uploadFileService';
import { SELF_CERTIFICATE_ADDITIONAL_DOCUMENT } from '../../../../dependencies/constant'

const OtherDocuments = (props) => {
  const { otherDocument = {}, index, isLoading, handleOnDelete, onChange } = props;
  const { name, url, file } = otherDocument;

  const handleInputChange = (e, name) => {
    if (e && e.target) {
      const value = e.target.value || "";
      let field = {
        [name]: value
      };
     
      if (name === "url") {
        field['file'] = null;
      } else if (name === 'name') {
        field['file'] = null;
        field['url']=null;
      }
      if (onChange) {
        onChange({ index, document: { ...otherDocument, ...field } })
      }
    }
  }

  const onUploadFile = (file) => {
    isLoading(true)
    const data = new FormData();
    data.append('file', file)
    data.append('documentType', 'OTHER_DOC');
    uploadFileService.uploadFile(data).then((data) => {
      if (data) {
        if (onChange) {
          onChange({ index, document: { ...otherDocument, ['file']: data, url: null } })
        }
      }
      isLoading(false)
    }, (error) => {
      isLoading(false)
   
    });
  }

  const onRemoveOtherDocument = () => {
    if (handleOnDelete) {
      handleOnDelete(index)
    }
  }

  const link = file && file.publicUrl && file.publicUrl || "";
  const linkName = file && file.compressedName || "";
  return (
    <React.Fragment>
      <div className="col">
        <DropDown
          options={SELF_CERTIFICATE_ADDITIONAL_DOCUMENT}
          error=""
          selectTagStyle="form-control dropdown-media"
          containerStyle={'form-group'}
          onChange={handleInputChange}
          name='name'
          value={name}
          defaultOptionText={'--Select--'}
        />
      </div>


      <div className="col">
        {
          // fileType === "file" &&
          ['PITCH_DECK', 'PATENTS', 'OTHERS'].indexOf(name) > -1 &&
          (
            <FileUpload
              onUploadFile={onUploadFile}
              allowedFiles={[".pdf", ".png", ".jpg"]}
              containerClass={"form-group"}
              maxFileSizeInMB={10}
            />)
        }
        {
          link &&
          (
            <label>
              <a href={API_URL() + link} target="_blank">{linkName}</a>
            </label>
          )
        }

        {
          ['WEBSITE_LINK'].indexOf(name) > -1 &&
          (
            <InputField
              type="text"
              name="url"
              containerClass={"form-group"}
              onChange={handleInputChange}
              value={url}
            />
          )
        }
        {
          ['VIDEOS'].indexOf(name) > -1 &&
          (
            <InputField
              type="text"
              name="url"
              containerClass={"form-group"}
              onChange={handleInputChange}
              value={url}
            />
          )
        }
      </div>
      {
        <span className='close-icon' onClick={onRemoveOtherDocument}> X</span>
      }
    </React.Fragment>
  )
}

export default OtherDocuments;
