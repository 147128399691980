import { combineReducers } from 'redux';

import loaderReducer from './loader.reducer';

import editProfile from './editProfile.reducer'

import roles from './roles.reducer';
import addUser from './addUser.reducer';

import userList from './userList.reducer';
import startupApplicationList from './startupApplication.reducer';
import startupSectionList from './startupSelection.reducer';
import startupSectionView from './startupApplicationView.reducer';
import getUserDetails from './getUserDetails.reducer';
import startupApplicationForm from '../../components/startupApplication/dependencies/reducer';
import getRejectListReducer from './getRejectList.reducer';
import bulkArchive from './bulkArchive.reducer';
import loginReducer from '../../components/Auth/common/dependencies/reducers/loginReducer'
import Applicatio80IACReducer from '../../components/applicationFor80Iac/dependencies/reducer';
import Section56Reducer from '../../components/Section56/dependencies/reducer';
import DashboardReducer from '../../components/dashboard/dependencies/reducer';
import StartupLogoReducer from '../../components/startupLogos/dependencies/reducer';
import ReportReducer from '../../components/Report/dependencies/reducer';
import NotificationDocumentsReducer from '../../components/NotificationDocument/dependencies/reducer';
import StartupDetailReport from '../../components/startupDetailReport/dependencies/reducer';
import GlossaryReducer from '../../components/GlossaryController/dependencies/reducer';
import  OtherDocumentsReducer from '../../components/OtherDocuments/dependencies/reducer';
import  UpdateReducer from '../../components/Update/dependencies/reducer';
import RecognitionHistoryReducer  from '../../components/DownloadSection/dependencies/reducer';
import userListFiftySixReducer from './userListFiftySix.reducer';
import userListStartupApplicationsReducer from './userListStartupApplications.reducer';
import nsaReportList from './nsaReport.reducer';



const appReducer = combineReducers({
    user: {},
    loader: loaderReducer,
    editProfile: editProfile,
    roles: roles,
    addUser: addUser,
    userList: userList,
    userFiftySix: userListFiftySixReducer,
    userListStartUpApplication: userListStartupApplicationsReducer,
    startupApplicationList: startupApplicationList,
    startupSectionList: startupSectionList,
    startupSectionView: startupSectionView,
    getUserDetails: getUserDetails,
    getRejectListReducer: getRejectListReducer,
    bulkArchive: bulkArchive,
    startupApplicationForm,
    loginReducer,
    Applicatio80IACReducer,
    Section56Reducer,
    DashboardReducer,
    StartupLogoReducer,
    ReportReducer,
    NotificationDocumentsReducer,
    StartupDetailReport,
    GlossaryReducer,
    OtherDocumentsReducer,
    UpdateReducer,
    RecognitionHistoryReducer,
    nsaReportList:nsaReportList

});

export default appReducer;