import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { getStartupApplicationList, doDownloadStartupList } from '../../dependencies/services/startupApplicationService';
import { getStarutupselection } from '../../dependencies/services/startupSelection';
import { getSectorOptions } from '../applicationFor80Iac/dependencies/action';
import { getNatureOfList } from '../startupApplication/dependencies/action';
import { bulkArchive } from '../../dependencies/services/bulkArchive';
import allApplicationDropdown from '../../dependencies/utils/json/allApplicationDropdown.json'
import { connect } from 'react-redux';
import columns, { rowStyle2 } from './common/dependencies/startupTableColumns';
import Table from '../common/Table';
import './scss/index.scss';
import ActionModal from './modal';
import popArchiveIcon from '../../assests/images/pop-archive.png';
import popApprovedIcon from '../../assests/images/pop-check.png';
import Download from '../common/Download';
import Refresh from '../common/Refresh';
import SectionTitle from '../common/sectionTitle/sectionTitle';
import SearchBox from '../common/Search';
import { SearchByStartupApplicationOptions } from './common/constant';
import GlobalDropDown from '../common/GlobalDropDown';
import moment from 'moment';
import IncompleteHistory from './IncompleteHitory';
import { storage } from '../../dependencies/store/storage';
import { ROLES } from '../../constants/Roles';
import AssignedApplication from '../common/AssignedApplication';

const actionsList = [{
  type: 'approved',
  text: 'Approved',
  icon: popApprovedIcon
},
{
  type: 'archived',
  text: 'Archived',
  icon: popArchiveIcon
}]


const StartupApplication = (props) => {

  const { getStartupApplicationList, startupApplicationDataState: { data, recordsTotal }, doDownloadStartupList, getNatureOfList } = props;
  const [sortField, setSortField] = useState('applicationDate');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [sizePerPage, setSizePerPage] = useState(50);

  const [dropDownStatus, setDropDownStatus] = useState('text');
  const [edited, setEdited] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [query, setQuery] = useState({
    status: "SUBMIT", archived: false
  });
  const [searchParams, setSearchParams] = useState({});
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [checkedValue, setCheckedValue] = useState([]);
  const [modalValue, setModalValue] = useState(false);
  const [buttonValue, setButtonValue] = useState('');
  const [bulkValue, setBulkValue] = useState({});
  const [natureOption, SetNatureOption] = useState([]);
  const [actionType, setActionType] = useState('');
  const [newApplication, setNewApplication] = useState(false);
  const [sectorOptions, setSectorOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showIncompleteHistory, setShowIncompleteHistory] = useState(false);
  const [exemptionId, setExemptionId] = useState("")

  const userRole = storage.getUserRole();
  const getUser = storage.getUser();



  const callApi = () => {
    setSortField('applicationDate');
    setSortOrder('DESC');
    setSizePerPage(50);
    setDropDownStatus('text');
    setPageNo(0);
    setSearchParams({});
    setQuery({
      status: "SUBMIT", archived: false
    })
  }
  useEffect(() => {
    getTableData();

  }, [sortField, sortOrder, sizePerPage, pageNo, searchParams, query, searchText])

  const getTableData = () => {
    // console.log(query)
    getStartupApplicationList({
      sortColumn: sortField,
      sortOrder: sortOrder.toUpperCase(),
      pageSize: sizePerPage,
      pageIndex: pageNo,
      query
    });
  }


  const onSelectRows = (selectedRows) => {
    setCheckedValue(selectedRows)

  }

  const onSortChange = async (params) => {
    const { order, dataField } = params;
    let sortName = '';
    if (dataField === 'mobileNumber') {
      sortName = 'representative.mobile';
    } else if (dataField === 'referenceNumber') {
      sortName = 'referenceNumber';
    } else if (dataField === 'certificateNumber') {
      sortName = 'startupEntity.recognitionNumber';
    } else if (dataField === 'incorporationNumber') {
      sortName = 'incorporationNumber';
    } else if (dataField === 'nameOfEntity') {
      sortName = 'startupEntity.name';
    } else if (dataField === 'applicationDate') {
      sortName = 'applicationDate';
    } else if (dataField === 'registrationDate') {
      sortName = 'startupEntity.corCreatedDate';
    } else if (dataField === 'emailId') {
      sortName = 'representative.email';
    } else {
      sortName = 'createdDate';
    }
    if (dataField !== '') {
      setSortField(sortName);
      setSortOrder(order.toUpperCase());
    }

  }
  const handleOnPageChange = (params) => {
    const { page } = params;
    setPageNo(page - 1);
  }
  const handleOnSizePerPageChange = (params) => {
    const { sizePerPage } = params;
    setSizePerPage(sizePerPage)
    setPageNo(0);
  }

  useEffect(() => {
    props.getStarutupselection();

  }, []);




  const toggleActionModal = () => {
    setModalValue(!modalValue);
    if (modalValue) {
      setActionType('');
    }

  }

  const actionHandler = (actionType) => {
    setActionType(actionType)
  }
  useEffect(() => {
    if (checkedValue.length === 0 && actionType) {
      setButtonValue('invalid')
      toggleActionModal();
    } else {
      bulkApprove();
    }
  }, [actionType])



  const bulkApprove = () => {
    if (actionType === 'approved') {
      toggleActionModal();
      setButtonValue('approved')
      setBulkValue({
        ids: checkedValue,
        status: 'APPROVE'
      })
    } else if (actionType === 'archived') {
      toggleActionModal();
      setButtonValue('archived')
      setBulkValue({
        ids: checkedValue,
        status: 'ARCHIVE'
      })
    }

  }
  function downloadStartupList() {
    if (doDownloadStartupList) {
      return doDownloadStartupList({
        fetchAllRecords: true,
        sortColumn: sortField,
        sortOrder: sortOrder.toUpperCase(),
        pageSize: sizePerPage,
        pageIndex: pageNo,
        query
      }).then((data) => {
        return data;
      })
    }
  }

  function refreshStatupList() {
    getStartupApplicationList({
      sortColumn: 'applicationDate',
      sortOrder: 'DESC',
      pageSize: sizePerPage,
      pageIndex: pageNo,
      query
    });
  }

  useEffect(() => {
    if (props.getSectorOptions) {
      props.getSectorOptions().then((response) => {
        const { data = [] } = response;
        let options = [];
        if (data && data instanceof Array) {
          options = data.reduce((arr, option) => {
            if (option['industryName'] !== null) {
              var text = `${option['sectionName']} (${option['industryName']})` || "";
            } else {
              var text = `${option['sectionName']}` || "";
            }
            const value = option['id'] || "";
            arr.push({ text, value });
            return arr;
          }, []);
        }
        setSectorOptions(options);
      });
    }

    getNatureOfList().then((response) => {
      const { data, status } = response || {};
      if (status) {
        let options = [];
        if (data && data instanceof Array) {
          options = data.reduce((arr, option) => {
            const text = option['name'] || "";
            const value = option['id'] || "";
            arr.push({ text, value });
            return arr;
          }, []);
        }
        SetNatureOption(options);
      }
    });

  }, [])

  const doSearch = (params) => {
    const { searchText = "", searchBy = "" } = params;
    if (newApplication) {
      var query = { ...{ status: "SUBMIT", archived: false, edited: edited, startDate: startDate, endDate: endDate }, ...params.query }

    } else {
      var query = { ...{ status: "SUBMIT", archived: false, edited: edited }, ...params.query }

    }
    if (searchBy && searchBy === 'certificateDate') {
      setQuery(() => {
        delete query['status'];
        return { ...query, 'searchType': 'CERTIFICATE_DATE' }
      });
    } else if (searchBy && searchBy === 'createdDate') {
      setQuery(() => {
        delete query['status'];
        return { ...query, 'searchType': 'CREATED_DATE' }
      });
    } else if (searchBy && (searchBy === 'incorporationDate' || searchBy === 'randomReg' || searchBy === 'incorporationNumber' || searchBy === 'referenceNumber' || searchBy === 'nameOfEntity' || searchBy === 'natureOfEntityId' || searchBy === 'mobileNumber' || searchBy === 'email' || searchBy === 'sectionId' || searchBy === 'panNumber' || searchBy === 'swsId')) {
      setQuery(() => {
        delete query['status'];
        return { ...query, 'searchType': 'CREATED_DATE', }
      });
    } else {
      setQuery(query);
    }
    setSearchText(searchText);
    setPageNo(0)
  }

  const AssignedApplicationList = () => {
    setQuery(() => {
      delete query['archived'];
      delete query['status'];
      return { ...query, role : userRole, adminUserName : getUser.user.name }
    });

    console.log(query)
  }

  const handleInput = (e, type) => {
    let value = e.target.value;
    if (type === 'srtAppDropdown') {
      if (value === 'true') {
        setQuery({
          status: "SUBMIT", archived: false, edited: value
        })
        setEdited(value);
        setStartDate('');
        setEndDate('');
        setNewApplication(false)
      } else if (value === 'date') {
        let strtDate = moment().format("YYYY-MM-DD");
        const today = moment();
        const nextWeek = today.add(10, 'days');
        let enDate = moment(nextWeek).format("YYYY-MM-DD");
        setQuery({
          status: "SUBMIT", archived: false, startDate: strtDate, endDate: enDate
        })
        setStartDate(strtDate);
        setEndDate(enDate);
        setNewApplication(true)
        setEdited(value);
      } else {
        setQuery({
          status: "SUBMIT", archived: false
        })
        setEdited(null)
        setStartDate('');
        setEndDate('');
        setNewApplication(false)

      }
    }
  }

  const onCloseIncompeletePopup = () => {
    setExemptionId('')
    setShowIncompleteHistory(x => !x);

  }

  useEffect(() => {
    if (exemptionId) {
      setShowIncompleteHistory(true)
    }
  }, [exemptionId]);
  const clickOnAction = (id) => {
    if (id) {
      setExemptionId(id)
    }
  }
  console.log("heloo")
  return (

    <div>
      <SectionTitle>Startup Application</SectionTitle>
      <div className="startup-app-filter-form">
        <div className="inner-wrap clearfix">
          <div className="left-filter-wrap">
            <div className="global-search-filter view-wrap">
              <div className="main-search-wrap view-dropdown">
                <div className="filter-dropdown clearfix">
                  <label className="text-center ph-15">View</label>
                  <GlobalDropDown
                    label=''
                    containerStyle="form-group"
                    selectTagStyle="form-control dropdown-media filter-dropdown"
                    onChange={handleInput}
                    options={allApplicationDropdown}
                    value={edited}
                    defaultOptionDisable={true}
                    name={'srtAppDropdown'}
                    error=""
                  />
                </div>
              </div>
            </div>
            <SearchBox
              searchByOptions={SearchByStartupApplicationOptions(sectorOptions, natureOption)}
              doSearch={doSearch}
            />

          </div>
        </div>
      </div>
      {
        <IncompleteHistory
          isOpen={showIncompleteHistory}
          onClose={onCloseIncompeletePopup}
          exemptionId={exemptionId}
        />
      }

      {modalValue ?
        <ActionModal
          modalValue={modalValue}
          toggleActionModal={toggleActionModal}
          actionValue={buttonValue}
          callApi={callApi}
          data={bulkValue
            ?
            bulkValue : ''}
        /> : null
      }
      <div className='row' style={{marginTop: '15px'}}>
        <div className='col-sm-8'>
          <div className='row mb-4'>
            <div className='col-sm-12' style={{marginLeft:'-15px'}}>
              {/* {
                  userRole === ROLES.ADMIN || userRole === ROLES.SUPER_ADMIN ? (
                      <AssignedApplication
                          onClickAssign={AssignedApplicationList}
                          className="bttn-link"
                      />
                  ):''
              } */}
            </div>
            <div className='col-sm-11 px-3'>
              <div className="table-legend-outer clearfix">
                <div className="legend mr-30">
                  <span className="color edited"></span>
                  <span className="legend-ttl">Edited</span>
                </div>
                <div className="legend mr-30">
                  <span className="color npl"></span>
                  <span className="legend-ttl">NPL</span>
                </div>
                <div className="legend">
                  <span className="color register-partnership"></span>
                  <span className="legend-ttl">Registered Partnership</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-sm-4 mt-3' style={{paddingRight:'24px'}}>
          <Download
            fileName={'Startup Application Details'}
            onClickDownload={downloadStartupList}
            className="bttn-link"
          />
          <Refresh
            onClickRefresh={refreshStatupList}
            className=""
          />
        </div>
      </div>
      


      <Table
        dataTable={data}
        columns={columns({
          clickOnAction,
          pageNo: pageNo,
          size: sizePerPage
        })}
        keyField='applicationId'
        // selectRowEnabled={true}
        // selectMode="checkbox"
        // onSelectRows={onSelectRows}
        onSortChange={onSortChange}
        totalSize={recordsTotal}
        rowStyle={rowStyle2}
        sizePerPage={sizePerPage}
        onPageChange={handleOnPageChange}
        onSizePerPageChange={handleOnSizePerPageChange}
        actionHandler={actionHandler}
        tableActions={actionsList}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    natureOfTheEntity: state.startupApplicationForm.natureOfTheEntity,
    startupApplicationDataState: state.startupApplicationList.startupData,
    startupSectionList: state.startupSectionList.startupSelectionData
  }
}

export default connect(mapStateToProps, { getStartupApplicationList, getStarutupselection, bulkArchive, doDownloadStartupList, getNatureOfList, getSectorOptions })(withRouter(StartupApplication));