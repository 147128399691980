import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
const ImbMemberAssignColumn = (props) => {

    return [
        {
            dataField: '',
            text: 'Sr.No',
            formatter: (cell, row, rowIndex) => {
                return rowIndex + 1
            }
        },
        {
            dataField: 'adminName',
            text: 'Admin Name',
            formatter: (observation) => {
                if (observation) {
                    return observation
                }
            }
        }, 
        {
            dataField: 'statusImbMember',
            text: 'Status',
            formatter: (cell) => {
                if (cell) {
                    return cell                    
                }
            }
        },
        {
            dataField: 'recommendComment',
            text: 'Comment',
            formatter: (cell) => {
                if (cell) {
                    return cell
                }
            },
        },
        {
            dataField: 'actionTakenDate',
            text: 'Response Date',
            formatter: (responseDate) => {
                if (responseDate) {
                    return moment(responseDate).format('DD/MM/YYYY');
                }
            }

        }
    ];
}

export default ImbMemberAssignColumn;