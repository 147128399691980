import React from 'react';
import { API_URL } from '../../../../constants/ApplicationUrl'
import { Link } from 'react-router-dom';
import moment from 'moment';
import { headerSortingStyle } from '../../../../constants';
import { ROLES } from '../../../../constants/Roles';
import { storage } from '../../../../dependencies/store/storage';

const Section56Columns = ({ clickOnAction,
  pageNo,
  size
}) => {
  const userRole = storage.getUserRole();

  return [
    {
      dataField: '',
      text: 'Sr.No',
      formatter: (cell, row, rowIndex) => {
        return (pageNo * size) + (rowIndex + 1);
      }
    }, {
      dataField: 'recognitionNumber',
      text: 'COR Number',
      sort: false,
      formatter: (cell, row, rowIndex) => {
        return cell
      }
    },
    {
      dataField: 'referenceNumber',
      text: 'Reference No.',
      sort: false,
    },
    {
      dataField: 'applicationDate',
      text: 'Application Date',
      sort: true,
      formatter: (cell) => {
        if (cell) {
          return moment(cell).utcOffset(0).format('DD/MM/YYYY')

        }
      },
    }, {
      dataField: 'id',
      text: '',
      hidden: true
    },
    {
      dataField: 'action',
      text: 'Action',
      sort: false,
      formatter: (cell, row) => {
        const { id } = row;
        return (
          <div className="IAS-Actions-list">
            <ul>
              <li> <Link to={`/application/section56/${id}`}>View</Link></li>
              <li><a href="javascript:void(0)" onClick={() => clickOnAction(id)}>View Incomplete History</a></li>
           
            </ul>
          </div>
        )

      }
    },
    {
      dataField: 'statusLabel',
      text: 'Status',
    },
    {
      dataField: 'incorporationCertificate',
      text: 'Incorporation Certificate',
      sort: false,
      formatter: (cell) => {
        if (cell && cell.publicUrl) {
          return (<a href={`${API_URL()}${cell.publicUrl}`} target="_blank">View Document</a>)
        }
      }
    }, {
      dataField: 'incorporationNumber',
      text: 'Registration No',
      sort: false,
    },
    {
      dataField: 'entityName',
      text: 'Name of the Entity',
      sort: false,
    }, {
      dataField: 'mobileNumber',
      text: 'Mobile No',
      sort: false,
    }, {
      dataField: 'emailId',
      text: 'Email Id',
      sort: false,
    },
  ];
}

export default Section56Columns;