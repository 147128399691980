import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getState } from '../dependencies/action';
import { connect } from 'react-redux';
import Table from '../../common/Table';
import column from '../dependencies/columns/state';
import '../style.scss';

const State = (props) => {
    const { getState } = props;

    const [stateList, setStateList] = useState([]);

    useEffect(() => {
        if (getState) {
            getState().then((repsonse) => {
                const { status, data } = repsonse;
                if (status) {
                    setStateList(data);
                }
            });
        }
    }, [])

    return (
        <>
            <div className="country-container">
                <h2 className="mb-20">{"States"}
                    <a href="/update/state/form" className="bttn blue normal add-btn">
                        {"Add State"}
                    </a>
                </h2>

                <Table
                    dataTable={stateList}
                    columns={column}
                    keyField={'id'}
                    sizePerPage={1000}
                />
            </div>
        </>
    )
}

export default connect(null, { getState })(State);