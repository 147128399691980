import api from '../../../dependencies/utils/Api';

import {
    DOWNLOAD_INCOMPLETE_SECTION56_REPORT,
} from '../../../constants/ApplicationUrl';

export const section56ReportService = {

    downloadReport(params) {
        return api.DOWNLOADDATA(`${DOWNLOAD_INCOMPLETE_SECTION56_REPORT}`,params).
            then((data) => {
                return data;
            }).catch(e => {
                return Promise.reject(e);
            })
    }
}