import { combineReducers } from 'redux';

import {
REPORT_STATE_LIST,
REPORT_SECTOR_LIST,
SET_STARTUP_INDUSTRY

} from '../../../constants/actionConstants';

const reportStateList = (state = {}, action) => {
    switch (action.type) {
        case REPORT_STATE_LIST:
            return { ...action.payload };
        default:
            return state;
    }
}
const reportIndustryList = (state = {}, action) => {
  
    switch (action.type) {
        case SET_STARTUP_INDUSTRY:
            return { ...action.payload };
        default:
            return state;
    }
}
const reportSectorList = (state = {}, action) => {
    switch (action.type) {
        case REPORT_SECTOR_LIST:
            return { ...action.payload };
        default:
            return state;
    }
}

const StartupDetailReportReducer = combineReducers({
    reportStateList,
    reportSectorList,
    reportIndustryList
})

export default StartupDetailReportReducer;