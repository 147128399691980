import {
    validateDiip,
    validateEntityName,
    validateIncorporationNumber,
    validateNatureOfEntity
} from '../../../dependencies/utils/validation/Validation';

export default {
   
    validateRegenerateDiipNumber: (params) => {
        const { diipNumber } = params;
        let formStatus = {};
        const diipNumberStatus = validateDiip(diipNumber);

        formStatus = {
            diipNumber: diipNumberStatus.error,
           
            hasErrors: (
                diipNumberStatus.hasError
            )
        };
        return formStatus;
    },

    validateChangeEntityName: (params) => {
        const { newDiipNumner,oldEntityName, newEntityName } = params;
        let formStatus = {};
        const newDiipNumnerStatus = validateDiip(newDiipNumner);
        const oldEntityNameStatus = validateEntityName(oldEntityName);
        const newEntityNameStatus = validateEntityName(newEntityName);

        formStatus = {
            newDiipNumner:newDiipNumnerStatus.error,
            oldEntityName:oldEntityNameStatus.error,
            newEntityName:newEntityNameStatus.error,
            hasErrors: (
                newDiipNumnerStatus.hasError,
                oldEntityNameStatus.hasError,
                newEntityNameStatus.hasError
            )
        };
        return formStatus;
    },


    validateIncorporationNumbers: (params) => {
        const { natureStatus,oldIncorporationNumber, newIncorporationNumber } = params;
        let formStatus = {};
        const natureStatusStatus = validateNatureOfEntity(natureStatus);
        const oldIncorporationNumberStatus = validateIncorporationNumber(oldIncorporationNumber);
        const newIncorporationNumberStatus = validateIncorporationNumber(newIncorporationNumber);

        formStatus = {
            natureStatus:natureStatusStatus.error,
            oldIncorporationNumber:oldIncorporationNumberStatus.error,
            newIncorporationNumber:newIncorporationNumberStatus.error,
            hasErrors: (
                natureStatusStatus.hasError,
                oldIncorporationNumberStatus.hasError,
                newIncorporationNumberStatus.hasError
            )
        };
        return formStatus;
    },
   
};
