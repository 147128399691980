import { combineReducers } from 'redux';
import {
    SET_USER,
    CLEAR_USER,
    SET_LOGGED_IN
} from '../../../../../constants/actionConstants';

const user = (state = {}, action) => {
    switch (action.type) {
        case SET_USER:
            return action.payload;
        case CLEAR_USER:
            return {};
        default:
            return state;
    }
}

const isLoggedIn = (state = false, action) => {
    switch (action.type) {
        case SET_LOGGED_IN:
            return action.payload;
        default:
            return state;
    }
}

const LoginReducer = combineReducers({
    user,
    isLoggedIn,
});

export default LoginReducer;