import { combineReducers } from 'redux';
import {
    GLOSSARY_LIST,
    NOTIFICATION_GLOSSARY_BY_ID
} from '../../../constants/actionConstants';


const glossaryList = (state = {}, action) => {
    switch (action.type) {
        case GLOSSARY_LIST:
            return { ...action.payload }
        default:
            return state;
    }
}

const glossaryListID = (state = {}, action) => {
    switch (action.type) {
        case NOTIFICATION_GLOSSARY_BY_ID:
            return { ...action.payload }
        default:
            return state;
    }
}


const GlossaryReducer = combineReducers({
    glossaryList,
    glossaryListID
})

export default GlossaryReducer;