
import { get, isEmpty } from 'lodash';
import { UpdateServices } from './service';
import { isLoading, COUNTRY_LIST, STATE_LIST, INDUSTRY_LIST, SERVICES_LIST } from '../../../constants/actionConstants';
import { toast } from "react-toastify";



export const getCountry = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await UpdateServices.getCountry(params).then((response) => {
        const { data = [], status = false } = response;
        dispatch(isLoading(false));
        if (status) {
            dispatch({
                type: COUNTRY_LIST,
                payload: data
            })
            return { status, data };
        }
        return { status };
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}

export const getState = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await UpdateServices.getState(params).then((response) => {
        const { data = [], status = false } = response;
        dispatch(isLoading(false));
        if (status) {
            dispatch({
                type: STATE_LIST,
                payload: data
            })
            return { status, data };
        }
        return { status };
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}

export const updateCountry = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await UpdateServices.updateCountry(params).then((response) => {
        const { data = [], status = false, message } = response;
        dispatch(isLoading(false));
        if (status) {
            toast.success(message)
            return { status, data };
        } else {
            toast.error(message)
            return { status, data };
        }
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}

export const updateState = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await UpdateServices.updateState(params).then((response) => {
        const { data = [], status = false, message } = response;
        dispatch(isLoading(false));
        if (status) {
            toast.success(message);
            return { status, data };
        } else {
            toast.error(message)
            return { status, data };
        }
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}

export const getDistrict = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await UpdateServices.getDistrict(params).then((response) => {
        const { data = [], status = false } = response;
        dispatch(isLoading(false));
        if (status) {
            // dispatch({
            //     type: ,
            //     payload: data
            // })
            return { status, data };
        }
        return { status };
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}

export const updateDistrict = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await UpdateServices.updateDistrict(params).then((response) => {
        const { data = [], status = false, message } = response;
        dispatch(isLoading(false));
        if (status) {
            toast.success(message);
            return { status, data };
        } else {
            toast.error(message)
            return { status, data };
        }
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}

export const getSubDistrict = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await UpdateServices.getSubDistrict(params).then((response) => {
        const { data = [], status = false } = response;
        dispatch(isLoading(false));
        if (status) {
            // dispatch({
            //     type: ,
            //     payload: data
            // })
            return { status, data };
        }
        return { status };
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}

export const updateSubDistrict = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await UpdateServices.updateSubDistrict(params).then((response) => {
        const { data = [], status = false, message } = response;
        dispatch(isLoading(false));
        if (status) {
            toast.success(message)
            return { status, data };
        } else {
            toast.error(message)
            return { status, data };
        }
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}


export const getIndustry = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await UpdateServices.getIndustry(params).then((response) => {
        const { data = [], message, status = false } = response;
        dispatch(isLoading(false));
        if (status) {
            dispatch({
                type: INDUSTRY_LIST,
                payload: data
            })
            return { status, message, data };
        }
        return { status, message };
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}


export const updateIndustry = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await UpdateServices.updateIndustry(params).then((response) => {
        const { data = [], message, status = false } = response;
        dispatch(isLoading(false));
        if (status) {
            toast.success(message)
            return { status, message, data };
        } else {
            toast.error(message)
            return { status, message, data };
        }

    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}


export const getServices = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await UpdateServices.getServices(params).then((response) => {
        const { data = [], message, status = false } = response;
        dispatch(isLoading(false));
        if (status) {
            dispatch({
                type: SERVICES_LIST,
                payload: data
            })
            return { status, message, data };
        }
        return { status, message };
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}


export const updateServices = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await UpdateServices.UpdateServices(params).then((response) => {
        const { data = [], message, status = false } = response;
        dispatch(isLoading(false));
        if (status) {
            toast.success(message);
            return { status, message, data };
        } else {
            toast.error(message);
            return { status, message, data };
        }

    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}

export const getSections = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await UpdateServices.getSections(params).then((response) => {
        const { data = [], message, status = false } = response;
        dispatch(isLoading(false));
        if (status) {
            return { status, data, message };
        }
        return { status, message };
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}

export const updateSection = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await UpdateServices.updateSection(params).then((response) => {
        const { data = [], message, status = false } = response;
        dispatch(isLoading(false));
        if (status) {
            toast.success(message)
            return { status, message, data };
        } else {
            toast.error(message)
            return { status, message, data };
        }
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false }
    })
}