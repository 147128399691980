import React from 'react';
import { API_URL } from '../../../constants/ApplicationUrl'

const columns = [
    {
        dataField: 'id',
        text: 'Sr.No ',
        formatter: (cell, row, rowIndex) => {
            return rowIndex + 1
        }
    },{
        dataField: 'financialYear',
        text: 'Financial Year',
       
    }, {
        dataField: 'balanceSheet',
        text: 'Balance Sheet',
        formatter: (cell, row) => {
            if (cell && cell.publicUrl) {
                return (
                    <a href={`${API_URL()}${cell.publicUrl}`} target="_blank">View Document </a>
                   
                )
            }

        }
    }, {
        dataField: 'tradingProfitAndLossAccount',
        text: 'Trading and P&L Account',
        formatter: (cell, row) => {
            if (cell && cell.publicUrl) {
                return (
                    <a href={`${API_URL()}${cell.publicUrl}`} target="_blank">View Document </a>
                   
                )
            }

        }
    }
];


export default columns;