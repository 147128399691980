import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import DropDown from '../GlobalDropDown';
import Button from '../Button';
// import './scss/index.scss';
// import moment from 'moment';
// import { toast } from 'react-toastify';

const SearchBoxForUser = (props) => {
  const { doSearch, option = [], } = props;
  const [searchText, setSearchText] = useState('')  

  const  onSearch = () => {
    // console.log(searchText)
    doSearch(searchText)
  }

  return (
    <div className="global-search-filter">
      <div className="clearfix">
        <div className="main-search-wrap">
            <div class="filter-dropdown clearfix">
                <label class="text-center ph-15">ROLE</label>
                <div class="global-select form-group  undefined ">
                    <select name="searchBy" class="form-control dropdown-media filter-dropdown" onChange={(e) => setSearchText(e.target.value)}>
                        {
                          option.map((value, index) =>{
                            return (
                              <option key={index} value={value.value}>{value.text}</option>
                            )
                          })
                        }
                                               
                    </select>
                </div>
            </div>
        </div>
        
      </div>
      <div className="row">
        <div className='col-12'>
          <Button
            text={'Search'}
            onClick={onSearch}
            type={'button'}
            className={'bttn'}
          />
        </div>
      </div>
    </div>
  )
}


export default SearchBoxForUser;