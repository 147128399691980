import React, { useEffect, useState } from 'react';
import './scss/index.scss';
import isEmpty from 'lodash/isEmpty';
import SectionTitle from '../../../components/common/sectionTitle/sectionTitle';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { getClarificationRequest, submitClarificationRequest } from '../dependencies/action';
import moment from 'moment';
import TextareaField from '../../common/TextareaField';
import Button from '../../common/Button';
import Modal from '../../common/Modal';
import { toast } from 'react-toastify';
import { unEscapeRegHtml } from '../../../dependencies/utils/helper';


const ClarificationRequest = (props) => {
    const { getClarificationRequest,
        data: { observation,
            startupResponse,
            requestDate,
            responseDate,
            uploadedFile,
            requestDescription } = {},
        exemptionId,
        submitClarificationRequest,
        onClose,
    } = props;

    const [description, setDescription] = useState("");

    useEffect(() => {
        if (exemptionId) {
            getClarificationRequest({ id: exemptionId }).then((data) => {
            });
        }
    }, []);

    useEffect(() => {
        if (!isEmpty(requestDescription)) {
            let text = ""
            Object.keys(requestDescription).forEach((val) => {
                if(val && requestDescription[val]){
                    text += requestDescription[val] + '\n\n'
                }
            })
            setDescription(text)
        }
    }, [requestDescription])


    const handleInputChange = (e, name) => {
        if (e && e.target) {
            const val = e.target.value || "";
            setDescription(val)
        }
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        if (submitClarificationRequest && exemptionId) {
            submitClarificationRequest({
                "exemption": {
                    "id": exemptionId
                },
                "observation": description,
            }).then((data) => {
                const { status = false, message = "" } = data || {};
                if (status) {
                    toast.success(message)
                } else {
                    toast.error(message)
                }
                onClose();
            }).catch(e => {
            });
        }
    }
    return (
        <div>
            <SectionTitle>Startup India : Clarification Request</SectionTitle>

            <div className="entity-detail-wrap observation">
                <div className="entity-detail-inner p-20 mb-15">
                    <div className="entity-row clearfix ">
                        <div className="entity-title">
                            <h6>{'Observation'}</h6>
                        </div>
                        <div className="entity-content">
                            <p className="clearfix"><span>:&nbsp;&nbsp;</span>
                                <span>{unEscapeRegHtml(observation)}</span>
                            </p>
                        </div>
                    </div>
                    <div className="entity-row clearfix ">
                        <div className="entity-title">
                            <h6>{"Startup's Response"}</h6>
                        </div>
                        <div className="entity-content">
                            <p className="clearfix"><span>:&nbsp;&nbsp;</span>
                                <span>{unEscapeRegHtml(startupResponse)}</span>
                            </p>
                        </div>
                    </div>
                    <div className="entity-row clearfix ">
                        <div className="entity-title">
                            <h6>{'Uploaded File'}</h6>
                        </div>
                        <div className="entity-content">
                            <p className="clearfix"><span>:&nbsp;&nbsp;</span>
                                <span>{uploadedFile && uploadedFile.filename}</span>
                            </p>
                        </div>
                    </div>
                    <div className="entity-row clearfix ">
                        <div className="entity-title">
                            <h6>{'Requested Date'}</h6>
                        </div>
                        <div className="entity-content">
                            <p className="clearfix"><span>:&nbsp;&nbsp;</span>
                                <span>{requestDate && moment(requestDate).format('LLLL')}</span>
                            </p>
                        </div>
                    </div>
                    <div className="entity-row clearfix ">
                        <div className="entity-title">
                            <h6>{'Response Date'}</h6>
                        </div>
                        <div className="entity-content">
                            <p className="clearfix"><span>:&nbsp;&nbsp;</span>
                                <span>{responseDate && moment(responseDate).format('LLLL')}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="entity-detail-wrap">
                <div class="entity-detail-inner p-20 mb-15">
                    <form onSubmit={e => handleOnSubmit(e)}>
                        <label>Request Description</label>
                        <TextareaField
                            rows='10'
                            name="description"
                            value={unEscapeRegHtml(description)}
                            onChange={handleInputChange}
                        />
                        <Button
                            text="Send Observation"
                            className="bttn blue"
                            type="submit"
                        />
                    </form>
                </div>
            </div>
        </div>


    )
}
const mapStateToProps = (state) => {
    return {
        data: state.Section56Reducer.clarificationRequest,
    }
}
export default connect(mapStateToProps, { getClarificationRequest, submitClarificationRequest })(withRouter(Modal(ClarificationRequest)))
