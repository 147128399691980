import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import InputField from '../../../../common/InputField';
import MobileNumberField from '../../../../common/MobileNumberField';
import FormValidator from '../../../dependencies/Validation';
import { unEscapeRegHtml } from '../../../../../dependencies/utils/helper'
import Button from '../../../../common/Button'
import InlineError from '../../../../common/inlineError'
const AuthorizedDetail = (props) => {
    const {
        representative,
        onChangeHandler,
        error = {},
        doReset,
        doSubmit
    } = props;
    const defaultFormData = {
        designation: "",
        email: "",
        mobile: "",
        name: ""
    }
    const [formData, setFormData] = useState(defaultFormData);

    useEffect(() => {
        if (!isEmpty(representative)) {
            setFormData(options => {
                return { ...options, ...representative }
            })
        }
    }, [representative])

    const handleInputChange = (e, name) => {
        const mobileRegex = /^\s*\d*\s*$/
        const value = e.target && e.target.value || "";
        if (name === 'mobile' && !mobileRegex.test(value)) {
            return;
        }
        setFormData(data => {
            return { ...data, [name]: value };
        })
    }


    useEffect(() => {
        if (onChangeHandler) {
            onChangeHandler({
                representative: { ...formData }
            })
        }
    }, [formData]);

    return (
        <div>
            <div className="row mb-20">
                <div className="col-sm-4">
                    <InputField
                        label='Name of Authorized Representative'
                        required
                        type='text'
                        name="name"
                        value={unEscapeRegHtml(formData.name)}
                        onChange={handleInputChange}
                        error={error.name}
                        containerClass={"form-group"}
                        hasInvalidSubmitOccurred={true}
                    />
                </div>
                <div className="col-sm-4">
                    <InputField
                        label='Designation of Authorized Representative'
                        required
                        type='text'
                        name="designation"
                        value={unEscapeRegHtml(formData.designation)}
                        onChange={handleInputChange}
                        error={error.designation}
                        containerClass={"form-group"}
                        hasInvalidSubmitOccurred={true}
                    />
                </div>
                <div className="col-sm-4">
                    {/* <MobileNumberField
                        required
                        >
                        <InputField
                        required
                        type='text'
                        name='countryCode'
                        value='+91'
                    /> */}
                    <InputField
                        required
                        type='text'
                        label='Mobile Number'
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleInputChange}
                        error={error.mobile}
                        hasInvalidSubmitOccurred={true}
                    />
                    {/* </MobileNumberField> */}
                </div>
            </div>
            <div className="row mb-20">
                <div className="col-sm-4">
                    <InputField
                        label='Email ID of Authorized Representative'
                        required
                        type='email'
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        error={error.email}
                        containerClass={"form-group"}
                        hasInvalidSubmitOccurred={true}
                    />
                </div>
            </div>
            {
                error.tnc && (
                    <div className="row mb-20">
                        <div className="col-sm-12">
                            <InlineError message={`Go to "Self Certification" and ${error.tnc}`} />
                        </div>
                    </div>
                )
            }
            <div className="row mb-20 text-center">
                <div className="col-sm-12">
                    <Button
                        className="bttn mb-20"
                        type="button"
                        text="Save"
                        onClick={() => doSubmit("REPRESENTATIVE")}
                    />
                    
                </div>
            </div>
        </div>
    )
}

export default AuthorizedDetail;