import React from 'react';
import { SUPPORTING_DOCUMENTS, headerSortingStyle } from '../../../../constants';
import moment from 'moment';
import { unEscapeRegHtml } from '../../../../dependencies/utils/helper';


const columns = [
    // {
    //     dataField: '',
    //     text: 'Sr.No',
    //     formatter: (cell, row, rowIndex) => {
    //         return (pageNo * size) + (rowIndex + 1);
    //     }
    // },
    // {
    //     dataField: 'referenceNumber',
    //     text: 'Refrence No',
    //     headerSortingStyle
    // },
    // {
    //     dataField: 'applicationDate',
    //     text: 'Application Date',
    //     headerSortingStyle
    // },

    {
        dataField: 'incorporationNumber',
        text: 'Registration No',

    },
    {
        dataField: 'startupEntity',
        text: 'Entity Name',
        formatter: (cell, row) => {
            if (cell && cell.name) {
                return cell.name;
            }

            // return cell.name ? cell.name :''
        },
    },
    {
        dataField: 'incompleteAction',
        text: 'Incomplete Reason',

        formatter: (cell, row) => {
            if (cell && cell.incompleteReasons) {
                return cell.incompleteReasons.map((element, index) => {
                    console.log('U74900KA2015PTC078812', element)
                    if (element.reason == 'Others- If any other, please specify.') {
                        return <div>  {index + 1}. {unEscapeRegHtml(cell.incompleteDescription)}</div>

                    } else {
                        return <div>  {index + 1}. {unEscapeRegHtml(element.reason)}</div>

                    }
                })
            }

        }
    },
    {
        dataField: 'incompleteAction',
        text: 'Incomplete Date',
        formatter: (cell, row) => {
            if (cell && cell.incompleteDate) {
                return (moment(cell.incompleteDate).format('YYYY-MM-DD'))

            }

        },
    },
    {
        dataField: 'rejectReasonAction',
        text: 'Reject Reason',

        formatter: (cell, row) => {
            if (cell && cell.rejectReason) {
                if(cell.rejectReason.reason.trim() === 'Others- If any other, please specify. A field to enter other reason with 1000 character limit.')
                {
                    return unEscapeRegHtml(cell.rejectDescription);
                }else{
                    return unEscapeRegHtml(cell.rejectReason.reason);
                }
            }

        }
    },
    {
        dataField: 'rejectReasonAction',
        text: 'Reject Date',
        formatter: (cell, row) => {
            if (cell && cell.rejectDate) {
                return (moment(cell.rejectDate).format('YYYY-MM-DD'))

            }

        },
    },
    {
        dataField: 'statusLabel',
        text: 'Status',

    },
];




{/* <TableHeaderColumn width='150px' dataField='supportingDocument' dataFormat={this.supportingDocumentFormatter}>Supporting Document</TableHeaderColumn> */ }

export default columns;