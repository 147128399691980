import React from 'react';
import { API_URL } from '../../../../constants/ApplicationUrl'
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ROLES } from '../../../../constants/Roles';
import { storage } from '../../../../dependencies/store/storage';

const columns = (
  {
    clickOnAction,
    pageNo,
    size
  }
) => {
  const userRole = storage.getUserRole();

  return [
    {
      dataField: '',
      text: 'S.No',
      formatter: (cell, row, rowIndex) => {
        return (pageNo * size) + (rowIndex + 1);
      }
    }, {
      dataField: 'name',
      text: 'Name',

    }, {
      dataField: 'description',
      text: 'Description',

    }, {
      dataField: 'defaultGlossary',
      text: 'Default',
      align: 'left',
      formatter: (cell) => {
        if (cell) {
          return 'Yes';
        }
        return 'No'
      }
    }, {
      dataField: 'action',
      text: 'Action',
      hidden: userRole !== ROLES.SUPER_ADMIN && userRole !== ROLES.ADMIN,
      formatter: (cell, row) => {
        const { id } = row;
        return (
          <div className="IAS-Actions-list">
            <span><a href='javascript:void(0)' onClick={() => clickOnAction(id)}> Delete</a></span>
            <span className='edit-color'><Link to={`/glossary/form/${id}`} >Edit</Link> </span>

          </div>
        )

      }
    }
  ];
}

export default columns;