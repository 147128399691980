export const LOGIN_ACTION = 'LOGIN_ACTION';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const IS_LOADING = 'IS_LOADING';
export const LOGOUT = 'LOGOUT';
export const AUDIT_TRAIL = 'AUDIT_TRAIL';
export const CLEAR_STORE = 'CLEAR_STORE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAILED = 'EDIT_PROFILE_FAILED';
export const FETCH_ROLES = 'FETCH_ROLES';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';
export const FETCH_USER_LIST = 'FETCH_USER_LIST';
export const FETCH_USER_LIST_FIFTY_SIX = 'FETCH_USER_LIST_FIFTY_SIX';
export const FETCH_USER_LIST_STARTUP_USERS = 'FETCH_USER_LIST_STARTUP_USERS';
export const CLEAR_USER_LIST = 'CLEAR_USER_LIST';
export const FETCH_STARTUP_APPLICATION = 'FETCH_STARTUP_APPLICATION';
export const FETCH_STARTUP_SECTION = 'FETCH_STARTUP_SECTION';
export const FETCH_STARTUP_APPLICATION_VIEW = 'FETCH_STARTUP_APPLICATION_VIEW';
export const SET_STARTUP_SUB_DISTRICT = 'SET_STARTUP_SUB_DISTRICT';
export const CURRENT_STARTUP_APPLICATION = 'CURRENT_STARTUP_APPLICATION';
export const SET_NATURE_OF_THE_ENTITY = 'SET_NATURE_OF_THE_ENTITY';
export const SET_STARTUP_SERVICES = 'SET_STARTUP_SERVICES';
export const SET_STARTUP_SECTOR = 'SET_STARTUP_SECTOR';
export const SET_STATE = 'SET_STATE';
export const SET_STARTUP_DISTRICT = 'SET_STARTUP_DISTRICT';
export const SET_STARTUP_INDUSTRY = 'SET_STARTUP_INDUSTRY';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_REJECTION_LIST = 'GET_REJECTION_LIST';
export const UPDATE_BULK_ARVCHIVE = 'UPDATE_BULK_ARVCHIVE';
export const SET_USER = 'SET_USER';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const CLEAR_USER = 'CLEAR_USER';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_SHOW_NOTIFICATION = 'SET_SHOW_NOTIFICATION';
export const IAC_APPLICATION_DETAILS = 'IAC_APPLICATION_DETAILS';
export const IAC_List = 'IAC_List';
export const NRDC_List = 'NRDC_List';
export const NRDC_Recognition = 'NRDC_ReApplicationUrlcognition';
export const COMMUNICATION_REQUEST = 'COMMUNICATION_REQUEST';
export const EP_ASSIGNED_DETAIL = 'EP_ASSIGNED_DETAIL';
export const IMBMEMBER_ASSIGNED_DETAIL = 'IMBMEMBER_ASSIGNED_DETAIL';
export const IMBCHAIR_ASSIGNED_DETAIL = 'IMBCHAIR_ASSIGNED_DETAIL';
// export const ADMIN_SUPERADMIN_DETAIL = 'ADMIN_SUPERADMIN_DETAIL';
export const FETCH_80IAC_CLARIFICATION = 'FETCH_80IAC_CLARIFICATION';
export const IAC_80_List_DETAIL = 'IAC_80_List_DETAIL';
export const IMB_MEMBER_COMMENT_LIST = 'IMB_MEMBER_COMMENT_LIST';
export const ADMIN_SUPERADMIN_DETAIL = 'ADMIN_SUPERADMIN_DETAIL';
export const SECTION_56_FETCH_ID = 'SECTION_56_FETCH_ID';
export const SECTION_56_LIST = 'SECTION_56_LIST';
export const FETCH_SECTION56_CLARIFICATION = 'FETCH_SECTION56_CLARIFICATION';
export const DASHBOARD_COUNT_DETAILS = 'DASHBOARD_COUNT_DETAILS';
export const DASHBOARD_DAILY_COUNT_DETAILS = 'DASHBOARD_DAILY_COUNT_DETAILS';
export const DASHBOARD_AUDIT_TRAIL = 'DASHBOARD_AUDIT_TRAIL';
export const DASHBORD_DAILY_COUNT_NEW = 'DASHBORD_DAILY_COUNT_NEW';
export const DASHBORD_COUNT_NEW = 'DASHBORD_COUNT_NEW';
export const IAC_80_SECTOR_LIST = 'IAC_80_SECTOR_LIST';
export const STARTUP_LOGO_LIST = 'STARTUP_LOGO_LIST';
export const STARTUP_LOGO_APP_COUNT = 'STARTUP_LOGO_APP_COUNT';
export const STARTUP_LOGO_VIEW_DETAIL = 'STARTUP_LOGO_VIEW_DETAIL';


export const FETCH_ENTITY_TYPE_APPLICATION_REPORT = 'FETCH_ENTITY_TYPE_APPLICATION_REPORT';
export const FETCH_RECOGNISED_REPORT = 'FETCH_RECOGNISED_REPORT';
export const FETCH_TOTAL_REPORT = 'FETCH_TOTAL_REPORT';
export const FETCH_TAX_BENEFIT_REPORT = 'FETCH_TAX_BENEFIT_REPORT';

export const REPORT_STATE_LIST = 'REPORT_STATE_LIST';
export const REPORT_SECTOR_LIST = 'REPORT_SECTOR_LIST';

export const NOTIFICATION_DOCUMENTS_LIST = 'NOTIFICATION_DOCUMENTS_LIST';
export const NOTIFICATION_DOCUMENTS_LIST_BY_ID = 'NOTIFICATION_DOCUMENTS_LIST_BY_ID';
export const GLOSSARY_LIST = 'GLOSSARY_LIST';
export const NOTIFICATION_GLOSSARY_BY_ID = 'NOTIFICATION_GLOSSARY_BY_ID';
export const OTHER_DOCUMENTS_LIST = 'OTHER_DOCUMENTS_LIST';
export const OTHER_DOCUMENTS_LIST_BY_ID = 'OTHER_DOCUMENTS_LIST_BY_ID';
export const  TAB_SECTION_LIST = 'TAB_SECTION_LIST';
export const INCOMPLETE_LIST = 'INCOMPLETE_LIST';
// export const AUDIT_TRAIL = 'AUDIT_TRAIL';
export const INCOMPLETE_LIST_IAC = 'INCOMPLETE_LIST_IAC';
export const COUNTRY_LIST = 'COUNTRY_LIST';
export const STATE_LIST = 'STATE_LIST';
export const INDUSTRY_LIST = 'INDUSTRY_LIST';
export const SERVICES_LIST = 'SERVICES_LIST';


export const RECOGNITION_HISTORY = 'RECOGNITION_HISTORY';
export const APPLICATION80IAC_HISTORY = 'APPLICATION80IAC_HISTORY';
export const SECTION_56_HISTORY = 'SECTION_56_HISTORY';
export const FETCH_NSA_REPORT ='FETCH_NSA_REPORT';
export const SAVE_NSA_RESULT ='SAVE_NSA_RESULT';



export const clearStore = () => {
    return { type: CLEAR_STORE };
}
export const getUserDetails = (data) => {

    return {
        type: GET_USER_DETAILS,
        payload: data
    }
}

// actions
export const loginAction = (data) => {
    return { type: LOGIN_ACTION, payload: data };
}

export const loginFailedAction = () => {
    return { type: LOGIN_FAILED };
}

export const logoutAction = () => {
    return { type: LOGOUT };
}
export const isLoading = (data) => {
    return {
        type: IS_LOADING,
        payload: data
    };
}
export const changePasswordAction = () => {
    return {
        type: CHANGE_PASSWORD
    };
}

export const changePasswordSuccessAction = (data) => {
    return {
        type: CHANGE_PASSWORD_SUCCESS,
        payload: data
    };
}

export const changePasswordFailedAction = (data) => {
    return {
        type: CHANGE_PASSWORD_FAILED,
        payload: data
    };
}


export const editProfileAction = () => {
    return {
        type: EDIT_PROFILE
    }
}
export const ediProfileSuccessAction = (data) => {

    return {
        type: EDIT_PROFILE_SUCCESS,
        payload: data
    }
}

export const editProfileFailedAction = (data) => {
    return {
        type: EDIT_PROFILE_FAILED,
        payload: data
    }
}

export const fetchRolesAction = (data) => {
    return {
        type: FETCH_ROLES,
        payload: data
    };
}

export const fetchUserList = (data) => {

    return {
        type: FETCH_USER_LIST,
        payload: data
    };
}

export const fetchUserListFiftySix = (data) => {
    return {
        type: FETCH_USER_LIST_FIFTY_SIX,
        payload: data
    }
}
export const fetchUserListStartupUsers = (data) => {
    return {
        type: FETCH_USER_LIST_STARTUP_USERS,
        payload: data
    }
}



export const clearUserList = () => {
    return {
        type: CLEAR_USER_LIST
    };
}

export const fetchStartupApplication = (data) => {
    return {
        type: FETCH_STARTUP_APPLICATION,
        payload: data
    }
}

export const fetchStartupApplicationView = (data) => {
    return {
        type: FETCH_STARTUP_APPLICATION_VIEW,
        payload: data
    }
}

export const fetchStartupSection = (data) => {
    return {
        type: FETCH_STARTUP_SECTION,
        payload: data
    }
}



export const addNewUSerAction = () => {
    return {
        type: ADD_USER
    };
}

export const addNewUSerSuccessAction = (data) => {
    return {
        type: ADD_USER_SUCCESS,
        payload: data
    };
}

export const addNewUSerFailedAction = (data) => {
    return {
        type: ADD_USER_FAILED,
        payload: data
    };

}

export const getRejectionList = (data) => {
    return {
        type: GET_REJECTION_LIST,
        payload: data
    }
};

export const updateBulkArchive = (data) => {
    return {
        type: UPDATE_BULK_ARVCHIVE,
        payload: data
    }
};

// export const updateBulkArchive = (data) => {
//     return {
//         type: UPDATE_BULK_ARVCHIVE,
//         payload: data
//     }
// };
export const fetchStartupObservation = (data) => {
    return {
        type: FETCH_80IAC_CLARIFICATION,
        payload: data
    }
};

export const fetchNSAReport = (data) => {
    return {
        type: FETCH_NSA_REPORT,
        payload: data
    }
}

export const saveNsaResult = (data) => {
    return {
        type: SAVE_NSA_RESULT,
        payload: data
    }
}
