import React from 'react';
import { API_URL } from '../../../../constants/ApplicationUrl'
import { Link } from 'react-router-dom';
import moment from 'moment';
// import { SUPPORTING_DOCUMENTS } from '../../../../constants';


const entityColumns = () => {

  return [
    {
      rowIndex: true,
      text: 'SI.No.',
      className: "heading-text",
      showIndexField: 'stateOrUt'
    }, {
      dataField: 'stateOrUtLabel',
      text: 'State/UT',
      className: "heading-text"
    }, {
      text: 'Number of applications based on Entity Type',
      // showTitle: true,
      titleText:"demo1",
      nestedColumn: [{
        dataField: 'approvedPrivateCount',
        text: 'Company',
      },
      {
        dataField: 'approvedRegisteredCount',
        text: 'Partnership Firm',
      }, {
        dataField: 'approvedLlpCount',
        text: 'LLP',
      }
      ]
    },
    {
      text: 'Number of applications based on Entity Type (Incomplete/Reject)',
      // showTitle: true,
      titleText:"demo2",
      nestedColumn: [
        {
          dataField: 'otherPrivateCount',
          text: 'Company',
        },
        {
          dataField: 'otherRegisteredCount',
          text: 'Partnership Firm',
        }, {
          dataField: 'otherLlpCount',
          text: 'LLP',
        }
      ]
    }
  ];
}

export default entityColumns;