export const RecognitionCertificateStatus = [{
    text: 'In Progress',
    value: 'PENDING'
},
{
    text: 'Documents verified',
    value: 'DOCUMENT_VERIFIED',
},
{
    text: 'Incomplete',
    value: 'INCOMPLETE',
},
{
    text: 'Edited',
    value: 'EDITED',
},
{
    text: 'In Progress(NRDC)',
    value: 'IN_PROGRESS_NRDC',
},
{
    text: 'Sent to NRDC for Comments(NRDC)',
    value: 'SEND_FOR_COMMENTS_NRDC',
},
{
    text: 'NRDC Comments Added(NRDC)',
    value: 'COMMENTS_ADDED_NRDC',
},
{
    text: 'Presented in IMB(NRDC)',
    value: 'PRESENTED_IN_IMB_NRDC',
},
{
    text: 'Observations Communicated(NRDC)',
    value: 'OBSERVATIONS_COMMUNICATED_NRDC',
},
{
    text: 'Startup Reply Received(NRDC)',
    value: 'STARTUP_REPLY_RECEIVED_NRDC',
},
{
    text: 'Resend to NRDC for comments(NRDC)',
    value: 'RESEND_FOR_COMMENTS_NRDC',
},
{
    text: 'Updated NRDC Comments added(NRDC)',
    value: 'UPDATED_COMMENTS_ADDED_NRDC',
},
{
    text: 'Tax benefit Approved(NRDC)',
    value: 'TAX_BENEFIT_APPROVED_NRDC',
},
{
    text: 'Rejected(NRDC)',
    value: 'REJECTED_NRDC',
},
{
    text: 'Re - Applied for tax(NRDC)',
    value: 'RE_APPLIED_FOR_TAX_NRDC',
},
{
    text: 'Recognition Expired(NRDC)',
    value: 'RECOGNITION_EXPIRED_NRDC',
},
{
    text: 'In Progress(TIFAC)',
    value: 'IN_PROGRESS_TIFAC',
},
{
    text: 'Sent to TIFAC for Comments(TIFAC)',
    value: 'SEND_FOR_COMMENTS_TIFAC',
},
{
    text: 'TIFAC Comments Added(TIFAC)',
    value: 'COMMENTS_ADDED_TIFAC',
},
{
    text: 'Presented in IMB(TIFAC)',
    value: 'PRESENTED_IN_IMB_TIFAC',
},
{
    text: 'Observations Communicated(TIFAC)',
    value: 'OBSERVATIONS_COMMUNICATED_TIFAC',
},
{
    text: 'Startup Reply Received(TIFAC)',
    value: 'STARTUP_REPLY_RECEIVED_TIFAC',
},
{
    text: 'Resend to TIFAC for comments(TIFAC)',
    value: 'RESEND_FOR_COMMENTS_TIFAC',
},
{
    text: 'Updated TIFAC Comments added(TIFAC)',
    value: 'UPDATED_COMMENTS_ADDED_TIFAC',
},
{
    text: 'Tax benefit Approved(TIFAC)',
    value: 'TAX_BENEFIT_APPROVED_TIFAC',
},
{
    text: 'Rejected(TIFAC)',
    value: 'REJECTED_TIFAC',
},
{
    text: 'Re - Applied for tax(TIFAC)',
    value: 'RE_APPLIED_FOR_TAX_TIFAC',
},
{
    text: 'Recognition Expired(TIFAC)',
    value: 'RECOGNITION_EXPIRED_TIFAC',
}
]

export const IAS80ApplicationStatus = [
    {
        text: 'In Progress',
        value: 'PENDING',
    },
    {
        text: 'Document Verified',
        value: 'DOCUMENT_VERIFIED',
    },
    {
        text: 'Rejected',
        value: 'REJECT',
    },
    {
        text: 'Incomplete',
        value: 'INCOMPLETE',
    },
    {
        text: 'Expired',
        value: 'EXPIRED',
    },
    // {
    //     text: 'N/A',
    //     value: 'NA',
    // },
    {
        text: 'Approved',
        value: 'APPROVED',
    },
    {
        text: 'Cancelled',
        value: 'CANCELLED',
    },
    {
        text: 'On Hold',
        value: 'ON_HOLD'
    },
    // {
    //     text: 'Under Technical Evaluation',
    //     value: 'UNDER_TECHNICAL_EVALUATION',
    // },
    // {
    //     text: 'Under IMB Consideration',
    //     value: 'UNDER_IMB_CONSIDERATION',
    // },
    // {
    //     text: 'Observation Communicated',
    //     value: 'OBSERVATION_COMMUNICATED',
    // }
];
export const IAS80ApplicationStatusForNonAdmin = [
    {
        text: 'In Progress',
        value: 'PENDING',
    },
    {
        text: 'Recommended',
        value: 'RECOMMENDED',
    },
    {
        text: 'Not Recommended',
        value: 'NOT_RECOMMENDED',
    }
    
    
];


export const IAS80Sectors = [
    {
        text: 'Sector 1',
        value: 'sectorOne',
    },
    {
        text: 'Sector 2',
        value: 'sectorTwo',
    }
]

export const SearchByIAS80Options = (sectorOptions,epUserOption) => {
    return [
        {
            text: "Certificate Number",
            value: "recognitionNumber",
            searchFields: [{
                field: 'text',
                name: 'recognitionNumber'
            }],
        },
        {
            text: "Registration No",
            value: "incorporationNumber",
            searchFields: [{
                field: 'text',
                name: 'incorporationNumber'
            }],
        },
        {
            text: "Registration Date",
            value: "incorporationDate",
            searchFields: [{
                field: 'date',
                name: 'incorporationStartDate',
                label: 'Start Date'
            }, {
                field: 'date',
                name: 'incorporationEndDate',
                label: 'End Date'
            }],
        },
        {
            text: "Certificate Date",
            value: "certificateDate",
            searchFields: [{
                field: 'date',
                name: 'startDate',
                label: 'Start Date'
            }, {
                field: 'date',
                name: 'endDate',
                label: 'End Date'
            }, {
                field: 'select',
                name: 'status',
                options: IAS80ApplicationStatus,
            }]
        },
        {
            text: "Name of the Entity",
            value: "entityName",
            searchFields: [{
                field: 'text',
                name: 'entityName'
            }]
        },
        {
            text: "Mobile",
            value: "mobileNumber",
            searchFields: [{
                field: 'text',
                name: 'mobileNumber'
            }]
        }, {
            text: "Email",
            value: "emailId",
            searchFields: [{
                field: 'text',
                name: 'emailId'
            }]
        },
        {
            text: "Application Date",
            value: "createdDate",
            searchFields: [{
                field: 'date',
                name: 'startDate'
            }, {
                field: 'date',
                name: 'endDate'
            }, {
                field: 'select',
                name: 'status',
                options: IAS80ApplicationStatus,
            }]
        },
        {
            text: "Sector",
            value: "sectionId",
            searchFields: [{
                field: 'select',
                name: 'sectionId',
                options: sectorOptions,
            }]
        },
        {
            text: "Status",
            value: "status",
            searchFields: [{
                field: 'select',
                name: 'status',
                options: IAS80ApplicationStatus,
            }]
        },
        {
            text: "User",
            value: "User",
            searchFields: [{
                field: 'select',
                name: 'adminUserName',
                options: epUserOption,
            }]
        }
    ]
}
export const SearchByIAS80OptionsForNonAdmin = (sectorOptions,key,skey) => {
    return [
        {
            text: "Certificate Number",
            value: "recognitionNumber",
            searchFields: [{
                field: 'text',
                name: 'recognitionNumber'
            }],
        },
        {
            text: "Registration No",
            value: "incorporationNumber",
            searchFields: [{
                field: 'text',
                name: 'incorporationNumber'
            }],
        },
        {
            text: "Registration Date",
            value: "incorporationDate",
            searchFields: [{
                field: 'date',
                name: 'incorporationStartDate',
                label: 'Start Date'
            }, {
                field: 'date',
                name: 'incorporationEndDate',
                label: 'End Date'
            }],
        },
        {
            text: "Certificate Date",
            value: "certificateDate",
            searchFields: [{
                field: 'date',
                name: 'startDate',
                label: 'Start Date'
            }, {
                field: 'date',
                name: 'endDate',
                label: 'End Date'
            }, {
                field: 'select',
                name: key,
                options: IAS80ApplicationStatusForNonAdmin,
            }]
        },
        {
            text: "Name of the Entity",
            value: "entityName",
            searchFields: [{
                field: 'text',
                name: 'entityName'
            }]
        },
        {
            text: "Mobile",
            value: "mobileNumber",
            searchFields: [{
                field: 'text',
                name: 'mobileNumber'
            }]
        }, {
            text: "Email",
            value: "emailId",
            searchFields: [{
                field: 'text',
                name: 'emailId'
            }]
        },
        {
            text: "Receiving date", // Application Date
            value: "createdDate",
            searchFields: [{
                field: 'date',
                name: 'startReceivedDate'
            }, {
                field: 'date',
                name: 'endReceivedDate'
            }, {
                field: 'select',
                name: key,
                options: IAS80ApplicationStatusForNonAdmin,
            }]
        },
        {
            text: "Sector",
            value: "sectionId",
            searchFields: [{
                field: 'select',
                name: 'sectionId',
                options: sectorOptions,
            }]
        },
        {
            text: "Status",
            value: "status",
            searchFields: [{
                field: 'select',
                name: key,
                options: IAS80ApplicationStatusForNonAdmin,
            }]
        }
    ]
}
export const SearchByIAS80OptionsForNonAdminIMBCHAIR = (sectorOptions,key,skey) => {
    return [
        {
            text: "Certificate Number",
            value: "recognitionNumber",
            searchFields: [{
                field: 'text',
                name: 'recognitionNumber'
            }],
        },
        {
            text: "Registration No",
            value: "incorporationNumber",
            searchFields: [{
                field: 'text',
                name: 'incorporationNumber'
            }],
        },
        {
            text: "Registration Date",
            value: "incorporationDate",
            searchFields: [{
                field: 'date',
                name: 'incorporationStartDate',
                label: 'Start Date'
            }, {
                field: 'date',
                name: 'incorporationEndDate',
                label: 'End Date'
            }],
        },
        {
            text: "Certificate Date",
            value: "certificateDate",
            searchFields: [{
                field: 'date',
                name: 'startDate',
                label: 'Start Date'
            }, {
                field: 'date',
                name: 'endDate',
                label: 'End Date'
            }, {
                field: 'select',
                name: key,
                options: IAS80ApplicationStatusForNonAdmin,
            }]
        },
        {
            text: "Name of the Entity",
            value: "entityName",
            searchFields: [{
                field: 'text',
                name: 'entityName'
            }]
        },
        {
            text: "Mobile",
            value: "mobileNumber",
            searchFields: [{
                field: 'text',
                name: 'mobileNumber'
            }]
        }, {
            text: "Email",
            value: "emailId",
            searchFields: [{
                field: 'text',
                name: 'emailId'
            }]
        },
        
        {
            text: "Receiving date", // Receiving date 
            value: skey,
            searchFields: [{
                field: 'date',
                name: 'startReceivedDate'
            }, {
                field: 'date',
                name: 'endReceivedDate'
            }, {
                field: 'select',
                name: key,
                options: IAS80ApplicationStatusForNonAdmin,
            }]
        },
        {
            text: "Application Date",
            value: "createdDate",
            searchFields: [{
                field: 'date',
                name: 'startDate'
            }, {
                field: 'date',
                name: 'endDate'
            }, {
                field: 'select',
                name: key,
                options: IAS80ApplicationStatusForNonAdmin,
            }]
        },
        {
            text: "Sector",
            value: "sectionId",
            searchFields: [{
                field: 'select',
                name: 'sectionId',
                options: sectorOptions,
            }]
        },
        {
            text: "Status",
            value: "status",
            searchFields: [{
                field: 'select',
                name: key,
                options: IAS80ApplicationStatusForNonAdmin,
            }]
        }
    ]
}
// export const SearchByIAS80OptionsForNonAdmin = (sectorOptions,key,skey) => {
//     return [
//         {
//             text: "Certificate Number",
//             value: "recognitionNumber",
//             searchFields: [{
//                 field: 'text',
//                 name: 'recognitionNumber'
//             }],
//         },
//         {
//             text: "Registration No",
//             value: "incorporationNumber",
//             searchFields: [{
//                 field: 'text',
//                 name: 'incorporationNumber'
//             }],
//         },
//         {
//             text: "Registration Date",
//             value: "incorporationDate",
//             searchFields: [{
//                 field: 'date',
//                 name: 'incorporationStartDate',
//                 label: 'Start Date'
//             }, {
//                 field: 'date',
//                 name: 'incorporationEndDate',
//                 label: 'End Date'
//             }],
//         },
//         {
//             text: "Certificate Date",
//             value: "certificateDate",
//             searchFields: [{
//                 field: 'date',
//                 name: 'startDate',
//                 label: 'Start Date'
//             }, {
//                 field: 'date',
//                 name: 'endDate',
//                 label: 'End Date'
//             }, {
//                 field: 'select',
//                 name: key,
//                 options: IAS80ApplicationStatusForNonAdmin,
//             }]
//         },
//         {
//             text: "Name of the Entity",
//             value: "entityName",
//             searchFields: [{
//                 field: 'text',
//                 name: 'entityName'
//             }]
//         },
//         {
//             text: "Mobile",
//             value: "mobileNumber",
//             searchFields: [{
//                 field: 'text',
//                 name: 'mobileNumber'
//             }]
//         }, {
//             text: "Email",
//             value: "emailId",
//             searchFields: [{
//                 field: 'text',
//                 name: 'emailId'
//             }]
//         },
//         {
//             text: "Receiving date", // Application Date
//             value: "createdDate",
//             searchFields: [{
//                 field: 'date',
//                 name: 'startReceivedDate'
//             }, {
//                 field: 'date',
//                 name: 'endReceivedDate'
//             }, {
//                 field: 'select',
//                 name: key,
//                 options: IAS80ApplicationStatusForNonAdmin,
//             }]
//         },
//         {
//             text: "Sector",
//             value: "sectionId",
//             searchFields: [{
//                 field: 'select',
//                 name: 'sectionId',
//                 options: sectorOptions,
//             }]
//         },
//         {
//             text: "Status",
//             value: "status",
//             searchFields: [{
//                 field: 'select',
//                 name: key,
//                 options: IAS80ApplicationStatusForNonAdmin,
//             }]
//         }
//     ]
// }
// export const SearchByIAS80OptionsForNonAdminIMBCHAIR = (sectorOptions,key,skey) => {
//     return [
//         {
//             text: "Certificate Number",
//             value: "recognitionNumber",
//             searchFields: [{
//                 field: 'text',
//                 name: 'recognitionNumber'
//             }],
//         },
//         {
//             text: "Registration No",
//             value: "incorporationNumber",
//             searchFields: [{
//                 field: 'text',
//                 name: 'incorporationNumber'
//             }],
//         },
//         {
//             text: "Registration Date",
//             value: "incorporationDate",
//             searchFields: [{
//                 field: 'date',
//                 name: 'incorporationStartDate',
//                 label: 'Start Date'
//             }, {
//                 field: 'date',
//                 name: 'incorporationEndDate',
//                 label: 'End Date'
//             }],
//         },
//         {
//             text: "Certificate Date",
//             value: "certificateDate",
//             searchFields: [{
//                 field: 'date',
//                 name: 'startDate',
//                 label: 'Start Date'
//             }, {
//                 field: 'date',
//                 name: 'endDate',
//                 label: 'End Date'
//             }, {
//                 field: 'select',
//                 name: key,
//                 options: IAS80ApplicationStatusForNonAdmin,
//             }]
//         },
//         {
//             text: "Name of the Entity",
//             value: "entityName",
//             searchFields: [{
//                 field: 'text',
//                 name: 'entityName'
//             }]
//         },
//         {
//             text: "Mobile",
//             value: "mobileNumber",
//             searchFields: [{
//                 field: 'text',
//                 name: 'mobileNumber'
//             }]
//         }, {
//             text: "Email",
//             value: "emailId",
//             searchFields: [{
//                 field: 'text',
//                 name: 'emailId'
//             }]
//         },
        
//         {
//             text: "Receiving date", // Receiving date 
//             value: skey,
//             searchFields: [{
//                 field: 'date',
//                 name: 'startReceivedDate'
//             }, {
//                 field: 'date',
//                 name: 'endReceivedDate'
//             }, {
//                 field: 'select',
//                 name: key,
//                 options: IAS80ApplicationStatusForNonAdmin,
//             }]
//         },
//         {
//             text: "Application Date",
//             value: "createdDate",
//             searchFields: [{
//                 field: 'date',
//                 name: 'startDate'
//             }, {
//                 field: 'date',
//                 name: 'endDate'
//             }, {
//                 field: 'select',
//                 name: key,
//                 options: IAS80ApplicationStatusForNonAdmin,
//             }]
//         },
//         {
//             text: "Sector",
//             value: "sectionId",
//             searchFields: [{
//                 field: 'select',
//                 name: 'sectionId',
//                 options: sectorOptions,
//             }]
//         },
//         {
//             text: "Status",
//             value: "status",
//             searchFields: [{
//                 field: 'select',
//                 name: key,
//                 options: IAS80ApplicationStatusForNonAdmin,
//             }]
//         }
//     ]
// }


export const NDRC_STATUS = {
    SUBMIT: 'Submit',
    SAVE_DRAFT: 'Save as Draft',
    FINAL_SUBMIT: 'Final Submit',
}

