export const STAGE = {
    IDEATION: 'IDEATION',
    VALIDATION: 'VALIDATION',
    EARLY_TRACTION: 'EARLY_TRACTION',
    SCALING: 'SCALING'
}

export const IPR = {
    YES: '1',
    NO: '0'
};

export const WORK_CATEGORIES = {
    YES: '1',
    NO: '0'
}
export const SCALABLE_BUSINESS_MODEL = {
    YES: '1',
    NO: '0'
}

export const RECEIVE_FUNDING = {
    YES: '1',
    NO: '0'
}


export const SELF_CERTIFICATE_ADDITIONAL_DOCUMENT = [
    {
        value: 'WEBSITE_LINK',
        text: 'Website link',
        type: 'link'
    },
    {
        value: 'VIDEOS',
        text: 'Videos',
        type: 'link'
    },
    {
        value: 'PITCH_DECK',
        text: 'Pitch Deck',
        type: 'file'
    },
    {
        value: 'PATENTS',
        text: 'Patents',
        type: 'file'
    },
    {
        value: 'OTHERS',
        text: 'Others',
        type: 'file'
    }
]