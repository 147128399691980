import React from 'react';
import { API_URL } from '../../../constants/ApplicationUrl'

const columns = [
    {
        dataField: 'id',
        text: 'Sr.No',
        formatter: (cell, row, rowIndex) => {
            return rowIndex + 1
        }
    }, {
        dataField: 'financialYear',
        text: 'Financial Year'
    }, {
        dataField: 'income',
        text: 'Upload Document',
        formatter: (cell, row) => {
            if (row.income && row.income.publicUrl) {
                return (
                    <a href={`${API_URL()}${row.income.publicUrl}`} target="_blank">View Document </a>
                   
                )
            }

        }
    },
];


export default columns;