import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ROLES } from '../../../../constants/Roles';
import { storage } from '../../../../dependencies/store/storage';
import ReadMore from '../../../common/ReadMore'
import {unEscapeRegHtml} from '../../../../dependencies/utils/helper';


const nrdcCommentUpdateCol = (props) => {

    const userRole = storage.getUserRole();
    return [
        {
            dataField: '',
            text: 'Sr.No',
            formatter: (cell, row, rowIndex) => {
                return rowIndex + 1
            }
        }, {
            dataField: 'dippNumber',
            text: 'DIPP NO',
        }, {
            dataField: 'entityName',
            text: 'Entity Name',
        }, {
            dataField: 'briefAboutEntity',
            text: 'Brief about Entity',
            formatter: (briefAboutEntity) => {
                if (briefAboutEntity) {
                    return <ReadMore text={unEscapeRegHtml(briefAboutEntity)} />
                }

            }
        }, {
            dataField: '',
            text: 'Comments on startups product / service or process',
            formatter: (cell, row) => {
                let value = "";

                if (row.innovation) {
                    value = 'processInnovation'
                }
                if (row.improvement) {
                    value = value ? value + ', ' : value
                    value = value + 'Improvement and Scalability'
                }
                if (row.employment) {
                    value = value ? value + ', ' : value
                    value = value + 'Employment generation'
                }
                if (row.wealth) {
                    value = value ? value + ', ' : value
                    value = value + 'Wealth creatio'
                }
                return value
            }
        }, {
            dataField: 'pspComment',
            text: 'Comments',
            formatter: (pspComment) => {
                if (pspComment) {
                    return <ReadMore text={unEscapeRegHtml(pspComment)} />
                }
            }
        }, {
            dataField: 'patentComment',
            text: 'Comments on Patent',
            formatter: (patentComment) => {
                if (patentComment) {
                    return <ReadMore text={unEscapeRegHtml(patentComment)} />
                }
            }
           

        }, {
            dataField: 'serviceComment',
            text: 'Comments on Similar Services',
            formatter: (serviceComment) => {
                if (serviceComment) {
                    return <ReadMore text={unEscapeRegHtml(serviceComment)} />
                }
            }
        }, {
            dataField: 'recommendTypeLabel',
            text: 'Would you like to recommend ?',
        }, {
            dataField: 'statusLabel',
            text: 'Status',
        }, {
            dataField: 'commentDate',
            text: 'Comment Added',
            formatter: (commentDate) => {
                if (commentDate) {
                    return moment(commentDate).format('DD MMM, YYYY')
                }
            }
        }, {
            dataField: '',
            text: '',
            hidden: userRole !== ROLES.NRDC_ADMIN && userRole !== ROLES.TIFAC_ADMIN,
            formatter: (cell, row) => {
                if (row.status !== 'SUBMIT') {
                    return (
                        <Link to={`/application/80iac/comment/form/${row.exemptionId}/${row.id}`}>Edit</Link>
                    )

                }

            }
        }
    ];
}

export default nrdcCommentUpdateCol;