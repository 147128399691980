export const sectionStartupLogoStatus = [
    {
        value: 'APPROVED',
        text: "Approved"
    }, {
        value: 'PENDING',
        text: "Pending"
    },
    {
        value: 'REJECT',
        text: "Rejected"
    }
]
export const SearchByStartupLogo = [

    {
        text: "Name of the event",
        value: "nameOfEvent",
        searchFields: [{
            field: 'text',
            name: 'nameOfEvent'
        }],
    }, {
        text: "Name of the applicant",
        value: "nameOfApplicant",
        searchFields: [{
            field: 'text',
            name: 'nameOfApplicant'
        }],
    },
    {
        text: "Name of the Entity",
        value: "nameOfOrganisingEntity",
        searchFields: [{
            field: 'text',
            name: 'nameOfOrganisingEntity'
        }]
    },
    {
        text: "Event Date",
        value: "eventDate",
        searchFields: [{
            subField: 'eventDate',
            field: 'date',
            name: 'startDate'
        }, {
            subField: 'eventDate',
            field: 'date',
            name: 'endDate'
        }]
    },
    {
        text: "Nature of Event",
        value: "natureOfEvent",
        searchFields: [{
            field: 'text',
            name: 'natureOfEvent'
        }]
    },
    {
        text: "Status",
        value: "startuplogostatus",
        searchFields: [{
            field: 'select',
            name: 'status',
            options: sectionStartupLogoStatus,
        }]

    },
]


