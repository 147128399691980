import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';


const columns = [
    {
        dataField: 'id',
        text: 'Sr.No',
        formatter: (cell, row, rowIndex) => {
            return rowIndex + 1
        }
    },
    {
        dataField: 'stateName',
        text: 'State Name'
    },
    {
        dataField: '',
        text: 'Action',
        formatter: (cell, row, rowIndex) => {
            return <div className='edit-color'>
                <Link to={`/update/state/form/${row.id}`}>Edit</Link>
            </div>
        }
    }
];


export default columns;
