import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { getUserList, clearUserListData } from '../../../dependencies/services/userListServices';
import { connect } from 'react-redux';
import Table from '../../common/Table';
import columns from '../dependencies/userListColumns';
import SearchBoxForUser from '../../common/SearchBoxForUser';
import { storage } from '../../../dependencies/store/storage';
const FilterOption = [
  {
    text: "ALL",
    value: "ALL"
  },
  {
    text: "ADMIN",
    value: "ADMIN"
  },
  {
    text:"SUPER ADMIN",
    value: "SUPER_ADMIN"
  },
  {
    text: "EXPERT PANEL (NON FINANCIAL)",
    value: "EP_1"
  },
  {
    text: "EXPERT PANEL (FINANCIAL)",
    value: "EP_2"
  },
  {
    text: "IMB MEMBER",
    value: "IMB_MEMBER"
  },
  {
    text: "IMB CHAIR",
    value: "IMB_CHAIR"
  }
]
const UserList = (props) => {
  const { getUserList, users } = props;
  const fetchAllRecords = true;
  const [sortOrder, setSortOrder] = useState('');
  const [sortColumn, setSortColumn] = useState('');
  const [selectedRole, setSelectedRole] = useState('ALL');
  const userRole = storage.getUserRole();

  useEffect(() => {
    getUserList({role:selectedRole});
  }, [sortOrder, sortColumn, selectedRole]);

  const onSortChange = (params) => {
    const { order = 'asc', dataField } = params;
    if (dataField) {
      setSortOrder(order);
      setSortColumn(dataField);
    }
  }
  return (
    <div className="table-wrap">
      <SearchBoxForUser option={FilterOption} doSearch={(e) => setSelectedRole(e)} />
      <Table
        dataTable={users}
        columns={columns({userRole,page:'80IAC'})}
        keyField='username'
        onSortChange={onSortChange}
        sizePerPage={1000}
        // totalSize={recordsTotal}
      />
    </div>
  )
}

UserList.propTypes = {
  getUserList: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.object),
};

UserList.defaultProps = {
  getUserList: () => { },
  users: []
};

const mapStateToProps = (state) => {
  return {
    users: state.userList.userData.data
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({
      getUserList,
    }, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserList);