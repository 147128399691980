import React, {useEffect, useState, useRef} from 'react';
import { FaCloudUploadAlt } from 'react-icons/fa';
import Button from '../Button';

const FileUploadBtn = (props) => {

    const {
        successMessage,
        setSuccessMessage,
        onUploadFile,
        error,
        allowedFiles = [],
        hasInvalidSubmitOccurred,
    } = props;

    const inputFile = useRef(null)

    const [fileError, setError] = useState("");
    const [uploadFile, setUploadFile] = useState("");
    const [touched, setTouched] = useState(false);


    useEffect(() => {
      setError(error);
    }, [error])

    const onButtonClick = () => {
       inputFile.current.click();
    };

    const validateUploadFile = (file) => {
        const val = file && file.name || "";
        var regex = new RegExp("([a-zA-Z0-9\s_\!@#\$%\^\&*\\.\-\w():])+(" + allowedFiles.join('|') + ")$");
        if (val && !regex.test(val.toLowerCase())) {
          setSuccessMessage('')
          setError("Please upload files having extensions " + allowedFiles.join(', ') + " only.");
          return false;
        }
        return true;
    }

    const onFileSelect = (event) => {
        const { target = {} } = event;
        const val = target.value || "";
        const name = target.name || "";
        const file = target.files[0];
        if (validateUploadFile(file)) {
          if (target.files && file) {            
            setUploadFile(name);
            setError("")
            if (onUploadFile) {
              onUploadFile(file);
            }
          }
        } else {
          event.target.value = ""
        }
      }
    return (
        <React.Fragment>
            {
                  fileError && <label
                    htmlFor=""
                    className="error txt-danger"
                    style={{float: 'right',position: 'absolute',right: '49px',top: '196px',color:'#f32013'}}
                    >
                    {fileError}
                    </label>
            }
            {
              successMessage && <label style={{float: 'right',position: 'absolute',right: '49px',top: '196px',color:'#198754'}}>
                {successMessage}
              </label>
            }
            <div>
                
                {/* <label style={{textAlign:'center'}}>Beneficiary List</label> */}
                <Button
                    text={'upload Beneficiary'}
                    onClick={onButtonClick}
                    type={'file'}
                    className={'bttn'}
                    icon={<FaCloudUploadAlt />}
                />
                <input
                    type='file' 
                    id='file' 
                    ref={inputFile} 
                    style={{display: 'none'}}
                    onChange={onFileSelect}
                    onClick={(event)=> { event.target.value = null }}
                />
            </div>
            <br></br>
        </React.Fragment>
    )
}

export default FileUploadBtn;