import api from "../utils/Api";
import {ADD_NEW_USER_URL} from "../../constants/ApplicationUrl";
import {
    addNewUSerAction,
    addNewUSerFailedAction,
    addNewUSerSuccessAction,
    isLoading
} from "../../constants/actionConstants";
import {validateEmail, validateUserName} from './../utils/validation/Validation';
import moment from 'moment';


export const clearMessage = () => (dispatch) => {
    dispatch(addNewUSerAction(''));
}

export const addUser = ({emailId, username, password, confirmPassword, role, name, dob}) => (dispatch) => {

        let flag = validateUserName(username);
        if (flag.status === false) {
            dispatch(addNewUSerFailedAction(flag.error));
            return false;
        }
        flag = validateEmail(emailId);
        if (flag.status === false) {
            dispatch(addNewUSerFailedAction(flag.error));
            return false;
        }

        if (password === '' || confirmPassword === '') {
            dispatch(addNewUSerFailedAction('Password field can not be blank.'));
            return false;
        }

        if (password !== confirmPassword) {
            dispatch(addNewUSerFailedAction('Password and confirm does not matched.'));
            return false;
        }
        if (name === '') {
            dispatch(addNewUSerFailedAction('Please enter name.'));
            return false;
        }
        if (moment(dob,'DD/MM/YYYY').format('DD/MM/YYYY') !== dob) {
            dispatch(addNewUSerFailedAction('Please enter valid dob.'));
            return false;
        }

        if (role === '') {
            dispatch(addNewUSerFailedAction('Select any role.'));
            return false;
        }
        dispatch(isLoading(true));
        dob = moment(dob,'DD/MM/YYYY').format('YYYY-MM-DD')

        return api.POST(ADD_NEW_USER_URL, {emailId, username, password, role, name, dob})
            .then((data) => {
                if (data.data && data.data.status) {
                    dispatch(addNewUSerSuccessAction(data.data.message));
                    return true;
                } else {
                    dispatch(addNewUSerFailedAction(data.data.message));
                    return false;
                }
                dispatch(isLoading(false));
            })

    }
;
