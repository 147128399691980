import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { API_URL } from '../../constants/ApplicationUrl';
import { SUPPORTING_DOCUMENTS } from '../../constants/index'
import React from 'react';
import moment from 'moment';
import { unEscapeRegHtml } from '../../dependencies/utils/helper';
import './applicationViewTransformer.scss';

export default (response) => {
    let natureOfEntity =
    {
        'Nature of the Entity': response.startupEntity && response.startupEntity.startupNatureOfEntity ? response.startupEntity.startupNatureOfEntity.name : '',
        'Industry': response.startupEntity && response.startupEntity.startupIndustry ? response.startupEntity.startupIndustry.industryName : '',
        'Sector': response.startupEntity && response.startupEntity.startupSection ? response.startupEntity.startupSection.sectionName : '',
        'Categories': response.startupEntity ? Array.prototype.map.call(response.startupEntity.startupServices, s => s.serviceName).toString() : '',
        'Reference number': response ? response.referenceNumber : '',
        'Certificate of recognition number': response.startupEntity && response.startupEntity.recognitionNumber ? response.startupEntity.recognitionNumber : '',
        'Registration / Incorporation No.': response ? response.incorporationNumber : '',
        'Name of the Entity': response && response.startupEntity ? response.startupEntity.name : '',
        'Registration / Incorporation Date': response.startupEntity ? response.startupEntity.incorporationDate : '',
        'PAN': response && response.startupEntity ? response.startupEntity.panNumber : ''
    }
    let officeAddress =
    {
        'Address Line 1': response.address ? unEscapeRegHtml(response.address.address1) : '',
        'Address Line 2': response.address ? unEscapeRegHtml(response.address.address2) : '',
        'Address Line 3': response.address ? unEscapeRegHtml(response.address.address3) : '',
        'City': response.address ? unEscapeRegHtml(response.address.city) : '',
        'State': response.address ? response.address.stateName : '',
        'District': response.address ? response.address.districtName : '',
        'Sub District': response.address ? response.address.subDistrictName : '',
        'Pin Code': response.address ? response.address.pinCode : '',
    }

    let autherizeRepresentativeDetails =
    {
        'Name of Authorized Representative': response.representative ? unEscapeRegHtml(response.representative.name) : '',
        'Designation of Authorized Representative': response.representative ? unEscapeRegHtml(response.representative.designation) : '',
        'Mobile No. of Authorized Representative': response.representative ? response.representative.mobile : '',
        'Email ID of Authorized Representative': response.representative ? response.representative.email : ''
    }
    let directors = Object.values(response.directors ? response.directors : '').map(item => {

        return {
            'Name of Director / Partner	': unEscapeRegHtml(item.name),
            'Gender': item.gender,
            'Contact Details of Director / Partner': item.mobileNumber,
            'Address of Director / Partner': unEscapeRegHtml(item.address),
            'Email Id': item.email,
            'DIN/DPIN' : item.directorCin || ""
        };
    })


    if (response) {
        if (response.selfCertificate !== undefined && response.selfCertificate.additionalDocument)

            var linkDiv = response.selfCertificate.additionalDocument.map((element, index) => {
                let link = "";
                if (element && element.hasOwnProperty('file') && element.file) {
                    link = `${API_URL()}${element.file.publicUrl}`
                } else if (element && element.hasOwnProperty('url') && element.name === 'WEBSITE_LINK') {
                    link = `${element.url.indexOf('http') == -1 ? 'http://' : ''}${element.url}`
                } else if (element && element.hasOwnProperty('url') && element.url) {
                    link = element.url
                }
                return <div key={index}>
                    {link ?
                        <a href={link} target="_blank"> {SUPPORTING_DOCUMENTS[element.name] || ""} </a> : null} </div>
            })

    }
    var finalCategory = {};
    var finalIprs = {};
    if (response.additionalInformation) {
        var finalCategory = response.additionalInformation.workCategories.reduce(
            (obj, item) => Object.assign(obj, {
                [item.workNature]: item.innovative ? 'Innovative'
                    : (item.improvement ? 'Improvement' : '')
            }), {});

    }
    if (response.additionalInformation) {
        var finalIprs = response.additionalInformation.iprs.reduce(
            (obj, item) => {
                let data = {};
                if (item && item.hasOwnProperty('iprTitle')) {
                    data[item.iprTitle] = item.applied ? item.applicationNumber ? `Applied (Application No:- ${item.applicationNumber})` : 'Applied' : (item.registered ? item.applicationNumber ? `Registered (Application No:- ${item.applicationNumber})` : 'Registered' : '');
                }
                return { ...obj, ...data }
            }, {})
    }

    let incomppleteReason = {}
    if (response && response.incompleteAction && response.incompleteAction.incompleteReasons && response.incompleteAction.incompleteReasons.length > 0) {
        var incompleteDiv = response.incompleteAction.incompleteReasons.map((element, index) => {
            if (element.reason == 'Others- If any other, please specify.') {
                return <div>  {index + 1}. {unEscapeRegHtml(response.incompleteAction.incompleteDescription)}</div>

            } else {
                return <div>  {index + 1}. {unEscapeRegHtml(element.reason)}</div>

            }
        })
        incomppleteReason = { 'Incomplete Reason': incompleteDiv, 'Incomplete Date': response.incompleteAction.incompleteDate ? moment(response.incompleteAction.incompleteDate).format('DD-MM-YYYY') : '' }

    }

    let rejectReason = {}
    if (response && response.rejectReasonAction && response.rejectReasonAction.rejectReason) {

        if (response.rejectReasonAction.rejectReason.reason === 'Others- If any other, please specify. A field to enter other reason with 1000 character limit. ') {
            rejectReason = { 'Reject Reason': response.rejectReasonAction.rejectDescription ? unEscapeRegHtml(response.rejectReasonAction.rejectDescription) : '', 'Reject Date': response.rejectReasonAction.rejectDate ? moment(response.rejectReasonAction.rejectDate).format('DD-MM-YYYY') : '' }

        } else {
            rejectReason = { 'Reject Reason': response.rejectReasonAction.rejectReason.reason ? unEscapeRegHtml(response.rejectReasonAction.rejectReason.reason) : '', 'Reject Date': response.rejectReasonAction.rejectDate ? moment(response.rejectReasonAction.rejectDate).format('DD-MM-YYYY') : '' }

        }

    }
    

    let informationRequired =
    {
        'Current number of employees (including founders)': response.additionalInformation ? response.additionalInformation.numberOfEmployees : '',
        'What is the current stage of development of your product / service?': response.additionalInformation ? response.additionalInformation.stage : '',
        'Has your startup applied for any IPR (Intellectual Property Right) ?*': response.additionalInformation ? (response.additionalInformation.ipr ? 'Yes' : 'No') : '',
        ...finalIprs,
        'Is the startup creating an innovative product / service / process or improving an existing product / service / process*': response.additionalInformation ? (response.additionalInformation.workCategory ? 'Yes' : 'No') : '',
        ...finalCategory,
        'Is the startup creating a scalable business model with high potential of employment generation or wealth creation?*': response.additionalInformation
            ? (response.additionalInformation.scalableBusinessModel
                ? ((response.additionalInformation.businessModel.employmentGeneration
                    && response.additionalInformation.businessModel.wealthCreation)
                    ? 'Yes, Employment generation , Wealth creation' :
                    (response.additionalInformation.businessModel.employmentGeneration
                        ? 'Yes, Employment generation'
                        : (response.additionalInformation.businessModel.wealthCreation
                            ? 'Yes, Wealth creation'
                            : ''))
                ) : ''
            ) :
            '',
        'Please submit a brief note supporting the options chosen above for innovation, improvement and scalability - 1000 words maximum	': (response.additionalInformation !== undefined && response.additionalInformation.businessModel) ? unEscapeRegHtml(response.additionalInformation.businessModel.note) : '',
        'Please mention any awards/recognition received by the entity': response.activities ? unEscapeRegHtml(response.activities.awardsReceived) : '',
        'Awards/Recognition Document': response.activities && response.activities.awardDocument && response.activities.awardDocument.publicUrl ? <a href={`${API_URL()}${response.activities.awardDocument.publicUrl}`} target="_blank">View Document </a> : '',
        'What is the problem the startup is solving?': response.activities ? unEscapeRegHtml(response.activities.problemStatement) : '',
        'How does your startup propose to solve this problem?': response.activities ? unEscapeRegHtml(response.activities.problemSolution) : '',
        'What is the uniqueness of your solution?': response.activities ? unEscapeRegHtml(response.activities.solutionUniqueness) : '',
        'How does your startup generate revenue?': response.activities ? unEscapeRegHtml(response.activities.generateRevenue) : '',
        'Registration / Incorporation Certificate': (response.selfCertificate !== undefined && response.selfCertificate.registrationCertificate) ? < a href={`${API_URL()}${response.selfCertificate.registrationCertificate.publicUrl}`} target="_blank" >View Document</a> : '',
        'Additional document to support your application': linkDiv,
        'Would you like to send for tax benefits to IMB?': response.sendForTaxBenefit ? response.sendForTaxBenefit : '',
        'No of Chances given to startup?': response ? response.incompleteCount : '',
        ...incomppleteReason,
        ...rejectReason
    }

    let selfCertificate = {
        'Is Merger': ( (response.selfCertificate !== undefined && response.selfCertificate.isMerger ) ? 
            <div className="self-certify">
                <a href={`${API_URL()}${response.selfCertificate.mergerDocs[0]?.publicUrl}`} target="_blank">Recognition Certificate</a> 
                <a href={`${API_URL()}${response.selfCertificate.mergerDocs[1]?.publicUrl}`} target="_blank">NCLT Order</a> 
                <a href={`${API_URL()}${response.selfCertificate.mergerDocs[2]?.publicUrl}`} target="_blank">Form INC-28</a>  
                <a href={`${API_URL()}${response.selfCertificate.mergerDocs[3]?.publicUrl}`} target="_blank">Financial statement</a>
            </div> 
            :  (response.selfCertificate !== undefined && !response.selfCertificate.isMerger) ? 'NA' : '' ),
        'The entity is formed as a result of conversion from one form to another subject to the fulfilment of condition provided in sub - section(3) of section 80-IAC of the Income- tax Act, 1961.': ( (response.selfCertificate !== undefined && response.selfCertificate.isConversion ) ? 
            <div className="self-certify">
                <a href={`${API_URL()}${response.selfCertificate.conversionDocs[0]?.publicUrl}`} target="_blank">Original Incorporation Certificate</a> 
                <a href={`${API_URL()}${response.selfCertificate.conversionDocs[1]?.publicUrl}`} target="_blank" >Incorporation Certificate upon conversion</a>
            </div> : (response.selfCertificate !== undefined && !response.selfCertificate.isConversion) ? 'NA' : '' ),

        'The entity has changed its name as necessitated under the relevant provisions of the applicable Act and the entity is applying with the new name.': ( (response.selfCertificate !== undefined && response.selfCertificate.isNameChange) ?
            <div className="self-certify">
                <a href={`${API_URL()}${response.selfCertificate.nameChangeDocs[0]?.publicUrl}`} target="_blank">Original Incorporation Certificate</a>  
                <a href={`${API_URL()}${response.selfCertificate.nameChangeDocs[1]?.publicUrl}`} target="_blank">Incorporation Certificate upon conversion</a>
                {response.startupEntity.startupNatureOfEntity.id === "5f48ce5f2a9bb065cdfa185d" ?
                 <a href={`${API_URL()}${response.selfCertificate.nameChangeDocs[2]?.publicUrl}`} target="_blank">Pan Card</a> : ''}
            </div> : (response.selfCertificate !== undefined && !response.selfCertificate.isNameChange) ? 'NA' : '' ) ,
        'The entity/s CIN/LLPIN changed': ((response.selfCertificate !== undefined && response.selfCertificate.isCinChange) ?
            <div className="self-certify">
                <a href={`${API_URL()}${response.selfCertificate.cinChangeDocs[0]?.publicUrl}`} target="_blank" > Original Incorporation Certificate</a>  
                <a href={`${API_URL()}${response.selfCertificate.cinChangeDocs[1]?.publicUrl}`} target="_blank" >Incorporation Certificate upon conversion</a>
            </div > : (response.selfCertificate !== undefined && !response.selfCertificate.isCinChange) ? 'NA' : '' ),
        'Authorization Letter': (response.selfCertificate !== undefined && response.selfCertificate.authorizationLetter ?
            <a href={`${API_URL()}${response.selfCertificate.authorizationLetter?.publicUrl}`} target="_blank" > Authorization Letter</a> : ''),  
    }
    return {
        natureOfEntity: natureOfEntity,
        officeAddress: officeAddress,
        autherizeRepresentativeDetails: autherizeRepresentativeDetails,
        directors: directors,
        informationRequired: informationRequired,
        buttonStatus: response.status,
        referenceNumber: response ? response.referenceNumber : '',
        applicationId: response ? response.id : '',
        archived: response ? response.archived : '',
        swsId: response ? response.swsId : '' ,
        applReqId: response ? response.applReqId : '',
        preCertified: response.isPreCertified,
        selfCertificate: !response.isPreCertified ? selfCertificate :''
    };
};