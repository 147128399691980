import React from 'react';
import { Link } from 'react-router-dom';
import ReadMore from '../../../common/ReadMore'
import { unEscapeRegHtml } from '../../../../dependencies/utils/helper';

import { getDocumentLink } from '../../../../dependencies/utils/helper';
const communicationColumns = (props) => {


    return [
        {
            dataField: '',
            text: 'Sr.No',
            formatter: (cell, row, rowIndex) => {
                return rowIndex + 1
            }
        },
        {
            dataField: 'observation',
            text: 'Observation',
            formatter: (observation) => {
                if (observation) {
                    return <ReadMore text={unEscapeRegHtml(observation)} />
                }
            }
        }, {
            dataField: 'startupResponse',
            text: 'Response',
            formatter: (startupResponse) => {
                if (startupResponse) {
                    return <ReadMore text={unEscapeRegHtml(startupResponse)} />
                }
            }
        },
        {
            dataField: 'uploadedFiles',
            text: 'Uploaded Files',
            formatter: (cell) => {
                if (cell) {
                    if (cell) {
                        return cell.map((element, index) => {
                            return <div><a href={`${getDocumentLink(element['publicUrl'])}`} target="_blank"> {element.name} </a></div>

                        })
                    }
                }
            },
        },
        {
            dataField: 'responseDate',
            text: 'Response Date',
            formatter: (responseDate) => {
                if (responseDate) {
                    var month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                    const d = new Date(responseDate);
                    const formate = `${weekdays[d.getUTCDay()]}, ${d.getUTCDate()} ${month[d.getUTCMonth() - 1]},  ${d.getUTCFullYear()}`
                    return formate;
                }
            }

        }
    ];
}

export default communicationColumns;