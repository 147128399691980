import React from 'react';
import { API_URL } from '../../../../constants/ApplicationUrl'
import { Link } from 'react-router-dom';
import moment from 'moment';
// import { SUPPORTING_DOCUMENTS } from '../../../../constants';


const taxBenefitColumns = () => {

  return [
    {
      rowIndex: true,
      text: 'SI.No.',
      className: "heading-text",
      showIndexField:'stateOrUt'
    }, {
      dataField: 'stateOrUtLabel',
      text: 'State/UT',
      className: "heading-text"
    }, {
      text: 'Number of applications for Tax benefits',
      nestedColumn: [{
        dataField: 'requestedCount80Iac',
        text: '80IAC Requested', 
      }, {
        dataField: 'notRequestedCount80Iac',
        text: '80IAC Not Requested',
      },
      {
        dataField: 'requestedCountSec56',
        text: 'Form56 Requested',
      }, {
        dataField: 'notRequestedCountSec56',
        text: 'Form56 Not Requested',
      }
    ]
    }
  ];
}

export default taxBenefitColumns;