import { get, isEmpty } from 'lodash';
import { Section56Services } from './service';
import {
    SECTION_56_FETCH_ID,
    SECTION_56_LIST,
    FETCH_SECTION56_CLARIFICATION
} from '../../../constants/actionConstants';
import { isLoading } from '../../../constants/actionConstants';
import { toast } from "react-toastify";
import { is } from '@babel/types';


export const getClarificationRequest = (params) => async (dispatch, store) => {
    dispatch(isLoading(true));
    const resposne = await Section56Services.getClarificationRequest(params).
        catch(e => {
            return { data: {}, status: false }
        });
    if (resposne) {
        const { status, data } = resposne;
        if (status) {
            dispatch({
                type: FETCH_SECTION56_CLARIFICATION,
                payload: data
            });
            dispatch(isLoading(false));
        } else {
            dispatch(isLoading(false));
        }
        return { status, data };
    }
}

export const submitClarificationRequest = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let response = await Section56Services.postClarificationRequest(params).catch(e => {
        return { status: false, message: "" };
    });
    if (response) {
        dispatch(isLoading(false));
        return response;
    }
}

export function section56FetchIdAction(params) {
    return async function (dispatch) {
        dispatch(isLoading(true));
        let Section56FetchIdDetails = await Section56Services.section56FetchId(params);
        dispatch({
            type: SECTION_56_FETCH_ID,
            payload: Section56FetchIdDetails
        });
        dispatch(isLoading(false));
    }

}

export const updateSection56Action = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    await Section56Services.updateSection56(params).
        then((response) => {
            const { message, status } = response.data || [];
            if (status) {
                toast.success(message)
                return response;
            } else {
                toast.error(message)
                return false;
            }
        }).catch(e => {
            toast.error(e)
        })
        dispatch(isLoading(false))
}

export const fetchSection56List = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await Section56Services.fetchList(params).then((response) => {
        const { data = {}, status = false } = response;
        if (status) {
            dispatch({
                type: SECTION_56_LIST,
                payload: data,
            });
        }
        dispatch(isLoading(false));
        return { status };
    }).catch(e => {
        dispatch(isLoading(false))
    })
}

export const doDownloadSection56 = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await Section56Services.doDownloadSection56(params).then((response) => {
        const { data = "" } = response;
        dispatch(isLoading(false));
        return data;
    }).catch(e => {
        dispatch(isLoading(false))
        return "";
    })
}

export const getIncompleteHistory = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await Section56Services.getIncompleteHistory(params).then((response) => {
        const { data, status, message } = response;
        dispatch(isLoading(false));
        return { data, status };
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false };
    })
}

export const doUploadCSV = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await Section56Services.doUploadCSV(params).then((response) => {
        const { data, status, message } = response;
        if (status) {
            toast.success(message);
            dispatch(isLoading(false));
            return { data, status };
        } else {
            toast.error(message);
            dispatch(isLoading(false));
            return { data, status };
        }
    }).catch(e => {
        dispatch(isLoading(false))
        return { status: false };
    })
}