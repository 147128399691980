import React, { useState, useEffect } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getIAC80List, doBulkAction, getSectorOptions,getEpUsers, doDownloadIAC80List, getIncompleteHistory,doDownloadBeneficiaryList } from '../dependencies/action';
import iasListColumns from '../dependencies/columns/iacListColumns';
import { SearchByIAS80Options, SearchByIAS80OptionsForNonAdmin,SearchByIAS80OptionsForNonAdminIMBCHAIR, IAS80ApplicationStatus } from '../dependencies/constant';
import Table from '../../common/Table';
import SearchBox from '../../common/Search';
import Button from '../../common/Button';
import './style.scss';
import './style.css';
import ObservationCommunicated from '../observationCommunicated';
import popArchiveIcon from '../../../assests/images/pop-archive.png';
import popApprovedIcon from '../../../assests/images/pop-check.png';
import ActionModal from '../dependencies/modal/index';
import SectionTitle from '../../common/sectionTitle/sectionTitle';
import Download from '../../common/Download';
import AssignedApplication from '../../common/AssignedApplication';
import { uploadFileService } from '../../../dependencies/services/uploadFileService';
import Refresh from '../../common/Refresh';
import IncompleteHistory from '../dependencies/IncompleteHitory'
import FileUploadBtn from '../../common/FileUploadBtn';
import { isLoading } from '../../../constants/actionConstants';
import { FaEye, FaFilter } from 'react-icons/fa';
import { storage } from '../../../dependencies/store/storage';
import { ROLES } from '../../../constants/Roles';
import { toast } from "react-toastify";

const actionsList = [{
    type: 'OBSERVATION_COMMUNICATED',
    text: 'Observation Communicated',
    icon: popApprovedIcon
},
{
    type: 'REJECT',
    text: 'Reject',
    icon: popArchiveIcon
}]


const IAC80List = (props) => {
    const { getIncompleteHistory, doBulkAction, IAC80ListDetail: { data: list = [], recordsTotal = 0 } = {}, IAC80SectorList, doDownloadIAC80List,doDownloadBeneficiaryList } = props;
    const { getIAC80List, getSectorOptions,isLoading,getEpUsers } = props;
    const userRole = storage.getUserRole();
    const getUser = storage.getUser();
    const [showObservationCommunicated, setShowObservationCommunicated] = useState(false);
    const [sortColumn, setSortColumn] = useState('applicationDate');
    const [sortOrder, setSortOrder] = useState('DESC');
    const [pageSize, setpageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [exemptionId, setExemptionId] = useState("");
    const [fromId, setFromId] = useState("");
    const [actionType, setActionType] = useState('');
    const [checkedValue, setCheckedValue] = useState([]);
    const [query, setQuery] = useState({});
    const [modalValue, setModalValue] = useState(false);
    const [sAppId, setSAppId] = useState('');
    const [buttonValue, setButtonValue] = useState('');
    const [sectorOptions, setSectorOptions] = useState([]);
    const [epUserOption, setEpUsersOptions] = useState([])
    const [showIncompleteHistory, setShowIncompleteHistory] = useState(false);
    const [formId, setFormId] = useState('');
    const [fileUploadMessage, setFileUploadMessage] = useState('');

    useEffect(() => {
        getTableData();
    }, [sortColumn, sortOrder, pageSize, pageIndex, query, searchText])
    useEffect(() => {
        if (getSectorOptions) {
            getSectorOptions().then((response) => {
                const { data = [] } = response;
                let options = [];
                if (data && data instanceof Array) {
                    options = data.reduce((arr, option) => {
                        if (option['industryName'] !== null) {
                            var text = `${option['sectionName']} (${option['industryName']})` || "";
                        } else {
                            var text = `${option['sectionName']}` || "";
                        }
                        const value = option['id'] || "";
                        arr.push({ text, value });
                        return arr;
                    }, []);
                }
                setSectorOptions(options);
            });            
        }   
        if(getEpUsers) {
            getEpUsers().then(res => {
                
                if(res.data && res.data.EP1 && res.data.EP1 instanceof Array && res.data.EP2 && res.data.EP2 instanceof Array) {
                    let receivedData = [...res.data.EP1, ...res.data.EP2];
                    let transformed = []
                    receivedData.map((d) => {
                        transformed.push({text:d,value:d})
                    })
                    setEpUsersOptions(transformed)
                }   
                
            }) 
        }    
    }, [])

    useEffect(() => {
        if (exemptionId) {
            setShowObservationCommunicated(true)
        }
    }, [exemptionId]);

    const getTableData = () => {   
        if(userRole == ROLES.EP_1 || userRole == ROLES.EP_2) {
            
            if (getIAC80List) {
                getIAC80List({
                    fetchAllRecords: false,
                    sortColumn,
                    pageIndex,
                    pageSize,
                    sortOrder,
                    searchText,
                    query:{ ...query, 'isBeneficiary':false,"role" : "EP",'adminUserName' : getUser.user.name },
                }).then((data) => { });
            } 
        }else if(userRole == ROLES.IMB_MEMBER) {
            if (getIAC80List) {
                getIAC80List({
                    fetchAllRecords: false,
                    sortColumn,
                    pageIndex,
                    pageSize,
                    sortOrder,
                    searchText,
                    query:{ ...query, 'isBeneficiary':false,"role" : "IMB_MEMBER",'adminUserName' : getUser.user.name },
                }).then((data) => { });
            }
        } else if(userRole === ROLES.IMB_CHAIR) {
            
            if (getIAC80List) {
                getIAC80List({
                    fetchAllRecords: false,
                    sortColumn,
                    pageIndex,
                    pageSize,
                    sortOrder,
                    searchText,
                    query:{ ...query, role:'IMB_CHAIR',adminUserName : getUser.user.name },
                }).then((data) => { });
            } 
        }else {
            
            if (getIAC80List) {
                getIAC80List({
                    fetchAllRecords: false,
                    sortColumn,
                    pageIndex,
                    pageSize,
                    sortOrder,
                    searchText,
                    query:{ ...query },
                }).then((data) => { });
            } 
        }     
               
    }
    const callApi80Iac = () => {
        getTableData();
    }
    const onSortChange = (params) => {
        const { order = 'asc', dataField } = params;
        const searchOrder = order && order.toUpperCase();
        if (dataField !== '') {
            let sortField = dataField;
           if(dataField === 'applicationDate') {
               sortField = 'applicationDate';
           } else if (dataField === 'recognitionNumber') {
                sortField = 'recognitionData.startupEntity.recognitionNumber';
            } else if (dataField === 'recognitionDate') {
                sortField = 'recognitionData.startupEntity.recognitionDate';
            } else if (dataField === 'incorporationNumber') {
                sortField = 'recognitionData.incorporationNumber';
            } else if (dataField === 'entityName') {
                sortField = 'recognitionData.startupEntity.name';
            } else if (dataField === 'note') {
                sortField = 'recognitionData.additionalInformation.businessModel.note';
            } else if (dataField === 'replyReceivedDate') {
                sortField = 'replyReceivedDate';
            } else if (dataField === 'mobileNumber') {
                sortField = 'recognitionData.representative.mobile';
            } else if (dataField === 'emailId') {
                sortField = 'recognitionData.representative.email';
            } else if (dataField === 'startupReplyDate') {
                sortField = 'replyReceivedDate';
            }
            setSortColumn(sortField);
            setSortOrder(searchOrder);
        }
    }
    const handleOnPageChange = (params) => {
        const { page } = params;
        setPageIndex(page - 1);
    }

    const doSearch = (params) => {
        console.log(params)
        const { query = {}, searchText = "", searchBy = "" } = params;
        if (searchBy && searchBy === 'certificateDate') {
            setQuery(() => {
                return { ...query, 'searchType': 'CERTIFICATE_DATE' }
            });
        } else if (searchBy && searchBy === 'createdDate') {
            setQuery(() => {
                return { ...query, 'searchType': 'CREATED_DATE' }
            });
        } else if(searchBy && searchBy === "User") {
            setQuery(() => {
                return { ...query, 'role': 'EP', 'isBeneficiary' : false, }
            });
        } else {
            setQuery(query);
        }
        setSearchText(searchText);
        setPageIndex(0)
    }
    const onCloseObservationPopup = () => {
        setExemptionId('')
        setShowObservationCommunicated(x => !x);

    }

    const clickOnAction = (id) => {
        if (id) {
            setExemptionId(id)
        }
    }


    const onCloseIncompeletePopup = () => {
        setFormId('')
        setShowIncompleteHistory(x => !x);

    }

    const clickOnActionIncomplete = (id) => {
        if (id) {
            setFormId(id)
        }
    }

    useEffect(() => {
        if (formId) {
            setShowIncompleteHistory(true)
        }
    }, [formId]);
    const onSelectRows = (selectedRows) => {
        setCheckedValue(selectedRows)

    }
    const doConfirmation = (actionType) => {
        var r = window.confirm("Do you want to proceed?");
        if (r == true) {
            setActionType(actionType)
        } else {
        }
    }

    const actionHandler = (actionType) => {
        doConfirmation(actionType)
    }

    useEffect(() => {
        if (doBulkAction && checkedValue && checkedValue.length > 0) {
            doBulkAction({
                ids: checkedValue,
                status: actionType
            }).then((data) => {
                const { status } = data;
                if (status) {
                    setCheckedValue([]);
                    setActionType("")
                    getTableData();
                }
            }).catch(e => {

            })
        }
    }, [actionType])
    const ApproveRejectAction = (id) => {
        if (id) {
            setSAppId(id);

            handleclick()
        }
    }

    const toggleActionModal = () => {
        setModalValue(!modalValue);

    }
    const handleclick = () => {
        toggleActionModal();
        setButtonValue('approvedReject');
    }

    const downloadCSV = () => {
        if (doDownloadIAC80List) {
            if(userRole == ROLES.EP_1 || userRole == ROLES.EP_2) {
                return doDownloadIAC80List({
                    fetchAllRecords: true,
                    sortColumn,
                    pageIndex,
                    pageSize,
                    sortOrder,
                    searchText,
                    query:{ ...query, isBeneficiary:false,"role" : "EP",'adminUserName' : getUser.user.name },
                }).then((data) => {
                    return data;
                })
            }else if(userRole == ROLES.IMB_MEMBER){
                return doDownloadIAC80List({
                    fetchAllRecords: true,
                    sortColumn,
                    pageIndex,
                    pageSize,
                    sortOrder,
                    searchText,
                    query:{ ...query, isBeneficiary:false,"role" : "IMB_MEMBER",'adminUserName' : getUser.user.name },
                }).then((data) => {
                    return data;
                })
                
            } else if(userRole === ROLES.IMB_CHAIR) {
                return doDownloadIAC80List({
                    fetchAllRecords: true,
                    sortColumn,
                    pageIndex,
                    pageSize,
                    sortOrder,
                    searchText,
                    query:{ ...query, role:'IMB_CHAIR',adminUserName : getUser.user.name },
                }).then((data) => {
                    return data;
                })
            }else {
                return doDownloadIAC80List({
                    fetchAllRecords: true,
                    sortColumn,
                    pageIndex,
                    pageSize,
                    sortOrder,
                    searchText,
                    query,
                }).then((data) => {
                    return data;
                }) 
            } 
            
        }
    }
    

    const DownloadBeneficiaryList = () => {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        if(doDownloadBeneficiaryList) {
            return doDownloadBeneficiaryList({
                fetchAllRecords: true,
                sortColumn,
                pageIndex,
                pageSize,
                sortOrder,
                searchText,
                query,
            }).then((data) => {
                if (data) {
                    let newdata = 'id,nativeId,diipOrCin,reason,snumber,\n';
                    data.map((d) => {
                        for (const [key, value] of Object.entries(d)) {
                            if(key == 'snumber') {
                                newdata += value+','
                            }
                            if(key == 'id') {
                                newdata += value+','
                            }
                            if(key == 'diipOrCin') {
                                newdata += value+','
                            }
                            if(key == 'nativeId') {
                                newdata += value+','
                            }
                            if(key == 'reason') {
                                newdata += value+','
                            }                            
                        }
                        newdata += '\n'                        
                    })
                    let _fileName = `${'beneficery_list'}.csv`;
                    var blob = new Blob([newdata], { type: "text/csv;charset=utf-8" });
                    const url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = _fileName;
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            });
        }
    }

    const onUploadFile = (file) => {
        isLoading(true)
        const data = new FormData();
        data.append('file', file)
        uploadFileService.uploadFileBtn(data).then((data) => {
            toast.success('beneficiary list has been uploaded successfully.')
            isLoading(false)
        }, (error) => {
            toast.error(error)
            isLoading(false)
        });
    }
    const setFileUploadSuccessMessage = (message) => {
        setFileUploadMessage(message)
    }
    function refreshStatupList() {
        if(userRole == ROLES.EP_1 || userRole == ROLES.EP_2) {
            setQuery(() => {
                return { ...query, isBeneficiary:false,"role" : "EP",'adminUserName' : getUser.user.name }
            });
            
        }else if(userRole == ROLES.IMB_MEMBER){
            setQuery(() => {
                return { ...query, isBeneficiary:false,"role" : "IMB_MEMBER",'adminUserName' : getUser.user.name }
            });
        } else if(userRole === ROLES.IMB_CHAIR) {
            setQuery(() => {
                return { ...query, role:'IMB_CHAIR',adminUserName : getUser.user.name }
            });
            
        }else {
            setQuery(() => {
                return { ...query, isBeneficiary:true  }
            });
            
        } 
    }
    function AssignedApplicationList() {
        setQuery(() => {
            return { ...query, isBeneficiary:false,role:userRole,adminUserName:getUser.user.name }
        });
        setSortOrder("ASC");
        // if (getIAC80List) {
        //     getIAC80List({
        //         fetchAllRecords: false,
        //         sortColumn,
        //         pageIndex,
        //         pageSize,
        //         sortOrder,
        //         searchText,
        //         query:{  isBeneficiary:false,role:userRole,adminUserName:getUser.user.name  }
        //     }).then((data) => { });
        // } 
    }
    function onClickPending() {
        
        if(userRole === ROLES.EP_1 || userRole === ROLES.EP_2) {
            setQuery(() => {
                return { ...query, "statusEP":"PENDING","role" : "EP","isBeneficiary":false,"adminUserName" : getUser.user.name }
            });
        }else if(userRole === ROLES.IMB_MEMBER) {
            setQuery(() => {
                return { ...query, "statusImbMember":"PENDING", "role" : "IMB_MEMBER","isBeneficiary":false,"adminUserName" : getUser.user.name }
            });
        }else if(userRole === ROLES.IMB_CHAIR) {
            setQuery(() => {
                return { ...query, "statusImbChair":"PENDING",role:'IMB_CHAIR',adminUserName : getUser.user.name }
            });
        }else {
            setQuery(() => {
                return { ...query, "status":"PENDING","isBeneficiary":true }
            });
        }
        setPageIndex(0)
       
    }
    function searchByOptionHandle() {
        if(userRole === ROLES.EP_1 || userRole === ROLES.EP_2) {
            return SearchByIAS80OptionsForNonAdmin(sectorOptions,'statusEP','assignDate')
        }else if(userRole === ROLES.IMB_MEMBER) {
            return SearchByIAS80OptionsForNonAdmin(sectorOptions,'statusImbMember','assignDate')
        }else if(userRole === ROLES.IMB_CHAIR) {
            return SearchByIAS80OptionsForNonAdminIMBCHAIR(sectorOptions,'statusImbChair','assignDate')
        }else {
            return SearchByIAS80Options(sectorOptions,epUserOption)
        }   
    }
    

    return (
        <div className="iac80-list-wrapper">
            <div className='section-ttile-pending-wrapp'>
                <SectionTitle breadCrumb title="Applications for 80IAC Exemption">Applications for 80IAC Exemption </SectionTitle>
                
            </div>
            
            <div className="aps-extn-form-filter">
                <div className='form-row'>
                    <SearchBox
                        searchByOptions={searchByOptionHandle()}
                        doSearch={doSearch}
                    />
                </div>
              <div className="pending-ud-ben-view-wrapp">
                    <Button type="button" onClick={() => onClickPending()} className={'bttn pend-btn'} text="Pending" icon={<FaFilter />} />                            
                
                    {
                        ((userRole == ROLES.ADMIN || userRole == ROLES.SUPER_ADMIN)?(
                            <>
                                <FileUploadBtn 
                                    onUploadFile={onUploadFile}
                                    allowedFiles={[".csv"]}
                                    successMessage={fileUploadMessage}
                                    setSuccessMessage={setFileUploadSuccessMessage}
                                />
                                <a href='#' onClick={DownloadBeneficiaryList} className='btn btn-info'><i><FaEye></FaEye></i></a>
                            </>
                        ):(''))
                    }
                </div>
            </div>
            {
                userRole === ROLES.ADMIN || userRole === ROLES.SUPER_ADMIN ? (
                    <AssignedApplication
                        onClickAssign={AssignedApplicationList}
                        className="bttn-link"
                    />
                ):''
            }
            
            <Download
                onClickDownload={downloadCSV}
                fileName={'IAC80Detail'}
                className="bttn-link"
            />
            <Refresh
                onClickRefresh={refreshStatupList}
                className=""
            />
            <Table
                dataTable={list}
                columns={iasListColumns({
                    clickOnActionIncomplete,
                    clickOnAction,
                    ApproveRejectAction,
                    pageNo: pageIndex,
                    size: pageSize
                })}
                keyField='id'
                // selectRowEnabled={true}
                // selectMode="checkbox"
                // selected={checkedValue}
                onSelectRows={onSelectRows}
                onSortChange={onSortChange}
                totalSize={recordsTotal}
                // rowStyle={rowStyle2}
                sizePerPage={pageSize}
                onPageChange={handleOnPageChange}
                actionHandler={actionHandler}
                tableActions={actionsList}
            />
            <div>

                {
                    <IncompleteHistory
                        isOpen={showIncompleteHistory}
                        onClose={onCloseIncompeletePopup}
                        exemptionId={formId}
                    />
                }
                {
                    <ObservationCommunicated
                        isOpen={showObservationCommunicated}
                        onClose={onCloseObservationPopup}
                        exemptionId={exemptionId}
                    />
                }
                {modalValue ?
                    <ActionModal
                        modalValue={modalValue}
                        toggleActionModal={toggleActionModal}
                        actionValue={buttonValue}
                        callApi80Iac={callApi80Iac}
                        id={sAppId
                            ?
                            sAppId : ''}
                    /> : null
                }

            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        IAC80ListDetail: state.Applicatio80IACReducer.IAC80ListDetail,
        IAC80SectorList: state.Applicatio80IACReducer.IAC80SectorList
    }
}

export default connect(mapStateToProps, { getIncompleteHistory, getIAC80List, doBulkAction, getSectorOptions,getEpUsers, doDownloadIAC80List,doDownloadBeneficiaryList,isLoading })(withRouter(IAC80List));
