import React from 'react';
import moment from 'moment';


export default [
    {
        dataField: '',
        text: 'Sr.No',
        formatter: (cell, row, rowIndex) => {
            return rowIndex + 1
        }
    }, {
        dataField: 'comment',
        text: 'Observation',
    }, {
        dataField: 'createdDate',
        text: 'Date',
        formatter: (cell) => {
            if (cell) {
                return moment(cell).format('DD/MM/YYYY')

            }
        }
    }, {
        dataField: 'createdBy',
        text: 'CreatedBy',
        formatter: (cell) => {
            if (cell && cell.username) {
                return cell.username;
            }
        }
    }
];
