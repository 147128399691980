import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { updateSection56Action } from '../../dependencies/action';
import { fetchRejectionList } from '../../../../dependencies/services/getRejectList';
import GlobalDropDown from '../../../common/GlobalDropDown/index'
import TextareaField from '../../../common/TextareaField'
import FormValidator from '../../dependencies/FromValidator';
import ApprovedRejectDropDown from '../../../../dependencies/utils/json/section56/approvedRejectSection56.json'

const ActionModal = (props) => {

    const {
        buttonLabel,
        className
    } = props;
    const refreshApi80Iac = props.details;
    const [incompleteStatus, setIncompleteStatus] = useState([]);
    const [approvedRejectStatus, setApprovedRejectStatus] = useState('')
    const [other, setOther] = useState('');
    const [showInputFiled, setShowInputFiled] = useState(false);
    const [showRejectDropdown, setshowRejectDropdown] = useState(false);
    const [rejectStatus, setRejectStatus] = useState('')
    const [error, setError] = useState({});
    const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] = useState(false);
    const [incompleteStatusTitle,setIncompleteStatusTitle] = useState([])
    const [rejectStatusTitle,setRejectStatusTitle] = useState('');

    useEffect(() => {
        props.fetchRejectionList({
            enablerType: "STARTUP",
            startupRejectType: "REJECT_SECTION_56",
        })
    }, [])

    if (props.getRejectList) {
        var outputSrtUpAppList = props.getRejectList.map(s => ({ text: s.reason, value: s.id }));
    }
    const validateInput = () => {

        let dropDownValidate
        if (incompleteStatus && incompleteStatus !== 'select') {
            dropDownValidate = incompleteStatus
        }if (approvedRejectStatus && approvedRejectStatus !== 'select') {
          
              dropDownValidate = approvedRejectStatus;
        }
        const result = incompleteStatusTitle.indexOf('Others- If any other, please specify. A field to enter other reason with 1000 character limit.');
      

        let otherData = {};
        if (result != -1 || (rejectStatusTitle == 'Others- If any other, please specify. A field to enter other reason with 1000 character limit.')) {
            otherData = { other }
        }

        let approveRejectValidate;
        if( approvedRejectStatus === 'REJECT'){
            approveRejectValidate = '';
            // approveRejectValidate = {};
            if (rejectStatus && rejectStatus !== 'select') {
                approveRejectValidate = rejectStatus;
          }
        }
        const formStatus = FormValidator.validateDropDownValue({
            dropDownValidate,
            approveRejectValidate,
            ...otherData
        });
        const errors = {
            dropDownValidate: formStatus.dropDownValidate,
            approveRejectValidate: formStatus.approveRejectValidate,
            other: formStatus.other,
        };
        setError(errors);
        return !formStatus.hasErrors;
    };

    const onBlur = (e, name) => {
        validateInput();
    }
    const refreshApi = () => props.callApi();

    const submitFormHandler = async (e) => {
        e.preventDefault();
        if (!validateInput()) {
            setHasInvalidSubmitOccurred(true)
        } else {
            let sendRes = {}
            let status = props.actionValue
            if (approvedRejectStatus) {
                status = approvedRejectStatus
                if (approvedRejectStatus === 'REJECT'){
                    const arrayToObject = {id: rejectStatus}
                    sendRes = {
                        rejectReason : arrayToObject,
                        rejectDescription: other
                    }
                }


            } else if (incompleteStatus) {
                const arrayToObject = incompleteStatus.map((item) => { return { id: item } })
                sendRes = {
                    incompleteReasons: arrayToObject,
                    incompleteDescription: other
                }

            }
            let responce = await props.updateSection56Action({
                id: props.id,
                status: status,
                ...sendRes
            })
            await toggle();
            await refreshApi();
            if(responce){
                props.history.push('/section56/list')
            }
            
           
        }
    }

    const handleInput = (e, name) => {
        let value = e.target.value;
        if (name === 'approvedRejectValue') {
            setApprovedRejectStatus(e.target.value)
            setShowInputFiled(false)
            setRejectStatus('');
            setRejectStatusTitle('');
            if(e.target.value === 'APPROVE'){
                setshowRejectDropdown(false)
            }
            // else if (e.target.value === 'REJECT') {
            //     setshowRejectDropdown(true)

            // }
            else{
                setshowRejectDropdown(false)
            }

        } else if(name === 'rejectDropDownValue'){
                let val = '';
                let titles = '';
                let options = e.target.options;
                options = Array.from(options);
                let otherSelected = false;
                for (let i = 0; i < options.length; i++) {
                    if (options[i].selected) {
                        val = options[i].value;
                        titles = options[i].title;
                        if(options[i].title.indexOf('Others- If any other')>-1 && otherSelected === false) {
                            otherSelected = true;
                        }
                    }
                }
                
                setRejectStatus(val);
                setRejectStatusTitle(titles);
                
                if (otherSelected) {
                    setShowInputFiled(true)
                } else {
                    setShowInputFiled(false)
                }
        }
        else if (name === 'incompleteDropDownValue') {
            const val = [];
            const titles = [];
            let options = e.target.options;
            options = Array.from(options);
            let otherSelected = false;
            for (let i = 0, l = options.length; i < l; i++) {
                if (options[i].selected) {
                    val.push(options[i].value);
                    titles.push(options[i].title);
                    if(options[i].title.indexOf('Others- If any other') > -1 && otherSelected === false){
                        otherSelected = true;
                    }
                }
            }
            setIncompleteStatusTitle(titles)
            setIncompleteStatus(val)
            if (otherSelected) {
                setShowInputFiled(true)
            } else {
                setShowInputFiled(false)
            }
        } else if (name === 'other') {
            setOther(e.target.value)
        }
    }
    const toggle = () => props.toggleActionModal();
    //const refreshApi = () => props.callApi();
    const actionPerform = async () => {

        if(props.actionValue ==  'Archive'){
            await props.updateSection56Action({
                id: props.id,
                status: 'ARCHIVE'
            })
            
        }else{
            await props.updateSection56Action({
                id: props.id,
                status: props.actionValue
            })
        }

        await toggle()
        await refreshApi();
        // props.history.push('/section56/list')
    }

    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            <Modal isOpen={props.modalValue} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>{
                    props.actionValue === 'approvedReject' ? 'Approve'
                        : props.actionValue
                }</ModalHeader>
                <ModalBody>

                    {props.actionValue === 'INCOMPLETE'
                        ? (
                            <form className="filter-form" onSubmit={submitFormHandler}>

                                <GlobalDropDown
                                    required
                                    label='Reason'
                                    selectTagStyle="form-control dropdown-media"
                                    multiple
                                    onChange={handleInput}
                                    options={outputSrtUpAppList}
                                    name={'incompleteDropDownValue'}
                                    defaultOptionDisable={true}
                                    value={incompleteStatus}
                                    error={error.dropDownValidate}
                                    onBlur={onBlur}
                                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                />
                                {showInputFiled &&
                                    <TextareaField

                                    required
                                    type="text"
                                    name="other"
                                    label="Other"
                                    value={other}
                                    onChange={handleInput}
                                    error={error.other}
                                    containerClass={"form-group"}
                                    onBlur={onBlur}
                                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                />
                                }
                                <div className="button-wrap">
                                    <button type='submit' className='bttn'>Submit</button>
                                </div>
                            </form>
                        ) : (props.actionValue === 'approvedReject'
                            ? (<form className="filter-form" onSubmit={submitFormHandler}>

                                <GlobalDropDown
                                    required
                                    label='Status'
                                    multiple = {false}
                                    containerStyle="form-group "
                                    selectTagStyle="form-control dropdown-media"
                                    onChange={handleInput}
                                    options={ApprovedRejectDropDown}
                                    value={approvedRejectStatus}
                                    name={'approvedRejectValue'}
                                    error={error.dropDownValidate}
                                    onBlur={onBlur}
                                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                />
                                {showRejectDropdown &&
                                 <GlobalDropDown
                                    required
                                    label='Reason'
                                    multiple={false}
                                    containerStyle="form-group "
                                    selectTagStyle="form-control dropdown-media"
                                    onChange={handleInput}
                                    options={outputSrtUpAppList}
                                    name={'rejectDropDownValue'}
                                    defaultOptionDisable={false}
                                    value={rejectStatus}
                                    error={error.approveRejectValidate}
                                    onBlur={onBlur}
                                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                />
                                }


                                {showInputFiled &&
                                    <TextareaField

                                    required
                                    type="text"
                                    name="other"
                                    label="Other"
                                    value={other}
                                    onChange={handleInput}
                                    error={error.other}
                                    containerClass={"form-group"}
                                    onBlur={onBlur}
                                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                />

                                }
                                <div className="button-wrap">
                                    <button type='submit' className='bttn'>Submit</button>
                                </div>
                            </form>)
                            : 'Do you want to proceed?')

                    }
                </ModalBody>

                {props.actionValue !== 'INCOMPLETE' && props.actionValue !== 'approvedReject' ?
                    (
                        <ModalFooter>
                            {props.actionValue !== 'INCOMPLETE'
                                ? <Button color="primary" onClick={actionPerform}>Ok</Button>
                                : ''}

                        </ModalFooter>
                    )
                    :
                    ''
                }
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {

    return {
        getRejectList: state.getRejectListReducer.rejectionList
    }
}
export default connect(mapStateToProps, { updateSection56Action, fetchRejectionList })(withRouter(ActionModal))
