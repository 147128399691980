import api from '../../../dependencies/utils/Api';
import {
    FETCH_SECTION_ID,
    UPDATE_SECTION56_URL,
    FETCH_SECTION_56,
    FETCH_56_CLARIFICATION_REQUEST,
    SAVE_CLARIFICATION_REQUEST,
    DOWNLOAD_SECTION_56_LIST,
    FETCH_SECTION56_INCOMPLETE_HISTORY,
    UPLOAD_ACTION_IN_BULK
} from "../../../constants/ApplicationUrl";

import Section56Transformer from './transformer/transformer'

export const Section56Services = {

    getClarificationRequest({ id }) {
        return api.GET(`${FETCH_56_CLARIFICATION_REQUEST}/${id}`).
            then((response) => {
                const { data: { data = {}, status = false } = {}, } = response || {};
                return { data, status }
            });
    },

    postClarificationRequest(params) {
        return api.POST(`${SAVE_CLARIFICATION_REQUEST}`, params).
            then((response) => {
                const { data: { status, message } = {} } = response;
                return { status, message };
            })
    },

    section56FetchId(id) {
        return api.GET(`${FETCH_SECTION_ID}/${id}`).
            then((response) => {
                let returnData = Section56Transformer(response.data);

                return Promise.resolve(returnData);
            }).catch(e => {
                return Promise.reject(e);
            });
    },


    updateSection56(params) {

        return api.POST(UPDATE_SECTION56_URL, params).
            then((response) => {
                const { data = {} } = response;
                return { data };
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    fetchList(params) {
        return api.POST(FETCH_SECTION_56, params).
            then((response) => {
                const { data = {}, status } = response;
                return { data, status };
            });
    },
    doDownloadSection56(params) {
        return api.DOWNLOADDATA(DOWNLOAD_SECTION_56_LIST, params).
            then((response) => {
                return response;
            });
    },
    getIncompleteHistory(id) {
        return api.GET(`${FETCH_SECTION56_INCOMPLETE_HISTORY}/${id}`).
            then((response) => {
                const { data: { data, status, message } = {} } = response;
                return { data, status, message };
            });
    },
    doUploadCSV(params) {
        return api.POSTDATA(UPLOAD_ACTION_IN_BULK, params).
            then((response) => {
                const { data: { status, data, message } } = response;
                return { data, message, status };
            });
    }
}