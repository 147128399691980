import { get, isEmpty } from 'lodash';
import { ReportServices } from './service';
import {
    FETCH_TOTAL_REPORT,
    FETCH_ENTITY_TYPE_APPLICATION_REPORT,
    FETCH_RECOGNISED_REPORT,
    FETCH_TAX_BENEFIT_REPORT
} from '../../../constants/actionConstants';
import { isLoading } from '../../../constants/actionConstants';
import { toast } from "react-toastify";



export const getFetchEntityTypeApplicationReport = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await ReportServices.fetchEntityTypeApplicationReport(params).then((response) => {
        const { data = {}, status = false } = response;
        if (status) {
            dispatch({
                type: FETCH_ENTITY_TYPE_APPLICATION_REPORT,
                payload: data,
            });
        }
        dispatch(isLoading(false));
        return { status };
    }).catch(e => {
        dispatch(isLoading(false))
    })
}

export const getFetchRecognisedReport = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await ReportServices.fetchRecognisedReport(params).then((response) => {
        const { data = {}, status = false } = response;
        if (status) {
            dispatch({
                type: FETCH_RECOGNISED_REPORT,
                payload: data,
            });
        }
        dispatch(isLoading(false));
        return { status };
    }).catch(e => {
        dispatch(isLoading(false))
    })
}


export const getFetchTaxBenefitReport = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await ReportServices.fetchTaxBenefitReport(params).then((response) => {
        const { data = {}, status = false } = response;
        if (status) {
            dispatch({
                type: FETCH_TAX_BENEFIT_REPORT,
                payload: data,
            });
        }
        dispatch(isLoading(false));
        return { status };
    }).catch(e => {
        dispatch(isLoading(false))
    })
}

export const getFetchTotalReport = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    return await ReportServices.fetchTotalReport(params).then((response) => {
        const { data = {}, status = false } = response;
        if (status) {
            dispatch({
                type: FETCH_TOTAL_REPORT,
                payload: data,
            });
        }
        dispatch(isLoading(false));
        return { status };
    }).catch(e => {
        dispatch(isLoading(false))
    })
}

export const downloadTotalReport = () => async (dispatch) => {
    dispatch(isLoading(true))
    return await ReportServices.downloadTotalReport().then((response) => {
        const { data = "" } = response;
        dispatch(isLoading(false));
        return data;
    }).catch(e => {
        dispatch(isLoading(false))
        return "";
    })
}

export const downloadEntityTypeApplicationReport = () => async (dispatch) => {
    dispatch(isLoading(true))
    return await ReportServices.downloadEntityTypeApplicationReport().then((response) => {
        const { data = "" } = response;
        dispatch(isLoading(false));
        return data;
    }).catch(e => {
        dispatch(isLoading(false))
        return "";
    })
}

export const downloadRecognisedReport = () => async (dispatch) => {
    dispatch(isLoading(true))
    return await ReportServices.downloadRecognisedReport().then((response) => {
        const { data = "" } = response;
        dispatch(isLoading(false));
        return data;
    }).catch(e => {
        dispatch(isLoading(false))
        return "";
    })
}

export const downloadTaxBenefitReport = () => async (dispatch) => {
    dispatch(isLoading(true))
    return await ReportServices.downloadTaxBenefitReport().then((response) => {
        const { data = "" } = response;
        dispatch(isLoading(false));
        return data;
    }).catch(e => {
        dispatch(isLoading(false))
        return "";
    })
}