import { combineReducers } from 'redux';
import {
    DASHBOARD_COUNT_DETAILS,
    DASHBOARD_DAILY_COUNT_DETAILS,
    DASHBOARD_AUDIT_TRAIL,
    DASHBORD_DAILY_COUNT_NEW,
    DASHBORD_COUNT_NEW

} from '../../../constants/actionConstants';

const dashboardCountDetail = (state = {}, action) => {
    switch (action.type) {
        case DASHBOARD_COUNT_DETAILS:
            return { ...action.payload }
        default:
            return state;
    }
}
const dashboardDailyCountDetail = (state = {}, action) => {
    switch (action.type) {
        case DASHBOARD_DAILY_COUNT_DETAILS:
            return { ...action.payload }
        default:
            return state;
    }
}

const dashboardAuditTrail = (state = [], action) => {
    switch (action.type) {
        case DASHBOARD_AUDIT_TRAIL:
            return [action.payload]
        default:
            return state;
    }
}
const dashoardCountNew = (state = {}, action) => {
    switch (action.type) {
        case DASHBORD_COUNT_NEW:
            return { ...action.payload }
        default:
            return state;
    }
}
const dashoardDailyCountNew = (state = {}, action) => {
    switch (action.type) {
        case DASHBORD_DAILY_COUNT_NEW:
            return { ...action.payload }
        default:
            return state;
    }
}

const DashboardReducer = combineReducers({
    dashboardCountDetail,
    dashboardDailyCountDetail,
    dashboardAuditTrail,
    dashoardCountNew,
    dashoardDailyCountNew
})

export default DashboardReducer;