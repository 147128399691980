import React, { useEffect, useState } from 'react';
import SectionTitle from '../../../components/common/sectionTitle/sectionTitle';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
// import { getClarificationRequest, submitClarificationObservation } from '../dependencies/action';
import Modal from '../../common/Modal';
import { toast } from 'react-toastify';
import { ROLES } from '../../../constants/Roles';
import { storage } from '../../../dependencies/store/storage';
import { getIncompleteHistory,getAuditTrail } from '../../startupApplication/dependencies/action';
import incompleteTable from './incompleteTable'
import auditTrailTable from './auditTrailTable';

import Table from '../../common/Table'
import moment from 'moment';

const IncompleteHistory = (props) => {

    
    const {
        exemptionId,
        getIncompleteHistory,
        getAuditTrail,
        incompleteHistoryList,
        auditTrailList,
        submitClarificationObservation,
        onClose,
    } = props;

    useEffect(() => {
        
        if (exemptionId) {
            getIncompleteHistory(exemptionId)
            getAuditTrail(exemptionId)
        }

    }, []);
 
    return (
        <div>
            <SectionTitle>Startup India : Incomplete Reasons History</SectionTitle>
            <Table
                dataTable={incompleteHistoryList ? incompleteHistoryList : ''}
                columns={incompleteTable}
                keyField='financialYear'
                cssClass='startupLogs'
            />
            <SectionTitle>Startup India : Edit History</SectionTitle>
            <div class="table-container">
            <div class="">
                <div>
                    <div class="react-bootstrap-table table-responsive">
                        <table class="table table-striped table-hover table-bordered table startupLogs">
                        <thead>
                            <tr>
                                <th tabindex="0">Sr.No</th>
                                <th tabindex="0">Date</th>
                                <th tabindex="0">Action</th>
                                <th tabindex="0">Action taken by</th>
                                <th tabindex="0">Edited fields</th>
                                <th tabindex="0">Remark</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                auditTrailList.length > 0? auditTrailList.map((d,index) =>{
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{moment(d.actionTakenDate).format('DD-MM-YYYY')}</td>
                                            <td>{d.auditAction}</td>
                                            <td>{d.actionTakenBy}</td>
                                            <td>
                                                <ul style={{listStyle: 'none', display: 'flex', width: '100%', flexWrap: 'wrap', padding: '0'}}>
                                                    {
                                                        d.editedField && d.editedField.length > 0 ? d.editedField.map(d => {
                                                            return (
                                                                <li style={{backgroundColor: '#8b8b8b', color: '#fff', padding: '5px', borderRadius: '30px', marginBottom: '5px',marginRight: '4px', marginLeft: '4px', paddingTop:'1px'}}>{d}</li>
                                                            )
                                                        }): d.editedField
                                                    }
                                                </ul>
                                            </td>
                                            <td>{d.remark}</td>
                                        </tr>
                                    )
                                }): (
                                    <tr><td data-toggle="collapse" colspan="6" class="react-bs-table-no-data"><div>No Record found.</div></td></tr>
                                )
                            }
                        </tbody>
                        </table>
                    </div>         
                </div>
            </div>
            </div>
            
        </div>
        

    )
}
const mapStateToProps = (state) => {
  
    return {
        incompleteHistoryList: state.startupApplicationForm.incompleteHistoryList,
        auditTrailList: state.startupApplicationForm.AuditTrailList
    }
}
export default connect(mapStateToProps, { getIncompleteHistory,getAuditTrail })(withRouter(Modal(IncompleteHistory)))
