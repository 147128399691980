import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty'
import connect from 'react-redux/es/connect/connect';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { updateCancelCertificateAction } from '../action';
import { fetchReasonsList } from '../../../../dependencies/services/getRejectList';
import GlobalDropDown from '../../../common/GlobalDropDown/index'
import TextareaField from '../../../common/TextareaField'
import FormValidator from '../FromValidator';
import ApprovedRejectDropDown from '../../../../dependencies/utils/json/cancellation/approvedReject.json'

const ActionModal = (props) => {
    const defaultFormData = {
        status: "",
        reason: "",
        other: null
    }
    const {
        className,
        fetchReasonsList,
        id: applicationId,
        callApi,
        cancellationList
    } = props;

    const [formData, setFormData] = useState(defaultFormData);
    const [fetchRejectListEnable, setFetchRejectListEnable] = useState(false);
    const [reasons, setReasons] = useState([]);
    const [reasonOptions, setReasonOptions] = useState([]);
    const [error, setError] = useState({});
    const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] = useState(false);
    const [statusOptions, setStatusOptions] = useState([]);

    useEffect(() => {
        if (ApprovedRejectDropDown && ApprovedRejectDropDown.length > 0) {
            const row = cancellationList && cancellationList instanceof Array && cancellationList.filter(x => x.applicationId === applicationId)[0] || {};
            if (row && !isEmpty(row)) {
                let arr = [...ApprovedRejectDropDown];
                arr = arr.filter(x => {
                    if (x.type === "RECOGNITION" && row.recognitionCertificateStatus === "RECOGNISED") {
                        return true
                    } else if (x.type === "EIGHTY_IAC" && row.recognitionCertificateStatus === "RECOGNISED" && row.eligibilityCertificateStatus === 'APPROVED') {
                        return true
                    } else if (x.type === "SEC_FIFTY_SIX" && row.recognitionCertificateStatus === "RECOGNISED" && row.section56CommonStatus === 'APPROVE') {
                        return true
                    }
                })
                setStatusOptions(arr)
            }
        }
    }, []);
    useEffect(() => {
        if (fetchRejectListEnable) {
            if (formData && formData.status && formData.status !== "EIGHTY_IAC") {
                fetchReasonsList({
                    enablerType: "STARTUP",
                    startupRejectType: formData.status,
                }).then((response) => {
                    const { data, status } = response;
                    if (status) {
                        const options = data && data.map(x => ({ text: x.reason, value: x.id }));
                        setReasonOptions(options);
                        setFetchRejectListEnable(false);
                        setReasons(data);
                    }
                })
            } else {
                setReasonOptions([]);
                setFetchRejectListEnable(false);
                setReasons([]);
            }
        }
    }, [fetchRejectListEnable])

    const reasonOptionItem = reasonOptions && reasonOptions.filter(x => {
        return (x.value === formData.reason && x.text.indexOf('Others- If any other') > -1)
    })[0] || {};
    const isOtherOption = !isEmpty(reasonOptionItem) || formData.status === 'EIGHTY_IAC' || false;


    const validateInput = () => {

        const {
            status,
            reason,
            other
        } = formData;

        const formStatus = FormValidator.validateCancellationAction({
            status,
            reason,
            other,
            isOtherOption
        });

        const errors = {
            status: formStatus.status,
            reason: formStatus.reason,
            other: formStatus.other,
        };
        setError(errors);
        return !formStatus.hasErrors;
    };

    const onBlur = (e, name) => {
        validateInput();
    }

    const submitFormHandler = async (e) => {
        e.preventDefault();
        if (!validateInput()) {
            setHasInvalidSubmitOccurred(true)
        } else {
            const { updateCancelCertificateAction } = props;
            const { reason, status, other: rejectDescription } = formData;
            const rejectReason = reasons && reasons.filter(x => x.id === formData.reason)[0] || null;
            const certificateItem = ApprovedRejectDropDown.filter(x => x.value === status)[0] || {};
            const certificateType = certificateItem && certificateItem.type;
            if (updateCancelCertificateAction && certificateType) {
                updateCancelCertificateAction({
                    rejectReason,
                    applicationId,
                    rejectDescription,
                    certificateType,
                }).then((response) => {
                    const { status } = response;
                    if (status) {
                        toggle();
                        callApi();
                        setHasInvalidSubmitOccurred(false);
                    }
                })
            }
        }
    }

    const handleInput = (e, name) => {
        let value = e.target && e.target.value || "";
        setFormData(d => {
            return { ...d, [name]: value }
        });
        if (name === "status") {
            setFetchRejectListEnable(true)
        }
    }
    const toggle = () => props.toggleActionModal();

    return (
        <div>
            <Modal isOpen={props.modalValue} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>{
                    props.actionValue === 'approvedReject' ? 'Reject'
                        : props.actionValue
                }</ModalHeader>
                <ModalBody>

                    <form className="filter-form" onSubmit={submitFormHandler}>
                        <GlobalDropDown
                            required
                            label='Status'
                            containerStyle="form-group "
                            selectTagStyle="form-control dropdown-media"
                            onChange={handleInput}
                            options={statusOptions}
                            value={formData.status}
                            name={'status'}
                            error={error.status}
                            onBlur={onBlur}
                            hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                        />
                        {
                            reasonOptions && reasonOptions.length > 0 &&
                            <GlobalDropDown
                                required
                                label='Reason'
                                containerStyle="form-group "
                                selectTagStyle="form-control dropdown-media"
                                onChange={handleInput}
                                options={reasonOptions}
                                name={'reason'}
                                defaultOptionDisable={false}
                                value={formData.reason}
                                error={error.reason}
                                onBlur={onBlur}
                                hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                            />
                        }

                        {
                            (isOtherOption ||
                                formData.status === "EIGHTY_IAC") &&

                            <TextareaField
                                required
                                type="text"
                                name="other"
                                label="Other"
                                value={formData.other}
                                onChange={handleInput}
                                error={error.other}
                                containerClass={"form-group"}
                                onBlur={onBlur}
                                hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                            />
                        }
                        <div className="button-wrap">
                            <button type='submit' className='bttn'>Submit</button>
                        </div>
                    </form>
                </ModalBody>

            </Modal>
        </div>
    );
}

export default connect(null, { updateCancelCertificateAction, fetchReasonsList })(withRouter(ActionModal))
