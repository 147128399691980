import React, { useState } from "react";
import { connect } from 'react-redux';
import FileUpload from '../../common/FileUpload';
import ModalHOC from "../../common/Modal";
import sample from '../dependencies/Sample.xls'
import './index.scss'
import { API_URL } from '../../../constants/ApplicationUrl'
import { isLoading } from '../../../constants/actionConstants';

const UploadCSV = (props) => {
    const { onUpload } = props;

    const [fileName, setFileName] = useState("");
    const [bulkResponseList, setBulkResponseList] = useState([]);
    const [showBulkResponse, setShowBulkResponse] = useState(false);


    const handleUploadFile = (file) => {
        setFileName(file.name);
        if (onUpload) {
            isLoading(true);
            onUpload(file).then((response) => {
                const { status, data } = response;
                if (status) {
                    setBulkResponseList(data)
                    setShowBulkResponse(true)
                } else {
                    setFileName("")
                }

            }, (error) => {
                isLoading(false);

            });
        }
    }

    const onBackResponse = () => {
        setShowBulkResponse(false)
        setBulkResponseList([]);
        setFileName("")
    }

    return (
        <div className="upload-csv">
            <h3>Bulk Actions</h3>
            {!showBulkResponse &&
                <div className="file-upload-container">

                    <p className="sample">Sample File <a href={sample} download>{'download'}</a></p>

                    <FileUpload
                        onUploadFile={(e) => {
                            handleUploadFile(e)
                        }}
                        allowedFiles={[".xls", ".xlsx"]}
                        containerClass={"form-group"}
                        value={""}
                    />
                    <br />
                    <br />

                    {fileName && <p>
                       <b>{'File Name:'}</b> {fileName}
                    </p>}
                </div>
            }
            {
                showBulkResponse &&
                <div className="bulk-response">
                    <div className="row header">
                        <div className="col-sm-3">
                            <b>{'Reference Number'}</b>
                        </div>
                        <div className="col-sm-1"></div>
                        <div className="col-sm-7">
                            <b>{'Response'}</b>
                        </div>
                    </div>
                    {
                        bulkResponseList.map((val, index) => {
                            return (
                                <div className="row">
                                    <div className="col-sm-3 side-header">
                                        {val.referenceNumber}
                                    </div>
                                    <div className="col-sm-1">:</div>
                                    <div className={`col-sm-7 ${val.success ? 'font-green' : 'font-red'}`}>
                                        {val.message}
                                    </div>
                                </div>
                            )
                        })
                    }
                    <br />
                    <div className="offset-md-3">
                        <button className={'btn-container  bttn blue normal'} onClick={() => {
                            onBackResponse()
                        }}>
                            {'Back'}
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}
export default connect(null, {})(ModalHOC(UploadCSV));