import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getRecognitionHistoryList } from '../dependencies/action'
import Download from '../../common/Download';
import SectionTitle from '../../common/sectionTitle/sectionTitle';
import SearchBox from '../../common/Search';
import Table from '../../common/Table';
import { SearchByStartupApplicationOptions } from '../common/constant';
import { getSectorOptions } from '../../applicationFor80Iac/dependencies/action';
import { getNatureOfList } from '../../startupApplication/dependencies/action';
import { doDownloadStartupList } from '../dependencies/service';
import columns from '../dependencies/columns/startupColumn';
// import '../scss/index.scss';
import '../scss/index.scss';
// import './s'
const StartupApplication = (props) => {
    const { getRecognitionHistoryList, doDownloadStartupList, recognitionData } = props;
    const [query, setQuery] = useState({
        status: "SUBMIT", archived: false
    });
    const [natureOption, SetNatureOption] = useState([]);
    const [sectorOptions, setSectorOptions] = useState([]);
    useEffect(() => {
        getTableData();

    }, [query])

    const getTableData = () => {
        getRecognitionHistoryList({
            query
        });
    }



    const doSearch = (params) => {
        const { query = {}, searchText = "", searchBy = "" } = params;
      
        if (searchBy && searchBy === 'certificateDate') {
            setQuery(() => {
                return { ...query, 'searchType': 'CERTIFICATE_DATE' }
            });
        } else if (searchBy && searchBy === 'createdDate') {
            setQuery(() => {
                return { ...query, 'searchType': 'CREATED_DATE' }
            });
        } else {
            setQuery(query);
        }

    }



    function downloadStartupList() {
        if (doDownloadStartupList) {
            return doDownloadStartupList({
                query
            }).then((data) => {
                return data;
            })
        }
    }

    return (
        <div>
            <SectionTitle>Startup Application</SectionTitle>
            <div className="startup-app-filter-form">
                <div className="inner-wrap clearfix">
                    <div className="left-filter-wrap">
                        <div className="global-search-filter view-wrap">

                        </div>
                        <SearchBox
                            searchByOptions={SearchByStartupApplicationOptions(sectorOptions, natureOption)}
                            doSearch={doSearch}
                        />

                    </div>
                </div>
            </div>
            <Download
                fileName={'Startup Application Details'}
                onClickDownload={downloadStartupList}
                className="bttn-link"
            />



            <Table
                dataTable={recognitionData ? recognitionData : ''}
                columns={columns}
                keyField='id'
                cssClass = 'startupLogs'
                // onSortChange={onSortChange}
                sizePerPage={1000}
            // totalSize={recordsTotal}
            />

        </div>
    )
}


const mapStateToProps = (state) => {

    return {
        recognitionData: state.RecognitionHistoryReducer.recognitionHistory
    }
}
export default connect(mapStateToProps, { getRecognitionHistoryList, doDownloadStartupList })(withRouter(StartupApplication));