import { combineReducers } from 'redux';
import {
    OTHER_DOCUMENTS_LIST,
    OTHER_DOCUMENTS_LIST_BY_ID,
    TAB_SECTION_LIST
} from '../../../constants/actionConstants';


const otherDocumentsList = (state = {}, action) => {
    switch (action.type) {
        case OTHER_DOCUMENTS_LIST:
            return { ...action.payload, }
        default:
            return state;
           
    }
}

const otherDocumentsID = (state = {}, action) => {
    switch (action.type) {
        case OTHER_DOCUMENTS_LIST_BY_ID:
            return { ...action.payload }
        default:
            return state;
    }
}

const tabSectionList = (state = {}, action) => {
    switch (action.type) {
        case TAB_SECTION_LIST:
            return { ...action.payload, }
        default:
            return state;
           
    }
}


const OtherDocumentsReducer = combineReducers({
    otherDocumentsList,
    otherDocumentsID,
    tabSectionList
})

export default OtherDocumentsReducer;