import {
    validateEmail,
    validateName,
    validateUserName,
    validateMobileNo,
    validateDob,
    validateDesignation,
    validatePassword,
    validateConfirmPassword
} from '../../../dependencies/utils/validation/Validation';

export default {
   
    validateEditProfile: (params) => {
        const { username, emailId,name,phoneNumber,dob,designation } = params;
        let formStatus = {};
        const usernameStatus = validateUserName(username);
        const emailIdStatus = validateEmail(emailId);
        const nameStatus = validateName(name);
        const phoneNumberStatus = validateMobileNo(phoneNumber)
        const dobStatus =  validateDob(dob)
        const designationStatus = validateDesignation(designation)

        formStatus = {
            username: usernameStatus.error,
            emailId: emailIdStatus.error,
            name: nameStatus.error,
            phoneNumber: phoneNumberStatus.error,
            dob: dobStatus.error,
            designation: designationStatus.error,
            hasErrors: (
                usernameStatus.hasError
                || emailIdStatus.hasError
                || nameStatus.hasError
                || phoneNumberStatus.hasError
                || dobStatus.hasError
                || designationStatus.hasError
            )
        };
        return formStatus;
    },

    validateUpdatePasswordForm: (params) => {
        const { oldPassword,password, confirmPassword } = params;
        let formStatus = {};
        const oldPasswordStatus = validatePassword(oldPassword);
        const passwordStatus = validatePassword(password);
        const confirmPasswordStatus = validateConfirmPassword(confirmPassword, password);

        formStatus = {
            oldPassword:oldPasswordStatus.error,
            confirmPassword: confirmPasswordStatus.error,
            password: passwordStatus.error,
            hasErrors: (
                confirmPasswordStatus.hasError
                || passwordStatus.hasError
                || oldPasswordStatus.hasError
            )
        };
        return formStatus;
    },
   
};
