
export default (response) => {
    return {
        activities: activitiesTransformer(response.activities || {}),
        additionalInformation: additionalInformationTransformer(response.additionalInformation || {}),
        address: addressTransformer(response.address || {}),
        directors: directorsTransformer(response.directors || []),
        id: response.id || "",
        incorporationNumber: response.incorporationNumber || "",
        referenceNumber: response.referenceNumber || "",
        representative: representativeTransformer(response.representative || {}),
        selfCertificate: selfCertificateTransformer(response.selfCertificate || {}, response.isPreCertified),
        startupEntity: startupEntityTransformer(response.startupEntity || {}),
        status: response.status || "",
        recognitionDetailType: 'ALL',
        isPreCertified: response.isPreCertified !== '' ? response.isPreCertified : ''
    }
}


export function activitiesTransformer(data) {
    const transform = {};
    transform.awardDocument = null;
    if (data && data.awardDocument && data.awardDocument.publicUrl) {
        transform.awardDocument = data.awardDocument;
    }
    transform.awardsReceived = data.awardsReceived || "";
    transform.generateRevenue = data.generateRevenue || "";
    transform.problemSolution = data.problemSolution || "";
    transform.problemStatement = data.problemStatement || "";
    transform.solutionUniqueness = data.solutionUniqueness || "";
    return transform;
}

export function additionalInformationTransformer(data) {
    const transform = {};
    transform.numberOfEmployees = data.numberOfEmployees || 0;
    transform.stage = data.stage || "";
    transform.ipr = data.ipr || false;
    transform.iprs = data.iprs || [];
    transform.workCategory = data.workCategory || false;
    transform.workCategories = data.workCategories || [];
    transform.scalableBusinessModel = data.scalableBusinessModel || false;
    transform.businessModel = data.businessModel || {};
    transform.receiveFunding = data.receiveFunding || false;
    transform.fundingProof = data.fundingProof || {};

    return transform;
}

export function addressTransformer(data) {
    const transform = {};
    transform.address1 = data.address1 || "";
    transform.address2 = data.address2 || "";
    transform.address3 = data.address3 || "";
    transform.city = data.city || "";
    transform.districtId = data.districtId || "";
    transform.districtName = data.districtName || "";
    transform.pinCode = data.pinCode || "";
    transform.stateId = data.stateId || "";
    transform.stateName = data.stateName || "";
    transform.subDistrictId = data.subDistrictId || "";
    transform.subDistrictName = data.subDistrictName || "";

    return transform;
}

export function directorsTransformer(data) {
    const transform = [];
    if (data && data.length > 0) {
        data.forEach(val => {
            const item = {};
            item.address = val.address || "";
            item.deleted = val.deleted || "";
            item.email = val.email || "";
            item.gender = val.gender || "MALE";
            item.id = val.id || "";
            item.mobileNumber = val.mobileNumber || "";
            item.mobilePrefix = val.mobilePrefix || "";
            item.name = val.name || "";
            item.nativeId = val.nativeId || 0;
            item.directorCin = val.directorCin || "";

            transform.push(item);
        });
    }
    return transform;
}

export function incompleteActionTransformer(data) {
    const transform = {};

    transform.incompleteDate = data.incompleteDate || "";
    transform.incompleteDescription = data.incompleteDescription || "";

    const incompleteReasons = [];
    if (data && data.incompleteReasons && data.incompleteReasons.length > 0) {
        data.incompleteReasons.forEach((val) => {
            const item = {};
            item.deleted = val.deleted;
            item.enablerType = val.enablerType;
            item.id = val.id;
            item.nativeId = val.nativeId;
            item.reason = val.reason;
            item.startupRejectType = val.startupRejectType;

            incompleteReasons.push(item);
        });
    }

    transform.incompleteReasons = incompleteReasons || [];
    return transform;
}

export function rejectReasonActionTransformer(data) {
    const transform = {};
    transform.rejectDate = data.rejectDate || "";
    transform.rejectDescription = data.rejectDescription || "";
    transform.rejectReason = data.rejectReason || {};

    return transform;
}

export function representativeTransformer(data) {
    const transform = {};

    transform.designation = data.designation || "";
    transform.email = data.email || "";
    transform.mobile = data.mobile || "";
    transform.name = data.name || "";

    return transform;
}

export function selfCertificateTransformer(data, isPrecertifed) {
    const transform = {};

    const additionalDocument = [];
    if (data && data.additionalDocument && data.additionalDocument.length > 0) {
        const item = {};
        data.additionalDocument.forEach((val) => {
            const item = {};
            item.name = val.name || "";
            additionalDocument.push({ ...val, ...item });
        });
    }
    transform.additionalDocument = additionalDocument || [];
    transform.registrationCertificate = data.registrationCertificate || {};
    if (!isPrecertifed){
        transform.authorizationLetter = data.authorizationLetter || {};
        transform.isMerger = (data.isMerger !== undefined && (data.isMerger === true || data.isMerger === false)) ? data.isMerger : false;
        transform.isNameChange = (data.isNameChange !== undefined && (data.isNameChange === true || data.isNameChange === false)) ? data.isNameChange : false;
        transform.isConversion = (data.isConversion !== undefined && (data.isConversion === true || data.isConversion === false)) ? data.isConversion : false;
        transform.isCinChange = (data.isCinChange !== undefined && (data.isCinChange === true || data.isCinChange === false)) ? data.isCinChange : false;
        transform.mergerDocs = data.mergerDocs || [];
        transform.nameChangeDocs = data.nameChangeDocs || [];
        transform.cinChangeDocs = data.cinChangeDocs || [];
        transform.conversionDocs = data.conversionDocs || [];
    }
    return transform;
}

export function startupEntityTransformer(data) {
    const transform = {};

    transform.eightyIACCertificateStatus = data.eightyIACCertificateStatus || "";
    transform.incorporationDate = data.incorporationDate;
    transform.name = data.name;
    transform.panNumber = data.panNumber;
    transform.recognitionCertificateStatus = data.recognitionCertificateStatus || "";
    transform.secFiftySixCertificateStatus = data.secFiftySixCertificateStatus || "";
    transform.startupIndustry = data.startupIndustry || {};
    transform.startupNatureOfEntity = data.startupNatureOfEntity || {};
    transform.startupSection = data.startupSection || {};
    transform.startupServices = startupServicesTransformer(data.startupServices || []);
    return transform;
}

export function recognitionCertificateTransformer(data) {
    const transform = {};

    transform.compressedName = data.compressedName || "";
    transform.name = data.name || "";
    transform.originalFilename = data.originalFilename || "";
    transform.sizeInBytes = data.sizeInBytes || 0;
    transform.type = data.type || "";

    return transform;
}

export function startupServicesTransformer(services) {
    const transform = [];

    if (services && services.length > 0) {
        services.forEach((val) => {
            const service = {};

            service.id = val.id;
            service.orderFlag = val.orderFlag;
            service.serviceName = val.serviceName;

            transform.push(service);
        });
    }
    return transform;
}