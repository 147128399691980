import FormValidator from './Validation';

export function dropdownOptionsTransform(array, text, val) {
    const isArray = array instanceof Array;
    let options = [];
    if (isArray) {
        options = array.reduce((arr, option) => {
            const label = option[text] || "";
            const value = option[val] || "";
            arr.push({ text: label, value });
            return arr;
        }, []);
    }
    return options;
}


export function validateStartupForm(formData = {}) {
    const {
        address: {
            address1 = "",
            address2 = "",
            city = "",
            stateId = "",
            districtId = "",
            subDistrictId = "",
            pinCode = ""
        } = {},
        startupEntity: {
            startupIndustry = {},
            startupNatureOfEntity = {},
            startupServices = [],
            startupSection = {}
        } = {},
        representative: {
            name = "",
            designation = "",
            mobile = "",
            email = ""
        } = {},
        directors = [],
        additionalInformation: {
            receiveFunding,
            businessModel: {
                note = "",
            } = {},
            numberOfEmployees = 0,
            scalableBusinessModel,
            stage,
            workCategory,
            ipr,
            iprs = []
        } = {},
        activities: {
            showAwards = "no",
            awardDocument = null,
            awardsReceived = "",
            generateRevenue = "",
            problemSolution = "",
            problemStatement = "",
            solutionUniqueness = "",
        } = {},
        selfCertificate: {
            registrationCertificate = {},
            additionalDocument = [],
            // tnc,
        } = {}
    } = formData;
    const noOfDirectors = directors.length || 0;
    const formStatus = FormValidator.startupFormValidation({
        startupIndustry,
        startupNatureOfEntity,
        startupServices,
        startupSection,
        address1,
        address2,
        city,
        stateId,
        districtId,
        subDistrictId,
        pinCode,
        name,
        designation,
        mobile,
        email,
        noOfDirectors,
        receiveFunding,
        numberOfEmployees,
        scalableBusinessModel,
        stage,
        directors,
        workCategory,
        ipr,
        iprs,
        awardDocument,
        awardsReceived,
        showAwards,
        generateRevenue,
        problemSolution,
        problemStatement,
        solutionUniqueness,
        registrationCertificate,
        // tnc,
        note,
        additionalDocument
    });
    return formStatus;
}

export function validateStartupEntityForm(formData = {}) {
    const {

        startupEntity: {
            startupIndustry = {},
            startupNatureOfEntity = {},
            startupServices = [],
            startupSection = {}
        } = {},

        selfCertificate: {
            tnc,
        } = {}
    } = formData;
    // const noOfDirectors = directors.length || 0;
    const formStatus = FormValidator.startupFormEntityValidation({
        startupIndustry,
        startupNatureOfEntity,
        startupServices,
        startupSection,
        // tnc,
    });
    return formStatus;
}
export function validateStartupInfoForm(formData = {}) {
    const {
        additionalInformation: {
            receiveFunding,
            businessModel: {
                note = "",
            } = {},
            numberOfEmployees = 0,
            scalableBusinessModel,
            stage,
            workCategory,
            ipr,
            iprs = []
        } = {},
        selfCertificate: {
            tnc,
        } = {}
    } = formData;
    // const noOfDirectors = directors.length || 0;
    const formStatus = FormValidator.startupFormInfoValidation({
        receiveFunding,
        numberOfEmployees,
        scalableBusinessModel,
        stage,
        workCategory,
        ipr,
        iprs,
        // tnc,
        note,
    });
    return formStatus;
}

export function validateStartupAddressForm(formData = {}) {
    const {
        address: {
            address1 = "",
            address2 = "",
            city = "",
            stateId = "",
            districtId = "",
            subDistrictId = "",
            pinCode = ""
        } = {},
        selfCertificate: {
            tnc,
        } = {}
    } = formData;
    // const noOfDirectors = directors.length || 0;
    const formStatus = FormValidator.startupFormAddressValidation({
        
        address1,
        address2,
        city,
        stateId,
        districtId,
        subDistrictId,
        pinCode,
        // tnc,
    });
    return formStatus;
}

export function validateStartupRepresentativeForm(formData = {}) {
    const {
        representative: {
            name = "",
            designation = "",
            mobile = "",
            email = ""
        } = {},
        selfCertificate: {
            tnc,
        } = {}
    } = formData;
    // const noOfDirectors = directors.length || 0;
    const formStatus = FormValidator.startupFormRepresentativeValidation({
        name,
        designation,
        mobile,
        email,
        // tnc,
    });
    return formStatus;
}

export function validateStartupDirectorForm(formData = {}) {
    const {
        directors = [],
        selfCertificate: {
            tnc,
        } = {}
    } = formData;
    const noOfDirectors = directors.length || 0;
    const formStatus = FormValidator.startupFormDirectorValidation({
        noOfDirectors,
        directors,
        // tnc,
    });
    return formStatus;
}
export function validateStartupActivityForm(formData = {}) {
    const {
        activities: {
            showAwards = "no",
            awardDocument = null,
            awardsReceived = "",
            generateRevenue = "",
            problemSolution = "",
            problemStatement = "",
            solutionUniqueness = "",
        } = {},
        selfCertificate: {
            tnc,
        } = {}
    } = formData;
    const formStatus = FormValidator.startupFormAcitivityValidation({
        awardDocument,
        awardsReceived,
        showAwards,
        generateRevenue,
        problemSolution,
        problemStatement,
        solutionUniqueness,
        // tnc,
    });
    return formStatus;
}

export function validateStartupCertificateForm(formData = {}) {
    const {
        selfCertificate: {
            registrationCertificate = {},
            additionalDocument = [],
            tnc,
        } = {}
    } = formData;
    // const noOfDirectors = directors.length || 0;
    const formStatus = FormValidator.startupFormCertificateValidation({
        registrationCertificate,
        // tnc,
        additionalDocument
    });
    return formStatus;
}
