import React, { Component, useEffect, useState } from 'react';
import './common/index.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import KeyValue from '../keyValue';
import { getCommunicationRequestDetails,getAssignedEpUserDetails,getAssignedIMBMemberUserDetails,getAssignedImbChairDetails,getAssignedAdminSuperadminDetails } from '../dependencies/action';
import communicationColumns from '../dependencies/columns/communicationThreadCol';
import EpAssignedColumns from '../dependencies/columns/EpAssignedColumns';
import ImbMemberAssignColumn from '../dependencies/columns/ImbMemberAssignColumn';
import Table from '../../common/Table';
import moment from 'moment';

class CommunicationRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sAppId: this.props.match.params.id
        }

    }

    componentDidMount() {
        this.props.getAssignedAdminSuperadminDetails(this.state.sAppId);
        this.props.getCommunicationRequestDetails(this.state.sAppId);
        this.props.getAssignedEpUserDetails(this.state.sAppId);
        this.props.getAssignedIMBMemberUserDetails(this.state.sAppId);
        this.props.getAssignedImbChairDetails(this.state.sAppId);
        
    }


    render() {
        const { 
            communicationThreadData: { communicationInfo: { data: { threads = [], entityName, incorporationNumber, recognitionNumber } = {} } = {} } = {},
            epAssignedDetails : { epAssignedDetail = {}  } = {},
            imbMemberAssignedDetails : {imbmemberAssignedDetail = {}} = {},
            imbChairDetails = {},
            adminSuperAdmiDetails = {}
        } = this.props;
        const threadsList = threads || [];
        console.log('adminSuperAdmiDetails',adminSuperAdmiDetails)
        let communicationEntity =
        {
            'Certificate No.': recognitionNumber,
            'Registration No.': incorporationNumber,
            'Name of The Entity': entityName,
        }
        return (
            <div>
                <div className="entity-detail-wrap mb-30">
                    <div className="entity-detail-inner p-20 ">
                        <h3>Communication</h3>
                        <KeyValue items={communicationEntity} />
                    </div>
                </div>

                <div className="observation-communication-table">
                    {/* <h3>Observation Communication & Response Received</h3> */}
                    {/* <Table
                        dataTable={threadsList}
                        columns={communicationColumns()}
                        keyField='communication'
                        sizePerPage="100"
                        cssClass='startupLogs'
                    /> */}
                    

                </div>
                <div className="observation-communication-table">
                    <h3>Admin Superadmin Details</h3>
                    <div className="table-container">
                        <div className="">
                            <div>
                                <div className="react-bootstrap-table table-responsive">
                                    <table className="table table-striped table-hover table-bordered table startupLogs">
                                    <thead>
                                        <tr>
                                            <th tabindex="0">Sr.No</th>
                                            <th tabindex="0">Admin Name</th>
                                            <th tabindex="0">Status</th>
                                            <th tabindex="0">Comment</th>
                                            <th tabindex="0">Response Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            adminSuperAdmiDetails.data && adminSuperAdmiDetails.data.assignDate ?(
                                                <tr>
                                                    <td>1</td>
                                                    <td>{adminSuperAdmiDetails.data?adminSuperAdmiDetails.data.adminName:''}</td>
                                                    <td>{adminSuperAdmiDetails.data?adminSuperAdmiDetails.data.statusAdmin:''}</td>
                                                    <td>{adminSuperAdmiDetails.data?adminSuperAdmiDetails.data.recommendComment:''}</td>
                                                    <td>{(adminSuperAdmiDetails.data && adminSuperAdmiDetails.data.actionTakenDate)?moment(adminSuperAdmiDetails.data.actionTakenDate).format('DD/MM/YYYY'):''}</td>
                                                </tr>
                                            ):(
                                                <tr>
                                                    <td colspan="5"><div>No Record found.</div></td>                                                    
                                                </tr>
                                            )
                                        }                                        
                                        
                                    </tbody>
                                    </table>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    

                </div>
                <div className="observation-communication-table">
                    <h3>EP Assigned Details</h3>
                    <Table
                        dataTable={epAssignedDetail.data}
                        columns={EpAssignedColumns()}
                        keyField='adminName'
                        sizePerPage="100"
                        cssClass='startupLogs'
                    />
                    

                </div>

                <div className="observation-communication-table">
                    <h3>Imb Member Assigned Details</h3>
                    <Table
                        dataTable={imbmemberAssignedDetail.data}
                        columns={ImbMemberAssignColumn()}
                        keyField='adminName'
                        sizePerPage="100"
                        cssClass='startupLogs'
                    />
                    

                </div>

                <div className="observation-communication-table">
                    <h3>Imb Chair Assigned Details</h3>
                    <div className="table-container">
                        <div className="">
                            <div>
                                <div className="react-bootstrap-table table-responsive">
                                    <table className="table table-striped table-hover table-bordered table startupLogs">
                                    <thead>
                                        <tr>
                                            <th tabindex="0">Sr.No</th>
                                            <th tabindex="0">Admin Name</th>
                                            <th tabindex="0">Status</th>
                                            <th tabindex="0">Comment</th>
                                            <th tabindex="0">Response Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            imbChairDetails.data && imbChairDetails.data.assignDate ?(
                                                <tr>
                                                    <td>1</td>
                                                    <td>{imbChairDetails.data?imbChairDetails.data.adminName:''}</td>
                                                    <td>{imbChairDetails.data?imbChairDetails.data.statusImbChair:''}</td>
                                                    <td>{imbChairDetails.data?imbChairDetails.data.recommendComment:''}</td>
                                                    <td>{(imbChairDetails.data && imbChairDetails.data.actionTakenDate)?moment(imbChairDetails.data.actionTakenDate).format('DD/MM/YYYY'):''}</td>
                                                </tr>
                                            ):(
                                                <tr>
                                                    <td colspan="5"><div>No Record found.</div></td>                                                    
                                                </tr>
                                            )
                                        }                                        
                                        
                                    </tbody>
                                    </table>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                </div>

            </div>)
    }
}

const mapStateToProps = (state) => {
    return {
        communicationThreadData: state.Applicatio80IACReducer.communicationDetails,
        epAssignedDetails : state.Applicatio80IACReducer.epAssignedDetails,
        imbMemberAssignedDetails : state.Applicatio80IACReducer.imbMemberAssigned,
        imbChairDetails : state.Applicatio80IACReducer.imbChairAssigned,
        adminSuperAdmiDetails : state.Applicatio80IACReducer.adminSuperadminAssigned
    }
}

export default connect(mapStateToProps, { getCommunicationRequestDetails,getAssignedEpUserDetails,getAssignedIMBMemberUserDetails,getAssignedImbChairDetails,getAssignedAdminSuperadminDetails })(withRouter(CommunicationRequest));