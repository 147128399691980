import api from '../../../dependencies/utils/Api';
import {
    FETCH_80IAC_APPLICATION_DETAILS,
    FETCH_80IAC_LIST,
    UPDATE_80IAC_ACTION,
    FETCH_NRDC_LIST,
    FETCH_COMMUNICATION_REQUEST,
    FETCH_NRDC_RECOGNITION,
    FETCH_80IAC_APPLICATION_OBSERVATION,
    FETCH_IMB_MEMBER_LIST,
    SAVE_IMB_MEMBER_COMMENT,
    SAVE_OBSERVATION_COMMUNICATED,
    UPDATE_BULK_ACTION,
    SAVE_NRDC_COMMENT,
    FETCH_STARTUP_SELECTION,
    FETCH_EP_USERS,
    DOWNLOAD_SECTION_56_LIST,
    FETCH_INCOMPLETE_HISTORY_IAC,
    FETCH_BENEFICIARY_LIST,
    UPDATE_RECOMENDATION_STATUS,
    UPDATE_IMB_STATUS,
    UPDATE_IMB_RECOMENDATION_STATUS,
    UPDATE_IMB_CHAIR_RECOMENDATION_STATUS,
    FETCH_ASSIGNED_EP_REQUEST,
    FETCH_ASSIGNED_IMBMEMBER_REQUEST,
    FETCH_ASSIGNED_IMBCHAIR_REQUEST,
    FETCH_ASSIGNED_ADMIN_SUPERADMIN_REQUEST
} from "../../../constants/ApplicationUrl";
import startupTransformer from './transformer/transformer'
import { returnStatement } from '@babel/types';



export const IACServices = {
    get80IACApplication(id) {
        return api.GET(`${FETCH_80IAC_APPLICATION_DETAILS}/${id}`).
            then((response) => {
                const { data: { status, data } = {} } = response;
                if (status) {

                    return startupTransformer(data);
                } else {
                    return Promise.reject(response.data);
                }
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    updateImbRecommendation(params) {
        return api.POST(UPDATE_IMB_RECOMENDATION_STATUS, params).
            then((response) => {
                const { data = {} } = response;
                return { data };
            })
    },
    updateImbChairRecommendation(params) {
        return api.POST(UPDATE_IMB_CHAIR_RECOMENDATION_STATUS, params).
            then((response) => {
                const { data = {} } = response;
                return { data };
            })
    },
    updateIMBStatus(id) {
        return api.GET(`${UPDATE_IMB_STATUS}/${id}`).
            then((response) => {
                const { data: { data = {}, status = false } = {}, } = response || {};
                return { data, status }
            });
    },
    updateRecommendation(params) {        
        return api.POST(UPDATE_RECOMENDATION_STATUS, params).
            then((response) => {
                const { data = {} } = response;
                return { data };
            })
    },
    getClarificationRequest({ id }) {
        return api.GET(`${FETCH_80IAC_APPLICATION_OBSERVATION}/${id}`).
            then((response) => {
                const { data: { data = {}, status = false } = {}, } = response || {};
                return { data, status }
            });
    },
    getIAC80List(params) {
        return api.POST(FETCH_80IAC_LIST, params).
            then((response) => {
                const { data = {} } = response;
                return { data };
            })
    },

    fecthIncompleteList(id) {

        return api.GET(`${FETCH_INCOMPLETE_HISTORY_IAC}/${id}`).
            then((response) => {
                const { data = [] } = response.data;
                return  data ;
            })
    },
    updateAction(params) {

        return api.POST(UPDATE_80IAC_ACTION, params).
            then((response) => {
                const { data: { status, data = [], message } = {} } = response;
                return { status, message, data };
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    getNRDCList(id) {
        return api.GET(`${FETCH_NRDC_LIST}/${id}`).
            then((response) => {
                return Promise.resolve(response.data);
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    getNRDCRecognition(id) {
        return api.GET(`${FETCH_NRDC_RECOGNITION}/${id}`).
            then((response) => {
                const { data: { data, status } = {} } = response;
                return { data, status };
            });
    },

    getCommunicationRequest(id) {
        return api.GET(`${FETCH_COMMUNICATION_REQUEST}/${id}`).
            then((response) => {
                return Promise.resolve(response.data);
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    getAssignedEpDetailsRequest(id) {
        return api.GET(`${FETCH_ASSIGNED_EP_REQUEST}/${id}`).
            then((response) => {
                return Promise.resolve(response.data);
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    getAssignedIMBMemberUserDetails(id) {
        return api.GET(`${FETCH_ASSIGNED_IMBMEMBER_REQUEST}/${id}`).
            then((response) => {
                return Promise.resolve(response.data);
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    getAssignedImbChairDetails(id) {
        return api.GET(`${FETCH_ASSIGNED_IMBCHAIR_REQUEST}/${id}`).
            then((response) => {
                return Promise.resolve(response.data);
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    getAssignedAdminSuperadminDetails(id) {
        return api.GET(`${FETCH_ASSIGNED_ADMIN_SUPERADMIN_REQUEST}/${id}`).
            then((response) => {
                return Promise.resolve(response.data);
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    postClarificationObservation(params) {
        return api.POST(`${SAVE_OBSERVATION_COMMUNICATED}`, params).
            then((response) => {
                const { data: { status, message } = {} } = response;
                return { status, message };
            })
    },
    getIMBMemberCommentList(params) {
        const { id } = params;
        return api.GET(`${FETCH_IMB_MEMBER_LIST}/${id}`).
            then((response) => {
                const { data: { data, status } } = response;
                return { data, status };
            })
    },
    saveImbMemberComment(params) {
        return api.POST(SAVE_IMB_MEMBER_COMMENT, params).
            then((response) => {
                const { data: { data, status } } = response;
                return { data, status };
            })
    },
    doBulkAction(params) {
        return api.POST(UPDATE_BULK_ACTION, params).
            then((response) => {
                const { data: { data, status } } = response;
                return { data, status };
            })
    },
    updateNRDCCommentAction(params) {
        return api.POST(SAVE_NRDC_COMMENT, params).
            then((response) => {
                const { data: { status, message, data } } = response;
                return { status, message };
            })
    },
    getSector() {
        return api.GET(FETCH_STARTUP_SELECTION)
            .then((response) => {
                const { data: { data, status } } = response;
                return { status, data };
            })
    },
    getEPUsers() {
        return api.GET(FETCH_EP_USERS)
            .then((response) => {
                const { data: { data, status } } = response;
                return { status, data };
            })
    },
    doDownloadIAC80List(params) {
        return api.DOWNLOADDATA(FETCH_80IAC_LIST, params).
            then((response) => {
                return response;
            })
    },
    doDownloadBeneficiaryList(params) {
        return api.DOWNLOADGET(FETCH_BENEFICIARY_LIST, params).
            then((response) => {
                return response;
            })
    }
}