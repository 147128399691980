import React, { Component, useEffect, useState } from 'react';
import InputField from '../common/InputField';
import Button from '../common/Button';
import { generateCor } from '../../dependencies/services/startupApplicationService';
import { API_URL } from '../../constants/ApplicationUrl';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import FormValidator from './dependencies/FormValidator';
import { getNatureOfList } from '../startupApplication/dependencies/action';
import "./scss/index.scss";

const GenerateCor = (props) => {
    const [diipNumber, setDiipNumber] = useState('');
    const [oldEntityName, setOldEntityName] = useState('');
    const [newEntityName, setNewEntityName] = useState('');
    const [newDiipNumner, setNewDiipNumner] = useState('');
    const [showCertificate, setShowCertificate] = useState(false)
    const [showCertificateEntity, setShowCertificateEntity] = useState(false)
    const [publicUrl, setPublicUrl] = useState('');
    const [error, setError] = useState('');
    const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] = useState(false);



    const handleInput = (e, type) => {
        let value = e.target.value;

        if (type === 'diipNumber') {
            setDiipNumber(value);
        } else if (type === 'newDiipNumner') {
            setNewDiipNumner(value)
        } else if (type === 'oldEntityName') {
            setOldEntityName(value);
        } else if (type === 'newEntityName') {
            setNewEntityName(value);
        } 
    }

    const validateInput = () => {

        const formStatus = FormValidator.validateRegenerateDiipNumber({
            diipNumber
        });
        const errors = {
            diipNumber: formStatus.diipNumber
        };
        setError(errors);
        return !formStatus.hasErrors;
    };

    const validateChangeEntity = () => {
        const formStatus = FormValidator.validateChangeEntityName({
            newDiipNumner,
            oldEntityName,
            newEntityName

        });
        const errors = {
            newDiipNumner: formStatus.newDiipNumner,
            oldEntityName: formStatus.oldEntityName,
            newEntityName: formStatus.newEntityName
        };
        setError(errors);
        return !formStatus.hasErrors;
    }

 
    const onBlur = (e, name) => {
      
        if (name === 'diipNumber') {
            validateInput();
        } else if (name === 'newDiipNumner' || name === 'oldEntityName' || name === 'newEntityName') {
            validateChangeEntity();
        }
    }


    const diipFormHandler = (e) => {
        e.preventDefault();
        if (!validateInput()) {
            setHasInvalidSubmitOccurred(true);
        } else {
            props.generateCor(
                { dippNumber: diipNumber, entityChangeRequest: false }).then(response => {
                    if (response.status === true) {
                        setShowCertificate(true);
                        setPublicUrl(response.data.publicUrl);
                        setDiipNumber('');
                    }
                })
        }
    }
    const entityFormSubmit = (e) => {
        e.preventDefault();
        if (!validateChangeEntity()) {
            setHasInvalidSubmitOccurred(true);
        } else {
            props.generateCor(
                { dippNumber: newDiipNumner, newEntityName: newEntityName, oldEntityName: oldEntityName, entityChangeRequest: true }).then(response => {
                    if (response.status === true) {
                        setShowCertificateEntity(true);
                        setPublicUrl(response.data.publicUrl);
                        setOldEntityName('');
                        setNewEntityName('');
                        setNewDiipNumner('');
                    }
                })
        }
    }

  
    return (
        <div className="regenerate-cor">


            <div>
                <form className="filter-form custom-form" onSubmit={diipFormHandler} >
                    <div className="row">
                        <div className="col-md-12">
                            <h3>Regenerate Certificate of Recognition</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {showCertificate ?
                                <a href={`${API_URL()}${publicUrl}`} target="_blank">View Certificate </a>
                                :
                                ''}
                        </div>
                        <div className="col-md-4">
                            <InputField

                                required
                                type="text"
                                name="diipNumber"
                                label="DIPP Number"
                                value={diipNumber}
                                onChange={handleInput}
                                containerClass={"form-group"}
                                onBlur={onBlur}
                                hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                error={error.diipNumber}

                            />
                        </div>

                    </div>
                    <div className="button-wrap">
                        <Button
                            className="bttn"
                            type="submit"
                            text="Submit"
                        />
                    </div>
                </form>
            </div>
            <div>
                <form className="filter-form custom-form" onSubmit={entityFormSubmit}>
                    <div className="row">
                        <div className="col-md-12">
                            <h3>Regenerate Certificate of Recognition with New Name</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {showCertificateEntity ?
                                <a href={`${API_URL()}${publicUrl}`} target="_blank">View Certificate </a>
                                :
                                ''}
                        </div>
                        <div className="col-md-4">
                            <InputField
                                required
                                type="text"
                                name="newDiipNumner"
                                label="DIPP Number"
                                value={newDiipNumner}
                                onChange={handleInput}
                                containerClass={"form-group"}
                                onBlur={onBlur}
                                hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                error={error.newDiipNumner}

                            />
                        </div>
                        <div className="col-md-4">
                            <InputField
                                required
                                type="text"
                                name="oldEntityName"
                                label="Old Entity Name"
                                value={oldEntityName}
                                onChange={handleInput}
                                containerClass={"form-group"}
                                onBlur={onBlur}
                                hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                error={error.oldEntityName}

                            />

                        </div>
                        <div className="col-md-4">
                            <InputField
                                required
                                type="text"
                                name="newEntityName"
                                label="New Entity Name"
                                value={newEntityName}
                                onChange={handleInput}
                                containerClass={"form-group"}
                                onBlur={onBlur}
                                hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                error={error.newEntityName}

                            />
                        </div>
                    </div>


                    <div className="button-wrap">
                        <Button
                            className="bttn"
                            type="submit"
                            text="Submit"
                        />
                    </div>
                </form>
            </div>

          
        </div>

    )
}

const mapStateToProps = (state) => {


}

export default connect(mapStateToProps, { generateCor, getNatureOfList })(withRouter(GenerateCor));