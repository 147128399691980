import React, { useEffect, useState } from 'react';
import { getServices } from '../dependencies/action';
import { connect } from 'react-redux';
import Table from '../../common/Table';
import column from '../dependencies/columns/services';
import '../style.scss';

const Services = (props) => {
    const { getServices } = props;

    const [servicesList, setServicesList] = useState([]);

    useEffect(() => {
        if (getServices) {
            getServices().then((repsonse) => {
                const { status, message , data } = repsonse;
                if (status) {
                    setServicesList(data);
                }
            });
        }
    }, [])

    return (
        <>
            <div className="country-container">
                <h2 className="mb-20">{"services"}
                    <a href="/update/service/form" className="bttn blue normal add-btn">
                        {"Add Services"}
                    </a>
                </h2>

                <Table
                    dataTable={servicesList}
                    columns={column}
                    keyField={'id'}
                    sizePerPage={1000}
                />
            </div>
        </>
    )
}

export default connect(null, { getServices })(Services);