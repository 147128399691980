import React from 'react';
import moment from 'moment'
import { getDocumentLink } from '../../../../dependencies/utils/helper';
import ReactHtmlParser from 'react-html-parser';

export default (response) => {

let starupInfo =
{
    'Name of the Startup': response.data && response.data.startupRecognition.startupEntity.name ? response.data.startupRecognition.startupEntity.name : '',
    'Date of Incorporation/Registration': response.data && response.data.startupRecognition.startupEntity.incorporationDate ? response.data.startupRecognition.startupEntity.incorporationDate : '',
    'Incorporation / Registration Number': response.data && response.data.startupRecognition.incorporationNumber ? response.data.startupRecognition.incorporationNumber : '',
    'Reference Number' : response.data && response.data.referenceNumber ? response.data.referenceNumber :'', 
    'Address and Business Location': response.data && response.data.startupRecognition.address ? response.data.startupRecognition.address.address1 + ' ' + response.data.startupRecognition.address.address2 + ' ' + response.data.startupRecognition.address.city + ' ' + response.data.startupRecognition.address.districtName + ' ' + response.data.startupRecognition.address.stateName + ' ' + response.data.startupRecognition.address.pinCode : '',
    'Nature of Entity': response.data && response.data.startupRecognition.startupEntity.startupNatureOfEntity.name ? response.data.startupRecognition.startupEntity.startupNatureOfEntity.name : '',
    'PAN of Company': response.data && response.data.startupRecognition.startupEntity.panNumber ? response.data.startupRecognition.startupEntity.panNumber : '',
}
let contactInfo =
{
    'Name of Applicant': response.data && response.data.startupRecognition.representative.name ? response.data.startupRecognition.representative.name : '',
    'Phone No': response.data && response.data.startupRecognition.representative.mobile ? response.data.startupRecognition.representative.mobile : '',
    'Email Address': response.data && response.data.startupRecognition.representative.email ? response.data.startupRecognition.representative.email : '',
    'Startup Recognition Number': response.data && response.data.startupRecognition.startupEntity.recognitionNumber ? response.data.startupRecognition.startupEntity.recognitionNumber : '',
    'Capacity of Applicant / Degination': response.data && response.data.startupRecognition.representative.designation ? response.data.startupRecognition.representative.designation : '',
}
let incomppleteReason = {}
if (response.data && response.data.incompleteAction && response.data.incompleteAction.incompleteReasons && response.data.incompleteAction.incompleteReasons.length > 0) {
    var incompleteDiv = response.data.incompleteAction.incompleteReasons.map((element, index) => {

        if (element.reason == 'Others- If any other, please specify. A field to enter other reason with 1000 character limit.') {
            return <div>  {index + 1}. {response.data.incompleteAction.incompleteDescription}</div>

        } else {
            return <div>  {index + 1}. {element.reason}</div>

        }
    })
    incomppleteReason = { 'Incomplete Reason': incompleteDiv, 'Incomplete Date': response.data.incompleteAction.incompleteDate ? moment(response.data.incompleteAction.incompleteDate).format('DD-MM-YYYY') : '' }

}

let rejectReason = {}
if (response.data && response.data.rejectAction && response.data.rejectAction.rejectReason) {

    if(response.data.rejectAction.rejectReason.reason === 'Others- If any other, please specify. A field to enter other reason with 1000 character limit.'){
        rejectReason = { 'Reject Reason': response.data.rejectAction.rejectDescription ? response.data.rejectAction.rejectDescription : '', 'Reject Date': response.data.rejectAction.rejectDate ? moment(response.data.rejectAction.rejectDate).format('DD-MM-YYYY') : '' }

    }else {
        rejectReason = { 'Reject Reason': response.data.rejectAction.rejectReason.reason ? response.data.rejectAction.rejectReason.reason : '', 'Reject Date': response.data.rejectAction.rejectDate ? moment(response.data.rejectAction.rejectDate).format('DD-MM-YYYY') : '' }

    }
 
}

let declarationInfo =
{
    'Declaration': response.data && response.data.declarationForm && response.data.declarationForm.publicUrl  ? ReactHtmlParser(`<a href="${getDocumentLink(response.data.declarationForm.publicUrl)}" target="_blank"> View Document </a>`) : '',
    'Declaration Signed Date': response.data && response.data.declarationDate ? response.data.declarationDate : '',
    ...incomppleteReason,
    ...rejectReason
}

return {
    starupInfo: starupInfo,
    contactInfo: contactInfo,
    declarationInfo: declarationInfo,
    status: response.data &&  response.data.status,
    isArchived : response.data && response.data.isArchived
  };

};