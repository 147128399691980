import { combineReducers } from 'redux';
import {
    COUNTRY_LIST,
    STATE_LIST,
    INDUSTRY_LIST,
    SERVICES_LIST
} from '../../../constants/actionConstants';


const countryList = (state = [], action) => {
    switch (action.type) {
        case COUNTRY_LIST:
            return [ ...action.payload];
        default:
            return state;
    }
}
const stateList = (state = [], action) => {
    switch (action.type) {
        case STATE_LIST:
            return [ ...action.payload];
        default:
            return state;
    }
}

const industryList = (state = [], action) => {
    switch (action.type) {
        case INDUSTRY_LIST:
            return [ ...action.payload];
        default:
            return state;
    }
}

const servicesList = (state = [], action) => {
    switch (action.type) {
        case SERVICES_LIST:
            return [ ...action.payload];
        default:
            return state;
    }
}



const UpdateReducer = combineReducers({
    countryList,
    stateList,
    industryList,
    servicesList
})

export default UpdateReducer;