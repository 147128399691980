import React, { Component, useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Button from '../../common/Button';
import KeyValue from '../../applicationFor80Iac/keyValue';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { startupLogoViewDetail, UpdateStartupLogoStatus } from '../dependencies/action';
import { API_URL } from "../../../constants/ApplicationUrl";
import { SUPPORTING_DOCUMENTS } from "../../../constants";
import { ROLES } from '../../../constants/Roles';
import { storage } from '../../../dependencies/store/storage';
import EntityDataRow from '../../common/EntityDataRow';
import moment from 'moment';

// import ActionModal from '../dependencies/modal/index';

class StartupLogoView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sAppId: this.props.match.params.id,
            details: {},
        }
    }

    componentDidMount() {
        this.props.startupLogoViewDetail(this.state.sAppId);
    }

    handleActionApprove = () => {
        let confirmation = window.confirm("Are you sure, you want to Approve ?");
        if (confirmation == true) {
            this.props.UpdateStartupLogoStatus({
                applicationId: this.props.match.params.id,
                startupLogoStatus: 'APPROVED',
            }).then(() => {
                this.props.history.push('/startup-logos')
            })
        }
    }
    handleActionReject = () => {
        let confirmation = window.confirm("Are you sure, you want to Reject ?");
        if (confirmation == true) {
            this.props.UpdateStartupLogoStatus({
                applicationId: this.props.match.params.id,
                startupLogoStatus: 'REJECT',
            }).then(() => {
                this.props.history.push('/startup-logos')
            })
        }
    }

    handleActionClose = () => {
        return (<Redirect to={'/startup-logos'} />)
    }

    parseDocUrl = (additionalDocumentList) => {
        return additionalDocumentList.map((element, index) => {
            const regex = /^(http|https):\/\/[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
            let link = "";
            let text = "";
            if (element && element.hasOwnProperty('documentType') && element.documentType === 'WEBSITE_LINK') {
                link = `${element.url.indexOf('http') == -1 ? 'http://' : ''}${element.url}`;
                text = "Website Link";
            } else if (element && element.hasOwnProperty('documentType') && element.documentType === 'FLYER') {
                link = `${API_URL()}${element.fileInfo.publicUrl}`;
                text = "Flyer";
            } else if (element && element.hasOwnProperty('documentType') && element.documentType === 'PAMPHLET') {
                link = `${API_URL()}${element.fileInfo.publicUrl}`;
                text = "Pamplet";
            } else if (element && element.hasOwnProperty('documentType') && element.documentType === 'OTHERS') {
                link = `${API_URL()}${element.fileInfo.publicUrl}`;
                text = "Others";
            }

            if (link && regex.test(link)) {
                return <div key={index}><a href={link} target="_blank"> {text} </a> </div>
            }
        });
    }
    dateFormatChanger = (dateString) => {
        let dateParts = dateString.split("-");
        let timeParts = dateParts[2].split(" ")[1].split(":");
        dateParts[2] = dateParts[2].split(" ")[0];
        // month is 0-based, that's why we need dataParts[1] - 1
        const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0], timeParts[0], timeParts[1]);
        return dateObject;
    }
    eventDuration = (sDate, eDate) => {
        let endDate = this.dateFormatChanger(eDate)
        let startDate = this.dateFormatChanger(sDate)
        var duration = moment.duration(moment(startDate).diff(moment(endDate)));
        var days = duration.asDays();
        duration.subtract(moment.duration(days, 'days'));

        //Get hours and subtract from duration
        var hours = duration.hours();
        duration.subtract(moment.duration(hours, 'hours'));

        //Get Minutes and subtract from duration
        var minutes = duration.minutes();
        duration.subtract(moment.duration(minutes, 'minutes'));

        //Get seconds
        var seconds = duration.seconds();
        let eventDurationDays = days.toString().split(".")[0]
        eventDurationDays = eventDurationDays < 0 ? -eventDurationDays : eventDurationDays
        let eventDurationHours = hours.toString().split(".")[0]
        eventDurationHours = eventDurationHours < 0 ? -eventDurationHours : eventDurationHours
        return `${eventDurationDays} Days, ${eventDurationHours} Hours`;
    }
    render() {
        const data = this.props.SectionData && this.props.SectionData;
        const info = data && data[0];

        this.state.details = info && info.data;
        const { sAppId, exemptionId, details } = this.state;
        const userRole = storage.getUserRole();
        const validRoles = [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.LOGO_ADMIN].indexOf(userRole) > -1;
        let applicantInfo =
        {
            'Name of Applicant': details && details.applicantName ? details.applicantName : '',
            'Telephone No.': details && details.phone ? details.phone : '',
            'Mobile No': details && details.mobile ? details.mobile : '',
            'Address of organizer': details && details.organizerAddress ? details.organizerAddress : '',
            // 'Address': details && details.address ? details.address.address1 + ' ' + details.address.address2 + ' ' + details.address.address3 + ' ' + details.address.city + ' ' + details.address.stateId + ' ' + details.address.pinCode : '',
            'Email': details && details.email ? details.email : '',
            "Applicant's Organization": details && details.applicantOrganization ? details.applicantOrganization : '',
            'Designation': details && details.designation ? details.designation : '',
            'Have you organized any startup related events in the past?': details && details.organizedStartupEvents ? "Yes" : "No",
            'Previous Event(s) Details': details && details.previousEventDetails ? details.previousEventDetails : '',
        }
   
     

      if(details && details.logoUsage ){

        var logoData =  Array.prototype.map.call(details.logoUsage, s => s.name).toString(); // "A,B,C"


      }

 
        let EventInfo =
        {
            "Name of the Event for which permission is being sought for use of 'Startup India' Logo": details && details.eventDetails.eventName ? details.eventDetails.eventName : '',
            'Date of Event': details && details.eventDetails.eventDate ? moment(details.eventDetails.eventDate).format("DD-MM-YYYY") : '',
            'Duration Of Event': details && details.event_duration_start && details.event_duration_end ? this.eventDuration(details.event_duration_start, details.event_duration_end) : '',
            'Date Of Application': details && details.date_of_application ? details.date_of_application : '',
            'Nature of Event': details && details.eventDetails.eventNature ? details.eventDetails.eventNature : '',
            'Nature of Entity': details && details.eventDetails.natureOfEntity ? details.eventDetails.natureOfEntity.name : '',
            'Name of Organising Entity(ies)': details && details.eventDetails.organisingEntity ? details.eventDetails.organisingEntity : '',
            'Venue of the event': details && details.eventDetails.eventVenue ? details.eventDetails.eventVenue : '',
            'City': details && details.address.city ? details.address.city : "",
            'State': details && details.address.stateName ? details.address.stateName : "",
            'Details of Participant Entity (ies)': details && details.eventDetails.detailParticipantEntity ? details.eventDetails.detailParticipantEntity : '',
            'Mention the partnering entities in the event': details && details.eventDetails.partneringEntityDetails ? details.eventDetails.partneringEntityDetails : '',
            'Purpose for which logo would be used': details && details.eventDetails.logoUsedPurpose ? details.eventDetails.logoUsedPurpose : '',
            'Relevance of StartUp India in the proposed event': details && details.eventDetails.relevanceText ? details.eventDetails.relevanceText : '',
            'Request for Speaker': details && details.eventDetails.requestSpeaker ? details.eventDetails.requestSpeaker : '',
            'Additional Comments': details && details.eventDetails.additionalComment ? details.eventDetails.additionalComment : '',
            'Additional document of the event': details && details.eventDetails.additionalDocumentList ? this.parseDocUrl(details.eventDetails.additionalDocumentList) : '',
            'Categories of the organizing entity': details && details.organizationEntityCategory ? details.organizationEntityCategory.name : '',
            'Sub categories of the organizing entity': details && details.organizationEntitySubcategory ? details.organizationEntitySubcategory.name : '',
            'Other sub categories of the organizing entity': details && details.organizationOtherSubcategory ? details.organizationOtherSubcategory : '',

            'Usage of logo': details && details.logoUsage ? logoData : '',
            // 'Self Certification': details && details.selfCertification ? 'YES' : 'NO',
        }
        let forEvent = {
            'Nature and importance of the event': details && details.eventDetails.nature_and_imp ? details.eventDetails.nature_and_imp : '',
            'Profile / track record of the organisers': details && details.eventDetails.profile_of_organisers ? details.eventDetails.profile_of_organisers : '',
            'Profile of participants and delegates': details && details.eventDetails.profile_of_delegates ? details.eventDetails.profile_of_delegates : '',
            'Other deliverables offered by way of exhibition space at the venue, distribution of DIPP’s publicity material in the delegate kits, etc.': details && details.eventDetails.other_deliverables ? details.eventDetails.other_deliverables : '',
        }
        let forElectronicMedia = {
            'Nature of the programme': details && details.eventDetails.nature_of_programme ? details.eventDetails.nature_of_programme : '',
            'Profile / track record of the producers of the programme': details && details.eventDetails.profile_of_producers ? details.eventDetails.profile_of_producers : '',
            'Target audience': details && details.eventDetails.target_audience ? details.eventDetails.target_audience : '',
            'Contents of the programme and inclusion of start-ups related content in the programme': details && details.eventDetails.content_of_programme ? details.eventDetails.content_of_programme : '',
            'Extent to which the programme would help in increasing awareness about start-ups and the innovativeness encouragement and sustenance model in the country, under ‘StartUp India’': details && details.eventDetails.extent_of_programme ? details.eventDetails.extent_of_programme : '',

        }
        const forEventFlag = Object.values(forEvent).some(val => val !== '' && val !== null)
        const forElectronicMediaFlag = Object.values(forElectronicMedia).some(val => val !== '' && val !== null)
        
        const itrateListItem = (itemList, rawList) => {
            return Object.entries(itemList).map((data, index) => {
                switch (data[0]) {
                    case 'Other sub categories of the organizing entity': {
                        if (rawList && rawList.organizationOtherSubcategory) {
                            return <EntityDataRow key={index} className="" title={data[0]} description={data[1]} />
                        }
                        break;
                    }
                    case 'Previous Event(s) Details': {
                        if (rawList && rawList.organizedStartupEvents) {
                            return <EntityDataRow key={index} className="" title={data[0]} description={data[1]} />
                        }
                        break;
                    }
                    default: {
                        return <EntityDataRow key={index} className="" title={data[0]} description={data[1]} />
                    }

                }
            })
        }
        return (
            <div>
                <div className="secion-title-wrap">
                    <h1>Startup India : Startup India Logo</h1>
                </div>

                <div className="entity-detail-wrap mb-10">
                    <div className="entity-detail-inner p-20 ">
                        <h3>Applicant Details</h3>
                        {/*<KeyValue items={applicantInfo} />*/}
                        {itrateListItem(applicantInfo, details)}
                    </div>
                </div>
                <div className="entity-detail-wrap mb-10">
                    <div className="entity-detail-inner p-20 ">
                        <h3>Event Details</h3>
                        {/*<KeyValue items={EventInfo} />*/}
                        {itrateListItem(EventInfo, details)}
                        {forEventFlag &&
                            <div>
                            {itrateListItem(forEvent, details)}
                            </div>
                        }
                        {forElectronicMediaFlag &&
                            <div>
                            {itrateListItem(forElectronicMedia, details)}
                            </div>
                        }

                    </div>
                </div>

                {validRoles && details && (details.status === 'PENDING') ?
                    <div className="button-group mt-30 mb-30">

                        <Button
                            className="bttn green normal"
                            type='button'
                            onClick={this.handleActionApprove}
                            text="Approve"
                        />
                        <Button
                            className="bttn red normal"
                            type='button'
                            onClick={this.handleActionReject}
                            text="Reject"
                        />
                    </div> : ''

                }


            </div>)

    }
}


const mapStateToProps = (state) => {
    return {
        SectionData: state.StartupLogoReducer.startupLogoDetails,
    }
}

export default connect(mapStateToProps, { startupLogoViewDetail, UpdateStartupLogoStatus })(withRouter(StartupLogoView));
