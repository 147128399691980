import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNotificationDocumentAction, fetchNotificationDocumentsListByID } from '../dependencies/action';
import { isLoading } from '../../../constants/actionConstants';
import FormValidator from '../dependencies/FormValidator';
import GlobalDropDown from '../../common/GlobalDropDown/index';
import InputField from '../../common/InputField';
import fileTypeOption from '../../../dependencies/utils/json/fileTypeOption';
import { uploadFileService } from '../../../dependencies/services/uploadFileService';
import FileUpload from '../../common/FileUpload';
import { notificationFileType } from '../dependencies/constant';
import Button from '../../common/Button';
import { getDocumentLink } from '../../../dependencies/utils/helper';
import SectionTitle from '../../common/sectionTitle/sectionTitle';

const AddEditNotificationDocuments = (props) => {
    const { fetchNotificationDocumentsListByID, addNotificationDocumentAction, isLoading, match: { params: { id: exemptionId } = {} } = {}, history } = props;

    const defaultFormData = {
        fileInfo: null,
        fileType: '',
        id: null,
        linkTitle: "",
        title: "",
        url: null
    }

    const [formData, setFormData] = useState(defaultFormData);
    const [error, setError] = useState({});
    const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] = useState(false);



    useEffect(() => {
        if (fetchNotificationDocumentsListByID && exemptionId) {
            fetchNotificationDocumentsListByID(exemptionId).then((response) => {
                let { data, status } = response;
                if (status) {
                    if (data && data.fileType === 'LINK') {
                        data = { ...data, fileInfo: null }
                    } else if (data && data.fileType === 'FILE_UPLOAD') {
                        data = { ...data, url: null }
                    } else {
                        data = { ...data, url: null, fileInfo: null }
                    }
                    setFormData(d => {
                        return { ...d, ...data }
                    })
                }
            })
        }
    }, [])

    const validateInput = () => {
        const {
            fileInfo,
            fileType,
            linkTitle,
            title,
            url,
        } = formData;

        const formStatus = FormValidator.validateNotificationDocument({
            fileInfo,
            fileType,
            linkTitle,
            title,
            url,
        });
        const errors = {
            fileInfo: formStatus.fileInfo,
            fileType: formStatus.fileType,
            linkTitle: formStatus.linkTitle,
            title: formStatus.title,
            url: formStatus.url,
        };

        setError(errors);
        return !formStatus.hasErrors;
    };

    const handleInputChange = (e, name) => {
        const { value, } = e.target;
        let fields = {
            [name]: value
        }
        if (name === 'fileType') {
            if (value === 'LINK') {
                fields = { ...fields, fileInfo: null }
            } else if (value === 'FILE_UPLOAD') {
                fields = { ...fields, url: null }
            }
        }
        setFormData((formData) => {
            return { ...formData, ...fields }
        });
    }

    const handleSubmit = async () => {
        if (!validateInput()) {
            setHasInvalidSubmitOccurred(true)
        } else {
            addNotificationDocumentAction({
                ...formData,
            }).then(({ status = false }) => {
                if (status) {
                    props.history.push('/notification/list')
                }
            })
        }
    }

    const handleReset = () => {
        setError({});
        setFormData(defaultFormData);
    }

    const onUploadFile = (file) => {
        isLoading(true)
        const data = new FormData();
        data.append('file', file)
        data.append('documentType', 'NOTIFICATION_DOC');
        uploadFileService.uploadFile(data).then((data) => {
            if (data) {
                setFormData(d => {
                    return { ...d, fileInfo: data };
                });
            }
            isLoading(false)
        }, (error) => {
            isLoading(false)
        });
    }

    const fileLink = formData && formData.fileInfo && formData.fileInfo.publicUrl && getDocumentLink(formData.fileInfo.publicUrl) || ""
    const onBlur = () => {
        validateInput();
    }
    return (
        <div className="row">

            <div className="report-table col-md-6">
                <SectionTitle>{exemptionId ? 'Edit Notification Documents' : 'Add New Notification Documents'}</SectionTitle>
                <InputField
                    required
                    autoComplete="off"
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    error={error.title}
                    label={"Notification Title"}
                    containerClass={"form-group"}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                />
                <InputField
                    required
                    autoComplete="off"
                    type="text"
                    name="linkTitle"
                    value={formData.linkTitle}
                    onChange={handleInputChange}
                    error={error.linkTitle}
                    label={"Link Title"}
                    containerClass={"form-group"}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                />
                <GlobalDropDown
                    required
                    label='File Type'
                    selectTagStyle="form-control dropdown-file mb-20"
                    onChange={handleInputChange}
                    options={notificationFileType}
                    name={'fileType'}
                    value={formData.fileType}
                    error={error.fileType}
                    onBlur={onBlur}
                    hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                />
                {
                    formData && formData.fileType === 'LINK' &&
                    <InputField
                        required
                        autoComplete="off"
                        type="text"
                        name="url"
                        value={formData.url}
                        onChange={handleInputChange}
                        error={error.url}
                        label={"Website(URL)"}
                        containerClass={"form-group"}
                        onBlur={onBlur}
                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                    />
                }
                {
                    fileLink &&
                    <label>uploaded document:  <a href={fileLink}>{formData.fileInfo.compressedName}</a></label>
                }
                {
                    formData && formData.fileType === 'FILE_UPLOAD' &&
                    <FileUpload
                        onUploadFile={onUploadFile}
                        allowedFiles={[".pdf", ".png", ".jpg"]}
                        error={error.fileInfo}
                        label='Upload Document'
                        onBlur={onBlur}
                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}

                    />
                }
                <div className="button-group mt-30">
                    <Button
                        className="bttn normal"
                        type='button'
                        onClick={handleSubmit}
                        text="Submit"
                    />
                    {!exemptionId &&
                        <Button
                            className="bttn blue normal"
                            type='button'
                            onClick={handleReset}
                            text="Reset"
                            value={formData.fileInfo}
                        />
                    }
                </div>

            </div>
        </div>)
}


const mapStateToProps = (state) => {
    return {
        notificationdata: state.NotificationDocumentsReducer.notificationDocumentsID,
    }
}

export default connect(mapStateToProps, { addNotificationDocumentAction, fetchNotificationDocumentsListByID, isLoading })(withRouter(AddEditNotificationDocuments));