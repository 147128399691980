import React, { Component, useEffect, useState } from 'react';
import Button from '../../common/Button';
import KeyValue from '../../applicationFor80Iac/keyValue';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { section56FetchIdAction, updateSection56Action } from '../dependencies/action';
import ActionModal from '../dependencies/modal/index';
import { ROLES } from '../../../constants/Roles';
import { storage } from '../../../dependencies/store/storage';
import './style.css';

class Section56Info extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sAppId: this.props.match.params.id,
            details: {},
            modalValue: false,
            buttonValue: '',
            exemptionId: this.props.match.params.id
            
        }
    }
    
    componentDidMount() {
        this.props.section56FetchIdAction(this.state.sAppId);
    }
    
    callApi = () => {
        this.props.section56FetchIdAction(this.state.sAppId);
        
    }
    
    toggleActionModal = () => {
        const { modalValue } = this.state;
        this.setState({
            modalValue: !modalValue
        });
    }
    
    handleAction = (e) => {

        const status = e.target.textContent;
        if (status == 'Approve') {
            this.toggleActionModal();
            const { buttonValue } = this.state;
            this.setState({
                buttonValue: 'approvedReject'
            })
        }
        else if (status == 'Incomplete') {
            const value = 'INCOMPLETE';
            this.toggleActionModal();
            const { buttonValue } = this.state;
            this.setState({
                buttonValue: value
            })
        } else if (status == 'Archive') {
            const value = 'ARCHIVE';
            this.toggleActionModal();
            const { buttonValue } = this.state;
            this.setState({
                buttonValue: value
            })
        }
        
    }
    
    render() {
        const userRole = storage.getUserRole();
        const data = this.props.SectionData && this.props.SectionData;
        const { sAppId, modalValue, buttonValue } = this.state;
       
        const validRoles = userRole === ROLES.SUPER_ADMIN //|| userRole === ROLES.ADMIN
        const section56Role = userRole === ROLES.SEC_56_ADMIN
        return (
            <div>
                <div className="secion-title-wrap">
                    <h1>Startup India : Applications for Section 56</h1>
                </div>

                <div className="entity-detail-wrap mb-10">
                    <div className="entity-detail-inner p-20 ">
                        <h3>Starup Details</h3>
                        <KeyValue items={data.starupInfo ? data.starupInfo :''} />

                    </div>
                </div>
                <div className="entity-detail-wrap mb-10">
                    <div className="entity-detail-inner p-20 ">
                        <h3>Contact Details</h3>
                        <KeyValue items={data.contactInfo ? data.contactInfo : ''} />

                    </div>
                </div>
                <div className="entity-detail-wrap mb-10">
                    <div className="entity-detail-inner p-20 ">
                        <h3>Declaration</h3>
                        <KeyValue items={data.declarationInfo ? data.declarationInfo : ''} />

                    </div>
                </div>
                <div className="button-group mt-30 mb-30 approv-inc-archive">

                { validRoles && data && (data.status === 'PENDING') ?
                        <Button
                            className="bttn normal"
                            type='button'
                            onClick={this.handleAction}
                            name="approvereject"
                            text="Approve"
                        /> : null}
                {validRoles && data && (data.status === 'PENDING') ?

                        <Button
                            className="bttn yellow normal"
                            type='button'
                            onClick={this.handleAction}
                            text="Incomplete"
                        /> : null}

                { validRoles && data && (data.status === 'PENDING' && data.isArchived === false || (data.status === 'REJECT' || data.status === 'INCOMPLETE' || data.status === 'CANCELLED' || data.status === 'EXPIRED')) ?
                        <Button
                            className="bttn blue normal"
                            type='button'
                            onClick={this.handleAction}
                            text="Archive"
                        /> : null}
                        </div>
                {modalValue ?
                    <ActionModal
                        modalValue={modalValue}
                        toggleActionModal={this.toggleActionModal}
                        actionValue={buttonValue}
                        
                        callApi={this.callApi}
                        id={sAppId
                            ?
                            sAppId : ''}
                    /> : null}

            </div>)
    }
}


const mapStateToProps = (state) => {
    
    return {
        SectionData: state.Section56Reducer.sectionIdDetails
    }
}

export default connect(mapStateToProps, { section56FetchIdAction, updateSection56Action })(withRouter(Section56Info));
