import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import InputField from '../../../../common/InputField';
import GlobalDropDown from '../../../../common/GlobalDropDown';
import { dropdownOptionsTransform } from '../../../dependencies/utils';
import FormValidator from '../../../dependencies/Validation';
import {unEscapeRegHtml} from '../../../../../dependencies/utils/helper'
import Button from '../../../../common/Button'
import InlineError from '../../../../common/inlineError'

const Address = (props) => {

    const {
        statesAndUnionTerritory,
        startupDistrict,
        startupSubDistrict,
        address,
        getDistrictData,
        getSubDistrictList,
        onChangeHandler,
        error = {},
        doSubmit,
        doReset
    } = props;
    const defaultFormData = {
        address1: "",
        address2: "",
        address3: "",
        city: "",
        districtId: "",
        districtName: "",
        pinCode: "",
        stateId: "",
        stateName: "",
        subDistrictId: "",
        subDistrictName: ""
    }

    const [formData, setFormData] = useState(defaultFormData);
    const [statesAndUnionTerritoryOptions, setStatesAndUnionTerritory] = useState([]);
    const [startupDistrictOptions, setStartupDistrict] = useState([]);
    const [loadDistrict, setLoadDistrict] = useState(false);
    const [loadSubDistrict, setLoadSubDistrict] = useState(false);
    const [startupSubDistrictOptions, setStartupSubDistrict] = useState([]);

    useEffect(() => {
        if (loadDistrict) {
            const { stateId } = formData;
            getDistrictData({ stateId }).then(() => {
                setLoadDistrict(false);
                const { districtId } = formData;
                if (districtId) {
                    if (getSubDistrictList) {
                        getSubDistrictList({ districtId })
                    }

                }
            })
        }
    }, [loadDistrict])

    useEffect(() => {
        if (loadSubDistrict) {
            const { districtId } = formData;
            getSubDistrictList({ districtId }).then(() => {
                setLoadSubDistrict(false);
            })
        }
    }, [loadSubDistrict])

    useEffect(() => {
        if (!isEmpty(address)) {
            setFormData(options => {
                return { ...options, ...address }
            });
            setLoadDistrict(true)
        }

    }, [address])
    useEffect(() => {
        const options = dropdownOptionsTransform(statesAndUnionTerritory, 'stateName', 'id');
        setStatesAndUnionTerritory(options);
    }, [statesAndUnionTerritory]);
    useEffect(() => {
        const options = dropdownOptionsTransform(startupDistrict, 'districtName', 'id');
        setStartupDistrict(options);
    }, [startupDistrict]);
    useEffect(() => {
        const options = dropdownOptionsTransform(startupSubDistrict, 'subDistrictName', 'id');
        setStartupSubDistrict(options);
    }, [startupSubDistrict]);

    useEffect(() => {
        if (onChangeHandler) {
            onChangeHandler({
                address: { ...formData }
            })
        }
    }, [formData]);

    const handleInputChange = (e, name) => {
        if (e && e.target) {
            const value = e.target.value;
            let field = {};
            if (name === 'stateId') {
                const obj = statesAndUnionTerritory.filter(x => x.id === value)[0] || {};
                field = {
                    stateId: obj.id,
                    stateName: obj.stateName,
                    districtId: "",
                    districtName: "",
                    subDistrictId: "",
                    subDistrictName: "",
                };
                setLoadDistrict(true)
            } else if (name === 'districtId') {
                const obj = startupDistrict.filter(x => x.id === value)[0] || {};
                field = {
                    districtId: obj.id,
                    districtName: obj.districtName,
                    subDistrictId: "",
                    subDistrictName: ""
                }
                setLoadSubDistrict(true);
            } else if (name === 'subDistrictId') {
                const obj = startupSubDistrict.filter(x => x.id === value)[0] || {};
                field = { subDistrictId: obj.id, subDistrictName: obj.subDistrictName }
            } else {
                field = { [name]: value };
            }

            setFormData((data) => {
                return { ...data, ...field }
            });

        }
    }

    return (
        <div>
            <div className="row mb-20">
                <div className="col-sm-4">
                    <InputField
                        label='Address Line 1'
                        required
                        type='text'
                        name="address1"
                        value={unEscapeRegHtml(formData.address1)}
                        onChange={handleInputChange}
                        error={error.address1}
                        containerClass={"form-group"}
                        hasInvalidSubmitOccurred={true}

                    />
                </div>
                <div className="col-sm-4">
                    <InputField
                        label='Address Line 2'
                        required
                        type='text'
                        name="address2"
                        value={unEscapeRegHtml(formData.address2)}
                        onChange={handleInputChange}
                        error={error.address2}
                        containerClass={"form-group"}
                        hasInvalidSubmitOccurred={true}
                    />
                </div>
                <div className="col-sm-4">
                    <InputField
                        label='Address Line 3'
                        type='text'
                        name="address3"
                        value={unEscapeRegHtml(formData.address3)}
                        onChange={handleInputChange}
                        containerClass={"form-group"}
                    />
                </div>
            </div>
            <div className="row mb-20">
                <div className="col-sm-4">
                    <InputField
                        label='City/ Village'
                        required
                        type='text'
                        name="city"
                        value={unEscapeRegHtml(formData.city)}
                        onChange={handleInputChange}
                        error={error.city}
                        containerClass={"form-group"}
                        hasInvalidSubmitOccurred={true}

                    />
                </div>
                <div className="col-sm-4">
                    <GlobalDropDown
                        required
                        label='State/ Union Territory'
                        selectTagStyle="form-control dropdown-media filter-dropdown"
                        onChange={handleInputChange}
                        options={statesAndUnionTerritoryOptions}
                        name={'stateId'}
                        value={formData.stateId}
                        hasInvalidSubmitOccurred={true}
                        error={error.stateId}
                    />
                </div>
                <div className="col-sm-4">
                    <GlobalDropDown
                        required
                        label='District'
                        selectTagStyle="form-control dropdown-media filter-dropdown"
                        onChange={handleInputChange}
                        options={startupDistrictOptions}
                        name={'districtId'}
                        value={formData.districtId}
                        hasInvalidSubmitOccurred={true}
                        error={error.districtId}
                    />
                </div>
            </div>
            <div className="row mb-20">
                <div className="col-sm-4">
                    <GlobalDropDown
                        // required
                        label='Sub District'
                        selectTagStyle="form-control dropdown-media filter-dropdown"
                        onChange={handleInputChange}
                        options={startupSubDistrictOptions}
                        name={'subDistrictId'}
                        value={formData.subDistrictId}
                        error={error.subDistrictId}
                        hasInvalidSubmitOccurred={true}
                    />
                </div>
                <div className="col-sm-4">
                    <InputField
                        label='Pin Code'
                        required
                        type='text'
                        name="pinCode"
                        value={formData.pinCode}
                        onChange={handleInputChange}
                        error={error.pinCode}
                        containerClass={"form-group"}
                        hasInvalidSubmitOccurred={true}
                    />
                </div>
            </div>
            {
                error.tnc && (
                    <div className="row mb-20">
                        <div className="col-sm-12">
                            <InlineError message={`Go to "Self Certification" and ${error.tnc}`} />
                        </div>
                    </div>
                )
            }
            <div className="row mb-20">
                <div className="col-sm-12 text-center">
                    <Button
                        className="bttn mb-20"
                        type="button"
                        text="Save"
                        onClick={() => doSubmit("ADDRESS")}
                    />
                   
                </div>
            </div>

        </div>
    )
}

export default Address;