import React, { useState, useEffect } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { get80IacHistoryList } from '../dependencies/action'
import SectionTitle from '../../common/sectionTitle/sectionTitle';
import SearchBox from '../../common/Search';
import GlobalDropDown from '../../common/GlobalDropDown';
import Table from '../../common/Table';
import allApplicationDropdown from '../../../dependencies/utils/json/allApplicationDropdown.json'
import { SearchByStartupApplicationOptions } from '../common/constant';
import moment from 'moment';
import columns from '../dependencies/columns/application80IacColumn';
import { SearchByIAS80Options, IAS80ApplicationStatus } from '../common/constant';
import { getSectorOptions } from '../../applicationFor80Iac/dependencies/action';
import { doDownload80IacList } from '../dependencies/service';

import Download from '../../common/Download';

const IAC80List = (props) => {
    const { get80IacHistoryList, applicationFor80IacData, doDownload80IacList } = props;

    const [pageIndex, setPageIndex] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [exemptionId, setExemptionId] = useState("");
    const [fromId, setFromId] = useState("");
    const [actionType, setActionType] = useState('');
    const [checkedValue, setCheckedValue] = useState([]);
    const [query, setQuery] = useState({
    });
    const [sectorOptions, setSectorOptions] = useState([]);
    useEffect(() => {
        getTableData();
    }, [query])


    const getTableData = () => {
        get80IacHistoryList({
            query
        });
    }


    const doSearch = (params) => {
        const { query = {}, searchText = "", searchBy = "" } = params;
        if (searchBy && searchBy === 'certificateDate') {
            setQuery(() => {
                return { ...query, 'searchType': 'CERTIFICATE_DATE' }
            });
        } else if (searchBy && searchBy === 'createdDate') {
            setQuery(() => {
                return { ...query, 'searchType': 'CREATED_DATE' }
            });
        } else {
            setQuery(query);
        }
        setSearchText(searchText);
        setPageIndex(0)
    }

    useEffect(() => {
        if (props.getSectorOptions) {
            props.getSectorOptions().then((response) => {
                const { data = [] } = response;
                let options = [];
                if (data && data instanceof Array) {
                    options = data.reduce((arr, option) => {
                        if (option['industryName'] !== null) {
                            var text = `${option['sectionName']} (${option['industryName']})` || "";
                        } else {
                            var text = `${option['sectionName']}` || "";
                        }
                        const value = option['id'] || "";
                        arr.push({ text, value });
                        return arr;
                    }, []);
                }
                setSectorOptions(options);
            });
        }
    }, [])

    const downloadCSV = () => {
        if (doDownload80IacList) {
            return doDownload80IacList({

                query,
            }).then((data) => {
                return data;
            })
        }
    }
    return (
        <div className="iac80-list-wrapper">
            <SectionTitle breadCrumb title="Applications for 80IAC Exemption">Applications for 80IAC Exemption</SectionTitle>
            <SearchBox
                searchByOptions={SearchByIAS80Options(sectorOptions)}
                doSearch={doSearch}
            />
            <Download
                onClickDownload={downloadCSV}
                fileName={'IAC80Detail'}
                className="bttn-link"
            />

            <Table
                dataTable={applicationFor80IacData ? applicationFor80IacData : ''}
                columns={columns}
                keyField='id'
                // onSortChange={onSortChange}
                sizePerPage={1000}
                // totalSize={recordsTotal}
                cssClass='startupLogs'
            />
        </div>
    )
}



const mapStateToProps = (state) => {
    return {
        applicationFor80IacData: state.RecognitionHistoryReducer.application80IacHistory
    }
}
export default connect(mapStateToProps, { doDownload80IacList, get80IacHistoryList, getSectorOptions })(IAC80List);
