import React, { Component, useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Link, NavLink, withRouter } from 'react-router-dom';
import navList, { parentLinks } from '../../../tempNav';
import './sideBar.scss';
import { routeWithRoles } from '../../../constants/Roles';
import { storage } from '../../../dependencies/store/storage';
import ReactTooltip from 'react-tooltip';

const SideBar = (props) => {
  const { location: { pathname: path } = {} } = props;
  const [activeSubNav, setActiveSubNav] = useState([path])

  const roles = storage.getUserRole();
  const doActiveSubNav = (link) => {
    const arr = cloneDeep(activeSubNav);
    if (activeSubNav.indexOf(link) > -1) {
      arr.splice(activeSubNav.indexOf(link), 1);
    } else {
      arr.push(link);
    }
    setActiveSubNav(arr)
  }

  useEffect(() => {
    parentLinks && parentLinks.forEach(element => {
      if (path.indexOf(element) > -1) {
        setActiveSubNav([element]);
      }
    });
  }, [])

  const renderMenu = (items) => {
    
    if (!roles) {
      return false;
    } else {
      return (<ul>
        {items.map((i, ind) => {
          if (i.subNav) {
            return <li className={`sub-nav ${routeWithRoles[roles] && routeWithRoles[roles].indexOf(i.routeName) > -1 ? '' : ' d-none'}`} key={i.name}>
              <span
                index={ind}
                onClick={() => doActiveSubNav(i.link)}
                className={`link sub-nav-title ${i.className} ${activeSubNav.indexOf(i.link) > -1 ? 'active' : ''}`}
               
              >
                {i.name}
                <i
                  index={ind}

                  className="plus"
                ></i>
              </span>
             
              {i.subNav && renderMenu(i.subNav)}
            </li>
          }
          else {
            return routeWithRoles[roles] && routeWithRoles[roles].indexOf(i.routeName) > -1 && (<li key={i.name}>
              <NavLink
                activeClassName="active-nav"
                className={`link ${i.className} ${routeWithRoles[roles].indexOf(i.routeName) > -1 ? '' : ' d-none'}`}
                to={i.link}
                data-tip={i.toolTip}
                data-delay-show='1500'
                data-place='top'
                data-type = 'light'
                data-effect = 'float'
                border = {true.toString()}
                >
                {i.name}
               
              </NavLink>
              <ReactTooltip />
            </li>)
          }

        })}
      </ul>)
    }
    
  }

  const menuList = renderMenu(navList);

  return (
    <div className='sidebar-wrap'>
      {menuList}
    </div>
  );
}


export default withRouter(SideBar);




