import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { bulkArchive } from '../../../dependencies/services/bulkArchive';
import { toast } from "react-toastify";
const ActionModal = (props) => {

    const {
        buttonLabel,
        className,
        data
    } = props;
    const toggle = () => props.toggleActionModal();
    const refreshApi = () => props.callApi();
    const actionPerform = async () => {

        await props.bulkArchive(data).then((data) => {

            if (data.status) {
                toast.success(data.message)
            } else {
                toast.error(data.message)
            }
        }).catch(e => {

        });

        await toggle();
        await refreshApi()
    }




    return (

        <div>
            <Modal isOpen={props.modalValue} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>
                    {props.actionValue === 'invalid' ? 'Invalid'
                        : (props.actionValue === 'approved' ? 'Approved'
                            : (props.actionValue === 'archived' ? 'Archived' : '')
                        )
                    }</ModalHeader>
                <ModalBody>
                    {props.actionValue === 'invalid'
                        ? 'Please select minimum one checkbox'
                        : 'Do you want to proceed?'}

                </ModalBody>
                <ModalFooter>
                    {props.actionValue === 'invalid'
                        ? <Button color="secondary" onClick={toggle}>Cancel</Button>
                        : <Button color="primary" onClick={actionPerform}>Ok</Button>
                    }



                </ModalFooter>
            </Modal>
        </div>



    );
}

const mapStateToProps = (state) => {

    return {

    }
}
export default connect(mapStateToProps, { bulkArchive })(withRouter(ActionModal))
