import {
    validateRejectDropDown,
    validateOther
} from '../../../dependencies/utils/validation/Validation';

export default {
   
    validateDropDownValue: (params) => {
        const { dropDownValidate,other } = params;
        if((params.other && params.other.length !== 0) || (params.other == '')){
            let formStatus = {};
            const dropDownValidateStatus = validateRejectDropDown(dropDownValidate);
            const otherStatus = validateOther(other);
           
    
            formStatus = {
                dropDownValidate: dropDownValidateStatus.error,
                other: otherStatus.error,
              
                hasErrors: (
                    dropDownValidateStatus.hasError,
                    otherStatus.hasError
                )
            };
            return formStatus;
        }else{
            let formStatus = {};
            const dropDownValidateStatus = validateRejectDropDown(dropDownValidate);
           
    
            formStatus = {
                dropDownValidate: dropDownValidateStatus.error,
              
                hasErrors: (
                    dropDownValidateStatus.hasError
                )
            };
            return formStatus;
        }
       
    },

   
   
};
