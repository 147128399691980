import { combineReducers } from 'redux';
import {
    RECOGNITION_HISTORY,
    APPLICATION80IAC_HISTORY,
    SECTION_56_HISTORY
} from '../../../constants/actionConstants';


const recognitionHistory = (state = [], action) => {
    
    switch (action.type) {
        case RECOGNITION_HISTORY:
            return [ ...action.payload];
        default:
            return state;
    }
}
const section56History = (state = {}, action) => {
    switch (action.type) {
        case SECTION_56_HISTORY:
            return { ...action.payload};
        default:
            return state;
    }
}

const application80IacHistory = (state = [], action) => {
    switch (action.type) {
        case APPLICATION80IAC_HISTORY:
            return [ ...action.payload];
        default:
            return state;
    }
}

const RecognitionHistoryReducer = combineReducers({
    recognitionHistory,
    application80IacHistory,
    section56History
})

export default RecognitionHistoryReducer;