import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './scss/index.scss';


class ActionPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupStatus: false
    }
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ ...this.state, popupStatus: false })
    }
  }

  doAction = (e, actionType) => {
    e.stopPropagation();
    const { actionHandler } = this.props;
    if (actionHandler) {
      actionHandler(actionType);
    }
  }
  actionPopupHandler = (e) => {
    e.stopPropagation();
  }
  caretClickHandler = (e) => {
    e.stopPropagation();
    this.setState({ ...this.state, popupStatus: !this.state.popupStatus })
  }
  render() {
    const {actionPopupHandler} = this;
    const { caretClickHandler, doAction } = this;
    const { actions } = this.props
    return (
      <div ref={this.setWrapperRef} className="action-popup-outer">
        <i onClick={e => caretClickHandler(e)} className="popup-caret" >&#9660;</i>
        {
          this.state.popupStatus &&
          <div className="action-popup-wrap pt-20 pb-10" onClick={e => actionPopupHandler(e)}>
            <h6 className="ph-20">TAKE ACTION</h6>
            <ul>
              {
                actions && actions.map((action, index) => {
                  return (
                    <li>
                      <a onClick={(e) => doAction(e, action.type)} className="action-link pt-15 pb-15 pl-20 pr-20" >
                        <img width="18" src={action.icon} />
                        {action.text}
                      </a>
                    </li>

                  )
                })
              }
              {/* <li><a onClick={(e) => doAction(e, 'archived')} className="action-link pt-15 pb-15 pl-20 pr-20" ><img width="18" src={popArchiveIcon} />Archived</a></li> */}
            </ul>
          </div>
        }
      </div>
    )
  }
}
ActionPopup.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string
  }))
};
ActionPopup.defaultProps = {
  actions: []
};
export default ActionPopup;