import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { actionStartupApplication } from '../../../dependencies/services/startupApplicationService';
import { fetchRejectionList } from '../../../dependencies/services/getRejectList';
import GlobalDropDown from '../../common/GlobalDropDown/index'
import TextareaField from '../../common/TextareaField'
import FormValidator from '../dependencies/FromValidator';
import './scss/index.scss';
const ActionModal = (props) => {

    const {
        buttonLabel,
        className,
        callApi,
    } = props;

    const [incompleteStatus, setIncompleteStatus] = useState([]);
    const [other, setOther] = useState('');
    const [showInputFiled, setShowInputFiled] = useState(props.actionValue === 'INCOMPLETE' ? true : false);
    const [rejectStatus, setRejectStatus] = useState('');
    const [incompleteStatusTitle, setIncompleteStatusTitle] = useState([])
    const [rejectReasonTitle, setRejectReasonTitle] = useState(false)
    const [incompleteOtherId, setIncompleteOtherId] = useState('');
    const [error, setError] = useState({});
    const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] = useState(false);
    useEffect(() => {
        if (props.actionValue === 'REJECT' || props.actionValue === 'INCOMPLETE' || props.actionValue === 'ON_HOLD')
            props.fetchRejectionList({
                enablerType: "STARTUP",
                startupRejectType: props.actionValue,
            })
    }, [props.actionValue])
    useEffect(() => {
        for (var i = 0; i < props.getRejectList.length; i++) {
            if (props.getRejectList[i].reason == "Others- If any other, please specify.") {
                setIncompleteOtherId(props.getRejectList[i].id);
            }

        }
    }, [props.getRejectList])
    if (props.getRejectList) {
        let otherId = '';
        var outputSrtUpAppList = props.getRejectList.map(s => ({ text: s.reason, value: s.id }));
        if (props.actionValue === 'INCOMPLETE') {
            for (var i = 0; i < outputSrtUpAppList.length; i++) {
                if (outputSrtUpAppList[i].text == "Others- If any other, please specify.") {
                    outputSrtUpAppList.splice(i, 1);
                }

            }


        }
    }
    const validateInput = () => {
        let dropDownValidate
        if (incompleteStatus && incompleteStatus !== 'select') {
            dropDownValidate = incompleteStatus
        } if (rejectStatus && rejectStatus !== 'select') {
            dropDownValidate = rejectStatus
        }

        const result = incompleteStatusTitle.indexOf('Others- If any other, please specify.')

        let otherData = {}
        if (result != -1 || rejectStatus === 'REJECT' || rejectReasonTitle) {
            otherData = { other }
        }


        const formStatus = FormValidator.validateDropDownValue({
            dropDownValidate,
            ...otherData
        });
        const errors = {
            dropDownValidate: formStatus.dropDownValidate,
            other: formStatus.other
        };
        setError(errors);
        return !formStatus.hasErrors;
    };

    const onBlur = (e, name) => {
        validateInput();
    }
    const submitFormHandler = async (e) => {
        e.preventDefault();
        if (props.actionValue === 'INCOMPLETE') {
            let statusWithOther = incompleteStatus;
            if (other !== '') {
                statusWithOther.push(incompleteOtherId)
                setIncompleteStatus(statusWithOther)
            }
        }
        if (!validateInput()) {
            setHasInvalidSubmitOccurred(true)
        } else {
            let sendRes = {}
            if (rejectStatus) {
                sendRes = {
                    rejectReason: { id: rejectStatus },
                    rejectDescription: other
                }
            } else if (incompleteStatus) {
                const arrayToObject = incompleteStatus.map((item) => { return { id: item } })
                sendRes = {
                    incompleteReasons: arrayToObject,
                    incompleteDescription: other
                }
            }
            await props.actionStartupApplication({
                id: props.id,
                status: props.actionValue,
                ...sendRes
            })
            await toggle();
            await callApi();
        }
    }

    const handleInput = (e, name) => {
        let value = e.target.value;
        if (name === 'rejectDropDownValue') {

            let ix = e.nativeEvent.target.selectedIndex;
            if (e.nativeEvent.target[ix].text.indexOf('Others- If any other') > -1) {
                setShowInputFiled(true)
                setRejectReasonTitle(true)
            } else {
                setShowInputFiled(false)
                setRejectReasonTitle(false)
            }
            setRejectStatus(e.target.value)
        } else if (name === 'incompleteDropDownValue') {
            const val = [];
            const titles = [];
            let otherSelected = false;
            let options = e.target.options;
            options = Array.from(options)


            for (let i = 0, l = options.length; i < l; i++) {
                if (options[i].selected) {

                    val.push(options[i].value);
                    titles.push(options[i].title);
                    // if(options[i].title.indexOf('Others- If any other, please specify.') > -1 && otherSelected === false) {
                    //     otherSelected = true;
                    // }

                }
            }
            setIncompleteStatusTitle(titles)
            setIncompleteStatus(val)
            // if (otherSelected) {
            //     setShowInputFiled(true)
            // } else {
            //     setShowInputFiled(false)
            // }
        } else if (name === 'other') {
            setOther(e.target.value)

        }
    }
    const toggle = () => props.toggleActionModal();

    const actionPerform = async () => {
        let sendRes = {};
        if (props.actionValue === "ON_HOLD") {

            sendRes = {
                onHoldReasons: [{ id: props.getRejectList[0]?.id }],
                onHoldDescription: ""
            }
            await props.actionStartupApplication({
                id: props.id,
                status: props.actionValue,
                ...sendRes
            })
        }
        else {
            await props.actionStartupApplication({
                id: props.id,
                status: props.actionValue
            })
        }
        await toggle();
        await callApi();
    }

    // console.log("props.getRejectList", props.getRejectList[0]?.id)
    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            <Modal isOpen={props.modalValue} toggle={toggle} className={`${className} ${props.actionValue === 'INCOMPLETE' ? 'incompleteModal' : ''}`}>
                <ModalHeader toggle={toggle}>{props.actionValue == 'ON_HOLD' ? 'On Hold' : props.actionValue}</ModalHeader>
                <ModalBody>

                    {props.actionValue === 'ARCHIVE' || props.actionValue === 'APPROVE' || props.actionValue === 'ON_HOLD' || props.actionValue === 'SUBMIT'
                        ? ' Do you want to proceed ?'
                        : (props.actionValue === 'INCOMPLETE'
                            ? (
                                <form className="filter-form" onSubmit={submitFormHandler}>

                                    <GlobalDropDown
                                        required
                                        label='Reason'
                                        selectTagStyle="form-control dropdown-media"
                                        multiple
                                        onChange={handleInput}
                                        options={outputSrtUpAppList}
                                        name={'incompleteDropDownValue'}
                                        defaultOptionDisable={true}
                                        value={incompleteStatus}
                                        error={error.dropDownValidate}
                                        onBlur={onBlur}
                                        hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                    />
                                    {showInputFiled &&
                                        <TextareaField
                                            required
                                            type="text"
                                            name="other"
                                            label="Others- If any other, please specify."
                                            value={other}
                                            onChange={handleInput}
                                            error={error.other}
                                            containerClass={"form-group"}
                                            hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}

                                        />
                                    }
                                    <div className="button-wrap">
                                        <button type='submit' className='bttn'>Submit</button>
                                    </div>
                                </form>
                            )
                            : (props.actionValue === 'REJECT'
                                ? (
                                    <form className="filter-form" onSubmit={submitFormHandler}>
                                        <GlobalDropDown
                                            required
                                            label='Reason'
                                            containerStyle="form-group "
                                            selectTagStyle="form-control dropdown-media"
                                            onChange={handleInput}
                                            options={outputSrtUpAppList}
                                            value={rejectStatus}
                                            name={'rejectDropDownValue'}
                                            error={error.dropDownValidate}
                                            onBlur={onBlur}
                                            hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}
                                        />
                                        {showInputFiled &&
                                            <TextareaField

                                                required
                                                type="text"
                                                name="other"
                                                label="Other"
                                                value={other}
                                                onChange={handleInput}
                                                error={error.other}
                                                containerClass={"form-group"}
                                                hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}

                                            />
                                        }

                                        <div className="button-wrap">
                                            <button type='submit' className='bttn'>Submit</button>
                                        </div>
                                    </form>
                                )
                                : ''
                            )
                        )
                    }
                </ModalBody>

                {props.actionValue === 'APPROVE' || props.actionValue === 'ARCHIVE' || props.actionValue === 'ON_HOLD' || props.actionValue === 'SUBMIT' ?
                    (
                        <ModalFooter>
                            {props.actionValue === 'APPROVE' || props.actionValue === 'ARCHIVE' || props.actionValue === 'ON_HOLD' || props.actionValue === 'SUBMIT'
                                ? <Button className='bttn blue' color="primary" onClick={actionPerform}>Ok</Button>
                                : ''}

                        </ModalFooter>
                    )
                    :
                    ''
                }
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        getRejectList: state.getRejectListReducer.rejectionList
    }
}
export default connect(mapStateToProps, { actionStartupApplication, fetchRejectionList })(withRouter(ActionModal))
