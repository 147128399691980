import { FETCH_USER_LIST,CLEAR_USER_LIST,FETCH_USER_LIST_FIFTY_SIX } from '../../constants/actionConstants';

const initialState = {
    userData: [],
};

export default (state = initialState, action) => {

    let newState;
    switch (action.type) {

        case FETCH_USER_LIST:
            newState = {
                ...state,
                userData: action.payload
            };
            return newState;        
        case CLEAR_USER_LIST:
            newState = {
                ...state,
                userData: []
            };
            return newState;
        default:
            return state;
    }
}
