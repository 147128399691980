import { combineReducers } from 'redux';
import {
    CURRENT_STARTUP_APPLICATION,
    SET_STARTUP_INDUSTRY,
    SET_NATURE_OF_THE_ENTITY,
    SET_STARTUP_SERVICES,
    SET_STARTUP_SECTOR,
    SET_STATE,
    SET_STARTUP_DISTRICT,
    SET_STARTUP_SUB_DISTRICT,
    INCOMPLETE_LIST,
    AUDIT_TRAIL
} from '../../../constants/actionConstants';



const activeStartupApplication = (state = {}, action) => {
    switch (action.type) {
        case CURRENT_STARTUP_APPLICATION:
            return { ...action.payload };
        default:
            return state;
    }
}

const natureOfTheEntity = (state = [], action) => {
    switch (action.type) {
        case SET_NATURE_OF_THE_ENTITY:
            return [...action.payload];
        default:
            return state;
    }
}

const startupIndustry = (state = [], action) => {
    switch (action.type) {
        case SET_STARTUP_INDUSTRY:
            return [...action.payload];
        default:
            return state;
    }
}

const startupEntitySector = (state = [], action) => {
    switch (action.type) {
        case SET_STARTUP_SECTOR:
            return [...action.payload];
        default:
            return state;
    }
}

const startupServices = (state = [], action) => {
    switch (action.type) {
        case SET_STARTUP_SERVICES:
            return [...action.payload];
        default:
            return state;
    }
}

const statesAndUnionTerritory = (state = [], action) => {
    switch (action.type) {
        case SET_STATE:
            return [...action.payload];
        default:
            return state;
    }
}

const startupDistrict = (state = [], action) => {
    switch (action.type) {
        case SET_STARTUP_DISTRICT:
            return [...action.payload];
        default:
            return state;
    }
}

const startupSubDistrict = (state = [], action) => {
    switch (action.type) {
        case SET_STARTUP_SUB_DISTRICT:
            return [...action.payload];
        default:
            return state;
    }
}

const incompleteHistoryList = (state = [], action) => {
    switch (action.type) {
        case INCOMPLETE_LIST:
            return [...action.payload]
        default:
            return state;
    }
}

const AuditTrailList = (state=[], action) => {
    switch (action.type) {
        case AUDIT_TRAIL:
            return [...action.payload]
        default:
            return state;
    }
}

const StartupFormReducer = combineReducers({
    activeStartupApplication,
    natureOfTheEntity,
    startupServices,
    startupDistrict,
    startupEntitySector,
    startupIndustry,
    startupSubDistrict,
    statesAndUnionTerritory,
    incompleteHistoryList,
    AuditTrailList
})

export default StartupFormReducer;