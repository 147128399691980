import React, {Component} from "react";
import './addUser.scss';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import SectionTitle from '../common/sectionTitle/sectionTitle';
import {getRoles} from '../../dependencies/services/rolesService';
import {addUser, clearMessage} from '../../dependencies/services/addUserService';
import AlertMessage from "../common/Alert";

class AddUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            emailId: '',
            newpass: '',
            confpass: '',
            role: '',
            name: '',
            dob: ''
        }
        this.baseState = this.state;
    }


    componentWillMount() {
        this.props.getRoles();
    }

    onChangeHandler = (e) => {
        const fieldName = e.target.name;
        const fieldVal = e.target.value;
        const newState = {...this.state};
        newState[`${fieldName}`] = fieldVal;
        this.setState(newState);
    }

    resetForm = () => {
        this.setState(this.baseState);
    }

    submitFormHandler = (e) => {
        e.preventDefault();
        let temp = this.props.addUser({
            emailId: this.state.emailId,
            username: this.state.username,
            password: this.state.newpass,
            confirmPassword: this.state.confpass,
            role: this.state.role,
            name: this.state.name,
            dob: this.state.dob
        });

        if (temp !== false) {
            temp.then((data) => {
                if (data) {
                    this.resetForm();
                }
            })
        }
    }

    
   
    render() {
        let roleData = this.props.roles.roles.map(it => (
            <option key={it.id} value={it.roleName}>{it.roleName}</option>));
        return (
            <div className="change-pass-wrap">
                <SectionTitle>Add User</SectionTitle>

                {this.props.addUserState.error &&
                <AlertMessage alertColor={'danger'} visible={this.props.addUserState.error}
                              alertMessage={this.props.addUserState.message}
                              onDismiss={this.props.clearMessage}/>
                }

                {this.props.addUserState.status && this.props.addUserState.message &&
                <AlertMessage alertColor={'success'} visible={this.props.addUserState.status}
                              onDismiss={this.props.clearMessage}
                              alertMessage={this.props.addUserState.message}/>
                }

                <form onSubmit={this.submitFormHandler}>
                    <div className="section clearfix">
                        <div className="form-group">
                            <label>Username</label>
                            <input type="text" autoComplete="off" name="username" onChange={this.onChangeHandler}
                                   value={this.state.username}/>
                        </div>
                        <div className="form-group">
                            <label>Email ID</label>
                            <input type="email" autoComplete="off" name="emailId" onChange={this.onChangeHandler}
                                   value={this.state.emailId}/>
                        </div>
                    </div>
                    <div className="section clearfix">
                        <div className="form-group">
                            <label>Create Password</label>
                            <input type="password" autoComplete="off" name="newpass" onChange={this.onChangeHandler}
                                   value={this.state.newpass}/>
                        </div>
                        <div className="form-group">
                            <label>Confirm Password</label>
                            <input type="password" autoComplete="off" name="confpass" onChange={this.onChangeHandler}
                                   value={this.state.confpass}/>
                        </div>
                    </div>
                    <div className="section clearfix">
                        <div className="form-group">
                            <label>Name</label>
                            <input type="text" autoComplete="off" name="name" onChange={this.onChangeHandler}
                                   value={this.state.name}/>
                        </div>
                        <div className="form-group">
                            <label>Date of Birth</label>
                            <input type="text" autoComplete="off" placeholder='DD/MM/YYYY' name="dob" onChange={this.onChangeHandler}
                                   value={this.state.dob}/>
                        </div>
                    </div>
                    <div className="section clearfix">
                        <div className="form-group">
                            <label>User Role</label>
                            <select name="role" id="" onChange={this.onChangeHandler}>
                                <option value="">Select</option>
                                {roleData}
                            </select>
                        </div>
                    </div>
                    
                    <div className="button-wrap">
                        <button type='submit' className='bttn'>UPDATE NOW</button>
                        <button type='submit' blueBtn='false' className='bttn white'>Cancel</button>
                    </div>
                </form>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        roles: state.roles,
        addUserState: state.addUser
    }
}

export default connect(mapStateToProps, {getRoles, clearMessage, addUser})(withRouter(AddUser));