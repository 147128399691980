import api from '../utils/Api';
import { UPDATE_BULK_ARVCHIVE_URL } from "../../constants/ApplicationUrl";
import { updateBulkArchive } from "../../constants/actionConstants";

export const bulkArchive = ({ids,status }) => (dispatch) => {
    return api.POST(UPDATE_BULK_ARVCHIVE_URL, { ids,status })
        .then((data) => {
            
            if (data && data.data && data.data) {
                const { data: { status, message } } = data;
                    return { status, message };
            }
        })

};
