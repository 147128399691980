import api from "../utils/Api";
import { FETCH_ROLES_URL } from "../../constants/ApplicationUrl";
import { fetchRolesAction, } from "../../constants/actionConstants";


export const getRoles = () => (dispatch) => {

    return api.GET(FETCH_ROLES_URL)
        .then((data) => {
            if (data && data.data && data.data.data) {
                dispatch(fetchRolesAction(data.data.data));
            }
        })

};
