import React from 'react';
import { API_URL } from '../../../../constants/ApplicationUrl'
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ROLES } from '../../../../constants/Roles';
import { storage } from '../../../../dependencies/store/storage';
import { headerSortingStyle } from '../../../../constants';


const cancellationColumns = ({clickOnAction, pageNo, size}) => {
  const userRole = storage.getUserRole();
return  [
  {
    dataField: '',
    text: 'Sr.No',
    formatter: (cell, row, rowIndex) => {
      return (pageNo * size) + (rowIndex + 1);
    }
  }, {
    dataField: 'certificateNumber',
    text: 'Certificate Number',
    sort: true,
    headerSortingStyle
  }, {
    dataField: 'incorporationNumber',
    text: 'Registration No',
    sort: true,
    headerSortingStyle
  }, {
    dataField: 'incorporationDate',
    text: 'Registration Date',
    // formatter: (cell) => {
    //   if (cell) {
    //     return moment(cell).format('DD/MM/YYYY')

    //   }
    // },
    sort: true,
    headerSortingStyle
  }, {
    dataField: 'applicationDate',
    text: 'Application Date',
    // formatter: (cell) => {
    //   if (cell) {
    //     return moment(cell).format('DD/MM/YYYY')

    //   }
    // },
    sort: true,
    headerSortingStyle
  }, {
    dataField: 'applicationId',
    text: '',
    hidden: true
  },
  {
    dataField: 'action',
    text: 'Action',
    hidden: userRole !== ROLES.SUPER_ADMIN && userRole !== ROLES.ADMIN,
    formatter: (cell, row) => {
      const { applicationId } = row;
      return (
        <div className="IAS-Actions-list">
          <ul>
            <li><Link to={`/cancellation/${applicationId}`}>View</Link></li>
            {(userRole === ROLES.SUPER_ADMIN || userRole === ROLES.ADMIN)  ? <li><a href="javascript:void(0)" onClick={() => clickOnAction(applicationId)}>Certificate Cancellation</a></li> : ''}
          </ul>
        </div>
      )

    }
  },
  {
    dataField: 'action',
    text: 'Action',
    hidden: !(userRole === ROLES.USER),
    formatter: (cell, row) => {
      const { applicationId } = row;
      return (
        <div className="IAS-Actions-list">
          <ul>
            <li><Link to={`/cancellation/${applicationId}`}>View</Link></li>
          </ul>
        </div>
      )

    }
  },
  {
    dataField: 'nameOfEntity',
    text: 'Name of the Entity',
    sort: true,
    headerSortingStyle
  },
  {
    dataField: 'mobileNumber',
    text: 'Mobile No',
    sort: true,
    headerSortingStyle
    
  }, 
  // {
  //   dataField: '',
  //   text: 'Availing Tax Benefits',
  //   sort: true,
  //   headerSortingStyle
  // },
  {
    dataField: 'recognitionCertificateStatusLabel',
    text: 'Certificate Of Recognition',
    sort: false,
    headerSortingStyle
  }, {
    dataField: 'eligibilityCertificateStatusLabel',
    text: 'Certificate Of Eligibility',
    sort: false,
    headerSortingStyle
  }, {
    dataField: 'section56CommonStatusLabel',
    text: 'Section 56 Status',
    sort: false,
    headerSortingStyle
  }, 
];
}

export default cancellationColumns;
