import api from '../../../dependencies/utils/Api';

import {
    FETCH_STATE_LIST,
    FETCH_STARTUP_SECTOR,
    DOWNLOAD_STARTUP_REPORT,
    FETCH_STARTUP_INDUSTRY_LIST,
    FETCH_STARTUP_REPORT_URL
} from '../../../constants/ApplicationUrl';

export const startupDetailReportService = {

    fetchStateList() {
        return api.GET(`${FETCH_STATE_LIST}`).
            then((data) => {
                return data;
            }).catch(e => {
                return Promise.reject(e);
            });
    },
    generateReport(params) {
        return api.POST(`${DOWNLOAD_STARTUP_REPORT}/false`,params).
        then((response) => {
            const { data } = response || {};
            return { data };
        });
    },
    fetchStartupIndustry() {
     
        return api.GET(`${FETCH_STARTUP_INDUSTRY_LIST}`).
            then((response) => {
                const { data: { data: startupSector = [], status } = {} } = response || {};
                return { startupSector, status };
            });
    },
    getStartupSector() {
        return api.GET(`${FETCH_STARTUP_SECTOR}/all/startupSection/list`).
            then((response) => {
                const { data: { data: startupSector = [], status } = {} } = response || {};
                return { startupSector, status };
            });
    },
    getcsvFileLink() {
        return api.GET(`${FETCH_STARTUP_REPORT_URL}`).
            then((response) => {
                const { data: { data: preSignedUrl = '', status } = {} } = response || {};
                return { preSignedUrl, status };
            });
    },
    downloadReport(params) {
        return api.DOWNLOADDATA(`${DOWNLOAD_STARTUP_REPORT}/true`,params).
            then((data) => {
                return data;
            }).catch(e => {
                return Promise.reject(e);
            })
    }
}