import React, { useEffect, useState } from 'react';
import { getDistrict, getState, updateSubDistrict, getSubDistrict } from '../dependencies/action';
import { connect } from 'react-redux';
import Table from '../../common/Table';
import Dropdown from '../../common/GlobalDropDown';
import column from '../dependencies/columns/subdistrict';
import '../style.scss';
import { dropdownOptionsTransform } from '../../../dependencies/utils/helper';
import InputField from '../../common/InputField';

const SubDistrict = (props) => {
    const { getDistrict, getState, updateSubDistrict, getSubDistrict } = props;

    const [subDistricts, setSubDistricts] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [districtOptions, setDistrictOptions] = useState([]);
    const [loadDistrict, setLoadDistrict] = useState(false);
    const [loadSubDistrict, setLoadSubDistrict] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [error, setError] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [form, setForm] = useState({
        subDistrictName: "",
        id: null,
        stateId: "",
        districtId: "",
        stateDTO: {
            id: "",
            stateName: "",
        },
        districtDTO: {
            id: "",
            districtName: ""
        }
    });

    const onCancel = () => {
        setShowForm(false);
        setDisabled(false);
        setForm(d => {
            return { ...d, id: null, subDistrictName: "" }
        })
    }

    useEffect(() => {
        if (getState) {
            getState().then((response) => {
                const { status, data } = response;
                if (status) {
                    const options = data && data.map((x, ind) => {
                        return { ...x, value: x.id, text: x.stateName }
                    })
                    setStateOptions(options);
                }
            });
        }
    }, [])


    useEffect(() => {
        if (loadDistrict) {
            if (form.stateId) {
                getDistrict(form.stateId).then((response) => {
                    const { status, data } = response;
                    if (status) {
                        const options = data && data.map((x, ind) => {
                            return { ...x, value: x.id, text: x.districtName }
                        })
                        setDistrictOptions(options)
                    }
                    setLoadDistrict(false);
                })
            } else {
                setDistrictOptions([]);
            }
        }
    }, [loadDistrict]);

    useEffect(() => {
        if (loadSubDistrict) {
            if (form.districtId) {
                getSubDistrict(form.districtId).then((response) => {
                    const { status, data } = response;
                    if (status) {
                        const options = data && data.map((x, ind) => {
                            return { ...x, value: x.id, text: x.districtName }
                        })
                        setSubDistricts(options)
                    }
                    setLoadSubDistrict(false);
                    setLoadDistrict(false);
                })
            } else {
                setSubDistricts([]);
                setLoadSubDistrict(false);
            }
        }
    }, [loadSubDistrict]);

    const handleInputChange = (e, name) => {
        const { value, } = e.target;
        if (name === "stateId") {
            setDistrictOptions([]);
            setSubDistricts([]);
            setLoadDistrict(true);
        } else if (name === "districtId") {
            setSubDistricts([]);
            setLoadSubDistrict(true);
        }
        setForm(d => {
            return { ...d, [name]: value }
        })
    }

    const validateName = (value) => {
        // var nameRegex = /^(?![\s.]+$)[a-zA-Z\s.]*$/;
        var nameRegex =  /^[ A-Za-z_@./#&+-]*$/
        var name = value.trim();
        let error = "";
        if (name == "" || name == undefined || name == null) {
            error = "This field is required.";
        }
        else if (!nameRegex.test(name)) {
            error = "Please provide valid country name.";
        }
        else if (name.length < 2) {
            error = "Please provide valid country name.";
        }
        else {
            error = "";
        }
        setError(error);
        return error;
    }


    const onSubmit = (e) => {
        e.preventDefault();
        const name = form && form.subDistrictName;
        const selectedState = stateOptions.filter(x => x.id === form.stateId)[0] || {};
        const stateDTO = { id: selectedState.id, stateName: selectedState.stateName };
        const selectedDistrict = districtOptions.filter(x => x.id === form.districtId)[0] || {};
        const districtDTO = { id: selectedDistrict.id, districtName: selectedDistrict.districtName }
        const params = { ...form, stateDTO, districtDTO };
        if (!validateName(name)) {
            const subDistrictName = (form && form.subDistrictName && form.subDistrictName.trim()) || "";
            updateSubDistrict({ ...params, subDistrictName }).then((response) => {
                const { status } = response;
                if (status) {
                    onCancel();
                    setLoadSubDistrict(true);
                }
            });
        }
    }

    const onEdit = (id) => {
        let selectedSubDistrict = subDistricts.filter(x => x.id === id)[0] || {};
        selectedSubDistrict = selectedSubDistrict && { id: selectedSubDistrict.id, subDistrictName: selectedSubDistrict.subDistrictName } || {};
        setForm(d => {
            return { ...d, ...selectedSubDistrict }
        })
        setDisabled(true);
        setShowForm(true)
    }
    return (
        <>
            <div className="country-container">
                <h2 className="mb-20">{"Districts"}
                    {form.districtId &&
                        <button onClick={e => { onEdit() }} className="bttn blue normal add-btn">
                            {"Add Sub-District"}
                        </button>}
                </h2>

                <Dropdown
                    containerStyle="form-group "
                    selectTagStyle="form-control dropdown-media"
                    onChange={handleInputChange}
                    options={stateOptions}
                    name={'stateId'}
                    value={form.stateId}
                    disabled={disabled}
                />
                <Dropdown
                    containerStyle="form-group "
                    selectTagStyle="form-control dropdown-media"
                    onChange={handleInputChange}
                    options={districtOptions}
                    name={'districtId'}
                    value={form.districtId}
                    disabled={disabled}
                />
                {
                    !showForm ?
                        <Table
                            dataTable={subDistricts}
                            columns={column({
                                onEdit,
                            })}
                            keyField={'id'}
                            sizePerPage={1000}
                        />
                        : <form>
                            <InputField
                                required
                                autoComplete="off"
                                type="text"
                                name="subDistrictName"
                                value={form.subDistrictName}
                                onChange={handleInputChange}
                                error={error}
                                label={"Sub-District Name"}
                                containerClass={"form-group"}
                            />
                            <button onClick={e => { onSubmit(e) }} className="bttn blue normal add-btn">
                                {"Submit"}
                            </button>
                            <button onClick={e => { onCancel() }} className="bttn normal add-btn">
                                {"Cancel"}
                            </button>

                        </form>
                }
            </div>
        </>
    )
}

export default connect(null, { getDistrict, getState, updateSubDistrict, getSubDistrict })(SubDistrict);