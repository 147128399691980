import React from 'react';
import './scss/index.scss';

const DataCard = (props) => {
    return (
        <div className='data-card-outer'>
                <div className="inner-wrap">
                    <h5>{props.title}</h5>
                    <div className="sub-ttl-wrap">
                        <span>{props.data}</span>
                    </div>
                </div>
        </div>
    )
}

export default DataCard;