import {EDIT_PROFILE, EDIT_PROFILE_SUCCESS, EDIT_PROFILE_FAILED} from '../../constants/actionConstants';

const initialState = {
    status: true,
    message: '',
    error : false
};

export default (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case EDIT_PROFILE:
        newState = {
            ...state,
            message: '',
            status: true,
            error: false
        };
        return newState;

        case EDIT_PROFILE_SUCCESS:
            newState = {
                ...state,
                message : action.payload,
                status :true,
                error : false
            };
            return newState;
        case EDIT_PROFILE_FAILED:
            newState = {
                ...state,
                message: action.payload,
                status: false,
                error : true
            };
            return newState;
        default:
            return state;
            
    }
}