import get from 'lodash/get';
import { toast } from "react-toastify";
import { profileServices } from './services';
import {
    SET_USER,
    isLoading
} from '../../../../constants/actionConstants';
import { storage } from '../../../../dependencies/store/storage';

export const editProfile = (params) => async (dispatch, state) => {
    dispatch(isLoading(true));
    const response = await profileServices.doEditProfile(params).
        catch(e => {
            const { message, status = false } = e;
            return { message, status };
        });

    if (response) {
        const { message, status } = response;
        if (!status) {
            dispatch(isLoading(false));
            return { message, status }
        }
        const updatedUser = get(response, 'user');
        const currentState = state();
        let activeUserDetail = currentState.loginReducer.user;
        if (activeUserDetail && updatedUser && activeUserDetail.id === updatedUser.id) {
            activeUserDetail = { ...activeUserDetail, ...updatedUser };
            dispatch({
                type: SET_USER,
                payload: activeUserDetail
            });
        }
        toast.success(message);
        dispatch(isLoading(false));
        return { status, message }
    }
}

export const updateUserDetail = () => async (dispatch) => {
    const user = storage && storage.getUser() || {};
    if(user && user.user){
        dispatch({
            type: SET_USER,
            payload: user && user.user
        });
    }
    return true;
}