import React, { Component, useState, useEffect } from "react";
import './editUser.scss';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import AlertMessage from '../../common/AlertMessage';
import { AlertType } from '../../../constants';
import { getUserDetailsValue,get80IACUserList } from '../../../dependencies/services/getUserService'
import { editProfile } from '../../AdminDetails/common/dependencies/action';
import { getRoles } from '../../../dependencies/services/rolesService';
import InputField from '../../common/InputField';
import FormValidator from '../dependencies/FormValidator';
import Button from '../../common/Button';
import moment from 'moment';
import refreshIconwhite from '../../../assests/images/refresh-icon-white.png';
import GlobalDropDown from '../../common/GlobalDropDown/index'
import Select from 'react-select'
import SectionTitle from '../../common/sectionTitle/sectionTitle';
import { ROLES } from "../../../constants/Roles";

const statusOption = [{
  text: 'Active',
  value: true
}, {
  text: 'Inactive',
  value: false
}];

const RoleOption = [
  {
    text: "EP",
    value: "EP"
  },
  {
    text:"IMB MEMBER",
    value: "IMB_MEMBER"
  }
]

const StartupEdituser = (props) => {
  const { userDetails, editProfile, getUserDetailsValue,get80IACUserList, match: { params } = {}, getRoles, history } = props;
  const defaultFormData = {
    emailId: '',
    username: '',
    name: '',
    phoneNumber: '',
    dob: '',
    accountEnabled: '',
    designation: '',
    toBeAssignedRecogUserNames: []
  }


  const [formData, setFormData] = useState(defaultFormData);
  const [error, setError] = useState({});
  const [hasInvalidSubmitOccurred, setHasInvalidSubmitOccurred] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [roleDropDown, setRoleDropDown] = useState('');
  const [AICUserList, setIACUserList] = useState([]);
  const [AICSelectedValue, setAICSelectedValue] = useState('');
  

  const validateInput = () => {
    const {
      emailId,
      username,
      name,
      phoneNumber,
      dob,
      designation
    } = formData;
    const formStatus = FormValidator.validateEditProfile({
      emailId,
      username,
      name,
      phoneNumber,
      dob,
      designation
    });
    const errors = {
      username: formStatus.username,
      emailId: formStatus.emailId,
      name: formStatus.name,
      phoneNumber: formStatus.phoneNumber,
      dob: formStatus.dob,
      designation: formStatus.designation
    };
    setError(errors);
    return !formStatus.hasErrors;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!validateInput()) {
      setHasInvalidSubmitOccurred(true)
    } else {
      editProfile({
        ...formData
      }).then((data) => {
        const { status = false, message } = data || {};
        if (status) {
          history.push('/user-management/startup-users', { message: 'User profile updated successfully' })
        } else if (!status) {
          if (message) {
            setAlertMessage(message);
          } else {
            setAlertMessage('Internal Server Error');
          }
          setAlertType(AlertType.DANGER);
        }
      })
    }
  }

  const setUserProfile = () => {
    const user = { ...userDetails };
    user.dob = moment(user.dob).format('YYYY-MM-DD');
    setFormData(u => {
      return { ...u, ...user }
    })
  }

  useEffect(() => {
    getUserDetailsValue({
      userId: params.id
    })    
    getRoles();
  }, []);

  useEffect(() => {
    if (userDetails) {
      setUserProfile();
      // get 80IAC list
      get80IACUserList({
        userId: userDetails.id,
        role: userDetails.role,
       // section: 'FORM_RECOG'
      }).then(data => data.data).then(data => {
        let uList = []
        data.map(d => {
          uList.push({label:d,value:d}) 
        })
        setIACUserList(uList)
      })
    }
  }, [userDetails]);

  const handleInputChange = (e, name) => {
    let { value, } = e.target;

    if (name === "accountEnabled") {
      value = value === 'true' ? true :
        value === 'false' ? false :
          value;
    }
    
    setFormData((user) => {
      return { ...user, [name]: value }
    });
  }
  const onBlur = (e, name) => {
    // validateInput();
  }
  const onClose = () => {
    setAlertMessage('')
  }
  const doReset = () => {
    if (userDetails) {
      setUserProfile();
    }
    setError({})
  }
  const handleInputMultChange = (e) => {
    let arrvalue = e.map(d => d.value)
    // setAICSelectedValue(arrvalue)
    setFormData((user) => {
      return {...user, toBeAssignedRecogUserNames:arrvalue}
    })
  }

  const accountEnabledValue = formData.accountEnabled === true ? 'true' :
    formData.accountEnabled === false ? 'false' : 'false';
  const isDisabled = (formData.role === ROLES.IMB_MEMBER || formData.role === ROLES.IMB_CHAIR)?true:false;
  return (
    <div className="edit-user-wrap">
      <SectionTitle title="Edit User">Edit User</SectionTitle>

      {/* <LoadingAnimation /> */}
      <AlertMessage
        message={alertMessage}
        type={alertType}
        allowClose={true}
        onClose={onClose}
      />
      <form onSubmit={e => onSubmit(e)}>
        <div className="row">
          <div className='col-sm-4'>
            <InputField
              readonly='readonly'
              type="text"
              name="username"
              containerClass={"form-group"}
              label="User Id"
              value={formData.username}
            />
          </div>
          <div className='col-sm-4'>
            <InputField

              required
              type="text"
              name="emailId"
              label="Email Id"
              value={formData.emailId}
              onChange={handleInputChange}
              error={error.emailId}
              containerClass={"form-group"}
              onBlur={onBlur}
              hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}

            />
          </div>

        </div>
        <div className="row">
          <div className='col-sm-4'>
            <InputField
              required
              type="text"
              name="name"
              label="Name"
              value={formData.name}
              onChange={handleInputChange}
              error={error.name}
              containerClass={"form-group"}
              onBlur={onBlur}
              hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}

            />
          </div>
          <div className='col-sm-4'>
            <InputField
              required
              type="text"
              name="phoneNumber"
              label="Phone Number"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              error={error.phoneNumber}
              containerClass={"form-group"}
              onBlur={onBlur}
              hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}

            />
          </div>

        </div>



        <div className="row">
          <div className='col-sm-4'>
            <InputField
              required
              type="date"
              name="dob"
              label="DOB"
              value={formData.dob}
              onChange={handleInputChange}
              error={error.dob}
              containerClass={"form-group"}
              onBlur={onBlur}
              hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}

            />
          </div>
          <div className='col-sm-4'>
            <InputField
              required
              type="text"
              name="designation"
              label="Designation"
              value={formData.designation}
              onChange={handleInputChange}
              error={error.designation}
              containerClass={"form-group"}
              onBlur={onBlur}
              hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}

            />
          </div>

        </div>


        <div className="row">
          
          <div className='col-sm-4'>
            <InputField
              required
              type="text"
              name="role"
              label="Role"
              value={formData.role}
              onChange={() =>{}}
              error={error.role}
              containerClass={"form-group"}
              onBlur={onBlur}
              hasInvalidSubmitOccurred={hasInvalidSubmitOccurred}

            />
          </div>
        </div>

        <label>Action</label>
        <hr />
        <div className="row">
          <div className='col-sm-4'>
            <GlobalDropDown
              required
              label='User Status (Recognition)'
              containerStyle="form-group "
              selectTagStyle="form-control dropdown-media"
              onChange={handleInputChange}
              value={accountEnabledValue}
              options={statusOption}
              name={'accountEnabled'}
              error=""
              defaultOptionDisable={true}
            />
          </div>
          <div className='col-sm-4'>
            
              <label>Reassign Recognition Application<span class="required">&nbsp;*</span></label>
              <Select 
                isMulti
                closeMenuOnSelect={false}
                options={AICUserList} 
                onChange={handleInputMultChange}
                isDisabled={isDisabled}
                
              />
          </div>

        </div>
        
        
       
        <div className="bttn-group">
          <Button
            type="submit"
            text="Submit"
            className='bttn'
          />
          <Button
            className="bttn blue "
            type='button'
            onClick={doReset}
            text="Reset"
            icon={(<img src={refreshIconwhite} />)}
          />
          <Link className="bttn-link" to='/user-management/startup-users'>
            Cancel
          </Link>
        </div>
      </form>
    </div >
  )

}




const mapStateToProps = (state) => {
  return {
    userDetails: state.getUserDetails.userDetails,
    userRoles: state.roles.roles,
    editProfileState: state.editProfile
  }
}

export default connect(mapStateToProps, { editProfile, getUserDetailsValue,get80IACUserList, getRoles })(withRouter(StartupEdituser));