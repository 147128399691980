import React from 'react';
import PropTypes from 'prop-types';
import './scss/index.scss';

const mobileNumberField = (props) => {
    const {label, required , ...rest} = props;
    return (
        <div className="mobile-number-wrap">
             { label &&
        <label
          htmlFor=""
        >
          {label}
          {
            required
            && (
              <span className="required">&nbsp;*</span>
            )
          }
        </label>}
            <div className="mo-input-wrap clearfix">
                {props.children}
            </div>
        </div>
    )
}
mobileNumberField.propTypes={
    label: PropTypes.string,
  required: PropTypes.bool
}
mobileNumberField.defaultProps ={
    label: '',
  required: false
}

export default mobileNumberField;