import React from 'react';
import AppRoutes from './routes.js';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from './components/common/loader/loadingAnimation';
import ScrollArrow from './components/common/ScrollArrow'

function App() {

  return (
    <div>
      
      <ScrollArrow />
      <Loader />
      <ToastContainer
        autoClose={5000}
       />
      <AppRoutes />
    </div>
  );
}

export default App;
